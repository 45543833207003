import DataTable from "react-data-table-component";
import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, Col, Spinner, Tooltip, Container,Modal,ModalBody,ModalFooter,ModalHeader,Form,FormFeedback,
 Row} from "reactstrap";
import ReactPaginate from "react-paginate";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { handleNullValue,AddressCell } from "../../helpers/function";
import { useLocation } from "react-router-dom";
import { AdminContext } from "../../helpers/adminContext";
import { utcToNormalTime } from "../../helpers/function";
import Loader from "../../helpers/Spinner";
import { Link } from "react-router-dom";


const AdminTransaction = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const userApi = location.state?.user;
  document.title = "ULtrapro | Admin Transaction ";
  const [user, setUser] = useState(userApi);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState([]);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; 
  const pageCount = Math.ceil(totalPagination);
  const { adminInfo } = useContext(AdminContext);
  const [adminType, setAdminType] = useState("");
  const [searchLoad, setSearchLoad] = useState(false);
  const [searchValid, setSearchValid] = useState(false);
  const [inputSearch, setinputSearch] = useState("");
  const [modal_update_list, setmodal_update_list] = useState(false);
  const [CurrentBalance,setCurrentBalance] = useState('')
  const[MaxBalance,setMaxBalance] = useState('')
  const [maxClicked, setMaxClicked] = useState(false);
  
  //update Modal
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
  }
 
  const handleUpdate = (record) => {
    // setId(record.acId);
    // setDepositStatus(record.depositStatus);
    // setWithdrawStatus(record.withdrawStatus);
    // formik.setValues({
    //   username: record.fullName || "",
    //   useremail: record.email || "",
    //   usermobilenumber: record.mobileNo || "",
    //   country: record.country || "",
    // });
    setmodal_update_list(true);
  };

  useEffect(() => {
    if (user === undefined) {
      setUser(Apiservices.AdminTransaction);
    }
  }, [user]);

  useEffect(() => {
    adminList();
    setAdminType(adminInfo?.adminType);
  }, []);

  const adminList = async () => {
    try {
      const response = await postData(Apiservices.AdminTransaction, {});
      if (response.status) {
        setCurrentBalance(response?.data?.uproWalletBalance)
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
        setFormData(response.data.data);
      } else {
      }
    } catch (error) {}
  };
  const HandleClickMAX = async () => {

    try {
      const response = await postData(Apiservices.AdminTransaction, {});
      if (response.status) {
        setMaxClicked(true); 
        formik.setFieldValue('amount', MaxBalance); 
        setMaxBalance(response?.data?.uproWalletBalance)
      } else {
      }
    } catch (error) {}
  };

  //Add Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      adminName: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      adminName: Yup.string().required("Admin Name is required"),
      email: Yup.string().email("Invalid email format").required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      handlePost(values);
    },
  });

  const handlePost = async (data) => {
    const postForm = {
      name: data.adminName,
      email: data.email,
      password: data.password,
    };

    try {
      setLoading(true);
      const response = await postData(Apiservices.ADMIN_ADD, postForm);
      if (response.status) {
        adminList();
        setLoading(false);
        setMaxBalance('')
        toast.success(response.message);
        formik.resetForm();
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  //Permission List
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage));
    HandleClickRowperpage(newRowsPerPage);
  };

  

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => row.serialNo,
      sortable: true,
      minWidth: "80px",
    },
   
    {
      name: "Email",
      selector: (row) => handleNullValue(row.userEmail),
      sortable: true,
      minWidth: "300px",
    },
    {
      name: "Coin",
      selector: (row) => handleNullValue(row.coin),
      sortable: true,
      minWidth: "100px",
    },
    {
      name: "Amount",
      selector: (row) => handleNullValue(row.amount),
      sortable: true,
      minWidth: "150px",
    },
    {
      name: 'Address',
      selector: row => <AddressCell address={row.address} />,
      sortable: true, 
     minWidth:'200px'
  },
   
    {
      name: 'TxnExplorer',
      selector: row => 
          <div className="d-flex align-items-center" >
      <Link className="link-tag" to={row.txnExplorer} target='_blank'>
        <span className="mx-1 text-decoration-underline">
          {row.txnExplorer || '-'}
        </span>
      </Link>
    </div>,
      sortable: true,
      minWidth:'150px'
  },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => {
        switch (row.status) {
          case 0:
            return "Pending";
          case 1:
            return "Send";
            case 2:
            return "Wallet Error";
          default:
            return "-";
        }
      },
      sortable: true,
      minWidth: "100px",
    },
   
   
    {
      name: "Created Date & Time",
      selector: (row) => (row.createdAt ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${utcToNormalTime(row.createdAt).time}` : "---"),
      sortable: true,
      minWidth: "180px",
    },
  ];


  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };

  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: itemsPerPage,
    };
   
    try {
      const response = await postData(Apiservices.AdminTransaction, postForm);

      if (response.status) {
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const HandleClickRowperpage = async (newRowsPerPage) => {
    setLoading(true);
    const postForm = {
      page: 1,
      limit: Number(newRowsPerPage),
    };

    // if (dateRange[0] && dateRange[1]) {
    //   postForm.startDate = formatDate(dateRange[0]);
    //   postForm.endDate = formatDate(dateRange[1]);
    // }

    try {
      if (formData === undefined) {
        const response = await postData(Apiservices.AdminTransaction, postForm);

        if (response.status) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
        }
      } else {
        // const response = await postData(`${Apiservices.MenuList}?${queryString}`);
        const response = await postData(Apiservices.AdminTransaction, postForm);

        if (response.status) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "" && user === undefined) {
      adminList();
      setSearchValid(false);
    } else if (value === "") {
      handleFilter(user);
    }
    setinputSearch(value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
     
      };

      
      try {
        if (user === undefined) {
          const response = await postData(Apiservices.AdminTransaction, postForm);
          if (response.status) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);
          } else {
            setSearchLoad(false);
          }
        } else {
          const response = await postData(user, postForm);
          if (response.status) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);
          } else {
            setSearchLoad(false);
          }
        }
      } catch (error) {
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };

  const handleFilter = async (apiName, type) => {
    const postform = {
      adminType: type,
    };
    setLoading(true);
    try {
      const response = await postData(apiName, postform);
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
     address:'',
     amount:''
    },
    validationSchema: Yup.object({
      address: Yup.string().required("Address is required"),
      amount: Yup.string().when('MaxBalance', (MaxBalance, schema) => {
        return !MaxBalance || !maxClicked
            ? schema.required('Amount is required') // Validate if MaxBalance is empty, null, or undefined
            : schema.notRequired(); // Skip validation
    })
    }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });

  const confirmUpdate = async (values) => {
    
    const postForm = {
      amount: values.amount,
      address: values.address,
      userId:adminInfo?.adminUserId,
      adminEmail:adminInfo?.email
    };

    try {
      setLoading(true);
      const response = await postData(
        `${Apiservices.AdminMoveWallet}`,
        postForm
      );

      if (response.status === true) {
        setmodal_update_list(false);
        toast.success(response.message);
        adminList();
        setLoading(false);
        formik.resetForm()
        setMaxBalance('')
      } else {
        toast.error(response.message);
        adminList();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Internal Server Error..Try Again Later");
      formik.resetForm();
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Admin Management" breadcrumbItem="Admin Transaction" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      {/* <Col className="col-sm-3">
                        <div className="input-group">
                          <Input
                            // onChange={handleInputChange}
                            type="text"
                            className="form-control"
                            placeholder="Search by Name,Email,Mobile Number"
                            aria-label="Recipient's username"
                            onChange={handleSearchChanage}
                            invalid={searchValid}
                            onKeyPress={handleKeyPress}
                          />
                          <div className="input-group-append">
                            <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                              {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                            </button>
                          </div>
                        </div>
                      </Col> */}
                      <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                      <Button color="success" className="add-btn" onClick={handleUpdate} id="create-btn">
                              <i className="ri-add-line align-bottom me-1"></i> Move Wallet
                            </Button>
                            </div>
                            </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <DataTable
                        columns={columns}
                        data={formData}
                        paginationServer
                        paginationTotalRows={formData.length}
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<div>Loading...</div>}
                        persistTableHead={true}
                        noDataComponent={<div className="mt-2">No records found</div>}
                      />
                    </div>

                    <div className="container">
                      <div className="row justify-content-end mt-4">
                        <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                          <div className="d-flex align-items-center me-3">
                            <span className="me-2">Rows per page:</span>
                            <select value={rowsPerPage} onChange={(e) => handleRowsPerPageChange(e.target.value)} className="form-control-sm">
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={30}>30</option>
                              <option value={50}>50</option>
                            </select>
                          </div>
                          <div className="me-md-3 mb-3 mb-md-0">
                            <span>{count}</span>
                          </div>
                          <div className="pagination pagination-rounded mt-3">
                            <ReactPaginate
                              previousLabel={<i className="mdi mdi-chevron-left"></i>}
                              nextLabel={<i className="mdi mdi-chevron-right"></i>}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              breakLinkClassName={"break-link"}
                              disabledClassName={"disabled"}
                              initialPage={currentPage}
                              disableInitialCallback={true}
                              forcePage={currentPage}
                              renderLink={(props) => {
                                if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                  return (
                                    <a
                                      {...props}
                                      onClick={() =>
                                        handlePageClick({
                                          selected: pageCount - 1,
                                        })
                                      }
                                    >
                                      {pageCount}
                                    </a>
                                  );
                                }
                                return <a {...props} />;
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
        className="modal-dialog "
        isOpen={modal_update_list}
        toggle={() => {
          tog_update_list();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            tog_update_list();
          }}
        >
        Move Wallet
        </ModalHeader>
        <Form
          autoComplete="off"
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Row>
            <h6> Current Wallet Balance : {CurrentBalance || '-'}</h6>
              <Col md={12}>
               
                  <div className="mb-3 mt-2">
                                    <label htmlFor="customername-field" className="form-label">Amount</label>
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            id="customername-field"
                                            className="form-control"
                                            placeholder="Enter Amount"
                                            name="amount"
                                            onChange={(e) => {
                                              setMaxClicked(false); // Reset maxClicked if user manually edits
                                              formik.handleChange(e);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={maxClicked ? MaxBalance : formik.values.amount} // Show MaxBalance if maxClicked is true
                                            invalid={formik.touched.amount && formik.errors.amount}
                                        />
                                        <div className="input-group-text" style={{ cursor: 'pointer' }}>
                                            {/* <span>{recordToUpdate.symbol}</span> */}
                                            <span onClick={HandleClickMAX}>{'MAX'}</span>
                                        </div>
                                        {formik.touched.amount && formik.errors.amount && (
                                            <FormFeedback type="invalid">{formik.errors.amount}</FormFeedback>
                                        )}
                                    </div>
                                </div>
                <div className="mb-3">
                  <label htmlFor="title-field" className="form-label">
                    Address
                  </label>
                  <Input
                    type="text"
                    id="sessionname-field"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Enter Address"
                    name="address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address || ""}
                    invalid={
                      formik.touched.address && formik.errors.address
                    }
                  />
                  {formik.touched.address &&
                    formik.errors.address && (
                      <FormFeedback type="invalid">
                        {formik.errors.address}
                      </FormFeedback>
                    )}
                </div>
              </Col>
          
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_update_list(false)
                    setMaxBalance('')
                    }}>
                Close
              </button>

              <button
                className="btn btn-primary waves-effect waves-light"
                type="submit"
                disabled={loading}
              >
                {loading ? <Loader /> : "Click to move wallet"}
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminTransaction;

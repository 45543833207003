import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import { Row, Col, CardBody, Card, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { createSelector } from 'reselect';
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import toast from "react-hot-toast";
import Loader from "../../helpers/Spinner";
import { usePasswordToggle } from '../../helpers/function';
import { Icon } from '@mdi/react';


const Login = () => {
  const { showPassword, togglePasswordVisibility, getIcon } = usePasswordToggle();

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  document.title = "Login | Ultrapro - Admin & Dashboard";

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    validation.setFieldValue(name, value.replace(/\s+/g, ''));
  };


  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',

    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email format").required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      handlePost(values)


    },
  });

  const handlePost = async (data) => {
    setLoading(true)
    const postForm = {
      email: data.email,
      password: data.password,
    }
    try {
      const response = await postData(Apiservices.BACKEND_ADMIN_LOGIN, postForm)
      if (response.status) {
        setLoading(false)
        // const token = response.token;
        // Cookies.set(TokenName.AUTH_TOKEN, token);
        // navigate('/email-otp',{state:data.email});
        navigate('/email-otp', { state: { email: postForm.email } });
        toast.success(response.message)
        handleAgentLogin(data.email)
      }
      else {
         toast.dismiss()
        setLoading(false)
        toast.error(response.message)
        // validation.resetForm();
      }
      if (response.data.isIpActive===2){
        toast.dismiss()
        navigate('/ipBlocked')
        toast.error(response.message)
      }else{
      }
    } catch (error) {
      // toast.error("Internal Server Error..Try Again Later")
      setLoading(false)
    }
  }

  const handleAgentLogin = async (email) => {
    setLoading(true)
    const postForm = {
      email: email,
      agentStatus:1
    }
    try {
      const response = await postData(Apiservices.AGENT_LOGIN, postForm)
      if (response.status) {
       console.log(response,"222")
      }
      else {
        console.log('')
      }
    } catch (error) {
      console.log(error)
      
    }
  }

  const loginpage = createSelector(
    (state) => state.login,
    (state) => ({
      error: state.error,
    })
  );

  const { error } = useSelector(loginpage);





  useEffect(() => {
    document.body.className = "bg-pattern";
    return function cleanup() {
      document.body.className = "";
    };
  }, []);



  return (
    <React.Fragment>
      <div className="bg-overlay"></div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xl={4}>
              <Card>
                <CardBody className="p-4">
                  <div>
                    <div className="text-center">
                      <Link to="/">
                        <img
                          src={logodark}
                          alt=""
                          height="35"
                          className="auth-logo logo-dark mx-auto"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="24"
                          className="auth-logo logo-light mx-auto"
                        />
                      </Link>
                    </div>
                    {/* <h4 className="font-size-18 text-muted mt-2 text-center">
                      Welcome
                    </h4> */}
                    <p className="mb-5 mt-2 text-center">
                      Ultrapro Admin Panel
                    </p>
                    <Form
                      autoComplete="off"
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >

                      <Row>
                        <Col md={12} className="AuthStar">
                          <div className="mb-4">
                            <Label className="form-label">Email<span>*</span></Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="text"
                              onChange={handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                              
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Password<span>*</span></Label>
                            <div className="input-group">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter password"
                              onChange={handleChange}
                              onBlur={validation.handleBlur}

                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                             <div className="input-group-text" onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                            <Icon path={getIcon()} size={1} />
                                        </div>
                              {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid"><div> {validation.errors.password}</div></FormFeedback>
                            ) : null}
                                    </div>
                          
                          </div>
                          {/* <div className="mb-4">
                            <Label className="form-label">Pattern<span>*</span></Label>
                            <PatternLock
                              width={200}
                              size={3}
                              path={path}
                              style={{ backgroundColor: 'black', borderRadius: '10px' }}
                              onChange={handlePatternChange}
                              onFinish={handlePatternFinish}
                              onBlur={handlePatternBlur}
                            />
                            {validation.errors.pattern && validation.touched.pattern ? (
                              <FormFeedback type="invalid" style={{ display: 'block' }}>
                                <div>{validation.errors.pattern}</div>
                              </FormFeedback>
                            ) : null}
                          </div> */}

                          <div className="log-copy d-grid mt-4">
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? (
                                <Loader />
                              ) : ("Log In")}

                            </button>

                            <p>Copyright © 2024. All Rights Reserved By Ultrapro</p>

                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};


// import React, { useState } from 'react';
// import CryptoJS from 'crypto-js';

// const CryptoDemo = () => {
//   const [encryptionKey, setEncryptionKey] = useState('');
//   const [valueToEncrypt, setValueToEncrypt] = useState('');
//   const [encryptedString, setEncryptedString] = useState('');
  
//   const [decryptionKey, setDecryptionKey] = useState('');
//   const [decryptionInputString, setDecryptionInputString] = useState('');
//   const [decryptedValue, setDecryptedValue] = useState('');

//   const handleEncrypt = (e) => {
//     e.preventDefault();
//     try {
//       const encrypted = CryptoJS.AES.encrypt(valueToEncrypt, encryptionKey).toString();
//       setEncryptedString(encrypted);
//     } catch (error) {
//       console.error("Encryption failed", error);
//       setEncryptedString("Encryption failed. Please check your key and value.");
//     }
//   };

//   const handleDecrypt = (e) => {
//     e.preventDefault();
//     try {
//       const bytes = CryptoJS.AES.decrypt(decryptionInputString, decryptionKey);
//       const decrypted = bytes.toString(CryptoJS.enc.Utf8);
//       setDecryptedValue(decrypted);
//     } catch (error) {
//       console.error("Decryption failed", error);
//       setDecryptedValue("Decryption failed. Please check your key and encrypted string.");
//     }
//   };

//   return (
//     <div>
//       <h2>Encryption/Decryption Demo</h2>
//       <form onSubmit={handleEncrypt}>
//         <div>
//           <label>
//             Encryption Key:
//             <input
//               type="text"
//               value={encryptionKey}
//               onChange={(e) => setEncryptionKey(e.target.value)}
//             />
//           </label>
//         </div>
//         <div>
//           <label>
//             Value to Encrypt:
//             <input
//               type="text"
//               value={valueToEncrypt}
//               onChange={(e) => setValueToEncrypt(e.target.value)}
//             />
//           </label>
//         </div>
//         <button type="submit">Encrypt</button>
//       </form>
//       {encryptedString && (
//         <div>
//           <h3>Encrypted String:</h3>
//           <p>{encryptedString}</p>
//         </div>
//       )}

//       <form onSubmit={handleDecrypt}>
//         <div>
//           <label>
//             Decryption Key:
//             <input
//               type="text"
//               value={decryptionKey}
//               onChange={(e) => setDecryptionKey(e.target.value)}
//             />
//           </label>
//         </div>
//         <div>
//           <label>
//             Encrypted String:
//             <input
//               type="text"
//               value={decryptionInputString}
//               onChange={(e) => setDecryptionInputString(e.target.value)}
//             />
//           </label>
//         </div>
//         <button type="submit">Decrypt</button>
//       </form>
//       {decryptedValue && (
//         <div>
//           <h3>Decrypted Value:</h3>
//           <p>{decryptedValue}</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CryptoDemo;


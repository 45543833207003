import React, { useEffect, useState } from "react";
import UserPanel from "./UserPanel";
import TodayUsers from "./TodayUsers";
import { Row, Container } from "reactstrap";
import UserTrade from "./UserTrade";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import UserDeposite from "./UserDeposite";
// import Notifications from "./Notifications";
// import SocialSource from "./SocialSource";
// import Kyc from "./Kyc";
// import RevenueByLocation from "./RevenueByLocation";
// import LatestTransation from "./LatestTransation";
// import { Helmet } from 'react-helmet';
// import { useDispatch } from 'react-redux';
// import { setAdminInfo } from '../../helpers/Redux Store/Actions';

// Import Breadcrumb
import Table from "./TableSamp";
import { postGetData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
// import UserWithdrawDashboard from "./UserWithdrawdashboard";
// import OverView1 from "./OverView";
// import UserWithDraw from "./UserWithDraw";

// import { AdminContext } from '../../helpers/adminContext'; // Adjust the path as needed


const Dashboard = () => {
  // const { setAdminInfo } = useContext(AdminContext); 
  document.title = "Dashboard | Ultrapro - Admin & Dashboard";

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({});
  const [permissions,setPermissions] = useState([])
  const [adminType,setAdminType] = useState('')

  const hasPermission = (moduleName, requiredAction) => {
    const module = permissions.find((perm) => perm.module === moduleName);
    return module && module.actions.includes(requiredAction);
  };

  useEffect(() => {
    ipList();
}, []);


const ipList = async () => {
    setLoading(true);

    try {
        const response = await postGetData(Apiservices.USER_OVERALL_DASHBOARD);
        console.log(response);
        
        if (response.status === true) {
          // setAdminInfo(response.data.adminInfo);
          // console.log(response.data.adminInfo,"RESPONSE");
            setLoading(false);
            setFormData(response.data);
            setPermissions(response.data.adminInfo?.permissions); 
            setAdminType(response.data.adminInfo?.adminType)
           
        } else {
      
            setLoading(false);

        }
    } catch (error) {
      
        setLoading(false);


    }
};

  return (
    <React.Fragment>
      
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Ultrapro" breadcrumbItem="Dashboard" />
          {/* User Panel Charts */}
          
          <UserPanel {...formData}/>

            {/* <Kyc  {...formData}/> */}
            <TodayUsers  {...formData}/>

            

          <Row>
            <Table />
            {/* Overview Chart */}
            {/* Social Source Chart */}
            <UserTrade />
          </Row>
          <Row>
            {/* <Table /> */}
           
          
            {/* <UserWithdrawDashboard /> */}

             {/* Overview Chart */}
            {/* <OverView1 /> */}
              {/* Social Source Chart */}
             {/* <SocialSource /> */}

          </Row>

          <Row>

            {/* Order Stats */}
            {/* <SocialSource /> */}
            {/* <UserDeposite /> */}

          
            {/* <UserWithDraw /> */}

            {/* Revenue by Location Vector Map */}
            {/* <RevenueByLocation /> */}
          </Row>

          {/* Latest Transaction Table */}
          {/* <LatestTransation /> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;

const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    // {
    //     label: "User Management",
    //     icon: "mdi mdi-account-circle-outline",
    //     subItem: [
    //         { sublabel: "All Users", link: "/allUsers" },
    //         { sublabel: "Today Users", link: "/todayUsers" },
    //         { sublabel: "Active Users", link: "/activeUsers" },
    //         { sublabel: "Pending Users", link: "/pendingUsers" },
    //         { sublabel: "Blocked Users", link: "/blockedUsers" },
    //         // { sublabel: "User Kyc", link: "/userKycDetails" },
    //         // { sublabel: "User Bank Details", link: "/userBankDetails" },
    //         { sublabel: "User IP Block", link: "/userIpBlock" },
    //         // { sublabel: "User Signup Bonus", link: "/wellcomeBonus" },

    //         // { sublabel: "User Referral Bonus", link: "/referralBonus" },

    //     ],
    // },
    {
        label: "User Management",
        icon: "mdi mdi-account-circle-outline",
        subItem: [
            {
                sublabel: "Users List",
                subMenu: [
                    { title: "All Users", link: "/allUsers"},
                    { title: "Today Users", link: "/todayUsers"},
                    { title: "Active Users", link: "/activeUsers"},
                    { title: "Pending Users", link: "/pendingUsers"},
                    { title: "Blocked Users", link: "/blockedUsers"},
                    { title: "User IP Block", link: "/userIpBlock"},


                

                   
                ],
            },
            // {
            //     sublabel: "User KYC List",
            //     subMenu: [
            //         { title: "All Users KYC", link: "/allUsersKyc"},
            //         // { title: "Today Users KYC", link: "/todayUsers"},
            //         { title: "Active Users KYC", link: "/activeUsersKyc"},
            //         { title: "Pending Users KYC", link: "/pendingUsersKyc"},
            //         { title: "Rejected Users KYC", link: "/rejectedUsersKyc"},
            //         { title: "Not Upload Users KYC", link: "/notUpladUsersKyc"},


                   
            //     ],
            // },
        ],
        
    },
    {
        label: "Coin Management",
        icon: "mdi mdi-circle-multiple-outline",
        url: "/coinManagement",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    {
        label: "Wallet Management",
        icon: "mdi mdi-wallet",
        url: "/walletManage",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    {
        label: "Swap Management",
        icon: "mdi mdi-view-list",
        subItem: [
            { sublabel: "Swap pairs", link: "/swappairManagement" },
    
            { sublabel: "Swap History", link: "/swap-history" },            

        ],
    },


    // {
    //     label: "Swap",
    //     icon: "mdi mdi-swap-horizontal-circle-outline",
    //     url: "/swap",
    //     issubMenubadge: true,
    //     bgcolor: "bg-primary",
    //     badgeValue: "3"
    // },
    // {
    //     label: "Pairs",
    //     icon: "mdi mdi-swap-horizontal-variant",
    //     url: "/pairs",
    //     issubMenubadge: true,
    //     bgcolor: "bg-primary",
    //     badgeValue: "3"
    // },
    // {
    //     label: "Pair Management",
    //     icon: "mdi mdi-handshake-outline",
    //     url: "/pairManagement",
    //     issubMenubadge: true,
    //     bgcolor: "bg-primary",
    //     badgeValue: "3"
    // },
    // {
    //     label: "Transaction History",
    //     icon: "mdi mdi-view-list",
    //     url: "/trasaction-history",
    //     issubMenubadge: true,
    //     bgcolor: "bg-primary",
    //     badgeValue: "3"
    // },
    {
        label: "Transaction History",
        icon: "mdi mdi-view-list",
        subItem: [
            // { sublabel: "User Transaction History", link: "/transaction-history" }
            { sublabel: "User Signup History", link: "/wellcomeBonus" },
            { sublabel: "User Referral History", link: "/referralBonus" },
            { sublabel: "User Voting History", link: "/VotingTransactionHistory" },
            { sublabel: "Social Media History", link: "/media-transaction-history" },

        ],
    },
    {
        label: "Trade Management",
        icon: "mdi mdi-chart-areaspline",
        url: "/tradeManagement",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    {
        label: "Trade History",
        icon: "mdi mdi-chart-line",
        subItem: [
            { sublabel: "Over All Trade History", link: "/tradehistory" },
            { sublabel: "Active Orders", link: "/tradeactiveorders" },
            { sublabel: "Completed Orders", link: "/tradecompletedorders" },
            { sublabel: "Cancelled Orders", link: "/tradecancelledorders" },
            // { sublabel: "User Referral History", link: "/referralBonus" },

        ],
    },


    
    // {
    //     label: "Crypto Withdraw",
    //     icon: "mdi mdi-cash-minus",
    //     subItem: [
    //         { sublabel: "Completed History", link: "/completed-withdraw-history" },
    //         { sublabel: "Pending History", link: "/pending-withdraw-history" },

    //         { sublabel: "Rejected History", link: "/rejected-withdraw-history" },
    //         { sublabel: "Cancelled History", link: "/canceled-withdraw-history" },
    //         // { sublabel: "User Referral History", link: "/referralBonus" },

    //     ],
    // },
    {
        label: "Admin Management",
        icon: "mdi mdi-security",
        subItem: [
            
            { sublabel: "Admin Users", link: "/adminUsers" },
            { sublabel: "Admin Transaction", link: "/admintransaction" },
            { sublabel: "Add Menus", link: "/addmenu" },
            { sublabel: "Admin IP White List", link: "/AdminWhiteList" },
            { sublabel: "Admin Login Activites", link: "/AdminLoginActivites" },
            { sublabel: "Wallet Types", link: "/walletTypes" },

            // /walletType


       
        ],
    },

    {
        label: "User Bank Details",
        icon: "mdi mdi-bank",
        subItem: [
            { sublabel: "Verified Bank", link: "/user-bank-verifyed" },
            { sublabel: "Pending Bank Verification", link: "/user-bank-pending" },
            { sublabel: "Admin Rejected Bank", link: "/user-bank-rejected" },
            { sublabel: "User Deleted Bank", link: "/user-bank-cancelled" },

        ],
    },

    {
        label: "Deposit History",
        icon: "mdi mdi-cash-plus",
        subItem: [
            // { sublabel: "Fiat History", link: "/depositFiatHistory" },
            { sublabel: "Crypto Deposit History", link: "/depositCryptoHistory" },
            // {
            // sublabel: "Fiat Deposit History",
            //  subMenu: [
            // { title: "Fiat Deposit Completed History", link: "/completed-fiat-deposit-history"},
            // { title: "Fiat Deposit Pending History", link: "/pending-fiat-deposit-history"},
            // { title: "Fiat Deposit Rejected History", link: "/rejected-fiat-deposit-history"},
            //  ],
            // },

        ],
    },

    {
        label: "Withdraw History",
        icon: "mdi mdi-cash-minus",
        subItem: [
            {
                sublabel: "Crypto Withdraw",
                subMenu: [
                    { title: "Completed History", link: "/completed-crypto-withdraw-history"},
                    { title: "Pending History", link: "/pending-crypto-withdraw-history"},
                    { title: "Rejected History", link: "/rejected-crypto-withdraw-history"},
                    // { title: "Cancelled  History", link: "/cancel-crypto-withdraw-history"},

                   
                ],
            },
            // {
            //     sublabel: "Fiat Withdraw",
            //     subMenu: [
            //         { title: "Fiat Withdraw Completed History", link: "/completed-fiat-withdraw-history"},
            //         { title: "Fiat Withdraw Pending History", link: "/pending-fiat-withdraw-history"},
            //         { title: "Fiat Withdraw Rejected History", link: "/rejected-fiat-withdraw-history"},

                   
            //     ],
            // },
        ],
        
    },
    // {
    //     label: "Deposit History",
    //     icon: "mdi mdi-cash-plus",
    //     subItem: [
    //         {
    //             sublabel: "Crypto Deposit",
    //             subMenu: [
    //                 { title: "Completed History", link: "/completed-crypto-deposit-history"},
    //                 { title: "Pending History", link: "/pending-crypto-deposit-history"},
    //                 { title: "Rejected History", link: "/rejected-crypto-deposit-history"},
    //                 // { title: "Cancelled History", link: "/canceled-crypto-Deposit-history"},

                   
    //             ],
    //         },
    //         {
    //             sublabel: "Fiat Deposit",
    //             subMenu: [
    //                 { title: "Completed History", link: "/completed-fiat-deposit-history"},
    //                 { title: "Pending History", link: "/pending-fiat-deposit-history"},
    //                 { title: "Rejected History", link: "/rejected-fiat-deposit-history"},

                   
    //             ],
    //         },
    //     ],
        
    // },
  
    // {
    //     label: "Home Management",
    //     icon: "mdi mdi-tooltip-edit",
    //     url: "/homemanagement",
    //     issubMenubadge: true,
    //     bgcolor: "bg-primary",
    //     badgeValue: "3"
    // },
  
    {
        label: "Community",
        icon: "mdi mdi-book-account-outline",
        url: "/communityManagement",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },

    {
        label: "Settings",
        icon: "mdi mdi-cog-outline",
        subItem: [
            { sublabel: "Site Settings", link: "/siteSettings" },
            { sublabel: "Maintenance Settings", link: "/maintenanceSettings" },
            { sublabel: "Site Bank Details", link: "/siteBank" },
            { sublabel: "Email Template", link: "/emailtemplate" },
            // { sublabel: "CMS Management", link: "/cmsmanagement" },
     
            { sublabel: "Site IP White List", link: "/siteIpWhiteList" },
        ],
    },
    {
        label: "Staking",
        icon: "mdi mdi-database",
        subItem: [
            { sublabel: "Staking offers", link: "/stakingofferslist" },
            { sublabel: "Users Stake History", link: "/usersstakehistory" },
            { sublabel: "Stake Without KYC History (Reward Wallet)", link: "/usersstakeWithoutKychistory" },
            { sublabel: "Stake,KYC Without Withdraw History (Reward Wallet)", link: "/usersstakeWithoutWithdrawhistory" },
            { sublabel: "Stake All Completed History (Reward Wallet)", link: "/completedstake" },
            { sublabel: "Stake Redeem History", link: "/stakeredeemhistory" },


        ],
    },
    // {
    //     label: "Flexible Stacking",
    //     icon: "mdi mdi-database",
    //     url: "/flexiblestacking",
    //     issubMenubadge: true,
    //     bgcolor: "bg-primary",
    //     badgeValue: "3"
    // },
    {
        label: "Flexible Staking",
        icon: "mdi mdi-database",
        subItem: [
            { sublabel: "User Stake History (Flexible Stake)", link: "/userhistoryFlexible" },
            { sublabel: "Flexible Stake Scheme", link: "/flexibleschemeList" },
            { sublabel: "Flexible Stake Plan ", link: "/flexiblestackplanlist" },
            { sublabel: "Claim Rewards", link: "/flexibleClaimReward" },
            { sublabel: "Referral Claim Rewards", link: "/referralClaimRewards" },
            // { sublabel: "Stake,KYC Without Withdraw History (Reward Wallet)", link: "/usersstakeWithoutWithdrawhistory" },
            // { sublabel: "Stake All Completed History (Reward Wallet)", link: "/completedstake" },


        ],
    },
    {
        label: "Compare Exchanges",
        icon: "mdi mdi-circle-multiple-outline",
        url: "/compareotherexchanges",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    {
        label: "Academy",
        icon: "mdi mdi-book-open-page-variant",
        url: "/academylist",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    
    {
        label: "Expo Users",
        icon: "mdi mdi-book-open-page-variant",
        url: "/expousers",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    {
        label: "Teams Management",
        icon: "mdi mdi-account-group",
        url: "/teamslist",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    {
        label: "Discover Management",
        icon: "mdi mdi-message-video",
        url: "/discoverlist",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    
    {
        label: "Probinar Academy",
        icon: "mdi mdi-message-video",
        url: "/probinar_academy",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    {
        label: "Crypto News",
        icon: "mdi mdi-message-video",
        url: "/cryptonews",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    {
        label: "Voting",
        icon: "mdi mdi-fingerprint",
        subItem: [
            { sublabel: "OverAll Votes", link: "/voting" },
            { sublabel: "Pending Votes", link: "/pendingvotes" },
            { sublabel: "Approved Votes", link: "/approvedvotes" },
            { sublabel: "Rejected Votes", link: "/rejectedvotes" },
        ],
    },

    {
        label: "SocialMedia Bonus",
        icon: "mdi mdi-fingerprint",
        subItem: [
            { sublabel: "Overall Bonus", link: "/socialMediaBonus" },
            // { sublabel: "Pending Bonus", link: "/pendingSocialMediaBonus" },
            // { sublabel: "Approved Bonus", link: "/approvedSocialMediaBonus" },
            // { sublabel: "Rejected Bonus", link: "/rejectedSocialMediaBonus" },
        ],
    },

     {
        label: "How to claim USDT",
        icon: " mdi mdi-message-video",
        url: "/claim_usdt",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    

    {
        label: "Live Chat",
        icon: "mdi mdi-message",
        subItem: [
            { sublabel: "Agent Users", link: "/agentlist" },
            { sublabel: "Live Chat List", link: "/livechat" },
            { sublabel: "Locked Chats", link: "/lockedchats" },
            { sublabel: "End Chats", link: "/endchat" },
            { sublabel: "Chat Message Content", link: "/livechatmessagecontent" },

        ],
    },
    {
        label: "Push Notification",
        icon: " mdi mdi-bell-ring-outline",
        url: "/push_notification",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    
    {
        label: "Task",
        icon: "mdi mdi-message-video",
        subItem: [
            { sublabel: "Customer Task", link: "/task-video" },
            { sublabel: "Task History", link: "/taskhistory" },


        ],
    },
    
    // {
    //     label: "Flow sInstruction",
    //     icon: "mdi mdi-message-video",
    //     url: "/flow_instruction",
    //     issubMenubadge: true,
    //     bgcolor: "bg-primary",
    //     badgeValue: "3"
    // },
]
export default SidebarData;
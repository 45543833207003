import React from "react";
import { Routes, Route } from "react-router-dom";

// redux
import { useSelector } from "react-redux";

//constants
import { layoutTypes } from "../constants/layout";
import { UserProvider } from "../helpers/useContext";
// layouts
import NonAuthLayout from "../Layout/NonAuthLayout";
import VerticalLayout from "../Layout/VerticalLayout/index";
import HorizontalLayout from "../Layout/HorizontalLayout/index";

import { IPerror, authProtectedRoutes, publicRoutes } from "./routes";

import { createSelector } from 'reselect';
import { SearchProvider } from "../Hooks/useContext";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
// import store from "../helpers/Redux Store/Store";
import NotFound from "../Pages/Page404/NotFound";
// import routeModules from "./PermissionRoute";

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const Index = () => {
  // const { adminInfo } = useContext(AdminContext);
  // const adminType = adminInfo ? adminInfo?.adminType : '';
  
 

  //const allowedActions = [1, 2, 3, 4];

  // const hasAccess = (route) => {
  
  //   if (route.path === '/dashboard' || route.path === '/login' || route.path === '/request-otp ') {
  //     return true; // Allow access without any condition
  //   }
  
  //   if (!adminInfo) {
  //     console.warn("adminInfo is null. Access denied.");
  //     return false; // Deny access
  //   }
  
  //   // Check if permissions is an array
  //   if (!Array.isArray(adminInfo?.permissions)) {
  //     console.warn("adminInfo?.permissions is not an array. Access denied.");
  //     return false; 
  //   }
  
  //   const module = routeModules[route.path];
  
  //   // Check for adminType and permissions
  //   return adminType === 1 || adminInfo?.permissions.some(data => 
  //     data.module === module && allowedActions.some(action => data.actions.includes(action))
  //   );

   
  // };

//   const hasAccess = (route) => {

//   if (route.path === '/dashboard' || route.path === '/login' || route.path === '/request-otp' || route.path === '/completed-crypto-withdraw-history') {
//     return true; 
//   }


//   if (!adminInfo) {
//     console.warn("adminInfo is null. Access denied.");
//     return false; 
//   }

//   // Check if permissions is an array
//   if (!Array.isArray(adminInfo?.permissions)) {
//     console.warn("adminInfo?.permissions is not an array. Access denied.");
//     return false; 
//   }

//   // Get the module for the route
//   const module = routeModules[route.path];

 
//   if (module) {
//     return adminType === 1 || adminInfo?.permissions.some(data => 
//       data.module === module && allowedActions.some(action => data.actions.includes(action))
//     );
//   }

 
//   return true; 
// };

  const routepage = createSelector(
    (state) => state.Layout,
    (state) => ({
      layoutType: state.layoutType,
    })
  );

  const { layoutType } = useSelector(routepage);
  const Layout = getLayout(layoutType);

  return (
    <UserProvider>
      <Routes>
      <Route path="/pages404" element={<NotFound />} />
        <Route>
          {IPerror.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={route.component}
            />
          ))}
        </Route>

        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              exact={true}
              path={route.path}
              element={
                <PublicRoute>
                  <NonAuthLayout>{route.component}</NonAuthLayout>
                </PublicRoute>
              }
            />
          ))}
        </Route>

        <Route>
      {authProtectedRoutes.map((route, idx) => {
        // const canAccess = hasAccess(route);
        //  if (canAccess) {
          return (
            <Route
              key={idx}
              path={route.path}
              element={
                <PrivateRoute>
                  <SearchProvider>
                    {route.layout === false ? route.component : <Layout>{route.component}</Layout>}
                  </SearchProvider>
                </PrivateRoute>
              }
            />
          );
        // }
            // Redirect to 404 for denied access
    // return (
    //   <Route key={idx} path={route.path} element={<Navigate to="/pages404" replace />} />
    // );

      })}
    </Route>
      </Routes>
    </UserProvider>
  );
};

export default Index;

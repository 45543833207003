import React, { useState, useEffect,useContext } from "react";
import {
  Button,
  Input,
  Card,
  CardBody,
  FormFeedback,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
  Form,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import Swal from "sweetalert2";
import { AddressCellFullLink, utcToNormalTime } from "../../helpers/function";
import { AdminContext } from "../../helpers/adminContext";

const Community = () => {
  const [loading, setLoading] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [modal_list, setmodal_list] = useState(false);
  const [modal_toggle, setmodal_toggle] = useState(false);
  const [rowDetails, setRowDetails] = useState({});
  const [modal_update_list, setmodal_update_list] = useState(false);
  // const [content, setContent] = useState("");
  const [searchTerm, setSearchTerm] = useState("")
  const itemsPerPage = 10; // Number of items to display per page

  document.name = "Settings | Email Template Management";
  const { adminInfo } = useContext(AdminContext);
  const [adminType,setAdminType] = useState('')
  //EMAIL TEMPLATE LIST
  useEffect(() => {
    setAdminType(adminInfo?.adminType)
    fetchData();
  }, []);


  // // Function to encode HTML entities
  // const encodeHtml = (str) => {
  //   const txt = document.createElement("textarea");
  //   txt.textContent = str;
  //   return txt.innerHTML;
  // };

  // // Function to decode HTML entities
  // const decodeHtml = (str) => {
  //   const txt = document.createElement("textarea");
  //   txt.innerHTML = str;
  //   return txt.value;
  // };
  const allowedActions = [1,2,3,4];
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.COMUNITY_LIST);

      if (response) {
        if (response.data.length !== 0) {
          setTemplateList(response.data.communityData);
          setLoading(false);
        } else {
          setTemplateList(response);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
      setLoading(false);

      // Handle fetch error
    }
  };

  const columns = [
    {
      name: "S.No",
      // selector: (row, index) => currentPage * itemsPerPage + index + 1,
      // selector: (row, index) => index + 1,
      selector: (row, index) => offset + index + 1,

      sortable: true,
      maxWidth: "3%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Redirect Link",
      selector: (row) => <AddressCellFullLink address={row.redirectLink} />,

      sortable: true,
      grow: 1

    },
    // {
    //   name: "Community ID",
    //   selector: (row) => row.communityId,
    //   sortable: true,
    // },
    ...(adminType === 1 || adminInfo.permissions.some(data => data.module === "Community" && allowedActions.some(action => data.actions.includes(action))) ? [
    {
      name: "Status",
      // selector: row => row.isActive ? 'Active' : 'Inactive',
      selector: (row) => (
        <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
          {(adminType === 1 || adminInfo.permissions.some(data => 
    data.module === "Community" && data.actions.includes(3))) ? (
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            checked={row.isActive === 1}
            // onChange={() => handleToggle(row, "isActive")}
            onClick={() => handleUpdateStatusClick(row)}
            // onChange={() => handleToggleModel(row, 'isIpActive')}
            // onChange={() =>handleToggleModel(row,Apiservices.EmailTemplateUpdate)}

            disabled={loading}
          />
    ):null}
        </div>
      ),

      sortable: true,
      maxWidth: "1%",
    }]: []),
    // {
    //   name: "Date",
    //   selector: (row) => utcToNormalTime(row.createdAt).date,
    //   sortable: true,
    // },
    ...(adminType === 1 || adminInfo.permissions.some(data => data.module === "Community" && allowedActions.some(action => data.actions.includes(action))) ? [

    {
      name: "Action",
      cell: (row) => (
        <div className="edit">
           {(adminType === 1 || adminInfo.permissions.some(data => 
    data.module === "Community" && data.actions.includes(3))) ? (
          <i
            style={{ cursor: "pointer" }}
            className="mdi mdi-border-color cursor-pointer"
            onClick={() => handleUpdate(row)}
          ></i> ):null}

      {(adminType === 1 || adminInfo.permissions.some(data => 
    data.module === "Community" && data.actions.includes(4))) ? (
          <i onClick={() => handleDelete(row)}
            className="mdi mdi-trash-can-outline"
            style={{ fontSize: '15px', cursor: 'pointer' }}></i>
    ):null}
        </div>
      ),
      ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    }]: []),
  ];

  const handleDelete = (value) => {
    showAlert(confirmDelete, value)
  }

  const showAlert = (onConfirm, row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to remove this record!`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          await onConfirm(row);
        } catch (error) {
          Swal.fire('Error!', 'There was an error deleting the record', 'error');
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // Additional actions after confirmation, if needed
      }
    });
  };

  const confirmDelete = async (row) => {
    const postForm = {
      communityId: row.communityId
    }
    try {
      const response = await postData(Apiservices.COMUNITY_DELETE, postForm)
      if (response.status) {
        Swal.fire('Success!', `${response.message}`, 'success');
        fetchData()

      } else {
        Swal.fire('Error!', `${response.message}`, 'error');
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later")
    }
  }

  //paginnation
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 10;
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
};

  const offset = currentPage * rowsPerPage;
  // const currentData = templateList.slice(offset, offset + rowsPerPage);

  //Add Modal

  function tog_list() {
    setmodal_list(!modal_list);
    formik.resetForm();
  }
  //update Modal
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
  }
  //** content modified functing */
  // const [textContent, setTextContent] = useState("");
  // const handleChange = (e) => {
  //   setTextContent(e.target.value);
  // };
  //Add form validation
  const formik = useFormik({
    initialValues: {
      name: "",
      link: "",
      isActive: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      link: Yup.string()
        .url("Please enter a valid URL")
        .required("Redirect Link is required"),
      isActive: Yup.string().required("Status is required"),
    }),
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  // const handleEditorChange = (content, editor) => {
  //   setContent(content);
  // };

  //Addapicall

  const handleAdd = async (data) => {
    const postForm = {
      name: data.name,
      redirectLink: data.link,
      isActive: data.isActive,
    };

    try {
      const response = await postData(
        Apiservices.COMUNITY_ADD,
        postForm
      );
      if (response.status === true) {
        toast.success(response.message);
        formik.resetForm();
        setmodal_list(false);
        fetchData();
        setLoading(false);
      } else {
        toast.error(response.message);
        setLoading(false);
        formik.resetForm();
        fetchData();
      }
    } catch (error) {
     
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      formik.resetForm();
      setLoading(false);
    }

  };
  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      link: "",
      isActive: "",
      communityId: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required("name is required"),
      link: Yup.string()
        .url("Please enter a valid URL")
        .required("Redirect Link is required"),
      isActive: Yup.string().required("Status is required"),
    }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });

  const handleToggle = async () => {
    const updatedStatus = rowDetails.isActive === 1 ? 0 : 1;
    const postForm = {
      name: rowDetails.name || "",
      subject: rowDetails.subject || "",
      htmlContent: rowDetails.htmlContent || "",
      mailFrom: rowDetails.mailFrom || "",
      // isActive: updatedStatus.isActive !== undefined  ? String(updatedStatus.isActive) : "",
      isActive: updatedStatus,
      templateIcon: rowDetails.templateIcon,
    };
    setLoading(true);
    try {
      const response = await postData(
        `${Apiservices.EmailTemplateUpdate}`,
        postForm
      );

      if (response.status) {
        toast.dismiss();
        setLoading(false);
        toast.success(response.message);
        setmodal_toggle(false);
        fetchData();
      } else {
        toast.error(response.message);
        setmodal_toggle(false);
        setLoading(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      setmodal_toggle(false);
    }
  };
  //updateapicall

  const handleUpdate = (record) => {
    validationUpdate.setValues({
      name: record.name || "",
      link: record.redirectLink || "",
      isActive: record.isActive !== undefined ? String(record.isActive) : "",
      communityId: record.communityId
    });

    setmodal_update_list(true);
  };

  const confirmUpdate = async (values) => {
    // const encodeContent = await encodeHtml(content);
    const postForm = {
      name: values.name,
      redirectLink: values.link,
      isActive: values.isActive,
      communityId: values.communityId


    };

    try {
      setLoading(true);
      const response = await postData(
        `${Apiservices.COMUNITY_UPDATE}`,
        postForm
      );

      if (response.status === true) {
        setmodal_update_list(false);
        // setDeleteLoad(false)
        toast.success(response.message);
        // setmodal_delete(false)
        fetchData();
        setLoading(false);
      } else {
        toast.error(response.message);
        fetchData();
        // validation.resetForm();
        // setmodal_delete(false)
        // setDeleteLoad(false)
        setLoading(false);
      }
    } catch (error) {
      // setDeleteLoad(false)
      toast.error("Internal Server Error..Try Again Later");
      // setmodal_delete(false)
      validationUpdate.resetForm();
    }
  };

  const handleAddClose = () => {
    setmodal_list(!modal_list);
    formik.resetForm();
  };

  const handleClose = () => {
    setmodal_update_list(false);
    // tog_list(false);
    formik.resetForm();
  };

  //status update api call
  const showStatusAlert = (onConfirm, row) => {
    Swal.fire({
      name: "Are you sure?",
      text: "You want to update status!",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm(row);
        // Swal.fire('Success !', 'The status has been updated successfully', 'success');
      }
    });
  };
  const handleUpdateStatusClick = (row) => {
    showStatusAlert(handleToggle1, row);
  };

  const handleToggle1 = async (rowDetails) => {
    const updatedStatus = rowDetails.isActive === 1 ? 0 : 1;
    const postForm = {
      name: rowDetails.name || "",
      redirectLink: rowDetails.redirectLink || "",
      communityId: rowDetails.communityId || "",
      isActive: updatedStatus,
    };
    setLoading(true);
    try {
      const response = await postData(
        `${Apiservices.COMUNITY_UPDATE}`,
        postForm
      );

      if (response.status) {
        toast.dismiss();
        setLoading(false);
        Swal.fire(
          "Success !",
          "The status has been updated successfully",
          "success"
        );

        setmodal_toggle(false);
        fetchData();
      } else {
        toast.error(response.message);
        setmodal_toggle(false);
        setLoading(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      setmodal_toggle(false);
    }
  };
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = templateList.filter(item => {
    const formattedDate = item.createdAt ? utcToNormalTime(item.createdAt).date : '';
    const isIPMatch = item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase());
    const isReasonMatch = item.redirectLink && item.redirectLink.toLowerCase().includes(searchTerm.toLowerCase());
    const isDateMatch = formattedDate && formattedDate.includes(searchTerm);
    const isIDMatch = item._id && item._id.includes(searchTerm);
    const isSearchMatch = isIPMatch || isIDMatch || isReasonMatch;
    return isSearchMatch; 
});

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const validCurrentPage = currentPage >= pageCount ? 0 : currentPage;

  const displayedData = filteredData.slice(
    validCurrentPage * itemsPerPage,
    (validCurrentPage + 1) * itemsPerPage
  );
 
  

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
        // handleInputChange();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs name="Settings" breadcrumbItem="Community Management" />
          <Row>
            <Col lg={12} className="row">

              <Card className="col-lg-12">
                <CardBody>
                  <Row className="g-4 mb-3">
                    {/* <Col className="col-sm-auto">
                  <h4 className="card-name">Email Template Details</h4>
                  </Col> */}
                    <Col className="col-sm-4">

                      <form className="app-search d-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name, Redirect link"
                            onChange={handleInputChange}
                          onKeyPress={handleKeyPress}

                          />
                          <span className="ri-search-line"></span>
                        </div>
                      </form>

                    </Col>
                    <Col className="col-sm">

                      <div className="d-flex justify-content-sm-end">
                      {(adminType === 1 || adminInfo.permissions.some(data => 
                    data.module === "Community" && data.actions.includes(1))) ? (
                        <Button
                          color="success"
                          className="add-btn"
                          onClick={() => tog_list()}
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                        </Button> 
                      ):null}
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={displayedData}
                      paginationServer
                      paginationTotalRows={filteredData.length}
                      progressPending={loading}
                      progressComponent={<div>Loading...</div>}
                      persistTableHead={true}
                      noDataComponent={
                        <div className="mt-5">No records found</div>
                      }
                      fixedHeader={true}
                    />
                    {displayedData.length !== 0 ? (
                      <div className="d-flex justify-content-end mt-3">
                        <div className="pagination pagination-rounded mb-0 ">
                          <ReactPaginate
                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            breakLinkClassName={"break-link"}
                            disabledClassName={"disabled"}
                            initialPage={currentPage}
                            disableInitialCallback={true}
                            forcePage={currentPage}
                            // disableInitialCallback={true}
                            renderLink={(props) => {
                              if (
                                props.page === "..." &&
                                props.pageCount > props.pageRangeDisplayed
                              ) {
                                return (
                                  <a
                                    {...props}
                                    onClick={() =>
                                      handlePageClick({
                                        selected: pageCount - 1,
                                      })
                                    }
                                  >
                                    {pageCount}
                                  </a>
                                );
                              }
                              return <a {...props} />;
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                    {/* // ADDEMAAIL  */}
                    <Modal
                      className="modal-dialog modal-lg"
                      isOpen={modal_list}
                      toggle={() => {
                        tog_list();
                      }}
                      centered
                    >
                      <ModalHeader
                        className="bg-light p-3"
                        id="exampleModalLabel"
                        toggle={() => {
                          tog_list();
                        }}
                      >
                        {" "}
                        Add Community{" "}
                      </ModalHeader>
                      <Form
                        autoComplete="off"
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          formik.handleSubmit();
                          return false;
                        }}
                      >
                        <ModalBody>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <label
                                  htmlFor="name-field"
                                  className="form-label"
                                >
                                  Name
                                </label>

                                <Input
                                  type="text"
                                  id="name-field"
                                  className="form-control"
                                  autoComplete="off"
                                  placeholder="Enter name"
                                  name="name"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.name || ""}
                                  invalid={
                                    formik.touched.name && formik.errors.name
                                  }
                                />
                                {formik.touched.name &&
                                  formik.errors.name && (
                                    <FormFeedback type="invalid">
                                      {formik.errors.name}
                                    </FormFeedback>
                                  )}
                              </div>

                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <label
                                  htmlFor="name-field"
                                  className="form-label"
                                >
                                  Redirect Link
                                </label>

                                <Input
                                  type="text"
                                  id="name-field"
                                  className="form-control"
                                  autoComplete="off"
                                  placeholder="Enter Redirect Link"
                                  name="link"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.link || ""}
                                  invalid={
                                    formik.touched.link && formik.errors.link
                                  }
                                />
                                {formik.touched.link &&
                                  formik.errors.link && (
                                    <FormFeedback type="invalid">
                                      {formik.errors.link}
                                    </FormFeedback>
                                  )}
                              </div>
                            </Col>
                          </Row>

                          <div className="mb-3">
                            <label
                              htmlFor="status-field"
                              className="form-label"
                            >
                              Status
                            </label>
                            <select
                              id="status-field"
                              //  className="form-control"
                              name="isActive"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.isActive || ""}
                              className={`form-control ${formik.touched.isActive &&
                                formik.errors.isActive
                                ? "is-invalid"
                                : ""
                                }`}
                            >
                              <option value="" disabled>
                                Select Status
                              </option>
                              <option value={1}>Active</option>
                              <option value={0}>Deactive</option>
                            </select>
                            {formik.touched.isActive &&
                              formik.errors.isActive && (
                                <FormFeedback type="invalid">
                                  {" "}
                                  {formik.errors.isActive}{" "}
                                </FormFeedback>
                              )}
                          </div>




                        </ModalBody>
                        <ModalFooter>
                          <div className="hstack gap-2 justify-content-end">
                            <button
                              type="button"
                              className="btn btn-light"
                              // onClick={() =>{setmodal_list(false)}}
                              onClick={handleAddClose}
                            >
                              Close
                            </button>

                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? <Loader /> : "Submit"}
                            </button>
                          </div>
                        </ModalFooter>
                      </Form>
                    </Modal>

                    {/* UpdateModal */}
                    <Modal
                      className=" modal-lg"
                      isOpen={modal_update_list}
                      toggle={() => {
                        tog_update_list();
                      }}
                      centered
                    >
                      <ModalHeader
                        className="bg-light p-3 "
                        id="exampleModalLabel"
                        toggle={() => {
                          tog_update_list();
                        }}
                      >
                        Update Community
                      </ModalHeader>
                      <Form
                        autoComplete="off"
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validationUpdate.handleSubmit();
                          return false;
                        }}
                      >
                        <ModalBody>
                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <label
                                  htmlFor="name-field"
                                  className="form-label"
                                >
                                  name
                                </label>

                                <Input
                                  type="text"
                                  id="name-field"
                                  className="form-control"
                                  autoComplete="off"
                                  placeholder="Enter name"
                                  name="name"
                                  onChange={validationUpdate.handleChange}
                                  onBlur={validationUpdate.handleBlur}
                                  value={validationUpdate.values.name || ""}
                                  invalid={
                                    validationUpdate.touched.name &&
                                    validationUpdate.errors.name
                                  }
                                />
                                {validationUpdate.touched.name &&
                                  validationUpdate.errors.name && (
                                    <FormFeedback type="invalid">
                                      {validationUpdate.errors.name}
                                    </FormFeedback>
                                  )}
                              </div>

                            </Col>
                            <Col sm={6}>
                              <div className="mb-3">
                                <label
                                  htmlFor="name-field"
                                  className="form-label"
                                >
                                  Redirect Link
                                </label>

                                <Input
                                  type="text"
                                  id="name-field"
                                  className="form-control"
                                  autoComplete="off"
                                  placeholder="Enter RedirectLink"
                                  name="link"
                                  onChange={validationUpdate.handleChange}
                                  onBlur={validationUpdate.handleBlur}
                                  value={validationUpdate.values.link || ""}
                                  invalid={
                                    validationUpdate.touched.link &&
                                    validationUpdate.errors.link
                                  }
                                />
                                {validationUpdate.touched.link &&
                                  validationUpdate.errors.link && (
                                    <FormFeedback type="invalid">
                                      {validationUpdate.errors.link}
                                    </FormFeedback>
                                  )}
                              </div>

                            </Col>
                          </Row>

                          <div className="mb-3">
                            <label
                              htmlFor="status-field"
                              className="form-label"
                            >
                              Status
                            </label>
                            <select
                              id="status-field"
                              //  className="form-control"
                              name="isActive"
                              onChange={validationUpdate.handleChange}
                              onBlur={validationUpdate.handleBlur}
                              value={validationUpdate.values.isActive || ""}
                              className={`form-control ${validationUpdate.touched.isActive &&
                                validationUpdate.errors.isActive
                                ? "is-invalid"
                                : ""
                                }`}
                            >
                              <option value="" disabled>
                                Select Status
                              </option>
                              <option value={1}>Active</option>
                              <option value={0}>Deactive</option>
                            </select>
                            {validationUpdate.touched.isActive &&
                              validationUpdate.errors.isActive && (
                                <FormFeedback type="invalid">
                                  {" "}
                                  {validationUpdate.errors.isActive}{" "}
                                </FormFeedback>
                              )}
                          </div>



                        </ModalBody>
                        <ModalFooter>
                          <div className="hstack gap-2 justify-content-end">
                            <button
                              type="button"
                              className="btn btn-light"
                              // onClick={() => setmodal_update_list(false)}
                              onClick={handleClose}
                            >
                              Close
                            </button>
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? <Loader /> : "Submit"}
                            </button>
                          </div>
                        </ModalFooter>
                      </Form>
                    </Modal>
                    <Modal
                      isOpen={modal_toggle}
                      className="modal zoomIn"
                      id="deleteRecordModal"
                      centered
                    >
                      <div className="modal-header">
                        <h4>Email List</h4>
                        <Button
                          type="button"
                          onClick={() => setmodal_toggle(false)}
                          className="btn-close"
                          aria-label="Close"
                        >
                          {" "}
                        </Button>
                      </div>
                      <ModalBody>
                        <div className="mt-2 text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                          ></lord-icon>
                          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-3 mb-0">
                              Are you sure you want to update this email status,{" "}
                              {rowDetails && rowDetails.name}?
                            </p>
                          </div>
                        </div>

                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                          <button
                            type="button"
                            className="btn w-sm btn-light"
                            onClick={() => setmodal_toggle(false)}
                          >
                            Close
                          </button>
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            onClick={handleToggle}
                            disabled={loading}
                          >
                            {loading ? <Loader /> : "Yes, Update It!"}
                          </button>
                        </div>
                      </ModalBody>
                    </Modal>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Community;

// import React, { createContext, useState } from 'react';

// // Create a context
// export const AdminContext = createContext();

// // Create a provider component
// export const AdminProvider = ({ children }) => {
//   const [adminInfo, setAdminInfo] = useState(null);

//   return (
//     <AdminContext.Provider value={{ adminInfo, setAdminInfo }}>
//       {children}
//     </AdminContext.Provider>
//   );
// };

import React, { createContext, useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';

// Create a context
export const AdminContext = createContext();

// Create a provider component
export const AdminProvider = ({ children }) => {
  // Retrieve the encrypted adminInfo from sessionStorage
  const [adminInfo, setAdminInfo] = useState(() => {
    const encryptedData = sessionStorage.getItem('adminInfo');
    if (encryptedData) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, 'secret_key');
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } catch (e) {
        console.error('Error decrypting adminInfo:', e);
        return null;
      }
    }
    return null;
  });

  // Update sessionStorage whenever adminInfo changes
  useEffect(() => {
    if (adminInfo !== null) {
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(adminInfo), 'secret_key').toString();
      sessionStorage.setItem('adminInfo', encryptedData);
    } else {
      sessionStorage.removeItem('adminInfo'); // Clear sessionStorage if adminInfo is null
    }
  }, [adminInfo]);

  return (
    <AdminContext.Provider value={{ adminInfo, setAdminInfo }}>
      {children}
    </AdminContext.Provider>
  );
};

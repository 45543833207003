
import DataTable from 'react-data-table-component';
import React, { useState, useEffect ,useContext} from "react";
import { Button, Input, Card, CardBody,  FormFeedback, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form } from "reactstrap";
// import { useTable, usePagination } from 'react-table';
// import { writeFile, utils } from 'xlsx';
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { utcToNormalTime } from '../../helpers/function';
import { AdminContext } from '../../helpers/adminContext';

const WalletCreate = () => {
  
    const { adminInfo } = useContext(AdminContext);
    const [adminType,setAdminType] = useState('')
    document.title = "Ultrapro | User IP Management";

    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState([])
    // const [coinList, setCoinList] = useState([])

    
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // Number of items to display per page
    const pageCount = Math.ceil(formData.length / itemsPerPage);
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    useEffect(() => {
        ipList()
        setAdminType(adminInfo?.adminType)
    }, [adminInfo?.adminType])
    const ipList = async () => {

        try {
            setLoading(true)
            const response = await postData(Apiservices.WALLET_LIST)
            
            if (response.status) {
                // setCoinList(response.data.coinList)
                setFormData(response.data)

                
                setLoading(false)

            } else {
                setLoading(false)

            }

        } catch (error) {

            setLoading(false)

        }





    }



    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            coinName: '',
            walletType: '',
            cloudProvider: 'mpc',


        },
        validationSchema: Yup.object({
            coinName: Yup.string().required("Coin name is required"),
            cloudProvider: Yup.string().required("Cloud Provider name is required"),
            walletType: Yup.string().required("Wallet type is required"),
       
        }),
        onSubmit: (values) => {

            handlePost(values)


        },
    });


    const handlePost = async (data) => {
        
        setLoading(true)
        const postForm = {
            cloudProvider:(data.cloudProvider).toLowerCase(),
            coin:(data.coinName).toLowerCase(),
            walletType:data.walletType
        }
        
        try {
            const response = await postData(Apiservices.WALLET_CREATE,postForm)
            if (response.status) {
                ipList()
                setLoading(false)
                toast.success(response.message)
                setmodal_list(false)
                validation.resetForm();


            } else {
                setLoading(false)
                toast.error(response.message)
            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later")
            setLoading(false)


            validation.resetForm();
        }




    }


    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
     
        // validation.resetForm()
        setmodal_list(!modal_list);
    }

 




    // const handleDelete = (record) => {
    //     setRecordToDelete(record);
    //     setmodal_delete(true)
    // }

    // const confirmDelete = async () => {
    //     const postForm={
    //         networkId:recordToDelete.networkId
    //     }
    //     const queryString = new URLSearchParams(postForm).toString();
    //     setDeleteLoad(true)
    //     try {
    //         const response = await postData(`${Apiservices.NETWORK_DELETE}?${queryString}` )
    //         if (response.status) {
    //             setDeleteLoad(false)
    //             toast.success(response.message)
    //             setmodal_delete(false)
    //             ipList()

    //         } else {
    //             toast.error(response.message)
    //             validation.resetForm();
    //             setmodal_delete(false)
    //             setDeleteLoad(false)

    //         }

    //     } catch (error) {
    //         setDeleteLoad(false)
    //         toast.error("Internal Server Error..Try Again Later")

    //         setmodal_delete(false)
            

    //         validation.resetForm();
    //     }



    // }


    const [searchTerm, setSearchTerm] = useState("")

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = formData.filter(item => {
        
        const formattedDate = item.createdAt ? utcToNormalTime(item.createdAt).date : '';
        const isIPMatch = item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase());
        const isReasonMatch = item.coin && item.coin.toLowerCase().includes(searchTerm.toLowerCase());
        const isSUbType = item.subtype && item.subtype.toLowerCase().includes(searchTerm.toLowerCase());


        
        const isDateMatch = formattedDate && formattedDate.includes(searchTerm);
        const isIDMatch = item._id && item._id.includes(searchTerm);
        return isIPMatch || isIDMatch || isReasonMatch || isDateMatch || isSUbType;
    });

    const displayedData = filteredData.slice(
        
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            // handleInputChange();
        }
      };

    // const handleDownload = () => {
    //     // Convert the filteredData to a worksheet
    //     const ws = utils.json_to_sheet(formData.map(item => ({
    //         'ID': item._id,
    //         'IP Address': item.ipAddress,
    //         'Date': item.date,
    //         'Price': item.price,
    //         'Quantity': item.quantity,
    //         'Amount': item.quantity * item.price,
    //         'Status': item.status,
    //     })));

    //     // Create a new workbook and add the worksheet
    //     const wb = utils.book_new();
    //     utils.book_append_sheet(wb, ws, 'Deposite History');

    //     // Write the workbook to an Excel file
    //     writeFile(wb, 'ultrapro_user_deposit_history_data.xlsx');
    // };

    const columns = [
        {
            name: 'S.No',
            selector:(row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true,
            maxWidth:'2%'

        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            maxWidth:'50%'
        },
        
        {
            name: 'Coin Name',
            selector: row => row.coin,
            sortable: true,
            maxWidth:'50%'
        },
        {
            name: 'Wallet ID',
            selector: row => row.id,
            sortable: true,
             maxWidth:'10%'
        },
        {
            name: 'ParentChain Name',
            selector: row => row.parentchain,
            sortable: true
        },
        {
            name: 'Type',
            selector: row => row.walletType,
            sortable: true
        },
        {
            name: 'Date & Time',
            selector: row =>row.createdAt
            ? `${utcToNormalTime(row.createdAt).date} ${utcToNormalTime(row.createdAt).time}`
            : "-",
            sortable: true
        },
        // {
        //     name: 'Action',
        //     cell: row => (
        //         <div className="d-flex gap-2">
        //             <div className="remove">
        //                 {/* <button
        //                     onClick={() => handleDelete(row)}
        //                     className="btn btn-sm btn-danger remove-item-btn"
        //                     data-bs-toggle="modal"
        //                     data-bs-target="#deleteRecordModal"
        //                 >
        //                     Remove
        //                 </button> */}
        //                 <span  onClick={() => handleDelete(row)} className="mdi mdi-trash-can-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></span>
        //             </div>
        //         </div>
        //     ),
        //     ignoreRowClick: true,
        //     // allowOverflow: true,
        //     button: true,
        // },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Wallet Management" breadcrumbItem="Wallet List" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">

                                                <form className="app-search d-block">
                                                    <div className="position-relative">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search..."
                                                            onChange={handleInputChange}
                                                            onKeyPress={handleKeyPress}

                                                        />
                                                        <span className="ri-search-line"></span>
                                                    </div>
                                                </form>

                                            </Col>
               
                    
                                            <Col className="col-sm">

                                                <div className="d-flex justify-content-sm-end">
                                                {(adminType === 1 || adminInfo?.permissions.some(data => 
                                                data.module === "Wallet Management" && data.actions.includes(1))) ? (
                                                    <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                                                     ):null}
                                                    {/* <Button color="info" className="add-btn mx-2" onClick={handleDownload} id="create-btn"><i className="mdi mdi-download-outline"></i> Download</Button> */}

                                                    {/* <Button color="soft-danger"
                                                       
                                                    ><i style={{ fontSize: '15px' }} className="mdi mdi-download-circle-outline"></i>Download</Button> */}

                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                        <DataTable
                                                columns={columns}
                                                data={displayedData}
                                                paginationServer
                                                paginationTotalRows={filteredData.length}
                                                progressPending={loading}
                        
                                                progressComponent={<div className="py-3">Loading...</div>}
                                                oDataComponent={<div className="py-3">No records found</div>}
                                                fixedHeader
                                                persistTableHead={true}
                                               
                                            />
                                     
                                            
                                           
                                        
                                        </div>
                                   

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                              
                                                {displayedData.length > 0 ? (
                                                 



                                                        <div className="pagination pagination-rounded mb-0">
                                                            <ReactPaginate
                                                                previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                                nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageClick}
                                                                containerClassName={"pagination"}
                                                                activeClassName={"active"}
                                                                pageLinkClassName={"page-link"}
                                                                breakLinkClassName={"break-link"}
                                                                disabledClassName={"disabled"}
                                                                initialPage={currentPage}
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                                // disableInitialCallback={true}
                                                                renderLink={(props) => {
                                                                    if (
                                                                        props.page === "..." &&
                                                                        props.pageCount > props.pageRangeDisplayed
                                                                    ) {
                                                                        return (
                                                                            <a
                                                                                {...props}
                                                                                onClick={() =>
                                                                                    handlePageClick({
                                                                                        selected: pageCount - 1,
                                                                                    })
                                                                                }
                                                                            >
                                                                                {pageCount}
                                                                            </a>
                                                                        );
                                                                    }
                                                                    return <a {...props} aria-label="Link description" />;
                                                                }}
                                                            />
                                                        </div>
                                                
                                                ):("")}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>





                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add Networks </ModalHeader>
                <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false
                    }}
                >
                    <ModalBody>

        
                        <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">Coin Name</label>
                            <Input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Coin Name"
                                name="coinName"
                                // readOnly
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.coinName || ""}
                                invalid={validation.touched.coinName && validation.errors.coinName}
                            />
                            {validation.touched.coinName && validation.errors.coinName && (
                                <FormFeedback type="invalid">{validation.errors.coinName}</FormFeedback>
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">Clod Provider Name</label>
                            <Input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Network Name"
                                name="cloudProvider"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.cloudProvider || ""}
                                disabled
                                // style={{ textTransform: 'uppercase' }}
                                invalid={validation.touched.cloudProvider && validation.errors.cloudProvider}
                            />
                            {validation.touched.cloudProvider && validation.errors.cloudProvider && (
                                <FormFeedback type="invalid">{validation.errors.cloudProvider}</FormFeedback>
                            )}
                        </div>
                        {/* <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">Deposit Wallet Name</label>
                            <Input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Network Name"
                                name="depositWalletId"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.depositWalletId || ""}
                                // style={{ textTransform: 'uppercase' }}
                                invalid={validation.touched.depositWalletId && validation.errors.depositWalletId}
                            />
                            {validation.touched.depositWalletId && validation.errors.depositWalletId && (
                                <FormFeedback type="invalid">{validation.errors.depositWalletId}</FormFeedback>
                            )}
                        </div> */}
                        <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Wallet Type</label>
                                <select
                                    id="status-field"
                                    
                                    name="walletType"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.walletType || ""}
                                    className={`form-control ${validation.touched.walletType && validation.errors.walletType ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select NetWork Type</option>
                                    <option value="1">Withdraw</option>
                                    <option value="0">Deposit</option>
                                </select>
                                {validation.touched.walletType && validation.errors.walletType && (
                                    <FormFeedback type="invalid">{validation.errors.walletType}</FormFeedback>
                                )}
                            </div>
                        {/* <div className="mb-3">
                            <label htmlFor="phone-field" className="form-label">Reason</label>
                            <Input
                                type="text"
                                id="phone-field"
                                name="reason"
                                className="form-control"
                                placeholder="Enter Reason"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.reason || ""}
                                invalid={validation.touched.reason && validation.errors.reason}
                            />
                            {validation.touched.reason && validation.errors.reason && (
                                <FormFeedback type="invalid">{validation.errors.reason}</FormFeedback>
                            )}
                        </div> */}





                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                            {/* <button  type="submit" className="btn btn-success" id="add-btn">Add Customer</button> */}
                            <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <Loader/>
                                
                                ) : ("Submit")}

                            </button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>

            {/* Remove Modal */}
            {/* <Modal isOpen={modal_delete} className="modal zoomIn" id="deleteRecordModal" centered>
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-3 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                onClick={confirmDelete}
                                disabled={deleteLoad}
                            >
                                {deleteLoad ? (
                                    <Loader/>
                                ) : ("Yes, Delete It!")}

                            </button>
                    </div>
                </ModalBody>
            </Modal> */}


        </React.Fragment>
    );
};

export default WalletCreate;

import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import { postData } from "../../../ApiService/AxiosInstance";
import { Apiservices } from "../../../ApiService/ApiServices";
import { handleNullValue } from "../../../helpers/function";
import { useParams } from "react-router-dom";

const UserAddress = ({ tabId }) => {
  document.title = "Ultrapro | User Address";
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState([]);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; 
  const pageCount = Math.ceil(totalPagination);
  const [copiedRow, setCopiedRow] = useState(null);

  const ipList = useCallback( async () => {
    const postForm = {
      acId: Number(id),
    };
    setLoading(true);
    try {
      const response = await postData(Apiservices.GetUserAddress, postForm);
      console.log(response,"8888888888888")
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  },[id]);

  useEffect(() => {
    if (tabId == 10) {
      ipList();
    }
  }, [tabId,ipList]);
 

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };

  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      acId: Number(id),
    };
   
    try {
      const response = await postData(Apiservices.GetUserAddress, postForm);
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCopy = (address, rowId) => {
    navigator.clipboard.writeText(address);
    setCopiedRow(rowId);
    setTimeout(() => {
      setCopiedRow(null);
    }, 2000);
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => currentPage * itemsPerPage + index + 1,
      sortable: true,
     grow:1
    },

    {
      name: "Currency",
      selector: (row) => handleNullValue(row.coin),
      sortable: true,
      grow:1
    },

    {
      name: "Address",
      selector: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{row.address}</span>
          <i
            className="fas fa-copy"
            onClick={() => handleCopy(row.address, row.id)} // Pass the row ID to track the specific row
            style={{
              marginLeft: "10px",
              cursor: "pointer",
              color: copiedRow === row.id ? "green" : "#000", // Change color for the copied row
            }}
          />
          {copiedRow === row.id && <span style={{ marginLeft: "10px", color: "green" }}>Copied</span>}
        </div>
      ),
      sortable: true,
      grow:4
    },
  ];


  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={formData}
                paginationServer
                paginationTotalRows={formData?.length}
                progressPending={loading}
                persistTableHead={true}
                progressComponent={<div className="py-3">Loading...</div>}
                noDataComponent={<div className="py-3">No records found</div>}
                fixedHeader={true}
              />
            </div>
            {loading || formData.length == 0 ? (
              ""
            ) : (
              <div className="d-flex justify-content-end mt-3">
                <div className="pagination pagination-rounded mb-0">
                  <ReactPaginate
                    previousLabel={<i className="mdi mdi-chevron-left"></i>}
                    nextLabel={<i className="mdi mdi-chevron-right"></i>}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageLinkClassName={"page-link"}
                    breakLinkClassName={"break-link"}
                    disabledClassName={"disabled"}
                    initialPage={currentPage}
                    disableInitialCallback={true}
                    forcePage={currentPage}
                    renderLink={(props) => {
                      if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                        return (
                          <a
                            {...props}
                            onClick={() =>
                              handlePageClick({
                                selected: pageCount - 1,
                              })
                            }
                          >
                            {pageCount}
                          </a>
                        );
                      }
                      return <a {...props} aria-label="Link Description" />;
                    }}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default UserAddress;

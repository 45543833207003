// export const Apiservices = {
//     BACKEND_LOCALHOST_SERVER: process.env.REACT_APP_BACKEND_SERVER,
//     ADMIN_IP_BLOCK_SITE: '/ipcheck',

//     BACKEND_ADMIN_LOGIN: '/comeIn',
//     ADMIN_OTP_SEND: '/comeInOtp',
//     ADMIN_USER_LIST:'/userList',
//     ADMIN_OTP_VERIFY: '/comeIncheck',    
//     ADMIN_IP_BLOCK_LIST: '/ipList',
//     ADMIN_IP_BLOCK_LIST_ADD: '/ipAdd',
//     ADMIN_IP_BLOCK_LIST_DELETE: '/ipDelete',
// }
export const Apiservices = {
    // BACKEND_LOCALHOST_SERVER: process.env.REACT_APP_BACKEND_SERVER,
    BACKEND_LOCALHOST_SERVER: process.env.REACT_APP_BACKEND_SERVER,
    BACKEND_CHAT_API_SERVER: process.env.REACT_APP__CHAT_API_BACKEND_SERVER,
    BACKEND_CHAT_SOCKET_BACKEND_SERVER: process.env.REACT_APP__CHAT_SOCKET_BACKEND_SERVER,
    ADMIN_ADD:'/auth/createLogin',
    BACKEND_ADMIN_LOGIN: '/auth/adminLogin',
    ADMIN_IP_BLOCK_SITE: '/auth/ipAddressChecking',
    USER_LIST: '/account/userList',
    DOWNLOAD: '/account/download',
    USER_OVERALL_DASHBOARD: '/account/getOverAllUsers',
    ADMIN_OTP_SEND: '/admin/request-otp',
    ADMIN_OTP_VERIFY: '/admin/verify-otp',
    API_SITE_API: 'siteMenu/update',
    AGENT_LOGIN:'/adminApi/agentStatus',
    AGENT_LOGOUT:'/adminApi/agentLogout',
    
    
    ADMIN_LOGIN_COUNT:'/admin/loginAttempts',
    
    ADMIN_LOGIN_ACTIVITES:'/admin/adminLoginDetails',

ADMIN_BLOCK_UNBLOCK:'/auth/blockUnblock',
ADMIN_CHANGE_PASSWORD:'/auth/changeAdminPassword',




    ADMIN_LIST: '/admin/adminUserList',
    ADMIN_IP_WHITE_LIST_ADD: '/whitelist/create',
    ADMIN_IP_WHITE_LIST: '/whitelist/list',
    ADMIN_IP_WHITE_LIST_EDIT: '/whitelist/update',
    ADMIN_IP_WHITE_LIST_DELETE: '/whitelist/delete',


    USER_SEARCH_LIST: '/account/userSearch',
    USER_BALENCE_LIST: '/account/userBalance',
    USER_COIN_LIST: '/coins/coinlist',
    USER_COIN_UPDATE: '/coins/editCoin',
    USER_COIN_CREATE: '/coins/createCoin',
    USER_COIN_DELETE: '/coins/deleteCoin',
    USER_BONUS: '/transaction/refferalBonusHistory',
    USER_RESEND_MAIL: '/admin/resendAccountActivationEmail',
    USER_RESEND_OTP: '/admin/resendLogin',
    USER_WELCOME_BONUS: '/admin/welcomeBonus',
    USER_REFERRAL_BONUS: '/admin/referralBonus',
    USER_VERIFY_BY_ADMIN: '/admin/verifyMailByAdmin',
    // USER_VERIFY_PRO_TRADERS: '/admin/proUsers',
    REGISTERED_COUPON: '/auth/coupon',



    USER_TRADE_PAIR_LIST: '/tradePairs/tradePairsList',
    USER_TRADE_EDIT_LIST: '/tradePairs/update',
    USER_TRADE_ADD: '/tradePairs/createPair',
    TRADE_NOTES:'/tradePairs/tradeDetails',


    USER_SWAP_PAIR_LIST: '/swapPairs/swapPairsList',
    USER_SWAP_EDIT_LIST: '/swapPairs/update',
    USER_SWAP_ADD: '/swapPairs/createPair',


    

    USER_IP_BLOCK_LIST: '/ip/ipList',
    USER_IP_BLOCK_ADD: '/ip/ipAdd',
    USER_IP_BLOCK_DELETE: '/ip/ipDelete',


    
// WITHDRAW SETTINGS  
    WITHDRAW_SETTING_API: '/withdrawal/withdrawal-settings-list',
    WITHDRAW_CREATE_API: '/withdrawal/withdrawal-settings-update',
    WITHDRAW_LOCKED:'/withdrawal/withdrawLock',

    WITHDRAW_AUTOMATIC_LOCKED:'/withdrawal/changeAutomatic',



    
    GET_WITHDRAW_LOCKED_USER:'/',
    DEPOSIT_SETTING_API: '/deposit/depositSettingsList',
    DEPOSIT_CREATE_API: '/deposit/updateDepositSettings',


// DEPOSIT CRYPTO HISTORY
DEPOSIT_CRYPTO_COMPLETED_HISTORY: '/deposit/listOfDeposit',

// DEPOSIT DOWNLOAD
DEPOSIT_DOWNLOAD_HISTORY: '/deposit/downloadDepositHistory',





// CRYPTO WITHDRAW
    WITHDRAW_CRYPTO_PENDING_HISTORY: '/withdrawal/pendingWithdraw',
    WITHDRAW_CRYPTO_COMPLETED_HISTORY: '/withdrawal/withdrawCompleted',
    WITHDRAW_CRYPTO_REJECTED_HISTORY: '/withdrawal/withdrawRejected',
    WITHDRAW_CRYPTO_CANCELED_HISTORY: '/withdrawal/withdrawCancelled',

    WITHDRAW_CRYPTO_APPROVE_USER: '/withdrawal/authorizationSignature',
    WITHDRAW_CRYPTO_APPROVE_OTP: '/withdrawal/requestOtp',

// FIAT WITHDRAW
    WITHDRAW_FIAT_PENDING_HISTORY: '/withdrawal/fiatWithdrawPending', 
    WITHDRAW_FIAT_COMPLETED_HISTORY: '/withdrawal/fiatWithdrawCompleted',
    WITHDRAW_FIAT_REJECTED_HISTORY: '/withdrawal/fiatWithdrawRejected',
    WITHDRAW_FIAT_APPROVE_USER: '/withdrawal/fiatAuthorizationSignature',
    WITHDRAW_FIAT_APPROVE_OTP: '/withdrawal/requestForFiatOtp',
    WITHDRAW_UNLOCK_BYSUPERADMIN_LOCKED:'/withdrawal/withdrawUnLock',


// USER BANK
    USER_BANK_PENDING_HISTORY: '/admin/bankDetailsPending',
    USER_BANK_COMPLETED_HISTORY: '/admin/bankDetailsApproved',
    USER_BANK_REJECTED_HISTORY: '/admin/bankDetailsRejected',
    USER_BANK_APPROVE_USER: '/admin/approveUserBank',
    USER_BANK_APPROVE_OTP: '/admin/requestBankOtp',
    USER_BANK_CANCELLED_HISTORY: '/admin/userCancelledBankList',
    USER_BANK_DELETE: '/admin/deleteUserBank',
    
// NETWORK
    NETWORK_LIST: '/networks/list',
    NETWORK_DELETE: '/networks/remove',
    NETWORK_CREATE: '/networks/create',
    NETWORK_UPDATE: '/networks/update',

    COIN_TOKEN_NETWORK_LIST: '/networks/coinNetworkList',


// WALLET
    WALLET_LIST: '/deposit/listOfWallets',
    WALLET_CREATE: '/deposit/createWallets',

    
//FIAT DEPOSIT


   FIAT_DEPOSIT_PENDING_HISTORY:'/deposit/fiatDepositPending',
   FIAT_DEPOSIT_COMPLETED_HISTORY:'/deposit/fiatDepositCompleted',
   FIAT_DEPOSIT_REJECTED_HISTORY:'/deposit/fiatDepositRejected',
   FIAT_DEPOSIT_APPROVED:'/deposit/fiatDepositApproval',
   FIAT_DEPOSIT_REQUEST_OTP:'/deposit/requestForFiatDepositOtp',
   DEPOSIT_LOCKED:'/deposit/depositLock',
   FIAT_PENDING_DEPOSIT_DOWNLOAD:'/deposit/downloadfiatDepositPending',
   FIAT_DEPOSIT_UNLOCK_SUPERADMIN:"/deposit/depositUnLock",
   FIAT_DEPOSIT_REJECTED_HISTORY_DOWNLOAD:"/deposit/downloadfiatDepositRejected",
   FIAT_DEPOSIT_COMPLETED_HISTORY_DOWNLOAD:"/deposit/downloadfiatDepositCompleted",
    

    
    



    // TRANSACTION HISTORY

    TRANSACTION_HISTORY: '/transaction/overAllBonusHistory',


    // SWAP HISTORY

    SWAP_HISTORY: '/swaphis/swap-history',
    SWAP_HISTORY_DOWNLOAD: '/swaphis/swap-history-download',


    // USER FILTER
    USER_VERIFIED_LIST: '/account/userVerifiedData',
    USER_NOT_VERIFYED_LIST: '/account/userNotVerifiedUser',
    USER_BLOCKED_LIST: '/account/blockedUsers',
    USER_EDIT_LIST: '/account/editUser',




    // KYC FILTER
    KYC_TOTAL: '/account/totalKycs',
    KYC_VERIFIED: '/account/kycsVerified',
    KYC_PENDING: '/account/pendingKyc',
    KYC_REJECTED: '/account/rejectedKyc',
    KYC_NOT_UPLOADED: '/account/kycNotUploaded',

    KYC_OTP_SEND: '/kyc/kycOtp',
    KYC_OTP_VERIFY: '/kyc/updateKycStatus',


    
    


// DAILY UPDATES
TODAY_USERS:'/account/todaySignUpUsers',

USER_BLOCK_UNBLOCK:'/admin/blockClients',
// WELLCOME_BONUS:'/admin/welcomeBonus',





    DepositeSettings_AddNetwork:'/deposit/coinCreate',
    GetCoinDetails:'/deposit/coinGet',
    getUserReferralList:'/user/filterUsedUserRefferalCode',
    getUserReferralUserList:'/ref/referralUsersStatus',
    UserReferralTen:'/referralHistory/registerReferralHistory',


    
    DepositeSettings_UpdateNetwork:'/deposit/coinUpdate',
    siteSettingsCreate:"/siteMenu/create",
    siteSettingsUpdate:"/siteMenu/update",
    getSiteSettings:"/siteMenu/getSiteSettings",

    GetBankList:'/adminBank/list',
    AddBankDetails:'/adminBank/create',
    UpdateBankDetails:'/adminBank/update',
    DeleteBankDetails:'/adminBank/delete',

    AddSession:'/sessionClass/create',
    UpdateSession:'/sessionClass/update',
    SessionList:'/sessionClass/classList',
    DeleteSession:'/sessionClass/delete',


    EmailTemplateList:'/emailTemplate/emailList',
    EmailTemplateUpdate:'/emailTemplate/update',
    EmailTemplateCreate:'/emailTemplate/create',


    
    StakingOffersList:'/stake/listStakeOffer',
    StakingOffersCreate:"/stake/addStakeOffer",
    StakingOffersUpdate:'/stake/updateStakeOffer',
    StakingOffersDelete:'/stake/deleteStakeOffer',
    UserStakingList: '/stake/stakeList',
    UserStakingDownload:'/stake/downloadStakeList',
    GetSingleStake:'/stake/getOneStake',
    GetCoinData:'/coins/specificCoin',
    stakeRedeemHistory:'/stake/redeem',
    stakeRedeemDownload:'/stake/downloadRedeemList',

    // Flexible Stake Apis
    UserListFlexibleStack:'/flexibleStaking/stackHistory',
    UserBlockUpdate:'/flexibleStaking/blockUpdate',
    flexibleStackClaimReward: '/flexibleStaking/rewardsHistory',
    flexibleReferralStackClaimReward: '/flexibleStaking/referralRewardsHistory',
    
    // scheme  api
    AddFlexibleStakeSchem:'/flexibleStaking/scheme/create',
    FlexibleStackSchemeList:'/flexibleStaking/scheme/list',
    FlexibleSchemeUpdate : '/flexibleStaking/scheme/update',
    FlexibleSchemeDelete:'/flexibleStaking/scheme/delete',

    // plan
    AddFlexiStakePlan:'/flexibleStaking/plan/create',
    FlexibleStakingPlan: '/flexibleStaking/plan/list',
    FlexibleStakeGetSingle:'/flexibleStaking/plan/single',
    FlexibleStakePlanUpdate : '/flexibleStaking/plan/update',
    FlexibleStakePlanDelete:'/flexibleStaking/plan/delete',
    FlexiFirstUSDTAmount:'/flexibleStaking/plan/validate',
// STAKING FILTER
USER_WITHOUT_KYC:'/stake/userStakingWithoutKyc',
USER_WITHOUT_KYC_DOWNLOAD:'/stake/downloadUserWithoutKyc',
USER_WITHOUT_WITHDRAW:'/stake/userStakingWithoutWithdraw',
USER_COMPLETED_KYC_WITHDRAW:'/stake/userStakingAllCompleted',




    AdminUsersPasswordUpdate:'/auth/editAdminPass',
    AdminUserDelete:'/auth/deleteAdmin',
    GetUserDetails:"/account/getUserById",
    OfferContentCreateApi:'/homePage/create',
    OfferContentUpdateApi:'/homePage/update',
    OfferContentgetApi:'/homePage/getContent',
    AdminUserById:"/admin/activeStatusHistory",
    AdminTransaction:'/admin/adminTransactions',
    AdminMoveWallet:'/admin/adminTransactionsUpdate',
    GetUserAddress:'/account/getUserDepositAddress',
    SingleUserOwnStakeHistory: '/account/ownStackHistory',
    SingleUserOthersStakeHistory: '/account/otherStackHistory',

    SiteMaintenanceAndroidCreate:'/siteMenu/createAndroidSettings',
    SiteMaintenanceAndroidUpdate:'/siteMenu/updateAndroidSettings',

    SiteMaintenanceIOSCreate:'/siteMenu/createIosSettings',
    SiteMaintenanceIOSUpdate:'/siteMenu/updateIosSettings',

     SiteMaintenanceWebCreate:'/siteMenu/createWebSettings',
    SiteMaintenanceWebUpdate:'/siteMenu/updateWebSettings',
    siteSettingsCreateApi:'/siteMenu/offersSettings',
    siteSettingsUpdateApi:'/siteMenu/updateOffersSettings',
    siteSettingsListApi:'/siteMenu/offersSettingsList',

    webMaintenaceList:'/siteMenu/listWebSiteDetails',
    AndroidMaintenaceList:'/siteMenu/listAndroidSitedetails',
    IOSMaintenaceList:'/siteMenu/listIosSitedetails',

    contactuscreate:"",
    contactusUpdate:"",
    contactusGet:"",

    siteWhiteIPAdd:'/whitelist/create',
    siteWhiteIPList:'/whitelist/list',
    siteWhiteIPDelete:'/whitelist/delete',
    siteWhiteIPUpdate:'/whitelist/update',

    GetTradeHistory:"/coins/coinOrders",
    GetTradeOpenOrders:"/coins/openOrderActive",
    GetTradeCompletedOrders:"/coins/openOrderFilled",
    GetTradeCancelledOrders:"/coins/openOrderCancelled",
    DeleteTradePair:'/tradePairs/deletePair',



    userWithdrawOtpCountUpdate:'/admin/updateWithdrawOtp',

    UserLoginActivities:'/admin/userLoginActivies',

    ExpoUsersList:"/expoUser/list",

    DiscoverList:'/discover/discoverList',
    DiscoverAdd:'/discover/create',
    DiscoverUpdate:'/discover/update',
    DiscoverDelete:'/discover/delete',

    TeamsList:"/teams/teamsList",
    TeamsAdd:"/teams/create",
    TeamsUpdate:"/teams/update",
    TeamsDelete:"/teams/delete",

    CompareExchangesList:"/compareExchanges/compareList",
    CompareExchangesAdd:"/compareExchanges/create",
    CompareExchangesUpdate:"/compareExchanges/update",
    CompareExchangesDelete:"/compareExchanges/delete",

    ProbinarList:"/probinar/probinarList",
    ProbinarAdd:"/probinar/create",
    ProbinarUpdate:"/probinar/update",
    ProbinarDelete:"/probinar/delete",

    // task api video content
    taskView:"/customerTask/getTask",
    taskAdd:"/customerTask/createTask",
    taskUpdate:"/customerTask/updateTask",
    taskDelete:"/customerTask/deleteTask ",
    taskHistory : "/customerTask/taskHistory",

    VotingList:"/voting/list",
    VotingUpdate:'/voting/update',
    VotingOtp:"/voting/otp",
    PendingVotesList:"/voting/votingpending",
    RejectedVotesList:'/voting/votingRejected',
    ApprovedVotesList:"/voting/votingCompleted",

    
    CliamList:"/claim/claimList",
    ClaimAdd:"/claim/create",
    ClaimUpdate:"/claim/update",
    ClaimDelete:"/claim/delete",


    COMUNITY_LIST:"/community/communityList",
    COMUNITY_ADD:"/community/create",
    COMUNITY_UPDATE:"/community/update",
    COMUNITY_DELETE:"/community/delete",

    MenuList:"/menu/list",
    MenuAdd:"/menu/create",
    MenuUpdate:"/menu/update",
    MenuDelete:"/menu/delete",
    AdminUserPermissionUpdate:'/auth/updatePermissions',
    GetSingleAdminUSer:"/auth/permissionList",



    CryptoNewsList:"/news/newsContentList",
    CryptoNewsAdd:"/news/create",
    CryptoNewsUpdate:"/news/update",
    CryptoNewsDelete:"/news/delete",
    CryptoNewsAdminStatus: "/news/adminStatusDetails",

    withDrawWalletId:'/withdrawal/withdrawal-wallettxid-update',

    CHAT_LIST:"/adminApi/chatList",
    ADMIN_CHAT_LIST:"/adminApi/submitChat",
    CHAT_STATUS_UPDATE: "/adminApi/chatStatusUpdate",
    CHAT_END: "/adminApi/chatEnd",
    CHAT_LOCK:"/adminApi/lockUser",
    LOCKED_CHAT_LIST:'/adminApi/lockedUserList',
    END_CHAT_LIST:'/adminApi/endChatList',
    CHAT_UNLOCK:'/adminApi/unLockUser',
    GET_ACTIVE_AGENT_LIST:'/adminApi/activeAgent',
    TRANSFER_CHAT:'/adminApi/transferChat',
   


    AGENT_ADD:'/adminApi/agentCreate',
    AGENT_LIST:'/adminApi/agentList',
    AGENT_UPDATE:'/adminApi/agentUpdate',
    AGENT_DELETE:'/adminApi/agentDelete',


    CHAT_MSG_CONTENT_ADD:'/message/create',
    CHAT_MSG_CONTENT_LIST:'/message/list',
    CHAT_MSG_CONTENT_UPDATE:'/message/update',
    CHAT_MSG_CONTENT_DELETE:'/message/delete',
    
    PUSH_NOTIFICATION_ADD:'/send-notification',
    // SOCIAL MEDIA BONUS API
    SOCIALMEDIA_LIST:'/socialMedia/list',
    SOCIALMEDIA_UPDATE_STATUS:'/socialMedia/mediaBonusStatus',
    SOCIALMEDIA_HISTORY: '/socialMedia/mediaBonusHistory',

    // wallet-type api
    WALLET_TYPE_LIST:'/walletType/list',
    WALLET_TYPE_UPDATED: '/walletType/update',


    TRANSACTION_TALLY_LIST: '/tallyDetails/list',
    TALLY_USER_BALANCE_UPDATED: '/tallyDetails/userBalanceUpdate',
    Tally_UPDATED_REQUEST_OTP: '/tallyDetails/tallyUpdateOtp',

    GetUserTradeHistory:"/coins/openOrderFilledUser",


    TALLY_BALANCE_CALCULATION: '/tallyBalance/facet',


    SPOT_BALANCE:'/account/spotBalance',
    REWARD_BALANCE:'/account/rewardBalance',
    REFERRAL_BALANCE:'/account/referralBalance',


    ADMIN_UPDATE_TRANSACTION_MODIFIED:'/tallyDetails/adminUpdateTransaction',

    ADMIN_UPDATE_ORDER_MODIFIED:'/tallyDetails/adminUpdateTransactionOrder',
}






// Token
export const TokenName = {
    AUTH_TOKEN: 'authentication',
    BANK_ACCOUNT_NAME_ADD_TOKEN: "T1gwM0U4VWdKRTJEQ01DWDhjLjgyYmZkMDFhZDlmYTg1YWQ3ODNkZTRiZTc0OGEyODNhOmRlMDkzMDFkMGRiZDEzNmMyOTNjZTgwYzIzMjRiNTI2NTEwMmU0Y2ZmYjg2Y2UxOA==",


}


export const SecretKey = {
    SECRET_KEY: 'mySjksdnfjknsdkgnkdfngkfjdiosjduifhuidVRNKJRuiwergwehurw23432435346jbj!@#%#*/*/*&$^'
}





export const Boolian = {

    OSWEB:'0',
    OSANDROID:'1',
    OSIOS:'2',


    PENDING_WITHDRAW: 0,
    COMPLETED_WITHDRAW: 1,
    FAILED_WITHDRAW: 2,
    BLOCKED_WITHDRAW: 3,



    PENDING_USER: 0,
    USER_VERIFIED: 1,
    USER_BLOCKED: 2,
    WITHDRAW_BLOCKED:1,

    USER_BANK_PENDING:0,
    USER_BANK_APRROVE:1,
    USER_BANK_REJECTED:2,
    USER_BANK_CANCELLED:3,
    


    UNBLOCK_ACBLOCK: 0,
    ACBLOCK_PASSWORD: 1,
    ACBLOCK_OTPBLOCKED: 2,
// 0-not yet started, 1-pending, 2-reject, 3-approved

    KYC_NOT_UPLOAD: 0,
    KYC_PENDING_NUMBER: 1,
    KYC_VERIFIED_NUMBER: 3,
    KYC_REJECTED: 2,

    ADMIN_BLOCKED:0,
    ADMIN_UNBLOCK:1,
    ADMIN_TEMP_BLOCKED:2,

    DISABLE: 0,
    ENABLE: 1,
    WALLET_TYPE_MANUAL: 0,
    WALLET_TYPE_AUTO:1,

    CLIENT_UNBLOCK_NUMBER: 0,
    CLIENT_LOGIN_BLOCK_NUMBER: 1,
    CLIENT_OTP_BLOCK_NUMBER: 2,

    CRYPTO_ENABLE : 1,
    CRYPTO_DISABLE : 0,
}

export const IPBLOCKED = {
    IPBLOCKED: 3
}

export const configServices = {
    CHAT_SOCKET_CONNECTION: process.env.CHAT_SOCKET_CONNECTION
}
import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Input,
  Card,
  CardBody,
  FormFeedback,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Form,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import { AdminContext } from "../../helpers/adminContext";

const SiteSettings = () => {
  document.title = "Ultrapro | Site Settings Management";
  const [getSiteSettings, setSiteSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contactusloading, setContactUsLoading] = useState(false);
  //Permission List 


  const { adminInfo } = useContext(AdminContext);
  const [adminType,setAdminType] = useState('')
  const formik = useFormik({
    initialValues: {
      sitename: "",
      copyRights: "",
      // userCount: "",
      bannerOffers: "",
      welcomebonus: "",
      welcomeReferalBonus: "",
      // inrValue: "",
      // coinAdjustment:"",
      twitter:"",
      instagram:"",
      facebook:"",
      siteLogo:"",
      medium:'',
      linkedin:"",
      telegram:"",
      youtube:"",
      gmail:"",
      votingamount:'',
      stakingPrice:'',
      tradestakeprice:"",
      // tradePriceValue_btc:'',
      // tradePriceValue_eth:'',
      tradePriceValue_bnb:'',
      tradePriceValue_trx:'',
      // unblocktime:"",
      // uprousdtpricechange:'',
      // uprousdttype:''
    },
    validationSchema: Yup.object({
      sitename: Yup.string().required("Site Name Required"),
      copyRights: Yup.string().required("Copy Rights Required"),
      // userCount: Yup.number().required("Required").positive("Must be positive").integer("Must be an integer"),
      bannerOffers: Yup.string().required("Banner Offers Required"),
      welcomebonus: Yup.string().required("welcome Bonus Required"),
      welcomeReferalBonus: Yup.string().required("welcome Referral Bonus Required"),
      // inrValue: Yup.string().required("Current INR value is Required"),
      // coinAdjustment: Yup.string().required("Upro Adjustment Value is Required"),
      twitter: Yup.string().required("Twitter URL is Required"),
      instagram: Yup.string().required("Instagram URL is Required"),
      facebook: Yup.string().required("Facebook URL is Required"),
      siteLogo: Yup.string().required("Site Logo is Required"),
      medium: Yup.string().required("Medium URL is Required"),
      linkedin: Yup.string().required("LinkedIn URL is Required"),
      telegram: Yup.string().required("Telegram URL is Required"),
      youtube: Yup.string().required("Youtube URL is Required"),
      gmail: Yup.string().required("Gmail is Required"),
      votingamount: Yup.number().required("Voting Amount Adjustment is Required").positive("Must be positive").integer("Must be an integer"),
      stakingPrice:  Yup.number().required("Staking Price is Required"),
      tradestakeprice:  Yup.number().required("Trade Price value is Required"),
      // tradePriceValue_btc:  Yup.number().required("Trade Price value (BTC) is Required"),
      // tradePriceValue_eth:  Yup.number().required("Trade Price value (ETH) is Required"),
      tradePriceValue_bnb:  Yup.number().required("Trade Price value (BNB) is Required"),
      tradePriceValue_trx:  Yup.number().required("Trade Price value (TRX) is Required"),
      // uprousdtpricechange:  Yup.number().required("UPRO USDT Price Change is Required"),
      // uprousdttype:Yup.number().required("UPRO USDT type is Required"),
      // unblocktime:Yup.string().required("Unblock Time is Required"),


      
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const data = {
          MaintenanceType:"offer_all",
          siteName: values.sitename,
          copyRights: values.copyRights,
          // userCount: values.userCount,
          bannerOffers: values.bannerOffers,
          welcomeBonus: values.welcomebonus,
          welcomeReferalBonus: values.welcomeReferalBonus,
          // inrValue: values.inrValue,
          // coinAdjustment: values.coinAdjustment,
          twitLink: values.twitter,
          instaLink: values.instagram,
          fbLink: values.facebook,
          siteLogo:values.siteLogo,
          linkedin:values.linkedin,
          gmail:values.gmail,
          youtube:values.youtube,
          medium:values.medium,
          telegram:values.telegram,
          votingAmount:values.votingamount,
          LoginOtpStatus: LoginOtpStatus === true ? 1 : 0,
          stakingPrice: values.stakingPrice,
          tradePriceValue:values.tradestakeprice,
          // tradePriceValue_btc:values.tradePriceValue_btc,
          // tradePriceValue_eth:values.tradePriceValue_eth,
          tradePriceValue_bnb:values.tradePriceValue_bnb,
          tradePriceValue_trx:values.tradePriceValue_trx,
          // uprousdtpricechange:values.uprousdtpricechange,
          // uprousdttype:values.uprousdttype
          // adminType: adminInfo?.adminType,
          // Permissions: adminInfo?.Permissions
          // unblocktime:values.unblocktime

        };
        // const response = await postData(Apiservices.siteSettingsCreateApi, data)
        const response = await postData(Apiservices.siteSettingsUpdateApi, data);
        if (response.status) {
          fetchData();
          // if(response.data[0].siteMaintenance){

          // }
          // setSiteMaintenanceStatus(response.data[0].siteMaintenance)
          toast.success(response.message);
          setLoading(false);
        } else {
          toast.error(response.message);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
  });
  const[contactuslist,setContactUslist] = useState([])
  useEffect(() => {
setAdminType(adminInfo?.adminType)
    fetchData();
    fetchcontactus()
  }, []);
  const fetchData = async () => {
    try {
      const response = await postData(Apiservices.siteSettingsListApi);
      if (response) {
        if (response.data.length !== 0) {
          setSiteSettings(response.data[0]);
          formik.setValues({
            sitename: response.data[0].siteName,
            copyRights: response.data[0].copyRights,
            // userCount: response.data[0].userCount,
            bannerOffers: response.data[0].bannerOffers,
            welcomeReferalBonus: response.data[0].welcomeReferalBonus,
            welcomebonus: response.data[0].welcomeBonus,
            // inrValue: response.data[0].inrValue,
            // coinAdjustment:response.data[0].coinAdjustment,
            facebook:response.data[0].fbLink,
            twitter:response.data[0].twitLink,
            instagram:response.data[0].instaLink,
            siteLogo:response.data[0].siteLogo,
            medium:response.data[0].medium,
            telegram:response.data[0].telegram,
            gmail:response.data[0].gmail,
            linkedin:response.data[0].linkedin,
            youtube:response.data[0].youtube,
            votingamount:response.data[0].votingAmount,
            stakingPrice: response.data[0].stakingPrice,
            tradestakeprice: response.data[0].tradePriceValue,
            // tradePriceValue_btc:response.data[0].tradePriceValue_btc,
            // tradePriceValue_eth:response.data[0].tradePriceValue_eth,
            tradePriceValue_bnb:response.data[0].tradePriceValue_bnb,
            tradePriceValue_trx:response.data[0].tradePriceValue_trx,
            // uprousdtpricechange:response.data[0].uprousdtpricechange,
            // uprousdttype: response.data[0].uprousdttype !== undefined ? String(response.data[0].uprousdttype) : "",



          });
        } else {
          setSiteSettings(response.data);
        }
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle fetch error
    }
  };
  const fetchcontactus = async () => {
    try {
      const response = await postData(Apiservices.contactusGet);
      if (response) {
        if (response.data.length !== 0) {
          setContactUslist(response.data[0]);
          formik.setValues({
            contactmail: response.data[0].contactMail,
            infomail: response.data[0].infoMail,
            supportmail: response.data[0].supportMail,
            listmail: response.data[0].listMail,
          
          });
        } else {
          setContactUslist(response.data);
        }
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle fetch error
    }
  };
  const Contactusformik = useFormik({
    initialValues: {
      contactmail: "",
      infomail: "",
      supportmail: "",
      listmail: "",
    },
    validationSchema: Yup.object({
      contactmail: Yup.string().required("Contact Mail is Required"),
      infomail: Yup.string().required("Info Mail is Required"),
      supportmail: Yup.string().required("Info Mail is Required"),
      listmail:Yup.string().required("List Mail is Required"),

    }),
    onSubmit: async (values) => {
      try {
        setContactUsLoading(true);
        const data = {
          // MaintenanceType:"offer_all",
          contactMail: values.contactmail,
          infoMail: values.infomail,
          supportMail: values.supportmail,
          listMail: values.listmail,
         
        };
        const response = await postData(Apiservices.contactuscreate, data)
        // const response = await postData(Apiservices.contactusUpdate, data);
        if (response.status) {
          fetchData();
          // if(response.data[0].siteMaintenance){

          // }
          // setSiteMaintenanceStatus(response.data[0].siteMaintenance)
          toast.success(response.message);
          setContactUsLoading(false);
        } else {
          toast.error(response.message);
          setContactUsLoading(false);
        }
      } catch (error) {
        console.error(error);
        setContactUsLoading(false);
      }
    },
  });
  const [LoginOtpStatus, setLoginOtpStatus] = useState(false);
  const LoginOtpStatusChange = (event) => {
    setLoginOtpStatus(event.target.checked);
    // isActive: activeMaintenanceStatus === true ? 1 : 0
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Settings" breadcrumbItem="Site Settings" />

          <Row>
            <Col lg={12} className="row">
              <Form onSubmit={formik.handleSubmit}>
               

                <Card
                  style={{ backgroundColor: "transparent", boxShadow: "unset" }}
                >
                  {/* <Row>
                    <div className="col-lg-12">
                      <Card>
                        <CardBody>
                          <h5 className="card-title">INR Settings</h5>
                          <p>UPRO Value INR Adjustment </p>
                          <Row> 
                              <Col md={6}>
                                <div className=" mb-3 mt-3">
                                  <label>Current INR Value</label>
                                  <Input
                                    type="number"
                                    name="inrValue"
                                    id="inrValue"
                                    className="form-control"
                                    value={formik.values.inrValue}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    autoComplete="off"
                                    invalid={
                                      formik.touched.inrValue &&
                                      !!formik.errors.inrValue
                                    }
                                  />

                                  <FormFeedback>
                                    {formik.errors.inrValue}
                                  </FormFeedback>
                                </div>
                                </Col>
                              <Col md={6}>

                                <div className=" mb-3 mt-3">
                                  <label>Upro Adjustment Value</label>
                                  <Input
                                    type="number"
                                    name="coinAdjustment"
                                    id="coinAdjustment"
                                    className="form-control"
                                    value={formik.values.coinAdjustment}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    autoComplete="off"
                                    invalid={
                                      formik.touched.coinAdjustment &&
                                      !!formik.errors.coinAdjustment
                                    }
                                  />

                                  <FormFeedback>
                                    {formik.errors.coinAdjustment}
                                  </FormFeedback>
                                </div>
                                </Col>
                          </Row>

                          <div></div>
                        </CardBody>
                      </Card>
                    </div>
                  </Row> */}
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                    
                        <FormGroup>
                          <Label for="sitename">Site Name</Label>
                          <Input
                            type="text"
                            name="sitename"
                            id="sitename"
                            className="form-control"
                            value={formik.values.sitename}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            invalid={
                              formik.touched.sitename &&
                              !!formik.errors.sitename
                            }
                          />
                          <FormFeedback>{formik.errors.sitename}</FormFeedback>
                        </FormGroup>
                      
                        {/* <FormGroup>
                          <Label for="userCount">User Count</Label>
                          <Input
                            type="text"
                            name="userCount"
                            id="userCount"
                            className="form-control"
                            autoComplete="off"
                            value={formik.values.userCount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.userCount &&
                              !!formik.errors.userCount
                            }
                          />
                          <FormFeedback>{formik.errors.userCount}</FormFeedback>
                          </FormGroup> */}
                              <FormGroup>
                          <Label for="welcomebonus">Welcome Bonus (USDT)</Label>
                          <Input
                            type="text"
                            name="welcomebonus"
                            id="welcomebonus"
                            className="form-control"
                            autoComplete="off"
                            value={formik.values.welcomebonus}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.welcomebonus &&
                              !!formik.errors.welcomebonus
                            }
                          />
                          <FormFeedback>
                            {formik.errors.welcomebonus}
                          </FormFeedback>
                        </FormGroup>
                            <FormGroup>
                          <Label for="welcomeReferalBonus">
                            Welcome Referral Bonus (USDT)
                          </Label>
                          <Input
                            type="text"
                            name="welcomeReferalBonus"
                            id="welcomeReferalBonus"
                            className="form-control"
                            autoComplete="off"
                            value={formik.values.welcomeReferalBonus}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.welcomeReferalBonus &&
                              !!formik.errors.welcomeReferalBonus
                            }
                          />
                          <FormFeedback>
                            {formik.errors.welcomeReferalBonus}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="tradestakeprice">
                          Stake Add Value
                          </Label>
                          <Input
                            type="text"
                            name="tradestakeprice"
                            id="tradestakeprice"
                            className="form-control"
                            autoComplete="off"
                            value={formik.values.tradestakeprice}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.tradestakeprice &&
                              !!formik.errors.tradestakeprice
                            }
                          />
                          <FormFeedback>
                            {formik.errors.tradestakeprice}
                          </FormFeedback>
                        </FormGroup>

                        {/* <FormGroup> */}
                          {/* <Label for="tradePriceValue_btc">
                           Trade Price value (BTC)
                          </Label>
                          <Input
                            type="text"
                            name="tradePriceValue_btc"
                            id="tradePriceValue_btc"
                            className="form-control"
                            autoComplete="off"
                            value={formik.values.tradePriceValue_btc}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.tradePriceValue_btc &&
                              !!formik.errors.tradePriceValue_btc
                            }
                          />
                          <FormFeedback>
                            {formik.errors.tradePriceValue_btc}
                          </FormFeedback> */}
                        {/* </FormGroup> */}
                        {/* <FormGroup> */}
                          {/* <Label for="tradePriceValue_eth">
                           Trade Price value (ETH)
                          </Label>
                          <Input
                            type="text"
                            name="tradePriceValue_eth"
                            id="tradePriceValue_eth"
                            className="form-control"
                            autoComplete="off"
                            value={formik.values.tradePriceValue_eth}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.tradePriceValue_eth &&
                              !!formik.errors.tradePriceValue_eth
                            }
                          />
                          <FormFeedback>
                            {formik.errors.tradePriceValue_eth}
                          </FormFeedback> */}
                        {/* </FormGroup> */}

                        <FormGroup>
                          <Label for="tradePriceValue_bnb">
                           Trade Price value (BNB)
                          </Label>
                          <Input
                            type="text"
                            name="tradePriceValue_bnb"
                            id="tradePriceValue_bnb"
                            className="form-control"
                            autoComplete="off"
                            value={formik.values.tradePriceValue_bnb}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.tradePriceValue_bnb &&
                              !!formik.errors.tradePriceValue_bnb
                            }
                          />
                          <FormFeedback>
                            {formik.errors.tradePriceValue_bnb}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="tradePriceValue_trx">
                           Trade Price value (TRX)
                          </Label>
                          <Input
                            type="text"
                            name="tradePriceValue_trx"
                            id="tradePriceValue_trx"
                            className="form-control"
                            autoComplete="off"
                            value={formik.values.tradePriceValue_trx}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.tradePriceValue_trx &&
                              !!formik.errors.tradePriceValue_trx
                            }
                          />
                          <FormFeedback>
                            {formik.errors.tradePriceValue_trx}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="sitename"> Voting Amount Adjustment (USDT) </Label>
                          <Input
                            type="text"
                            name="votingamount"
                            id="votingamount"
                            className="form-control"
                            value={formik.values.votingamount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            invalid={
                              formik.touched.votingamount &&
                              !!formik.errors.votingamount
                            }
                          />
                          <FormFeedback>{formik.errors.votingamount}</FormFeedback>
                        </FormGroup>
                          <FormGroup>
                          <Label for="bannerOffers">Banner Offers</Label>
                          <Input
                            type="text"
                            name="bannerOffers"
                            id="bannerOffers"
                            className="form-control"
                            autoComplete="off"
                            value={formik.values.bannerOffers}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.bannerOffers &&
                              !!formik.errors.bannerOffers
                            }
                          />
                          <FormFeedback>
                            {formik.errors.bannerOffers}
                          </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="userCount">Youtube URL</Label>
                          <Input
                            type="text"
                            name="youtube"
                            id="youtube"
                            className="form-control"
                            value={formik.values.youtube}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            invalid={
                              formik.touched.youtube &&
                              !!formik.errors.youtube
                            }
                          />
                          <FormFeedback>{formik.errors.youtube}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="userCount">Medium URL</Label>
                          <Input
                            type="text"
                            name="medium"
                            id="medium"
                            autoComplete="off"
                            className="form-control"
                            value={formik.values.medium}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.medium &&
                              !!formik.errors.medium
                            }
                          />
                          <FormFeedback>{formik.errors.medium}</FormFeedback>
                        </FormGroup>
                     
                      
                      
                      </Col>
                      <Col md={6}>
                            
                      <FormGroup>
                          <Label for="sitename">Site Logo</Label>
                          <Input
                            type="text"
                            name="siteLogo"
                            id="siteLogo"
                            className="form-control"
                            value={formik.values.siteLogo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            invalid={
                              formik.touched.siteLogo &&
                              !!formik.errors.siteLogo
                            }
                          />
                          <FormFeedback>{formik.errors.siteLogo}</FormFeedback>
                        </FormGroup>
                      <FormGroup>
                          <Label for="copyRights">Copy Rights</Label>
                          <Input
                            type="text"
                            name="copyRights"
                            id="copyRights"
                            className="form-control"
                            value={formik.values.copyRights}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.copyRights &&
                              !!formik.errors.copyRights
                            }
                          />
                          <FormFeedback>
                            {formik.errors.copyRights}
                          </FormFeedback>
                        </FormGroup>
                      
                        <FormGroup>
                          <Label for="userCount">Twitter URL</Label>
                          <Input
                            type="text"
                            name="twitter"
                            id="twitter"
                            className="form-control"
                            value={formik.values.twitter}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            invalid={
                              formik.touched.twitter &&
                              !!formik.errors.twitter
                            }
                          />
                          <FormFeedback>{formik.errors.twitter}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="userCount">Upro(Stack) Price</Label>
                          <Input
                            type="text"
                            name="stakingPrice"
                            id="stakingPrice"
                            className="form-control"
                            value={formik.values.stakingPrice}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            invalid={
                              formik.touched.stakingPrice &&
                              !!formik.errors.stakingPrice
                            }
                          />
                          <FormFeedback>{formik.errors.stakingPrice}</FormFeedback>
                        </FormGroup>
                        {/* <FormGroup>
                          <Label for="userCount">UPRO USDT Price Change</Label>
                          <Input
                            type="text"
                            name="uprousdtpricechange"
                            id="uprousdtpricechange"
                            className="form-control"
                            value={formik.values.uprousdtpricechange}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            invalid={
                              formik.touched.uprousdtpricechange &&
                              !!formik.errors.uprousdtpricechange
                            }
                          />
                          <FormFeedback>{formik.errors.uprousdtpricechange}</FormFeedback>
                        </FormGroup>

                      <div className="mb-3">
                      <Label for="duration">UPRO USDT Type</Label>
                              <select
                                id="status-field"
                                name="uprousdttype"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.uprousdttype || ""}
                                className={`form-control ${formik.touched.uprousdttype && formik.errors.uprousdttype ? "is-invalid" : ""}`}
                              >
                                <option value="" disabled>
                                  Select Type
                                </option>
                                <option value={0}>Free</option>
                                <option value={1}>Cost</option>
                              </select>
                              {formik.touched.uprousdttype && formik.errors.uprousdttype && <FormFeedback type="invalid"> {formik.errors.uprousdttype} </FormFeedback>}
                            </div> */}
                        <FormGroup>
                          <Label for="userCount">Instagram URL</Label>
                          <Input
                            type="text"
                            name="instagram"
                            id="instagram"
                            className="form-control"
                            value={formik.values.instagram}
                            onChange={formik.handleChange}
                            autoComplete="off"
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.instagram &&
                              !!formik.errors.instagram
                            }
                          />
                          <FormFeedback>{formik.errors.instagram}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="userCount">Facebook URL</Label>
                          <Input
                            type="text"
                            name="facebook"
                            id="facebook"
                            className="form-control"
                            autoComplete="off"
                            value={formik.values.facebook}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.facebook &&
                              !!formik.errors.facebook
                            }
                          />
                          <FormFeedback>{formik.errors.facebook}</FormFeedback>
                        </FormGroup>
                        
                        <FormGroup>
                          <Label for="userCount">LinkedIn URL</Label>
                          <Input
                            type="text"
                            name="linkedin"
                            id="linkedin"
                            className="form-control"
                            autoComplete="off"
                            value={formik.values.linkedin}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.linkedin &&
                              !!formik.errors.linkedin
                            }
                          />
                          <FormFeedback>{formik.errors.linkedin}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="userCount">Gmail</Label>
                          <Input
                            type="text"
                            name="gmail"
                            id="gmail"
                            className="form-control"
                            value={formik.values.gmail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            invalid={
                              formik.touched.gmail &&
                              !!formik.errors.gmail
                            }
                          />
                          <FormFeedback>{formik.errors.gmail}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="userCount">Telegram URL</Label>
                          <Input
                            type="text"
                            name="telegram"
                            id="telegram"
                            className="form-control"
                            value={formik.values.telegram}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.telegram &&
                              !!formik.errors.telegram
                            }
                          />
                          <FormFeedback>{formik.errors.telegram}</FormFeedback>
                        </FormGroup>
                        {/* <FormGroup>
                          <Label for="userCount">Unblock Time</Label>
                          <Input
                            type="text"
                            name="unblocktime"
                            id="unblocktime"
                            className="form-control"
                            value={formik.values.unblocktime}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.unblocktime &&
                              !!formik.errors.unblocktime
                            }
                          />
                          <FormFeedback>{formik.errors.unblocktime}</FormFeedback>
                        </FormGroup> */}
                         {/* <FormGroup>
                          <Row> 
                            <Col md={6}>
                          <Label for="userCount">Login Without OTP</Label>
                            </Col>
                            <Col md={6}>
                            <div className="form-check form-switch form-switch-md d-flex justyfy-content-center align-items-center">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="customSwitchsizemd"
                              checked={LoginOtpStatus}
                              onChange={LoginOtpStatusChange}
                              // checked={row.sessionIsActive === 1}
                              // onChange={() => handleToggle(row, "sessionIsActive")}
                              // onChange={() =>handleToggleModel(row,Apiservices.UpdateSession)}
                              // onChange={() => handleUpdateStatusClick(row)}
                              disabled={loading}
                            />
                          </div>
                            </Col>
                          </Row>
                      </FormGroup> */}
                       
                      </Col>
                    </Row>
                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                    data.module === "Site Settings" && data.actions.includes(3))) ? (
                    <div className="text-end">
                      {loading ? (
                        <Button color="primary">Loading...</Button>
                      ) : (
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                      )}
                    </div> 
                   ) :null} 
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
          {/* <Row>
          <h5>Contact Us</h5>

            <Card className="mt-2">
              <CardBody>
                <Form onSubmit={Contactusformik.handleSubmit}>
                <Row >
              <Col md={6}>
                       <FormGroup>
                          <Label for="contactmail">Contact Mail</Label>
                          <Input
                            type="text"
                            name="contactmail"
                            id="contactmail"
                            className="form-control"
                            value={Contactusformik.values.contactmail}
                            onChange={Contactusformik.handleChange}
                            onBlur={Contactusformik.handleBlur}
                            placeholder="Enter Contact mail"
                            autoComplete="off"
                            invalid={
                              Contactusformik.touched.contactmail &&
                              !!Contactusformik.errors.contactmail
                            }
                          />
                          <FormFeedback>{Contactusformik.errors.contactmail}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="supportmail">Support Mail</Label>
                          <Input
                            type="text"
                            name="supportmail"
                            id="supportmail"
                            className="form-control"
                            value={Contactusformik.values.supportmail}
                            onChange={Contactusformik.handleChange}
                            onBlur={Contactusformik.handleBlur}
                            placeholder="Enter Support mail"

                            autoComplete="off"
                            invalid={
                              Contactusformik.touched.supportmail &&
                              !!Contactusformik.errors.supportmail
                            }
                          />
                          <FormFeedback>{Contactusformik.errors.supportmail}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                    <FormGroup>
                          <Label for="listmail">List Mail</Label>
                          <Input
                            type="text"
                            name="listmail"
                            id="listmail"
                            className="form-control"
                            value={Contactusformik.values.listmail}
                            onChange={Contactusformik.handleChange}
                            onBlur={Contactusformik.handleBlur}
                            autoComplete="off"
                            placeholder="Enter List mail"

                            invalid={
                              Contactusformik.touched.listmail &&
                              !!Contactusformik.errors.listmail
                            }
                          />
                          <FormFeedback>{Contactusformik.errors.listmail}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                          <Label for="infomail">Info Mail</Label>
                          <Input
                            type="text"
                            name="infomail"
                            id="infomail"
                            className="form-control"
                            value={Contactusformik.values.infomail}
                            onChange={Contactusformik.handleChange}
                            onBlur={Contactusformik.handleBlur}
                            autoComplete="off"
                            placeholder="Enter Info mail"

                            invalid={
                              Contactusformik.touched.infomail &&
                              !!Contactusformik.errors.infomail
                            }
                          />
                          <FormFeedback>{Contactusformik.errors.infomail}</FormFeedback>
                        </FormGroup>
                        </Col>
                        <div className="text-end">
                      {contactusloading ? (
                        <Button color="primary">Loading...</Button>
                      ) : (
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                      )}
                    </div>
           
                        </Row>
                    </Form>
                       
              </CardBody>
              
            </Card>
          
           

          </Row> */}
        
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SiteSettings;


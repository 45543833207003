

import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../helpers/adminContext";
import { useContext,useState ,useEffect} from "react";
import toast from "react-hot-toast";

const TodayUsers = (formData) => {
  const navigate=useNavigate()

  const { adminInfo } = useContext(AdminContext);
  const [adminType,setAdminType] = useState('')
  useEffect(()=>{
    setAdminType(adminInfo?.adminType)
  },[])

  // const handleTodayUsers=()=>{
  //   navigate('/allUsers',{ state: { user:Apiservices.TODAY_USERS} })
  // }

  const handleTodayUsers=()=>{
    if ((adminType === 1 || (adminInfo.permissions && adminInfo.permissions.some(data => 
      data.module === "Dashboard" && data.actions.includes(3))))) {
      navigate('/todayUsers');
  }
  else {
    toast.error('Permission denied')
      navigate('/dashboard');
  }
  }
  const handleWellcomeBonus=()=>{
    if ((adminType === 1 || (adminInfo.permissions && adminInfo.permissions.some(data => 
      data.module === "Dashboard" && data.actions.includes(3))))) {
      navigate('/wellcomeBonus');
  }
  else {
    toast.error('Permission denied')
      navigate('/dashboard');
  }
    
  }

  
  const handleReferralBonus=()=>{
    if ((adminType === 1 || (adminInfo.permissions && adminInfo.permissions.some(data => 
      data.module === "Dashboard" && data.actions.includes(3))))) {
      navigate('/userhistoryFlexible');
  }
  else {
    toast.error('Permission denied')
      navigate('/dashboard');
  }
   
  }
  const handlePendingWithdraw=()=>{
    if ((adminType === 1 || (adminInfo.permissions && adminInfo.permissions.some(data => 
      data.module === "Dashboard" && data.actions.includes(3))))) {
      navigate('/pending-crypto-withdraw-history');
  }
  else {
    toast.error('Permission denied')
      navigate('/dashboard');
  }
  }
  
  const handleStaking=()=>{
    if ((adminType === 1 || (adminInfo.permissions && adminInfo.permissions.some(data => 
      data.module === "Dashboard" && data.actions.includes(3))))) {
      navigate('/usersstakehistory');
  }
  else {
    toast.error('Permission denied')
      navigate('/dashboard');
  }
   
  }
  const handleDeposit=()=>{
    if ((adminType === 1 || (adminInfo.permissions && adminInfo.permissions.some(data => 
      data.module === "Dashboard" && data.actions.includes(3))))) {
      navigate('/depositCryptoHistory');
  }
  else {
    toast.error('Permission denied')
      navigate('/dashboard');
  }
   
  }

  return (
    <React.Fragment>
      <Row>
      
        <Col xl={3} sm={6}>
          <Card className="bg-success text-light">
            
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                  <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="mdi mdi-account-group-outline"></i>
                    </div>
                  </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden" onClick={handleTodayUsers} style={{cursor:'pointer'}}>
                  <p className="mb-1 text-light">Today Users</p>
                  <h4 className="mb-3 text-light"><b>{formData.todaySignUp}</b></h4>
                  
                  {/* <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      0.02%{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card className="bg-danger text-light">
            <CardBody>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-checkbox-circle-line text-success"></i>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden" onClick={handleWellcomeBonus} style={{cursor:'pointer'}}>
                  <p className="mb-1">Total Signup Bonus</p>
                  <h4 className="mb-3 text-light">
                    {/* <b>{formData.totalWelcomeBonus}</b> */}
                    <b>{formData.totalWelcomeBonusAmount ? formData.totalWelcomeBonusAmount.toFixed(4):''} </b>
                    <span style={{fontSize:'10px'}}>
                      USDT
                    </span>
                    

                  {/* <span className="text-light me-2 ps-3 fs-6">
                      {" "}
                      {formData.totalWelcomeBonusAmount ? formData.totalWelcomeBonusAmount.toFixed(4):''}
                      <i  className=""> USDT</i>
                    </span> */}
                  </h4>
                 
                  <p className="text-truncate mb-0">
                    {" "}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* <Col xl={3} sm={6}>
          <Card className="bg-secondary text-light">
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-calendar-2-line text-warning"></i>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden" onClick={handleReferralBonus}  style={{cursor:'pointer'}}>
                  <p className="mb-1 text-light">Total Referral Bonus</p>
                  <h4 className="mb-3 text-light">
                  
                    <b>{formData.totalReferralBonusAmount ? formData.totalReferralBonusAmount.toFixed(4):''} </b>
                    <span style={{fontSize:'10px'}}>
                      USDT
                    </span>

                
                  </h4>
        
                </div>
              </div>
            </CardBody>
          </Card>
        </Col> */}
          <Col xl={3} sm={6}>
          <Card className="bg-secondary text-light">
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-calendar-2-line text-warning"></i>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden" onClick={handleReferralBonus}  style={{cursor:'pointer'}}>
                  <p className="mb-1 text-light">Total Flexible Staking</p>
                  <h4 className="mb-3 text-light">
                    <b>{formData.totalFlexibleStake || 0}</b>
                  </h4>
        
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* <Col xl={3} sm={6}>
          <Card className="text-light bg-info">
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-close-circle-line text-danger"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1 text-light">
                    Today KYC</p>
                  <h4 className="mb-3 text-light"><b>0</b></h4>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col> */}
        <Col xl={3} sm={6}>
          <Card className="text-light bg-info">
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-close-circle-line text-danger"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden" onClick={handleDeposit}  style={{cursor:'pointer'}}>
                  <p className="mb-1 text-light">
                    Total Deposit</p>
                  <h4 className="mb-3 text-light"><b>{formData?.totalDeposit || 0}</b></h4>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* <Col xl={3} sm={6}>
          <Card className="bg-secondary text-light">
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-calendar-2-line text-warning"></i>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden" onClick={handleReferralBonus}  style={{cursor:'pointer'}}>
                  <p className="mb-1 text-light">Total Swap orders</p>
                  <h4 className="mb-3 text-light">
                    <b>{formData.swapHistory ? formData.swapHistory:'0'} </b>
                  </h4>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col> */}
         <Col xl={3} sm={6}>
          <Card className="bg-secondary text-light">
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-calendar-2-line text-warning"></i>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden" onClick={handleStaking}  style={{cursor:'pointer'}}>
                  <p className="mb-1 text-light">Total Staking</p>
                  <h4 className="mb-3 text-light">
                    <b>{formData.totalStake || '0'} </b>
                  </h4>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
       
       
        <Col xl={3} sm={6}>
          <Card className="bg-warning text-light">
            <CardBody>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-checkbox-circle-line text-success"></i>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden" onClick={handleWellcomeBonus} style={{cursor:'pointer'}}>
                  <p className="mb-1">Pending Votes</p>
                  <h4 className="mb-3 text-light">
                    <b>{formData.pendingVotes || 0}</b>
                    {/* <b>0</b> */}
                  </h4>
                 
                  <p className="text-truncate mb-0">
                    {" "}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} sm={6}>
          <Card className="bg-danger text-light">
            <CardBody>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-checkbox-circle-line text-success"></i>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden" onClick={handleWellcomeBonus} style={{cursor:'pointer'}}>
                  <p className="mb-1">Voting Bonus</p>
                  <h4 className="mb-3 text-light">
                    <b>0</b>
                    <b>{formData.totalvotingBonus ? formData.totalvotingBonus.toFixed(4):''} </b>
                    <span style={{fontSize:'10px'}}>
                      USDT
                    </span>
                    

                  {/* <span className="text-light me-2 ps-3 fs-6">
                      {" "}
                      {formData.totalWelcomeBonusAmount ? formData.totalWelcomeBonusAmount.toFixed(4):''}
                      <i  className=""> USDT</i>
                    </span> */}
                  </h4>
                 
                  <p className="text-truncate mb-0">
                    {" "}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} sm={6}>
          <Card className="bg-warning text-light">
            <CardBody>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-checkbox-circle-line text-success"></i>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden" onClick={handlePendingWithdraw} style={{cursor:'pointer'}}>
                  <p className="mb-1">Crypto Pending Withdraw</p>
                  <h4 className="mb-3 text-light">
                    <b>{formData?.pendingCryptoWithdraw || 0}</b>
                  </h4>
                 
                  <p className="text-truncate mb-0">
                    {" "}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TodayUsers;

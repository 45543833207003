// import React, { useEffect } from "react";
// import { Navigate } from "react-router-dom";
// import Cookies from "js-cookie";
// import { Apiservices, TokenName } from "../ApiService/ApiServices";
// import { postGetData } from "../ApiService/AxiosInstance";

// const PublicRoute = ({ children }) => {



//     useEffect(async()=>{
//         const response=await postGetData(Apiservices.ADMIN_IP_BLOCK_SITE)

//         if (response.code) {
            
//         }

        

//     },[])




//     const isAuthenticated = Cookies.get(TokenName.AUTH_TOKEN) !== undefined;
//     return isAuthenticated ? <Navigate to="/dashboard" /> : children;
// };

// export default PublicRoute;


import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Apiservices, TokenName } from "../ApiService/ApiServices";
import { postData } from "../ApiService/AxiosInstance";
// import { AdminContext } from "../helpers/adminContext";

const PublicRoute = ({ children }) => {
  const [ipBlocked, setIpBlocked] = useState(false);

// useEffect(()=>{
//   if{(adminType === 1 || adminInfo.permissions.some(data => 
//     data.module === "All Users" && data.actions.includes(3))) ? (
// })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await postData(Apiservices.ADMIN_IP_BLOCK_SITE,{});
        if (response && (response.data.isIpActive===2 || response.data.isActive === 0)) {
          
          setIpBlocked(true); // IP is blocked
        } else {
          setIpBlocked(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle fetch error
      }
    };

    fetchData();
  }, []);

  const isAuthenticated = Cookies.get(TokenName.AUTH_TOKEN) !== undefined;

  // Redirect to /ipBlock if IP is blocked
  if (ipBlocked) {
    return <Navigate to="/ipBlocked" />;
  }

  // Redirect to /dashboard if authenticated, otherwise render children
  return isAuthenticated ? <Navigate to="/dashboard" /> : children;
};

export default PublicRoute;

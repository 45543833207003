import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { TokenName } from "../ApiService/ApiServices";

const PrivateRoute = ({ children }) => {
    const isShow = Cookies.get(TokenName.AUTH_TOKEN) !== undefined;
    return isShow ? children : <Navigate to="/login" />;
};

export default PrivateRoute;


// import React from "react";
// import { Navigate } from "react-router-dom";
// import Cookies from "js-cookie";
// import { TokenName } from "../ApiService/ApiServices";

// const PrivateRoute = ({ component: Component, layout: Layout }) => {
//   const isShow = Cookies.get(TokenName.AUTH_TOKEN) !== undefined;
//   return isShow ? (
//     <Layout>
//       <Component />
//     </Layout>
//   ) : (
//     <Navigate to="/login" />
//   );
// };

// export default PrivateRoute;

import React, { useState, useEffect } from "react";
import { CardBody, Modal, } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue, utcToNormalTime } from "../../../helpers/function";

const UserNotes = ({reasonData}) => {
    document.title = "Ultrapro | Withdraw Management";

    const [loading, setLoading] = useState(false);
    const [showModel, setShowModel] = useState(false)
    const [showDetails, setShowDetails] = useState({})
    // const [getReason, setGetReason] = useState([]);
    const flattenData = Array.isArray(reasonData) && Array.isArray(reasonData[0]) ? reasonData[0] : reasonData;

    const [getReason, setGetReason] = useState(Array.isArray(flattenData) ? flattenData : [flattenData]);


    useEffect(() => {
      const flattenData = Array.isArray(reasonData) && Array.isArray(reasonData[0]) ? reasonData[0] : reasonData;
      setGetReason(Array.isArray(flattenData) ? flattenData : [flattenData]);
  }, [reasonData]);


    const handleShowModel = (value) => {
        setShowDetails(value)
        setShowModel(true)
    }

    const [currentPage, setCurrentPage] = useState(0);
    const rowsPerPage = 10;
    const handlePageChange = (selectedPage) => {
      setCurrentPage(selectedPage.selected);
    };
    const offset = currentPage * rowsPerPage;
    const ResonsData = getReason.slice(offset, offset + rowsPerPage);
    
  //reasonTable
  const reasonColumns = [
    {
      name: "S.No",
      selector: (row, index) => offset + index + 1,
      sortable: true,
      grow: 1,
    },
    {
      name: "Admin Email",
      selector: (row) => handleNullValue(row.admin),
      sortable: true,
      grow: 3,

    },
   
    {
      name: "Type",
      selector: (row) => handleNullValue(row.blockType),
      sortable: true,
      grow: 3,

    },
     {  
      name: "Reason",  
      selector: (row) => handleNullValue(row.reason)?.slice(0, 20) + (row.reason?.length > 20 ? '...' : ''),
      grow: 2,


    },  
   

    {
      name: "Date & Time",
      selector: (row) =>
        row.createdAt
          ? `${utcToNormalTime(row.createdAt).date} ${
              utcToNormalTime(row.createdAt).time
            }`
          : "---",
      sortable: true,
      grow: 2,

    },
    {
        name: 'Action',
        cell: row => (
            <div className="edit d-flex">
                <span id={`tooltipEmail${row.id}`} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline" 
                style={{ fontSize: '15px', cursor: 'pointer' }}></span>
            </div>
        ),
        ignoreRowClick: true,
      grow: 1,
        

    },
    
  ];

    return (
        <React.Fragment>
            


            <Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static" >

                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
                        User Notes View
                    </h5>
                    <button
                        onClick={() => { setShowModel(false); }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>


                <div className="modal-body">

                    <div className="table-responsive">
                        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td><b>Admin Email</b></td>
                                    <td>{showDetails.admin || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Type</b></td>
                                    <td>{showDetails.blockType || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Reason</b></td>
                                    <td>{showDetails.reason || '-'}</td>
                                </tr>
                               
                                <tr>

                                    <td><b>Date & Time</b></td>
                                    <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time}</td>
                                </tr>
                             
                            </tbody>
                        </table>
                    </div>

                </div>

            </Modal>
            <CardBody>
                              <div className="table-responsive">
                                <DataTable
                                  columns={reasonColumns}
                                  data={ResonsData}
                                  paginationServer
                                  paginationTotalRows={ResonsData.length}
                                  progressPending={loading}
                                  persistTableHead={true}
                                  progressComponent={
                                    <div className="py-3">Loading...</div>
                                  }
                                  // noDataComponent={formData.length===0}
                                  noDataComponent={
                                    <div className="py-3">No records found</div>
                                  }
                                  fixedHeader={true}
                                />
                                {ResonsData.length != 0 ? (
                                  <div className="d-flex justify-content-end mt-3">
                                    <div className="pagination pagination-rounded mb-0 ">
                                      <ReactPaginate
                                        previousLabel={
                                          <i className="mdi mdi-chevron-left"></i>
                                        }
                                        nextLabel={
                                          <i className="mdi mdi-chevron-right"></i>
                                        }
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        containerClassName={"pagination"}
                                        activeClassName={"active"}
                                        pageLinkClassName={"page-link"}
                                        breakLinkClassName={"break-link"}
                                        disabledClassName={"disabled"}
                                        pageCount={Math.ceil(
                                          getReason.length / rowsPerPage
                                        )}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageChange}
                                      />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </CardBody>
        </React.Fragment>
    );
};

export default UserNotes;

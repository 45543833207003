import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row, Input, Spinner } from "reactstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { handleNullValue } from "../../../helpers/function";
import { postData } from "../../../ApiService/AxiosInstance";
import { Apiservices } from "../../../ApiService/ApiServices";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { formatNumber } from "../../../helpers/common";


const RewardAsset = ({tabId}) => {
  const { id } = useParams();
  document.title = "Ultrapro | User Management";
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchLoad, setSearchLoad] = useState(false);
  const [searchValid, setSearchValid] = useState(false);
  const [inputSearch, setinputSearch] = useState("");
  const [hideZeroBalance1, setHideZeroBalance1] = useState(false);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = totalLimit; 
  const pageCount = Math.ceil(totalPagination); 
  const [RewardBlance,setRewardbalance] = useState([])
  const [showHide, setShowHide] = useState(0); // New showHide state


  const handleRewardBalance = useCallback( async () => {
    setLoading(true);
    const postForm = {
      acId: Number(id),
      walletId:1,
      showHide: showHide, // Use showHide state here
    };
    try {
      const response = await postData(Apiservices.REWARD_BALANCE, postForm);
      if (response && response.status) {
        setLoading(false);
        setRewardbalance(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.totalCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  },[id,showHide]);

  useEffect(() => {
    if(tabId == 2) {
      handleRewardBalance();
    }
  }, [tabId,handleRewardBalance]);

  
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };

 
  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      search: inputSearch,
      acId: Number(id),
      showHide: showHide, // Add showHide in pagination as well
    };
    try {
      const response = await postData(Apiservices.REWARD_BALANCE, postForm);
      if (response.status) {
        setLoading(false);
        setRewardbalance(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.totalCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  };
  const handleFilter1 = async (value) => {
    setLoading(true);
    const postForm = {
      acId: Number(id),
      showHide: value,
      walletId:1
    };
    try {
      const response = await postData(Apiservices.REWARD_BALANCE, postForm);
      if (response && response.status) {
        setLoading(false);
        setRewardbalance(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.totalCount);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  

  const UserRewardcolumns = [
    {
      name: "S.No",
      selector: (row, index) => currentPage * itemsPerPage + index + 1,
      sortable: true,
      grow: 1,
    },
    {
      name: "Image",
      selector: (row) => (
        <div>
          <img style={{ width: "30px" }} src={row?.image ? row?.image : "-"} alt=""></img>
        </div>
      ),
      sortable: true,
      grow: 2,
    },
    {
      name: "Name",
      selector: (row) => handleNullValue(row?.name),
      sortable: true,
      grow: 2,
    },

    {
      name: "Symbol",
      selector: (row) => handleNullValue(row?.symbol),
      sortable: true,
      grow: 2,
    },

    {
      name: "Balance",
      selector: (row) => handleNullValue(row?.currencyType === 0 ? formatNumber(row.balance, row.decimalPoint) : formatNumber(row.balance, row.decimalPoint)),
      sortable: true,
      grow: 2,
    }
  ];
  

  const toggleHideZeroBalance1 = () => {
    const newValue = !hideZeroBalance1;  // Toggle the value of hideZeroBalance1
    const newShowHideValue = newValue ? 1 : 0;  // Map the checkbox value to 1 or 0
    setHideZeroBalance1(newValue);  // Update local state for checkbox UI
    setShowHide(newShowHideValue);  // Update the showHide state
  
    // Call the handleFilter1 function directly with the newShowHideValue
    handleFilter1(newShowHideValue);
  };
  

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "" && RewardBlance === undefined) {
      handleRewardBalance();
      setSearchValid(false);
    } else if (value === "") {
      handleFilter(RewardBlance);
    }
    setinputSearch(value);
  };

  const handleFilter = async (value) => {
    setLoading(true);
    const postForm = {
      acId: Number(id),
      showHide: value,
    };
    try {
      const response = await postData(Apiservices.REWARD_BALANCE, postForm);
      if (response && response.status) {
        setLoading(false);
        setRewardbalance(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.totalCount);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
        acId: Number(id),
      };
      try {
        const response = await postData(Apiservices.REWARD_BALANCE, postForm);
        if (response.status) {
          setSearchLoad(false);
          setRewardbalance(response.data.data);
          setTotalPagination(response.data.totalPages);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm="12" className="d-lg-flex">
          <Card className="col-lg-12">
              <Row className="mb-3">
                <Col sm={4} lg={3}>
                  <div className="input-group">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Search by Name or Symbol"
                      aria-label="Recipient's username"
                      onChange={handleSearchChanage}
                      invalid={searchValid}
                      onKeyPress={handleKeyPress}
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                        {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                      </button>
                    </div>
                  </div>
                </Col>
                <Col className="col-sm">
                  <div className="d-flex justify-content-sm-end">
                    <div className="form-check form-switch form-switch-md">
                      <span>Hide Zero Balance</span>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        disabled={loading}
                        onChange={toggleHideZeroBalance1}
                        // checked={hideZeroBalance1}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="table-responsive">
                <DataTable
                  columns={UserRewardcolumns}
                  data={RewardBlance}
                  paginationServer
                  paginationTotalRows={RewardBlance?.length}
                  progressPending={loading}
                  persistTableHead={true}
                  progressComponent={<div className="py-3">Loading...</div>}
                  noDataComponent={<div className="py-3">No records found</div>}
                  fixedHeader={true}
                />
              </div>
              <div className="row mt-3">
                <div className="col-12 col-md-6">
                  <div className="d-flex justify-content-start mt-3">
                    <span>Current Count : {count}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  {loading ? (
                    ""
                  ) : (
                    <div className="d-flex justify-content-end">
                      <div className="pagination pagination-rounded mb-0">
                        <ReactPaginate
                          previousLabel={<i className="mdi mdi-chevron-left"></i>}
                          nextLabel={<i className="mdi mdi-chevron-right"></i>}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          pageLinkClassName={"page-link"}
                          breakLinkClassName={"break-link"}
                          disabledClassName={"disabled"}
                          initialPage={currentPage}
                          disableInitialCallback={true}
                          forcePage={currentPage}
                          renderLink={(props) => {
                            if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                              return (
                                <a
                                  {...props}
                                  onClick={() =>
                                    handlePageClick({
                                      selected: pageCount - 1,
                                    })
                                  }
                                  aria-label="Link Description"
                                >
                                  {pageCount}
                                </a>
                              );
                            }
                            return <a {...props} aria-label="Link Description" />;
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
           </Card>
           </Col>
           </Row>

    </React.Fragment>
  );
};

export default RewardAsset;

import React, { useState, useEffect,useContext } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

// users
import user1 from "../../../assets/images/logo-sm.png";
import { TokenName } from "../../../ApiService/ApiServices";
import { AdminContext } from "../../../helpers/adminContext";
import { postData } from "../../../ApiService/AxiosInstance";
import { Apiservices } from "../../../ApiService/ApiServices";
import toast from "react-hot-toast";
const ProfileMenu = props => {
  const navigate=useNavigate()
  const { adminInfo } = useContext(AdminContext);
  const [adminType,setAdminType] = useState('')
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  // const [username, setusername] = useState("Admin");
  

  useEffect(() => {
    setAdminType(adminInfo?.adminType);
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        // const obj = JSON.parse(localStorage.getItem("authUser"));
        // setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        // const obj = JSON.parse(localStorage.getItem("authUser"));
        // setusername(obj.username);
      }
    }
  }, [props.success,adminInfo?.adminType]);


  const handleLogout=async()=>{
    await Cookies.remove(TokenName.AUTH_TOKEN)
       await navigate('/login')
    // const postForm = {
    //   email: adminInfo?.email,
    //   // agentStatus:0
    // }
    // try {
    //   const response = await postData(Apiservices.AGENT_LOGOUT, postForm)
    //   if (response.status) {
    //    toast.success('Logout Successfully')
    //    console.log(response,"222")
    //    await Cookies.remove(TokenName.AUTH_TOKEN)
    //    await navigate('/login')
    //   }
    //   else {
    //     console.log('')
    //    toast.error(response.message)

    //   }
    // } catch (error) {
    //   console.log(error)
      
    // }
  
  }



  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-2">{adminInfo?.name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        {/* <DropdownMenu className="dropdown-menu-end">
          
          <DropdownItem tag="button" href="/userprofile">
            {" "}
            <i className="ri-user-line align-middle me-2" />
            {props.t("Profile")}{" "}
          </DropdownItem>
      
          
          <DropdownItem tag="button" href="">
            <Link to='/changePassword'>
          
            <i className="ri-settings-2-line align-middle me-2" />
            {props.t("Change Password")}
            </Link>
          </DropdownItem>
        
          <div className="dropdown-divider" />
          <Link className="dropdown-item" onClick={handleLogout}>
            <i className="ri-shut-down-line align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu> */}

        <DropdownMenu className="dropdown-menu-end">
  {adminType === 1 ? (
    <>
      <DropdownItem tag={Link} to="/userprofile">
        <i className="ri-user-line align-middle me-2" />
        {props.t("Profile")}
      </DropdownItem>

      <DropdownItem tag={Link} to="/changePassword">
        <i className="ri-settings-2-line align-middle me-2" />
        {props.t("Change Password")}
      </DropdownItem>
  <div className="dropdown-divider" />

    </>
  ) : null}

  {/* <div className="dropdown-divider" /> */}

  <DropdownItem onClick={handleLogout}>
    <i className="ri-shut-down-line align-middle me-2 text-danger" />
    <span>{props.t("Logout")}</span>
  </DropdownItem>
</DropdownMenu>

      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);

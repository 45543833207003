import React, { useState, useEffect } from "react";
import {
  Input, Card, CardBody, FormFeedback,
  Col, Container, Form,
  Label,Row,
  FormGroup
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import Loader from "../../helpers/Spinner";
import { AdminContext } from "../../helpers/adminContext";
import { useContext } from "react";

const HomeManagement = () => {
  const { adminInfo } = useContext(AdminContext);
  const [adminType,setAdminType] = useState('')
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState('')
  document.title = "Home Mangement | Home Management";

  //LIST
  useEffect(() => {
    fetchContentApi();
    setAdminType(adminInfo?.adminType)
  }, []);

  const fetchContentApi = async () => {
    try {
      // setLoading(true)
      const response = await postData(Apiservices.OfferContentgetApi);
      if (response) { 
        if (response.data.length !== 0) {
          validationUpdate.setValues({
            content:response.data[0].htmlContent
          })
          if (response.data[0].isActive === 1) {
            setActiveMaintenanceStatus(true);
          } else {
            setActiveMaintenanceStatus(false);
          }

            // setContent(response.data[0].htmlContent);
          setLoading(false)
          const stripHtmlTags = (html) => {
            const div = document.createElement("div");
            div.innerHTML = html;
            return div.textContent || div.innerText || "";
          };

          // Strip HTML tags from the initial content
          const cleanedContent = stripHtmlTags(response.data[0].htmlContent);
          setContent(cleanedContent)

        }
        else {
          setContent(response);
          // setLoading(false)

        }

      } else {
        // setLoading(false)

      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false)

      // Handle fetch error
    }
  };
  const [activeMaintenanceStatus, setActiveMaintenanceStatus] = useState(false)
  const activeMaintenanceClick = () => {
    setActiveMaintenanceStatus(!activeMaintenanceStatus);
  }

  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,
    initialValues: {
      content:"",
      // title: "",
    },
    validationSchema: Yup.object({
      // title: Yup.string().required("Title is required"),
      content: Yup.string().required("Content is required"),
    }),
    onSubmit: (values) => {
      handleAdd1(values);
     
    },
  });



  const handleAdd1 = async (values) => {
    setLoading(true);
      const postForm = {
        name: "offer_content",
        htmlContent: values.content,
        isActive: activeMaintenanceStatus === true ? 1 : 0,
        adminType:adminInfo?.adminType,
        permissions:adminInfo?.permissions
      };

      try {
        const response = await postData(Apiservices.OfferContentUpdateApi, postForm);
        if (response.status === true) {
          toast.success(response.message);
          setLoading(false);
          fetchContentApi()
        } else {
          // toast.error(response.message);
          setLoading(false);
          fetchContentApi()
        }
      } catch (error) {
        toast.error("Internal Server Error..Try Again Later");
        setLoading(false);
      }
   
  };

 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Home Management" breadcrumbItem="Home Management" />
          <Row>
            <Col lg={12} className="row">
              <Card className="col-lg-12">
                <CardBody>
                  <Row className="g-4 mb-3">
                 
                  </Row>
                  <div className="table-responsive">
                    <Form
                      autoComplete="off"
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validationUpdate.handleSubmit();
                        return false;
                      }} >

                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Title
                        </label>

                        <Input
                          type="text"
                          id="title-field"
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter Title"
                          //   name="title"
                          readOnly
                          //   onChange={formik.handleChange}
                          //   onBlur={formik.handleBlur}
                          value={"offer_content"}
                        //   invalid={formik.touched.title && formik.errors.title}
                        />
                        {/* {formik.touched.title && formik.errors.title && <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>} */}
                      </div>
                      {(adminType === 1 || adminInfo?.permissions.some(data => 
                   data.module === "Home Management" && data.actions.includes(3))) ? (
                    <>
                      <Label for="siteMaintenanceMessage">Active Status</Label>
                     
                      <div className="form-check form-switch form-switch-md mb-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="isActive"
                          name="withdrawMaintenance"
                          checked={activeMaintenanceStatus == true}
                          // onChange={formik.handleChange}
                          onClick={activeMaintenanceClick}
                        />
                      </div>
                      </>
                          ): null}
                    
                        <FormGroup>
                        <Label for="siteMaintenanceMessage">Content</Label>
                          <Input
                            type="textarea"
                            id="customername-field"
                            className="form-control"
                            placeholder="Enter Content"
                            name="content"
                            onChange={validationUpdate.handleChange}
                            onBlur={validationUpdate.handleBlur}
                            value={validationUpdate.values.content || ""}
                            invalid={validationUpdate.touched.content && validationUpdate.errors.content}
                          />
                              <FormFeedback>{validationUpdate.errors.content}</FormFeedback>
                        
                         </FormGroup>
                         {(adminType === 1 || adminInfo?.permissions.some(data => 
                         data.module === "Home Management" && data.actions.includes(3))) ? (
                      
                      <div className="text-end">
                        <button className="btn btn-primary waves-effect waves-light" type="submit"  disabled={loading}>
                          {loading ? <Loader /> : "Submit"}
                        </button>
                      </div>
                      ):null}
                    </Form>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>

  );
};

export default HomeManagement;

import React, { useEffect, useState , useCallback } from "react";
import { Card,Col, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import platform from 'platform';

const UserLoginActivities = ({tabId}) => {
  const { id } = useParams();
  document.title = "Ultrapro | User Management";


  const [formData, setFormData] = useState([]);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  // const [searchLoad, setSearchLoad] = useState(false);
  // const [searchValid, setSearchValid] = useState(false);
  // const [inputSearch, setinputSearch] = useState("");
  const [loading, setLoading] = useState(false);
  
  const itemsPerPage = totalLimit; 
  const pageCount = Math.ceil(totalPagination); 

  const handleFetch = useCallback(async () => {
    setLoading(true);
    const postForm = {
      userId: Number(id),
    };
    try {
      const response = await postData(Apiservices.UserLoginActivities, postForm);
      if (response && response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, [id]); 

  useEffect(() => {
    if (tabId == 4) {
      handleFetch();
    }
  }, [tabId, handleFetch]); 
  
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };

  const extractOsAndBrowser = (userAgent) => {
    const parsedPlatform = platform.parse(userAgent);
        const osName = parsedPlatform.os.family || "Unknown OS";
    const browserName = parsedPlatform.name || "Unknown Browser";
    
    return { osName, browserName };
  };
  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      userId: Number(id),
    };
    try {
      const response = await postData(Apiservices.UserLoginActivities, postForm);

      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };
  const UserBalancecolumns = [
    {
      name: "S.No",
      selector: (row, index) => currentPage * itemsPerPage + index + 1,
      sortable: true,
      grow: 1,
      minWidth: "100px", 
    },
    {
      name: "Name",
      selector: (row) => handleNullValue(row.userDetails?.fullName ? row.userDetails?.fullName : '-'),
      sortable: true,
      grow: 2,
      minWidth: "150px", 
    },
    {
      name: "Email",
      selector: (row) => handleNullValue(row.userDetails.email ? row.userDetails.email : '-' ),
      sortable: true,
      grow: 4,
      minWidth: "300px", 
    },
    {
      name: "IP Address",
      selector: (row) => handleNullValue(row.ipAddress),
      sortable: true,
      grow: 3,
      minWidth: "150px", 
    },
    {
      name: "Location",
      selector: (row) => handleNullValue(`${row.location?.city},${row.location?.country}` ),
      sortable: true,
      grow: 3,
      minWidth: "200px", 
    },
    {
      name: "Browser Name",
      selector: (row) => extractOsAndBrowser(row.userAgent).browserName, 
      sortable: true,
      grow: 2,
      minWidth: "150px", 
    },
    {
      name: "OS Name",
      selector: (row) => extractOsAndBrowser(row.userAgent).osName, 
      sortable: true,
      grow: 2,
      minWidth: "150px", 
    },
    {
      name: "Created Date & Time",
      selector: (row) => (row.updatedAt ? `${utcToNormalTime(row.updatedAt).date} ${utcToNormalTime(row.updatedAt).time}` : "---"),
      // selector: (row) => (row.createdAt ? `${utcToNormalTime(row.createdAt).date} ${utcToNormalTime(row.createdAt).time}` : "---"),
      
      sortable: true,
      grow: 3,
      minWidth: "200px", 
    },
  ];
  
  <div className="table-responsive" style={{ overflowX: 'auto' }}>
    <DataTable
      columns={UserBalancecolumns}
      data={formData}
      paginationServer
      paginationTotalRows={formData.length}
      progressPending={loading}
      persistTableHead={true}
      progressComponent={<div className="py-3">Loading...</div>}
      noDataComponent={<div className="py-3">No records found</div>}
      fixedHeader={true}
    />
  </div>
  


  // const handleSearchChanage = (e) => {
  //   const value = e.target.value;
  //   if (value === "" && formData === undefined) {
  //     handleFetch();
  //   } else if (value === "") {
  //     handleFilter(formData);
  //   }
  //   setinputSearch(value);
  // };

  // const handleFilter = async (value) => {
  //   setLoading(true);
  //   const postForm = {
  //     userId: Number(id),
  //     showHide: value,
  //   };
  //   try {
  //     const response = await postData(Apiservices.UserLoginActivities, postForm);
  //     if (response && response.status) {
  //       setLoading(false);
  //       setFormData(response.data.data);
  //       setTotalPagination(response.data.totalPages);
  //       setTotalLimit(response.data.limit);
  //       setCount(response.data.currentCount);
  //     } else {
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  // };
  // const handleSearch = async () => {
  //   if (inputSearch !== "") {
  //     setSearchLoad(true);
  //     const postForm = {
  //       userId: Number(id),
  //     };
  //     try {
  //       const response = await postData(Apiservices.UserLoginActivities, postForm);

  //       if (response.status) {
  //         setSearchLoad(false);
  //         setFormData(response.data.data);
  //         setTotalPagination(response.data.totalPages);
  //       } else {
  //         setSearchLoad(false);
  //       }
  //     } catch (error) {
  //       toast.error("Internal Server Error..Try Again Later");
  //       setSearchLoad(false);
  //     }
  //   } else {
  //     setSearchValid(true);
  //   }
  // };
  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     handleSearch();
  //   }
  // };

  return (
    <React.Fragment>
      <Row>
        <Col sm="12" className="d-lg-flex">
          <Card className="col-lg-12">
           
            <Row className="mb-3">
              {/* <Col sm={4} lg={3}>
                <div className="input-group">
                  <Input
                    // onChange={handleInputChange}
                    type="text"
                    className="form-control"
                    placeholder="Search by Name or Symbol"
                    aria-label="Recipient's username"
                    onChange={handleSearchChanage}
                    invalid={searchValid}
                    onKeyPress={handleKeyPress}
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                      {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                    </button>
                  </div>
                </div>
              </Col> */}
                
              </Row>
             

              <div className="table-responsive">
                <DataTable
                  columns={UserBalancecolumns}
                  data={formData}
                  paginationServer
                  paginationTotalRows={formData.length}
                  progressPending={loading}
                  persistTableHead={true}
                  progressComponent={<div className="py-3">Loading...</div>}
                  noDataComponent={<div className="py-3">No records found</div>}
                  fixedHeader={true}
                />
                 </div>
                 <div className="row mt-3">
                    <div className="col-12 col-md-6">
                      <div className="d-flex justify-content-start mt-3">
                        <span>Current Count : {count}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                    {loading ? (
                      ""
                    ) : (
                      <div className="d-flex justify-content-end">
                        <div className="pagination pagination-rounded mb-0">
                          <ReactPaginate
                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            breakLinkClassName={"break-link"}
                            disabledClassName={"disabled"}
                            initialPage={currentPage}
                            disableInitialCallback={true}
                            forcePage={currentPage}
                            renderLink={(props) => {
                              if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                return (
                                  <a
                                    {...props}
                                    onClick={() =>
                                      handlePageClick({
                                        selected: pageCount - 1,
                                      })
                                    }
                                  >
                                    {pageCount}
                                  </a>
                                );
                              }
                              return <a {...props}  aria-label="Link Description" />;
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
             
           
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserLoginActivities;

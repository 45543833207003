// import React from "react";
// import { Link } from "react-router-dom";
// import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";

// const Breadcrumbs = (props) => {
//   return (
//     <React.Fragment>
//       <Row>
//         <Col xs="12">
//           <div className="page-title-box d-sm-flex align-items-center justify-content-between">
//             <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
//             <div className="page-title-right">
//               <Breadcrumb listClassName="m-0">
//              {props.childdren ?(
//  <BreadcrumbItem>
//  {props.childdren}
// </BreadcrumbItem>
//              ):(
// ""
//              )}
               
          
              
//                 <BreadcrumbItem>
//                   <Link to={props.titleRoute}>{props.title}</Link>
//                 </BreadcrumbItem>
//                 <BreadcrumbItem active>
//                   <Link to="#">
//                     {props.breadcrumbItem}
//                     </Link>
//                 </BreadcrumbItem>
//               </Breadcrumb>
//             </div>
//           </div>
//         </Col>
//       </Row>
//     </React.Fragment>
//   );
// }



// export default Breadcrumbs;
import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";

const Breadcrumbs = (props) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
            <div className="page-title-right">
              <Breadcrumb listClassName="m-0">
                {props.subChildrenRoute ? (
                  <BreadcrumbItem>
                    <Link to={props.subChildrenRoute}>{props.childdren}</Link>
                  </BreadcrumbItem>
                ) : (
                  props.childdren ? (
                    <BreadcrumbItem>{props.childdren}</BreadcrumbItem>
                  ) : null
                )}
                <BreadcrumbItem>
                  {props.titleRoute ? (
                    <Link to={props.titleRoute}>{props.title}</Link>
                  ) : (
                    <span>{props.title}</span>
                  )}
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <span>{props.breadcrumbItem}</span>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Breadcrumbs;

import React, { useState, useEffect,useContext } from "react";
import {  Input, Card, CardBody, Col, Container, Row, Spinner, InputGroup,Button,Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Modal,ModalBody,Form,ModalHeader } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance,{ postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue } from "../../helpers/function";
import Loader from "../../helpers/Spinner";
import { AdminContext } from "../../helpers/adminContext";
import { Link } from "react-router-dom";

const SwapHistory = () => {
    // const navigate = useNavigate()

    // const kyc = location.state?.kyc

    document.title = "Ultrapro | Swap History" 

    const [loading, setLoading] = useState(false);
    const [modal_update_list, setmodal_update_list] = useState(false);

    const [isEdit, setisEdit] = useState(false);
    const [recordToUpdate, setRecordToUpdate] = useState({});

    const [searchLoad, setSearchLoad] = useState(false);
    const [searchLoad1, setSearchLoad1] = useState(false);
    
    const [searchValid, setSearchValid] = useState(false);
    const [selectedItem, setSelectedItem] = useState("All");


  
    const [inputSearch, setinputSearch] = useState('');
    const [formData, setFormData] = useState([]);
    const [formData1, setFormData1] = useState([]);
    const [totalPagination, setTotalPagination] = useState(1);
    const [totalLimit, setTotalLimit] = useState(1);

    const [user, setUser] = useState();
    const [btnauto3, setbtnauto3] = useState(false);
  

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = totalLimit; // Number of items to display per page
    const pageCount = Math.ceil(totalPagination);
    //permission List
    const { adminInfo } = useContext(AdminContext);
const [adminType,setAdminType] = useState('')


    const validationAdd = useFormik({
        enableReinitialize: true,

        initialValues: {
            pair: '',
            fee: '',



        },
        validationSchema: Yup.object({}),
        onSubmit: (values) => {
            // handleAdd(values)



        },
    });

    useEffect(() => {
            ipList();
            setAdminType(adminInfo?.adminType)
    }, []);


    useEffect(() => {
        tradePair()
    }, [])
    const tradePair = async () => {

        try {
            setLoading(true)
            const response = await postData(Apiservices.USER_SWAP_PAIR_LIST)
            if (response.status) {
                const filteredData = response.data;
                setFormData1(filteredData);
                
                setLoading(false)


            } else {
                setLoading(false)

            }

        } catch (error) {


        }


    }






    const handleFilter = async (apiName) => {
        setLoading(true);
        // const postForm = {
        //     ...filterParams
        // };
        try {
            const response = await postData(apiName);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.limit)
            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };

    const [selectedFormat, setSelectedFormat] = useState('');
    const [downLoading, setDownLoading] = useState(false);

    const exportOptions = [
        { label: 'PDF', value: 'pdf' },
        { label: 'CSV', value: 'csv' },
        { label: 'XLSX', value: 'xlsx' }
    ];


    function convertJsonToCsv(json) {
    if (!json || !json.length) return '';

    // Get headers from the first object
    const headers = Object.keys(json[0]);
    const csvRows = [];

    // Add header row
    csvRows.push(headers.join(','));

    // Add data rows
    for (const row of json) {
        const values = headers.map(header => {
            // Escape quotes and commas
            const escaped = ('' + row[header]).replace(/"/g, '""').replace(/,/g, '\\,');
            return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
}

    const handleDownload = async (format) => {
        setSelectedFormat(format.toUpperCase());
        setDownLoading(true)
        try {
            const postForm = {};

            if (dateRange[0] && dateRange[1]) {
                postForm.startDate = formatDate(dateRange[0]);
                postForm.endDate = formatDate(dateRange[1]);
            }

            if (inputSearch) {
                postForm.search = inputSearch;
            }
            // if (FileName) {
            //     postForm.filter = FileName;
            // }
            postForm.download = format;
            const queryString = new URLSearchParams(postForm).toString();
            const response = await axiosInstance.post(`${Apiservices.SWAP_HISTORY_DOWNLOAD}?${queryString}`, {
                // Include any data you need to send with the POST request here
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                // responseType: 'blob'
            });
            if (response.data) {
                setDownLoading(false)
                const jsonData = response.data.data.data; // Adjust based on your actual data structure


// Convert JSON data to CSV
const csv = convertJsonToCsv(jsonData);

// Create a Blob with CSV data
const blob = new Blob([csv], { type: 'text/csv' });

// Create a URL for the Blob
const url = window.URL.createObjectURL(blob);

// Create a link element
const link = document.createElement('a');
link.href = url;

// Set the filename for the downloaded file
link.setAttribute('download', `SwapHistory.${format}`);

// Append the link to the document, click it to start the download, and then remove it
document.body.appendChild(link);
link.click();
document.body.removeChild(link);

// Revoke the object URL to free up memory
window.URL.revokeObjectURL(url);
            } else {
                toast.error(response.data.message)
                setDownLoading(false)

            }
        } catch (err) {
            setDownLoading(false)
            console.error('Error downloading the file', err);
        }
    };
 

    const ipList = async () => {
        setLoading(true);

        try {
            const response = await postData(Apiservices.SWAP_HISTORY);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
            } else {
                toast.error(response.message);
                setLoading(false);

            }
        } catch (error) {
            toast.error("Internal Server Error...");
            setLoading(false);


        }
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        handlePagePost(selected);
    };

    const handlePagePost = async (selected) => {
        setLoading(true);
        const postForm = {
            page: selected + 1,
            // limit: itemsPerPage,
            search: inputSearch,
        };

        const queryString = new URLSearchParams(postForm).toString();
        try {
                const response = await postData(`${Apiservices.SWAP_HISTORY}?${queryString}`);

                if (response.status) {
                    setLoading(false);
                    setFormData(response.data.data);
                } else {
                    setLoading(false);
                    toast.error(response.message);
                }

           
         
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };

    const allowedActions = [1,2,3,4];
    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true,
            maxWidth: '1%',
        },
        {
            name: 'Pair Name',
            selector: row => row.pair.replace("_","/"),
            sortable: true,
            maxWidth: '15%',
        },        
        /*{
            name: 'Name',
            selector: row => (row.fullName ? row.fullName : '-'),
            sortable: true,
            maxWidth: '2%',
        },*/
        {
            name: 'Email',
            selector: row => <div className="d-flex align-items-center">
            <Link className="link-tag" to={`/allUsers/userDetails/${row.userId}`} target="_blank">
              <span className="mx-1 text-decoration-underline">
                {row.email || '-'}
              </span>
            </Link>
          </div>,
            sortable: true,
            // maxWidth: '4%',
        },
        {
            name: 'Send Amount',
            selector: row => `${parseFloat(row.amount).toFixed(row.pairDetails.amountDecimal)} ${row.pair.split("_")[0]}`,
            sortable: true,
            maxWidth: '15%',
            
        },
        /*{
            name: 'Swap Rate',
            selector: row => `${row.price} ${row.pair.split("_")[1]}`,
            sortable: true,
            maxWidth: '15%',
            
        },*/
        {
            name: 'Actual Price',
            selector: row => `${parseFloat(parseFloat(row.total) + parseFloat((row.fee) ? row.fee : 0)).toFixed(row.pairDetails.priceDecimal)} ${row.pair.split("_")[1]}`,
            sortable: true,
            maxWidth: '15%',
            
        },

        {
            name: 'Fee',
            selector: row => (row.fee) ? parseFloat(row.fee).toFixed(row.pairDetails.priceDecimal)+" "+row.pair.split("_")[1] : "--",
            sortable: true,
            maxWidth: '15%',
            
        },

        {
            name: 'Final receive',
            selector: row => `${parseFloat(row.total).toFixed(row.pairDetails.priceDecimal)} ${row.pair.split("_")[1]}`,
            sortable: true,
            maxWidth: '15%',
            
        },

        {
            name: 'Tx ID',
            selector: row => handleNullValue(row.txid),
            sortable: true,
            maxWidth: '15%',
            
        },
       
       

         /*{
            name: 'Status',
            selector: row =>  row.status,
           
            sortable: true,
            maxWidth: '10%',
        },*/

        {
            name: 'Date & Time',
            selector: row => row.createdAt
                ? `${utcToNormalTime(row.createdAt).date} ${utcToNormalTime(row.createdAt).time}`
                : "-",
            sortable: true,
            // maxWidth: '3%',

        },
        ...(adminType === 1 || adminInfo?.permissions.some(data => 
            data.module === "Swap History" && allowedActions.some(action => data.actions.includes(action))) ? [
        {
            name: "Action",
            cell: (row) => (
                <div className="edit">
                    {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Swap History" && data.actions.includes(2))) ? (
                    <i
                        style={{ cursor: "pointer" }}
                        className="mdi mdi-eye cursor-pointer"
                      onClick={() => handleUpdate(row)}
                    ></i>
    ): null}
                </div>
            ),
            ignoreRowClick: true,
            // allowOverflow: true,
            // button: true,
        }]:[]),
      
    ];

    const handleUpdate = (record) => {
        setisEdit(true);
        validationAdd.setValues({
            pair:record.pair,
            email:record.email,
            fullName:record.fullName,
            amount:record.amount,
            price:record.price,
            total:record.total,
            status:record.status,
            txid:record.txid,
            fee:record.fee,
            createdAt:`${utcToNormalTime(record.createdAt).date} ${utcToNormalTime(record.createdAt).time}`,
        });
        setRecordToUpdate(record);
        setmodal_update_list(true)
    }

   
    const utcToNormalTime = (utcString) => {
        const date = new Date(utcString);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${months[date.getMonth()]}-${date.getFullYear()}`;

        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, "0");
        // const seconds = date.getSeconds().toString().padStart(2, "0");
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes} ${ampm}`;
        return { date: formattedDate, time: formattedTime };
    };



    const handleSearchChanage = (e) => {
        const value = e.target.value
        if (value == '' && user === undefined) {
            ipList()
            setSearchValid(false)
        }else if(value == ''){

            handleFilter(user)
        }
        setinputSearch(value)

    }

    useEffect(()=>{
        handleSearch1();
    },[inputSearch])


    const handleSearch1 = async () => {
        if (inputSearch !== '') {


            setSearchLoad1(true);
            const postForm = {
                search: inputSearch,
                // page: currentPage + 1,
                // limit: itemsPerPage,
            };

            const queryString = new URLSearchParams(postForm).toString();
            try {
               
                    const response = await postData(`${Apiservices.SWAP_HISTORY}?${queryString}`,);
                    if (response.status) {
                        setSearchLoad1(false);
                        setFormData(response.data.data);
                        setTotalPagination(response.data.totalPages);

                        // toast(response.message);
                    } else {
                        setSearchLoad1(false);
                    }
                
            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
                setSearchLoad1(false);
            }
        } else {
            // setSearchValid(true)
        }
    }


    const handleSearch = async () => {
        if (inputSearch !== '') {


            setSearchLoad(true);
            const postForm = {
                search: inputSearch,
                // page: currentPage + 1,
                // limit: itemsPerPage,
            };

            const queryString = new URLSearchParams(postForm).toString();
            try {
               
                    const response = await postData(`${Apiservices.SWAP_HISTORY}?${queryString}`,);
                    if (response.status) {
                        setSearchLoad(false);
                        setFormData(response.data.data);
                        setTotalPagination(response.data.totalPages);

                        // toast(response.message);
                    } else {
                        setSearchLoad(false);
                    }
                
            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
                setSearchLoad(false);
            }
        } else {
            setSearchValid(true)
        }
    }

    // const handleDropdownClick = (item) => {
    //     setSelectedItem(item);
    //     switch (item) {
    //         case 'All Users':
    //             setUser(Apiservices.USER_LIST)
    //             handleFilter(Apiservices.USER_LIST);
    //             break;
    //         case 'Active Users':
                
    //           setUser(Apiservices.USER_VERIFIED_LIST)
    //             handleFilter(Apiservices.USER_VERIFIED_LIST);
                
    //             break;
    //         case 'Pending Users':
    //             setUser(Apiservices.USER_NOT_VERIFYED_LIST)
    //             handleFilter(Apiservices.USER_NOT_VERIFYED_LIST);
           
    //             break;
    //         case 'Rejected Users':
    //             setUser(Apiservices.USER_BLOCKED_LIST)
    //             handleFilter(Apiservices.USER_BLOCKED_LIST);
      
    //             break;
    //         case 'All KYC':
    //             setUser(Apiservices.KYC_TOTAL)
    //             handleFilter(Apiservices.KYC_TOTAL);
    //             break;
    //         case 'Active KYC':
    //             handleFilter(Apiservices.KYC_VERIFIED);
    //             setUser(Apiservices.KYC_VERIFIED)
    //             break;
    //         case 'Pending KYC':
    //             handleFilter(Apiservices.KYC_PENDING);
    //             setUser(Apiservices.KYC_PENDING)
    //             break;
    //         case 'Rejected KYC':
    //             handleFilter(Apiservices.KYC_REJECTED);
    //             setUser(Apiservices.KYC_REJECTED)
    //             break;
    //         default:
    //             break;
    //     }
    // };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        // return `${day}-${month}-${year}`;
        return `${year}-${month}-${day}`;
    };
    const [dateRange, setDateRange] = useState([null, null]);
    const today = new Date();
    const handleDateChange = ([start, end]) => {
 

        const formattedStart = start ? formatDate(start) : null;
        const formattedEnd = end ? formatDate(end) : null;

        setDateRange([start, end]);

        // if (!formattedStart && !formattedEnd) {    
        //     handleFilter(user);
        // }
     

        // if (formattedStart && formattedEnd) {
        //     handleDate({ startDate: formattedStart, endDate: formattedEnd });
        // }
        if (formattedStart && formattedEnd) {
            handleDate({ startDate: formattedStart, endDate: formattedEnd });

        }
        else{
            ipList()
        }
    };
    const handleDate = async ({ startDate, endDate }) => {
    
        setLoading(true);
   

        const postForm={
            startDate:startDate,
            endDate:endDate
        }
        try {
            const queryString = new URLSearchParams(postForm).toString();
            
            const response = await postData(`${Apiservices.SWAP_HISTORY}?${queryString}`);
            if (response.status) {
                setLoading(false);
                if(response.data.length !== 0){
                    setTotalPagination(response.data.totalPages);
                    setTotalLimit(response.data.limit)
                setFormData(response.data.data);
                }
                else{
                    setFormData(response.data);
                    // setDateRange("")
                }
              
                
            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            // toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    let handleDropdownClick = (pair)=>{
           if(pair != "all")
           setinputSearch(pair)
           else 
           setinputSearch("m")

           if(pair != "all")
           setSelectedItem(pair.replace("_","/"))
            else
           setSelectedItem("All")
           // handleSearch1();
        
    }
    let refresh=()=>{
        setinputSearch("")
    }

    function tog_update_list() {
        setisEdit(false);
        setmodal_update_list(!modal_update_list);
        if (modal_update_list) {
            resetFormValues();
            setRecordToUpdate({})
            // clearErrors();
        }

    }

    const resetFormValues = () => {
        validationAdd.setValues({
            pair:'',
            email:'',
            amount:'',
            price:'',
            total:'',
            status:'',
            txid:'',
            fee:'',
        });
    };
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Swap History" breadcrumbItem=" User Swap History" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col sm={4} lg={3}>
                                            <div className="input-group">
                                                <Input
                                                    // onChange={handleInputChange}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search ..."
                                                    aria-label="Recipient's username"
                                                    onChange={handleSearchChanage}
                                                    invalid={searchValid}
                                                    onKeyPress={handleKeyPress}
                                                />
                                                <div className="input-group-append">

                                                    <button className="btn btn-primary" type="submit" onClick={()=>handleSearch()} disabled={searchLoad}>
                                                        {searchLoad ? (

                                                            <Spinner className="text-light" size="sm" />
                                                        ) : (

                                                            <i className="ri-search-line" />
                                                        )}

                                                    </button>


                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={4} lg={3}>
                                        <div className="form-group mb-4">
                                            
                                            <div>
                                                <InputGroup>
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        placeholder="Select Start to End Date"
                                                        options={{
                                                            mode: 'range',
                                                            altInput: true,
                                                            altFormat: "F j, Y",
                                                            dateFormat: "Y-m-d",
                                                            maxDate: today,
                                                            
                                                        }}
                                                       value={dateRange}
                                                        onChange={handleDateChange}
                                                    />
                                                </InputGroup>
                                            </div>

                                        </div>
                                        </Col>

                                        <Col className="col-sm">

                                            <div className="d-flex justify-content-sm-end">
                                                 <Dropdown
                                                    isOpen={btnauto3}
                                                    toggle={() => setbtnauto3(!btnauto3)}
                                                    id="dropdownMenuClickableInside"
                                                >
                                                    <DropdownToggle tag="button" className="btn btn-info">
                                                    {searchLoad1 ? (
        <Spinner className="text-light" size="sm" />
      ) : (
        <div>
          {(selectedItem == "m") ? "All" : selectedItem}
          <i className="mdi mdi-chevron-down" />
        </div>
      )}
                                                        
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start jega-ui">
                                                        <DropdownItem onClick={()=>handleDropdownClick('m')}>All</DropdownItem>

                                                        {formData1 && formData1.map((daa)=>(
                                                        <DropdownItem onClick={() => handleDropdownClick(daa.pair)}>{daa.pair.replace("_","/")}</DropdownItem>
                                                        
                                                        )) }       
                                                                              </DropdownMenu>                       
                                                </Dropdown> 


                                                {/* <Button color="soft-danger"
       
    ><i style={{ fontSize: '15px' }} className="mdi mdi-download-circle-outline"></i>Download</Button> */}
                                            <Button
                          color="info"
                          className="add-btn mx-2"
                          onClick={() => handleDownload('xlsx')}
                          id="create-btn"
                          disabled={downLoading}
                        >
                          <i className="mdi mdi-download-outline"></i>

                          {downLoading ? <Loader /> : "Download"}
                        </Button>

                                            </div>
                                        </Col>
                                    </Row>


                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={formData}
                                            paginationServer
                                            paginationTotalRows={formData.length}
                                            progressPending={loading}
                                            progressComponent={<div>Loading...</div>}
                                            persistTableHead={true}
                                            // noDataComponent={formData.length===0}
                                            noDataComponent={<div className="py-3">No records found</div>}
                                            fixedHeader={true}

                                        />
                                    </div>
                                    {loading ? (
                                        ""

                                    ) : (

                                        <div className="d-flex justify-content-end mt-3">
                                            <div className="pagination pagination-rounded mb-0">
                                                <ReactPaginate
                                                    previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                    nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    activeClassName={"active"}
                                                    pageLinkClassName={"page-link"}
                                                    breakLinkClassName={"break-link"}
                                                    disabledClassName={"disabled"}
                                                    initialPage={currentPage}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                    // disableInitialCallback={true}
                                                    renderLink={(props) => {
                                                        if (
                                                            props.page === "..." &&
                                                            props.pageCount > props.pageRangeDisplayed
                                                        ) {
                                                            return (
                                                                <a
                                                                    {...props}
                                                                    onClick={() =>
                                                                        handlePageClick({
                                                                            selected: pageCount - 1,
                                                                        })
                                                                    }
                                                                >
                                                                    {pageCount}
                                                                </a>
                                                            );
                                                        }
                                                        return <a {...props} />;
                                                    }}
                                                />
                                            </div>


                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
             <Modal className='modal-dialog modal-lg' isOpen={modal_update_list} toggle={() => { tog_update_list(); }} centered >
                    <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>Swap History Details </ModalHeader>
                    <Form
                        autoComplete="off"
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validationAdd.handleSubmit();
                            return false
                        }}
                    >
                        <ModalBody className='d-lg-flex justify-content-around'>

                            <div className="col-lg-5 col-sm-12">

                                <div className="mb-3">
                                    <label htmlFor="fromCurrency-field" className="form-label">Name</label>
                                    <Input
                                        type="text"
                                        style={{border: 'transparent'}}
                                        id="fromCurrency-field"
                                        className="form-control"
                                        placeholder="Enter From Currency"
                                        name="fromCurrency"
                                        value={validationAdd.values.fullName || ""}
                                        readOnly={isEdit ? true : false}
                                    />
                                    
                                </div>


                                <div className="mb-3">
                                    <label htmlFor="fromCurrency-field" className="form-label">Email</label>
                                    <Input
                                        type="text"
                                        id="fromCurrency-field"
                                        className="form-control"
                                        placeholder="Enter From Currency"
                                        name="fromCurrency"
                                        style={{border: 'transparent'}}
                                        value={validationAdd.values.email || ""}
                                        readOnly={isEdit ? true : false}
                                    />
                                    
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="fromCurrency-field" className="form-label">Pair Name</label>
                                    <Input
                                        type="text"
                                        id="fromCurrency-field"
                                        className="form-control"
                                        placeholder="Enter From Currency"
                                        name="fromCurrency"
                                        style={{border: 'transparent'}}
                                        onBlur={validationAdd.handleBlur}
                                        value={validationAdd.values.pair || ""}
                                        invalid={validationAdd.touched.fromCurrency && validationAdd.errors.fromCurrency}
                                        readOnly={isEdit ? true : false}
                                    />
                                    
                                </div>


                                <div className="mb-3">
                                    <label htmlFor="fromCurrency-field" className="form-label">Market price</label>
                                    <Input
                                        type="text"
                                        id="fromCurrency-field"
                                        className="form-control"
                                        placeholder="Enter From Currency"
                                        name="fromCurrency"
                                        style={{border: 'transparent'}}
                                        value={validationAdd.values.price || ""}
                                        readOnly={isEdit ? true : false}
                                    />
                                    
                                </div>


                                <div className="mb-3">
                                    <label htmlFor="fromCurrency-field" className="form-label">Send Currency</label>
                                    <Input
                                        type="text"
                                        id="fromCurrency-field"
                                        className="form-control"
                                        placeholder="Enter From Currency"
                                        name="fromCurrency"
                                        style={{border: 'transparent'}}
                                        value={validationAdd.values.pair.split("_")[0] || ""}
                                        readOnly={isEdit ? true : false}
                                    />
                                    
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="fromCurrency-field" className="form-label">Receive curency</label>
                                    <Input
                                        type="text"
                                        id="fromCurrency-field"
                                        className="form-control"
                                        style={{border: 'transparent'}}
                                        placeholder="Enter From Currency"
                                        name="fromCurrency"
                                        value={validationAdd.values.pair.split("_")[1] || ""}
                                        readOnly={isEdit ? true : false}
                                    />
                                    
                                </div>
                                


                                 

                                </div>

                            <div className="col-lg-5 col-sm-12">


                            <div className="mb-3">
                                    <label htmlFor="fromCurrency-field" className="form-label">Send Amount</label>
                                    <Input
                                        style={{border: 'transparent'}}
                                        type="text"
                                        id="fromCurrency-field"
                                        className="form-control"
                                        placeholder="Enter From Currency"
                                        name="fromCurrency"
                                        value={validationAdd.values.amount || ""}
                                        readOnly={isEdit ? true : false}
                                    />
                                    
                                </div>


                                <div className="mb-3">
                                    <label htmlFor="fromCurrency-field" className="form-label">Receive Amount</label>
                                    <Input
                                        style={{border: 'transparent'}}
                                        type="text"
                                        id="fromCurrency-field"
                                        className="form-control"
                                        placeholder="Enter From Currency"
                                        name="fromCurrency"
                                        value={validationAdd.values.total || ""}
                                        readOnly={isEdit ? true : false}
                                    />
                                    
                                </div>


                                <div className="mb-3">
                                    <label htmlFor="fromCurrency-field" className="form-label">Fee value</label>
                                    <Input
                                        type="text"
                                        style={{border: 'transparent'}}
                                        id="fromCurrency-field"
                                        className="form-control"
                                        placeholder="Enter From Currency"
                                        name="fromCurrency"
                                        value={((parseFloat(validationAdd.values.fee) / parseFloat(validationAdd.values.total)) * 100).toFixed(4)+"%"  || ""}
                                        readOnly={isEdit ? true : false}
                                    />
                                    
                                </div>

                                 <div className="mb-3">
                                    <label htmlFor="fromCurrency-field" className="form-label">Fees amount</label>
                                    <Input
                                        type="text"
                                        style={{border: 'transparent'}}
                                        id="fromCurrency-field"
                                        className="form-control"
                                        placeholder="Enter From Currency"
                                        name="fromCurrency"
                                        value={parseFloat(validationAdd.values.fee).toFixed(8) || ""}
                                        readOnly={isEdit ? true : false}
                                    />
                                    
                                </div>


                                <div className="mb-3">
                                    <label htmlFor="fromCurrency-field" className="form-label">Status</label>
                                    <Input
                                        type="text"
                                        style={{border: 'transparent'}}
                                        id="fromCurrency-field"
                                        className="form-control"
                                        placeholder="Enter From Currency"
                                        name="fromCurrency"
                                        value={validationAdd.values.status || ""}
                                        readOnly={isEdit ? true : false}
                                    />
                                    
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="fromCurrency-field" className="form-label">Date/Time</label>
                                    <Input
                                        type="text"
                                        style={{border: 'transparent'}}
                                        id="fromCurrency-field"
                                        className="form-control"
                                        placeholder="Enter From Currency"
                                        name="fromCurrency"
                                        value={validationAdd.values.createdAt || ""}
                                        readOnly={isEdit ? true : false}
                                    />
                                    
                                </div>



                                </div>
                        </ModalBody>
                    </Form>
                </Modal>
        </React.Fragment>
    );
};

export default SwapHistory;

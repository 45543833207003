import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AdminContext } from "../../helpers/adminContext";
import { useNavigate } from "react-router-dom";
import { useContext,useState,useEffect } from "react";
import toast from "react-hot-toast";


const UserPanel = (formData) => {
  const navigate=useNavigate()
  
  const { adminInfo } = useContext(AdminContext);
  const [adminType, setAdminType] = useState('')
  useEffect(()=>{
    setAdminType(adminInfo?.adminType)
  },[])
const handleTotal=()=>{
  
    if ((adminType === 1 || (adminInfo.permissions && adminInfo.permissions.some(data => 
        data.module === "Dashboard" && data.actions.includes(3))))) {
        navigate('/allUsers');
    }
    else {
      toast.error('Permission denied')
        navigate('/dashboard');
    }
}

// const handleActive=()=>{
//   navigate('/allUsers',{ state: { user:(Apiservices.USER_VERIFIED_LIST)}})
// }

// const handlePending=()=>{
//   navigate('/allUsers',{ state: { user:(Apiservices.USER_NOT_VERIFYED_LIST)} })
// }

// const handleRejected=()=>{
//   navigate('/allUsers',{ state: { user:Apiservices.USER_BLOCKED_LIST} })
// }


const handleActive=()=>{
  if ((adminType === 1 || (adminInfo.permissions && adminInfo.permissions.some(data => 
    data.module === "Dashboard" && data.actions.includes(3))))) {
    navigate('/activeUsers');
}
else {
  toast.error('Permission denied')
    navigate('/dashboard');
}
    
  }

  const handlePending=()=>{
    if ((adminType === 1 || (adminInfo.permissions && adminInfo.permissions.some(data => 
      data.module === "Dashboard" && data.actions.includes(3))))) {
      navigate('/pendingUsers');
  }
  else {
    toast.error('Permission denied')
      navigate('/dashboard');
  }
   
  }
  
  const handleRejected=()=>{
    if ((adminType === 1 || (adminInfo.permissions && adminInfo.permissions.some(data => 
      data.module === "Dashboard" && data.actions.includes(3))))) {
      navigate('/blockedUsers');
  }
  else {
    toast.error('Permission denied')
      navigate('/dashboard');
  }
    
  }
  





  return (
    <React.Fragment>
      <Row>
        <Col xl={3} sm={6}>
          <Card className="bg-info text-light"> 
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    {/* <RadialChart1 /> */}
                    <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-group-line"></i>
                    </div>
                  </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden"  onClick={handleTotal} style={{cursor:'pointer'}}>
                  <p className="mb-1 text-light">Total Users</p>
                  <h4 className="mb-3 text-light"><b>{formData.totalUsers}</b></h4>
                  {/* <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      0.02%{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card className="text-light bg-warning">
            <CardBody>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <span className="text-success">
                      <i className="ri-group-line"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden"  onClick={handleActive} style={{cursor:'pointer'}}>
                  <p className="mb-1 text-light">Active Users</p>
                  <h4 className="mb-3 text-light"><b>{formData.emailIsVerified}</b></h4>
                  {/* <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      1.7%{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card className="bg-success">
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <span className="text-warning">
                      <i className="ri-group-line"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden" onClick={handlePending} style={{cursor:'pointer'}}>
                  <p className="mb-1 text-light">Pending Users</p>
                  <h4 className="mb-3 text-light"><b>{formData.emailPendingVerified}</b></h4>
                  {/* <p className="text-truncate mb-0">
                    <span className="text-danger me-2">
                      {" "}
                      0.01%{" "}
                      <i className="ri-arrow-right-down-line align-bottom ms-1" ></i>
                    </span>{" "}
                    From previous
                  </p> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card className="bg-danger">
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm" style={{borderRadius:'50%'}}>
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <span className="text-danger">
                      <i className="ri-group-line"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden" onClick={handleRejected} style={{cursor:'pointer'}}>
                  <p className="mb-1 text-light">Blocked Users</p>
                  <h4 className="mb-3 text-light"><b>{formData.blockedUser}</b></h4>
                  {/* <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      0.01%{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1" ></i>
                    </span>{" "}
                    From previous
                  </p> */}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserPanel;

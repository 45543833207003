import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody, Form, FormFeedback, Input } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import toast from "react-hot-toast";
import Loader from "../../helpers/Spinner";
import { handleNullValue } from "../../helpers/function";
import DataTable from "react-data-table-component";
import { useFormik } from "formik";
import * as Yup from "yup";
import MenuPermissions, { permissionRequirements } from "./MenuPermission";

const AddAdminUser = () => {

  document.title = "Ultrapro | Add Admin User";
  const [loading, setLoading] = useState(false);
  const [getMenuList, setmenuList] = useState([]);

  useEffect(() => {
    GetMenuList()
  }, []);

  const GetMenuList = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.MenuList);
  
      if (response.status) {
        setmenuList(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      toast.error("Internal Server Error...");
      setLoading(false);
    }
  };

const [permissions, setPermissions] = useState([]);
const handleUpdateStatusClick = (row, action) => {
  const existingPermission = permissions.find((perm) => perm.module === row.name);

  if (existingPermission) {
    const updatedActions = existingPermission.actions.includes(action)
      ? existingPermission.actions.filter((a) => a !== action) 
      : [...existingPermission.actions, action]; 

    // Check if updated actions is empty
    if (updatedActions.length === 0) {
      setPermissions((prevPermissions) =>
        prevPermissions.filter((perm) => perm.module !== row.name)
      );
    } else {
      setPermissions((prevPermissions) =>
        prevPermissions.map((perm) =>
          perm.module === row.name ? { ...perm, actions: updatedActions } : perm
        )
      );
    }
  } else {
    // Add new module with the action
    setPermissions((prevPermissions) => [
      ...prevPermissions,
      { module: row.name, actions: [action] },
    ]);
  }
};

    


const checks = {
  flexibleStakeChecked: permissions.some(perm => perm.module === 'Flexible Staking' && perm.actions.includes(2)),
  WithdrawHistoryChecked: permissions.some(perm => perm.module === 'Withdraw History' && perm.actions.includes(2)),
  settingsChecked: permissions.some(perm => perm.module === 'Settings' && perm.actions.includes(2)),
  stakingChecked: permissions.some(perm => perm.module === 'Staking' && perm.actions.includes(2)),
  votingChecked: permissions.some(perm => perm.module === 'Voting' && perm.actions.includes(2)),
  socialMediaBonusChecked: permissions.some(perm => perm.module === 'SocialMedia Bonus' && perm.actions.includes(2)),
  bankDetailsChecked: permissions.some(perm => perm.module === 'User Bank Details' && perm.actions.includes(2)),
  adminManagementChecked: permissions.some(perm => perm.module === 'Admin Management' && perm.actions.includes(2)),
  tradeHistoryChecked: permissions.some(perm => perm.module === 'Trade History' && perm.actions.includes(2)),
  transactionHistoryChecked: permissions.some(perm => perm.module === 'Transaction History' && perm.actions.includes(2)),
  swapManagementChecked: permissions.some(perm => perm.module === 'Swap Management' && perm.actions.includes(2)),
  userListChecked: permissions.some(perm => perm.module === 'Users List' && perm.actions.includes(2)),
  userManagementChecked: permissions.some(perm => perm.module === 'User Management' && perm.actions.includes(2)),
  requiredDepositMenu: permissions.some(perm => perm.module === 'Deposit History' && perm.actions.includes(2)),
  requiredCustomerTaskMenu: permissions.some(perm => perm.module === 'Task' && perm.actions.includes(2)),
  requiredLiveChatMenu: permissions.some(perm => perm.module === 'Live Chat' && perm.actions.includes(2)),
  requiredFiatDeposit: permissions.some(perm => perm.module === 'Fiat Deposit History' && perm.actions.includes(2)),
  requiredFiatWithdraw: permissions.some(perm => perm.module === 'Fiat Withdraw History' && perm.actions.includes(2)),
  
  
};
function validatePermissions(name,action) {
  console.log(name,action,"88888888888888888888888")
  // Assuming 'row.name' should be replaced with 'name'
  if (permissionRequirements.requiresUserManagement.includes(name) && !checks.userManagementChecked) {
    toast.error('Please enable User Management first.');
    return false; 
  }
  // if (action !== 2) {
  //   toast.error('Please enable view option first.');
  //   return false; 
  // }
  if (permissionRequirements.requiredBankDetails.includes(name) && !checks.bankDetailsChecked) {
    toast.error('Please enable User Bank Details first.');
    return false;
  }
  if (permissionRequirements.requiredAdminManagement.includes(name) && !checks.adminManagementChecked) {
    toast.error('Please enable Admin Management first.');
    return false;
  }
  if (permissionRequirements.requiredSettings.includes(name) && !checks.settingsChecked) {
    toast.error('Please enable Settings first.');
    return false;
  }
  if (permissionRequirements.requiredSocialMediaBonus.includes(name) && !checks.socialMediaBonusChecked) {
    toast.error('Please enable SocialMedia Bonus first.');
    return false;
  }
  if (permissionRequirements.requiredFlexible.includes(name) && !checks.flexibleStakeChecked) {
    toast.error('Please enable Flexible Staking first.');
    return false;
  }
  if (permissionRequirements.requiredStaking.includes(name) && !checks.stakingChecked) {
    toast.error('Please enable Staking first.');
    return false;
  }
  if (permissionRequirements.requiredSwapManagement.includes(name) && !checks.swapManagementChecked) {
    toast.error('Please enable Swap Management first.');
    return false;
  }
  if (permissionRequirements.requiredVoting.includes(name) && !checks.votingChecked) {
    toast.error('Please enable Voting first.');
    return false;
  }
  if (permissionRequirements.requiredTradeHistory.includes(name) && !checks.tradeHistoryChecked) {
    toast.error('Please enable Trade History first.');
    return false;
  }
  if (permissionRequirements.requiredTransactionHistory.includes(name) && !checks.transactionHistoryChecked) {
    toast.error('Please enable Transaction History first.');
    return false;
  }
  if (permissionRequirements.requiresUserList.includes(name) && !checks.userListChecked) {
    toast.error('Please enable Users List first.');
    return false;
  }
  if (permissionRequirements.requiredWithdrawHistory.includes(name) && !checks.WithdrawHistoryChecked) {
    toast.error('Please enable Withdraw History first.');
    return false;
  }
  if (permissionRequirements.requiredDeposit.includes(name) && !checks.requiredDepositMenu) {
    toast.error('Please enable Deposit History first.');
  return false;
  }
  if (permissionRequirements.requiredFiatDeposit.includes(name) && !checks.requiredFiatDeposit) {
    toast.error('Please enable Fiat Deposit History first.');
  return false;
  }
  if (permissionRequirements.requiredFiatWithdraw.includes(name) && !checks.requiredFiatWithdraw) {
    toast.error('Please enable Fiat Withdraw History first.');
  return false;
  }
  if (permissionRequirements.requiredCustomerTask.includes(name) && !checks.requiredCustomerTaskMenu) {
    toast.error('Please enable Task first.');
  return false;
  }
  if (permissionRequirements.requiredLiveChat.includes(name) && !checks.requiredLiveChatMenu) {
    toast.error('Please enable Live chart first.');
  return false;
  }
  return true; // Return true if all checks pass
}


const handlePermissionChange = (row, action) => {
  console.log(action,"22222222222222222222222")
  const isValid = validatePermissions(row.name,action);
  
  if (!isValid) {
    return; // If there's an error, exit early.
  }
  

  handleUpdateStatusClick(row, action);
};


//no permission -0  create-1, view-2, edit-3, delete-4, 
  const columns = [
    {
      name: "S.No",
      // selector: (row, index) => offset + index + 1,
      selector: (row) => handleNullValue(row.serialNo),
      sortable: true,
      grow: 1,
    },
    {
      name: "Menus",
      selector: (row) => handleNullValue(row.name),
      sortable: true,
      grow:2
    },
   
    {
      selector: (row) => {
        const hasPermission = MenuPermissions[row.name]?.includes(1);
        const isChecked = permissions.some(perm => perm.module === row.name && perm.actions.includes(1));
        return (
          <div className="form-check mb-3 d-flex justify-content-center align-items-center">
            <input
              type="checkbox"
              className="form-check-input"
              id={`create-${row.serialNo}`} // unique ID
              checked={isChecked}
              onChange={() => handlePermissionChange(row, 1)}
              disabled={loading || !hasPermission}
            />
            <label className="form-check-label p-1" htmlFor={`create-${row.serialNo}`}>
              Create
            </label>
          </div>
        );
      }
    },    
    {
      selector: (row) => {
        const hasPermission = MenuPermissions[row.name]?.includes(2);
        const isChecked = permissions.some(perm => perm.module === row.name && perm.actions.includes(2));
        // Function to validate permission requirements and return the failed check name
       
        return (
          <div className="form-check mb-3 d-flex justify-content-center align-items-center">
            <input
              type="checkbox"
              className="form-check-input"
              id={`View-${row.serialNo}`}
              checked={isChecked}
              onChange={() => handlePermissionChange(row, 2)}
              disabled={loading || !hasPermission}
            />
            <label className="form-check-label p-1" htmlFor={`View-${row.serialNo}`}>
              View
            </label>
          </div>
        );
      },
      sortable: true,
    },    
    {
      // name: "Edit",
      selector: (row) => {
        const hasPermission = MenuPermissions[row.name]?.includes(3);
        const isChecked = permissions.some(perm => perm.module === row.name && perm.actions.includes(3));
        
        return(
        <div className="form-check mb-3 d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            className="form-check-input"
            id={`Edit-${row.serialNo}`}
            checked={isChecked}
            onChange={() => handlePermissionChange(row, 3)}
            disabled={loading || !hasPermission}
          />
            <label className="form-check-label p-1" htmlFor={`Edit-${row.serialNo}`}>
            Edit
          </label>
        </div>
      );
    },
      sortable: true,
    },
    {
      // name: "Delete",
      selector: (row) => {
        const hasPermission = MenuPermissions[row.name]?.includes(4);
        const isChecked = permissions.some(perm => perm.module === row.name && perm.actions.includes(4));
        
        return(
        <div className="form-check mb-3 d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            className="form-check-input"
            id={`Delete-${row.serialNo}`}
            checked={isChecked}
            onChange={() => handlePermissionChange(row, 4)}
            disabled={loading || !hasPermission}
          />
           <label className="form-check-label p-1" htmlFor={`Delete-${row.serialNo}`}>
            Delete
          </label>
        </div>
      );
    },
      sortable: true,
    },
  ];
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      adminName: "",
      email: "",
      password: "",
      role:"",

    },
    validationSchema: Yup.object({
      adminName: Yup.string().required("Admin Name is required"),
      email: Yup.string().email("Invalid email format").required("Email is required"),
      password: Yup.string().required("Password is required"),
      role: Yup.string().required("Role is required"),
     
    }),
    onSubmit: (values) => {
      handlePost(values);
    },
  });
const [addLoad,setAddLoad] = useState(false)
  const handlePost = async (data) => {
    const postForm = {
      name: data.adminName,
      email: data.email,
      password: data.password,
      role: data.role,
      permissions: permissions, 
      isActive:1
    }
   
    try {
      setAddLoad(true);

      const response = await postData(Apiservices.ADMIN_ADD, postForm);
      if (response.status === true) {
        setAddLoad(false);
        toast.success(response.message);
      } else {
        setAddLoad(false);
        toast.error(response.message);
      }
    } catch (error) {
      setAddLoad(false);
      toast.error(error.data.message);
     
    }
  };

//   const columns1 = [
//     {
//       name: "S.No",
//       // selector: (row, index) => offset + index + 1,
//       selector: (row) => handleNullValue(row.serialNo),
//       sortable: true,
//       grow: 1,
//     },
//     {
//       name: "Menus",
//       selector: (row) => row.label, // You can still use selector for sorting, but JSX goes in the cell
//       cell: (row) => {
//         if (row.issubMenubadge) {
//           return row.label; // If issubMenubadge is true, return the label
//         } else if (row.subItem && row.subItem === true) {
//           return row.sublabel; // If subItem is true, return the sublabel
//         } else if (row.subItem && row.subItem[0] && row.subItem[0].subMenu) {
//           return (
//             <select className="form-control">
//             {/* Option with row.label */}
//             <option key="label" value={row.label}>
//               {row.label}
//             </option>
  
//             {/* Options from subMenu */}
//             {row.subItem[0].subMenu.map((data, index) => (
//               <option key={index} value={data.title}>
//                 {data.title}
//               </option>
//             ))}
//           </select>
//           );
//         } else {
//           return handleNullValue(row.label); // Fallback for null or undefined values
//         }
//       },
//       sortable: true,
//     }
    
// ,    
    
//     // {
//     //   name: "No permission",
//     //   selector: (row) => (
//     //     <div className="form-check mb-3 d-flex justify-content-center align-items-center">
//     //       <input
//     //         type="checkbox"
//     //         className="form-check-input"
//     //         id="customSwitchsizemd"
//     //         checked={permissions.some(perm => perm.module === row.name && perm.actions.includes(0))}
//     //           onChange={() => handleUpdateStatusClick(row,0)}
//     //         disabled={loading}
//     //       />
//     //     </div>
//     //   ),
//     //   sortable: true,
//     // },
//     {
//       // name: "Create",
//       selector: (row) => (
//         <div className="form-check mb-3 d-flex justify-content-center align-items-center">
//           <input
//             type="checkbox"
//             className="form-check-input"
//             id={`create-${row.serialNo}`} // unique ID
//             checked={permissions.some(perm => perm.module === row.name && perm.actions.includes(1))}
//             onChange={() => handleUpdateStatusClick(row, 1)}
//             disabled={loading}
//           />
//           <label className="form-check-label p-1" htmlFor={`create-${row.serialNo}`}>
//             Create
//           </label>
//         </div>
//       ),
//       sortable: true,
//     },
//     {
//       // name: "View",
//       selector: (row) => (
//         <div className="form-check mb-3 d-flex justify-content-center align-items-center">
//           <input
//             type="checkbox"
//             className="form-check-input"
//             id={`View-${row.serialNo}`}
//             checked={permissions.some(perm => perm.module === row.name && perm.actions.includes(2))}
//               onChange={() => handleUpdateStatusClick(row,2)}
//             disabled={loading}
//           />
//             <label className="form-check-label p-1" htmlFor={`View-${row.serialNo}`}>
//             View
//           </label>
//         </div>
//       ),
//       sortable: true,
//     },
//     {
//       // name: "Edit",
//       selector: (row) => (
//         <div className="form-check mb-3 d-flex justify-content-center align-items-center">
//           <input
//             type="checkbox"
//             className="form-check-input"
//             id={`Edit-${row.serialNo}`}
//             checked={permissions.some(perm => perm.module === row.name && perm.actions.includes(3))}
//               onChange={() => handleUpdateStatusClick(row,3)}
//             disabled={loading}
//           />
//             <label className="form-check-label p-1" htmlFor={`Edit-${row.serialNo}`}>
//             Edit
//           </label>
//         </div>
//       ),
//       sortable: true,
//     },
//     {
//       // name: "Delete",
//       selector: (row) => (
//         <div className="form-check mb-3 d-flex justify-content-center align-items-center">
//           <input
//             type="checkbox"
//             className="form-check-input"
//             id={`Delete-${row.serialNo}`}
//             checked={permissions.some(perm => perm.module === row.name && perm.actions.includes(4))}
//               onChange={() => handleUpdateStatusClick(row,4)}
//             disabled={loading}
//           />
//            <label className="form-check-label p-1" htmlFor={`Delete-${row.serialNo}`}>
//             Delete
//           </label>
//         </div>
        
//       ),
//       sortable: true,
//     },
//   ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Admin Management" breadcrumbItem="Add Admin User" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="customername-field" className="form-label">
                            Admin Name
                          </label>
                          <Input
                            type="text"
                            id="customername-field"
                            className="form-control"
                            placeholder="Enter Admin Name"
                            name="adminName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.adminName || ""}
                            invalid={validation.touched.adminName && validation.errors.adminName}
                          />
                          {validation.touched.adminName && validation.errors.adminName && <FormFeedback type="invalid">{validation.errors.adminName}</FormFeedback>}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="customername-field" className="form-label">
                            Password
                          </label>
                          <Input
                            type="text"
                            id="customername-field"
                            className="form-control"
                            placeholder="Enter Password"
                            name="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={validation.touched.password && validation.errors.password}
                          />
                          {validation.touched.password && validation.errors.password && <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>}
                        </div>

                  
                      </div>
                     
                        

                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="customername-field" className="form-label">
                            Email
                          </label>
                          <Input
                            type="text"
                            id="customername-field"
                            className="form-control"
                            placeholder="Enter Email"
                            name="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={validation.touched.email && validation.errors.email}
                          />
                          {validation.touched.email && validation.errors.email && <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>}
                        </div>
                        <div className="mb-3">
                        <label htmlFor="status-field" className="form-label"> Role </label>
                        <select
                          id="status-field"
                          name="role"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.role || ""}
                          className={`form-control ${validation.touched.role && validation.errors.role ? "is-invalid" : ""}`}
                        >
                          <option value="" disabled>
                            Select Role
                          </option>
                          <option value={1}>Super Admin</option>
                          <option value={2}>Sub Admin</option>
                        </select>
                        {validation.touched.role && validation.errors.role && <FormFeedback type="invalid"> {validation.errors.role} </FormFeedback>}
                      </div>

                                         </div>
                     
                    </div>


                   {validation.values.role == 2 && 
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={getMenuList}
                      paginationServer
                      progressPending={loading}
                      persistTableHead={true}
                      progressComponent={<div className="py-3">Loading...</div>}
                      noDataComponent={<div className="py-3">No records found</div>}
                      fixedHeader={true}
                    />
                  </div>
}

                  {/* <div className="user-permissions">
    <div className="permissions-header">
        <div className="header-item">Menu</div>
        <div className="header-item">Permissions</div>
    </div>

    {getMenuList.map((row) => (
        <div key={row.name} className="permissions-row">
            <div className="row-item menu-label">{row.name}</div>

            <div className="row-item">
                
                {row.isLimitedAccess ? (
                    <div>
                       
                        <input
                            type="checkbox"
                            className="form-check-input"
                            checked={permissions.some(perm => perm.module === row.name && perm.actions.includes(4))} // 4 for view
                            onChange={() => handleUpdateStatusClick(row, 4)}
                            disabled={loading}
                        />
                        <label className="permission-label">View</label>
                    </div>
                ) : (
                    <div className="permissions-group">
                       
                        <div className="permission-item">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={permissions.some(perm => perm.module === row.name && perm.actions.includes(1))} // 1 for create
                                onChange={() => handleUpdateStatusClick(row, 1)}
                                disabled={loading}
                            />
                            <label className="permission-label">Create</label>
                        </div>
                        <div className="permission-item">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={permissions.some(perm => perm.module === row.name && perm.actions.includes(2))} // 2 for edit
                                onChange={() => handleUpdateStatusClick(row, 2)}
                                disabled={loading}
                            />
                            <label className="permission-label">Edit</label>
                        </div>
                        <div className="permission-item">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={permissions.some(perm => perm.module === row.name && perm.actions.includes(3))} // 3 for delete
                                onChange={() => handleUpdateStatusClick(row, 3)}
                                disabled={loading}
                            />
                            <label className="permission-label">Delete</label>
                        </div>
                        <div className="permission-item">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={permissions.some(perm => perm.module === row.name && perm.actions.includes(4))} // 4 for view
                                onChange={() => handleUpdateStatusClick(row, 4)}
                                disabled={loading}
                            />
                            <label className="permission-label">View</label>
                        </div>
                    </div>
                )}
            </div>
        </div>
    ))}
</div> */}

{/* <div className="table-responsive">
                    <DataTable
                      columns={columns1}
                      data={SidebarData}
                      paginationServer
                      progressPending={loading}
                      persistTableHead={true}
                      progressComponent={<div className="py-3">Loading...</div>}
                      noDataComponent={<div className="py-3">No records found</div>}
                      fixedHeader={true}
                    />
                  </div> */}

                  <div className="hstack gap-2 justify-content-end">
                      <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={addLoad}>
                        {addLoad ? <Loader /> : "Submit"}
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddAdminUser;

import React, { useState, useEffect, useContext } from "react";
import {
  Input,
  Card,
  CardBody,
  FormFeedback,
  Col,
  Container,
  FormGroup,
  Row,
  Form, Nav, NavLink, NavItem, TabContent, TabPane,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import classnames from "classnames";
import { AdminContext } from "../../helpers/adminContext";


const MaintenanceSettings = () => {
  // const navigate = useNavigate();
  const [modalToggle, setModalToggle] = useState(false);



  document.title = "Ultrapro | Site Settings Management";
  const [siteMaintenanceStatus, setSiteMaintenanceStatus] = useState(false);
  const [siteMaintenanceStatusAndroid, setSiteMaintenanceStatusAndroid] = useState(false);
  const [siteMaintenanceStatusIOS, setSiteMaintenanceStatusIOS] = useState(false);

  const [TradeMaintenanceStatus, setTradeeMaintenanceStatus] = useState(false);
  const [TradeMaintenanceStatusAndroid, setTradeeMaintenanceStatusAndroid] = useState(false);
  const [TradeMaintenanceStatusIos, setTradeeMaintenanceStatusIos] = useState(false);

  const [DepositeMaintenanceStatus, setDepositeMaintenanceStatus] = useState(false);
  const [DepositeMaintenanceStatusAndroid, setDepositeMaintenanceStatusAndroid] = useState(false);
  const [DepositeMaintenanceStatusIos, setDepositeMaintenanceStatusIos] = useState(false);

  const [WithdrawMaintenanceStatus, setWithdrawMaintenanceStatus] = useState(false);
  const [WithdrawMaintenanceStatusAndroid, setWithdrawMaintenanceStatusAndroid] = useState(false);
  const [WithdrawMaintenanceStatusIos, setWithdrawMaintenanceStatusIos] = useState(false);


  const [ipStatus, setIpMaintenanceStatus] = useState(false);
  const [ipMaintenanceStatusAndroid, setIpMaintenanceStatusAndroid] = useState(false);
  const [ipMaintenanceStatusIos, setIpMaintenanceStatusIos] = useState(false);

  const [registerMaintenanceStatus, setRegisterMaintenanceStatus] = useState(false);
  const [registerMaintenanceStatusAndroid, setRegisterMaintenanceStatusAndroid] = useState(false);
  const [registerMaintenanceStatusIos, setRegisterMaintenanceStatusIos] = useState(false);

  const [StakeMaintenanceStatus, setStakeMaintenanceStatus] = useState(false);
  const [StakeMaintenanceStatusAndroid, setStakeMaintenanceStatusAndroid] = useState(false);
  const [StakeMaintenanceStatusIos, setStakeMaintenanceStatusIos] = useState(false);

  
  const [liveChatMaintenanceStatus, setLiveChatMaintenanceStatus] = useState(false);
  const [liveChatMaintenanceStatusAndroid, setLiveChatMaintenanceStatusAndroid] = useState(false);
  const [liveChatMaintenanceStatusIos, setLiveChatMaintenanceStatusIos] = useState(false);

  const [getSiteSettings, setSiteSettings] = useState([]);
  const [webDetails, setWebDetails] = useState([])
  const [androidDetails, setAndroidDetails] = useState([])
  const [iosDetails, setIosDetails] = useState([])
  const [loading, setLoading] = useState(false);


  const [loadingWeb, setLoadingWeb] = useState({ siteWeb: false, registerWeb: false, tradeWeb: false, depositWeb: false, withdrawWeb: false,ipWeb:false,liveChatWeb: false });
  const [loadingAndroid, setLoadingAndroid] = useState({ siteAndroid: false, registerAndroid: false, tradeAndroid: false, depositAndroid: false, withdrawAndroid: false,ipAndroid: false,liveChatAndroid: false });
  const [loadingIOS, setLoadingIOS] = useState({ siteIOS: false, registerIOS: false, tradeIOS: false, depositIOS: false, withdrawIOS: false,ipIOS:false,liveChatIOS:false });

  //Permission List
  const { adminInfo } = useContext(AdminContext);
  const [adminType,setAdminType] = useState('');

  //site
  const siteMaintenanceClick = (event) => {
    setSiteMaintenanceStatus(event.target.checked);
  };
  const siteMaintenanceClickAndriod = (event) => {
    setSiteMaintenanceStatusAndroid(event.target.checked);
  };
  const siteMaintenanceClickIOS = (event) => {
    setSiteMaintenanceStatusIOS(event.target.checked);
  };

  //trade
  const TradeMaintenanceClick = (event) => {
    setTradeeMaintenanceStatus(event.target.checked);
  };

  const TradeMaintenanceClickAndroid = (event) => {
    setTradeeMaintenanceStatusAndroid(event.target.checked);
  };
  const TradeMaintenanceClickIos = (event) => {
    setTradeeMaintenanceStatusIos(event.target.checked);
  };

  // LiveChat
   const liveChatMaintenanceClick = (event) => {
    setLiveChatMaintenanceStatus(event.target.checked);
  };

  const liveChatMaintenanceClickAndroid = (event) => {
    setLiveChatMaintenanceStatusAndroid(event.target.checked);
  };
  const liveChatMaintenanceClickIos = (event) => {
    setLiveChatMaintenanceStatusIos(event.target.checked);
  };
  //deposit
  const DepositeMaintenanceClick = (event) => {
    setDepositeMaintenanceStatus(event.target.checked);
  };
  const DepositeMaintenanceClickAndroid = (event) => {
    setDepositeMaintenanceStatusAndroid(event.target.checked);
  };
  const DepositeMaintenanceClickIos = (event) => {
    setDepositeMaintenanceStatusIos(event.target.checked);
  };
  //withdraw
  const WithdrawMaintenanceClick = (event) => {
    setWithdrawMaintenanceStatus(event.target.checked);
  };
  const WithdrawMaintenanceClickAndroid = (event) => {
    setWithdrawMaintenanceStatusAndroid(event.target.checked);
  };
  const WithdrawMaintenanceClickIos = (event) => {
    setWithdrawMaintenanceStatusIos(event.target.checked);
  };
  //register
  const RegisterMaintenanceClick = (event) => {
    setRegisterMaintenanceStatus(event.target.checked);
  };
  const RegisterMaintenanceClickAndroid = (event) => {
    setRegisterMaintenanceStatusAndroid(event.target.checked);
  };
  const RegisterMaintenanceClickIos = (event) => {
    setRegisterMaintenanceStatusIos(event.target.checked);
  };

  // IP MAintance
  const ipMaintenanceClick = (event) => {
    setIpMaintenanceStatus(event.target.checked);
  };
  const ipMaintenanceClickAndroid = (event) => {
    setIpMaintenanceStatusAndroid(event.target.checked);
  };
  const ipMaintenanceClickIos = (event) => {
    setIpMaintenanceStatusIos(event.target.checked);
  };
//stake maintenance
  const StakeMaintenanceClick = (event) => {
    setStakeMaintenanceStatus(event.target.checked);
  };
  const StakeMaintenanceClickAndroid = (event) => {
    setStakeMaintenanceStatusAndroid(event.target.checked);
  };
  const StakeMaintenanceClickIos = (event) => {
    setStakeMaintenanceStatusIos(event.target.checked);
  };
  const handleToggle = () => {
    setLoading(true);
    // Perform your update logic here (e.g., API call)
    // After the operation is done, set loading to false and close the modal
    setTimeout(() => {
      setLoading(false);
      setModalToggle(false);
    }, 2000); // Simulating API call
  };


  useEffect(() => {
    fetchWebDetails()
    fetchAndroidDetails()
    fetchIOSDetails()
    setAdminType(adminInfo?.adminType);

  }, [])

  const fetchWebDetails = async () => {
    try {
      const response = await postData(Apiservices.webMaintenaceList);
      if (response) {
        if (response.data.length !== 0) {
          setWebDetails(response.data[0]);
          sitewebformik.setValues({ siteMaintenanceMessage: response?.data[0]?.siteMaintenanceMessage })
          Registerwebformik.setValues({ registerMaintenanceMessage: response?.data[0]?.registerMaintenanceMessage })
          Depositwebformik.setValues({ depositMaintenanceMessage: response?.data[0]?.depositMaintenanceMessage })
          Withdrawwebformik.setValues({ withdrawMaintenanceMessage: response?.data[0]?.withdrawMaintenanceMessage })
          Tradewebformik.setValues({ tradeMaintenanceMessage: response?.data[0]?.tradeMaintenanceMessage })
          ipWebformik.setValues({ ipBlockMessage: response?.data[0]?.ipBlockMessage })
          Stakewebformik.setValues({ stakeMaintenanceMessage: response?.data[0]?.stakingMaintenanceMessage })
          liveChatwebformik.setValues({ liveChatMaintenanceMessage: response?.data[0]?.liveChatMaintenanceMessage })

        } else {
          setWebDetails(response.data);
        }
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle fetch error
    }
  };
  const fetchAndroidDetails = async () => {
    try {
      const response = await postData(Apiservices.AndroidMaintenaceList);

      if (response) {
        if (response.data.length !== 0) {
          setAndroidDetails(response.data[0]);
          siteAndroidformik.setValues({ siteMaintenanceMessage: response.data[0].siteMaintenanceMessage })
          RegisterAndroidformik.setValues({ registerMaintenanceMessage: response.data[0].registerMaintenanceMessage })
          DepositAndroidformik.setValues({ depositMaintenanceMessage: response.data[0].depositMaintenanceMessage })
          WithdrawAndroidformik.setValues({ withdrawMaintenanceMessage: response.data[0].withdrawMaintenanceMessage })
          TradeAndroidformik.setValues({ tradeMaintenanceMessage: response.data[0].tradeMaintenanceMessage })
          StakeAndroidformik.setValues({ StakeMaintenanceMessage: response.data[0].stakingMaintenanceMessage })
          ipAndroidformik.setValues({ ipBlockMessage: response.data[0].ipBlockMessage })
          liveChatAndroidformik.setValues({ liveChatMaintenanceMessage: response?.data[0]?.liveChatMaintenanceMessage })

        } else {
          setAndroidDetails(response.data);
        }
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle fetch error
    }
  };

  const fetchIOSDetails = async () => {
    try {
      const response = await postData(Apiservices.IOSMaintenaceList);

      if (response) {
        if (response.data.length !== 0) {
          setIosDetails(response.data[0]);
          siteIOSformik.setValues({ siteMaintenanceMessage: response.data[0].siteMaintenanceMessage })
          RegisterIOSformik.setValues({ registerMaintenanceMessage: response.data[0].registerMaintenanceMessage })
          DepositIOSformik.setValues({ depositMaintenanceMessage: response.data[0].depositMaintenanceMessage })
          WithdrawIOSformik.setValues({ withdrawMaintenanceMessage: response.data[0].withdrawMaintenanceMessage })
          TradeIOSformik.setValues({ tradeMaintenanceMessage: response.data[0].tradeMaintenanceMessage })
          StakeIOSformik.setValues({ StakeMaintenanceMessage: response.data[0].stakingMaintenanceMessage })
          ipIOSformik.setValues({ ipBlockMessage: response.data[0].ipBlockMessage })
          liveChatIOSformik.setValues({ liveChatMaintenanceMessage: response?.data[0]?.liveChatMaintenanceMessage })
          


        } else {
          setIosDetails(response.data);
        }
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle fetch error
    }
  };

  useEffect(() => {

    //site
    if (webDetails.siteMaintenance === 1) {
      setSiteMaintenanceStatus(true);
    } else {
      setSiteMaintenanceStatus(false);
    }
    if (androidDetails.siteMaintenance === 1) {
      setSiteMaintenanceStatusAndroid(true);
    } else {
      setSiteMaintenanceStatusAndroid(false);
    }
    if (iosDetails.siteMaintenance === 1) {
      setSiteMaintenanceStatusIOS(true);
    } else {
      setSiteMaintenanceStatusIOS(false);
    }

    //trade

    if (webDetails.tradeMaintenance === 1) {
      setTradeeMaintenanceStatus(true);
    } else {
      setTradeeMaintenanceStatus(false);
    }
    if (androidDetails.tradeMaintenance === 1) {
      setTradeeMaintenanceStatusAndroid(true);
    } else {
      setTradeeMaintenanceStatusAndroid(false);
    }
    if (iosDetails.tradeMaintenance === 1) {
      setTradeeMaintenanceStatusIos(true);
    } else {
      setTradeeMaintenanceStatusIos(false);
    }

    //liveChat

    if (webDetails.liveChatMaintenance === 1) {
      setLiveChatMaintenanceStatus(true);
    } else {
      setLiveChatMaintenanceStatus(false);
    }
    if (androidDetails.liveChatMaintenance === 1) {
      setLiveChatMaintenanceStatusAndroid(true);
    } else {
      setLiveChatMaintenanceStatusAndroid(false);
    }
    if (iosDetails.liveChatMaintenance === 1) {
      setLiveChatMaintenanceStatusIos(true);
    } else {
      setLiveChatMaintenanceStatusIos(false);
    }
  //stake

  if (webDetails.stakingMaintenance === 1) {
    setStakeMaintenanceStatus(true);
  } else {
    setStakeMaintenanceStatus(false);
  }
  if (androidDetails.stakingMaintenance === 1) {
    setStakeMaintenanceStatusAndroid(true);
  } else {
    setStakeMaintenanceStatusAndroid(false);
  }
  if (iosDetails.stakingMaintenance === 1) {
    setStakeMaintenanceStatusIos(true);
  } else {
    setStakeMaintenanceStatusIos(false);
  }

    //deposit
    if (webDetails.depositMaintenance === 1) {
      setDepositeMaintenanceStatus(true);
    } else {
      setDepositeMaintenanceStatus(false);
    }
    if (androidDetails.depositMaintenance === 1) {
      setDepositeMaintenanceStatusAndroid(true);
    } else {
      setDepositeMaintenanceStatusAndroid(false);
    }
    if (iosDetails.depositMaintenance === 1) {
      setDepositeMaintenanceStatusIos(true);
    } else {
      setDepositeMaintenanceStatusIos(false);
    }

    //withdraw
    if (webDetails.withdrawMaintenance === 1) {
      setWithdrawMaintenanceStatus(true);
    } else {
      setWithdrawMaintenanceStatus(false);
    }
    if (androidDetails.withdrawMaintenance === 1) {
      setWithdrawMaintenanceStatusAndroid(true);
    } else {
      setWithdrawMaintenanceStatusAndroid(false);
    }
    if (iosDetails.withdrawMaintenance === 1) {
      setWithdrawMaintenanceStatusIos(true);
    } else {
      setWithdrawMaintenanceStatusIos(false);
    }

    // IP

        //withdraw
        if (webDetails.ipBlock === 1) {
          setIpMaintenanceStatus(true);
        } else {
          setIpMaintenanceStatus(false);
        }
        if (androidDetails.ipBlock === 1) {
          setIpMaintenanceStatusAndroid(true);
        } else {
          setIpMaintenanceStatusAndroid(false);
        }
        if (iosDetails.ipBlock === 1) {
          setIpMaintenanceStatusIos(true);
        } else {
          setIpMaintenanceStatusIos(false);
        }



    //register
    if (webDetails.registerMaintenance === 1) {
      setRegisterMaintenanceStatus(true);
    } else {
      setRegisterMaintenanceStatus(false);
    }
    if (androidDetails.registerMaintenance === 1) {
      setRegisterMaintenanceStatusAndroid(true);
    } else {
      setRegisterMaintenanceStatusAndroid(false);
    }
    if (iosDetails.registerMaintenance === 1) {
      setRegisterMaintenanceStatusIos(true);
    } else {
      setRegisterMaintenanceStatusIos(false);
    }
  }, [webDetails, androidDetails, iosDetails]);

  // ----------WEB

  //site web
  const sitewebformik = useFormik({
    initialValues: {
      siteMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      siteMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {

      try {
        setLoadingWeb((prevState) => ({ ...prevState, siteWeb: true }));
        const data = {
          siteMaintenance: siteMaintenanceStatus === true ? 1 : 0,
          siteMaintenanceMessage: values.siteMaintenanceMessage,
          MaintenanceType: "UltraPro_Web",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceWebCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceWebUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, siteWeb: false }));
          fetchWebDetails()
        } else {
          toast.error(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, siteWeb: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingWeb((prevState) => ({ ...prevState, siteWeb: false }));
      }
    },
  });
  //register web
  const Registerwebformik = useFormik({
    initialValues: {
      registerMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      registerMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingWeb((prevState) => ({ ...prevState, registerWeb: true }));
        const data = {
          registerMaintenance: registerMaintenanceStatus === true ? 1 : 0,
          registerMaintenanceMessage: values.registerMaintenanceMessage,
          MaintenanceType: "UltraPro_Web",
          adminType:adminInfo?.adminType,
permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceWebCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceWebUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, registerWeb: false }));
          fetchWebDetails()
        } else {
          toast.error(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, registerWeb: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingWeb((prevState) => ({ ...prevState, registerWeb: false }));
      }
    },
  });
  //deposit web
  const Depositwebformik = useFormik({
    initialValues: {
      depositMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      depositMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingWeb((prevState) => ({ ...prevState, depositWeb: true }));
        const data = {
          depositMaintenance: DepositeMaintenanceStatus === true ? 1 : 0,
          depositMaintenanceMessage: values.depositMaintenanceMessage,
          MaintenanceType: "UltraPro_Web",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceWebCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceWebUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, depositWeb: false }));
          fetchWebDetails()
        } else {
          toast.error(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, depositWeb: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingWeb((prevState) => ({ ...prevState, depositWeb: false }));
      }
    },
  });

  //withdraw web
  const Withdrawwebformik = useFormik({
    initialValues: {
      withdrawMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      withdrawMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingWeb((prevState) => ({ ...prevState, withdrawWeb: true }));

        const data = {
          withdrawMaintenance: WithdrawMaintenanceStatus === true ? 1 : 0,
          withdrawMaintenanceMessage: values.withdrawMaintenanceMessage,
          MaintenanceType: "UltraPro_Web",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceWebCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceWebUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, withdrawWeb: false }));

          fetchWebDetails()
        } else {
          toast.error(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, withdrawWeb: false }));

        }
      } catch (error) {
        console.error(error);
        setLoadingWeb((prevState) => ({ ...prevState, withdrawWeb: false }));

      }
    },
  });
  //trade web
  const Tradewebformik = useFormik({
    initialValues: {
      tradeMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      tradeMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingWeb((prevState) => ({ ...prevState, tradeWeb: true }));
        const data = {
          tradeMaintenance: TradeMaintenanceStatus === true ? 1 : 0,
          tradeMaintenanceMessage: values.tradeMaintenanceMessage,
          MaintenanceType: "UltraPro_Web",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceWebCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceWebUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, tradeWeb: false }));
          fetchWebDetails()
        } else {
          toast.error(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, tradeWeb: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingWeb((prevState) => ({ ...prevState, tradeWeb: false }));
      }
    },
  });

    //liveChat web
    const liveChatwebformik = useFormik({
      initialValues: {
        liveChatMaintenanceMessage: ''
      },
      validationSchema: Yup.object({
        liveChatMaintenanceMessage: Yup.string().required("Message is Required"),
      }),
      onSubmit: async (values) => {
        try {
          setLoadingWeb((prevState) => ({ ...prevState, liveChatWeb: true }));
          const data = {
            liveChatMaintenance: liveChatMaintenanceStatus === true ? 1 : 0,
            liveChatMaintenanceMessage: values.liveChatMaintenanceMessage,
            MaintenanceType: "UltraPro_Web",
            adminType:adminInfo?.adminType,
            permissions:adminInfo?.permissions
          };
          //   const response = await postData(Apiservices.SiteMaintenanceWebCreate, data)
          const response = await postData(Apiservices.SiteMaintenanceWebUpdate, data);
          if (response.status) {
            toast.success(response.message);
            setLoadingWeb((prevState) => ({ ...prevState, liveChatWeb: false }));
            fetchWebDetails()
          } else {
            toast.error(response.message);
            setLoadingWeb((prevState) => ({ ...prevState, liveChatWeb: false }));
          }
        } catch (error) {
          console.error(error);
          setLoadingWeb((prevState) => ({ ...prevState, liveChatWeb: false }));
        }
      },
    });
   //stake web
   const Stakewebformik = useFormik({
    initialValues: {
      stakeMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      stakeMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingWeb((prevState) => ({ ...prevState, stakeWeb: true }));
        const data = {
          stakingMaintenance: StakeMaintenanceStatus === true ? 1 : 0,
          stakingMaintenanceMessage: values.stakeMaintenanceMessage,
          MaintenanceType: "UltraPro_Web",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceWebCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceWebUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, stakeWeb: false }));
          fetchWebDetails()
        } else {
          toast.error(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, stakeWeb: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingWeb((prevState) => ({ ...prevState, stakeWeb: false }));
      }
    },
  });

  const ipWebformik = useFormik({
    initialValues: {
      ipBlockMessage: ''
    },
    validationSchema: Yup.object({
      ipBlockMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingWeb((prevState) => ({ ...prevState, ipWeb: true }));
        const data = {
          ipBlock: ipStatus === true ? 1 : 0,
          ipBlockMessage: values.ipBlockMessage,
          MaintenanceType: "UltraPro_Web",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceWebCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceWebUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, ipWeb: false }));
          fetchWebDetails()
        } else {
          toast.error(response.message);
          setLoadingWeb((prevState) => ({ ...prevState, ipWeb: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingWeb((prevState) => ({ ...prevState, ipWeb: false }));
      }
    },
  });


  // ----------ANDROID

  //site android
  const siteAndroidformik = useFormik({
    initialValues: {
      siteMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      siteMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingAndroid((prevState) => ({ ...prevState, siteAndroid: true }));
        const data = {
          siteMaintenance: siteMaintenanceStatusAndroid === true ? 1 : 0,
          siteMaintenanceMessage: values.siteMaintenanceMessage,
          MaintenanceType: "UltraPro_Android",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        // const response = await postData(Apiservices.SiteMaintenanceAndroidCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceAndroidUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, siteAndroid: false }));
          fetchAndroidDetails()
        } else {
          toast.error(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, siteAndroid: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingAndroid((prevState) => ({ ...prevState, siteAndroid: false }));
      }
    },
  });
  //register android
  const RegisterAndroidformik = useFormik({
    initialValues: {
      registerMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      registerMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingAndroid((prevState) => ({ ...prevState, registerAndroid: true }));

        const data = {
          registerMaintenance: registerMaintenanceStatusAndroid === true ? 1 : 0,
          registerMaintenanceMessage: values.registerMaintenanceMessage,
          MaintenanceType: "UltraPro_Android",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceAndroidCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceAndroidUpdate, data);
        if (response.status) {
          toast.success(response.message);
          fetchAndroidDetails()
          setLoadingAndroid((prevState) => ({ ...prevState, registerAndroid: false }));
        } else {
          toast.error(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, registerAndroid: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingAndroid((prevState) => ({ ...prevState, registerAndroid: false }));
      }
    },
  });
  //deposit Android
  const DepositAndroidformik = useFormik({
    initialValues: {
      depositMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      depositMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingAndroid((prevState) => ({ ...prevState, depositAndroid: true }));

        const data = {
          depositMaintenance: DepositeMaintenanceStatusAndroid === true ? 1 : 0,
          depositMaintenanceMessage: values.depositMaintenanceMessage,
          MaintenanceType: "UltraPro_Android",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceAndroidCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceAndroidUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, depositAndroid: false }));
          fetchAndroidDetails()
        } else {
          toast.error(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, depositAndroid: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingAndroid((prevState) => ({ ...prevState, depositAndroid: false }));
      }
    },
  });
  //withdraw Android
  const WithdrawAndroidformik = useFormik({
    initialValues: {
      withdrawMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      withdrawMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingAndroid((prevState) => ({ ...prevState, withdrawAndroid: true }));
        const data = {
          withdrawMaintenance: WithdrawMaintenanceStatusAndroid === true ? 1 : 0,
          withdrawMaintenanceMessage: values.withdrawMaintenanceMessage,
          MaintenanceType: "UltraPro_Android",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceAndroidCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceAndroidUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, withdrawAndroid: false }));
          fetchAndroidDetails()
        } else {
          toast.error(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, withdrawAndroid: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingAndroid((prevState) => ({ ...prevState, withdrawAndroid: false }));
      }
    },
  });
  //trade Android
  const TradeAndroidformik = useFormik({
    initialValues: {
      tradeMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      tradeMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingAndroid((prevState) => ({ ...prevState, tradeAndroid: true }));
        const data = {
          tradeMaintenance: TradeMaintenanceStatusAndroid === true ? 1 : 0,
          tradeMaintenanceMessage: values.tradeMaintenanceMessage,
          MaintenanceType: "UltraPro_Android",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceAndroidCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceAndroidUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, tradeAndroid: false }));
          fetchAndroidDetails()
        } else {
          toast.error(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, tradeAndroid: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingAndroid((prevState) => ({ ...prevState, tradeAndroid: false }));
      }
    },
  });
  //liveChat Android
  const liveChatAndroidformik = useFormik({
    initialValues: {
      liveChatMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      liveChatMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingAndroid((prevState) => ({ ...prevState, liveChatAndroid: true }));
        const data = {
          liveChatMaintenance: liveChatMaintenanceStatusAndroid === true ? 1 : 0,
          liveChatMaintenanceMessage: values.liveChatMaintenanceMessage,
          MaintenanceType: "UltraPro_Android",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceAndroidCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceAndroidUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, liveChatAndroid: false }));
          fetchAndroidDetails()
        } else {
          toast.error(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, liveChatAndroid: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingAndroid((prevState) => ({ ...prevState, liveChatAndroid: false }));
      }
    },
  });
  //stake Android
  const StakeAndroidformik = useFormik({
    initialValues: {
      StakeMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      StakeMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingAndroid((prevState) => ({ ...prevState, stakeAndroid: true }));
        const data = {
          stakingMaintenance: StakeMaintenanceStatusAndroid === true ? 1 : 0,
          stakingMaintenanceMessage: values.StakeMaintenanceMessage,
          MaintenanceType: "UltraPro_Android",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceAndroidCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceAndroidUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, stakeAndroid: false }));
          fetchAndroidDetails()
        } else {
          toast.error(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, stakeAndroid: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingAndroid((prevState) => ({ ...prevState, stakeAndroid: false }));
      }
    },
  });
  const ipAndroidformik = useFormik({
    initialValues: {
      ipBlockMessage: ''
    },
    validationSchema: Yup.object({
      ipBlockMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingAndroid((prevState) => ({ ...prevState, ipAndroid: true }));
        const data = {
          ipBlock: ipStatus === true ? 1 : 0,
          ipBlockMessage: values.ipBlockMessage,
          MaintenanceType: "UltraPro_Android",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions
        };
        //   const response = await postData(Apiservices.SiteMaintenanceAndroidCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceAndroidUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, ipAndroid: false }));
          fetchAndroidDetails()
        } else {
          toast.error(response.message);
          setLoadingAndroid((prevState) => ({ ...prevState, ipAndroid: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingAndroid((prevState) => ({ ...prevState, ipAndroid: false }));
      }
    },
  });



  // ----------IOS


  //site IOS
  const siteIOSformik = useFormik({
    initialValues: {
      siteMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      siteMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingIOS((prevState) => ({ ...prevState, siteIOS: true }));
        const data = {
          siteMaintenance: siteMaintenanceStatusIOS === true ? 1 : 0,
          siteMaintenanceMessage: values.siteMaintenanceMessage,
          MaintenanceType: "UltraPro_iOS",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions

        };
        //  const response = await postData(Apiservices.SiteMaintenanceIOSCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceIOSUpdate, data);
        if (response.status) {
          toast.success(response.message);
          fetchIOSDetails()
          setLoadingIOS((prevState) => ({ ...prevState, siteIOS: false }));
        } else {
          toast.error(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, siteIOS: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingIOS((prevState) => ({ ...prevState, siteIOS: false }));
      }
    },
  });

  //register IOS
  const RegisterIOSformik = useFormik({
    initialValues: {
      registerMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      registerMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingIOS((prevState) => ({ ...prevState, registerIOS: true }));

        const data = {
          registerMaintenance: registerMaintenanceStatusIos === true ? 1 : 0,
          registerMaintenanceMessage: values.registerMaintenanceMessage,
          MaintenanceType: "UltraPro_iOS",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions,
        };
        //   const response = await postData(Apiservices.SiteMaintenanceIOSCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceIOSUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, registerIOS: false }));
          fetchIOSDetails()
        } else {
          toast.error(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, registerIOS: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingIOS((prevState) => ({ ...prevState, registerIOS: false }));
      }
    },
  });

  //deposit IOS
  const DepositIOSformik = useFormik({
    initialValues: {
      depositMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      depositMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingIOS((prevState) => ({ ...prevState, depositIOS: true }));
        const data = {
          depositMaintenance: DepositeMaintenanceStatusIos === true ? 1 : 0,
          depositMaintenanceMessage: values.depositMaintenanceMessage,
          MaintenanceType: "UltraPro_iOS",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions,
        };
        //   const response = await postData(Apiservices.SiteMaintenanceIOSCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceIOSUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, depositIOS: false }));
          fetchIOSDetails()
        } else {
          toast.error(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, depositIOS: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingIOS((prevState) => ({ ...prevState, depositIOS: false }));
      }
    },
  });

  //withdraw IOS
  const WithdrawIOSformik = useFormik({
    initialValues: {
      withdrawMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      withdrawMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingIOS((prevState) => ({ ...prevState, withdrawIOS: true }));

        const data = {
          withdrawMaintenance: WithdrawMaintenanceStatusIos === true ? 1 : 0,
          withdrawMaintenanceMessage: values.withdrawMaintenanceMessage,
          MaintenanceType: "UltraPro_iOS",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions,
        };
        //   const response = await postData(Apiservices.SiteMaintenanceIOSCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceIOSUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, withdrawIOS: false }));
          fetchIOSDetails()
        } else {
          toast.error(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, withdrawIOS: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingIOS((prevState) => ({ ...prevState, withdrawIOS: false }));
      }
    },
  });

  //trade IOS
  const TradeIOSformik = useFormik({
    initialValues: {
      tradeMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      tradeMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingIOS((prevState) => ({ ...prevState, tradeIOS: true }));

        const data = {
          tradeMaintenance: TradeMaintenanceStatusIos === true ? 1 : 0,
          tradeMaintenanceMessage: values.tradeMaintenanceMessage,
          MaintenanceType: "UltraPro_iOS",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions,
        };
        //   const response = await postData(Apiservices.SiteMaintenanceIOSCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceIOSUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, tradeIOS: false }));
          fetchIOSDetails()
        } else {
          toast.error(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, tradeIOS: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingIOS((prevState) => ({ ...prevState, tradeIOS: false }));
      }
    },
  });

  //liveChat IOS
  const liveChatIOSformik = useFormik({
    initialValues: {
      liveChatMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      liveChatMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingIOS((prevState) => ({ ...prevState, liveChatIOS: true }));

        const data = {
          liveChatMaintenance: liveChatMaintenanceStatusIos === true ? 1 : 0,
          liveChatMaintenanceMessage: values.liveChatMaintenanceMessage,
          MaintenanceType: "UltraPro_iOS",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions,
        };
        //   const response = await postData(Apiservices.SiteMaintenanceIOSCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceIOSUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, liveChatIOS: false }));
          fetchIOSDetails()
        } else {
          toast.error(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, liveChatIOS: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingIOS((prevState) => ({ ...prevState, liveChatIOS: false }));
      }
    },
  });
  //stake IOS
  const StakeIOSformik = useFormik({
    initialValues: {
      StakeMaintenanceMessage: ''
    },
    validationSchema: Yup.object({
      StakeMaintenanceMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingIOS((prevState) => ({ ...prevState, stakeIOS: true }));

        const data = {
          stakingMaintenance: StakeMaintenanceStatusIos === true ? 1 : 0,
          stakingMaintenanceMessage: values.StakeMaintenanceMessage,
          MaintenanceType: "UltraPro_iOS",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions,
        };
        //   const response = await postData(Apiservices.SiteMaintenanceIOSCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceIOSUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, stakeIOS: false }));
          fetchIOSDetails()
        } else {
          toast.error(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, stakeIOS: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingIOS((prevState) => ({ ...prevState, stakeIOS: false }));
      }
    },
  });



  const ipIOSformik = useFormik({
    initialValues: {
      ipBlockMessage: ''
    },
    validationSchema: Yup.object({
      ipBlockMessage: Yup.string().required("Message is Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoadingIOS((prevState) => ({ ...prevState, ipIOS: true }));

        const data = {
          ipBlock: ipStatus === true ? 1 : 0,
          ipBlockMessage: values.ipBlockMessage,
          MaintenanceType: "UltraPro_iOS",
          adminType:adminInfo?.adminType,
          permissions:adminInfo?.permissions,
        };
        //   const response = await postData(Apiservices.SiteMaintenanceIOSCreate, data)
        const response = await postData(Apiservices.SiteMaintenanceIOSUpdate, data);
        if (response.status) {
          toast.success(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, ipIOS: false }));
          fetchIOSDetails()
        } else {
          toast.error(response.message);
          setLoadingIOS((prevState) => ({ ...prevState, ipIOS: false }));
        }
      } catch (error) {
        console.error(error);
        setLoadingIOS((prevState) => ({ ...prevState, ipIOS: false }));
      }
    },
  });

  const [activeTab, setactiveTab] = useState("1");
  const toggle = async (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);

    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Settings" breadcrumbItem="Maintenance Settings" />

          <Row>
            <Col lg={12} className="row">
              <Card style={{ backgroundColor: "transparent", boxShadow: "unset" }}>
                <CardBody>
                  <Row>
                    <Card>
                      <CardBody>
                        <Nav tabs className="custom-nav-tabs">
                          <NavItem>
                            <NavLink style={{ cursor: "pointer" }}
                              className={classnames({ active: activeTab === "1", })}
                              onClick={() => { toggle("1"); }} >
                              <i className="mdi mdi-web"></i>{" "}
                              Site Maintenance for Web
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink style={{ cursor: "pointer" }}
                              className={classnames({ active: activeTab === "2", })}
                              onClick={() => { toggle("2"); }} >
                              <i className="mdi mdi-android"></i>{" "}
                              Site Maintenance for Android
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink style={{ cursor: "pointer" }}
                              className={classnames({ active: activeTab === "3", })}
                              onClick={() => { toggle("3"); }} >
                              <i className="mdi mdi-apple-ios"></i>{" "}
                              Site Maintenace for IOS
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} className="p-3">
                          <TabPane tabId="1" id="home">
                            <Row className="mt-3">
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={sitewebformik.handleSubmit}>
                                      <h5 className="card-title">Site Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                        {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                          <>
                                            <p>Status</p>
                                            <div className="form-check form-switch form-switch-md mb-3">
                                              <input 
                                                type="checkbox" 
                                                className="form-check-input" 
                                                id="siteMaintenance"
                                                name="siteMaintenance"
                                                checked={siteMaintenanceStatus}
                                                onChange={siteMaintenanceClick}
                                              />
                                            </div>
                                          </>
                                        ) : null}
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="siteMaintenanceMessage"
                                          id="siteMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={sitewebformik.values.siteMaintenanceMessage}
                                          onChange={sitewebformik.handleChange}
                                          onBlur={sitewebformik.handleBlur}
                                          autoComplete="off"
                                          invalid={sitewebformik.touched.siteMaintenanceMessage && !!sitewebformik.errors.siteMaintenanceMessage}
                                        />
                                        <FormFeedback>{sitewebformik.errors.siteMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light">
                                          {loadingWeb.siteWeb ? 'Loading...' : 'Submit'}
                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={Registerwebformik.handleSubmit}>
                                      <h5 className="card-title">Register Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={registerMaintenanceStatus}
                                            onChange={RegisterMaintenanceClick}
                                          />
                                        </div>
                                        </>
                                      ): null}
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="registerMaintenanceMessage"
                                          id="registerMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={Registerwebformik.values.registerMaintenanceMessage}
                                          onChange={Registerwebformik.handleChange}
                                          onBlur={Registerwebformik.handleBlur}
                                          autoComplete="off"
                                          invalid={Registerwebformik.touched.registerMaintenanceMessage && !!Registerwebformik.errors.registerMaintenanceMessage}
                                        />
                                        <FormFeedback>{Registerwebformik.errors.registerMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingWeb.registerWeb ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null}
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>

                            </Row>
                            <Row>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={Depositwebformik.handleSubmit}>
                                      <h5 className="card-title">Deposit Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            onChange={DepositeMaintenanceClick}
                                            checked={DepositeMaintenanceStatus}
                                          />
                                          
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="depositMaintenanceMessage"
                                          id="depositMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={Depositwebformik.values.depositMaintenanceMessage}
                                          onChange={Depositwebformik.handleChange}
                                          onBlur={Depositwebformik.handleBlur}
                                          autoComplete="off"
                                          invalid={Depositwebformik.touched.depositMaintenanceMessage && !!Depositwebformik.errors.depositMaintenanceMessage}
                                        />
                                        <FormFeedback>{Depositwebformik.errors.depositMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingWeb.depositWeb ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null}

                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={Withdrawwebformik.handleSubmit}>
                                      <h5 className="card-title">Withdraw Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={WithdrawMaintenanceStatus}
                                            autoComplete="off"
                                            onChange={WithdrawMaintenanceClick}
                                          />
                                        </div>
                                      </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="withdrawMaintenanceMessage"
                                          id="withdrawMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={Withdrawwebformik.values.withdrawMaintenanceMessage}
                                          onChange={Withdrawwebformik.handleChange}
                                          onBlur={Withdrawwebformik.handleBlur}
                                          autoComplete="off"
                                          invalid={Withdrawwebformik.touched.withdrawMaintenanceMessage && !!Withdrawwebformik.errors.withdrawMaintenanceMessage}
                                        />
                                        <FormFeedback>{Withdrawwebformik.errors.withdrawMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingWeb.withdrawWeb ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>

                            </Row>
                            <Row>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={Tradewebformik.handleSubmit}>
                                      <h5 className="card-title">Trade Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={TradeMaintenanceStatus}
                                            onChange={TradeMaintenanceClick}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="tradeMaintenanceMessage"
                                          id="tradeMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={Tradewebformik.values.tradeMaintenanceMessage}
                                          onChange={Tradewebformik.handleChange}
                                          onBlur={Tradewebformik.handleBlur}
                                          autoComplete="off"
                                          invalid={Tradewebformik.touched.tradeMaintenanceMessage && !!Tradewebformik.errors.tradeMaintenanceMessage}
                                        />
                                        <FormFeedback>{Tradewebformik.errors.tradeMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingWeb.tradeWeb ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null }

                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={Stakewebformik.handleSubmit}>
                                      <h5 className="card-title">Stake Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={StakeMaintenanceStatus}
                                            onChange={StakeMaintenanceClick}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="stakeMaintenanceMessage"
                                          id="stakeMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={Stakewebformik.values.stakeMaintenanceMessage}
                                          onChange={Stakewebformik.handleChange}
                                          onBlur={Stakewebformik.handleBlur}
                                          autoComplete="off"
                                          invalid={Stakewebformik.touched.stakeMaintenanceMessage && !!Stakewebformik.errors.stakeMaintenanceMessage}
                                        />
                                        <FormFeedback>{Stakewebformik.errors.stakeMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingWeb.stakeWeb ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null }

                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                            </Row>
                            
                            <Row>
                            <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={liveChatwebformik.handleSubmit}>
                                      <h5 className="card-title">LiveChat Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={liveChatMaintenanceStatus}
                                            onChange={liveChatMaintenanceClick}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="liveChatMaintenanceMessage"
                                          id="liveChatMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={liveChatwebformik.values.liveChatMaintenanceMessage}
                                          onChange={liveChatwebformik.handleChange}
                                          onBlur={liveChatwebformik.handleBlur}
                                          autoComplete="off"
                                          invalid={liveChatwebformik.touched.liveChatMaintenanceMessage && !!liveChatwebformik.errors.liveChatMaintenanceMessage}
                                        />
                                        <FormFeedback>{liveChatwebformik.errors.liveChatMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingWeb.liveChatWeb ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null }

                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={ipWebformik.handleSubmit}>
                                      <h5 className="card-title">IP Block Mainanence</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={ipStatus}
                                            // onChange={ipMaintenanceClick}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="ipBlockMessage"
                                          id="ipBlockMessage"
                                          className="form-control custom-scrollbar"
                                          value={ipWebformik.values.ipBlockMessage}
                                          onChange={ipWebformik.handleChange}
                                          onBlur={ipWebformik.handleBlur}
                                          autoComplete="off"
                                          invalid={ipWebformik.touched.ipBlockMessage && !!ipWebformik.errors.ipBlockMessage}
                                        />
                                        <FormFeedback>{ipWebformik.errors.ipBlockMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingWeb.ipWeb ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                            </Row>
                          </TabPane>



                          <TabPane tabId="2" id="home">
                            <Row className="mt-3">
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={siteAndroidformik.handleSubmit}>
                                      <h5 className="card-title">Site Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={siteMaintenanceStatusAndroid}
                                            onChange={siteMaintenanceClickAndriod}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="siteMaintenanceMessage"
                                          id="siteMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={siteAndroidformik.values.siteMaintenanceMessage}
                                          onChange={siteAndroidformik.handleChange}
                                          onBlur={siteAndroidformik.handleBlur}
                                          autoComplete="off"
                                          invalid={siteAndroidformik.touched.siteMaintenanceMessage && !!siteAndroidformik.errors.siteMaintenanceMessage}
                                        />
                                        <FormFeedback>{siteAndroidformik.errors.siteMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light">
                                          {loadingAndroid.siteAndroid ? 'Loading...' : 'Submit'}
                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={RegisterAndroidformik.handleSubmit}>
                                      <h5 className="card-title">Register Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"

                                            checked={registerMaintenanceStatusAndroid}
                                            autoComplete="off"
                                            onChange={RegisterMaintenanceClickAndroid}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="registerMaintenanceMessage"
                                          id="registerMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={RegisterAndroidformik.values.registerMaintenanceMessage}
                                          onChange={RegisterAndroidformik.handleChange}
                                          onBlur={RegisterAndroidformik.handleBlur}
                                          autoComplete="off"
                                          invalid={RegisterAndroidformik.touched.registerMaintenanceMessage && !!RegisterAndroidformik.errors.registerMaintenanceMessage}
                                        />
                                        <FormFeedback>{RegisterAndroidformik.errors.registerMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingAndroid.registerAndroid ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null }

                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>

                            </Row>
                            <Row>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={DepositAndroidformik.handleSubmit}>
                                      <h5 className="card-title">Deposit Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            onChange={DepositeMaintenanceClickAndroid}
                                            checked={DepositeMaintenanceStatusAndroid}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="depositMaintenanceMessage"
                                          id="depositMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={DepositAndroidformik.values.depositMaintenanceMessage}
                                          onChange={DepositAndroidformik.handleChange}
                                          onBlur={DepositAndroidformik.handleBlur}
                                          autoComplete="off"
                                          invalid={DepositAndroidformik.touched.depositMaintenanceMessage && !!DepositAndroidformik.errors.depositMaintenanceMessage}
                                        />
                                        <FormFeedback>{DepositAndroidformik.errors.depositMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingAndroid.depositAndroid ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null}
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={WithdrawAndroidformik.handleSubmit}>
                                      <h5 className="card-title">Withdraw Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={WithdrawMaintenanceStatusAndroid}
                                            autoComplete="off"
                                            onChange={WithdrawMaintenanceClickAndroid}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="withdrawMaintenanceMessage"
                                          id="withdrawMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={WithdrawAndroidformik.values.withdrawMaintenanceMessage}
                                          onChange={WithdrawAndroidformik.handleChange}
                                          onBlur={WithdrawAndroidformik.handleBlur}
                                          autoComplete="off"
                                          invalid={WithdrawAndroidformik.touched.withdrawMaintenanceMessage && !!WithdrawAndroidformik.errors.withdrawMaintenanceMessage}
                                        />
                                        <FormFeedback>{WithdrawAndroidformik.errors.withdrawMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingAndroid.withdrawAndroid ? 'Loading...' : 'Submit'}


                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>

                            </Row>
                            <Row>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={TradeAndroidformik.handleSubmit}>
                                      <h5 className="card-title">Trade Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={TradeMaintenanceStatusAndroid}
                                            onChange={TradeMaintenanceClickAndroid}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="tradeMaintenanceMessage"
                                          id="tradeMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={TradeAndroidformik.values.tradeMaintenanceMessage}
                                          onChange={TradeAndroidformik.handleChange}
                                          onBlur={TradeAndroidformik.handleBlur}
                                          autoComplete="off"
                                          invalid={TradeAndroidformik.touched.tradeMaintenanceMessage && !!TradeAndroidformik.errors.tradeMaintenanceMessage}
                                        />
                                        <FormFeedback>{TradeAndroidformik.errors.tradeMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingAndroid.tradeAndroid ? 'Loading...' : 'Submit'}
                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={StakeAndroidformik.handleSubmit}>
                                      <h5 className="card-title">Stake Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={StakeMaintenanceStatusAndroid}
                                            onChange={StakeMaintenanceClickAndroid}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="StakeMaintenanceMessage"
                                          id="StakeMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={StakeAndroidformik.values.StakeMaintenanceMessage}
                                          onChange={StakeAndroidformik.handleChange}
                                          onBlur={StakeAndroidformik.handleBlur}
                                          autoComplete="off"
                                          invalid={StakeAndroidformik.touched.StakeMaintenanceMessage && !!StakeAndroidformik.errors.StakeMaintenanceMessage}
                                        />
                                        <FormFeedback>{StakeAndroidformik.errors.StakeMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingAndroid.tradeAndroid ? 'Loading...' : 'Submit'}
                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                            </Row>

                            <Row>
                            <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={TradeAndroidformik.handleSubmit}>
                                      <h5 className="card-title">LiveChat Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={liveChatMaintenanceStatusAndroid}
                                            onChange={liveChatMaintenanceClickAndroid}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="liveChatMaintenanceMessage"
                                          id="liveChatMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={liveChatAndroidformik.values.liveChatMaintenanceMessage}
                                          onChange={liveChatAndroidformik.handleChange}
                                          onBlur={liveChatAndroidformik.handleBlur}
                                          autoComplete="off"
                                          invalid={liveChatAndroidformik.touched.liveChatMaintenanceMessage && !!liveChatAndroidformik.errors.liveChatMaintenanceMessage}
                                        />
                                        <FormFeedback>{liveChatAndroidformik.errors.liveChatMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingAndroid.liveChatAndroid ? 'Loading...' : 'Submit'}
                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={ipAndroidformik.handleSubmit}>
                                      <h5 className="card-title">IP Block Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={ipMaintenanceStatusAndroid}
                                            // onChange={ipMaintenanceClickAndroid}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="ipBlockMessage"
                                          id="ipBlockMessage"
                                          className="form-control custom-scrollbar"
                                          value={ipAndroidformik.values.ipBlockMessage}
                                          onChange={ipAndroidformik.handleChange}
                                          onBlur={ipAndroidformik.handleBlur}
                                          autoComplete="off"

                                          invalid={ipAndroidformik.touched.ipBlockMessage && !!ipAndroidformik.errors.ipBlockMessage}
                                        />
                                        <FormFeedback>{ipAndroidformik.errors.ipBlockMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingAndroid.ipAndroid ? 'Loading...' : 'Submit'}
                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>

                            </Row>

                          </TabPane>


                          <TabPane tabId="3" id="home">
                            <Row className="mt-3">
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={siteIOSformik.handleSubmit}>
                                      <h5 className="card-title">Site Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={siteMaintenanceStatusIOS}
                                            onChange={siteMaintenanceClickIOS}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="siteMaintenanceMessage"
                                          id="siteMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={siteIOSformik.values.siteMaintenanceMessage}
                                          onChange={siteIOSformik.handleChange}
                                          onBlur={siteIOSformik.handleBlur}
                                          autoComplete="off"
                                          invalid={siteIOSformik.touched.siteMaintenanceMessage && !!siteIOSformik.errors.siteMaintenanceMessage}
                                        />
                                        <FormFeedback>{siteIOSformik.errors.siteMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingIOS.siteIOS ? 'Loading...' : 'Submit'}
                                        </button>
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={RegisterIOSformik.handleSubmit}>
                                      <h5 className="card-title">Register Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={registerMaintenanceStatusIos}
                                            autoComplete="off"
                                            onChange={RegisterMaintenanceClickIos}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="registerMaintenanceMessage"
                                          id="registerMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={RegisterIOSformik.values.registerMaintenanceMessage}
                                          onChange={RegisterIOSformik.handleChange}
                                          onBlur={RegisterIOSformik.handleBlur}
                                          autoComplete="off"
                                          invalid={RegisterIOSformik.touched.registerMaintenanceMessage && !!RegisterIOSformik.errors.registerMaintenanceMessage}
                                        />
                                        <FormFeedback>{RegisterIOSformik.errors.registerMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingIOS.registerIOS ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null }

                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>

                              <Row>
                              </Row>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={DepositIOSformik.handleSubmit}>
                                      <h5 className="card-title">Deposit Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            onChange={DepositeMaintenanceClickIos}
                                            checked={DepositeMaintenanceStatusIos}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="depositMaintenanceMessage"
                                          id="depositMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={DepositIOSformik.values.depositMaintenanceMessage}
                                          onChange={DepositIOSformik.handleChange}
                                          onBlur={DepositIOSformik.handleBlur}
                                          autoComplete="off"
                                          invalid={DepositIOSformik.touched.depositMaintenanceMessage && !!DepositIOSformik.errors.depositMaintenanceMessage}
                                        />
                                        <FormFeedback>{DepositIOSformik.errors.depositMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingIOS.depositIOS ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={WithdrawIOSformik.handleSubmit}>
                                      <h5 className="card-title">Withdraw Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={WithdrawMaintenanceStatusIos}
                                            autoComplete="off"
                                            onChange={WithdrawMaintenanceClickIos}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="withdrawMaintenanceMessage"
                                          id="withdrawMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={WithdrawIOSformik.values.withdrawMaintenanceMessage}
                                          onChange={WithdrawIOSformik.handleChange}
                                          onBlur={WithdrawIOSformik.handleBlur}
                                          autoComplete="off"
                                          invalid={WithdrawIOSformik.touched.withdrawMaintenanceMessage && !!WithdrawIOSformik.errors.withdrawMaintenanceMessage}
                                        />
                                        <FormFeedback>{WithdrawIOSformik.errors.withdrawMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingIOS.withdrawIOS ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>

                            </Row>
                            <Row>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={TradeIOSformik.handleSubmit}>
                                      <h5 className="card-title">Trade Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={TradeMaintenanceStatusIos}
                                            onChange={TradeMaintenanceClickIos}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="tradeMaintenanceMessage"
                                          id="tradeMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={TradeIOSformik.values.tradeMaintenanceMessage}
                                          onChange={TradeIOSformik.handleChange}
                                          onBlur={TradeIOSformik.handleBlur}
                                          autoComplete="off"
                                          invalid={TradeIOSformik.touched.tradeMaintenanceMessage && !!TradeIOSformik.errors.tradeMaintenanceMessage}
                                        />
                                        <FormFeedback>{TradeIOSformik.errors.tradeMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingIOS.tradeIOS ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={StakeIOSformik.handleSubmit}>
                                      <h5 className="card-title">Stake Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={StakeMaintenanceStatusIos}
                                            onChange={StakeMaintenanceClickIos}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="StakeMaintenanceMessage"
                                          id="StakeMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={StakeIOSformik.values.StakeMaintenanceMessage}
                                          onChange={StakeIOSformik.handleChange}
                                          onBlur={StakeIOSformik.handleBlur}
                                          autoComplete="off"
                                          invalid={StakeIOSformik.touched.StakeMaintenanceMessage && !!StakeIOSformik.errors.StakeMaintenanceMessage}
                                        />
                                        <FormFeedback>{StakeIOSformik.errors.StakeMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingIOS.stakeIOS ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                            </Row>


                            <Row>
                            <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={TradeIOSformik.handleSubmit}>
                                      <h5 className="card-title">LiveChat Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={liveChatMaintenanceStatusIos}
                                            onChange={liveChatMaintenanceClickIos}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="tradeMaintenanceMessage"
                                          id="tradeMaintenanceMessage"
                                          className="form-control custom-scrollbar"
                                          value={liveChatIOSformik.values.liveChatMaintenanceMessage}
                                          onChange={liveChatIOSformik.handleChange}
                                          onBlur={liveChatIOSformik.handleBlur}
                                          autoComplete="off"
                                          invalid={liveChatIOSformik.touched.liveChatMaintenanceMessage && !!liveChatIOSformik.errors.liveChatMaintenanceMessage}
                                        />
                                        <FormFeedback>{liveChatIOSformik.errors.liveChatMaintenanceMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingIOS.liveChatIOS ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                              <div className="col-lg-6">
                                <Card className="card-shadow">
                                  <CardBody>
                                    <Form onSubmit={ipIOSformik.handleSubmit}>
                                      <h5 className="card-title">IP Block Maintenance</h5>
                                      <div className="hstack gap-3 mt-3">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <>
                                        <p>Status</p>
                                        <div className="form-check form-switch form-switch-md mb-3">
                                          <input type="checkbox" className="form-check-input" id="siteMaintenance"
                                            name="siteMaintenance"
                                            checked={ipMaintenanceStatusIos}
                                            // onChange={TradeMaintenanceClickIos}
                                          />
                                        </div>
                                        </>
                                      ): null }
                                      </div>
                                      <FormGroup>
                                        <label>Message :</label>
                                        <Input type="textarea" rows="3"
                                          name="ipBlockMessage"
                                          id="ipBlockMessage"
                                          className="form-control custom-scrollbar"
                                          value={ipIOSformik.values.ipBlockMessage}
                                          onChange={ipIOSformik.handleChange}
                                          onBlur={ipIOSformik.handleBlur}
                                          autoComplete="off"
                                          invalid={ipIOSformik.touched.ipBlockMessage && !!ipIOSformik.errors.ipBlockMessage}
                                        />
                                        <FormFeedback>{ipIOSformik.errors.ipBlockMessage} </FormFeedback>
                                      </FormGroup>
                                      <div className="text-end">
                                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Maintenance Settings" && data.actions.includes(3))) ? (
                                        <button type="submit" className="btn btn-primary waves-effect waves-light" >
                                          {loadingIOS.tradeIOS ? 'Loading...' : 'Submit'}

                                        </button>
                                      ): null }
                                      </div>
                                    </Form>
                                  </CardBody>
                                </Card>
                              </div>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
     
                  </Row>
           
                </CardBody>
              </Card>
            </Col>
          </Row>












          <Row>
            <Col lg={12} className="row">
              {/* <Form onSubmit={formik.handleSubmit}> */}
              <Card
                style={{ backgroundColor: "transparent", boxShadow: "unset" }}
              >
                <Row>
                  <div className="col-lg-6">
                
                  </div>
                  <div className="col-lg-6">
                  
                  </div>
                </Row>
              </Card>

              <Card style={{ backgroundColor: "transparent", boxShadow: "unset" }}   >
                <Row>
                  <div className="col-lg-6">
                 
                  </div>
                
                </Row>
              </Card>
    
            </Col>
          </Row>
        
          <div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MaintenanceSettings;


import React, { useState, useEffect } from "react";
import {  Input, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices, Boolian } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { utcToNormalTime } from "../../helpers/function";

const AdminLoginActivites = () => {
    document.title = "Ultrapro | Admin Login Activities";
    const [loading, setLoading] = useState(false);
    const [searchLoad, setSearchLoad] = useState(false);
    const [searchValid, setSearchValid] = useState(false);
    const [inputSearch, setinputSearch] = useState('');
    const [formData, setFormData] = useState([]);
    const [totalPagination, setTotalPagination] = useState(1);
    const [totalLimit, setTotalLimit] = useState(1);
    const [user, setUser] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = totalLimit;
    const pageCount = Math.ceil(totalPagination);

    useEffect(() => {
            ipList();
    }, []);

    const handleFilter = async (apiName) => {
        setLoading(true);
        try {
            const response = await postData(apiName);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.limit)
            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };

 

    const ipList = async () => {
        setLoading(true);
        try {
            const response = await postData(Apiservices.ADMIN_LOGIN_ACTIVITES);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
            } else {
                toast.error(response.message);
                setLoading(false);

            }
        } catch (error) {
            toast.error("Internal Server Error...");
            setLoading(false);


        }
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        handlePagePost(selected);
    };

    const handlePagePost = async (selected) => {
        setLoading(true);
        const postForm = {
            page: selected + 1,
            search: inputSearch,
        };
        const queryString = new URLSearchParams(postForm).toString();
        try {
                const response = await postData(`${Apiservices.ADMIN_LOGIN_ACTIVITES}?${queryString}`);

                if (response.status) {
                    setLoading(false);
                    setFormData(response.data.data);
                    setTotalPagination(response.data.totalPages);
                    setTotalLimit(response.data.limit)
                } else {
                    setLoading(false);
                    toast.error(response.message);
                }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };

    const getBadgeKYCClass = (code) => {
        switch (code) {
            case Boolian.ADMIN_TEMP_BLOCKED:
                return "badge-soft-warning";
            case Boolian.ADMIN_UNBLOCK:
                return "badge-soft-success";
            case Boolian.ADMIN_BLOCKED:
                return "badge-soft-danger";
            default:
                return "";
        }
    };


    const getKycStatusText = (code) => {
        switch (code) {
            case Boolian.ADMIN_TEMP_BLOCKED:
                return "Temprouary Blocked";
            case Boolian.ADMIN_UNBLOCK:
                return "Active";
            case Boolian.ADMIN_BLOCKED:
                return "Blocked";
            default:
                return "Unknown";
        }
    };


    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true,
            maxWidth: '3%',
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            maxWidth: '10%',
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            maxWidth: '25%',
        },
        {
            name: 'IP Address',
            selector: row => row.ipAddress,
            sortable: true,
            maxWidth: '12%',
        },
        {
            name: 'Status',
            selector: row => (
                <div className="edit">
                    <span className={`badge ${getBadgeKYCClass(row.isIpActive)} me-1`}>{getKycStatusText(row.isIpActive)}</span>

                </div>
            ),
            ignoreRowClick: true,
            
        },
        {
            name: 'Role',
            selector: row =>(row.adminType===0 ? ('Sub Admin'): ('Super Admin')),
            sortable: true,
            maxWidth: '12%',
        },
        {
            name: 'Date & Time',
            selector: row => row.createdAt
                ? `${utcToNormalTime(row.createdAt).date} ${utcToNormalTime(row.createdAt).time}` : "---",
            sortable: true
        },
      
    ];

    const handleSearchChanage = (e) => {
        const value = e.target.value
        if (value == '' && user === undefined) {
            ipList()
            setSearchValid(false)
        }else if(value == ''){
            handleFilter(user)
        }
        setinputSearch(value)
    }


    const handleSearch = async () => {
        if (inputSearch !== '') {
            setSearchLoad(true);
            const postForm = {
                search: inputSearch,
            };
            const queryString = new URLSearchParams(postForm).toString();
            try {
               
                    const response = await postData(`${Apiservices.ADMIN_LOGIN_ACTIVITES}?${queryString}`,);
                    if (response.status) {
                        setSearchLoad(false);
                        setFormData(response.data.data);
                        setTotalPagination(response.data.totalPages);
                    } else {
                        setSearchLoad(false);
                    }
                
            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
                setSearchLoad(false);
            }
        } else {
            setSearchValid(true)
        }
    }

 
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Admin Management" breadcrumbItem="Admin Login Activites" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col sm={4} lg={3}>
                                            <div className="input-group">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search by Name,Email,Ip Address or Role"
                                                    aria-label="Recipient's username"
                                                    onChange={handleSearchChanage}
                                                    invalid={searchValid}
                                                />
                                                <div className="input-group-append">

                                                    <button className="btn btn-primary" type="submit" onClick={()=>handleSearch()} disabled={searchLoad}>
                                                        {searchLoad ? (
                                                            <Spinner className="text-light" size="sm" />
                                                        ) : (
                                                            <i className="ri-search-line" />
                                                        )}

                                                    </button>


                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="col-sm">
                                        </Col>
                                    </Row>
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={formData}
                                            paginationServer
                                            paginationTotalRows={formData.length}
                                            progressPending={loading}
                                            progressComponent={<div>Loading...</div>}
                                            noDataComponent={<div>No records found</div>}
                                            fixedHeader={true}

                                        />
                                    </div>
                                    {loading ? (
                                        ""
                                    ) : (

                                        <div className="d-flex justify-content-end mt-3">
                                            <div className="pagination pagination-rounded mb-0">
                                                <ReactPaginate
                                                    previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                    nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    activeClassName={"active"}
                                                    pageLinkClassName={"page-link"}
                                                    breakLinkClassName={"break-link"}
                                                    disabledClassName={"disabled"}
                                                    initialPage={currentPage}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                    renderLink={(props) => {
                                                        if (
                                                            props.page === "..." &&
                                                            props.pageCount > props.pageRangeDisplayed
                                                        ) {
                                                            return (
                                                                <a
                                                                    {...props}
                                                                    onClick={() =>
                                                                        handlePageClick({
                                                                            selected: pageCount - 1,
                                                                        })
                                                                    }
                                                                >
                                                                    {pageCount}
                                                                </a>
                                                            );
                                                        }
                                                        return <a {...props} />;
                                                    }}
                                                />
                                            </div>


                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AdminLoginActivites;

import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback, InputGroupText, InputGroup, Spinner, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form, Label } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices, TokenName } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import axios from "axios";
import getIFSC from "node-ifsc";
import Swal from "sweetalert2";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import { AdminContext } from "../../helpers/adminContext";


const SiteBank = () => {
  const [submitLoad, setSubmitLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getBankDetails, setBankDetails] = useState([]);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [BankId, setBankId] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [showModel, setShowModel] = useState(false)
  const [showOTPModel, setShowOTPModel] = useState(false)
  const [countdown, setCountdown] = useState(60);
  const [showDetails, setShowDetails] = useState('')
  const [currentPage, setCurrentPage] = useState(0);
  const [modal_list, setmodal_list] = useState(false);
  const [modal_update_list, setmodal_update_list] = useState(false);
  const [formData,setFormData] = useState('')
 const [fetchloading,setFetchLoading] = useState(false)
 const [verifyLoader,setverifyLoader] = useState(false);
 const [isTrue,setIsTrue] = useState(false)

 //Permission List
 const { adminInfo } = useContext(AdminContext);
 const [ adminType , setAdminType] = useState('');


  document.title = "Settings | Site Bank Details";

  useEffect(() => {
    fetchData();
    setAdminType(adminInfo?.adminType);
  }, []);
  const fetchData = async () => {
    try {
      setFetchLoading(true)
      const response = await postData(Apiservices.GetBankList);
      if (response) {
        setBankDetails(response.data);
        setFetchLoading(false)

      } else {
        setFetchLoading(false)

      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setFetchLoading(false)

      // Handle fetch error
    }
  };

  const handleUpdateStatusClick = (row) => {
    showStatusAlert(handleToggle1, row);
  };
  const handleToggle1 = async (rowDetails) => {
    const updatedStatus = rowDetails.isActive === 1 ? 0 : 1;

    const postForm = {
      adminBankId: rowDetails.adminBankId,
      holderName: rowDetails.holderName,
      bankName: rowDetails.bankName,
      accountNumber: rowDetails.accountNumber,
      accountType: rowDetails.accountType,
      branchName: rowDetails.branchName,
      ifscCode: rowDetails.ifscCode,
      isActive: updatedStatus,
    };


    setLoading(true)
    try {
      const response = await postData(Apiservices.UpdateBankDetails, postForm)
      if (response.status) {
        toast.dismiss()
        fetchData();
        setLoading(false)
        Swal.fire('Success!', 'The status has been updated successfully', 'success');
      } else {
        toast.error(response.message)
        setLoading(false)
      }

    } catch (error) {
      toast.dismiss()
      toast.error("Internal Server Error..Try Again Later")
      setLoading(false)

    }

  }

  const showStatusAlert = (onConfirm, row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to update status!",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
          try {
              await onConfirm(row);
          } catch (error) {
             console.log(error)
          }
      }
  }).then((result) => {
      if (result.isConfirmed) {
          // Additional actions after confirmation, if needed
      }
  });
  };
  

  

  const handleView = (data) => {
    setShowModel(true)
    setShowDetails(data)

  }
  const allowedActions = [1,2,3,4];

  const columns = [
    {
      name: "S.No",
      // selector: (row, index) => currentPage * itemsPerPage + index + 1,
      selector: (row, index) => index + 1,
      sortable: true,
      minWidth:'80px'  
  },
    {
      name: "Holder Name",
      selector: (row) => row.holderName,
      sortable: true,
      minWidth:'200px'  

    },
    {
      name: "Bank Name",
      selector: (row) => row.bankName,
      sortable: true,
      minWidth:'250px'  

    },
    {
      name: "Account Number",
      selector: (row) => row.accountNumber,
      sortable: true,
      minWidth:'150px'  

    },
    {
      name: "IFSC Code",
      selector: (row) => row.ifscCode,
      sortable: true,
      minWidth:'150px'  

    },


    {
      name: 'Status',
      // selector: row => row.isActive ? 'Active' : 'Inactive',
      selector: row => (
        <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
          {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Site Bank Details" && data.actions.includes(3))) ? (
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            checked={row.isActive === 1}
            onChange={() => handleUpdateStatusClick(row)}
            disabled={loading}
          />
    ): null}
        </div>
      ),

      minWidth:'150px',
      sortable: true
    },


    {
      name: "Date & Time",
      selector: (row) => `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${(utcToNormalTime(row.createdAt).time)}`,
      sortable: true,
      minWidth:'200px',

    },
    ...(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Site Bank Details" && allowedActions.some(action => data.actions.includes(action))) ? [
    {
      name: "Action",
      cell: (row) => (
        <div className="edit">
          {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Site Bank Details" && data.actions.includes(3))) ? (
          <i
            style={{ cursor: "pointer" }}
            className="mdi mdi-border-color cursor-pointer"
            onClick={() => handleUpdate(row)}
          ></i>
    ): null }
    {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Site Bank Details" && data.actions.includes(2))) ? (
          <i
            style={{ cursor: "pointer" }}
            className="mdi mdi-eye-outline mx-2"
            onClick={() => handleView(row)}
          ></i>
    ): null}
    {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Site Bank Details" && data.actions.includes(4))) ? (
           <i
            style={{ cursor: "pointer" }}
            className="mdi mdi-trash-can-outline"
            onClick={() => handleDeleteClick(row)}
            ></i>
    ): null}
        </div>
      ),
      ignoreRowClick: true,
      minWidth:'250px'  

      // allowOverflow: true,
      // button: true,
    }]: []),
  ];

  //paginnation
  const rowsPerPage = 5;
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * rowsPerPage;
  const currentData = getBankDetails.slice(offset, offset + rowsPerPage);




  //Add Modal
  function tog_list() {
    formik.resetForm()
    setmodal_list(!modal_list);
  }
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
  }
  const formik = useFormik({
    initialValues: {
      accountholdername: "",
      bankname: "",
      accountnumber: "",
      accountype: "",
      branchname: "",
      ifsccode: "",
      isActive: "",
    },
    validationSchema: Yup.object({

      accountnumber: Yup.string().required("Account Number is required") 
      .matches(/^[0-9]+$/, "Account Number must contain only digits")
      .min(8, "Account Number must be at least 8 digits")
      .max(16, "Account Number must be no more than 16 digits"),  
      accountype: Yup.string().required("Account Type is required"),
      accountholdername: Yup.string().required("Account Holder Name is required"),
      bankname: Yup.string().required("Bank Name is required")
      .matches(/^[a-zA-Z\s]+$/, "Bank Name must contain only letters and spaces"),
      ifsccode: Yup.string().required("IFSC Code is required")
      .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC Code format"),
      isActive: Yup.string().required("Status is required"),
      branchname: Yup.string().required("Branch Name is required")
      .matches(/^[a-zA-Z\s]+$/, "Branch Name must contain only letters and spaces"),

    }),
    onSubmit: (values) => {
      handleBankAdd(values);
    },
  });

  const otpVerify = async (data) => {
    setSubmitLoad(true)
 
    const postForm = {
      holderName: formik.values.accountholdername,
      bankName: formik.values.bankname,
      accountNumber: formik.values.accountnumber,
      accountType: formik.values.accountype,
      branchName: formik.values.branchname,
      ifscCode: formik.values.ifsccode,
      isActive: formik.values.isActive,
      otp:validation.values.otp
    };

    try {
      const response = await postData(Apiservices.AddBankDetails, postForm);

      if (response.status === true) {
        toast.success(response.message);
        setSubmitLoad(false)
       setShowOTPModel(false)
       fetchData()

      } else {
        toast.error(response.message);
        setLoading(false);
        // setmodal_list(false);
        fetchData();
        setSubmitLoad(false)

      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      formik.resetForm();
      setLoading(false);
      setSubmitLoad(false)

    }
  };



  const handleBankAdd = async (data) => {
    // if(isTrue === true){
    
    setLoading(true);
    const postForm = {
      holderName: data.accountholdername,
      bankName: data.bankname,
      accountNumber: data.accountnumber,
      accountType: data.accountype,
      branchName: data.branchname,
      ifscCode: data.ifsccode,
      isActive: data.isActive,
      adminType: adminInfo?.adminType,
      permissions: adminInfo?.permissions

    };
    setFormData(postForm)

    try {
      const response = await postData(Apiservices.AddBankDetails, postForm);

      if (response.status === true) {
        toast.success(response.message);
        setmodal_list(false);
        setShowOTPModel(true);
        startCountdown()
        fetchData();
        setLoading(false);

      } else {
        toast.error(response.message);
        setLoading(false);
        // setmodal_list(false);
        fetchData();
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      formik.resetForm();
    }
  // }
  // else{
  //   toast.error('Verify Bank Account Number and IFSC Code')
  // }
  };

  
  const resendOTP = async (data) => {
    // if(isTrue === true){
    
    setLoading(true);
   

    try {
      const response = await postData(Apiservices.AddBankDetails, formData);

      if (response.status === true) {
        toast.success(response.message);
        setmodal_list(false);
        setCountdown(60);
        startCountdown();
        setLoading(false);

      } else {
        toast.error(response.message);
        setLoading(false);
        // setmodal_list(false);
        fetchData();
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      formik.resetForm();
    }
  // }
  // else{
  //   toast.error('Verify Bank Account Number and IFSC Code')
  // }
  };
  const startCountdown = () => {
    
    const intervalId = setInterval(() => {
        setCountdown(prevCountdown => {
            const newCountdown = prevCountdown - 1;
            if (newCountdown <= 0) {
                clearInterval(intervalId);
                // setShowOTPModel(false);
                setIsDisabled(false)
                validation.resetForm()
                return 0;
            }
            return newCountdown;
        });
    }, 1000);
};
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
        otp: ''
    },
    validationSchema: Yup.object({
        otp: Yup.string()

            .required('OTP is required'),
    }),
    onSubmit: (values) => {
        otpVerify(values)
    },
});
  const validationUpdate = useFormik({
    enableReinitialize: true,

    initialValues: {
      accountholdername: "",
      bankname: "",
      accountnumber: "",
      accountype: "",
      branchname: "",
      ifsccode: "",
      isActive: "",
    },
    validationSchema: Yup.object({
      accountnumber: Yup.string().required("Account Number is required") 
      .matches(/^[0-9]+$/, "Account Number must contain only digits")
      .min(8, "Account Number must be at least 8 digits")
      .max(16, "Account Number must be no more than 16 digits"),  
      accountype: Yup.string().required("Account Type is required"),
      accountholdername: Yup.string().required("Account Holder Name is required"),
      bankname: Yup.string().required("Bank Name is required")
      .matches(/^[a-zA-Z\s]+$/, "Bank Name must contain only letters and spaces"),
      ifsccode: Yup.string().required("IFSC Code is required")
      .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC Code format"),
      isActive: Yup.string().required("Status is required"),
      branchname: Yup.string().required("Branch Name is required")
      .matches(/^[a-zA-Z\s]+$/, "Branch Name must contain only letters and spaces"),
    }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });
  const handleUpdate = (record) => {
    setBankId(record.adminBankId);
    validationUpdate.setValues({
      bankId: record.adminBankId || "",
      accountholdername: record.holderName || "",
      bankname: record.bankName || "",
      accountnumber: record.accountNumber || "",
      accountype: record.accountType || "",
      branchname: record.branchName || "",
      ifsccode: record.ifscCode || "",
      isActive: record.isActive !== undefined ? String(record.isActive) : "",
    });
    // setRecordToUpdate(record);
    setmodal_update_list(true);
  };
  const confirmUpdate = async (values) => {
    const postForm = {
      adminBankId: BankId,
      holderName: values.accountholdername,
      bankName: values.bankname,
      accountNumber: values.accountnumber,
      accountType: values.accountype,
      branchName: values.branchname,
      ifscCode: values.ifsccode,
      isActive: values.isActive,
    };
    
    try {
      setLoading(true);
      const response = await postData(
        `${Apiservices.UpdateBankDetails}`, postForm);

      if (response.status === true) {
        setmodal_update_list(false);
        toast.success(response.message);
        fetchData();
        setLoading(false);
      } else {
        toast.error(response.message);
        fetchData();
      
        setLoading(false);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      validationUpdate.resetForm();
    }
  };

  const handleVerify = async (values) => {
    setverifyLoader(true);
    const apiUrl = "https://api.attestr.com/api/v1/public/finanx/acc";
    const requestBody = {
      acc: values.accountnumber,
      ifsc: values.ifsccode,
      fetchIfsc: false,
    };

    // Construct request headers
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${TokenName.BANK_ACCOUNT_NAME_ADD_TOKEN}`,
    };

    try {
      const response = await axios.post(apiUrl, requestBody, {
        headers: headers,
      });
      if (response.valid === true) {
        const data = await getIFSC(values.ifsccode);
        
        formik.setValues({
          accountholdername: response.name || "",
          bankname: data.BANK || "",
          branchname: data.BRANCH || "",
          accountnumber: values.accountnumber,
          ifsccode: values.ifsccode,
        });
        setverifyLoader(false);
      } 
      else {
      toast.error(response.message);

        setverifyLoader(false);
      }
    } catch (error) {
      toast.error("Please enter valid account number and ifsc code");
      setverifyLoader(false);
    }
  };
  const handleVerifyupdate = async (values) => {
    setUpdateLoader(true);
    const apiUrl = "https://api.attestr.com/api/v1/public/finanx/acc";
    const requestBody = {
      acc: values.accountnumber,
      ifsc: values.ifsccode,
      fetchIfsc: false,
    };

    // Construct request headers
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${TokenName.BANK_ACCOUNT_NAME_ADD_TOKEN}`,
    };

    try {
      const response = await axios.post(apiUrl, requestBody, {
        headers: headers,
      });
      if (response.valid === true) {
        const data = await getIFSC(values.ifsccode);
        validationUpdate.setValues({
          accountholdername: response.name || "",
          bankname: data.BANK || "",
          branchname: data.BRANCH || "",
          accountnumber: values.accountnumber,
          ifsccode: values.ifsccode,
        });
        setUpdateLoader(false);
        setIsTrue(true)
      } else {
        toast.error(response.message)
        setUpdateLoader(false);
      }
    } catch (error) {
      setUpdateLoader(false);
      // toast.error("Please Try Again Later");
      toast.error("Please enter valid account number and ifsc code");

    }
  };
  function tog_xlarge(image) {
    setShowDetails(image)
    setShowModel(!showModel);
  }

   //delete api call
  //  const showDeleteAlert = (onConfirm,row) => {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to recover this once it's deleted!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       onConfirm(row);
  //       // Swal.fire(
  //       //   'Deleted !',
  //       //   'The requested record has been deleted',
  //       //   'success'
  //       // );
  //     }
  //   });
  // };

  const showDeleteAlert = (onConfirm, row) => {
    Swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to recover this once it's deleted!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async () => {
            try {
                await onConfirm(row);
            } catch (error) {
                Swal.fire('Error!', 'There was an error deleting the record', 'error');
            }
        }
    }).then((result) => {
        if (result.isConfirmed) {
            // Additional actions after confirmation, if needed
        }
    });
  }
  const handleDeleteClick = (row) => {
    showDeleteAlert(handleDelete1,row);
  };
   const handleDelete1 = async (rowDetails) => {
   
    const postForm = {
      adminBankId: rowDetails.adminBankId,
    };
    setLoading(true);
    try {
      const response = await postData(
        Apiservices.DeleteBankDetails,
        postForm
      );
      if (response.status) {
        setLoading(false);
        // toast.success(response.message);
         Swal.fire(
          'Deleted !',
          'The requested record has been deleted',
          'success'
        );
        fetchData();
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Internal Server Error..Try Again Later");
    }
  };
  const handleClose = () =>{
    setmodal_list(false)
    formik.resetForm();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Settings" breadcrumbItem="Admin Bank Details" />
          <Row>
            <Col lg={12} className="row">
              <Card className="col-lg-12">
                <CardBody>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <h4 className="card-title">Admin Bank Details</h4>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                      {(adminType === 1 || adminInfo?.permissions.some(data => 
                        data.module === "Site Bank Details" && data.actions.includes(1))) ? (
                        <Button
                          color="success"
                          className="add-btn"
                          onClick={() => tog_list()}
                    
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> <b>Add</b>
                        </Button>
                        ): null}
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={currentData}
                      paginationServer
                      paginationTotalRows={currentData.length}
                      progressPending={fetchloading}
                      progressComponent={<div>Loading...</div>}
                      persistTableHead={true}
                      // noDataComponent={formData.length===0}
                      noDataComponent={
                        <div className="mt-5">No records found</div>
                      }
                      fixedHeader={true}
                    />
                    {currentData.length !== 0 ? (
                      <div className="d-flex justify-content-end mt-3">
                        <div className="pagination pagination-rounded mb-0 ">
                          <ReactPaginate
                            previousLabel={
                              <i className="mdi mdi-chevron-left"></i>
                            }
                            nextLabel={
                              <i className="mdi mdi-chevron-right"></i>
                            }
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            breakLinkClassName={"break-link"}
                            disabledClassName={"disabled"}
                            pageCount={Math.ceil(
                              getBankDetails.length / rowsPerPage
                            )}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                          />
                        </div>
                      </div>
                    ) : null}
                    {/* // AddBank  */}
                    <Modal   className="modal-dialog modal-lg"  isOpen={modal_list}  toggle={() => {tog_list();  }} centered backdrop="static">
                      <ModalHeader className="bg-light p-3" id="exampleModalLabel"
                        toggle={() => {  tog_list(); }} > Add Bank Details </ModalHeader>
                      <Form autoComplete="off"   className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          formik.handleSubmit();
                          return false;
                        }}
                      >
                        <ModalBody className='d-lg-flex justify-content-around'>
                          <div className="col-lg-5 col-sm-12">
                            <div className="mb-3">
                              <label  htmlFor="accountnumber-field"   className="form-label" > Account Number </label>
                              <Input
                                type="text"
                                id="accountnumber-field"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter Account Number"
                                name="accountnumber"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.accountnumber || ""}
                                invalid={
                                  formik.touched.accountnumber &&
                                  formik.errors.accountnumber
                                }
                              />
                             {formik.touched.accountnumber && formik.errors.accountnumber && (<FormFeedback type="invalid">{formik.errors.accountnumber}</FormFeedback>)}
                            </div>
                            <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">
                                IFSC Code
                              </label>

                              <InputGroup>
                                <Input
                                  type="text"
                                  id="customername-field"
                                  className="form-control"
                                  placeholder="Enter IFSC Code"
                                  name="ifsccode"
                                  autoComplete="off"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.ifsccode || ""}
                                  invalid={
                                    formik.touched.ifsccode &&
                                    formik.errors.ifsccode
                                  }
                                />
                                {/* {verifyLoader ? (
                                  <InputGroupText>
                                    <Button color="primary" size="sm" disabled>
                                      <Spinner size="sm" />
                                    </Button>
                                  </InputGroupText>
                                ) : (
                                  <InputGroupText>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      disabled={ !formik.values.accountnumber || !formik.values.ifsccode }
                                      onClick={() => handleVerify(formik.values)}>
                                      Verify
                                    </Button>
                                  </InputGroupText>
                                )} */}
                              {formik.touched.ifsccode && formik.errors.ifsccode && (
                                <FormFeedback type="invalid">
                                  {formik.errors.ifsccode}
                                </FormFeedback>
                                )}
                              </InputGroup>

                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="customername-field"
                                className="form-label"
                              >
                                Account Holder Name
                              </label>
                              <Input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Account Holder Name"
                                name="accountholdername"
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.accountholdername || ""}
                              invalid={formik.touched.accountholdername && formik.errors.accountholdername}
                              />
                              {formik.touched.accountholdername && formik.errors.accountholdername && (
                                <FormFeedback type="invalid">{formik.errors.accountholdername}</FormFeedback>
                            )}
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="customername-field"
                                className="form-label"
                              >
                                Bank Name
                              </label>
                              <Input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Bank Name"
                                name="bankname"
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.bankname || ""}
                              invalid={formik.touched.bankname && formik.errors.bankname}
                              />
                              {formik.touched.bankname && formik.errors.bankname && (
                                <FormFeedback type="invalid">{formik.errors.bankname}</FormFeedback>
                            )}
                            </div>
                          </div>

                          <div className="col-lg-5 col-sm-12">
                            <div className="mb-3">
                              <label
                                htmlFor="customername-field"
                                className="form-label"
                              >
                                Branch Name
                              </label>
                              <Input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Branch Name"
                                name="branchname"
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.branchname || ""}
                              invalid={formik.touched.branchname && formik.errors.branchname}
                              />
                              {formik.touched.branchname && formik.errors.branchname && (
                                <FormFeedback type="invalid">{formik.errors.branchname}</FormFeedback>
                            )}
                            </div>

                            <div className="mb-3">
                              <label
                                htmlFor="status-field"
                                className="form-label"
                              >
                                Account Type
                              </label>
                              <select
                                id="status-field"
                                //  className="form-control"
                                name="accountype"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.accountype || ""}
                                className={`form-control ${formik.touched.accountype &&
                                    formik.errors.accountype
                                    ? "is-invalid"
                                    : ""
                                  }`}
                              >
                                <option value="">Select accountype</option>
                                <option value="savings">Savings</option>
                                <option value="current">Current</option>
                              </select>
                              {formik.touched.accountype &&
                                formik.errors.accountype && (
                                  <FormFeedback type="invalid">
                                    {formik.errors.accountype}
                                  </FormFeedback>
                                )}
                            </div>

                            <div className="mb-3">
                              <label
                                htmlFor="status-field"
                                className="form-label"
                              >
                                Status
                              </label>
                              <select
                                id="status-field"
                                // className="form-control"
                                name="isActive"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.isActive || ""}
                                className={`form-control ${formik.touched.isActive &&
                                    formik.errors.isActive
                                    ? "is-invalid"
                                    : ""
                                  }`}
                              >
                                <option value="">Select isActive</option>
                                <option value="1">Active</option>
                                <option value="0">InActive</option>
                              </select>
                              {formik.touched.isActive &&
                                formik.errors.isActive && (
                                  <FormFeedback type="invalid">
                                    {formik.errors.isActive}
                                  </FormFeedback>
                                )}
                            </div>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <div className="hstack gap-2 justify-content-end">
                            <button
                              type="button"
                              className="btn btn-light"
                              // onClick={() => setmodal_list(false)}
                            // onClick={handleifsc}
                            onClick={handleClose}
                            >
                              Close
                            </button>

                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? <Loader /> : "Submit"}
                            </button>
                          </div>
                        </ModalFooter>
                      </Form>
                    </Modal>

                    {/* UpdateModal */}
                    <Modal className="modal-dialog modal-lg" isOpen={modal_update_list} toggle={() => {  tog_update_list();  }} centered backdrop="static" >
                      <ModalHeader className="bg-light p-3"  id="exampleModalLabel" toggle={() => {  tog_update_list(); }} >
                        Update Bank Details
                      </ModalHeader>
                      <Form autoComplete="off" className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validationUpdate.handleSubmit();
                          return false;
                        }}
                      >
                        <ModalBody className='d-lg-flex justify-content-around'>
                          <div className="col-lg-5 col-sm-12">
                            <div className="mb-3">
                              <label htmlFor="accountnumber-field" className="form-label" >
                                Account Number
                              </label>

                              <Input
                                type="text"
                                id="accountnumber-field"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter Account Number"
                                name="accountnumber"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={
                                  validationUpdate.values.accountnumber || ""
                                }
                                invalid={
                                  validationUpdate.touched.accountnumber &&
                                  validationUpdate.errors.accountnumber
                                }
                              />

                              {validationUpdate.touched.accountnumber &&
                                validationUpdate.errors.accountnumber && (
                                  <FormFeedback type="invalid">
                                    {validationUpdate.errors.accountnumber}
                                  </FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="ifsccode-field"
                                className="form-label"
                              >
                                IFSC Code
                              </label>
                              <InputGroup>
                                <Input
                                  type="text"
                                  id="ifsccode-field"
                                  className="form-control"
                                  placeholder="Enter IFSC Code"
                                  name="ifsccode"
                                  autoComplete="off"
                                  onChange={validationUpdate.handleChange}
                                  onBlur={validationUpdate.handleBlur}
                                  value={validationUpdate.values.ifsccode || ""}
                                  invalid={
                                    validationUpdate.touched.ifsccode &&
                                    validationUpdate.errors.ifsccode
                                  }
                                />
                                {/* <InputGroupText>
                                  {updateLoader ? (
                                    <Button color="primary" size="sm" disabled>
                                      <Spinner size="sm" />
                                    </Button>
                                  ) : (
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() =>
                                        handleVerifyupdate(
                                          validationUpdate.values
                                        )
                                      }
                                    >
                                      Verify
                                    </Button>
                                  )}
                                </InputGroupText> */}
                                {validationUpdate.touched.ifsccode &&
                                  validationUpdate.errors.ifsccode && (
                                    <FormFeedback type="invalid">
                                      {validationUpdate.errors.ifsccode}
                                    </FormFeedback>
                                  )}
                              </InputGroup>

                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="customername-field"
                                className="form-label"
                              >
                                Account Holder Name
                              </label>
                              <Input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Account Holder Name"
                                name="accountholdername"
                                autoComplete="off"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={
                                  validationUpdate.values.accountholdername || ""
                                }
                                invalid={
                                  validationUpdate.touched.accountholdername &&
                                  validationUpdate.errors.accountholdername
                                }
                              />
                              {validationUpdate.touched.accountholdername &&
                                validationUpdate.errors.accountholdername && (
                                  <FormFeedback type="invalid">
                                    {validationUpdate.errors.accountholdername}
                                  </FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="bankname-field"
                                className="form-label"
                              >
                                Bank Name
                              </label>
                              <Input
                                type="text"
                                id="bankname-field"
                                className="form-control"
                                placeholder="Enter Bank Name"
                                name="bankname"
                                autoComplete="off"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={validationUpdate.values.bankname || ""}
                                invalid={
                                  validationUpdate.touched.bankname &&
                                  validationUpdate.errors.bankname
                                }
                              />
                              {validationUpdate.touched.bankname &&
                                validationUpdate.errors.bankname && (
                                  <FormFeedback type="invalid">
                                    {validationUpdate.errors.bankname}
                                  </FormFeedback>
                                )}
                            </div>
                          </div>
                          <div className="col-lg-5 col-sm-12">
                            <div className="mb-3">
                              <label
                                htmlFor="branchname-field"
                                className="form-label"
                              >
                                Branch Name
                              </label>
                              <Input
                                type="text"
                                id="branchname-field"
                                className="form-control"
                                placeholder="Enter Branch Name"
                                name="branchname"
                                autoComplete="off"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={validationUpdate.values.branchname || ""}
                                invalid={
                                  validationUpdate.touched.branchname &&
                                  validationUpdate.errors.branchname
                                }
                              />
                              {validationUpdate.touched.branchname &&
                                validationUpdate.errors.branchname && (
                                  <FormFeedback type="invalid">
                                    {validationUpdate.errors.branchname}
                                  </FormFeedback>
                                )}
                            </div>

                            <div className="mb-3">
                              <label
                                htmlFor="accountype-field"
                                className="form-label"
                              >
                                Account Type
                              </label>
                              <select
                                id="accountype-field"
                                name="accountype"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={validationUpdate.values.accountype || ""}
                                className={`form-control ${validationUpdate.touched.accountype &&
                                    validationUpdate.errors.accountype
                                    ? "is-invalid"
                                    : ""
                                  }`}
                              >
                                <option value="">Select accountype</option>
                                <option value="savings">Savings</option>
                                <option value="current">Current</option>
                              </select>
                              {validationUpdate.touched.accountype &&
                                validationUpdate.errors.accountype && (
                                  <FormFeedback type="invalid">
                                    {validationUpdate.errors.accountype}
                                  </FormFeedback>
                                )}
                            </div>

                            <div className="mb-3">
                              <label
                                htmlFor="isActive-field"
                                className="form-label"
                              >
                                Status
                              </label>
                              <select
                                id="isActive-field"
                                name="isActive"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={validationUpdate.values.isActive || ""}
                                className={`form-control ${validationUpdate.touched.isActive &&
                                    validationUpdate.errors.isActive
                                    ? "is-invalid"
                                    : ""
                                  }`}
                              >
                                <option value="">Select isActive</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </select>
                              {validationUpdate.touched.isActive &&
                                validationUpdate.errors.isActive && (
                                  <FormFeedback type="invalid">
                                    {validationUpdate.errors.isActive}
                                  </FormFeedback>
                                )}
                            </div>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <div className="hstack gap-2 justify-content-end">
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={() => setmodal_update_list(false)}
                            >
                              Close
                            </button>
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? <Loader /> : "Submit"}
                            </button>
                          </div>
                        </ModalFooter>
                      </Form>
                    </Modal>


                    <Modal size="l"  isOpen={showModel}   toggle={() => { tog_xlarge(); }} centered backdrop="static" >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
                          Account Details
                        </h5>
                        <button
                          onClick={() => { setShowModel(false);}}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                   
                            <div className="table-responsive">
                              <table className="table table-bordered mb-0" style={{width:'100%'}}>
                                <tbody>
                                  <tr>

                                    <td><b>Account Number</b></td>
                                    <td>{showDetails.accountNumber}</td>

                                  </tr>
                                  <tr>

                                    <td><b>Account Holder Name</b></td>
                                    <td>{showDetails.holderName}</td>

                                  </tr>
                                  <tr>

                                    <td><b>Bank Name</b></td>
                                    <td>{showDetails.bankName}</td>

                                  </tr>
                                  <tr>

                                    <td><b>Branch Name</b></td>
                                    <td>{showDetails.branchName}</td>

                                  </tr>
                                  <tr>

                                    <td><b>IFSC Number</b></td>
                                    <td>{showDetails.ifscCode}</td>

                                  </tr>
                                </tbody>
                              </table>
                            </div>
                         
                      </div>
                    </Modal>


                    <Modal className="modal-dialog modal px-4"   size="l"   isOpen={showOTPModel} centered backdrop="static"  >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          Account Add OTP
                        </h5>
                        <button
                          onClick={() => {
                            setShowOTPModel(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                    
                                    <div>
                                        <div className="text-center">
                                           
                                        </div>
                                        <h4 className="font-size-18 text-muted mt-2 text-center">
                                            Verify Your OTP
                                        </h4>
                                      
                                        <div className="user-thumb text-center m-b-30">
                                            <h4 className="rounded-circle avatar-lg img-thumbnail mx-auto d-flex justify-content-center align-items-center">{countdown === 60 ? ("0") : (countdown)}<span style={{fontSize:'15px',marginTop:'7px'}}>s</span></h4>
                                        </div>
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                        
                                            <Row>
                                                <Col md={12} className="AuthStar">
                                                    <div className="mb-4">
                                                        <Label className="form-label">One Time Password<span>*</span></Label>
                                                        <InputGroup>
                                                            <Input
                                                                style={{ borderColor: '#80838B' }}
                                                                name="otp"
                                                                disabled={loading || countdown === 60 || countdown === 0 }
                                                                className="form-control"
                                                                placeholder="Enter OTP"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.otp || ""}
                                                                invalid={validation.touched.otp && validation.errors.otp ? true : false}
                                                            />
                                                          {countdown === 0 && 
                                                                <Button
                                                                    onClick={resendOTP}
                                                                    className="btn btn-primary waves-effect waves-light"
                                                                    style={{ backgroundColor: '#80838B', color: 'white' }}
                                                                    disabled={isDisabled || loading}
                                                                >
                                                                     {loading ? ( 
                                                                            <Spinner size="sm" />    
                                                                    ) : ("ReSend")}
                                                                </Button> }
                                                            {validation.touched.otp && validation.errors.otp ? (
                                                                <FormFeedback>{validation.errors.otp}</FormFeedback>
                                                            ) : null}
                                                        </InputGroup>
                                                    </div>

                                                    <div className="d-grid mt-4">
                                                  
                                                            <button
                                                                className="btn btn-primary waves-effect waves-light"
                                                                type="submit"
                                                                disabled={loading || countdown === 60 || submitLoad}
                                                                onClick={validation.handleSubmit}
                                                            >
                                                                      {submitLoad ? (
                                                            <>
                                                                <Spinner size="sm">
                                                                    Loading...
                                                                </Spinner>
                                                                <span style={{ color: 'white' }}>
                                                                    {' '}Loading...
                                                                </span>
                                                            </>


                                                        ) : ("Submit")}
                                                                
                                                            </button>

                                                       


                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                             
                      

                           
                         
                      </div>
                  </Modal>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SiteBank;

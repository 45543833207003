import React, { useState, useEffect, useContext } from "react";
import { Col, Card, CardBody, Input, ListGroupItem, ListGroup, Row ,Spinner} from "reactstrap";
import socketIOClient from "socket.io-client"; 
import "./chat.css";
import { ChatPostGetData } from "../../ApiService/AxiosInstance";
import { AdminContext } from '../../helpers/adminContext'; 
import {  formatMonthDateTime } from '../../helpers/common';
import { Apiservices, configServices } from "../../ApiService/ApiServices";
import toast from "react-hot-toast";
import UserProfile from '../../assets/images/user.png'
const CHAT_SOCKET_URL = Apiservices.BACKEND_CHAT_SOCKET_BACKEND_SERVER;

const InboxSidebar = ({ onUserSelect, lockChatStatus, setlockChatStatus, setSelectedChat }) => {
  const { adminInfo } = useContext(AdminContext);
  const [activeChatId, setActiveChatId] = useState(null); 
  const [userList, setUserList] = useState([]);
  const [searchVal, setSearchVal] = useState(""); 
  const [socketConnection, setSocketConnection] = useState(null);
  const [lockedbyDetails,setLockedDetails] = useState('')
  const [getIsLocked,setIsLocked] = useState('')
  const [isLoad,setIsLoad] = useState(false)
  const [selectedItem, setSelectedItem] = useState('Select');


  useEffect(() => {
    if(configServices.CHAT_SOCKET_CONNECTION !== "Disable") {
    let socket;
    if (!socketConnection) {
      socket = socketIOClient(CHAT_SOCKET_URL, { transports: ["websocket"]});
      socket.on("connect", () => {
        setSocketConnection(socket);
      });

      socket.on("connect_error", (err) => {
        console.log("Socket connection error:", err);
      });
      socket.on("disconnect", () => {
        setSocketConnection(null);
      });
      socket.on("chatAllList", (data) => {
        if (data) {
          getChatList();
        }
      });
      socket.on("userchatMessageReceiver", (data) => {
        if (data) {
          getChatList();
        }
      });
      socket.on("chatFirstMessage", (data) => {
        if (data) {
          getChatList();
        }
      });
      socket.on("chatEndList", (data) => {
        if (data) {
          getChatList();
        }
      });
      
    }

    return () => {
      if (socketConnection) {
        socketConnection.disconnect();
        console.log("Socket disconnected on unmount");
      }
    };
  }
  }, [socketConnection]);

  useEffect(() => {
    if(configServices.CHAT_SOCKET_CONNECTION !== "Disable") {
    if (socketConnection && socketConnection != null) {
      loadSocket();
    }
  }
  }, [socketConnection])

  if (loadSocket == true) {
    loadSocket();
  }

  async function loadSocket() {
    if(configServices.CHAT_SOCKET_CONNECTION !== "Disable") {
    try {
      socketConnection.on("lockUserResponse", (data) => {
        if (data) {
          getChatList();
        }
      });
      socketConnection.on("userUnLockResponse", (data) =>{
        if (data) {
          getChatList();
        }
      })
      socketConnection.on("chatEnded", (data) => {
        if (data) {
          setSelectedChat("");
          getChatList();
        }
      })
      socketConnection.on("userchatMessageReceiver", (data) => {
        if (data) {
          getChatList();
        }
      });
      socketConnection.on("chatTransfer", (data) => {
        if (data) {
          getChatList();
        }
      });
      
      socketConnection.on("chatMessageList", (data) => {
        if (data) {
          if (data.chatMsg && data.chatMsg.chatId) {
            setActiveChatId(data.chatMsg.chatId);
          }
          onUserSelect(data.userDet);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }
  }
  useEffect(() => {
    getChatList();
  }, []);

  async function getChatList() {
    try {
      
      const response = await ChatPostGetData(Apiservices.CHAT_LIST, { "search": "" });
      if (response && response.status) {
      setUserList(response.userDet);
      setLockedDetails(response?.userDet[0]?.chatMsg?.lockUser[0])
      

      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleUserSelect = async (chat, defaultSelect) => {
      if (chat?.chatMsg?.isLocked == 0) {
        toast.error('Please Lock First');
      } 
      else if (chat.chatMsg.lockUser[0]?.adminId !== adminInfo?.adminUserId) {
        toast.error('This is locked by another agent');
      } 
      else {
        if (chat.chatMsg) {
          if (
            chat.chatMsg.lockUser && 
            Object.keys(chat.chatMsg.lockUser).length > 0 && 
            chat.chatMsg.lockUser.status == 1 &&
            chat.chatMsg.lockUser.userId !== adminInfo._id.toString()
          ) {
            setlockChatStatus(true);
            return false;
          } else {
            setlockChatStatus(false);
          }
  
          if (chat.chatMsg.chatId) {
            setActiveChatId(chat.chatMsg.chatId);
  
            if (socketConnection) {
              socketConnection.emit("adminChatMessage", {
                type: "newJoin", 
                chatId: chat.chatMsg.chatId, 
                jwt: adminInfo.jwt
              });
            }
          }
  
          onUserSelect(chat);
        }
      }
   
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };




  const handleLockClick = async (chatID) => {
    setIsLoad(true)
    const postForm = {
      chatId:chatID,
      isLocked:1,
      adminId:adminInfo?.adminUserId
    }
    try {
      socketConnection.emit("lockChat",  postForm);
      socketConnection.on("lockUserResponse", (response) => {
        if (response) {
        setIsLoad(false)
          
         if (response.status == true) {
          toast.success(response.message)
          setIsLocked(response[0]?.data?.isLocked)
          setLockedDetails(response[0]?.data?.lockUser[0])
          getChatList();
          setActiveChatId(chatID)
          } else {
          toast.error(response.message)
          setIsLoad(false)

          }
        }
      });
     
    } 
    catch (error) {
      
      toast.error(error.message)
      setIsLoad(false)

    }
  };

  async function handleChange(event) {
    let value = event.target.value;
    setSearchVal(value);
    if (value === '') {
      getChatList()
    }
   
  }
  const [searchLoad, setSearchLoad] = useState(false);
  const handleSearch = async () => {
    if (searchVal !== "") {
      setSearchLoad(true);
      const postForm = {
        search: searchVal,
      };

      try {
        const response = await ChatPostGetData(Apiservices.CHAT_LIST,postForm);
        if (response.status) {
          setUserList(response.userDet);
          setSearchLoad(false);
          
        } else {
          getChatList();
          setSearchLoad(false);
        }
      } 

      catch (error) {
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    }
 
  };
  const handleDropdownClick = (item) => {
    setSelectedItem(item);
    if(item === ''){
      handleClickFilter('')
    }
    else{
      handleClickFilter(Number(item))
    }
};
const [loading,setLoading] = useState(false)
const handleClickFilter = async (item) => {
  
  const postForm = {
    isLocked: item,
  };

  try {
      setLoading(true);
      const response = await ChatPostGetData(Apiservices.CHAT_LIST, postForm);
      if (response.status) {
        setUserList(response.userDet);
          setLoading(false);
      } else {
          setLoading(false);
      }
  } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
  }
};
  return (
    <Col xl={4} className="mb-4 mb-xl-0">
      <Card className="chat-sidebar-card shadow-sm border-0">
        <CardBody>
          <div className="search-box mb-3">
            {/* <select
              className="form-select"
              value={selectedItem}
              onChange={(e) => handleDropdownClick(e.target.value)}
            >
              <option  value="" disabled>
                Select Lock Status
              </option>
             
              <option value={""}>Show All</option>
              <option value={0}>Lock</option>
              <option value={1}>Locked</option>
            </select> */}
            <div className="input-group">
              <Input
                // onChange={handleInputChange}
                type="text"
                className="form-control"
                placeholder="Search ..."
                aria-label="Recipient's username"
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => handleSearch()}
                  disabled={searchLoad}
                >
                  {searchLoad ? (
                    <Spinner className="text-light" size="sm" />
                  ) : (
                    <i className="ri-search-line" />
                  )}
                </button>
              </div>
            </div>
          </div>
          <ListGroup className="chat-list custom-scrollbar">
            {userList.length > 0 ? (
              userList.map((data) => {
                return (
                  <>
                    {data.chatMsg && (
                      <ListGroupItem
                        key={data.chatMsg.chatId ? data.chatMsg.chatId : ""}
                        className={`chat-item ${
                          activeChatId === data.chatMsg.chatId
                            ? "active-chat"
                            : ""
                        }`}
                        onClick={() => handleUserSelect(data, userList[0])}
                      >
                        <Row className="align-items-center">
                          {/* Chat Details */}
                          <Col xs="9" className="px-0">
                            <div className="d-flex flex-column chat_hold position-relative">
                              {data.profileImg ? (
                                <img
                                  src={data.profileImg}
                                  alt={data.fullName}
                                  className="rounded-circle chat-img"
                                  width="45"
                                  height="45"
                                />
                              ) : (
                                <img
                                  src={UserProfile}
                                  alt=""
                                  className="rounded-circle chat-img"
                                  width="45"
                                  height="45"
                                />
                              )}

                              <strong className="chat-name">
                                {data.fullName || ""}
                              </strong>
                              <small className="text-muted">
                                {data.email || ""}
                              </small>
                            </div>
                          </Col>

                          {/* Notification Badge and Time */}
                          <Col xs="3" className="text-end px-0">
                            <small className="text-muted chat-time">
                              {formatMonthDateTime(
                                new Date(data.chatMsg.startedchatAt)
                              )}
                            </small>
                          </Col>
                        </Row>

                        {/* Locked By Details */}
                        {data.chatMsg.isLocked === 1 && (
                          <Row className="mt-2 align-items-center">
                            <Col xs="10" className="px-0">
                              <small className="text-muted">
                                Locked By: {data.chatMsg.lockUser[0]?.adminName}
                              </small>
                              <br />
                              <small className="text-muted">
                                {data.chatMsg.lockUser[0]?.adminEmail}
                              </small>
                            </Col>
                            {adminInfo?.adminType === 1 ||
                            adminInfo?.permissions.some(
                              (data) =>
                                data.module === "Live Chat List" &&
                                data.actions.includes(3)
                            ) ? (
                              <Col
                                xs="2"
                                className="d-flex justify-content-end px-0"
                              >
                                <button
                                  type="button"
                                  className="btn btn-sm btn-outline-secondary lock-button"
                                  disabled
                                >
                                  {"Locked"}
                                </button>
                              </Col>
                            ) : null}
                          </Row>
                        )}
                      </ListGroupItem>
                    )}

                    {/* Lock Button (if not locked) */}
                    {!(data.chatMsg.isLocked === 1) && (
                      <Row className="mt-2 align-items-center mx-2">
                        <Col xs="10" className="px-0">
                          {data?.chatMsg?.lockUser[0]?.length > 0 && (
                            <>
                              <small className="text-muted">
                                Locked By: {data.chatMsg.lockUser[0]?.adminName}
                              </small>
                              <br />
                              <small className="text-muted">
                                Admin Email:{" "}
                                {data.chatMsg.lockUser[0]?.adminEmail}
                              </small>
                            </>
                          )}
                        </Col>
                        {adminInfo?.adminType === 1 ||
                        adminInfo?.permissions.some(
                          (data) =>
                            data.module === "Live Chat List" &&
                            data.actions.includes(3)
                        ) ? (
                          <Col xs="2" className="px-0">
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-secondary lock-button float-end"
                              onClick={() =>
                                handleLockClick(data?.chatMsg?.chatId)
                              }
                            >
                              {data?.chatMsg?.chatId === activeChatId && isLoad
                                ? "Loading..."
                                : "Lock"}
                            </button>
                          </Col>
                        ) : null}
                      </Row>
                    )}
                    <hr></hr>
                  </>
                );
              })
            ) : (
              <span className="text-center">No Data Found !</span>
            )}
          </ListGroup>
        </CardBody>
      </Card>
    </Col>
  );
};

export default InboxSidebar;
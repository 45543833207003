const MenuPermissions = {
    "Wallet Type Update": [1, 2, 3, 4],
    "How to claim USDT": [1, 2, 3, 4],
    "Wallet Management":[1,2],
    "Overall Bonus": [2,3],
    "Social Media Bonus": [2],
    "Fiat Deposit History": [2],
    "Fiat Deposit Completed History": [2],
    "Fiat Deposit Pending History": [2,3],
    "Fiat Deposit Rejected History": [2],
    "Deposit Crypto History": [2],
    "All Users KYC": [2],
    "Active Users": [2],
    "Pending Users":[2],
    "Blocked Users":[2],
    "User KYC List":[2],
    "Users List":[2],
    "Social Media History":[2],
    "Active Users KYC": [2],
    "Pending Users KYC": [2],
    "Rejected Users KYC": [2],
    "Not Upload Users KYC": [2],
    "User IP Block": [1,2,4],
    "All Users": [2,3,4],
    "Swap Management":[2],
    "Coin Management": [1,2,3,4],
    "User Bank Details":[2],
    "Deposit History":[2],
    "Crypto Deposit History":[2],
    "Site Settings": [2,3],
    "Admin IP White List": [1,2,3],
    "Site IP White List": [1,2],
    "Transaction History":[2],
    "User Transaction History": [2],
    "Swap History": [2],
    "Site Bank Details": [1,2,3,4],
    "Admin Users": [1,2,3,4],
    "Admin Login Activities": [2],
    "Admin Transaction": [1,2],
    "User Signup History": [2],
    "User Referral History": [2],
    "Voting":[2],
    "User Voting History":[2],
    "Email Template": [1,2,3],
    "Staking":[2],
    "Staking offers": [1,2,3,4],
    "Stake Redeem History":[2],
    "Fiat Withdraw":[2],
    "Fiat Withdraw Completed History": [2],
    "Fiat Withdraw Pending History": [2,3],
    "Fiat Withdraw Rejected History": [2],
    "Crypto Withdraw":[2],
    "Users Stake History": [2],
    "Stake History View": [],
    "Academy": [1,2,3,4],
    "Trade Management": [1,2,3,4],
    "Swap pairs": [1,2,3],
    "Community": [1,2,3,4],
    "Withdraw History":[2],
    "Home Management": [1,2],
    "Completed History": [2],
    "Pending History": [2,3],
    "Rejected History": [2],
    "Verified Bank": [2],
    "Pending Bank Verification": [2],
    "Admin Rejected Bank": [2],
    "Settings":[2],
    "Maintenance Settings": [2,3],
    "Network Management": [],
    "CMS Management": [],
    "Trade History":[2],
    "Over All Trade History": [2],
    "Active Orders": [2],
    "Completed Orders": [2],
    "Cancelled Orders": [2],
    "Today Users": [2],
    "User Deleted Bank": [2],
    "Expo Users": [2],
    "Discover Management": [1,2,3,4],
    "Teams Management": [1,2,3,4],
    "Compare Exchanges": [1,2,3,4],
    "Probinar Academy": [1,2,3,4],
    "OverAll Votes": [2,3],
    "Pending Votes": [2,3],
    "Approved Votes": [2],
    "Rejected Votes": [2],
    "Update Admin User": [],
    "Add Admin User": [1,2],
    "Add Menus": [1,2,3,4],
    "Wallet Types": [2,3],
    "Crypto News": [1,2,3,4,],
    "SocialMedia Bonus":[2],
    "Crypto News Notes": [2],
    "User Management":[2],
    "User List":[2],
    "Admin Management":[2],
    "Dashboard":[2,3],
    "Admin Log Activites":[2],
    "Customer Task":[1,2,3,4],
    "Task History":[2],
    "Task":[2],
    "Stake Without KYC History (Reward Wallet)":[2],
    "Stake,KYC Without Withdraw History (Reward Wallet)":[2],
    "Stake All Completed History (Reward Wallet)":[2],
    "Live Chat": [2],
    "Agent Users": [1,2,3,4],
    "Live Chat List": [2, 3],
    "Locked Chats": [2, 3],
    "End Chats": [2],
    "Push Notification":[1,2],
    "Chat Message Content":[1,2,3,4],
    "Flexible Staking":[2],
    "User Stake History (Flexible Stake)":[2,3],
    "Flexible Stake Scheme":[1,2,3,4],
    "Flexible Stake Plan":[1,2,3,4],
    "Claim Rewards":[2],
    "Referral Claim Rewards":[2]
  };
  
export default MenuPermissions;
  export const permissionRequirements = {
    requiresUserManagement: ['Users List'],
    requiresUserList: ['All Users', 'Today Users', 'Active Users','Pending Users','Blocked Users','User IP Block'],
    requiredSwapManagement: ['Swap pairs', 'Swap History'],
    requiredTransactionHistory: ['User Transaction History', 'User Signup History', 'User Referral History', 'User Voting History', 'Social Media History'],
    requiredTradeHistory: ['Over All Trade History', 'Active Orders', 'Completed Orders', 'Cancelled Orders'],
    requiredAdminManagement: ['Admin Users', 'Add Menus', 'Admin IP White List', 'Admin Login Activities', 'Wallet Types','Admin Transaction'],
    requiredBankDetails: ['Verified Bank', 'Pending Bank Verification', 'Admin Rejected Bank', 'User Deleted Bank'],
    requiredWithdrawHistory: ['Completed History', 'Pending History', 'Rejected History','Fiat Withdraw Completed History','Fiat Withdraw Pending History','Fiat Withdraw Rejected History'],
    requiredSettings: ['Site Settings', 'Maintenance Settings', 'Site Bank Details', 'Email Template'],
    requiredStaking: ['Staking offers', 'Users Stake History','Stake Without KYC History (Reward Wallet)','Stake,KYC Without Withdraw History (Reward Wallet)','Stake All Completed History (Reward Wallet)','Stake Redeem History'],
    requiredFlexible: ['Flexible Stacking','User Stack History (Flexible Stack)'],
    requiredVoting: ['OverAll Votes', 'Pending Votes', 'Approved Votes', 'Rejected Votes'],
    requiredSocialMediaBonus: ['Overall Bonus'],
    requiredDeposit: ['Crypto Deposit History','Fiat Deposit History','Fiat Deposit Completed History','Fiat Deposit Pending History','Fiat Deposit Rejected History'],
    requiredFiatDeposit: ['Fiat Deposit Completed History','Fiat Deposit Pending History','Fiat Deposit Rejected History'],
    requiredFiatWithdraw :['Fiat Withdraw Completed History','Fiat Withdraw Pending History','Fiat Withdraw Rejected History'],
    requiredCustomerTask: ['Customer Task','Task History'],
    requiredLiveChat: ['Agent Users','Live Chat List','Locked Chats','End Chats','Chat Message Content'],
  };
 
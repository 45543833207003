

import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const UserBankDetails = () => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState([
    {
        name:'Nantha',
        email:'nanthu03tmg@gmail.com',
        phone:'9384183624'
    },
    {
        name:'Kumar',
        email:'nanthu03tmg@gmail.com',
        phone:'9384183624'
    },
    {
        name:'Singam',
        email:'nanthu03tmg@gmail.com',
        phone:'9384183624'
    }

    ]);
    const [totalPagination, setTotalPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(0);

    const itemsPerPage = 10; // Number of items to display per page
    const pageCount = Math.ceil(totalPagination.total / itemsPerPage);

    useEffect(() => {
        ipList();
    }, []);



    const ipList = async () => {
        setLoading(true);

        try {
            const response = await postData(Apiservices.ADMIN_USER_LIST);
            if (response.status) {
        setLoading(false);
                setFormData(response.data);
                setTotalPagination(response.pagination);
            } else {
                toast.error(response.message);
        setLoading(false);

            }
        } catch (error) {
            toast.error("Internal Server Error...");
        setLoading(false);


        }
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        handlePagePost(selected);
    };

    const handlePagePost = async (selected) => {
        setLoading(true);
        const postForm = {
            page: selected + 1,
            limit: itemsPerPage
        };

        try {
            const response = await postData(Apiservices.ADMIN_USER_LIST, postForm);
            if (response.status) {
                setLoading(false);
                setFormData(response.data);
            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Bank Name',
            selector: row => row.email,
            sortable: true
        },
        {
            name: 'IFSC Code',
            selector: row => row.phone,
            sortable: true
        },
        {
            name: 'Date',
            selector: row => utcToNormalTime(row.createdAt).date,
            sortable: true
        },
        {
            name: 'Action',
            cell: row => (
                <div className="edit">
                <Button
                className="btn btn-sm btn-success edit-item-btn"
                   
                    size="sm"
                    onClick={handleNav}
                >
                    View
                </Button>
                </div>
            ),
            ignoreRowClick: true,
            // allowOverflow: true,
            // button: true,
        },
    ];

    const handleNav = async (record) => {
        // navigate('/allUsers/userDetails')
        // Implement deletion logic here
    };
    const utcToNormalTime = (utcString) => {
        const date = new Date(utcString);
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`;
        const formattedTime = `${date.getHours()
          .toString()
          .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
            .getSeconds()
            .toString()
            .padStart(2, "0")}`;
        return { date: formattedDate, time: formattedTime };
      };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="User Management" breadcrumbItem="User Bank List" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                    <Col sm={4} lg={3}>
                                        <div className="input-group">
                                                    <input
                                                    // onChange={handleInputChange}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search ..."
                                                        aria-label="Recipient's username"
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="ri-search-line" />
                                                        </button>
                                                    </div>
                                                </div>
                                        </Col>
                                    </Row>
                                    <div className="table-responsive">
                                    <DataTable
                                                columns={columns}
                                                data={formData}
                                                paginationServer
                                                paginationTotalRows={formData.length}
                                                progressPending={loading}
                                                
                                                noDataComponent
                                                // noDataComponent={<div>No records found</div>}
                                                fixedHeader={true}

                                            />
                                    </div>
                                    {loading ? (
                                        ""

                                    ):(

                                    <div className="d-flex justify-content-end mt-3">
                                        <div className="pagination pagination-rounded mb-0">
                                        <ReactPaginate
                                                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={pageCount}
                                                            marginPagesDisplayed={1}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={handlePageClick}
                                                            containerClassName={"pagination"}
                                                            activeClassName={"active"}
                                                            pageLinkClassName={"page-link"}
                                                            breakLinkClassName={"break-link"}
                                                            disabledClassName={"disabled"}
                                                            initialPage={currentPage}
                                                            disableInitialCallback={true}
                                                            forcePage={currentPage}
                                                            // disableInitialCallback={true}
                                                            renderLink={(props) => {
                                                                if (
                                                                    props.page === "..." &&
                                                                    props.pageCount > props.pageRangeDisplayed
                                                                ) {
                                                                    return (
                                                                        <a
                                                                            {...props}
                                                                            onClick={() =>
                                                                                handlePageClick({
                                                                                    selected: pageCount - 1,
                                                                                })
                                                                            }
                                                                        >
                                                                            {pageCount}
                                                                        </a>
                                                                    );
                                                                }
                                                                return <a {...props} aria-label="Link Description" />;
                                                            }}
                                                        />
                                                        </div>
                                        
                                        
                                    </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UserBankDetails;

import React, { useState, useEffect ,useContext} from "react";
import { Col, Container, Row, Card, CardBody, Form, FormFeedback, Input } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import toast from "react-hot-toast";
import Loader from "../../helpers/Spinner";
import { useParams ,useLocation } from "react-router-dom";
import { handleNullValue } from "../../helpers/function";
import DataTable from "react-data-table-component";
import { useFormik } from "formik";
import * as Yup from "yup";
import MenuPermissions, { permissionRequirements } from "./MenuPermission";
import { AdminContext } from "../../helpers/adminContext";

const UpdateAdminUser = () => {
  const { id } = useParams();
  document.title = "Ultrapro | Update Admin User";
  const [loading, setLoading] = useState(false);
  const [getMenuList, setmenuList] = useState([]);
  const [getId,setId] = useState('')
  const location = useLocation();
  const record = location.state?.record || {}; 
  const [adminType,setAdminType] = useState('')
  const { adminInfo } = useContext(AdminContext);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    GetMenuList()
    GetSingleAdmin()
    setAdminType(adminInfo?.adminType)
  }, []);

//permission map

  const GetMenuList = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.MenuList);
 
      if (response.status) {
        setmenuList(response.data.data);
        setLoading(false);
      
      } else {
        setLoading(false);
      }
    } catch (error) {
      toast.error("Internal Server Error...");
      setLoading(false);
    }
  };

  const handleUpdateStatusClick = (row, action) => {
    const existingPermission = permissions.find((perm) => perm.module === row.name);
  
    if (existingPermission) {
      const updatedActions = existingPermission?.actions.includes(action)
        ? existingPermission?.actions.filter((a) => a !== action) 
        : [...existingPermission?.actions, action]; 
  
      // Check if updated actions is empty
      if (updatedActions?.length === 0 && existingPermission?._id?.length === 0 ) {
        setPermissions((prevPermissions) =>
          prevPermissions.filter((perm) => perm.module !== row.name)
        );
      } else {
        setPermissions((prevPermissions) =>
          prevPermissions.map((perm) =>
            perm.module === row.name ? { ...perm, actions: updatedActions } : perm
          )
        );
      }
    } else {
      // Add new module with the action
      setPermissions((prevPermissions) => [
        ...prevPermissions,
        { module: row.name, actions: [action] },
      ]);
    }
  };

useEffect(() => {
  if (record.permissions) {
    setDefaultPermissions(record.permissions);
  }
}, [record.permissions]);



const [loading1, setLoading1] = useState(false);

const GetSingleAdmin = async () => {
  const postForm = {
    id :id
  }
  setLoading1(true);
  try {
      const response = await postData(Apiservices.GetSingleAdminUSer,postForm);
      if (response.status === true) {
          setLoading(false);
          setId(record.adminUserId)
          validation.setValues({
            // adminType 1 - super admin , 2-sub admin
            email: response.data.data[0].email || "",
            adminName: response.data.data[0].name || "",
            // password: response.data.data[0].password || "",
            role:response.data.data[0].adminType || "",
          });  
          
          setDefaultPermissions(response.data.data[0].permissions);
      } else {
          setLoading1(false);
      }
  } catch (error) {
      setLoading1(false);
  }
};


const setDefaultPermissions = (permissionget) => {
  

  if (!Array.isArray(permissionget)) {
    console.error("Expected permissionget to be an array but got:", permissionget);
    return;   
  }

  setPermissions(() =>
    permissionget.map((perm) => ({
      module: perm.module,
      actions: perm.actions || [],
      _id: perm._id
    }))
  );
};

//no permission -0  create-1, view-2, edit-3, delete-4, 
const checks = {
  flexibleStakeChecked: permissions.some(perm => perm.module === 'Flexible Staking' && perm.actions.includes(2)),
  WithdrawHistoryChecked: permissions.some(perm => perm.module === 'Withdraw History' && perm.actions.includes(2)),
  settingsChecked: permissions.some(perm => perm.module === 'Settings' && perm.actions.includes(2)),
  stakingChecked: permissions.some(perm => perm.module === 'Staking' && perm.actions.includes(2)),
  votingChecked: permissions.some(perm => perm.module === 'Voting' && perm.actions.includes(2)),
  socialMediaBonusChecked: permissions.some(perm => perm.module === 'SocialMedia Bonus' && perm.actions.includes(2)),
  bankDetailsChecked: permissions.some(perm => perm.module === 'User Bank Details' && perm.actions.includes(2)),
  adminManagementChecked: permissions.some(perm => perm.module === 'Admin Management' && perm.actions.includes(2)),
  tradeHistoryChecked: permissions.some(perm => perm.module === 'Trade History' && perm.actions.includes(2)),
  transactionHistoryChecked: permissions.some(perm => perm.module === 'Transaction History' && perm.actions.includes(2)),
  swapManagementChecked: permissions.some(perm => perm.module === 'Swap Management' && perm.actions.includes(2)),
  userListChecked: permissions.some(perm => perm.module === 'Users List' && perm.actions.includes(2)),
  userManagementChecked: permissions.some(perm => perm.module === 'User Management' && perm.actions.includes(2)),
  requiredDepositMenu: permissions.some(perm => perm.module === 'Deposit History' && perm.actions.includes(2)),
  requiredCustomerTaskMenu: permissions.some(perm => perm.module === 'Task' && perm.actions.includes(2)),
  requiredLiveChatMenu: permissions.some(perm => perm.module === 'Live Chat' && perm.actions.includes(2)),
  requiredFiatDeposit: permissions.some(perm => perm.module === 'Fiat Deposit History' && perm.actions.includes(2)),
  requiredFiatWithdraw: permissions.some(perm => perm.module === 'Fiat Withdraw History' && perm.actions.includes(2)),

};
function validatePermissions(name,action) {
  // Assuming 'row.name' should be replaced with 'name'
  if (permissionRequirements.requiresUserManagement.includes(name) && !checks.userManagementChecked) {
    toast.error('Please enable User Management first.');
    return false; // Return false indicating a validation error
  }
  // if (action !==2) {
  //   toast.error('Please enable view option first.');
  //   return false; 
  // }
  if (permissionRequirements.requiredBankDetails.includes(name) && !checks.bankDetailsChecked) {
    toast.error('Please enable User Bank Details first.');
    return false;
  }
  if (permissionRequirements.requiredAdminManagement.includes(name) && !checks.adminManagementChecked) {
    toast.error('Please enable Admin Management first.');
    return false;
  }
  if (permissionRequirements.requiredSettings.includes(name) && !checks.settingsChecked) {
    toast.error('Please enable Settings first.');
    return false;
  }
  if (permissionRequirements.requiredSocialMediaBonus.includes(name) && !checks.socialMediaBonusChecked) {
    toast.error('Please enable SocialMedia Bonus first.');
    return false;
  }
  if (permissionRequirements.requiredStaking.includes(name) && !checks.stakingChecked) {
    toast.error('Please enable Staking first.');
    return false;
  }
  if (permissionRequirements.requiredSwapManagement.includes(name) && !checks.swapManagementChecked) {
    toast.error('Please enable Swap Management first.');
    return false;
  }
  if (permissionRequirements.requiredFlexible.includes(name) && !checks.flexibleStakeChecked) {
    toast.error('Please enable Flexible Staking first.');
    return false;
  }
  if (permissionRequirements.requiredVoting.includes(name) && !checks.votingChecked) {
    toast.error('Please enable Voting first.');
    return false;
  }
  if (permissionRequirements.requiredTradeHistory.includes(name) && !checks.tradeHistoryChecked) {
    toast.error('Please enable Trade History first.');
    return false;
  }
  if (permissionRequirements.requiredTransactionHistory.includes(name) && !checks.transactionHistoryChecked) {
    toast.error('Please enable Transaction History first.');
    return false;
  }
  if (permissionRequirements.requiresUserList.includes(name) && !checks.userListChecked) {
    toast.error('Please enable Users List first.');
    return false;
  }
  if (permissionRequirements.requiredWithdrawHistory.includes(name) && !checks.WithdrawHistoryChecked) {
    toast.error('Please enable Withdraw History first.');
    return false;
  }
  if (permissionRequirements.requiredDeposit.includes(name) && !checks.requiredDepositMenu) {
    toast.error('Please enable Deposit History first.');
    return false;
  }
  if (permissionRequirements.requiredFiatDeposit.includes(name) && !checks.requiredFiatDeposit) {
    toast.error('Please enable Fiat Deposit History first.');
  return false;
  }
  if (permissionRequirements.requiredFiatWithdraw.includes(name) && !checks.requiredFiatWithdraw) {
    toast.error('Please enable Fiat Withdraw History first.');
  return false;
  }
  if (permissionRequirements.requiredCustomerTask.includes(name) && !checks.requiredCustomerTaskMenu) {
    toast.error('Please enable Task first.');
  return false;
  }
  
  if (permissionRequirements.requiredLiveChat.includes(name) && !checks.requiredLiveChatMenu) {
    toast.error('Please enable Live chart first.');
  return false;
  }
  return true; // Return true if all checks pass
}


const handlePermissionChange = (row, action) => {
  const isValid = validatePermissions(row.name,action);
  if (!isValid) {
    return; // If there's an error, exit early.
  }
  handleUpdateStatusClick(row, action);
};

const columns = [
  {
    name: "S.No",
    selector: (row) => handleNullValue(row.serialNo),
    sortable: true,
    grow: 1,
  },
  {
    name: "Menus",
    selector: (row) => handleNullValue(row.name),
    sortable: true,
    grow:2

  },
  {
    // name: "Create",
    selector: (row) => {
      const hasPermission = MenuPermissions[row.name]?.includes(1);
      const isChecked = permissions.some(perm => perm.module === row.name && perm.actions.includes(1));
      return (
        <div className="form-check mb-3 d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            className="form-check-input"
            id={`create-${row.serialNo}`} // unique ID
            checked={isChecked}
            onChange={() => handlePermissionChange(row, 1)}
            disabled={loading || !hasPermission} // disable if loading or no permission
          />
          <label className="form-check-label p-1" htmlFor={`create-${row.serialNo}`}>
            Create
          </label>
        </div>
      );
    },
    sortable: true,
},
{
  // name: "Create",
  selector: (row) => {
    const hasPermission = MenuPermissions[row.name]?.includes(2);
    const isChecked = permissions.some(perm => perm.module === row.name && perm.actions.includes(2));
    return (
      <div className="form-check mb-3 d-flex justify-content-center align-items-center">
        <input
          type="checkbox"
          className="form-check-input"
          id={`create-${row.serialNo}`} // unique ID
          checked={isChecked}
          onChange={() => handlePermissionChange(row, 2)}
          disabled={loading || !hasPermission} // disable if loading or no permission
        />
        <label className="form-check-label p-1" htmlFor={`create-${row.serialNo}`}>
          View
        </label>
      </div>
    );
  },
  sortable: true,
},
{
  // name: "Create",
  selector: (row) => {
    const hasPermission = MenuPermissions[row.name]?.includes(3);
    const isChecked = permissions.some(perm => perm.module === row.name && perm.actions.includes(3));
    return (
      <div className="form-check mb-3 d-flex justify-content-center align-items-center">
        <input
          type="checkbox"
          className="form-check-input"
          id={`create-${row.serialNo}`} // unique ID
          checked={isChecked}
          onChange={() => handlePermissionChange(row, 3)}
          disabled={loading || !hasPermission} // disable if loading or no permission
        />
        <label className="form-check-label p-1" htmlFor={`create-${row.serialNo}`}>
          Edit
        </label>
      </div>
    );
  },
  sortable: true,
},
{
  // name: "Create",
  selector: (row) => {
    const hasPermission = MenuPermissions[row.name]?.includes(4);
    const isChecked = permissions.some(perm => perm.module === row.name && perm.actions.includes(4));
    return (
      <div className="form-check mb-3 d-flex justify-content-center align-items-center">
        <input
          type="checkbox"
          className="form-check-input"
          id={`create-${row.serialNo}`} // unique ID
          checked={isChecked}
          onChange={() => handlePermissionChange(row, 4)}
          disabled={loading || !hasPermission} // disable if loading or no permission
        />
        <label className="form-check-label p-1" htmlFor={`create-${row.serialNo}`}>
          Delete
        </label>
      </div>
    );
  },
  sortable: true,
},
];


  
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      adminName: "",
      email: "",
      password: "",
      role:"",
    //  password:""

    },
    validationSchema: Yup.object({
      adminName: Yup.string().required("Admin Name is required"),
      email: Yup.string().email("Invalid email format").required("Email is required"),
     // password: Yup.string().required("Password is required"),
      role: Yup.string().required("Role is required"),
     
    }),
    onSubmit: (values) => {
      handlePost(values);
    },
  });
const [addLoad,setAddLoad] = useState(false)
  const handlePost = async (data) => {
    const postForm = {
      name: data.adminName,
      email: data.email,
      adminType: data.role,
      password:data.password,
      permissions: permissions,
      id:Number(id)
      
    }
    
    try {
      setAddLoad(true);

      const response = await postData(Apiservices.AdminUserPermissionUpdate, postForm);
      if (response.status === true) {
        setAddLoad(false);
        toast.success(response.message);
      } else {
        setAddLoad(false);
        toast.error(response.message);
      }
    } catch (error) {
      setAddLoad(false);
      toast.error(error.message);
     
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Admin Management" breadcrumbItem="Update Admin User" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="customername-field" className="form-label">
                            Admin Name
                          </label>
                          <Input
                            type="text"
                            id="customername-field"
                            className="form-control"
                            placeholder="Enter Admin Name"
                            name="adminName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.adminName || ""}
                            invalid={validation.touched.adminName && validation.errors.adminName}
                          />
                          {validation.touched.adminName && validation.errors.adminName && <FormFeedback type="invalid">{validation.errors.adminName}</FormFeedback>}
                        </div>
                        <div className="mb-3">
                        <label htmlFor="status-field" className="form-label"> Role </label>
                        <select
                          id="status-field"
                          name="role"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.role || ""}
                          className={`form-control ${validation.touched.role && validation.errors.role ? "is-invalid" : ""}`}
                        >
                          <option value="" disabled>
                            Select Role
                          </option>
                          <option value={1}>Super Admin</option>
                          <option value={2}>Sub Admin</option>
                        </select>
                        {validation.touched.role && validation.errors.role && <FormFeedback type="invalid"> {validation.errors.role} </FormFeedback>}
                      </div>
                      </div>
                          
                        

                      <div className="col-md-6">
                        <div className="mb-3">
                          <label htmlFor="customername-field" className="form-label">
                            Email
                          </label>
                          <Input
                            type="text"
                            id="customername-field"
                            className="form-control"
                            placeholder="Enter Email"
                            name="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={validation.touched.email && validation.errors.email}
                          />
                          {validation.touched.email && validation.errors.email && <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>}
                        </div>

                        <div className="mb-3">
                          <label htmlFor="customername-field" className="form-label">
                            Password
                          </label>
                          <Input
                            type="text"
                            id="customername-field"
                            className="form-control"
                            placeholder="Enter Password"
                            name="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password}
                            // disabled={true}
                            invalid={validation.touched.password && validation.errors.password}
                          />
                          {validation.touched.password && validation.errors.password && <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>}
                        </div>
                      
                      </div>
                    
                     
                    </div>

                   
                  {validation.values.role == 2 && 
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={getMenuList}
                      paginationServer
                      progressPending={loading}
                      persistTableHead={true}
                      progressComponent={<div className="py-3">Loading...</div>}
                      noDataComponent={<div className="py-3">No records found</div>}
                      fixedHeader={true}
                    />
                  </div>
                  }
                  <div className="hstack gap-2 justify-content-end">
                      <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={addLoad}>
                        {addLoad ? <Loader /> : "Submit"}
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UpdateAdminUser;

import DataTable from 'react-data-table-component';
import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form } from "reactstrap";
// import { useTable, usePagination } from 'react-table';
import { writeFile, utils } from 'xlsx';
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { handleNullValue, utcToNormalTime } from '../../helpers/function';
import Swal from "sweetalert2";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { AdminContext } from '../../helpers/adminContext';


const SiteIpWhiteList = () => {
    
    document.title = "Ultrapro | Site IP Management";

    const [loading, setLoading] = useState(false)
    const [deleteLoad,setDeleteLoad] = useState(false)

    const [recordToDelete, setRecordToDelete] = useState({});

    const [formData, setFormData] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // Number of items to display per page
    // const pageCount = Math.ceil(formData.length / itemsPerPage);

    //Permission List 
    const { adminInfo } = useContext(AdminContext);
    const [adminType, setAdminType] = useState('');

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    useEffect(() => {
        ipList();
        setAdminType(adminInfo?.adminType);
    }, [])
    const ipList = async () => {

        try {
            const response = await postData(Apiservices.siteWhiteIPList)
            if (response.status) {
                setFormData(response.data)


            } else {

            }

        } catch (error) {


        }





    }

// Regex pattern for IPv4 validation
const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/;
    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            ipAddress: '',
            // isActive:""
        },
        validationSchema: Yup.object({
            ipAddress: Yup.string()
            .matches(ipv4Regex, "Invalid IP address")
            .required("IP Address is required"),
            // isActive: Yup.string().required("Status is required"),
        }),
        onSubmit: (values) => {

            handlePost(values)


        },
    });

    const handlePost = async (data) => {
        setLoading(true)
        const postForm = {
            ipAddress: data.ipAddress,
            isActive:1
            // reason: data.reason,
        }
        try {
            const response = await postData(Apiservices.siteWhiteIPAdd,postForm)
            if (response.status) {
                ipList()
                setLoading(false)
                toast.success(response.message)
                setmodal_list(false)
                formik.resetForm();


            } else {
                setLoading(false)
                toast.error(response.message)
                formik.resetForm();
            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later")
            setLoading(false)


            formik.resetForm();
        }




    }


    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }



    const handleDelete = (record) => {
        setRecordToDelete(record);
        setmodal_delete(true)
    }

    const confirmDelete = async () => {
        const postForm={
            ipAddress:recordToDelete.ipAddress
        }
        setDeleteLoad(true)
        try {
            const response = await postData(Apiservices.siteWhiteIPDelete,postForm )
            if (response.status) {
                setDeleteLoad(false)
                toast.success(response.message)
                setmodal_delete(false)
                ipList()
            } else {
                toast.error(response.message)
                formik.resetForm();
                setmodal_delete(false)
                setDeleteLoad(false)
            }

        } catch (error) {
            setDeleteLoad(false)
            toast.error("Internal Server Error..Try Again Later")

            setmodal_delete(false)
            

            formik.resetForm();
        }

    }

 

    const [searchTerm, setSearchTerm] = useState("")

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // const filteredData = formData.filter(item => {
    //     const formattedDate = item.createdAt ? utcToNormalTime(item.createdAt).date : '';
    //     const isIPMatch = item.clientName && item.clientName.toLowerCase().includes(searchTerm.toLowerCase());
    //     const isReasonMatch = item.reason && item.reason.toLowerCase().includes(searchTerm.toLowerCase());
    //     const isDateMatch = formattedDate && formattedDate.includes(searchTerm);
    //     const isIDMatch = item._id && item._id.includes(searchTerm);
    //     return isIPMatch || isIDMatch || isReasonMatch || isDateMatch;
    // });
    const [dateRange, setDateRange] = useState([null, null]); 
    // Handle date range change
    const handleDateChange = (selectedDates) => {
     setDateRange(selectedDates);
 };
 
    const filteredData = formData.filter(item => {
        const itemDate = new Date(item.createdAt); 
        const [startDate, endDate] = dateRange;
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;
        if (end) {  
            end.setHours(23, 59, 59, 999);  
        }
        const isValidItemDate = !isNaN(itemDate.getTime());
        const isDateInRange = (!start || (isValidItemDate && itemDate >= start)) && (!end || (isValidItemDate && itemDate <= end));
        const formattedDate = item.createdAt ? utcToNormalTime(item.createdAt).date : '';
        const isIPMatch = item.IpAddress && item.IpAddress.toLowerCase().includes(searchTerm.toLowerCase());
        const isReasonMatch = item.reason && item.reason.toLowerCase().includes(searchTerm.toLowerCase());
        const isAdminEmailMatch = item.blockedAdminEmail && item.blockedAdminEmail.toLowerCase().includes(searchTerm.toLowerCase());
        const isDateMatch = formattedDate && formattedDate.includes(searchTerm);
        const isIDMatch = item._id && item._id.includes(searchTerm);

        const isSearchMatch = isIPMatch || isIDMatch || isReasonMatch || isDateMatch || isAdminEmailMatch;
        return isSearchMatch && isDateInRange; 
    });

    // const displayedData = filteredData.slice(
    //     currentPage * itemsPerPage,
    //     (currentPage + 1) * itemsPerPage
    // );

    const pageCount = Math.ceil(filteredData.length / itemsPerPage);
    const validCurrentPage = currentPage >= pageCount ? 0 : currentPage;

    // Slice the filtered data for pagination
    const displayedData = filteredData.slice(
        validCurrentPage * itemsPerPage,
        (validCurrentPage + 1) * itemsPerPage
    );
    const handleDownload = () => {
        // Convert the filteredData to a worksheet
        const ws = utils.json_to_sheet(formData.map(item => ({
            'ID': item._id,
            'IP Address': item.ipAddress,
            'Date': item.date,
            'Price': item.price,
            'Quantity': item.quantity,
            'Amount': item.quantity * item.price,
            'Status': item.status,
        })));

        // Create a new workbook and add the worksheet
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Deposite History');

        // Write the workbook to an Excel file
        writeFile(wb, 'ultrapro_user_deposit_history_data.xlsx');
    };
    const allowedActions = [1,2,3,4];

    const columns = [
        {
            name: 'S.No',
            selector:(row, index) => currentPage * itemsPerPage + index + 1,
            
        },
        // {
        //     name: 'White ID',
        //     selector: row =>row.adminWhiteId,
        //     sortable: true
        // },
        {
            name: 'IP Address',
            selector: row => row.ipAddress,
            sortable: true
        },
        {
            name: 'Added By',
            selector: row => row.addedBy,
            sortable: true
        },
    ...(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Site IP White List" && allowedActions.some(action => data.actions.includes(action))) ? [

        {
            name: "Status",
            selector: (row) => (
              <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
                {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Site IP White List" && data.actions.includes(3))) ? (
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizemd"
                  checked={row.isActive === 1}
                  // onChange={() => handleToggle(row, "sessionIsActive")}
                  // onChange={() =>handleToggleModel(row,Apiservices.UpdateSession)}
                  onChange={() => handleUpdateStatusClick(row)}
                />
    ): null }
              </div>
            ),
            sortable: true,
            maxWidth: "1%",
        }]: []),
        {
            name: 'Date',
            selector: row =>`${handleNullValue(utcToNormalTime(row.createdAt).date)} ${(utcToNormalTime(row.createdAt).time)}`,
            sortable: true
        },
    ...(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Site IP White List" && allowedActions.some(action => data.actions.includes(action))) ? [

        {
            name: 'Action',
            cell: row => (
                <div className="d-flex gap-2">
                    {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Site IP White List" && data.actions.includes(4))) ? (
                    <div className="remove">
                    {/* <i style={{ cursor: "pointer", marginLeft: "15px" }} className="mdi mdi-border-color cursor-pointer"
                          onClick={() => handleUpdate(row)}></i> */}
                         <i
                        style={{ cursor: "pointer", marginLeft: "15px" }}
                        className="mdi mdi-trash-can-outline"
                        // onClick={() => handleDelete(row)}
                        onClick={()=>handleDeleteClick(row)}
                        ></i>
                      
                    </div>
    ): null }
                </div>
            ),
            ignoreRowClick: true,
            // allowOverflow: true,
            // button: true,
        }]: []),
    ];
        const[getId,setId] = useState('')
    const handleUpdate = (record) => {
        setId(record.adminWhiteId);
        validationUpdate.setValues({
        //   sessionId: getId || "",
          ipAddress: record.ipAddress || "",
          isActive: record.isActive !== undefined ? String(record.isActive) : "",
        });
        setmodal_update_list(true);
    }
    
 //update Modal
 const [modal_update_list, setmodal_update_list] = useState(false);
 function tog_update_list() {
   setmodal_update_list(!modal_update_list);
 }


  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,
  
    initialValues: {
        ipAddress: '',
        isActive: "",
    },
    validationSchema: Yup.object({
        ipAddress: Yup.string()
        .matches(ipv4Regex, "Invalid IP address")
        .required("IP Address is required"),
        isActive: Yup.string().required("Status is required"),
    }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });
  const confirmUpdate = async (values) => {
    
    const postForm = {
        // adminWhiteId: getId,
        ipAddress: values.ipAddress,
        isActive: Number(values.isActive),
        adminType: adminInfo?.adminType,
        Permissions: adminInfo?.Permissions
    };
    try {
      setLoading(true);
      const response = await postData(`${Apiservices.siteWhiteIPUpdate}`, postForm);
  
      if (response.status === 200) {
        setmodal_update_list(false);
        toast.success(response.data.message);
        ipList();
        setLoading(false);
      } else {
        toast.error(response.data.message);
        ipList();
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.response.data.message);

      setLoading(false);
      toast.error("Internal Server Error..Try Again Later");
      // setmodal_delete(false)
      validationUpdate.resetForm();
    }
  };
//status update api call
const showStatusAlert = (onConfirm, row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to update status!",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!'
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm(row);
        // Swal.fire('Success !', 'The status has been updated successfully', 'success');
  
      }
    });
  };
  const handleUpdateStatusClick = (row) => {
    showStatusAlert(handleToggle1, row);
  };
  
  const handleToggle1 = async (rowDetails) => {
    const updatedStatus = rowDetails.isActive === 1 ? 0 : 1;
    try {
     const statusUpdate = {
        ipAddress: rowDetails.ipAddress,
        isActive: updatedStatus,
     }
      setLoading(true);
      const response = await postData(`${Apiservices.siteWhiteIPUpdate}`, statusUpdate);
     
  
      if (response.status) {
        toast.dismiss();
        setLoading(false);
        // toast.success(response.message);
        Swal.fire("Success !", "The status has been updated successfully", "success");
  
        ipList();
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };


     //delete api call
     const showDeleteAlert = (onConfirm,row) => {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to recover this once it's deleted!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            onConfirm(row);
            // Swal.fire(
            //   'Deleted !',
            //   'The requested record has been deleted',
            //   'success'
            // );
          }
        });
      };
     const handleDeleteClick = (row) => {
        showDeleteAlert(handleDelete1,row);
      };
    
       const handleDelete1 = async (rowDetails) => {
        const postForm={
            ipAddress:rowDetails.ipAddress
        }
        setDeleteLoad(true)
        try {
            const response = await postData(Apiservices.siteWhiteIPDelete,postForm )
            if (response.status) {
                setDeleteLoad(false)
                toast.success(response.message)
                setmodal_delete(false)
                ipList()
            } else {
                toast.error(response.message)
                formik.resetForm();
                setmodal_delete(false)
                setDeleteLoad(false)
            }

        } catch (error) {
            setDeleteLoad(false)
            toast.error("Internal Server Error..Try Again Later")

            setmodal_delete(false)
            

            formik.resetForm();
        }

      };






    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Settings" breadcrumbItem="Site IP White List" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">

                                                <form className="app-search d-block">
                                                    <div className="position-relative">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search..."
                                                            onChange={handleInputChange}
                                                        />
                                                        <span className="ri-search-line"></span>
                                                    </div>
                                                </form>

                                            </Col><Col className="col-sm-4">

                                                    <form className="d-block mt-3">
                                                        <div className="position-relative">
                                                        <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="Select Start to End Date"
                                                                options={{
                                                                    mode: 'range',
                                                                    altInput: true,
                                                                    altFormat: "F j, Y",
                                                                    dateFormat: "Y-m-d",
                                                                    maxDate: new Date(),
                                                                }}
                                                                value={dateRange}
                                                                onChange={handleDateChange}
                                                            />
                                                        </div>
                                                    </form>

                                                    </Col>

                                            <Col className="col-sm">

                                                <div className="d-flex justify-content-sm-end">
                                                {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Site IP White List" && data.actions.includes(1))) ? (
                                                    <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                                                ): null }

                                                    {/* <Button color="soft-danger"
                                                       
                                                    ><i style={{ fontSize: '15px' }} className="mdi mdi-download-circle-outline"></i>Download</Button> */}

                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                        <DataTable
                                                columns={columns}
                                                data={displayedData}
                                                paginationServer
                                                persistTableHead={true}
                                                paginationTotalRows={filteredData.length}
                                                // noDataComponent={<div>No records found</div>}
                                                fixedHeader
                                               
                                            />

                                            
                                           
                                        
                                        </div>
                                        {/* <AlternativePagination/> */}

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                {/* <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link> */}
                                                {displayedData.length > 0 ? (
                                                 



                                                        <div className="pagination pagination-rounded mb-0">
                                                            <ReactPaginate
                                                                previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                                nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageClick}
                                                                containerClassName={"pagination"}
                                                                activeClassName={"active"}
                                                                pageLinkClassName={"page-link"}
                                                                breakLinkClassName={"break-link"}
                                                                disabledClassName={"disabled"}
                                                                initialPage={currentPage}
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                                // disableInitialCallback={true}
                                                                renderLink={(props) => {
                                                                    if (
                                                                        props.page === "..." &&
                                                                        props.pageCount > props.pageRangeDisplayed
                                                                    ) {
                                                                        return (
                                                                            <a
                                                                                {...props}
                                                                                onClick={() =>
                                                                                    handlePageClick({
                                                                                        selected: pageCount - 1,
                                                                                    })
                                                                                }
                                                                            >
                                                                                {pageCount}
                                                                            </a>
                                                                        );
                                                                    }
                                                                    return <a {...props} />;
                                                                }}
                                                            />
                                                        </div>
                                                
                                                ):("")}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>





                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add IP  Address </ModalHeader>
                <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false
                    }}
                >
                    <ModalBody>


                        <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">IP Address</label>
                            <Input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter IP Address"
                                name="ipAddress"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.ipAddress || ""}
                                invalid={formik.touched.ipAddress && formik.errors.ipAddress}
                            />
                            {formik.touched.ipAddress && formik.errors.ipAddress && (
                                <FormFeedback type="invalid">{formik.errors.ipAddress}</FormFeedback>
                            )}
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                            {/* <button  type="submit" className="btn btn-success" id="add-btn">Add Customer</button> */}
                            <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <Loader/>
                                
                                ) : ("Submit")}

                            </button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={tog_delete} className="modal zoomIn" id="deleteRecordModal" centered>
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-3 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                onClick={confirmDelete}
                                disabled={deleteLoad}
                            >
                                {deleteLoad ? (
                                    <Loader/>
                                ) : ("Yes, Delete It!")}

                            </button>
                    </div>
                </ModalBody>
            </Modal>
       
            <Modal  isOpen={modal_update_list}  toggle={() => { tog_update_list();}} centered>
  <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>
    Update IP Address
  </ModalHeader>
  <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validationUpdate.handleSubmit();
                        return false
                    }}
                >
                    <ModalBody>


                        <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">IP Address</label>
                            <Input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter IP Address"
                                name="ipAddress"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={validationUpdate.values.ipAddress || ""}
                                invalid={validationUpdate.touched.ipAddress && validationUpdate.errors.ipAddress}
                            />
                            {validationUpdate.touched.ipAddress && validationUpdate.errors.ipAddress && (
                                <FormFeedback type="invalid">{validationUpdate.errors.ipAddress}</FormFeedback>
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="status-field" className="form-label"> Status </label>
                            <select
                            id="status-field"
                            //  className="form-control"
                            name="isActive"
                            onChange={validationUpdate.handleChange}
                            onBlur={validationUpdate.handleBlur}
                            value={validationUpdate.values.isActive || ""}
                            className={`form-control ${validationUpdate.touched.isActive && validationUpdate.errors.isActive ? "is-invalid" : ""}`}
                            >
                            <option value="" disabled>
                                Select Status
                            </option>
                            <option value={1}>Active</option>
                            <option value={0}>Deactive</option>
                            </select>
                            {validationUpdate.touched.isActive && validationUpdate.errors.isActive && <FormFeedback type="invalid"> {validationUpdate.errors.isActive} </FormFeedback>}
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                            {/* <button  type="submit" className="btn btn-success" id="add-btn">Add Customer</button> */}
                            <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <Loader/>
                                
                                ) : ("Submit")}

                            </button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </Form>
  
    </Modal>
        </React.Fragment>
    );
};

export default SiteIpWhiteList;

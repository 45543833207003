export const formatNumber = (value, decimal) => {
    if (isNaN(value) || !value) {
        return '0.0000';
    }
    const [integerPart, fractionalPart = ''] = value.toString().split('.');
    let formattedFractionalPart = fractionalPart.substring(0, decimal);
    while (formattedFractionalPart.length < decimal) {
        formattedFractionalPart += '0';
    }
    const formattedPrice = `${integerPart}.${formattedFractionalPart}`.replace(/\d(?=(\d{3})+\.)/g, '$&');
    return formattedPrice;
};
export const shortenEmail = (email, maxLength = 10) =>{
    try {
        const [localPart, domain] = email.split('@');
        if (localPart.length <= maxLength) {
            return email; 
        }
        const shortLocalPart = localPart.slice(0, maxLength - 3) + '...'; // Truncate and add ellipsis
        return `${shortLocalPart}@${domain}`;
    } catch (err){
        console.log(err);
        return false;
    }
};
export const formatAMPM = (date) =>{
    try {
        var data = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = data +"-"+ month +"-" + year +" " + hours + ':' + minutes + ' ' + ampm;
        return strTime;
    } catch (err){
        console.log(err);
        return false;
    }
}
export const formatMonthDateTime = (date) => {
    try {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        const day = date.getDate();
        const month = monthNames[date.getMonth()]; // Get month name
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM'; // Convert to uppercase

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        const strTime = `${month} ${day < 10 ? '0' + day : day}, ${year} ${hours}:${formattedMinutes} ${ampm}`;
        return strTime;
    } catch (err) {
        console.log(err);
        return false;
    }
}

import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, Col, Container, Row, Spinner, InputGroup, } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import { AdminContext } from "../../helpers/adminContext";


import { Link } from "react-router-dom";

const TaskHistory = () => {
    document.title = "Ultrapro | Withdraw Management";
    const today = new Date();
    // const kyc = location.state?.kyc

    const [dateRange, setDateRange] = useState([null, null]);
    const [loading, setLoading] = useState(false);
    const [downLoading, setDownLoading] = useState(false);
    const [downloadStartDate, setDownloadEndDate] = useState({});
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const [searchLoad, setSearchLoad] = useState(false);
    const [searchValid, setSearchValid] = useState(false);
    const [inputSearch, setinputSearch] = useState('');
    const [formData, setFormData] = useState([]);


    const [totalPagination, setTotalPagination] = useState(1);
    const [totalLimit, setTotalLimit] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = totalLimit; // Number of items to display per page
    const pageCount = Math.ceil(totalPagination);
    const [count, setCount] = useState("");


    useEffect(() => {
        ipList();
    }, []);

    const ipList = async () => {
        setLoading(true);
        try {
            
            const response = await postData(Apiservices.taskHistory, {});

            if (response.status === true) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);
            } else {
                toast.error(response.message);
                setLoading(false);
            }
        } catch (error) {
            toast.error("Internal Server Error...");
            setLoading(false);
        }
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        handlePagePost(selected);
    };

    const handlePagePost = async (selected) => {
        setLoading(true);
        const postForm = {
            page: selected + 1,
        };
        if (dateRange[0] && dateRange[1]) {
            postForm.startDate = formatDate(dateRange[0]);
            postForm.endDate = formatDate(dateRange[1]);
        }
        if (inputSearch) {
            postForm.search = inputSearch;
        }
        const queryString = new URLSearchParams(postForm).toString();
        try {
            const response = await postData(Apiservices.taskHistory, postForm);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);
            } else {
                setLoading(false);
                toast.error(response.message);
            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };


    // const allowedActions = [1, 2, 3, 4];

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true,
            width: '80px'  // Set equal width for all columns
        },
        {
            name: 'User Name',
            selector: row => handleNullValue(row?.userName),
            sortable: true,
            width: '180px'  // Equal width
        },
        // {
        //     name: 'Is Completed',
        //     selector: row => row.isCompleted === 1 ? " In Progress" : "Completed",  // Display based on isActive value
        //     sortable: true,
        //     minWidth: '150px'
        // },
        {
            name: 'User Email',
            selector: row =>
                <div className="d-flex align-items-center">
      <Link className="link-tag" to={`/allUsers/userDetails/${row.userId}` } target='_blank'>
        <span className="mx-1 text-decoration-underline">
          {row?.userEmail || '-'}
        </span>
      </Link>
    </div>,
            sortable: true,
            width: '320px'  // Equal width
        },
        {
            name: 'Status',
            selector: row => row.isCompleted === 1 ? "In Progress" : "Completed",
            sortable: true,
            width: '180px'  // Equal width
        },
        {
            name: "Start Date",
            selector: (row) =>
                row.createdAt
                    ? `${utcToNormalTime(row.createdAt).date} ${utcToNormalTime(row.createdAt).time}`
                    : "---",
            sortable: true,
            width: '200px'  
        },
        {
            name: "End Date",
            selector: (row) =>
                row.updatedAt
                    ? `${utcToNormalTime(row.updatedAt).date} ${utcToNormalTime(row.updatedAt).time}`
                    : "---",
            sortable: true,
            width: '200px' 
        }
    ];
    
    
    const handleSearchChanage = (e) => {
        const value = e.target.value
        if (value == '') {
            ipList()
            setSearchValid(false)
        }
        setinputSearch(value)
    }

    const handleSearch = async () => {
        if (inputSearch !== '') {
            setSearchLoad(true);
            const postForm = {
                search: inputSearch,
                // page: currentPage + 1,
                // limit: itemsPerPage,
            };

            const queryString = new URLSearchParams(postForm).toString();
            try {
                const response = await postData(Apiservices.taskHistory, postForm);

                if (response.status) {
                    setSearchLoad(false);
                    setFormData(response.data.data);
                    setTotalPagination(response.data.totalPages);
                    setTotalLimit(response.data.limit)
                    setCount(response.data.currentCount);
                } else {
                    setSearchLoad(false);
                }
            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
                setSearchLoad(false);
            }
        } else {
            setSearchValid(true)
        }
    }




    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleDateChange = ([start, end]) => {
        const formattedStart = start ? formatDate(start) : null;
        const formattedEnd = end ? formatDate(end) : null;
        setDateRange([start, end]);
        if (!formattedStart && !formattedEnd) {
            ipList();
            setDownloadEndDate({})
        }

        if (formattedStart && formattedEnd) {
            setDownloadEndDate({ startDate: formattedStart, endDate: formattedEnd })
            handleDate({ startDate: formattedStart, endDate: formattedEnd });
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    const handleDate = async ({ startDate, endDate }) => {
        setLoading(true);
        const postForm = {
            startDate: startDate,
            endDate: endDate,
        }

        try {
            const queryString = new URLSearchParams(postForm).toString();

            const response = await postData(Apiservices.taskHistory, postForm);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);


            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };

    const handleDownload = async (type) => {

        setDownLoading(true)
        try {
            const postForm = {};
            if (downloadStartDate.startDate) {
                postForm.startDate = downloadStartDate.startDate;
            }
            if (downloadStartDate.endDate) {
                postForm.endDate = downloadStartDate.endDate;
            }
            if (inputSearch) {
                postForm.search = inputSearch;
            }

            const queryString = new URLSearchParams(postForm).toString();

            const response = await axiosInstance.post(Apiservices.taskHistory, postForm, {
                // Include any data you need to send with the POST request here
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: 'blob'
            });
            if (response.data) {
                setDownLoading(false)
                const blob = response.data;

                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                // link.setAttribute('download', `${user}.xlsx`);
                link.setAttribute('download', `Customer Video History.${type}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                toast.error(response.data.message)
                setDownLoading(false)

            }
        } catch (err) {
            setDownLoading(false)
            console.error('Error downloading the file', err);
        }
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Video Task" breadcrumbItem="Task History" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col sm={4} lg={3}>
                                            <div className="input-group">
                                                <Input
                                                    // onChange={handleInputChange}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name,Email,Address,ID ..."
                                                    aria-label="Recipient's username"
                                                    onChange={handleSearchChanage}
                                                    invalid={searchValid}
                                                    onKeyPress={handleKeyPress}
                                                />
                                                <div className="input-group-append">

                                                    <button className="btn btn-primary" type="submit"
                                                        onClick={() => handleSearch()} disabled={searchLoad}
                                                    >
                                                        {searchLoad ? (

                                                            <Spinner className="text-light" size="sm" />
                                                        ) : (

                                                            <i className="ri-search-line" />
                                                        )}

                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={4} lg={3}>
                                            <div className="form-group mb-4">

                                                <div>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="Select Start to End Date"
                                                            options={{
                                                                mode: 'range',
                                                                altInput: true,
                                                                altFormat: "F j, Y",
                                                                dateFormat: "Y-m-d",
                                                                maxDate: today,
                                                            }}
                                                            value={dateRange}
                                                            onChange={handleDateChange}
                                                        />
                                                    </InputGroup>
                                                </div>

                                            </div>
                                        </Col>

                                        <Col className="col-sm">

                                            <div className="d-flex justify-content-sm-end">


                                                <Button
                                                    color="info"
                                                    className="add-btn mx-2"
                                                    onClick={() => handleDownload('xlsx')}
                                                    id="create-btn"
                                                    disabled={downLoading}
                                                >
                                                    <i className="mdi mdi-download-outline"></i>

                                                    {downLoading ? <Loader /> : "Download"}
                                                </Button>


                                            </div>
                                        </Col>
                                    </Row>


                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={formData}
                                            paginationServer
                                            paginationTotalRows={formData?.length}
                                            progressPending={loading}
                                            persistTableHead={true}
                                            progressComponent={<div className="py-3">Loading...</div>}
                                            // noDataComponent={formData.length===0}
                                            noDataComponent={<div className="py-3">No records found</div>}
                                            fixedHeader={true}

                                        />
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 col-md-6">
                                            <div className="d-flex justify-content-start mt-3">
                                                <span>Current Count : {count}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {loading || formData?.length == 0 ? (
                                                ""

                                            ) : (

                                                <div className="d-flex justify-content-end mt-3">
                                                    <div className="pagination pagination-rounded mb-0">
                                                        <ReactPaginate
                                                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={pageCount}
                                                            marginPagesDisplayed={1}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={handlePageClick}
                                                            containerClassName={"pagination"}   
                                                            activeClassName={"active"}
                                                            pageLinkClassName={"page-link"}
                                                            breakLinkClassName={"break-link"}
                                                            disabledClassName={"disabled"}
                                                            initialPage={currentPage}
                                                            disableInitialCallback={true}
                                                            forcePage={currentPage}
                                                            // disableInitialCallback={true}
                                                            renderLink={(props) => {
                                                                if (
                                                                    props.page === "..." &&
                                                                    props.pageCount > props.pageRangeDisplayed
                                                                ) {
                                                                    return (
                                                                        <a
                                                                            {...props}
                                                                            onClick={() =>
                                                                                handlePageClick({
                                                                                    selected: pageCount - 1,
                                                                                })
                                                                            }
                                                                        >
                                                                            {pageCount}
                                                                        </a>
                                                                    );
                                                                }
                                                                return <a {...props} />;
                                                            }}
                                                        />
                                                    </div>


                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* <Modal size="l" className='modal-dialog modal-lg' isOpen={showModel} centered backdrop="static" >

                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
                        Customer Task Video History View
                    </h5>
                    <button
                        onClick={() => { setShowModel(false); }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body custom-modal-body">
                    <div className="table-responsive">
                        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td><b>User Name</b></td>
                                    <td>{showDetails?.userName || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>User Id</b></td>
                                    <td>{showDetails?.userId || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Video Status</b></td>
                                    <td>{showDetails.isCompleted == 1 ? 'InProgess' : 'completed' || '-'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal> */}
        </React.Fragment>
    );
};

export default TaskHistory;

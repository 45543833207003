import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Input,
  Card,
  CardBody,
  FormGroup,
  Label,
  Col,
  Container,
  Modal,
  Row,
  ModalHeader,
  Form,
  Spinner,
  InputGroup,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData, postGetFile } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import {
  AddressCell,
  AddressCellFull,
  handleNullValue,
  utcToNormalTime,
} from "../../helpers/function";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import country from "../../helpers/CountryCodes.json";
import { formatNumber } from "../../helpers/common";
import Swal from "sweetalert2";
import { AdminContext } from "../../helpers/adminContext";
import { mdiConsoleNetwork } from "@mdi/js";
const UserHistoryFlexibleStack = () => {
  document.title = "Ultrapro | Staking Offers";
  const location = useLocation();
  const { adminInfo } = useContext(AdminContext);
  const [selectedCountry, setSelectedCountry] = useState();
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [downLoading, setDownLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startingDate, setStartingDate] = useState('');
  const [endingDate, setEndingDate] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [downloadStartDate, setDownloadEndDate] = useState({});
  const userApi = location.state?.user;
  const [user, setUser] = useState(userApi);
  // const [selectedItem, setSelectedItem] = useState("All Users");
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedType, setSelectedType] = useState('')
  const [GetDetails, setDetails] = useState('')

  const [adminType, setAdminType] = useState('');
  const [stakeFilter,setStakeFilter] = useState('');
 

  const today = new Date();
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
  }


  //Pagination;
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);
  const [count, setCount] = useState("");

  const [DateValue, setDateValue] = useState({
    startDate: "",
    endDate: ""
  });
  //const currentData = templateList.slice(offset, offset + rowsPerPage);

  //LIST API CALL
  useEffect(() => {
    fetchData();
    setAdminType(adminInfo?.adminType)
  }, []);

  useEffect(() => {
    if (user === undefined) {
      setUser(Apiservices.UserListFlexibleStack);
    }
  }, [user]);


  const fetchData = async () => {
    // const postData = {};
    setDateValue({
      startDate: "",
      endDate: ""
    })
    try {
      const response = await postData(Apiservices.UserListFlexibleStack, {});
      if (response) {
        setTotalPagination(response?.data?.totalPages);
        setTotalLimit(response?.data?.itemsPerPage);
        setCount(response?.data?.countFrom);
        setRecord(response?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleNav = async (record) => {
    setmodal_update_list(true);
    setDetails(record)
  };
  

  const showBlockConfirmation = (record) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to ${record.isBlock === 1 ? 'Block' : 'Unblock'} this user?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      preConfirm: () => {
        // Call the handleBlockUpdate function if confirmed
        return handleBlockUpdate(record);
      }
    });
  };

  const handleBlockUpdate = async (record) => {
   
      const postForm = {
        id: record._id,
        isBlock: record.isBlock === 1 ? 0 : 1,
      };
  
      try {
        const response = await postData(Apiservices.UserBlockUpdate, postForm);
        if (response.status === true) {
          toast.success(response.message);
          fetchData();
        } else {
          setLoading(false);
          toast.error(response.message || 'Failed to update status.');
        }
      } catch (error) {
        console.error("Error updating status:", error);
        toast.error("An error occurred while updating the status.");
      }
     
  };

  const allowedActions = [1, 2, 3, 4];
  const columns = [
    {
      name: "S.No",
      // selector: (row, index) => currentPage * itemsPerPage + index + 1,
      selector: (row, index) => currentPage * totalLimit + index + 1,
      sortable: true,
    },
    {
      name: "Plan Name",
      selector: (row) => handleNullValue(row?.planName),
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'Name',
      selector: row => 
          <div className="d-flex align-items-center" >
              {row.investorFullName ? 
      <Link className="link-tag" to={`/allUsers/userDetails/${row.userId}`} target='_blank'>
        <span className="mx-1 text-decoration-underline">
          {row.investorFullName || '-'}
        </span>
      </Link> : <span>-</span>}
    </div>,
      sortable: true,
      minWidth:'200px'
  },
  
    
    {
      name: 'Email',
      selector: row => 
          <div className="d-flex align-items-center" >
              {row.investorEmail ? 
      <Link className="link-tag" to={`/allUsers/userDetails/${row.userId}`} target='_blank'>
        <span className="mx-1 text-decoration-underline">
          {row.investorEmail || '-'}
        </span>
      </Link> : <span>-</span>}
    </div>,
      sortable: true,
      minWidth:'300px'
  },

    // {
    //   name: "OS Type",
    //   selector: (row) => (row?.osType == 0 ? 'Web' : 'Android' || '-'),
    //   sortable: true,
    //   minWidth: '150px',
    // },
    {
      name: "Coin",
      selector: (row) => handleNullValue(row?.currency),
      sortable: true,
      maxWidth: '100px',
    },
    {
      name: "Transaction Id",
      selector: (row) => <AddressCell address={row?.transactionId} />,
      sortable: true,
      minWidth: '200px',
    },
    {
      name: "Stake Amount",
      selector: (row) => handleNullValue(formatNumber(row?.amount, 4)),
      sortable: true,
      minWidth: '150px',

    },
    {
      name: "Country",
      selector: (row) => handleNullValue(row?.country),
      sortable: true,
      maxWidth: '150px',
    },
    {
      name: "USDT Value",
      selector: (row) => handleNullValue(formatNumber(row?.usdtValue, 5)),
      sortable: true,
      minWidth: '200px',
    },

    // {
    //   name: "Last Claimed Date",
    //   selector: (row) => handleNullValue(row?.lastClaimDate),
    //   sortable: true,
    //   minWidth: '200px',

    // },
    {
      name: "total Duration",
      selector: (row) => handleNullValue(row?.totalDuration),
      sortable: true,
      minWidth: '150px',

    },
    {
      name: "total Earnings",
      selector: (row) => handleNullValue(formatNumber(row?.totalEarnings, 4)),
      sortable: true,
      minWidth: '150px',

    },
    // {
    //   name: "Ending Date",
    //   selector: (row) => handleNullValue(row.stakingEnd),
    //   sortable: true,

    // },
    {
      name: "voucher Code",
      selector: (row) =>
        <div>
          {row.couponCode ? 
          <Link className="link-tag" to={`/allUsers/userDetails/${row.voucherOwnerId}`} target='_blank'>
            <span className="mx-1 text-decoration-underline">
              {row.couponCode || '-'}
            </span>
          </Link> : <span>-</span>}
        </div>,
      sortable: true,
      minWidth: '150px',
    },



    {
      name: "Start Date",
      selector: (row) =>
        row.createdAt
          ? `${utcToNormalTime(row.createdAt).date} ${utcToNormalTime(row.createdAt).time
          }`
          : "---",
      sortable: true,
      minWidth: '200px',
    },
    {
      name: "End Date",
      selector: (row) =>
        row.createdAt
          ? `${utcToNormalTime(row.endDate).date} ${utcToNormalTime(row.endDate).time
          }`
          : "---",
      sortable: true,
      minWidth: '200px',
    },
    {
      name: "Lock Period",
      selector: (row) =>
        row.createdAt
          ? `${utcToNormalTime(row.initialLockPeriod).date} ${utcToNormalTime(row.initialLockPeriod).time
          }`
          : "---",
      sortable: true,
      minWidth: '200px',
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <i
            style={{ fontSize: '15px', cursor: 'pointer', marginLeft: "15px" }}
            onClick={() => handleNav(row)}
            className="mdi mdi-eye-outline mx-1"
          ></i>
        </div>
      ),
      ignoreRowClick: true,
      maxWidth: '80px',
    },
    ...(adminType === 1 || adminInfo?.permissions.some((data) => data.module === "User Stake History (Flexible Stake)" && allowedActions.some((action) => data.actions.includes(action)))
    ? [ {
          name: "Block/UnBlock",
          cell: (row) => {
            if (row.isBlock !== undefined && row.isBlock !== null) {
              return (
                <div className="d-flex align-items-center">
                  <button
                    style={{ fontSize: '15px', cursor: 'pointer', marginLeft: "15px" }}
                    onClick={() => showBlockConfirmation(row)}
                    className={`btn btn-${row.isBlock === 1 ? 'danger' : 'success'} mx-1`}
                  >
                    {row.isBlock === 1 ? 'Block' : 'Unblock'}
                  </button>
                </div>
              );
            }
            return null; // Do not render anything if row.isBlock is not defined or null
          },
          ignoreRowClick: true,
          minWidth: '200px',
    
        }
      ]
      : []),
  ];


  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,

    initialValues: {
      stakename: "",
      duration: "",
      durationcount: "",
      minstakeamount: "",
      maxstakeamount: "",
      status: "",
      description: "",
      currencyname: "",
      stakeBonus: "",
      referalBonus: ""
    },
    validationSchema: Yup.object({
      stakename: Yup.string().trim().required("Stake Name is Required"),
      duration: Yup.string().required("Duration is Required"),
      // durationcount: Yup.string().required("Duration count is Required"),
      minstakeamount: Yup.number()
        .required("Minimum Stake Amount is Required")
        .typeError("Minimum Stake Amount must be a number")
        .lessThan(
          Yup.ref("maxstakeamount"),
          "Minimum Stake Amount must be less than Maximum Stake Amount"
        ),
      maxstakeamount: Yup.number()
        .required("Maximum Stake Amount is Required")
        .typeError("Maximum Stake Amount must be a number")
        .moreThan(
          Yup.ref("minstakeamount"),
          "Maximum Stake Amount must be greater than Minimum Stake Amount"
        ),
      status: Yup.string().required("Status is Required"),
      description: Yup.string().trim().required("Description is Required"),
      currencyname: Yup.string().required("Currency Name is Required"),
      stakeBonus: Yup.number()
        .required("Stake Bonus is Required")
        .typeError("Stake Bonus must be a number")
        .min(0, "Stake Bonus must be a non-negative number"),
      referalBonus: Yup.number()
        .required("Referral Bonus is Required")
        .typeError("Referral Bonus must be a number")
        .min(0, "Referral Bonus must be a non-negative number"),

    }),
  });



  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    // handlePagePost(selected );
    fetchPaginationList({ search: searchTerm, page: selected, limit: rowsPerPage, startDate: DateValue.startDate, endDate: DateValue.endDate, filterType: selectedItem, stakeType: stakeFilter,isBlock:selectedBlock });
    // handlePagePost(selected, { startDate: downloadStartDate.startDate, endDate: downloadStartDate.endDate });
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    let perPage = parseInt(newRowsPerPage);
    setRowsPerPage(perPage);
    setCurrentPage(0);
    fetchPaginationList({ search: searchTerm, page: 0, limit: perPage, startDate: DateValue.startDate, filterType: selectedItem, endDate: DateValue.endDate,stakeType: stakeFilter,isBlock:selectedBlock });
    // HandleClickRowperpage(newRowsPerPage)
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  //   const handleDateChange = ([start, end]) => {
  //     const formattedStart = start ? formatDate(start) : null;
  //     const formattedEnd = end ? formatDate(end) : null;
  //     setDateRange([start, end]);
  //     setStartingDate(formattedStart);
  //     setEndingDate(formattedEnd);
  // };

  const handleFilter = async (apiName) => {
    setLoading(true);
    try {
      const response = await postData(apiName);
      if (response.status) {
        setLoading(false);
        setRecord(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.itemsPerPage);
        setCount(response.data.countFrom);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleDateChange = ([start, end]) => {
    const formattedStart = start ? formatDate(start) : null;
    const formattedEnd = end ? formatDate(end) : null;

    setDateRange([start, end]);

    if (!formattedStart && !formattedEnd) {
      handleFilter(user);
      setDownloadEndDate({});
      setDateValue({
        startDate: "",
        endDate: ""
      });
    }

    if (formattedStart && formattedEnd) {
      setDownloadEndDate({
        startDate: formattedStart,
        endDate: formattedEnd,
      });
      setDateValue({
        startDate: formattedStart,
        endDate: formattedEnd
      });
      fetchPaginationList({ search: searchTerm, page: 0, limit: rowsPerPage, startDate: formattedStart, endDate: formattedEnd, filterType: selectedItem, stakeType: stakeFilter,isBlock:selectedBlock });
      // return true;
      // handlePagePost(currentPage, { startDate: formattedStart, endDate: formattedEnd });
    }
  };

  const handleFilter1 = async (apiName, value, type) => {
    const postForm = {
      filterType: value
    }
    if (type === 'country') {
      postForm.country = value
      postForm.search = searchTerm || ''
    }

    setLoading(true);
    try {
      const response = await postData(apiName, postForm);
      if (response.status) {
        setLoading(false);
        setRecord(response?.data?.data);
        setTotalPagination(response?.data?.totalPages);
        setTotalLimit(response?.data?.itemsPerPage);
        setCount(response?.data?.countFrom);
        setSelectedType(value)
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleSearch = () => {
    // handlePagePost(currentPage, searchTerm); // Perform the search using currentPage and searchTerm

    fetchPaginationList({ search: searchTerm, page: currentPage, limit: rowsPerPage, startDate: DateValue.startDate, endDate: DateValue.endDate, filterType: selectedItem,stakeType: stakeFilter,isBlock:selectedBlock })
  };
  // const handleSearchChange = (event) => {
  //   const value = event.target.value.replace(/\s+/g, '');
  //   if (value) setSearchTerm(value);
  //   else setSearchTerm("");

  // };

  
  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      fetchData();
      setSearchValid(false);
    } 
    
    setSearchTerm(value);
  };


  const handleMonthChange = (monthValue) => {
    setSelectedMonth(monthValue);
    handlePagePost(currentPage); // Ensure currentPage is defined in your component
  };

  const HandleClickRowperpage = async (newRowsPerPage) => {
    setLoading(true);
    setRowsPerPage(newRowsPerPage);
    handlePagePost();
  };

  const handlePagePost = async (selected, { startDate = null, endDate = null } = {}) => {
    const postForm = {
      page: selected + 1,
      limit: rowsPerPage,
      search: searchTerm,
      month: selectedMonth,
      startDate: startDate,
      endDate: endDate,
      filterType: selectedType,
      country: selectedType,
      isBlock: selectedBlock,
      stakeType: stakeFilter
    };
    try {
      setLoading(true);
      const response = await postData(Apiservices.UserListFlexibleStack, postForm);
      if (response) {
        setTotalPagination(response?.data?.totalPages);
        setTotalLimit(response?.data?.itemsPerPage);
        setCount(response?.data?.countFrom);
        setRecord(response?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }



  const handleSelect = (value, type) => {
    if (type === 'country') {
      setSelectedCountry(value);
      // handleFilter1(Apiservices.UserListFlexibleStack, value, type);
      fetchPaginationList({ page: 0, search: value, startDate: DateValue.startDate, endDate: DateValue.endDate, filterType: selectedItem,stakeType: stakeFilter,isBlock:selectedBlock })
    }
    setSearchTerm("");
  };

  // const handleDownload = async (format) => {
  //   setDownLoading(true);
  //   try {
  //     const postForm = {
  //       startDate: startingDate,
  //       endDate: endingDate,
  //       status: "",
  //       search: searchTerm || "",
  //       filterType: selectedType
  //     };
  //     if (downloadStartDate.startDate) {
  //       postForm.startDate = downloadStartDate.startDate;
  //     }
  //     if (downloadStartDate.endDate) {
  //       postForm.endDate = downloadStartDate.endDate;
  //     }

  //     const response = await postGetFile(Apiservices.UserListFlexibleStack, postForm, {
  //       responseType: 'blob'
  //     });
  //     const blob = response.data;
  //     const url = window.URL.createObjectURL(new Blob([blob]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", `UsersFlexibleStaking.${format}`);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (err) {
  //     console.error("Error downloading the file", err);
  //     toast.error("Error downloading the file");
  //   } finally {
  //     setDownLoading(false);
  //   }
  // };

  const handleDownload = async (format) => {
    setDownLoading(true);
    try {
      const postForm = {
        startDate: DateValue.startDate,
        endDate: DateValue.endDate,
        status: "",
        search: searchTerm || "",
        filterType: selectedType,
        download: true
      };

      const response = await postGetFile(Apiservices.UserListFlexibleStack, postForm, {
        responseType: 'blob'
      });
      const blob = response.data;
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `UsersFlexibleStaking.${format}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error("Error downloading the file", err);
      toast.error("Error downloading the file");
    } finally {
      setDownLoading(false);
    }
  };
  // const handleDropdownClick = async (item) => {
  //   setDownloadEndDate({});
  //   setSelectedItem(item);
  //   setDateRange([null, null]);

  //   try {
  //     let response;
  //     switch (item) {
  //       case "all":
  //         response = await handleFilter1(Apiservices.UserListFlexibleStack);
  //         break;
  //       case "today":
  //         response = await handleFilter1(Apiservices.UserListFlexibleStack, item);
  //         break;
  //       case "lastWeek":
  //         response = await handleFilter1(Apiservices.UserListFlexibleStack, item);
  //         break;
  //       case "lastMonth":
  //         response = await handleFilter1(Apiservices.UserListFlexibleStack, item);
  //         break;
  //       default:
  //         response = null;
  //         break;
  //     }

  //     if (response) {
  //       setUser(response);
  //     } else {
  //       console.error("No data fetched for the selected item.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handleDropdownClick = async (item) => {
    setSelectedItem(item);
    setDateRange([null, null]);
    let response = "";
    try {
      switch (item) {
        case "all":
          response = ""
          break;
        case "today":
          response = item
          break;
        case "lastWeek":
          response = item
          break;
        case "lastMonth":
          response = item
          break;
        default:
          response = "";
          break;
      }
      // setDateValue({
      //   startDate: "",
      //   endDate: ""
      // })
      fetchPaginationList({ search: searchTerm, page: 0, country: selectedCountry, startDate: DateValue.startDate, endDate: DateValue.endDate, filterType: response,stakeType: stakeFilter,isBlock:selectedBlock })
    } catch (error) {
      console.error("Error fetching data:", error);
      
    }
  };
  const fetchPaginationList = async (params) => {
    try {
      const postForm = {
        status: params.status,
        page: params.page + 1,
        search: params.search || '',
        startDate: params.startDate || "",
        endDate: params.endDate || "",
        limit: params.limit || 10,
        filterType: params.filterType || "",
        isBlock: params.isBlock,
        stakeType: params.stakeType
      }
      setLoading(true);
      const response = await postData(Apiservices.UserListFlexibleStack, postForm);
      if (response && response.status) {
    console.log("response?.data===========", response?.data);

        setTotalPagination(response?.data?.totalPages);
        setTotalLimit(response?.data?.itemsPerPage);
        setCount(response?.data?.countFrom);
        setRecord(response?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }
  const handleBlockFilter = async (value) => {
    setSelectedBlock(value);
    
    
    // Make sure to use the correct value for `isBlock` in the fetchPaginationList
    fetchPaginationList({
      page: 0,
      search: searchTerm,
      startDate: DateValue.startDate,
      endDate: DateValue.endDate,
      filterType: selectedItem,
      isBlock: value,
      stakeType: stakeFilter, 
    });
  };
  const handleStakeFilter = async(value)=>{
    setStakeFilter(value);
    
    
    // Make sure to use the correct value for `isBlock` in the fetchPaginationList
    fetchPaginationList({
      page: 0,
      search: searchTerm,
      startDate: DateValue.startDate,
      endDate: DateValue.endDate,
      filterType: selectedItem,
      isBlock: selectedBlock,
      stakeType: value
    });
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="User Flexible Stakes" breadcrumbItem="Users Flexible Stake History" />
          <Row>
            <Col lg={12} className="row">
              <Card className="col-lg-12">
                <CardBody>
                  <Row className="mb-3">
                    {/* First row with three fields */}
                    <Col className="col-sm-3">
                      <div className="input-group">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Search by Name, Email couponcode"
                          aria-label="Search"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                            {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                          </button>
                        </div>
                      </div>
                    </Col>

                    <Col sm={4} lg={3}>
                      <div className="form-group mb-4">
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Select Start to End Date"
                            options={{
                              mode: 'range',
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                              maxDate: today,
                            }}
                            value={dateRange}
                            onChange={handleDateChange}
                          />
                        </InputGroup>
                      </div>
                    </Col>

                    <Col className="col-3">
                      <select
                        onChange={(e) => handleSelect(e.target.value, 'country')}
                        className="form-select"
                        id="autoSizingSelect"
                        value={selectedCountry || 'Select Country'}
                      >
                        <option value={selectedCountry || 'Select Country'} disabled>
                          {selectedCountry || 'Select Country'}
                        </option>
                        <option value=''>All</option>
                        {country.map((data) => (
                          <option key={data.name} value={data.name}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col className="col-sm-3">
                      <Button
                        color="info"
                        className="add-btn"
                        onClick={() => handleDownload('xlsx')}
                        id="create-btn"
                        disabled={downLoading}
                      >
                        <i className="mdi mdi-download-outline"></i>
                        {downLoading ? <Loader /> : "Download"}
                      </Button>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    {/* Second row with three fields */}
                    <Col className="col-sm-3 mx-1">
                      <select
                        className="form-select"
                        value={selectedItem}
                        onChange={(e) => handleDropdownClick(e.target.value)}
                      >
                        <option value="All" disabled>Select</option>
                        <option value="all">All</option>
                        <option value="today">Today</option>
                        <option value="lastWeek">Last Week</option>
                        <option value="lastMonth">Last Month</option>
                      </select>
                    </Col>
                    <Col className="col-sm-3 mx-1">
                        <select
                          className="form-select"
                          value={selectedBlock}
                          onChange={(e) => handleBlockFilter(e.target.value)}
                        >
                          {/* <option value="" disabled>Select</option> */}
                          <option value="">All STATUS</option>
                          <option value={0}>Block</option>
                          <option value={1}>UnBlock</option>
                        </select>
                      </Col>
                      <Col className="col-sm-3 mx-1">
                        <select
                          className="form-select"
                          value={stakeFilter}
                          onChange={(e) => handleStakeFilter(e.target.value)}
                        >
                          {/* <option value="" disabled>Select</option> */}
                          <option value="">All STAKE</option>
                          <option value={1}>Flexible</option>
                          <option value={2}>Minimal</option>
                        </select>
                      </Col>
                    {/* <Col className="col-sm-3 align-items-end">
  <select
    className="form-select"
    value={selectedMonth}  
    onChange={(e) => handleMonthChange(Number(e.target.value))} 
  >
    <option value="" disabled>Select Month</option>  
    {Array.from({ length: 12 }, (_, i) => (
      <option key={i + 1} value={i + 1}>
        {new Date(0, i).toLocaleString('default', { month: 'long' })}
      </option>
    ))}
  </select>
</Col> */}



                  </Row>

                  <div className="table-responsive overflow-hidden">
                    <DataTable
                      columns={columns}
                      data={record || []}
                      paginationServer
                      paginationTotalRows={record ? record.length : 0}
                      progressPending={loading}
                      progressComponent={<div>Loading...</div>}
                      persistTableHead={true}
                      noDataComponent={
                        <div className="mt-2">No records found</div>
                      }
                      fixedHeader={true}
                    />
                    <div className="container">
                      <div className="row justify-content-end mt-4">
                        <div className="col-12 d-flex flex-column flex-md-row justify-content-end align-items-center">
                          <div className="d-flex align-items-center me-3">
                            <span className="me-2">Rows per page:</span>
                            <select
                              value={rowsPerPage}
                              onChange={(e) => handleRowsPerPageChange(e.target.value)}
                              className="form-control-sm"
                            >
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={30}>30</option>
                              <option value={50}>50</option>
                            </select>
                          </div>
                          <div className="me-md-3 mb-3 mb-md-0">
                            <span>{count}</span>
                          </div>
                          <div className="pagination pagination-rounded mt-3">
                            <ReactPaginate
                              previousLabel={<i className="mdi mdi-chevron-left"></i>}
                              nextLabel={<i className="mdi mdi-chevron-right"></i>}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              breakLinkClassName={"break-link"}
                              disabledClassName={"disabled"}
                              initialPage={currentPage}
                              disableInitialCallback={true}
                              forcePage={currentPage}
                              renderLink={(props) => {
                                if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                  return (
                                    <a
                                      {...props}
                                      onClick={() =>
                                        handlePageClick({
                                          selected: pageCount - 1,
                                        })
                                      }
                                    >
                                      {pageCount}
                                    </a>
                                  );
                                }
                                return <a {...props} />;
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <Modal isOpen={modal_update_list} className="modal-lg" toggle={() => { tog_update_list(); }} centered>
                      <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }} >
                        User Staking Details
                      </ModalHeader> */}
                    {/* UpdateModal */}


                    <Modal className='modal-dialog modal-lg' size="l" isOpen={modal_update_list} centered backdrop="static">
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                          User Flexible Staking Details
                        </h5>
                        <button
                          onClick={() => {
                            setmodal_update_list(!modal_update_list);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div className="modal-body custom-modal-body">
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                            <tbody>
                              <tr>
                                <td>Plan Name</td>
                                <td>{GetDetails?.planName || "-"}</td>
                              </tr>
                              <tr>
                                <td>Stake User Name</td>
                                <td>{GetDetails?.investorFullName || "-"}</td>
                              </tr>

                              <tr>
                                <td>Stake User Email</td>
                                <td>
                                  {GetDetails?.investorEmail || "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>Coin</td>
                                <td>
                                  {GetDetails?.currency || "-"}
                                </td>
                              </tr>

                              <tr>
                                <td>Stake UPRO Amount</td>
                                <td>
                                {/* {GetDetails?.amount || "-"} */}

                                  {GetDetails?.amount ? formatNumber(GetDetails?.amount || "-", 6) : "-"}

                                </td>
                              </tr>
                              <tr>
                                <td>Stake USDT Amount</td>
                                <td>
                                  {/* {GetDetails?.usdtValue || "-"} */}
                                  {GetDetails?.usdtValue ? formatNumber(GetDetails?.usdtValue || "-", 4) : "-"}

                                </td>
                              </tr>
                              <tr>
                                <td>UPRO_USDT Market Price</td>
                                <td>
                                  {GetDetails?.uproLastPrice || "-"}

                                </td>
                              </tr>
                              <tr>
                                <td>Voucher Code</td>
                                <td>
                                  {GetDetails?.couponCode || "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>Last Claimed Amount</td>
                                <td>
                                  {GetDetails?.lastClaimAmount || "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>Last Claimed Date</td>
                                <td>
                                  {
                                    GetDetails.lastClaimDate
                                      ? `${utcToNormalTime(GetDetails.lastClaimDate).date} ${utcToNormalTime(GetDetails.lastClaimDate).time
                                      }`
                                      : "---"
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Total Duration</td>
                                <td>
                                  {GetDetails?.totalDuration || "-"}
                                </td>
                              </tr>

                              {/* <tr>
                                        <td>Staked Type</td>
                                        <td>                                       
                                        {
                                          !GetDetails?.usedCouponCode ? 
                                            'Free Stake' :
                                            GetDetails?.stakedType === 1 ? 
                                            'Own Coupon' : 
                                            GetDetails?.stakedType === 2 ? 
                                            'Others Coupon' : 
                                            "-"
                                        }

                                        </td>
                                      </tr> */}
                              <tr>
                                <td>Staking StartDate</td>
                                <td>
                                  <td>{utcToNormalTime(GetDetails?.createdAt).date} {utcToNormalTime(GetDetails?.createdAt).time || '-'}</td>
                                </td>
                              </tr>
                              <tr>
                                <td>Staking EndDate</td>
                                <td>
                                  <td>{utcToNormalTime(GetDetails?.endDate).date} {utcToNormalTime(GetDetails?.endDate).time || '-'}</td>
                                </td>
                              </tr>
                              <tr>
                                <td>Staking Lock Period</td>
                                <td>
                                  <td>{utcToNormalTime(GetDetails?.initialLockPeriod).date} {utcToNormalTime(GetDetails?.initialLockPeriod).time || '-'}</td>
                                </td>
                              </tr>
                              <tr>
                                <td>Transaction Id</td>
                                <td>
                                  <AddressCellFull address={GetDetails?.transactionId || '-'} />

                                  {/* {GetDetails?.transactionId || "-"} */}
                                </td>
                              </tr>
                              <tr>
                                <td>Total Earnings</td>
                                <td>
                                  {/* {GetDetails?.totalEarnings !== undefined ? GetDetails?.totalEarnings : "-"} */}
                                  { GetDetails?.totalEarnings ? formatNumber(GetDetails?.totalEarnings, 4) : "-"}

                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Modal>

                  </div>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserHistoryFlexibleStack;

import React, { useState, useEffect ,useContext} from "react";
import { Button, Input, Card, CardBody, FormFeedback,CardImg, Col, Container, Modal, ModalBody, ModalFooter, 
  Row, ModalHeader, Form, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../helpers/adminContext";

const CryptoNews = () => {
  const navigate = useNavigate()
  const { adminInfo } = useContext(AdminContext);
  const [adminType,setAdminType] = useState('')
  const [loading, setLoading] = useState(false);
  const [addLoad,setAddLoad] = useState(false)
  const [getDiscoverList, setDisoverList] = useState([]);
  const [getId, setId] = useState("");
  document.title = "Ultrapro | Crypto News";
  const [modal, setModal] = useState(false);
  const [showDetails, setShowDetails] = useState({})
  const [showModel, setShowModel] = useState(false)
  const [deleteLoad,setDeleteLoad] = useState(false)
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  const [sessionLink, setSessionLink] = useState(null);
  const [ImageType,setImageType] = useState('')
  const [errors, setErrors] = useState('');
  const [touched, setTouched] = useState(false);
  const [getThumImage,setThumImage] = useState('')
  const [selectedItem, setSelectedItem] = useState("Select Type");


  //Add Modal
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
    formik.resetForm();
    setImage('')
  }
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
    setImage('')
  }
  const [getImage,setGetImage] = useState('')
  //classList api call
  useEffect(() => {
    fetchData();
    setAdminType(adminInfo?.adminType)

  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.CryptoNewsList);
   
      if (response.status) {
        setLoading(false);
        setTotalPagination(response.data.totalPages);
        setDisoverList(response.data.data);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Handle fetch error
    }
  };


  const handleShowModel = (value) => {
    setShowDetails(value)
    setShowModel(true)
}
const handleDelete = (value) => {
  showUserBlockAlert(confirmDelete, value)
}

const showUserBlockAlert = (onConfirm, row) => {
  Swal.fire({
      title: 'Are you sure?',
      text: `You want to remove this record!`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
          try {
              await onConfirm(row);
          } catch (error) {
              Swal.fire('Error!', 'There was an error deleting the record', 'error');
          }
      }
  }).then((result) => {
      if (result.isConfirmed) {
          // Additional actions after confirmation, if needed
      }
  });
};

const confirmDelete = async (row) => {
  const postForm = {
    newsId : row.newsId
  }
  setDeleteLoad(true)
  try {
      const response = await postData(Apiservices.CryptoNewsDelete,postForm )
      if (response.status) {
          setDeleteLoad(false)
          // toast.success(response.message)
          Swal.fire('Success!', `${response.message}`, 'success');
          fetchData()

      } else {
          // toast.error(response.message)
          Swal.fire('Error!', `${response.message}`, 'error');
          setDeleteLoad(false)

      }

  } catch (error) {
      setDeleteLoad(false)
      toast.error("Internal Server Error..Try Again Later")
  }

}
const Notes = (row) =>{
  navigate(`/cryptonewsnotes/${row.newsId}`);
}
const allowedActions = [1,2,3,4]
const columns = [
  {
    name: "S.No",
    selector: (row, index) => handleNullValue(row.serialNo ?? index + 1),
    sortable: true,
    minWidth: '80px',
    maxWidth: '100px'
  },
  {
    name: "Image",
    selector: (row) => <img 
    style={{
      width: "100px",  
      height: "50px",
      objectFit: "cover",
    }} 
    src={row.newsImage || row.videoThumbImg }
    alt=''
    />,
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },

  {
    name: "Title",
    selector: (row) => handleNullValue(row.newsTitle),
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  
  
  {
    name: "Position",
    selector: (row) => handleNullValue(row.position),
    // sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  {
    name: "Type",
    selector: (row) => handleNullValue(row.uploadType),
    // sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
 

  {
    name: "Status",
    selector: (row) => (
      <div className="form-check form-switch form-switch-md mb-3 d-flex justify-content-center align-items-center">
        <input
          type="checkbox"
          className="form-check-input"
          id="customSwitchsizemd"
          checked={row.isActive === 1}
          onChange={() => handleUpdateStatusClick(row)}
          disabled={loading}
        />
      </div>
    ),
    sortable: true,
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    name: "Created Date & Time",
    selector: (row) => row.createdAt ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${handleNullValue(utcToNormalTime(row.createdAt).time)}` : "---",
    sortable: true,
    minWidth: '200px',
    maxWidth: '250px'
  },
  {
    name: "Updated Date & Time",
    selector: (row) => row.updatedAt ? `${handleNullValue(utcToNormalTime(row.updatedAt).date)} ${handleNullValue(utcToNormalTime(row.updatedAt).time)}` : "---",
    sortable: true,
    minWidth: '200px',
    maxWidth: '250px'
  },
  ...(adminType === 1 || adminInfo.permissions.some(data => 
    data.module === "Crypto News" && allowedActions.some(action => data.actions.includes(action))) ? [
  {
    name: "Action",
    cell: (row) => (
      <div className="d-flex">
          {(adminType === 1 || adminInfo.permissions.some(data => 
            data.module === "Crypto News" && data.actions.includes(3))) ? (
        <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-border-color cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>
            ): null }
            {(adminType === 1 || adminInfo.permissions.some(data => 
              data.module === "Crypto News" && data.actions.includes(2))) ? (
        <i style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline mx-1"></i>
              ): null }
          {(adminType === 1 || adminInfo.permissions.some(data => 
                data.module === "Crypto News" && data.actions.includes(4))) ? (
        <i onClick={() => handleDelete(row)} className="mdi mdi-trash-can-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></i>
                ): null }
       {(adminType === 1 || adminInfo.permissions.some(data => 
                data.module === "Crypto News" && data.actions.includes(2))) ? (
         <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-history cursor-pointer" onClick={() => Notes(row)}></i> 
        ): null }
      </div>
    ),
    ignoreRowClick: true,
    minWidth: '150px',
    maxWidth: '200px'
  }]: []),
];
  


  //paginnation
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; 
  const pageCount = Math.ceil(totalPagination);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };
  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: itemsPerPage,
      // search: inputSearch,
    };
    try {
      const response = await postData(Apiservices.CryptoNewsList,postForm);
      if (response.status) {
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  
  const validateSessionLink = (value) => {
    let error = '';
    if (!value) {
      error = 'Video link is required';
    } 
    return error;
  };


  
  //Add form validation
  const formik = useFormik({
    initialValues: {
      title: "",
      position:'',
      isActive:"",
      description:'',
      uploadtype:""
    },
    validationSchema: Yup.object({
      title: Yup.string().trim().required("Title is required"),
      position: Yup.string().trim()
      .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
      .required("Position is required"),
      isActive: Yup.string().trim().required("Status is required"),
      description:Yup.string().trim().required("Description is required"),
      uploadtype:Yup.string().trim().required("Type is required")

    }),
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  
  

  const handleChange = (e) => {
    const { value } = e.target;
    setSessionLink(value);
    const error = validateSessionLink(value);
    setErrors(error);
  };
  const handleBlur = () => {
    setTouched(true);
  };
//Addapicall
const handleAdd = async (data) => {

 if (formik.values.uploadtype === 'image') {
  if (!image) {
    toast.error("Image is required");
    setAddLoad(false);
    return;
  }
}
if (formik.values.uploadtype === 'video') {

  if (!videoImage) {
    toast.error("Video Thumbnail Image is required");
    setAddLoad(false);
    return;
  }
}

if (formik.values.uploadtype === 'video') {

  if (!ImageType) {
    toast.error("Image type is required");
    setAddLoad(false);
    return;
  }

  const error = validateSessionLink(sessionLink);
  if (error) {
    setErrors(error);  
    setAddLoad(false); 
    setTouched(true);
    return;
  }
}
  // Validate session link before submission
  const formData = new FormData();
  formData.append("newsTitle", data.title);
  formData.append("description", data.description);
  formData.append("videoLink", sessionLink);
  formData.append("videoType", ImageType);
  formData.append("uploadType", data.uploadtype);
  formData.append('newsId', "");
  formData.append("isActive", data.isActive);
  formData.append("position", data.position);
  formData.append('files', image);
  

  if(formik.values.uploadtype !== 'video'){
    formData.append('files', image);
  }
  else{
    formData.append('files', videoImage);
  }
 
  try {
  setAddLoad(true);
    const response = await postData(Apiservices.CryptoNewsAdd, formData);
    if (response.status === true) {
      toast.success(response.message);
      formik.resetForm();
      setmodal_list(false);
      setAddLoad(false);
      fetchData();
      setImage(null)
      setVideoImage(null)
      setImageType(null)
      setDisoverList('')
      setSessionLink(null)
      setErrors('');

    } else {
      toast.error(response.message);
      // formik.resetForm();
      setAddLoad(false);
      fetchData();
      setImage(null)
      setDisoverList('')
      setErrors('');
      setSessionLink(null)


    }
  } catch (error) {
    toast.error(error.response.message);
    setAddLoad(false);
    setAddLoad(false);
    setImage(null)
    setDisoverList('')
    setErrors('');
  }
};

  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,
  
    initialValues: {
      title: "",
      position:'',
      isActive:"",
      description:'',
      uploadtype:""
    
    },
    validationSchema: Yup.object({
      title: Yup.string().trim().required("Title is required"),
      position: Yup.string().trim()
      .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
      .required("Position is required"),
      isActive: Yup.string().trim().required("Status is required"),
      description:Yup.string().trim().required("Description is required"),
      uploadtype:Yup.string().trim().required("Type is required")
      
    }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });
  
  const [getUploadType,SetGetUploadType] = useState('')
  const handleUpdate = (record) => {
    setId(record.newsId);
    setThumImage(record.videoThumbImg)
    setGetImage(record.newsImage)
    setSessionLink(record.videoLink)
    setImageType(record.videoType)
    SetGetUploadType(record.uploadType)
    validationUpdate.setValues({
      title: record.newsTitle || "",
      position:record.position || "",
      uploadtype:record.uploadType || "",
      description:record.description || "",
      isActive: record.isActive !== undefined ? String(record.isActive) : "",
      
    });
   
    setmodal_update_list(true);
  };

useEffect(()=>{
  if(getUploadType === 'image'){
    setSessionLink('')
    setVideoImage('')
    setImageType('')
    setThumImage('')
    }
    else{
      setImage('')
    setGetImage('')

    }
},[validationUpdate.values.uploadtype])
  
 const [updateLoad,setUpdateLoad] = useState(false)
 const confirmUpdate = async (values) => {
  setUpdateLoad(true);

  const { uploadtype } = validationUpdate.values;

  if (uploadtype === 'video') {
    const error = validateSessionLink(sessionLink);
    if (error) {
      setErrors(error);
      setUpdateLoad(false);
      setTouched(true);
      return;
    }
  }

  if (uploadtype !== 'video' && !image && !getImage) {
    toast.error("Image is required");
    setUpdateLoad(false);
    return;
  }

  if (uploadtype === 'video' && !ImageType) {
    toast.error("Image type is required");
    setUpdateLoad(false);
    return;
  }

  const updateformData = new FormData();
  updateformData.append("newsTitle", values.title);
  updateformData.append("description", values.description);
  updateformData.append("videoLink", sessionLink);
  updateformData.append("videoType", ImageType);
  updateformData.append("uploadType", values.uploadtype);
  updateformData.append("newsId", getId);
  updateformData.append("isActive", values.isActive);
  updateformData.append("position", values.position);
  updateformData.append("files", videoImage);


  if(getUploadType === 'Video'){
    updateformData.append("files", videoImage);
  }
  else{
    updateformData.append("files", image);

  }
 
  if (uploadtype !== 'video') {
    updateformData.append("files", image || getImage);
  } else {
    updateformData.append("files", videoImage || getThumImage);
  }
 

  try {
    const response = await postData(Apiservices.CryptoNewsUpdate, updateformData);
    if (response.status === true) {
      setmodal_update_list(false);
      toast.success(response.message);
      fetchData();
      setDisoverList('');
      setSessionLink(null)
    } else {
      toast.error(response.message);
      fetchData();
      setDisoverList('');
      setErrors('');
      setSessionLink(null)
    }
  } catch (error) {
    toast.error(error.response.message);
    validationUpdate.resetForm();
    setErrors('');
    setUpdateLoad(false)
   
  } finally {
    setUpdateLoad(false);
   
  }
};

  //Searchfun
  const [inputSearch, setinputSearch] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "") {
      fetchData();
      setSearchValid(false);
    } else {
      setinputSearch(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
      };

     
      try {
       
      const response = await postData(Apiservices.CryptoNewsList,postForm);

        if (response.status) {
          setSearchLoad(false);
          setDisoverList(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);

          // toast(response.message);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };

const showStatusAlert = (onConfirm,value) => {
   
  Swal.fire({
    title: 'Are you sure?',
    // text: "You want to update Withdraw OTP Attempt Count ?",
    text: "You want to change this status ?",
    icon: 'info',
    showCancelButton: true,
    inputLabel: "Enter a Reason",
    input: "textarea",
    inputAttributes: {
      autocapitalize: "off",
      autocomplete: "off"
    },
    inputPlaceholder: "Enter Reason",
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Update it!',
    showLoaderOnConfirm: true,
  
    preConfirm: async (reason) => {
      if (!reason) {
        Swal.showValidationMessage('Reason is required');
      } else {
        Swal.showLoading();
        try {
          await onConfirm(value, reason);
        } catch (error) {
          // Swal.fire('Error!', 'There was an error updating the status', 'error');
        }
      }
    },

    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
    if (result.isConfirmed) {
      // Handle confirmation here if needed
    }
  });
};
const handleUpdateStatusClick = (value) => {
  showStatusAlert(handleToggle1, value);
}

const handleToggle1 = async (rowDetails,reason) => {
  
  const updatedStatus = rowDetails.isActive === 1 ? 0 : 1;
  try {
    const statusUpdate = new FormData();
    statusUpdate.append("newsTitle", rowDetails.newsTitle);
    statusUpdate.append("description", rowDetails.description);
    statusUpdate.append("videoLink", sessionLink);
    statusUpdate.append("videoType", ImageType);
    statusUpdate.append("uploadType", rowDetails.uploadType);
    statusUpdate.append('newsId', rowDetails.newsId);
    statusUpdate.append("position", rowDetails.position);
    statusUpdate.append("isActive", updatedStatus);
    statusUpdate.append("reason", reason);
    const response = await postData(Apiservices.CryptoNewsUpdate, statusUpdate);
 
    if (response.status) {
      toast.dismiss();
      Swal.fire("Success !", "The status has been updated successfully", "success");
      //setReason('')
      fetchData();
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    
    toast.dismiss();
    toast.error(error.response.data.message);
  }
};


const toggleModal = () => {
  setModal(!modal);
};

const [rowsPerPage, setRowsPerPage] = useState(10);
const [count, setCount] = useState("");

const handleRowsPerPageChange = (newRowsPerPage) => {
  setRowsPerPage(parseInt(newRowsPerPage));
  HandleClickRowperpage(newRowsPerPage)
}
const HandleClickRowperpage = async(newRowsPerPage) => {
  setLoading(true);
  const postForm = {
    page:1,
    limit:Number(newRowsPerPage) ,
  };

 
  try {
    if (getDiscoverList === undefined) {
      const response = await postData(Apiservices.CryptoNewsList,postForm);

      if (response.status) {
        setLoading(false);
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } else {
      const response = await postData(Apiservices.CryptoNewsList,postForm);
      if (response.status) {
        setLoading(false);
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    }
  } catch (error) {
   
    toast.error("Internal Server Error..Try Again Later");
    setLoading(false);
  }

};


const handleImageChange = (e) => {
  const file = e.target.files[0];
  setImage(file);
 
};
const [videoImage,setVideoImage] = useState('')
const handleImageChange1 = (e) => {
  const file = e.target.files[0];
  setVideoImage(file);
}


const handlechangeType = (e) => {
  setImageType(e.target.value);
};



const handleFilterType = async (item) => {
  setLoading(true);
  const postForm = {
   uploadType:item
  };
  try {
    const response = await postData(Apiservices.CryptoNewsList,postForm);
    if (response.status) {
      setDisoverList(response.data.data);
      setTotalPagination(response.data.totalPages);
      setTotalLimit(response.data.limit);
      setCount(response.data.currentCount);

      setLoading(false);
    } else {
      setLoading(false);
    }
  } catch (err) {
    setLoading(false);
  }
};
const handleDropdownClick = (item) => {
 
  setSelectedItem(item);
  switch (item) {
    case "All":
      fetchData();
      break;
    case "image":
      handleFilterType(item);
      break;

    case "video":
      handleFilterType(item);
      break;
   
    default:
      break;
  }
};
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Crypto News" breadcrumbItem="Crypto News List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4">
                    <Col className="col-sm-auto">
                    <div className="input-group">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => handleSearch()}
                            disabled={searchLoad}
                          >
                            {searchLoad ? (<Spinner className="text-light" size="sm" /> ) : (<i className="ri-search-line" /> )}
                          </button>
                        </div>
                      </div>
                   
                    </Col>
                    {/* Add Modal Button */}
                    <Col className="col-sm">
                   
                      <div className="d-flex justify-content-sm-end">
                      <div className="mx-2">
                        <select
                          className="form-select"
                          value={selectedItem}
                          onChange={(e) => handleDropdownClick(e.target.value)}
                        >
                          <option selected value="" disabled>
                            Select Type
                          </option>
                          <option value="All">Show All</option>
                          <option value="image">Image</option>
                          <option value="video">Video</option>
                         
                        </select>
                      </div>
                      {(adminType === 1 || adminInfo.permissions.some(data => 
                      data.module === "Crypto News" && data.actions.includes(1))) ? (
                        <Button
                          color="success"
                          className="add-btn"
                          onClick={() => tog_list()}
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                        </Button> ) :null}
                      </div>
                    </Col>
                  </Row>
                 
    <div className="table-responsive  mt-2">
    <DataTable
      columns={columns}
      data={getDiscoverList}
      paginationServer
      paginationTotalRows={getDiscoverList.length}
      progressPending={loading}
      persistTableHead={true}
      progressComponent={<div className="py-3">Loading...</div>}
      noDataComponent={<div className="py-3">No records found</div>}
      fixedHeader={true}
      
    />
    </div>
   

<div className="container">
  <div className="row justify-content-end mt-4">
  <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
      <div className="d-flex align-items-center me-3">
        <span className="me-2">Rows per page:</span>
        <select
          value={rowsPerPage}
          onChange={(e) => handleRowsPerPageChange(e.target.value)}
          className="form-control-sm"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>
      <div className="me-md-3 mb-3 mb-md-0">
        <span>{count}</span>
      </div>
      <div className="pagination pagination-rounded mt-3">
        <ReactPaginate
          previousLabel={<i className="mdi mdi-chevron-left"></i>}
          nextLabel={<i className="mdi mdi-chevron-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageLinkClassName={"page-link"}
          breakLinkClassName={"break-link"}
          disabledClassName={"disabled"}
          initialPage={currentPage}
          disableInitialCallback={true}
          forcePage={currentPage}
          renderLink={(props) => {
            if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
              return (
                <a
                  {...props}
                  onClick={() =>
                    handlePageClick({
                      selected: pageCount - 1,
                    })
                  }
                >
                  {pageCount}
                </a>
              );
            }
            return <a {...props} />;
          }}
        />
      </div>
    </div>
  </div>
</div>
  

  {/* // ADDMODAL  */}
  <Modal className="modal-dialog modal-lg" isOpen={modal_list}  toggle={() => { tog_list(); }}centered>
  <ModalHeader  className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list();}}> Add Crypto News</ModalHeader>
  <Form autoComplete="off" className="form-horizontal" 
  onSubmit={(e) => { e.preventDefault();   formik.handleSubmit();    return false;}}>
  <ModalBody>
  <Row>
    <Col md={6}>
  

        <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
           Title 
        </label>

        <Input
          type="text"
          id="title-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Title"
          name="title"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title || ""}
          invalid={formik.touched.title && formik.errors.title}
        />
        {formik.touched.title && formik.errors.title && <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>}
      </div>
     
      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
         Select Type{" "}
        </label>
        <select
          id="status-field"
          //  className="form-control"
          name="uploadtype"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.uploadtype || ""}
          className={`form-control ${formik.touched.uploadtype && formik.errors.uploadtype ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Type
          </option>
          <option value='video'>Video</option>
          <option value='image'>Image</option>
        </select>
        {formik.touched.uploadtype && formik.errors.uploadtype && <FormFeedback uploadtype="invalid"> {formik.errors.uploadtype} </FormFeedback>}
      </div>
     
     
      {formik.values.uploadtype === 'image' && (
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
           Image 
        </label>
        <div> 
            <input type="file" className="form-control"
                  accept=".png, .jpg, .jpeg"
                 onChange={handleImageChange} />
                </div>
                {error && <div className="text-danger" style={{ fontSize: '12px' }}>{error}</div>}

                {image ? 
                    <Card style={{ width: '200px'}}>
            <CardBody>
              {/* <CardTitle tag="h6">Image Preview</CardTitle> */}
              <CardImg
                top
                src={image ? URL.createObjectURL(image) : ''}
                style={{ width: '100%', objectFit: 'cover' }}
              />
            </CardBody>
          </Card> : null}
      </div> )}


      {formik.values.uploadtype === 'video' && (
        <>
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
        Video Thumbnail  Image 
        </label>
        <div> 
            <input type="file" className="form-control"
                  accept=".png, .jpg, .jpeg"
                 onChange={handleImageChange1} />
                </div>
                {error && <div className="text-danger" style={{ fontSize: '12px' }}>{error}</div>}

                {videoImage ? 
                    <Card style={{ width: '200px'}}>
            <CardBody>
              {/* <CardTitle tag="h6">Image Preview</CardTitle> */}
              <CardImg
                top
                src={videoImage ? URL.createObjectURL(videoImage) : ''}
                style={{ width: '100%', objectFit: 'cover' }}
              />
            </CardBody>
          </Card> : null}
      </div> 


        <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Video Link
        </label>
        <Input
          type="text"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Video Link"
          // name="videolink"
          // onChange={formik.handleChange}
          // onBlur={formik.handleBlur}
          // value={formik.values.videolink || ""}
          // invalid={formik.touched.videolink && formik.errors.videolink}
          name="sessionlink"
          value={sessionLink}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={touched && !!errors}
        />
        {touched && errors && <FormFeedback>{errors}</FormFeedback>}
        </div>

        <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
        Image Type{" "}
        </label>
        <select
          id="status-field"
           className="form-control"
          name="imagetype"
          onChange={handlechangeType}
          value={ImageType}
          // onChange={formik.handleChange}
          // onBlur={formik.handleBlur}
          // value={formik.values.imagetype || ""}
          // className={`form-control ${formik.touched.imagetype && formik.errors.imagetype ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Image Type
          </option>
          <option value='portrait'>Portrait</option>
          <option value='landscape'>Landscape</option>
        </select>
        {/* {formik.touched.imagetype && formik.errors.imagetype && <FormFeedback type="invalid"> {formik.errors.imagetype} </FormFeedback>} */}
      </div>
        </> 

      
      )}
    </Col>
    <Col md={6}>

     
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Description 
        </label>

        <Input
          type="textarea"
          id="description-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Description"
          name="description"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description || ""}
          invalid={formik.touched.description && formik.errors.description}
        />
        {formik.touched.description && formik.errors.description && <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>}
      </div>

      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Position
        </label>
        <Input
          type="number"
           min="1"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Position"
          name="position"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.position || ""}
          invalid={formik.touched.position && formik.errors.position}
        />
        {formik.touched.position && formik.errors.position && <FormFeedback type="invalid">{formik.errors.position}</FormFeedback>}
      </div> 

      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
          Status
        </label>
        <select
          id="status-field"
          //  className="form-control"
          name="isActive"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.isActive || ""}
          className={`form-control ${formik.touched.isActive && formik.errors.isActive ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Status
          </option>
          <option value={1}>Active</option>
          <option value={0}>Deactive</option>
        </select>
        {formik.touched.isActive && formik.errors.isActive && <FormFeedback type="invalid"> {formik.errors.isActive} </FormFeedback>}
      </div>
      
    </Col>
  </Row>
</ModalBody>

    <ModalFooter>
      <div className="hstack gap-2 justify-content-end">
        <button type="button" className="btn btn-light" onClick={() => {setmodal_list(false); formik.resetForm();setImage('')}}>
          Close
        </button>

        <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={addLoad}>
          {addLoad ? <Loader /> : "Submit"}
        </button>
      </div>
    </ModalFooter>
  </Form>
</Modal>


     {/* UpdateModal */}
  <Modal className="modal-dialog modal-lg" isOpen={modal_update_list}  toggle={() => { tog_update_list();}} centered>
  <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>
    Update Crypto News
  </ModalHeader>
  <Form autoComplete="off" className="form-horizontal"
    onSubmit={(e) => {
      e.preventDefault();
      validationUpdate.handleSubmit();
      return false;
    }}
  >
    <ModalBody>
    <Row>
    <Col md={6}>

        <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
           Title 
        </label>

        <Input
          type="text"
          id="title-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Title"
          name="title"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.title || ""}
          invalid={validationUpdate.touched.title && validationUpdate.errors.title}
        />
        {validationUpdate.touched.title && validationUpdate.errors.title && <FormFeedback type="invalid">{validationUpdate.errors.title}</FormFeedback>}
      </div>
     
      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
         Select Type{" "}
        </label>
        <select
          id="status-field"
          //  className="form-control"
          name="uploadtype"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.uploadtype || ""}
          className={`form-control ${validationUpdate.touched.uploadtype && validationUpdate.errors.uploadtype ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Type
          </option>
          <option value='video'>Video</option>
          <option value='image'>Image</option>
        </select>
        {validationUpdate.touched.uploadtype && validationUpdate.errors.uploadtype && <FormFeedback uploadtype="invalid"> {validationUpdate.errors.uploadtype} </FormFeedback>}
      </div>
     
     
      {validationUpdate.values.uploadtype === 'image' && (
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
           Image 
        </label>
        <div> 
            <input type="file" className="form-control"
                  accept=".png, .jpg, .jpeg"
                 onChange={handleImageChange} />
                </div>
                {error && <div className="text-danger" style={{ fontSize: '12px' }}>{error}</div>}

                {(getImage || image) && (
        <Card style={{ width: '200px' }}>
          <CardBody>
            <CardImg
              top
              src={image ? URL.createObjectURL(image) : getImage}
              style={{ width: '100%', objectFit: 'cover' }}
            />
          </CardBody>
        </Card>
      )}
      </div> )}


      {validationUpdate.values.uploadtype === 'video' && (
        <>
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
        Video Thumbnail  Image 
        </label>
        <div> 
            <input type="file" className="form-control"
                  accept=".png, .jpg, .jpeg"
                 onChange={handleImageChange1} />
                </div>
                {error && <div className="text-danger" style={{ fontSize: '12px' }}>{error}</div>}

               
           {(getThumImage || videoImage) && (
        <Card style={{ width: '200px' }}>
          <CardBody>
            <CardImg
              top
              src={videoImage ? URL.createObjectURL(videoImage) : getThumImage}
              style={{ width: '100%', objectFit: 'cover' }}
            />
          </CardBody>
        </Card>
      )}
      </div> 


        <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Video Link
        </label>
        <Input
          type="text"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Video Link"
          // name="videolink"
          // onChange={validationUpdate.handleChange}
          // onBlur={validationUpdate.handleBlur}
          // value={validationUpdate.values.videolink || ""}
          // invalid={validationUpdate.touched.videolink && validationUpdate.errors.videolink}
          name="sessionlink"
          value={sessionLink}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={touched && !!errors}
        />
        {touched && errors && <FormFeedback>{errors}</FormFeedback>}
        </div>

        <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
        Image Type{" "}
        </label>
        <select
          id="status-field"
           className="form-control"
          name="imagetype"
          onChange={handlechangeType}
          value={ImageType}
          // onChange={validationUpdate.handleChange}
          // onBlur={validationUpdate.handleBlur}
          // value={validationUpdate.values.imagetype || ""}
          // className={`form-control ${validationUpdate.touched.imagetype && validationUpdate.errors.imagetype ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Image Type
          </option>
          <option value='portrait'>Portrait</option>
          <option value='landscape'>Landscape</option>
        </select>
        {/* {validationUpdate.touched.imagetype && validationUpdate.errors.imagetype && <FormFeedback type="invalid"> {validationUpdate.errors.imagetype} </FormFeedback>} */}
      </div>
        </> 

      
      )}
    </Col>
    <Col md={6}>

     
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Description
        </label>

        <Input
          type="textarea"
          id="description-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Description"
          name="description"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.description || ""}
          invalid={validationUpdate.touched.description && validationUpdate.errors.description}
        />
        {validationUpdate.touched.description && validationUpdate.errors.description && <FormFeedback type="invalid">{validationUpdate.errors.description}</FormFeedback>}
      </div>

      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Position
        </label>
        <Input
          type="number"
           min="1"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Position"
          name="position"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.position || ""}
          invalid={validationUpdate.touched.position && validationUpdate.errors.position}
        />
        {validationUpdate.touched.position && validationUpdate.errors.position && <FormFeedback type="invalid">{validationUpdate.errors.position}</FormFeedback>}
      </div> 

      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
          Status
        </label>
        <select
          id="status-field"
          //  className="form-control"
          name="isActive"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.isActive || ""}
          className={`form-control ${validationUpdate.touched.isActive && validationUpdate.errors.isActive ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Status
          </option>
          <option value={1}>Active</option>
          <option value={0}>Deactive</option>
        </select>
        {validationUpdate.touched.isActive && validationUpdate.errors.isActive && <FormFeedback type="invalid"> {validationUpdate.errors.isActive} </FormFeedback>}
      </div>
      
    </Col>
  </Row>
  
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-light" onClick={() => {setmodal_update_list(false);setImage('')}}>
              Close
            </button>

            <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={updateLoad}>
              {updateLoad ? <Loader /> : "Submit"}
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>



{/* View Modal    */}
<Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static" >

<div className="modal-header">
    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
      Crypto News Details 
    </h5>
    <button
        onClick={() => { setShowModel(false); }}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div className="modal-body">

    <div className="table-responsive">
        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td><b>Title</b></td>
                    <td>{showDetails?.newsTitle || '-'}</td>
                </tr>
                <tr>
                    <td><b>Crypto News ID</b></td>
                    <td>{showDetails?.newsId || '-'}</td>
                </tr>
                <tr>
                    <td><b>Description</b></td>
                    <td>{showDetails?.description || '-'}</td>
                </tr>
              
               
            
                <tr>
                    <td><b>Position</b></td>
                    <td>{showDetails?.position || '-'}</td>
                </tr>
            
                <tr>
                    <td><b>Status</b></td>
                    <td>{showDetails.isActive == 1 ? 'Active' : 'Deactive' || '-'}</td>
                </tr>
                <tr>
                    <td><b>Reason</b></td>
                    <td>{showDetails?.reason || '-'}</td>
                </tr>
                <tr>

                    <td><b>Created Date & Time</b></td>
                    <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                </tr>
                <tr>

              <td><b>Updated Date & Time</b></td>
              <td>{utcToNormalTime(showDetails.updatedAt).date} {utcToNormalTime(showDetails.updatedAt).time || '-'}</td>
              </tr>

                {showDetails?.uploadType === 'video' ? 
                <>
                <tr>
                    <td><b>Video Link</b></td>
                    <td>{showDetails?.videoLink || '-'}</td>
                </tr>
                <tr>
                    <td><b>Image Type</b></td>
                    <td>{showDetails?.videoType || '-'}</td>
                </tr>
                <tr>
                    <td><b>Video Thumbnail Image</b></td>
                    <td>
                      <Card style={{ width: '200px'}}>
                      <CardBody>
                        <CardImg
                        top
                          src={showDetails.videoThumbImg || '-'}
                          style={{ width: '100%', objectFit: 'cover' }}
                        />
                      </CardBody>
                    </Card>
                    </td>
                </tr>
                </> 
                :
                <tr>
                <td><b>Image</b></td>
                <td>
                  <Card style={{ width: '200px'}}>
                  <CardBody>
                    <CardImg
                    top
                      src={showDetails.newsImage || '-'}
                      style={{ width: '100%', objectFit: 'cover' }}
                    />
                  </CardBody>
                </Card>
                </td>
            </tr>
              }
               
               
            </tbody>
        </table>
    </div>

</div>

</Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CryptoNews;

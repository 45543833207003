import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  Row,
  ModalHeader,
  Form,
  Spinner,
  InputGroup,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData, postGetFile } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import {
  AddressCell,
  AddressCellFull,
  handleNullValue,
  utcToNormalTime,
} from "../../helpers/function";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import country from "../../helpers/CountryCodes.json";
import { formatNumber } from "../../helpers/common";
const ClaimRewardHistory = () => {
  document.title = "Ultrapro | Staking Offers";
  const location = useLocation();

  const [selectedCountry, setSelectedCountry] = useState();
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [downLoading, setDownLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startingDate, setStartingDate] = useState('');
  const [endingDate, setEndingDate] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [downloadStartDate, setDownloadEndDate] = useState({});
  const userApi = location.state?.user;
  const [user, setUser] = useState(userApi);
  // const [selectedItem, setSelectedItem] = useState("All Users");
  const [selectedItem, setSelectedItem] = useState("");

  const [selectedType, setSelectedType] = useState('')
  const [GetDetails, setDetails] = useState([])



  const today = new Date();
  //update Modal
  const [modal_view, setmodal_view] = useState(false);


  //Pagination;
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = Math.ceil(totalPagination);
  const [count, setCount] = useState("");

  const [DateValue, setDateValue] = useState({
    startDate: "",
    endDate: ""
  });

  //LIST API CALL
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (user === undefined) {
      setUser(Apiservices.flexibleStackClaimReward);
    }
  }, [user]);



  const fetchData = async () => {
    // const postData = {};
    try {
      const response = await postData(Apiservices.flexibleStackClaimReward);
      console.log(response.data, "7777777777777777777")
      if (response) {
        setTotalPagination(response?.data?.totalPages);
        setTotalLimit(response?.data?.itemsPerPage);
        setCount(response?.data?.countFrom);
        setRecord(response?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleNav = async (record) => {
    console.log("record==============", record);
    setmodal_view(!modal_view);
    setDetails(record)
  };

  {/* <td>{formatNumber(transactionDetail?.runningBalance || 0, transactionDetail?.decimalPoint)}</td> */ }
  const columns = [
    {
      name: "S.No",
      // selector: (row, index) => currentPage * itemsPerPage + index + 1,
      selector: (row, index) => currentPage * totalLimit + index + 1,
      sortable: true,
    },
    {
      name: "Plan Name",
      selector: (row) => handleNullValue(row?.planName || '-'),
      sortable: true,
      minWidth: '200px',
    },
   
    {
      name: 'Name',
      selector: row => 
          <div className="d-flex align-items-center" >
              {row.fullName ? 
      <Link className="link-tag" to={`/allUsers/userDetails/${row.userId}`} target='_blank'>
        <span className="mx-1 text-decoration-underline">
          {row.fullName || '-'}
        </span>
      </Link> : <span>-</span>}
    </div>,
      sortable: true,
      minWidth:'200px'
  },
  
    {
      name: 'Email',
      selector: row => 
          <div className="d-flex align-items-center" >
              {row.email ? 
      <Link className="link-tag" to={`/allUsers/userDetails/${row.userId}`} target='_blank'>
        <span className="mx-1 text-decoration-underline">
          {row.email || '-'}
        </span>
      </Link> : <span>-</span>}
    </div>,
      sortable: true,
      minWidth:'300px'
  },
    {
      name: "Coin",
      selector: (row) => handleNullValue(row?.currency || '-'),
      sortable: true,
      maxWidth: '100px',
    },
    {
      name: "Transaction Id",
      selector: (row) => <AddressCell address={row.transId} />,
      sortable: true,
      minWidth: '150px',
    },
    {
      name: "Amount",
      selector: (row) => handleNullValue(formatNumber(row?.amount, row?.decimalPoint || '-')),
      sortable: true,
      minWidth: '100px',

    },
    {
      name: "Country",
      selector: (row) => handleNullValue(row?.country || '-'),
      sortable: true,
      maxWidth: '200px',
    },

    {
      name: "Date",
      selector: (row) =>
        row.createdAt
          ? `${utcToNormalTime(row.createdAt).date} ${utcToNormalTime(row.createdAt).time
          }`
          : "-",
      sortable: true,
      minWidth: '200px',
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <i
            style={{ fontSize: '15px', cursor: 'pointer', marginLeft: "15px" }}
            onClick={() => handleNav(row)}
            className="mdi mdi-eye-outline mx-1"
          ></i>
        </div>
      ),
      ignoreRowClick: true,
      maxWidth: '80px',
    }

  ];


  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    // handlePagePost(selected );
    fetchPaginationList({ search: searchTerm, page: selected, limit: rowsPerPage, startDate: DateValue.startDate, 
      endDate: DateValue.endDate, filterType: selectedItem, country: selectedCountry });
    // handlePagePost(selected, { startDate: downloadStartDate.startDate, endDate: downloadStartDate.endDate });
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    let perPage = parseInt(newRowsPerPage);
    setRowsPerPage(perPage);
    setCurrentPage(0);
    fetchPaginationList({ search: searchTerm, page: 0, limit: perPage, startDate: DateValue.startDate, endDate: DateValue.endDate });
    // HandleClickRowperpage(newRowsPerPage)
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const handleFilter = async (apiName) => {
    setLoading(true);
    try {
      const response = await postData(apiName);
      if (response.status) {
        setLoading(false);
        setRecord(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.itemsPerPage);
        setCount(response.data.countFrom);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleDateChange = ([start, end]) => {
    const formattedStart = start ? formatDate(start) : null;
    const formattedEnd = end ? formatDate(end) : null;

    setDateRange([start, end]);

    if (!formattedStart && !formattedEnd) {
      handleFilter(user);
      setDownloadEndDate({});
    }

    if (formattedStart && formattedEnd) {
      setDownloadEndDate({
        startDate: formattedStart,
        endDate: formattedEnd,
      });
      setDateValue({
        startDate: formattedStart,
        endDate: formattedEnd
      });
      fetchPaginationList({ search: searchTerm, page: 0, limit: rowsPerPage, startDate: formattedStart, endDate: formattedEnd,
        filterType: selectedItem, country: selectedCountry });
      // handlePagePost(currentPage, { startDate: formattedStart, endDate: formattedEnd });
    }
  };

  const handleSearch = () => {
    // handlePagePost(currentPage, searchTerm); 
    fetchPaginationList({ search: searchTerm, page: 0, limit: rowsPerPage, startDate: DateValue.startDate, endDate: DateValue.endDate })
  };
  const handleSearchChange = (event) => {
    const value = event.target.value.replace(/\s+/g, '');
    setSearchTerm(value);
  };


  const HandleClickRowperpage = async (newRowsPerPage) => {
    setLoading(true);
    setRowsPerPage(newRowsPerPage);
    handlePagePost();
  };

  const handlePagePost = async (selected, { startDate = null, endDate = null } = {}) => {
    const postForm = {
      page: selected + 1,
      limit: rowsPerPage,
      search: searchTerm,
      month: selectedMonth,
      startDate: startDate,
      endDate: endDate,
      filterType: selectedType,
      country: selectedType,
    };
    try {
      setLoading(true);
      const response = await postData(Apiservices.UserListFlexibleStack, postForm);
      if (response) {
        setTotalPagination(response?.data?.totalPages);
        setTotalLimit(response?.data?.itemsPerPage);
        setCount(response?.data?.countFrom);
        setRecord(response?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }

  const handleSelect = (value, type) => {
    if (type === 'country') {
      setSelectedCountry(value)
    }
    fetchPaginationList({ page: 0, country: value, startDate: DateValue.startDate, endDate: DateValue.endDate, filterType: selectedItem })
  };

  const handleDownload = async (format) => {
    setDownLoading(true);
    try {
      const postForm = {
        startDate: DateValue.startDate,
        endDate: DateValue.endDate,
        status: "",
        search: searchTerm || "",
        filterType: selectedItem || "",
        country: selectedCountry || "",
        download: true,
      };

      const response = await postGetFile(Apiservices.flexibleStackClaimReward, postForm, {
        responseType: 'blob'
      });
      const blob = response.data;
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `FlexibleClaimRewards.${format}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error("Error downloading the file", err);
      toast.error("Error downloading the file");
    } finally {
      setDownLoading(false);
    }
  };

  const handleDropdownClick = async (item) => {
    setSelectedItem(item);
    setDateRange([null, null]);
    let response = "";
    try {
      switch (item) {
        case "all":
          response = ""
          break;
        case "today":
          response = item
          break;
        case "lastWeek":
          response = item
          break;
        case "lastMonth":
          response = item
          break;
        default:
          response = "";
          break;
      }
      // setDateValue({
      //   startDate: "",
      //   endDate: ""
      // })
      fetchPaginationList({ page: 0, country: selectedCountry, startDate: DateValue.startDate, endDate: DateValue.endDate, filterType: response })
    } catch (error) {
      console.error("Error fetching data:", error);
      
    }
  };

  const fetchPaginationList = async (params) => {
    console.log("fetchDetails === params===========", params);
    try {
      const postForm = {
        status: params.status,
        page: params.page + 1,
        search: params.search || '',
        startDate: params.startDate || "",
        endDate: params.endDate || "",
        limit: params.limit || 10,
        country: params.country || "",
        filterType: params.filterType || "",
      }
      setLoading(true);
      const response = await postData(Apiservices.flexibleStackClaimReward, postForm);
      console.log("fetchDetails === params===========", response);

      if (response && response.status) {
        setTotalPagination(response?.data?.totalPages);
        setTotalLimit(response?.data?.itemsPerPage);
        setCount(response?.data?.countFrom);
        setRecord(response?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Flexible Stakes Claim Rewards" breadcrumbItem="Flexible Stakes Claim Rewards" />
          <Row>
            <Col lg={12} className="row">
              <Card className="col-lg-12">
                <CardBody>
                  <Row className="mb-3">
                    <Col className="col-sm-3">
                      <div className="input-group">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Search by Name, Email couponcode"
                          aria-label="Search"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                            {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                          </button>
                        </div>
                      </div>
                    </Col>

                    <Col sm={4} lg={3}>
                      <div className="form-group mb-4">
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Select Start to End Date"
                            options={{
                              mode: 'range',
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                              maxDate: today,
                            }}
                            value={dateRange}
                            onChange={handleDateChange}
                          />
                        </InputGroup>
                      </div>
                    </Col>

                    <Col className="col-3">
                      <select
                        onChange={(e) => handleSelect(e.target.value, 'country')}
                        className="form-select"
                        id="autoSizingSelect"
                        value={selectedCountry || 'Select Country'}
                      >
                        <option value={selectedCountry || 'Select Country'} disabled>
                          {selectedCountry || 'Select Country'}
                        </option>
                        <option value=''>All</option>
                        {country.map((data) => (
                          <option key={data.name} value={data.name}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col className="col-sm-3">
                      <Button
                        color="info"
                        className="add-btn"
                        onClick={() => handleDownload('xlsx')}
                        id="create-btn"
                        disabled={downLoading}
                      >
                        <i className="mdi mdi-download-outline"></i>
                        {downLoading ? <Loader /> : "Download"}
                      </Button>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-sm-3 mx-1">
                      <select
                        className="form-select"
                        value={selectedItem}
                        onChange={(e) => handleDropdownClick(e.target.value)}
                      >
                        <option value="All" disabled>Select</option>
                        <option value="all">All</option>
                        <option value="today">Today</option>
                        <option value="lastWeek">Last Week</option>
                        <option value="lastMonth">Last Month</option>
                      </select>
                    </Col>
                  </Row>
                  <div className="table-responsive overflow-hidden">
                    <DataTable
                      columns={columns}
                      data={record || []}
                      paginationServer
                      paginationTotalRows={record ? record.length : 0}
                      progressPending={loading}
                      progressComponent={<div>Loading...</div>}
                      persistTableHead={true}
                      noDataComponent={
                        <div className="mt-2">No records found</div>
                      }
                      fixedHeader={true}
                    />
                    <div className="container">
                      <div className="row justify-content-end mt-4">
                        <div className="col-12 d-flex flex-column flex-md-row justify-content-end align-items-center">
                          <div className="d-flex align-items-center me-3">
                            <span className="me-2">Rows per page:</span>
                            <select
                              value={rowsPerPage}
                              onChange={(e) => handleRowsPerPageChange(e.target.value)}
                              className="form-control-sm"
                            >
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={30}>30</option>
                              <option value={50}>50</option>
                            </select>
                          </div>
                          <div className="me-md-3 mb-3 mb-md-0">
                            <span>{count}</span>
                          </div>
                          <div className="pagination pagination-rounded mt-3">
                            <ReactPaginate
                              previousLabel={<i className="mdi mdi-chevron-left"></i>}
                              nextLabel={<i className="mdi mdi-chevron-right"></i>}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              breakLinkClassName={"break-link"}
                              disabledClassName={"disabled"}
                              initialPage={currentPage}
                              disableInitialCallback={true}
                              forcePage={currentPage}
                              renderLink={(props) => {
                                if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                  return (
                                    <a
                                      {...props}
                                      onClick={() =>
                                        handlePageClick({
                                          selected: pageCount - 1,
                                        })
                                      }
                                    >
                                      {pageCount}
                                    </a>
                                  );
                                }
                                return <a {...props} />;
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <Modal className='modal-dialog modal-lg' size="l" isOpen={modal_view} centered backdrop="static">
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                          User Claim Details
                        </h5>
                        <button
                          onClick={() => {
                            setmodal_view(!modal_view);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div className="modal-body custom-modal-body">
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                            <tbody>
                              <tr>
                                <td>Plan Name</td>
                                <td>{GetDetails?.planName || "-"}</td>
                              </tr>
                              <tr>
                                <td>Transaction Id</td>
                                <td>
                                  <AddressCellFull address={GetDetails?.transId || '-'} />

                                  {/* {GetDetails?.transactionId || "-"} */}
                                </td>
                              </tr>
                              <tr>
                                <td>Stake User Name</td>
                                <td>{GetDetails?.fullName || "-"}</td>
                              </tr>

                              <tr>
                                <td>Stake User Email</td>
                                <td>
                                  {GetDetails?.email || "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>Coin</td>
                                <td>
                                  {GetDetails?.currency || "-"}
                                </td>
                              </tr>

                              <tr>
                                <td>Transaction Amount</td>
                                <td>
                                  {GetDetails?.amount || "-"}

                                </td>
                              </tr>
                             
                              <tr>
                                <td>Description</td>
                                <td>
                                  {GetDetails?.description || "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>Transaction Type</td>
                                <td>
                                  {GetDetails?.transType || "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>Transaction Date</td>
                                <td>
                                  <td>{utcToNormalTime(GetDetails?.createdAt).date} {utcToNormalTime(GetDetails?.createdAt).time || '-'}</td>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClaimRewardHistory;

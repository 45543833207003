import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Modal, Form, InputGroup, Input, FormFeedback, Spinner, Label, ModalFooter, Tooltip } from "reactstrap";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import { useParams } from "react-router-dom";
import { formatNumber } from "../../helpers/common";
import { AddressCellFull, handleNullValue, utcToNormalTime } from "../../helpers/function";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../helpers/Spinner";


const WithdrawTallyPage = () => {
  const params = useParams();

  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [appLoad, setAppLoad] = useState(false);
  const [showOTPModel, setShowOTPModel] = useState(false);
  const [showTransactionOTPModel, setShowTransactionOTPModel] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonTransactLoading, setButtonTransactLoading] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState(null);
  const [showTransactionModel, setShowTransactionModel] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState([]);
  // const toggleTooltip2 = () => setTooltipOpen2(!tooltipOpen2);
  const [tooltipOpenArray, setTooltipOpenArray] = useState([]);
  // const [WithdrawProceedBtn, setWithdrawProceedBtn] = useState(false);
  const [copyStatus, setCopyStatus] = useState({});

  const toggleTooltip2 = (index) => {
    const updatedTooltipState = [...tooltipOpenArray];
    updatedTooltipState[index] = !updatedTooltipState[index];
    setTooltipOpenArray(updatedTooltipState);
  };
  const [fetchData, setFetchData] = useState({
    coinDetails: [],
    walletList: [],
    coinUserBalance: [],
    tallyAmount: [],
    updatedDetails: [],
    userDetails: [],
    transactionColors:[],
    overallTallyStatus: "",
  })

  const [userData, setUserData] = useState({
    userId: "",
    // currencyId: "", 
    currencyName: "",
    oldBalance: "",
    newBalance: "",
  })

  useEffect(() => {
    if (params.userId) {
      fetchWalletData(params.userId);
      setUserData({ userId: params.userId });
    }
  }, [params.userId]);

  const fetchWalletData = async (userId) => {
    try {
      setLoading(true);
      setError(null);
      const res = await postData(Apiservices.TRANSACTION_TALLY_LIST, {
        userId,
      });

      if (res.status) {
        if (res.data && res.data.data) {
          const { userBalance, userTransaction, coinDetails, userDetails,transactionColors } = res.data.data;
          console.log("res.data===============", res.data);
          console.log("res.data===============", res.data.data.transactionColors);
          setFetchData((prev) => ({
            ...prev,
            coinDetails: coinDetails,
            tallyAmount: userTransaction?.balances,
            updatedDetails: userTransaction?.tallyResults,
            coinUserBalance: userBalance,
            walletList: userTransaction?.transactions,
            userDetails: userDetails,
            overallTallyStatus: userTransaction?.overallTallyStatus,
            transactionColors:transactionColors
          }))
          // setCoinDetails(coinDetails);
          // // setTallyAmount(userTransaction?.tallyResults);
          // setTallyAmount(userTransaction?.balances);
          // setUpdatedDetails(userTransaction?.tallyResults);
          // setCoinUserBalance(userBalance);
          // // setCoinUserBalance(userTransaction?.balances)
          // setWalletList(userTransaction?.transactions);
        }
      } else {
        setError("Failed to fetch data");
      }
    } catch (error) {
      console.log("fetchWalletData list err: ", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDateTimeModify = (date) => {
    if (date) {
      const formattedDate = utcToNormalTime(date);
      return `${handleNullValue(formattedDate.date)} ${formattedDate.time}`;
    } else {
      return "---";
    }
  };


  const handleShowUserBalance = (data) => {
    if (fetchData.coinUserBalance?.length > 0) {
      const balance = fetchData.coinUserBalance.find((balance) =>
        balance?.balances?.find((user) => user.currency === data.currency)
      );

      if (balance) {
        const userBalance = balance.balances.find(
          (user) => user.currency === data.currency
        );
        // return formatNumber(userBalance?.amount, data.decimalPoint);
        return userBalance?.amount
      }
    }
    return 0;
  };

  //   const startCountdown = () => {
  //     const intervalId = setInterval(() => {
  //         setCountdown(prevCountdown => {
  //             const newCountdown = prevCountdown - 1;
  //             if (newCountdown <= 0) {
  //                 clearInterval(intervalId);
  //                 setShowOTPModel(false);
  //                 validation.resetForm()
  //                 // setIsDisabled(false)
  //                 return 0;
  //             }

  //             return newCountdown;
  //         });
  //     }, 1000);
  // };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required("OTP is required").matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
    }),
    onSubmit: (values) => {
      otpVerify(values);
    },
  });
  const otpVerify = async (data) => {
    setAppLoad(true);

    const statusdata = {
      userId: userData.userId,
      status: "Verified",
      currencyName: userData.currencyName,
      oldBalance: userData.oldBalance,
      newBalance: userData.newBalance,
      otp: data.otp
    };

    try {
      const response = await postData(Apiservices.TALLY_USER_BALANCE_UPDATED, statusdata);
      if (response.status) {
        toast.success(response.message);
        setAppLoad(false);
        setShowOTPModel(false);
        setButtonLoading(false);
        // setmodal_update_list(false);
        if (params.userId) {
          fetchWalletData(params.userId);
        }
        validation.resetForm();
      } else {
        toast.dismiss();
        toast.error(response.message);
        setLoading(false);
        // setmodal_list(false);
        setAppLoad(false);
      }
    } catch (error) {
      toast.dismiss();

      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      setAppLoad(false);
    }
  };


  const handleApprove = async (details) => {
    try {
      if (details) {
        // setCountdown(60)
        setShowOTPModel(true)
        // startCountdown()
        setButtonLoading(true);
        let oldBalance = await handleShowUserBalance(details);
        setUserData((prev) => ({
          ...prev,
          currencyName: details.currency,
          oldBalance: oldBalance,
          newBalance: details?.balance
        }))
        setActiveCurrency(details.currency);
      }
    } catch (error) {
      console.log("handleApprove btn error: ", error);

      setButtonLoading(false);
      setActiveCurrency(null);
      toast.error("Internal Server Error..Try Again Later");

    }
  };
  const transactionValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required("OTP is required").matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
    }),
    onSubmit: (values) => {
      otpTransactionVerify(values);
    },
  });
  const otpTransactionVerify = async (data) => {
    setAppLoad(true);

    const statusdata = {
      userId: userData.userId,
      transactionId: transactionDetail.transId,
      status: "history_transaction",
      currencyName: transactionDetail.currency,
      // userBalance: transactionDetail.runningBalance, 
      newBalance: transactionDetail.amount,
      otp: data.otp
    };
    try {
      const response = await postData(Apiservices.ADMIN_UPDATE_TRANSACTION_MODIFIED, statusdata);
      if (response.status) {
        toast.success(response.message);
        setAppLoad(false);
        setShowTransactionOTPModel(!showTransactionOTPModel);
        setShowTransactionModel(!showTransactionModel);
        setButtonTransactLoading(false);
        // setmodal_update_list(false);
        if (params.userId) {
          fetchWalletData(params.userId);
        }
        transactionValidation.resetForm();
      } else {
        toast.dismiss();
        toast.error(response.message);
        setLoading(false);
        setButtonTransactLoading(false);
        // setmodal_list(false);
        setAppLoad(false);
      }
    } catch (error) {
      toast.dismiss();

      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      setAppLoad(false);
    }
  };
  const handleTransactionApproved = () => {
    try {
      // setCountdown(60)
      setShowTransactionOTPModel(true)
      // startCountdown();
      setButtonTransactLoading(true);
    } catch (error) {
      console.log("handleApprove btn error: ", error);
      setButtonTransactLoading(false);
      // setActiveCurrency(null);
      toast.error("Internal Server Error..Try Again Later");
    }
  };
  const handleModalClick = (data) => {
    if (data) {
      setTransactionDetail(data);
      setShowTransactionModel(!showTransactionModel)
    }
  }


const handleCopy = (text, index) => {
  navigator.clipboard.writeText(text).then(() => {
    setCopyStatus((prev) => ({ ...prev, [index]: true }));
    setTimeout(() => setCopyStatus((prev) => ({ ...prev, [index]: false })), 2000); // Reset message after 2 seconds
  });
};

  const getBadgeClass = (code) => {
    switch (code) {
      case 'Matched':
        return "badge-soft-success";
      case 'Not-Matched':
        return "badge-soft-danger";
      default:
        return "";
    }
  };

  const pairLabelShow = (data) => {

    switch (data.transType) {
      case 'trade-buy':
        return `( ${(data.pair) ? data.pair : '-'} ${data.side ? '/ ' + data.side : ''} )`
      case 'trade-map':
        return `( ${(data.pair) ? data.pair : '-'} ${data.side ? '/ ' + data.side : ''} )`
      case 'trade-sell':
        return `( ${(data.pair) ? data.pair : '-'} ${data.side ? '/ ' + data.side : ''} )`
      case 'trade-cancel':
        return `( ${(data.pair) ? data.pair : '-'} ${data.side ? '/ ' + data.side : ''} )`
      default:
        return '';
    }
    // (data.transType =='trade-buy') && `(${data.pair ? data.pair : '-'})`
  }


  const nameStyle = {
    fontSize: '16px',
    color: '#666',
    marginBottom: '8px',
  };

  const emailStyle = {
    fontSize: '16px',
    color: '#666',
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;


  return (
    <React.Fragment>
      <div className="page-content mb-1">
        <Container fluid>
          <Row>
            <Col>
              <div className="d-flex justify-content-center align-items-center">
                <h1> Tally Wallet Balance </h1>
              </div>
              {/* <div className="d-flex justify-content-start align-items-center"> */}
              <div className="container">
                <div className="row">
                  
                  {/* currently used */}
                  {/* <div className="col-lg-4 col-md-12 mb-4">
                    <Card
                      style={{
                        width: "100%",
                        padding: "15px",
                        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                        minHeight: "auto",
                        height: "100%",
                      }}
                    >
                      <CardBody
                        style={{
                          maxHeight: "350px", 
                          overflowY: "auto",  
                          padding: "10px",
                          scrollbarWidth: "thin",
                         }}
                      >
                        <h4 className="mb-4">Transaction Colors</h4>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Transaction Type</th>
                                <th scope="col">Color</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fetchData.transactionColors &&
                                fetchData.transactionColors.length > 0 &&
                                fetchData.transactionColors.reduce((acc, data) => {
                                  const colorGroup = acc.find((group) => group.color === data.color);
                                  if (colorGroup) {
                                    colorGroup.labels.push(data.label); 
                                  } else {
                                    acc.push({ color: data.color, colorname: data.colorname, labels: [data.label] }); 
                                  }
                                  return acc;
                                }, []).map((group, index) => (
                                  <tr key={index}>
                                    <td>{group.labels.join(", ")}</td>
                                    <td
                                      style={{
                                        backgroundColor: group.color.replace("_", " ").toLowerCase(), 
                                        color: "#1c1b22",
                                      }}
                                    >
                                      {group.colorname.replace("_", " ")} 
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </div> */}
                  
                  {/* User Details and Tally Card */}
                  {/* <div className="col-lg-8 col-md-12 mb-4">
                    <Card
                      style={{
                        width: "100%",
                        padding: "40px",
                        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                        margin: "0"
                      }}
                    >
                      <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <div style={nameStyle}>
                              Name: {fetchData.userDetails ? fetchData.userDetails[0].fullName : '-'}
                            </div>
                            <div style={emailStyle}>
                              Email: {fetchData.userDetails ? fetchData.userDetails[0].email : '-'}
                            </div>
                          </div>
                          <span
                            className={`badge ${getBadgeClass(fetchData?.overallTallyStatus)}`}
                            style={{ fontSize: '14px' }}
                          >
                            {fetchData?.overallTallyStatus}
                          </span>
                        </div>

                        <div className="table-responsive">
                          <table className="table table-bordered mb-0 mt-4">
                            <thead>
                              <tr>
                                <th scope="col">Currency Name</th>
                                <th scope="col">Currency Balance Amount</th>
                                <th scope="col">Tally Balance Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fetchData.tallyAmount &&
                                fetchData.tallyAmount.length > 0 &&
                                fetchData.tallyAmount.map((tallyData) => {
                                  const updateData = fetchData.updatedDetails && fetchData.updatedDetails.find((update) => update.currency === tallyData.currency);
                                  const showUpdateTag = updateData && updateData.status === "Approved" && updateData.tallyUpdate;
                                  const tally_blnc_amt = updateData && updateData.tally_blnc_amt;

                                  return (
                                    <tr key={tallyData.currency}>
                                      <td>{tallyData.currency}</td>
                                      <td>{formatNumber(handleShowUserBalance(tallyData), tallyData.decimalPoint)}</td>
                                      <td>
                                        {formatNumber(tallyData.balance, tallyData.decimalPoint)}
                                        {updateData?.tally_amt_color && (
                                          <span style={{ color: updateData.tally_amt_color }} className="mx-2">
                                            {updateData.tally_blnc_show ? `(${formatNumber(tally_blnc_amt, tallyData.decimalPoint)})` : ''}
                                          </span>
                                        )}
                                        {showUpdateTag && (
                                          <button
                                            onClick={() => handleApprove(tallyData)}
                                            className="btn btn-primary mx-2 p-2"
                                            disabled={buttonLoading && activeCurrency !== tallyData.currency}
                                          >
                                            {buttonLoading && activeCurrency === tallyData.currency ? <Loader /> : "Update"}
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </div> */}

                  <div className="col-lg-4 col-md-12 mb-4">
                    <Card
                      style={{
                        width: "100%",
                        padding: "15px",
                        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                        minHeight: "auto",
                        height: "100%", // Ensuring full height available
                      }}
                    >
                      <CardBody
                        style={{
                          display: "flex", // Use flexbox to allow for scrollable content
                          flexDirection: "column", // Ensure content flows vertically
                          height: "200px", // Fixed height for CardBody
                          overflowY: "auto", // Enable scroll if content exceeds the height
                          padding: "10px",
                          scrollbarWidth: "thin", // Custom scrollbar styling
                        }}
                      >
                        <h4 className="mb-4">Transaction Colors</h4>
                        <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto", scrollbarWidth: "thin", }}>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Transaction Type</th>
                                <th scope="col">Color</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fetchData.transactionColors &&
                                fetchData.transactionColors.length > 0 &&
                                fetchData.transactionColors.reduce((acc, data) => {
                                  const colorGroup = acc.find((group) => group.color === data.color);
                                  if (colorGroup) {
                                    colorGroup.labels.push(data.label);
                                  } else {
                                    acc.push({ color: data.color, colorname: data.colorname, labels: [data.label] });
                                  }
                                  return acc;
                                }, []).map((group, index) => (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        color: "#666", // Change this color to your preferred text color for the labels
                                      }}
                                    >{group.labels.join(", ")}</td>
                                    <td
                                      style={{
                                        backgroundColor: group.color.replace("_", " ").toLowerCase(),
                                        color: "#1c1b22",
                                      }}
                                    >
                                      {group.colorname.replace("_", " ")}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>

                  </div>
                  
                  {/* User Details and Tally Card */}
                  <div className="col-lg-8 col-md-12 mb-4">
                    <Card
                      style={{
                        width: "100%",
                        padding: "40px",
                        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                        margin: "0"
                      }}
                    >
                      <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <div style={nameStyle}>
                              Name: {fetchData.userDetails ? fetchData.userDetails[0].fullName : '-'}
                            </div>
                            <div style={emailStyle}>
                              Email: {fetchData.userDetails ? fetchData.userDetails[0].email : '-'}
                            </div>
                          </div>
                          <span
                            className={`badge ${getBadgeClass(fetchData?.overallTallyStatus)}`}
                            style={{ fontSize: '14px' }}
                          >
                            {fetchData?.overallTallyStatus}
                          </span>
                        </div>

                        <div className="table-responsive">
                          <table className="table table-bordered mb-0 mt-4">
                            <thead>
                              <tr>
                                <th scope="col">Currency Name</th>
                                <th scope="col">Currency Balance Amount</th>
                                <th scope="col">Tally Balance Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fetchData.tallyAmount &&
                                fetchData.tallyAmount.length > 0 &&
                                fetchData.tallyAmount.map((tallyData) => {
                                  const updateData = fetchData.updatedDetails && fetchData.updatedDetails.find((update) => update.currency === tallyData.currency);
                                  const showUpdateTag = updateData && updateData.status === "Approved" && updateData.tallyUpdate;
                                  const tally_blnc_amt = updateData && updateData.tally_blnc_amt;

                                  return (
                                    <tr key={tallyData.currency}>
                                      <td>{tallyData.currency}</td>
                                      <td>{formatNumber(handleShowUserBalance(tallyData), tallyData.decimalPoint)}</td>
                                      <td>
                                        {formatNumber(tallyData.balance, tallyData.decimalPoint)}
                                        {updateData?.tally_amt_color && (
                                          <span style={{ color: updateData.tally_amt_color }} className="mx-2">
                                            {updateData.tally_blnc_show ? `(${formatNumber(tally_blnc_amt, tallyData.decimalPoint)})` : ''}
                                          </span>
                                        )}
                                        {showUpdateTag && (
                                          <button
                                            onClick={() => handleApprove(tallyData)}
                                            className="btn btn-primary mx-2 p-2"
                                            disabled={buttonLoading && activeCurrency !== tallyData.currency}
                                          >
                                            {buttonLoading && activeCurrency === tallyData.currency ? <Loader /> : "Update"}
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
              {/* </div> */}

              <Card>
                <CardBody>
                  <div className="d-flex justify-content-start align-items-start mb-4">
                    <h4> User Transaction History </h4>

                  </div>
                  <div className="table-responsive">
                    <table
                      className="table table-bordered mb-0"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr >
                          <th style={{ backgroundColor: '#2d2c35', color: 'white' }} scope="col">SI.No</th>
                          <th style={{ backgroundColor: '#2d2c35', color: 'white' }} scope="col">Date</th>
                          <th style={{ backgroundColor: '#2d2c35', color: 'white' }} scope="col">Currency Name</th>
                          <th style={{ backgroundColor: '#2d2c35', color: 'white' }} scope="col">Transaction Id</th>
                          <th style={{ backgroundColor: '#2d2c35', color: 'white' }} scope="col">Transaction Type</th>
                          <th style={{ backgroundColor: '#2d2c35', color: 'white' }} scope="col">Amount</th>
                          <th style={{ backgroundColor: '#2d2c35', color: 'white' }} scope="col">User Balance</th>
                          <th style={{ backgroundColor: '#2d2c35', color: 'white' }} scope="col">Action</th>

                        </tr>
                      </thead>
                      <tbody>
                        {fetchData.walletList &&
                          fetchData.walletList.length > 0 &&
                          fetchData.walletList.map((data, index) => (
                            <tr>
                              <th scope="row" style={{ backgroundColor: data.color, color: data.textColor }}>{index + 1}</th>
                              <td style={{ backgroundColor: data.color, color: data.textColor }} >{handleDateTimeModify(data.createdAt)}</td>
                              <td style={{ backgroundColor: data.color, color: data.textColor }}>{data.currency}</td>
                              {/* <td style={{ backgroundColor: data.color, color: data.textColor }}>{data.transId === "" ? "----" : data.transId}
                              </td> */}
                            <td style={{ backgroundColor: data.color, color: data.textColor }}>
                                {data.transId ? (
                                  <>
                                    <span>{`${data.transId.substring(0, 6)}...`}</span>
                                    <button
                                      onClick={() => {
                                        navigator.clipboard.writeText(data.transId);
                                        toast.success('Copied to clipboard!');
                                      }}
                                      style={{
                                        marginLeft: '8px',
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        color:'#1c1b22',
                                      }}
                                    >
                                      <i className="fa fa-copy"></i> {/* FontAwesome copy icon */}
                                    </button>
                                  </>
                                ) : (
                                  '___'
                                )}
                              </td>

                              <td style={{ backgroundColor: data.color, color: data.textColor }}>{data.transType} {pairLabelShow(data)}</td>
                              <td style={{ backgroundColor: data.color, color: data.textColor }}>
                                {formatNumber(data.amount, data.decimalPoint || 4)}
                              </td>
                              <td style={{ backgroundColor: data.color, color: data.textColor }}>
                                {formatNumber(
                                  data.runningBalance,
                                  data.decimalPoint || 4
                                )}
                              </td>
                              <td style={{ backgroundColor: data.color, color: data.textColor }}>
                                <>
                                  <span id={`tooltipClose${index}`} onClick={() => handleModalClick(data)} className="mdi mdi-close-box" style={{ fontSize: '15px', cursor: 'pointer' }}></span>

                                  <Tooltip
                                    placement="top"
                                    isOpen={tooltipOpenArray[index] || false}
                                    target={`tooltipClose${index}`}
                                    toggle={() => toggleTooltip2(index)}
                                  >
                                    Close Transaction
                                  </Tooltip>
                                </>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Modal
              className="modal-dialog modal px-4"
              size="l"
              isOpen={showOTPModel}
              centered
              backdrop="static"
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                  Approve Confirmation OTP
                </h5>
                <button
                  onClick={() => {
                    setShowOTPModel(false); setButtonLoading(false);
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <div className="text-center"></div>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md={12} className="AuthStar">
                        <div className="mb-4">
                          <Label className="form-label">
                            One Time Password<span>*</span>
                          </Label>
                          <InputGroup>
                            <Input
                              style={{ borderColor: "#80838B" }}
                              name="otp"
                              // disabled={
                              //   loading || countdown === 60 || countdown === 0
                              // }
                              disabled={
                                loading
                              }
                              className="form-control"
                              placeholder="Enter OTP"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.otp || ""}
                              invalid={
                                validation.touched.otp && validation.errors.otp
                                  ? true
                                  : false
                              }
                            />

                            {validation.touched.otp && validation.errors.otp ? (
                              <FormFeedback>
                                {validation.errors.otp}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>

                        <div className="d-grid mt-4">
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            disabled={appLoad || appLoad}
                            onClick={validation.handleSubmit}
                          >
                            {appLoad ? (
                              <>
                                <Spinner size="sm">Loading...</Spinner>
                                <span style={{ color: "white" }}>
                                  {" "}
                                  Loading...
                                </span>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Modal>

            {/* confirm transaction */}
            <Modal className='modal-dialog modal-lg' size="l" isOpen={showTransactionModel} toggle={() => setShowTransactionModel(!showTransactionModel)} centered backdrop="static" >

              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
                  Transaction Details View & Approve
                </h5>
                <button
                  onClick={() => { setShowTransactionModel(!showTransactionModel); }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>


              <div className="modal-body custom-modal-body">

                <div className="table-responsive">
                  <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td><b>Name</b></td>
                        <td>{transactionDetail.fullName || '-'}</td>
                      </tr>
                      <tr>
                        <td><b>Email</b></td>
                        <td>{transactionDetail.email || '-'}</td>
                      </tr>
                      <tr>
                        <td><b>Transaction Id</b></td>
                        {/* <td>{transactionDetail?.transId || '-'}</td> */}
                        <td><AddressCellFull address={transactionDetail?.transId || '-'} /></td>
                      </tr>
                      <tr>
                        <td><b>Currency Name</b></td>
                        <td>{transactionDetail.currency || '-'}</td>
                      </tr>
                      <tr>
                        <td><b>Amount</b></td>
                        <td>{formatNumber(transactionDetail.amount || 0, transactionDetail.decimalPoint)}</td>
                      </tr>
                      <tr>
                        <td><b>User Balance</b></td>
                        <td>{formatNumber(transactionDetail?.runningBalance || 0, transactionDetail?.decimalPoint)}</td>
                      </tr>
                      <tr>
                        <td><b>Transaction Type</b></td>
                        <td>{transactionDetail?.transType || '-'}</td>
                      </tr>
                      <tr>
                        <td><b>Amount type</b></td>
                        <td>{transactionDetail?.transactionType || '-'}</td>
                      </tr>
                      <tr>
                        <td><b>Request Date & Time</b></td>
                        <td>{utcToNormalTime(transactionDetail?.createdAt).date} {utcToNormalTime(transactionDetail?.createdAt).time}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
              <ModalFooter>
                <div className="hstack gap-2 justify-content-end">
                  <button onClick={() => { setShowTransactionModel(!showTransactionModel); setButtonTransactLoading(false); }} className="btn btn-danger waves-effect waves-light" type="submit">
                    cancel
                  </button>

                  <button onClick={() => handleTransactionApproved()} className="btn btn-primary waves-effect waves-light" type="submit" disabled={buttonTransactLoading}>
                    {buttonTransactLoading ? <Loader /> : "Confirm"}
                  </button>
                </div>
              </ModalFooter>
            </Modal>

            <Modal
              className="modal-dialog modal px-4"
              size="l"
              isOpen={showTransactionOTPModel}
              centered
              backdrop="static"
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                  Approve Confirmation OTP
                </h5>
                <button
                  onClick={() => {
                    setShowTransactionOTPModel(false); setButtonTransactLoading(false);
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <div className="text-center"></div>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      transactionValidation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md={12} className="AuthStar">
                        <div className="mb-4">
                          <Label className="form-label">
                            One Time Password<span>*</span>
                          </Label>
                          <InputGroup>
                            <Input
                              style={{ borderColor: "#80838B" }}
                              name="otp"
                              // disabled={
                              //   loading || countdown === 60 || countdown === 0
                              // }
                              disabled={loading}
                              className="form-control"
                              placeholder="Enter OTP"
                              type="text"
                              onChange={transactionValidation.handleChange}
                              onBlur={transactionValidation.handleBlur}
                              value={transactionValidation.values.otp || ""}
                              invalid={
                                transactionValidation.touched.otp && transactionValidation.errors.otp
                                  ? true
                                  : false
                              }
                            />

                            {transactionValidation.touched.otp && transactionValidation.errors.otp ? (
                              <FormFeedback>
                                {transactionValidation.errors.otp}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>

                        <div className="d-grid mt-4">
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            // disabled={appLoad || countdown === 60 || appLoad}
                            disabled={appLoad}
                            onClick={transactionValidation.handleSubmit}
                          >
                            {appLoad ? (
                              <>
                                <Spinner size="sm">Loading...</Spinner>
                                <span style={{ color: "white" }}>
                                  {" "}
                                  Loading...
                                </span>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WithdrawTallyPage;

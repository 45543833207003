import React, { useState, useEffect, useContext } from "react";
import { Input, Card, CardBody, Col, Tooltip, Container, Row, Spinner, InputGroup, Button, Modal, } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import { AdminContext } from "../../helpers/adminContext";
import { Link } from "react-router-dom";


const UserBankRejected = () => {
        document.title = "Ultrapro | User bank Details";
        const today = new Date();
        const [downloadStartDate, setDownloadEndDate] = useState({});
        const [dateRange, setDateRange] = useState([null, null]);
        const [loading, setLoading] = useState(false);
        const [downLoading, setDownLoading] = useState(false);
        const [showModel, setShowModel] = useState(false)
        const [showDetails, setShowDetails] = useState({})
        const [showPreview, SetPreview] = useState(false)
        const [searchLoad, setSearchLoad] = useState(false);
        const [searchValid, setSearchValid] = useState(false);
        const [count, setCount] = useState('');
        const [inputSearch, setinputSearch] = useState('');
        const [formData, setFormData] = useState([]);
        const [totalPagination, setTotalPagination] = useState(1);
        const [totalLimit, setTotalLimit] = useState(1);
        const [tooltipOpen1, setTooltipOpen1] = useState(false);
        const [currentPage, setCurrentPage] = useState(0);
        const itemsPerPage = totalLimit; // Number of items to display per page
        const pageCount = Math.ceil(totalPagination);
        const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1);

        //Permission List
        const {adminInfo} = useContext(AdminContext);
        const [adminType,setAdminType] = useState('');
        function previewModel() {
            SetPreview(!showPreview);
          }
        useEffect(() => {
            ipList();
            setAdminType(adminInfo?.adminType);
        }, [adminInfo?.adminType]);
        const ipList = async () => {
            setLoading(true);

            try {
                const response = await postData(Apiservices.USER_BANK_REJECTED_HISTORY);
                console.log(response,"55555555555")
                if (response.status) {
                    setLoading(false);

                    setCount(response.data.currentCount)

                    setFormData(response.data.data);
                    setTotalPagination(response.data.totalPages);
                    setTotalLimit(response.data.limit)
                } else {
                    toast.error(response.message);
                    setLoading(false);

                }
            } catch (error) {
                toast.error("Internal Server Error...");
                setLoading(false);


            }
        };

        const handlePageClick = ({ selected }) => {
            setCurrentPage(selected);
            handlePagePost(selected);
        };

        const handlePagePost = async (selected) => {
            setLoading(true);
            const postForm = {
                page: selected + 1,
                search: inputSearch,
            };

            if (dateRange[0] && dateRange[1]) {


                postForm.startDate = formatDate(dateRange[0]);
                postForm.endDate = formatDate(dateRange[1]);
            }

            try {

                const response = await postData(Apiservices.USER_BANK_REJECTED_HISTORY,postForm);

                if (response.status) {
                    setLoading(false);
                    setFormData(response.data.data);
                    setTotalPagination(response.data.totalPages);
                    setTotalLimit(response.data.limit)
                    setCount(response.data.currentCount)

                } else {
                    setLoading(false);
                    toast.error(response.message);
                }

            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
                setLoading(false);
            }
        };
        const handleShowModel = (value) => {
            setShowDetails(value)
            setShowModel(true)
        }

        const allowedActions = [1,2,3,4];
        const columns = [
            {
                name: 'S.No',
                selector: (row, index) => currentPage * itemsPerPage + index + 1,
                sortable: true,
                minWidth: '80px'
            },
            {
                name: 'Date',
                selector: row => row.createdAt
                    ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${(utcToNormalTime(row.createdAt).time)}`
                    : "---",
                sortable: true,
                minWidth: '200px'
            },
            {
                name: 'Email',
                selector: row => 
                    <div className="d-flex align-items-center" >
                <Link className="link-tag" to={`/allUsers/userDetails/${row?.userDetails?.acId}`} target='_blank'>
                  <span className="mx-1 text-decoration-underline">
                    {row.userDetails?.email || '-'}
                  </span>
                </Link>
              </div>,
                sortable: true,
                minWidth:'300px'           
            },
            {
                name: 'Holder Name',
                selector: row => handleNullValue(row?.accountHolderName),
                sortable: true,
                minWidth: '150px'
            },
            {
                name: 'Account No',
                selector: row => handleNullValue(row?.accountNumber),
                sortable: true,
                minWidth: '150px'

            },
            {
                name: 'IFSC No',
                selector: row => handleNullValue(row?.ifscCode),
                sortable: true,
                minWidth: '150px'


            },
            {
                name: 'Account Type',
                selector: row => handleNullValue(row?.accountType),
                sortable: true,
                minWidth: '150px'

            },

            {
                name: 'Bank Name',
                selector: row => handleNullValue(row?.bankName),
                sortable: true,
                minWidth: '200px'
            },
            ...(adminType === 1 || adminInfo.permissions.some(data => 
                data.module === "Admin Rejected Bank" && allowedActions.some(action => data.actions.includes(action))) ? [
            {
                name: 'Action',
                cell: row => (
                    <div className="edit d-flex">
                        {(adminType === 1 || adminInfo.permissions.some(data => 
    data.module === "Admin Rejected Bank" && data.actions.includes(2))) ? (
                        <span id={`tooltipEmail${row.id}`} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></span>
    ): null}
                        <Tooltip placement="top" isOpen={tooltipOpen1} target={`tooltipEmail${row.id}`} toggle={toggleTooltip1}> View </Tooltip>


                    </div>
                ),
                ignoreRowClick: true,
                minWidth: '150px'
                // allowOverflow: true,
                // button: true,
            }]: []),






        ];



        const handleSearchChanage = (e) => {
            const value = e.target.value
            if (value == '') {
                ipList()
                setSearchValid(false)
            }
            setinputSearch(value)

        }


        const handleSearch = async () => {
            if (inputSearch !== '') {


                setSearchLoad(true);
                const postForm = {
                    search: inputSearch.trim(),
                    // page: currentPage + 1,
                    // limit: itemsPerPage,
                };

                try {

                    const response = await postData(Apiservices.USER_BANK_REJECTED_HISTORY,postForm);
                    if (response.status) {
                        setSearchLoad(false);
                        setFormData(response.data.data);
                        setTotalPagination(response.data.totalPages);
                        setTotalLimit(response.data.limit)
                        setCount(response.data.currentCount)

                    } else {
                        setSearchLoad(false);
                    }


                } catch (error) {
                    toast.error("Internal Server Error..Try Again Later");
                    setSearchLoad(false);
                }
            } else {
                setSearchValid(true)
            }
        }


        const formatDate = (date) => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        };


        const handleDateChange = ([start, end]) => {


            const formattedStart = start ? formatDate(start) : null;
            const formattedEnd = end ? formatDate(end) : null;

            setDateRange([start, end]);

            if (!formattedStart && !formattedEnd) {
                ipList();
                setDownloadEndDate({})
            }


            if (formattedStart && formattedEnd) {
                setDownloadEndDate({ startDate: formattedStart, endDate: formattedEnd })
                handleDate({ startDate: formattedStart, endDate: formattedEnd });
            }
        };
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSearch();
            }
        };

        const handleDate = async ({ startDate, endDate }) => {
            setLoading(true);
            const postForm = {
                startDate: startDate,
                endDate: endDate
            }
            try {

                const response = await postData(Apiservices.USER_BANK_REJECTED_HISTORY,postForm);
                if (response.status) {
                    setLoading(false);
                    setFormData(response.data.data);
                    setTotalPagination(response.data.totalPages);
                    setTotalLimit(response.data.limit)
                    setCount(response.data.currentCount)

                } else {
                    setLoading(false);
                    toast.error(response.message);
                }
            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
                setLoading(false);
            }
        };


        const handleDownload = async (type) => {

            setDownLoading(true)
            try {
                const postForm = {};


                if (downloadStartDate.startDate) {
                    postForm.startDate = downloadStartDate.startDate;
                }
                if (downloadStartDate.endDate) {
                    postForm.endDate = downloadStartDate.endDate;
                }
                if (inputSearch) {
                    postForm.search = inputSearch;
                }
                postForm.download = type;

                const response = await axiosInstance.post(Apiservices.USER_BANK_REJECTED_HISTORY,postForm, {
                    // Include any data you need to send with the POST request here
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    responseType: 'blob'
                });
                if (response.data) {
                    setDownLoading(false)
                    const blob = response.data;

                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    // link.setAttribute('download', `${user}.xlsx`);
                    link.setAttribute('download', `Admin Rejected Bank History.${type}`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    toast.error(response.data.message)
                    setDownLoading(false)

                }
            } catch (err) {
                setDownLoading(false)
                console.error('Error downloading the file', err);
            }
        };


        // const showStatusAlert = (onConfirm, row) => {
        //     Swal.fire({
        //         title: 'Are you sure?',
        //         text: "You want to update status!",
        //         icon: 'info',
        //         showCancelButton: true,
        //         confirmButtonColor: '#3085d6',
        //         cancelButtonColor: '#d33',
        //         confirmButtonText: 'Yes, Update it!'
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             onConfirm(row);
        //         }
        //     });
        // };
        const [rowsPerPage, setRowsPerPage] = useState(10);


        const handleRowsPerPageChange = (newRowsPerPage) => {
            setRowsPerPage(parseInt(newRowsPerPage));
            HandleClickRowperpage(newRowsPerPage)
          }
          const HandleClickRowperpage = async(newRowsPerPage) => {
            setLoading(true);
            const postForm = {
              page:1,
              limit:Number(newRowsPerPage) ,
            };
          
          
            try {
              if (formData === undefined) {
                const response = await postData(Apiservices.USER_BANK_REJECTED_HISTORY,postForm);
          
                if (response.status) {
                  setLoading(false);
                  setFormData(response.data.data);
                  setCount(response.data.currentCount)
                          setTotalPagination(response.data.totalPages);
                          setTotalLimit(response.data.limit)
                } else {
                  setLoading(false);
                }
              } else {
                const response = await postData(Apiservices.USER_BANK_REJECTED_HISTORY,postForm);
          
          
                if (response.status) {
                  setLoading(false);
                  setFormData(response.data.data);
                  setCount(response.data.currentCount)
                  setTotalPagination(response.data.totalPages);
                  setTotalLimit(response.data.limit)
                } else {
                  setLoading(false);
                  toast.error(response.message);
                }
              }
            } catch (error) {
             
              toast.error("Internal Server Error..Try Again Later");
              setLoading(false);
            }
          
          };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Bank Details" breadcrumbItem="Admin Rejected Bank" />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3">
                                            <Col sm={4} lg={3}>
                                                <div className="input-group">
                                                    <Input
                                                        // onChange={handleInputChange}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search ..."
                                                        aria-label="Recipient's username"
                                                        onChange={handleSearchChanage}
                                                        invalid={searchValid}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                    <div className="input-group-append">

                                                        <button className="btn btn-primary" type="submit"
                                                            onClick={() => handleSearch()} disabled={searchLoad}
                                                        >
                                                            {searchLoad ? (

                                                                <Spinner className="text-light" size="sm" />
                                                            ) : (

                                                                <i className="ri-search-line" />
                                                            )}

                                                        </button>


                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm={4} lg={3}>
                                                <div className="form-group mb-4">

                                                    <div>
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="Select Start to End Date"
                                                                options={{
                                                                    mode: 'range',
                                                                    altInput: true,
                                                                    altFormat: "F j, Y",
                                                                    dateFormat: "Y-m-d",
                                                                    maxDate: today,

                                                                }}
                                                                value={dateRange}
                                                                onChange={handleDateChange}
                                                            />
                                                        </InputGroup>
                                                    </div>

                                                </div>
                                            </Col>

                                            <Col className="col-sm">

                                                <div className="d-flex justify-content-sm-end">

                                                <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Button color="info" className="add-btn mx-2" onClick={() => handleDownload("xlsx")} id="create-btn" disabled={downLoading}>
                                                    <i className="mdi mdi-download-outline"></i>

                                                    {downLoading ? <Loader /> : "Download"}
                                                    </Button>
                                                </div>
                                                </Col>
                                                </div>
                                            </Col>
                                        </Row>


                                        <div className="table-responsive">
                                            <DataTable
                                                columns={columns}
                                                data={formData}
                                                paginationServer
                                                paginationTotalRows={formData.length}
                                                progressPending={loading}
                                                persistTableHead={true}
                                                progressComponent={<div className="py-3">Loading...</div>}
                                                // noDataComponent={formData.length===0}
                                                noDataComponent={<div className="py-3">No records found</div>}
                                                fixedHeader={true}

                                            />
                                        </div>

                                      
                                        {loading || formData.length == 0 ? (
                                            ""

                                        ) : (

                                            <div className="container">
                                            <div className="row justify-content-end mt-4">
                                            <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                                                <div className="d-flex align-items-center me-3">
                                                  <span className="me-2">Rows per page:</span>
                                                  <select
                                                    value={rowsPerPage}
                                                    onChange={(e) => handleRowsPerPageChange(e.target.value)}
                                                    className="form-control-sm"
                                                  >
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={30}>30</option>
                                                    <option value={50}>50</option>
                                                  </select>
                                                </div>
                                                <div className="me-md-3 mb-3 mb-md-0">
                                                  <span>{count}</span>
                                                </div>
                                                <div className="pagination pagination-rounded mt-3">
                                                  <ReactPaginate
                                                    previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                    nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    activeClassName={"active"}
                                                    pageLinkClassName={"page-link"}
                                                    breakLinkClassName={"break-link"}
                                                    disabledClassName={"disabled"}
                                                    initialPage={currentPage}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                    renderLink={(props) => {
                                                      if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                                        return (
                                                          <a
                                                            {...props}
                                                            onClick={() =>
                                                              handlePageClick({
                                                                selected: pageCount - 1,
                                                              })
                                                            }
                                                          >
                                                            {pageCount}
                                                          </a>
                                                        );
                                                      }
                                                      return <a {...props} />;
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>



                <Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static">
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                            User Bank Account View
                        </h5>
                        <button
                            onClick={() => {
                                setShowModel(false);
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body custom-modal-body">
                        <div className="table-responsive">
                            <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td><b>Email</b></td>
                                        <td>{showDetails.userDetails?.email || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Holder Name</b></td>
                                        <td>{showDetails.accountHolderName || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Account Number</b></td>
                                        <td>{showDetails.accountNumber}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Bank Name</b></td>
                                        <td>{showDetails.bankName}</td>
                                    </tr>
                                    <tr>
                                        <td><b>IFSC Code</b></td>
                                        <td>{showDetails.ifscCode}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Account Type</b></td>
                                        <td>{showDetails.accountType || '-'}</td>
                                    </tr>
                                   <tr>
                                    <td><b>Status</b></td>
                                    <td>
                                        {showDetails.status === 0 
                                        ? 'Pending'  : showDetails.status === 1  ? 'Approved' 
                                        : showDetails.status === 2  ? 'Rejected'  : showDetails.status === 3 ? 'Cancelled' 
                                        : '-'}
                                    </td>
                                    </tr>
                                    <tr>
                                        <td><b>Branch Name</b></td>
                                        <td>{showDetails.branchName || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Reason</b></td>
                                        <td>{showDetails.reason}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Rejected By</b></td>
                                        <td>{showDetails.adminEmail} ({showDetails.adminName || '-'})</td>
                                    </tr>

                                    <tr>

                                        <td><b>Request Date & Time</b></td>
                                        <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Rejected Date & Time</b></td>
                                        <td>{utcToNormalTime(showDetails.updatedAt).date} {utcToNormalTime(showDetails.updatedAt).time}</td>
                                    </tr>
                                    <tr>
                                    <td><b>Bank Proof</b></td>
                                    <td>
                                        <img src={showDetails.bankProof || '-'} alt='' style={{ width: '50%', objectFit: 'cover' }}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal>
                <Modal size="l" className="modal-dialog modal-lg px-4"  isOpen={showPreview} 
                    toggle={() => { previewModel(); }} centered backdrop="static" >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
                         Bank Proof
                        </h5>
                        <button
                          onClick={() => { SetPreview(false);}}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <img src={showDetails.bankProof || '-'} alt='' style={{ width: '100%', objectFit: 'cover' }}/>

                         
                      </div>
                    </Modal>

            </React.Fragment>
        );
    };

export default UserBankRejected;

import React, { useState, useEffect } from "react";
import { Button, Input, Card, CardBody, FormFeedback, Col, Container, FormGroup, Label, Row, Form } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";

const FlexibleStakePlan = () => {

    document.title = "Ultrapro | Stake Offers";
    const [loading, setLoading] = useState(false);
  
    const formik = useFormik({
      initialValues: {
     
      plan: [
        { days: '', amount: '', percentage: '', status: '' }
      ],
      },
      validationSchema: Yup.object({
   
        plan: Yup.array().of(
          Yup.object().shape({
            days: Yup.string().trim().required(" plan days are Required"),
            amount: Yup.string().trim().required(" Plan Amount is Required"),
            percentage: Yup.string().trim().required(" plan  Percentage is  Required"),
            status: Yup.boolean().required("plan Status is Required"),
          })
        )
  
      }),
      validateOnChange: false,
      onSubmit: async (values) => {
        handleAdd(values);
      },
    });

    const handleAdd = async (values) => {
        setLoading(true);
        const data = {
          stakeName: values.stakename,
          duration: values.duration,
          minstake: values.minstakeamount,
          maxstake: values.maxstakeamount,
          description: values.description,
          isActive: values.isActive,
          content: values.content,
          currencyName: values.currencyName,
          stakeType:values.stakeType,
          position:values.position
        } 
      
        try {
         
          const response = await postData('https://jsonplaceholder.typicode.com/posts', data);
      
          if (response.status) {
            toast.success(response.message);
            formik.resetForm();
            setLoading(false);
            
          } else {
            toast.error(response.message);
            formik.resetForm();
            setLoading(false)
          }
        } 
        catch (error) {
          // toast.error(error);
          setLoading(false);
          formik.resetForm();
          setLoading(false);
        }
      }
   

    const addPlanRow = () => {
        formik.setValues({
          ...formik.values,
          plan: [...formik.values.plan, { days: '', amount: '', percentage: '', status: '' }]
        });
      };
      
      const removePlanRow = (index) => {
        const updatedplan = formik.values.plan.filter((_, i) => i !== index);
        formik.setValues({ ...formik.values, plan: updatedplan});
      };

    return(
        <React.Fragment>
            <div className="page-content">
      <Container fluid={true}>
        <Breadcrumbs title="Flexible Stack Plan" breadcrumbItem="Create Flexible Staking Plan " />
        <Row>
          <Col lg={12} className="row">
           <Form onSubmit={formik.handleSubmit}>
           <Card>
           <CardBody>
           <Row>
                  {formik.values.plan.map((row, index) => (
        <Row key={index} className="mb-3 align-items-center">
         <Row> <Col md={6}>
            <Input
              type="number"
              name={`plan[${index}].days`} // Dynamically set name for each item
              id={`days-${index}`}
              className="form-control"
              placeholder="Enter plan days"
              value={formik.values.plan[index].days}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="off"
              invalid={
                formik.touched.plan?.[index]?.days &&
                !!formik.errors.plan?.[index]?.days
              }
            />
             <FormFeedback>
                {formik.errors.plan?.[index]?.days}
              </FormFeedback>
          </Col>
          <Col md={6}>
          <Input
              type="number"
              name={`plan[${index}].amount`} // Dynamically set name for each item
              id={`amount-${index}`}
              className="form-control"
              placeholder="Enter plan amount"
              value={formik.values.plan[index].amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="off"
              invalid={
                formik.touched.plan?.[index]?.amount &&
                !!formik.errors.plan?.[index]?.amount
              }
            />
             <FormFeedback>
                {formik.errors.plan?.[index]?.amount}
              </FormFeedback>
          </Col></Row>
          <Row className="mt-5">
          <Col md={6}>
            <Input
             type="number"
             name={`plan[${index}].percentage`} // Dynamically set name for each item
             id={`percentage-${index}`}
             className="form-control"
             placeholder="Enter Percentage"
             value={formik.values.plan[index].percentage}
             onChange={formik.handleChange}
             onBlur={formik.handleBlur}
             autoComplete="off"
             invalid={
               formik.touched.plan?.[index]?.percentage &&
               !!formik.errors.plan?.[index]?.percentage
             }
            />
             <FormFeedback>
                {formik.errors.plan?.[index]?.percentage}
              </FormFeedback>
          </Col>
          <Col md={5}>
            <Input
              type="select"
              name={`plan[${index}].status`}
              value={row.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.plan?.[index]?.status && Boolean(formik.errors.plan?.[index]?.status)}
            >
              <option value="" disabled>
                              Select Status
                            </option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
            {formik.touched.plan?.[index]?.status && formik.errors.plan?.[index]?.status && (
              <FormFeedback>{formik.errors.plan[index].status}</FormFeedback>
            )}
          </Col>
          <Col sm={1} className="d-flex align-items-center">
            {index > 0 && (
              <i className="ri-delete-bin-line" onClick={() => removePlanRow(index)} style={{ cursor: 'pointer' }}></i>
            )}
          </Col>
          </Row>

        </Row>
        
      ))}
      <Col sm={2}>
          <Button color="success" onClick={addPlanRow}>
            <i className="ri-add-line align-bottom me-1"></i> Add Plan
          </Button>
        </Col>
                  </Row>
  
                  <div className="text-end">
                    {loading ? (
                      <Button color="primary">Loading...</Button>
                    ) : (
                      <Button color="primary" type="submit">
                        Submit
                      </Button>
                    )}</div>
                    </CardBody></Card>
           </Form>
           </Col>
           </Row>
           </Container>
           </div>
        </React.Fragment>
    )
};
export default FlexibleStakePlan;
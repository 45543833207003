import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import { handleNullValue, utcToNormalTime } from "../../../helpers/function";
import { postData } from "../../../ApiService/AxiosInstance";
import { Apiservices } from "../../../ApiService/ApiServices";

const UserRefferalUser = ({ id }) => {
  document.title = "Ultrapro | User Management";

  const [getReferralUser, setReferralUser] = useState([]);
  const [refLoad, setRefLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 10;
  
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * rowsPerPage;
  const currentData = getReferralUser.length > 0 && getReferralUser[0].referredUsers 
    ? getReferralUser[0].referredUsers.slice(offset, offset + rowsPerPage)
    : [];

  const totalRows = getReferralUser.length > 0 && getReferralUser[0].referredUsers
    ? getReferralUser[0].referredUsers.length
    : 0;

    const FetchRefferalDetails = useCallback(async () => {
      setRefLoad(true);
      try {
        const data = {
          acId: Number(id),
        };
        const response = await postData(Apiservices.getUserReferralUserList, data);
    
        if (response.status === true) {
          setReferralUser(response.data.data);
          setRefLoad(false);
        } else {
          setRefLoad(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setRefLoad(false);
      }
    }, [id]); 
    
    useEffect(() => {
      FetchRefferalDetails();
    }, [FetchRefferalDetails])

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => offset + index + 1,
      sortable: true,
      grow: 1,
    },
    {
      name: "Name",
      selector: (row) => handleNullValue(row.fullName),
      sortable: true,
      grow: 2,
    },
    {
      name: "Registered Mail ID",
      selector: (row) => handleNullValue(row.email),
      sortable: true,
      grow:3,
    },
    {
      name: "Status",
      selector: (row) => handleNullValue(row.isActive),
      sortable: true,
      grow: 3,
    },
    {
      name: "Date & Time",
      selector: (row) =>
        row.createdAt
          ? `${utcToNormalTime(row.createdAt).date} ${utcToNormalTime(row.createdAt).time}`
          : "---",
      sortable: true,
      grow: 2,
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col sm="12" className="d-lg-flex">
          <Card className="col-lg-12">
            <CardBody>
              <Row className="mb-2">
                <Col md={8}>
                  <h4 className="card-title">Referral Users</h4>
                </Col>
              </Row>
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={currentData}
                  paginationServer
                  paginationTotalRows={totalRows}
                  progressPending={refLoad}
                  persistTableHead={true}
                  progressComponent={<div className="py-3">Loading...</div>}
                  noDataComponent={<div className="py-3">No records found</div>}
                  fixedHeader={true}
                />
                {totalRows > 0 && (
                  <div className="d-flex justify-content-end mt-3">
                    <div className="pagination pagination-rounded mb-0">
                      <ReactPaginate
                        forcePage={currentPage}
                        previousLabel={<i className="mdi mdi-chevron-left"></i>}
                        nextLabel={<i className="mdi mdi-chevron-right"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        pageLinkClassName={"page-link"}
                        breakLinkClassName={"break-link"}
                        disabledClassName={"disabled"}
                        pageCount={Math.ceil(totalRows / rowsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserRefferalUser;

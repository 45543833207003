import React, { useState } from "react";

const Navdata = () => {
  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);

  const [component, setcomponent] = useState(false);

  const [pages, setpages] = useState(false);


  const NavnavData = [
    {
      id: 1,
      label: "Dashboard",
      icon: "mdi mdi-home-variant-outline me-2",
      isdropDown: true,
      click: function () {
        setui(false);
        setapp(false);
        setcomponent(false);
        setpages(false);
      },
    },
    {
      id: 2,
      label: "",
      icon: "mdi mdi-briefcase-variant-outline me-2",
      click: function () {
        setui(!ui);
        setapp(false);
        setcomponent(false);
        setpages(false);
      },
      currentState: ui,
      subItem: [
        { link: "/ui-alerts", title: "Alerts" },
        { link: "/ui-badge", title: "Badge" },
        { link: "/ui-breadcrumb", title: "Breadcrumb" },
        { link: "/ui-buttons", title: "Buttons" },
       
      ],
    },

   
   
  ];
  return <React.Fragment>{NavnavData}</React.Fragment>;
};

export default Navdata;

import DataTable from 'react-data-table-component';
import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback, Col, Spinner,Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form } from "reactstrap";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { AdminContext } from '../../helpers/adminContext';
import Swal from 'sweetalert2';

const TradeManagement = () => {
    const navigate = useNavigate()
    document.title = "Ultrapro | Trade Management";
    const [loading, setLoading] = useState(false)
    const [deleteLoad, setDeleteLoad] = useState(false)
    const [recordToUpdate, setRecordToUpdate] = useState({});
    const [formData, setFormData] = useState([])
    //Permission List
    const { adminInfo } = useContext(AdminContext);
    const [adminType,setAdminType] = useState('')
    const [position,setPosition] = useState('')
    useEffect(() => {
        // tradePair()
        fetchData()
        setAdminType(adminInfo?.adminType)
    }, [])
   
    const fetchData = async () => {
        try {
          setLoading(true);
          const response = await postData(Apiservices.USER_TRADE_PAIR_LIST);
          if (response.status) {
            setLoading(false);
            setTotalPagination(response.data.totalPages);
            setFormData(response.data.data);
            setTotalLimit(response.data.limit);
            setCount(response.data.currentCount);
          } else {
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
         
        }
      };

    const validationAdd = useFormik({
        enableReinitialize: true,

        initialValues: {
            pairName: '',
            fromCurrency: '',
            toCurrency: '',
            streamPair: '',
            inrPrice: '',
            isActive: '',
            priceDecimal: '',
            amountDecimal: '',
            liqudity: '',
            proUsers: '',
            minTradeAmount:'',
            maxTradeAmount:'',
            takerFee:'',
            makerFee:'',
            feeType:'',
            // position:'',
            lastPrice:'',
            close:'',
            high:'',
            low:"",
            changePercentage:"",
            open:'',
            amountVolume:'',
            priceVolume:'',
            trendingPair: '',
            TopPair: '',
            uprousdttype:'',
            // uprousdttypevalue:'',
            UproUsdtPriceExchange:''
            


        },
        validationSchema: Yup.object({
            pairName: Yup.string().required("pairName is required"),
            // fromCurrency: Yup.string().required("From Currency is required"),
            // toCurrency: Yup.string().required("To Currency is required"),
            fromCurrency: Yup.string()
            .required("From Currency is required"),
            toCurrency: Yup.string()
            .required("To Currency is required")
            .test('not-same', 'From Currency and To Currency cannot be the same', function (value) {
            return value !== this.parent.fromCurrency;
            }),
            streamPair: Yup.string().required("Stream Pair is required"),
            inrPrice: Yup.string(),
            isActive: Yup.string().required("Active Status is required"),
            priceDecimal: Yup.string()
            .matches(/^(0|[1-9]\d*(\.\d+)?|\d*\.\d+)$/, "Please enter a valid decimal value")
            .required("Price Decimal is required"),
            amountDecimal: Yup.string()
            .required("Amount Decimal is required"),
            liqudity: Yup.string().required("Trade Type is required"),
            proUsers: Yup.string().required("Pro Users is required"),
            minTradeAmount: Yup.number()
            .typeError("Minimum Trade Amount must be a number")
            .required("Minimum Trade Amount is required")
            .positive("Minimum Trade Amount must be a positive number")
            .moreThan(0, "Taker Fee must be greater than zero"),
            maxTradeAmount: Yup.number()
            .typeError("Maximum Trade Amount must be a number")
            .required("Maximum Trade Amount is required")
            .positive("Maximum Trade Amount must be a positive number")
            .moreThan(0, "Taker Fee must be greater than zero"),
            makerFee: Yup.string()
            .required("Maker Fee is required"),
            takerFee: Yup.string()
            .required("Taker Fee is required"),
            // feeType: Yup.string().required("Fee Type is required"),
            // position: Yup.string().required("Position is required"),

            lastPrice: Yup.number()
            .typeError("LastPrice must be a number")
            .required("LastPrice is required")
            .positive("LastPrice must be a positive number")
            .moreThan(0, "LastPrice must be greater than zero"), 
            
            close:Yup.string().required("Close is required"),
            high:Yup.string().required("High is required"),
            low:Yup.string().required("Low is required"),
            changePercentage:Yup.string().required("Change Perccentage is required"),
            open:Yup.string().required("Open is required"),
            amountVolume:Yup.string().required("Amount Volume is required"),
            priceVolume:Yup.string().required("Price volume is required"),
            trendingPair:Yup.string().required("Trending Pair is required"),
            TopPair:Yup.string().required("Top Pair is required"),
            // uprousdttype: Yup.string().required("UPRO USDT Type is required"),
            // // uprousdttypevalue: Yup.string().required("UPRO USDT Type Value is required"),
            // UproUsdtPriceExchange: Yup.string().required("UPRO USDT Price Exchange is required"),
            uprousdttype: Yup.string()
            .nullable()
            .when('streamPair', {
              is: (value) => value === 'uprousdt' || value === 'oneusdt',
              then: (schema) => schema.required("UPRO USDT Type is required"),
              otherwise: (schema) => schema.notRequired(),
            }),
        
          UproUsdtPriceExchange: Yup.string()
            .nullable()
            .when('streamPair', {
              is: (value) => value === 'uprousdt' || value === 'oneusdt',
              then: (schema) => schema.required("UPRO USDT Price Exchange is required"),
              otherwise: (schema) => schema.notRequired(),
            }),
        }),
        onSubmit: (values) => {
            handleUpdateClick(values,'submit')
            // handleAdd(values)
        },
    });



   const  handleChangePosition = (e) =>{
    setPosition(e.target.value)
   }


    const handleChange = (e) => {
        const { name, value } = e.target;
        // Allow only letters and convert to uppercase
        const filteredValue = value.toUpperCase();
    
        validationAdd.setFieldValue(name, filteredValue);
    
        let { fromCurrency, toCurrency } = validationAdd.values;
    
        if (name === 'fromCurrency') {
          fromCurrency = filteredValue;
        }
        if (name === 'toCurrency') {
          toCurrency = filteredValue;
        }
    
        if (fromCurrency || toCurrency) {
          validationAdd.setFieldValue('pairName', `${fromCurrency}_${toCurrency}`);
          validationAdd.setFieldValue('streamPair', `${fromCurrency.toLowerCase()}${toCurrency.toLowerCase()}`);
        }
      };


      const [FeeType,setFeeType] = useState('')

      const handleFeeType = (e) => {
          setFeeType(e.target.value)
      };
    const handleAdd = async (data, field, reason) => {
        const postForm = {
            pair: data.pairName,
            fromCurrency: data.fromCurrency,
            toCurrency: data.toCurrency,
            streamPair: data.streamPair,
            inrPrice: data.inrPrice,
            isActive: Number(data.isActive),
            amountDecimal: Number(data.amountDecimal),
            priceDecimal: Number(data.priceDecimal),
            liqudity: Number(data.liqudity),
            proUsers: Number(data.proUsers),
            minTradeAmount:data.minTradeAmount,
            maxTradeAmount: data.maxTradeAmount,
            takerFee:data.takerFee,
            makerFee:data.makerFee,
            tradeFeeType:0,
            position:position,
            lastPrice:data.lastPrice,
            close:data.close,
            high:data.high,
            low:data.low,
            changePercentage:data.changePercentage,
            open:data.open,
            amountVolume:data.amountVolume,
            priceVolume:Number(data.priceVolume),
            trendingPair:data.trendingPair,
            TopPair:data.TopPair,
            uprousdttype:data.uprousdttype,
            // uprousdttypevalue:data.uprousdttypevalue,
            uprousdtpricechange:data.UproUsdtPriceExchange,
            updatedReason:reason

        }
        setLoading(true)

        try {
            let response;
            console.log(Object.keys(recordToUpdate).length === 0,"11111111111111111111111")
            if (Object.keys(recordToUpdate).length === 0) {
                response = await postData(Apiservices.USER_TRADE_ADD, postForm)
            } else {
                response = await postData(Apiservices.USER_TRADE_EDIT_LIST, postForm)
            }
            if (response.status) {
                fetchData()
                setLoading(false)
                handlePagePost(currentPage)
                toast.success(response.message)
                setmodal_update_list(false)
                validationAdd.resetForm();
                setPosition('')

            } else {
                setLoading(false)
                toast.error(response.message)
            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later")
            setLoading(false)
        }

    }



    const [modal_update_list, setmodal_update_list] = useState(false);
    function tog_update_list() {
        setmodal_update_list(!modal_update_list);
        if (modal_update_list) {
            resetFormValues();
            setRecordToUpdate({})
            clearErrors();
        }

    }
    function clearErrors() {
        Object.keys(validationAdd.errors).forEach((key) => {
            validationAdd.setFieldError(key, null);
        });
    }
    
    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }


    const [modelCount, setmodalCount] = useState(false);
    const [resend, setResend] = useState({
        row: {},
        userStatus: null
    });

    // const handleToggleModel = (row, action) => {
    //     setResend({ row: row, userStatus: action });
    //     setmodalCount(true);
    // };
    const handleUpdateStatusClick = (row, field) => {
        showStatusAlert(handleToggle, row, field);
    };
    const handleUpdateClick = (row, field) => {
        showStatusAlert(handleAdd, row, field);
    };
    const showStatusAlert = (onConfirm, row, field) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to change the status of this user?",
            icon: 'info',
            showCancelButton: true,
            inputLabel: "Enter a Reason",
            input: "textarea",
            inputAttributes: {
                autocapitalize: "off",
                autocomplete: "off"
            },
            inputPlaceholder: "Enter Reason",
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update it!',
            showLoaderOnConfirm: true,
            preConfirm: async (reason) => {
                if (!reason) {
                    Swal.showValidationMessage('Reason is required');
                } else {
                    Swal.showLoading();
                    try {
                        await onConfirm(row, field, reason); // Pass row, field, and reason to handleToggle
                    } catch (error) {
                        // Handle error if needed
                    }
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                // Handle confirmation if necessary
            }
        });
    };
    
    const handleToggle = async (row, field, reason) => {
        console.log(row,"88888888888888888")
        const updatedStatus = row[field] === 1 ? 0 : 1;
        const postForm = {

            pair: row.pairName,
            fromCurrency: row.fromCurrency,
            toCurrency: row.toCurrency,
            streamPair: row.streamPair,
            inrPrice: row.inrPrice,
            amountDecimal: row.amountDecimal,
            priceDecimal: row.priceDecimal,
            minimalAmount:row.minTradeAmount,
            maxTradeAmount:row.maxTradeAmount,
            takerFee:row.takerFee,
            makerFee:row.makerFee,
            tradeFeeType:0,
            position:row.position,
            lastPrice:row.lastPrice,
            liqudity: row.liqudity,
            close:row.close,
            high:row.high,
            low:row.low,
            changePercentage:row.changePercentage,
            open:row.open,
            amountVolume:row.amountVolume,
            trendingPair:row.trendingPair,
            TopPair:row.TopPair,
            priceVolume:row.priceVolume,
            uprousdttype:row.uprousdttype,
            // uprousdttypevalue:row.uprousdttype,
            uprousdtpricechange:row.uprousdtpricechange
            
        };
    
        if (field === 'liqudity') {
            postForm.liqudity = updatedStatus;
            postForm.liqudityReason = reason; // Reason for liquidity
        } else if (field === 'proUsers') {
            postForm.proUsers = updatedStatus;
            postForm.proUserReason = reason; // Reason for pro users
        } else if (field === 'isActive') {
            postForm.isActive = updatedStatus;
            postForm.tradeReason = reason; // Reason for trade status
        }
    
    
        setLoading(true);
        try {
            const response = await postData(Apiservices.USER_TRADE_EDIT_LIST, postForm);
            if (response.status) {
                toast.dismiss();
                setLoading(false);
                toast.success(response.message);
                fetchData(); // Refresh data
                setmodalCount(false);
                handlePagePost(currentPage)

            } else {
                toast.error(response.message);
                setLoading(false);
                setmodalCount(false);
                handlePagePost(currentPage)
            }
        } catch (error) {
            toast.dismiss();
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };
    

    const handleUpdate = (record) => {
  console.log(record,"33333333333333")

    setPosition(record.position)
    setFeeType(record.tradeFeeType)
        validationAdd.setValues({
            pairName:record.pair,
            fromCurrency:record.fromCurrency,
            toCurrency:record.toCurrency,
            minTradeAmount:record.minTradeAmount,
            maxTradeAmount:record.maxTradeAmount,
            streamPair:record.streamPair,
            inrPrice:record.inrPrice,
            isActive: record.isActive !== undefined ? String(record.isActive) : '',
            priceDecimal:record.priceDecimal !== undefined ? String(record.priceDecimal) : '',
            amountDecimal:record.amountDecimal !== undefined ? String(record.amountDecimal) : '',
            liqudity: record.liqudity !== undefined ? String(record.liqudity) : '',
            proUsers: record.proUsers !== undefined ? String(record.proUsers) : '',
            takerFee: record.takerFee, // Ensure zero is set correctly
            makerFee: record.makerFee,
            lastPrice:record.lastPrice,
            close:record.close,
            high:record.high,
            low:record.low,
            changePercentage:record.changePercentage,
            open:record.open,
            amountVolume:record.amountVolume,
            priceVolume:record.priceVolume,
            trendingPair:record.trendingPair,
            TopPair:record.TopPair,
            uprousdttype: record.uprousdttype !== undefined ? String(record.uprousdttype) : '',
            // uprousdttypevalue:record.uprousdttype,
            UproUsdtPriceExchange:record.uprousdtpricechange
            
            // position:record.position
        });
        setRecordToUpdate(record);
        setmodal_update_list(true)
    }
    const Notes = (row) =>{
        navigate(`/tradenotes/${row.streamPair}`);

    }
      const handleDeleteClick = (row) => {
        showDeleteAlert(handleDelete1,row);
      };
      const showDeleteAlert = (onConfirm,row) => {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to recover this once it's deleted!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            onConfirm(row);
        
          }
        });
      };
      const handleDelete1 = async (rowDetails) => {
        const postForm = {
            _id: rowDetails._id,
        };
        setLoading(true);
        try {
          const response = await postData(
            Apiservices.DeleteTradePair,
            postForm
          );
          if (response.status) {
            setLoading(false);
            toast.success(response.message);
             Swal.fire(
              'Deleted !',
              'The requested record has been deleted',
              'success'
            );
            fetchData();
            handlePagePost(currentPage)
          } else {
            toast.error(response.message);
            setLoading(false);
            handlePagePost(currentPage)

          }
        } catch (error) {
          setLoading(false);
          toast.error("Internal Server Error..Try Again Later");
        }
      };
    const allowedActions = [1,2,3,4];
    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true,
            minWidth:'100px'
        },
        {
            name: 'Pair Name',
            selector: row => row.pair.replace(/_/g, '/'),
            minWidth:'150px',
            sortable: true
        },
        // {
        //     name: 'Trade Type',
        //     selector: row => row.tradeType === 0 ? ('Liqudity') : ('Non-Liqudity'),
        //     minWidth:'150px',
        //     sortable: true
        // },
        {
            name: 'Trade Type',
            selector: row => row.liqudity === 1 ? ('Liqudity') : ('Non-Liqudity'),
            minWidth:'150px',
            sortable: true
        },
        {
            name: 'Stream Pair',
            selector: row => row.streamPair,
            minWidth:'150px',
            sortable: true
        },
        {
            name: 'Last Price',
            selector: row => row.lastPrice,
            minWidth:'150px',
            sortable: true
        },
        {
            name: 'Position',
            selector: row => row.position,
            minWidth:'150px',
            sortable: true
        },
        ...(adminType === 1 || adminInfo?.permissions.some(data => 
            data.module === "Trade Management" && data.actions.includes(3)) ? [
        {
            name: 'Liqudity Action',
            // selector: row => row.isActive ? 'Active' : 'Inactive',
            selector: row => (
                <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                        data.module === "Trade Management" && data.actions.includes(3))) ? (
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizemd"
                        checked={row.liqudity === 1}
                        onChange={(e) => handleUpdateStatusClick(row, 'liqudity')}

                        disabled={loading}
                    />
            ): null }
                </div>
            ),

            minWidth:'150px',
            sortable: true
        }]:[]),
        ...(adminType === 1 || adminInfo?.permissions.some(data => 
            data.module === "Trade Management" && data.actions.includes(3)) ? [
        {
            name: 'Pro User',
            // selector: row => row.isActive ? 'Active' : 'Inactive',
            selector: row => (
                <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                     data.module === "Trade Management" && data.actions.includes(3))) ? (
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizemd"
                        checked={row.proUsers === 1}
                        // onChange={(e) => handleToggleModel(row,'')}
                        onChange={() => handleUpdateStatusClick(row, 'proUsers')}
                        disabled={loading}
                    />
                    ): null }
                </div>
            ),
            minWidth:'100px',

            sortable: true
        }]:[]),
        ...(adminType === 1 || adminInfo?.permissions.some(data => 
            data.module === "Trade Management" && data.actions.includes(3)) ? [
        {
            name: 'Status',
            // selector: row => row.isActive ? 'Active' : 'Inactive',
            selector: row => (
                <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizemd"
                        checked={row.isActive === 1}
                        onChange={(e) => handleUpdateStatusClick(row, 'isActive')}
                        // onChange={() => handleToggle(row, 'withdrawStatus')}
                        disabled={loading}
                    />

                </div>
            ),
            minWidth:'100px',

            sortable: true
        }]:[]),
        ...(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Trade Management" && allowedActions.some(action => data.actions.includes(action))) ? [
        {
            name: "Action",
            cell: (row) => (
                <div className="edit">
                {(adminType === 1 || adminInfo?.permissions.some(data => 
                data.module === "Trade Management" && data.actions.includes(3))) ? (
                    <i
                        style={{ cursor: "pointer" }}
                        className="mdi mdi-border-color cursor-pointer"
                      onClick={() => handleUpdate(row)}
                    ></i>
                ): null}
                
                  {(adminType === 1 || adminInfo.permissions.some(data => 
                 data.module === "Trade Management" && data.actions.includes(2))) ? (
                  <i style={{ fontSize: '15px', cursor: "pointer" }} 
                  className="mdi mdi-history cursor-pointer" onClick={() => Notes(row)}></i> ): null }

                {(adminType === 1 || adminInfo?.permissions.some(data => 
                data.module === "Trade Management" && data.actions.includes(4))) ? (
                    <i
                        style={{ cursor: "pointer", marginLeft: "15px" }}
                        className="mdi mdi-trash-can-outline"
                        onClick={() => handleDeleteClick(row)}
                    ></i>
                ): null}
                </div>
            ),
            ignoreRowClick: true,
            minWidth:'100px',
        }]:[]),
    ];

    // const handleSelect = (currency) => {
    //     setSelectedCurrency(currency);
    //     const newFilteredData = selectedData.filter(data => data.toCurrency === currency);
    //     setFormData(newFilteredData);
    //     setbtnauto3(false); 
    // };


    const resetFormValues = () => {
        validationAdd.setValues({
            pairName: '',
            fromCurrency: '',
            toCurrency: '',
            streamPair: '',
            inrPrice: '',
            isActive: '',
            priceDecimal: '',
            amountDecimal: '',
            liqudity: '',
            proUsers: '',
            takerFee:'',
            makerFee:'',
            position:'',
            feeType:'',
            lastPrice:'',
            close:'',
            high:'',
            low:"",
            changePercentage:"",
            open:'',
            amountVolume:'',
            priceVolume:'',
            trendingPair:'',
            TopPair:'',
            uprousdttype:'',
            // uprousdttypevalue:'',
            UproUsdtPriceExchange:''

            
        });
        setPosition('')
    };

 
    //paginnation
    const [totalPagination, setTotalPagination] = useState(1);
    const [totalLimit, setTotalLimit] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = totalLimit; // Number of items to display per page
    const pageCount = Math.ceil(totalPagination);
  
    const handlePageClick = ({ selected }) => {
      setCurrentPage(selected);
      handlePagePost(selected);
    };
    const handlePagePost = async (selected) => {
      setLoading(true);
      const postForm = {
        page: selected + 1,
        limit: itemsPerPage,
        // search: inputSearch,
      };
      try {
        const response = await postData(Apiservices.USER_TRADE_PAIR_LIST,postForm);
        console.log(response,"777777777777777777777777777")
        if (response.status) {
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
  
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    };
     //Searchfun
  const [inputSearch, setinputSearch] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "") {
      fetchData();
      setSearchValid(false);
    } 
    else if(value === 'Liqudity'){
        setinputSearch('0');
    }
    else if(value === 'Non-Liqudity'){
        setinputSearch('1');
    }
    else {
      setinputSearch(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
      };
      try {
        const response = await postData(Apiservices.USER_TRADE_PAIR_LIST,postForm);
        if (response.status) {
          setSearchLoad(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
     
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };

const [rowsPerPage, setRowsPerPage] = useState(10);
const [count, setCount] = useState("");

const handleRowsPerPageChange = (newRowsPerPage) => {
  setRowsPerPage(parseInt(newRowsPerPage));
  HandleClickRowperpage(newRowsPerPage)
}
const HandleClickRowperpage = async(newRowsPerPage) => {
  setLoading(true);
  const postForm = {
    page:1,
    limit:Number(newRowsPerPage) ,
  };

  try {
    if (formData === undefined) {
      const response = await postData(Apiservices.USER_TRADE_PAIR_LIST,postForm);
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } else {
      const response = await postData(Apiservices.USER_TRADE_PAIR_LIST,postForm);
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    }
  } catch (error) {
   
    toast.error("Internal Server Error..Try Again Later");
    setLoading(false);
  }

};
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Trade Management" breadcrumbItem="Trade Pair List" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">

                                            <div className="input-group">
                                                <Input
                                                // onChange={handleInputChange}
                                                type="text"
                                                className="form-control"
                                                placeholder="Search ..."
                                                aria-label="Recipient's username"
                                                onChange={handleSearchChanage}
                                                invalid={searchValid}
                                                onKeyPress={handleKeyPress}
                                                />
                                                <div className="input-group-append">
                                                <button
                                                    className="btn btn-primary"
                                                    type="submit"
                                                    onClick={() => handleSearch()}
                                                    disabled={searchLoad}
                                                >
                                                    {searchLoad ? (<Spinner className="text-light" size="sm" /> ) : (<i className="ri-search-line" /> )}
                                                </button>
                                                </div>
                                            </div>

                                            </Col>
                                            <Col className="col-sm">

                                                <div className="d-flex justify-content-sm-end">
                                                    {/* <Dropdown
                                                        className='mx-4'
                                                        isOpen={btnauto3}
                                                        toggle={() => setbtnauto3(!btnauto3)}
                                                        id="dropdownMenuClickableInside"
                                                    >
                                                        <DropdownToggle tag="button" className="btn btn-info">
                                                            {selectedCurrency}  <i className="mdi mdi-chevron-down" />
                                                        </DropdownToggle>

                                                        <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start">

                                                            {[...new Set(selectedData.map((data) => data.toCurrency))].map((currency, index) => (
                                                                <DropdownItem key={index} onClick={() => handleSelect(currency)}>{currency}</DropdownItem>
                                                            ))}
                                                        </DropdownMenu>

                                                    </Dropdown> */}
                                                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                                                        data.module === "Trade Management" && data.actions.includes(1))) ? (
                                                    <Button color="success" className="add-btn" onClick={() => tog_update_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i>Add Pairs</Button>
                                                        ) :null}

                                                   

                                                </div>
                                            </Col>
                                        </Row>

                                        {/* <div className="table-responsive table-card mt-3 mb-1">
                                            <DataTable
                                                columns={columns}
                                                data={displayedData}
                                                paginationServer
                                                paginationTotalRows={filteredData.length}
                                                persistTableHead={true}
                                                progressPending={loading}
                                                progressComponent={<div className="py-3">Loading...</div>}
                                                // noDataComponent={<div>No records found</div>}
                                                fixedHeader

                                            />
                                        </div> */}
                                         <div className="table-responsive  mt-2">
                                            <DataTable
                                            columns={columns}
                                            data={formData}
                                            paginationServer
                                            paginationTotalRows={formData?.length}
                                            progressPending={loading}
                                            persistTableHead={true}
                                            progressComponent={<div className="py-3">Loading...</div>}
                                            // noDataComponent={formData.length===0}
                                            noDataComponent={<div className="py-3">No records found</div>}
                                            fixedHeader={true}
                                            
                                            />
                                            </div>
                                        
                                     
                                        <div className="container">
                                                    <div className="row justify-content-end mt-4">
                                                    <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                                                        <div className="d-flex align-items-center me-3">
                                                            <span className="me-2">Rows per page:</span>
                                                            <select
                                                            value={rowsPerPage}
                                                            onChange={(e) => handleRowsPerPageChange(e.target.value)}
                                                            className="form-control-sm"
                                                            >
                                                            <option value={10}>10</option>
                                                            <option value={20}>20</option>
                                                            <option value={30}>30</option>
                                                            <option value={50}>50</option>
                                                            </select>
                                                        </div>
                                                        <div className="me-md-3 mb-3 mb-md-0">
                                                            <span>{count}</span>
                                                        </div>
                                                        <div className="pagination pagination-rounded mt-3">
                                                            <ReactPaginate
                                                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={pageCount}
                                                            marginPagesDisplayed={1}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={handlePageClick}
                                                            containerClassName={"pagination"}
                                                            activeClassName={"active"}
                                                            pageLinkClassName={"page-link"}
                                                            breakLinkClassName={"break-link"}
                                                            disabledClassName={"disabled"}
                                                            initialPage={currentPage}
                                                            disableInitialCallback={true}
                                                            forcePage={currentPage}
                                                            renderLink={(props) => {
                                                                if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                                                return (
                                                                    <a
                                                                    {...props}
                                                                    onClick={() =>
                                                                        handlePageClick({
                                                                        selected: pageCount - 1,
                                                                        })
                                                                    }
                                                                    >
                                                                    {pageCount}
                                                                    </a>
                                                                );
                                                                }
                                                                return <a {...props} />;
                                                            }}
                                                            />
                                                        </div>
                                                        </div>
                                                    </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>





                </Container>
            </div>



     {/* Add Modal */}
            <Modal className='modal-dialog modal-lg' isOpen={modal_update_list} toggle={() => { tog_update_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}> Pair List </ModalHeader>
                <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validationAdd.handleSubmit();
                        return false
                    }}
                >
                    <ModalBody className='d-lg-flex justify-content-around'>

                        <div className="col-lg-5 col-sm-12">

                            <div className="mb-3">
                                <label htmlFor="fromCurrency-field" className="form-label">From Currency</label>
                                <Input
                                    type="text"
                                    id="fromCurrency-field"
                                    className="form-control"
                                    placeholder="Enter From Currency"
                                    name="fromCurrency"
                                    onChange={handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.fromCurrency || ""}
                                    invalid={validationAdd.touched.fromCurrency && validationAdd.errors.fromCurrency}
                                />
                                {validationAdd.touched.fromCurrency && validationAdd.errors.fromCurrency && (
                                    <FormFeedback type="invalid">{validationAdd.errors.fromCurrency}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="toCurrency-field" className="form-label">To Currency</label>
                                <Input
                                    type="text"
                                    id="toCurrency-field"
                                    className="form-control"
                                    placeholder="Enter To Currency"
                                    name="toCurrency"
                                    onChange={handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.toCurrency || ""}
                                    invalid={validationAdd.touched.toCurrency && validationAdd.errors.toCurrency}
                                />
                                {validationAdd.touched.toCurrency && validationAdd.errors.toCurrency && (
                                    <FormFeedback type="invalid">{validationAdd.errors.toCurrency}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="pairName-field" className="form-label">Pair Name</label>
                                <Input
                                    type="text"
                                    id="pairName-field"
                                    className="form-control"
                                    placeholder="Enter Pair Name"
                                    name="pairName"
                                    readOnly
                                    value={validationAdd.values.pairName || ""}
                                    invalid={validationAdd.touched.pairName && validationAdd.errors.pairName}
                                />
                                {validationAdd.touched.pairName && validationAdd.errors.pairName && (
                                    <FormFeedback type="invalid">{validationAdd.errors.pairName}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Stream Pair</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Stream Pair"
                                    name="streamPair"
                                    // onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.streamPair || ""}
                                    invalid={validationAdd.touched.streamPair && validationAdd.errors.streamPair}
                                />
                                {validationAdd.touched.streamPair && validationAdd.errors.streamPair && (
                                    <FormFeedback type="invalid">{validationAdd.errors.streamPair}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">INR Price</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter INR Price (optional)"
                                    name="inrPrice"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.inrPrice || ""}
                                    invalid={validationAdd.touched.inrPrice && validationAdd.errors.inrPrice}
                                />
                                {validationAdd.touched.inrPrice && validationAdd.errors.inrPrice && (
                                    <FormFeedback type="invalid">{validationAdd.errors.inrPrice}</FormFeedback>
                                )}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Minimum Trade Amount</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Minimum Trade Amount"
                                    name="minTradeAmount"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.minTradeAmount || ""}
                                    invalid={validationAdd.touched.minTradeAmount && validationAdd.errors.minTradeAmount}
                                />
                                {validationAdd.touched.minTradeAmount && validationAdd.errors.minTradeAmount && (
                                    <FormFeedback type="invalid">{validationAdd.errors.minTradeAmount}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Maker Fee</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Maker Fee"
                                    name="makerFee"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.makerFee}
                                    invalid={validationAdd.touched.makerFee && validationAdd.errors.makerFee}
                                />
                                {validationAdd.touched.makerFee && validationAdd.errors.makerFee && (
                                    <FormFeedback type="invalid">{validationAdd.errors.makerFee}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Last Price</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Last Price"
                                    name="lastPrice"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.lastPrice || ""}
                                    invalid={validationAdd.touched.lastPrice && validationAdd.errors.lastPrice}
                                />
                                {validationAdd.touched.lastPrice && validationAdd.errors.lastPrice && (
                                    <FormFeedback type="invalid">{validationAdd.errors.lastPrice}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Close</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Close "
                                    name="close"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.close}
                                    invalid={validationAdd.touched.close && validationAdd.errors.close}
                                />
                                {validationAdd.touched.close && validationAdd.errors.close && (
                                    <FormFeedback type="invalid">{validationAdd.errors.close}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">High</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter High "
                                    name="high"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.high}
                                    invalid={validationAdd.touched.high && validationAdd.errors.high}
                                />
                                {validationAdd.touched.high && validationAdd.errors.high && (
                                    <FormFeedback type="invalid">{validationAdd.errors.high}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Change Percentage</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter change percentage "
                                    name="changePercentage"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.changePercentage}
                                    invalid={validationAdd.touched.changePercentage && validationAdd.errors.changePercentage}
                                />
                                {validationAdd.touched.changePercentage && validationAdd.errors.changePercentage && (
                                    <FormFeedback type="invalid">{validationAdd.errors.changePercentage}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Low</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Low "
                                    name="low"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.low}
                                    invalid={validationAdd.touched.low && validationAdd.errors.low}
                                />
                                {validationAdd.touched.low && validationAdd.errors.low && (
                                    <FormFeedback type="invalid">{validationAdd.errors.low}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Trending Pair</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Trending Pair"
                                    name="trendingPair"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.trendingPair}
                                    invalid={validationAdd.touched.trendingPair && validationAdd.errors.trendingPair}
                                />
                                {validationAdd.touched.trendingPair && validationAdd.errors.trendingPair && (
                                    <FormFeedback type="invalid">{validationAdd.errors.trendingPair}</FormFeedback>
                                )}
                            </div>
                            {(validationAdd.values.streamPair === "uprousdt" || validationAdd.values.streamPair === "oneusdt") && (
                                <div className="mb-3">
                                    <label htmlFor="status-field" className="form-label">UPRO USDT Type</label>
                                    <select
                                        id="status-field"
                                        name="uprousdttype"
                                        onChange={validationAdd.handleChange}
                                        onBlur={validationAdd.handleBlur}
                                        value={validationAdd.values.uprousdttype || ""}
                                        className={`form-control ${validationAdd.touched.uprousdttype && validationAdd.errors.uprousdttype ? 'is-invalid' : ''}`}
                                    >
                                        <option value="">Select Plus or Minus</option>
                                        <option value="1">Plus(+)</option>
                                        <option value="0">Minus(-)</option>
                                    </select>
                                    {validationAdd.touched.uprousdttype && validationAdd.errors.uprousdttype && (
                                        <div className="invalid-feedback">
                                            {validationAdd.errors.uprousdttype}
                                        </div>
                                    )}
                                </div>
                            )}
                             </div>

                        <div className="col-lg-5 col-sm-12">
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Amount Decimal</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Price Decimal"
                                    name="amountDecimal"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.amountDecimal || ''}
                                    invalid={validationAdd.touched.amountDecimal && validationAdd.errors.amountDecimal}
                                />
                                {validationAdd.touched.amountDecimal && validationAdd.errors.amountDecimal && (
                                    <FormFeedback type="invalid">{validationAdd.errors.amountDecimal}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Price Decimal</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Price Decimal"
                                    name="priceDecimal"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.priceDecimal || ""}
                                    invalid={validationAdd.touched.priceDecimal && validationAdd.errors.priceDecimal}
                                />
                                {validationAdd.touched.priceDecimal && validationAdd.errors.priceDecimal && (
                                    <FormFeedback type="invalid">{validationAdd.errors.priceDecimal}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Active Status</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="isActive"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.isActive || ""}
                                    className={`form-control ${validationAdd.touched.isActive && validationAdd.errors.isActive ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select Active Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">Deactive</option>
                                </select>
                                {validationAdd.touched.isActive && validationAdd.errors.isActive && (
                                    <FormFeedback type="invalid">{validationAdd.errors.isActive}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Trade Type</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="liqudity"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.liqudity || ""}
                                    className={`form-control ${validationAdd.touched.liqudity && validationAdd.errors.liqudity ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select Trade Type</option>
                                    <option value="0">Non-Liqudity</option>
                                    <option value="1">Liqudity</option>
                                </select>
                                {validationAdd.touched.liqudity && validationAdd.errors.liqudity && (
                                    <FormFeedback type="invalid">{validationAdd.errors.liqudity}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">ProUsers Status</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="proUsers"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.proUsers || ""}
                                    className={`form-control ${validationAdd.touched.proUsers && validationAdd.errors.proUsers ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select ProUsers Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">Deactive</option>
                                </select>
                                {validationAdd.touched.proUsers && validationAdd.errors.proUsers && (
                                    <FormFeedback type="invalid">{validationAdd.errors.proUsers}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Maximum Trade Amount</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Minimum Trade Amount"
                                    name="maxTradeAmount"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.maxTradeAmount || ""}
                                    invalid={validationAdd.touched.maxTradeAmount && validationAdd.errors.maxTradeAmount}
                                />
                                {validationAdd.touched.maxTradeAmount && validationAdd.errors.maxTradeAmount && (
                                    <FormFeedback type="invalid">{validationAdd.errors.maxTradeAmount}</FormFeedback>
                                )}
                            </div>
                            {/* <div className="mb-3">
                                    <label htmlFor="status-field" className="form-label">Fee Type</label>
                                    <select
                                        id="status-field"
                                        //   className="form-control"
                                        name="feeType"
                                        onChange={handleFeeType}
                                        onBlur={validationAdd.handleBlur}
                                        value={0}
            
                                        className={`form-control ${validationAdd.touched.feeType && validationAdd.errors.feeType ? 'is-invalid' : ''}`}
                                    >
                                        <option value="">Select Fee</option>
                                        <option value="0">Flat</option>
                                        <option value="1">Fixed</option>
                                        <option value={0}>Percentage</option>

                                    </select>
                                    {validationAdd.touched.feeType && validationAdd.errors.feeType && (
                                        <FormFeedback type="invalid">{validationAdd.errors.feeType}</FormFeedback>
                                    )}
                                </div> */}
                                <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Fee Type</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Fee Type"
                                    name="feeType"
                                    onChange={handleFeeType}
                                    onBlur={validationAdd.handleBlur}
                                    value={'Percentage' || ""}
                                    // invalid={validationAdd.touched.feeType && validationAdd.errors.feeType}
                                />
                                {/* {validationAdd.touched.takerFee && validationAdd.errors.takerFee && (
                                    <FormFeedback type="invalid">{validationAdd.errors.takerFee}</FormFeedback>
                                )} */}
                            </div>

                                <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Taker Fee</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Taker Fee"
                                    name="takerFee"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.takerFee}
                                    invalid={validationAdd.touched.takerFee && validationAdd.errors.takerFee}
                                />
                                {validationAdd.touched.takerFee && validationAdd.errors.takerFee && (
                                    <FormFeedback type="invalid">{validationAdd.errors.takerFee}</FormFeedback>
                                )}
                            </div>
                            
                            <div className="mb-3">
                            <label htmlFor="title-field" className="form-label">
                            Position
                            </label>
                            <Input
                            type="number"
                            min="1"
                            id="subject-field"
                            className="form-control"
                            autoComplete="off"
                            placeholder="Enter Position"
                            name="position"
                            onChange={handleChangePosition}
                            onBlur={validationAdd.handleBlur}
                            value={position || ''}
                            // invalid={validationAdd.touched.position && validationAdd.errors.position}
                            />
                            {/* {validationAdd.touched.position && validationAdd.errors.position && <FormFeedback type="invalid">{validationAdd.errors.position}</FormFeedback>} */}
                        </div>

                        <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Amount Volume</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter amount volume "
                                    name="amountVolume"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.amountVolume}
                                    invalid={validationAdd.touched.amountVolume && validationAdd.errors.amountVolume}
                                />
                                {validationAdd.touched.amountVolume && validationAdd.errors.amountVolume && (
                                    <FormFeedback type="invalid">{validationAdd.errors.amountVolume}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Price volume</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Price volume "
                                    name="priceVolume"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.priceVolume}
                                    invalid={validationAdd.touched.priceVolume && validationAdd.errors.priceVolume}
                                />
                                {validationAdd.touched.priceVolume && validationAdd.errors.priceVolume && (
                                    <FormFeedback type="invalid">{validationAdd.errors.priceVolume}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Open</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Open "
                                    name="open"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.open}
                                    invalid={validationAdd.touched.open && validationAdd.errors.open}
                                />
                                {validationAdd.touched.open && validationAdd.errors.open && (
                                    <FormFeedback type="invalid">{validationAdd.errors.open}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Top Pair</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Top Pair"
                                    name="TopPair"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.TopPair}
                                    invalid={validationAdd.touched.TopPair && validationAdd.errors.TopPair}
                                />
                                {validationAdd.touched.TopPair && validationAdd.errors.TopPair && (
                                    <FormFeedback type="invalid">{validationAdd.errors.TopPair}</FormFeedback>
                                )}
                            </div>
                            {(validationAdd.values.streamPair === "uprousdt" || validationAdd.values.streamPair === "oneusdt") && (
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">UPRO USDT Price Exchange</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Top Pair"
                                    name="UproUsdtPriceExchange"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.UproUsdtPriceExchange}
                                    invalid={validationAdd.touched.UproUsdtPriceExchange && validationAdd.errors.UproUsdtPriceExchange}
                                />
                                {validationAdd.touched.UproUsdtPriceExchange && validationAdd.errors.UproUsdtPriceExchange && (
                                    <FormFeedback type="invalid">{validationAdd.errors.UproUsdtPriceExchange}</FormFeedback>
                                )}
                            </div>
                              )}
                            {/* <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">UPRO USDT Type Value</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter UPRO USDT Type Value."
                                    name="uprousdttypevalue"                                    
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.uprousdttypevalue || ""}
                                    invalid={validationAdd.touched.uprousdttypevalue && validationAdd.errors.uprousdttypevalue}
                                />
                                {validationAdd.touched.uprousdttypevalue && validationAdd.errors.uprousdttypevalue && (
                                    <FormFeedback type="invalid">{validationAdd.errors.uprousdttypevalue}</FormFeedback>
                                )}
                            </div> */}

                        </div>


                       

                           
                           
                           
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update_list(false)}>Close</button>
                            {/* <button  type="submit" className="btn btn-success" id="add-btn">Add Customer</button> */}
                            <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <Loader />

                                ) : ("Submit")}

                            </button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={tog_delete} className="modal zoomIn" id="deleteRecordModal" centered>
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-3 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            // onClick={confirmUpdate}
                            disabled={deleteLoad}
                        >
                            {deleteLoad ? (
                                <Loader />
                            ) : ("Yes, Delete It!")}

                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modelCount} className="modal zoomIn" id="deleteRecordModal" centered>
                <div className="modal-header">
                    <h4>Update Status</h4>
                    <Button type="button" onClick={() => setmodalCount(false)} className="btn-close" aria-label="Close"> </Button>
                </div>

                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-3 mb-0">Are you Sure You want to Update The {resend.row.pair} Status</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodalCount(false)}>Close</button>
                        <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            disabled={loading}
                            onClick={handleToggle}
                        >
                            {loading ? (
                                <Loader />
                            ) : ("Yes, Change It!")}

                        </button>
                    </div>
                </ModalBody>

            </Modal>

        </React.Fragment>
    );
};

export default TradeManagement;

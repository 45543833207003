import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Container, Modal, ModalBody } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { writeFile, utils } from 'xlsx';
import { LatestTransationData } from './data';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Link } from 'react-router-dom';



const FiatDepositeHistory = () => {
    // const { searchTerm } = useContext(SearchContext);


    const [searchTerm, setSearchTerm] = useState("")



    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = LatestTransationData.filter(item => {
        const isNameMatch = item.clientName && item.clientName.toLowerCase().includes(searchTerm.toLowerCase());
        const isDateMatch = item.date && item.date.includes(searchTerm); // Assuming item.date is in MM/DD/YYYY format
        return isNameMatch || isDateMatch;
    });


    const handleDownload = () => {
        // Convert the filteredData to a worksheet
        const ws = utils.json_to_sheet(LatestTransationData.map(item => ({
            'ID': item.clientId,
            'Name': item.clientName,
            'Date': item.date,
            'Price': item.price,
            'Quantity': item.quantity,
            'Amount': item.quantity * item.price,
            'Status': item.status,
        })));

        // Create a new workbook and add the worksheet
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Deposite History');

        // Write the workbook to an Excel file
        writeFile(wb, 'ultrapro_user_deposit_history_data.xlsx');
    };




    



    return (

        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Deposite History" breadcrumbItem="Fiat Deposite History" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>

                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                {/* <div className="search-box ms-2">
                                                    <input onChange={handleInputChange} type="text" className="form-control search" placeholder="Search..." />
                                                   
                                                </div> */}
                                                <div className="input-group">
                                                    <input
                                                    onChange={handleInputChange}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search ..."
                                                        aria-label="Recipient's username"
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="ri-search-line" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="d-flex gap-1">
                                                        <Button color="soft-danger"
                                                            onClick={handleDownload}
                                                        >Download Excel Sheet<i className="mdi mdi-download-circle-outline"></i></Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            {filteredData.length > 0 ? (
                                                <table className="table align-middle table-nowrap" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>

                                                            <th className="sort" data-sort="customer_name">Customer</th>
                                                            <th className="sort" data-sort="email">Email</th>
                                                            <th className="sort" data-sort="phone">Phone</th>
                                                            <th className="sort" data-sort="date">Joining Date</th>
                                                            <th className="sort" data-sort="status">Delivery Status</th>
                                                            <th className="sort" data-sort="action">Action</th>
                                                        </tr>
                                                    </thead>


                                                    <tbody className="list form-check-all">
                                                        {filteredData.map((data, index) => (
                                                            <tr key={data}>

                                                                <td className="id" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2101</Link></td>
                                                                <td className="customer_name">{data.clientName}</td>
                                                                <td className="email"></td>
                                                                <td className="phone">580-464-4694</td>
                                                                <td className="date">{data.date}</td>
                                                                <td className="status"><span className="badge badge-soft-success text-uppercase">Active</span></td>
                                                                <td>
                                                                    <div className="d-flex gap-2">
                                                                        <div className="edit">
                                                                            <button className="btn btn-sm btn-success edit-item-btn"
                                                                                data-bs-toggle="modal" data-bs-target="#showModal">Edit</button>
                                                                        </div>
                                                                        <div className="remove">

                                                                            <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div className="noresult">
                                                    <div className="text-center">
                                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                            colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                        </lord-icon>
                                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                                        <p className="text-muted mb-0">We've searched through {LatestTransationData.length} Fiat Crypto Deposite but couldn't find any matching your search.</p>
                                                    </div>
                                                </div>
                                            )}




                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </Container>
            </div>

            {/* Add Modal */}


            {/* Remove Modal */}
            <Modal className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light">Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>

    )
}

export default FiatDepositeHistory;

// import React, { useState } from 'react';
// import CryptoJS from 'crypto-js';

// const FiatDepositeHistory = () => {
//   const [data, setData] = useState('');
//   const [encryptedData, setEncryptedData] = useState('');
//   const [decryptedData, setDecryptedData] = useState('');
//   const key = 'jwvcoswlaltwfypnnlmzdhtinysszitn';
//   const key2 = 'jwvcoswlaltwfypnnlmzdhtinysszitn'; // Example key
//    // Example key

//   const handleEncrypt = () => {
//     const encrypted = CryptoJS.AES.encrypt(data, key).toString();
//     setEncryptedData(encrypted);
//   };

//   const handleDecrypt = () => {
//     const bytes = CryptoJS.AES.decrypt(encryptedData, key2);
//     const decrypted = bytes.toString(CryptoJS.enc.Utf8);
//     setDecryptedData(decrypted);
//   };

//   return (
//     <div style={{margin:'100px'}}>
//       <h1>Encryption and Decryption Example</h1>
//       <input
//         type="text"
//         value={data}
//         onChange={(e) => setData(e.target.value)}
//         placeholder="Enter data to encrypt"
//       />
//       <button onClick={handleEncrypt}>Encrypt</button>
//       <p>Encrypted Data: {encryptedData}</p>
//       <button onClick={handleDecrypt}>Decrypt</button>
//       <p>Decrypted Data: {decryptedData}</p>
//     </div>
//   );
// };

// export default FiatDepositeHistory;

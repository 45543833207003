import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback, CardImg, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import Swal from "sweetalert2";
import { AdminContext } from "../../helpers/adminContext";

const TaskComponent = () => {
  document.title = "Ultrapro | FlexibleStake Scheme";
  const [loading, setLoading] = useState(false);
  const [getDiscoverList, setDisoverList] = useState([]);
  const [modal, setModal] = useState(false);
  const [showDetails, setShowDetails] = useState({});
  const [showModel, setShowModel] = useState(false);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);
  //Searchfun
  const [inputSearch, setinputSearch] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);

  //Permission List
  const { adminInfo } = useContext(AdminContext);
  const [adminType, setAdminType] = useState("");
  const [content, setContent] = useState("");
  //Add Modal
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
    formik.resetForm();
  }
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
  }

  const [filterType, setFilterType] = useState([]);
  //classList api call
  useEffect(() => {
    fetchData();
    setAdminType(adminInfo?.adminType);
  }, []);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.FlexibleStackSchemeList);
      console.log(response);

      if (response && response?.data) {
        setLoading(false);
        setTotalPagination(response.data.totalPages);
        setDisoverList(response.data.data);
        setFilterType(response.data.filterDays);
        setTotalLimit(response.data.itemsPerPage);
        setCount(response.data.countFrom);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setDisoverList([]);
      // Handle fetch error
    }
  };

  const handleShowModel = (value) => {
    setShowDetails(value);

    // setmodal_view_list(!modal_view_list);
    setShowModel(!showModel);
  };
  const handleDelete = (value) => {

    showUserBlockAlert(confirmDelete, value);
  };

  const showUserBlockAlert = (onConfirm, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove this record!`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          await onConfirm(row);
        } catch (error) {
          Swal.fire("Error!", "There was an error deleting the record", "error");
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Additional actions after confirmation, if needed
      }
    });
  };

  const confirmDelete = async (row) => {
    const postForm = {
      flexibleSchemeId: row.flexibleSchemeId,
    };

    try {
      const response = await postData(Apiservices.FlexibleSchemeDelete, postForm);
      if (response.status) {
        Swal.fire("Success!", `${response.message}`, "success");
        fetchData();
      } else {
        Swal.fire("Error!", `${response.message}`, "error");
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
    }
  };

  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  const allowedActions = [1, 2, 3, 4];

  //paginnation

  const columns = [
    {
      name: "Duration Type",
      selector: (row) => row.durationType || "-",
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Details",
      cell: (row) => (
        <table style={{ width: "100%", textAlign: "left" }}>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Percentage</th>
            </tr>
          </thead>
          <tbody>
            {row.streamDetails.map((detail, index) => (
              <tr key={index} style={{ borderBottom: "10px solid transparent" }}>
                <td style={{ paddingBottom: "10px" }}>{detail.stakeUsdtAmount} USDT</td>
                <td style={{ paddingBottom: "10px" }}>{detail.percentage}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      ),
      minWidth: "200px",
    },
    {
      name: "Date & Time",
      selector: (row) => (row.createdAt ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${handleNullValue(utcToNormalTime(row.createdAt).time)}` : "---"),
      sortable: true,
      minWidth: "200px",
      maxWidth: "250px",
    },
    ...(adminType === 1 || adminInfo?.permissions.some((data) => data.module === "Probinar Academy" && allowedActions.some((action) => data.actions.includes(action)))
      ? [
          {
            name: "Action",
            cell: (row) => (
              <div className="d-flex">
                {/* Edit, View, Delete Icons */}
                <i className="mdi mdi-border-color mx-1" style={{ cursor: "pointer" }} onClick={() => handleUpdate(row)}></i>
                <i className="mdi mdi-eye-outline mx-1" style={{ cursor: "pointer" }} onClick={() => handleShowModel(row)}></i>
                <i
                  className="mdi mdi-trash-can-outline cursor-pointer"
                  onClick={() => handleDelete(row)}
                ></i>
              </div>
            ),
            ignoreRowClick: true,
            minWidth: "150px",
            maxWidth: "200px",
          },
        ]
      : []),
  ];

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };
  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: itemsPerPage,
    };
    try {
      const response = await postData(Apiservices.FlexibleStackSchemeList, postForm);

      if (response.status) {
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };


  //Add form validation
  const formik = useFormik({
    initialValues: {
      durationType: "",
      streamDetails: [{ stakeUsdtAmount: "", percentage: "" }],
      stakeType: "",
    },
    validationSchema: Yup.object({
      durationType: Yup.string().trim().required("Duration type is required"),
      stakeType: Yup.string().trim().required("Stake type is required"),
      streamDetails: Yup.array()
        .of(
          Yup.object().shape({
            stakeUsdtAmount: Yup.number().required("Stake USDT Amount is required"),
            percentage: Yup.number().required("Percentage is required"),
          })
        )
        .min(1, "At least one stream detail is required"),
    }),
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  //Addapicall
  const handleAdd = async (data) => {
    setLoading(true);

    try {
      const payload = {
        durationType: data.durationType,
        streamDetails: data.streamDetails,
        stakingPlanType: data.stakeType,
      };

      const response = await postData(Apiservices.AddFlexibleStakeSchem, payload);

      if (response.status === true) {
        toast.success(response.message);
        formik.resetForm();
        setmodal_list(false);
        setLoading(false);
        fetchData();
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
      setLoading(false);
    }
  };



  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,
    initialValues: {
      durationType: "",
      stakeType: "",
      amounts: [], // For multiple amounts
      percentages: [], // For multiple percentages
    },
    validationSchema: Yup.object({
      durationType: Yup.string().required("Duration Type is required"),
      stakeType: Yup.string().required("Stake Type is required"),
      amounts: Yup.array().of(Yup.number().required("Amount is required")),
      percentages: Yup.array().of(Yup.number().required("Percentage is required")),
    }),
    onSubmit: (values) => {
      confirmUpdate(values); // Handle submission
    },
  });

  const handleUpdate = (record) => {
    // Extract `_id` directly
    const durationType = record.durationType || ""; // Adjust this based on your actual record structure
    const streamDetails = record.streamDetails.map((detail) => ({
      amount: detail.stakeUsdtAmount,
      percentage: detail.percentage,
      stakeType: detail.stakingPlanType,
    }));

    // Ensure to set the correct ID
    validationUpdate.setValues({
      id: record._id, // Set the id for the confirmUpdate
      flexibleSchemeId: record.flexibleSchemeId,
      stakeType: record.stakingPlanType !== undefined ? String(record.stakingPlanType) : "",
      durationType,
      amounts: streamDetails.map((detail) => detail.amount),
      percentages: streamDetails.map((detail) => detail.percentage),
    });

    setmodal_update_list(true);
  };

  const confirmUpdate = async (values) => {
    setLoading(true);
    console.log(values, "Values");
    // Construct the update object
    const updateData = {
      flexibleSchemeId: values.flexibleSchemeId,
      stakingPlanType: values.stakeType,
      durationType: values.durationType,
      streamDetails: values.amounts.map((amount, index) => ({
        stakeUsdtAmount: amount,
        percentage: values.percentages[index],
      })),
    };

    console.log("Update Data: ", updateData); // Log the object being sent
    // return true;
    try {
      const response = await postData(Apiservices.FlexibleSchemeUpdate, updateData);
      if (response.status === true) {
        setmodal_update_list(false);
        toast.success(response.message);
        fetchData();
        setDisoverList("");
      } else {
        toast.error(response.data.message);
        fetchData();
        setDisoverList("");
      }
    } catch (error) {
      // Handle specific error cases
      toast.error(error.response?.data?.message || "An error occurred");
      validationUpdate.resetForm();
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "") {
      fetchData();
      setSearchValid(false);
    } else {
      setinputSearch(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
      };

      try {
        const response = await postData(Apiservices.FlexibleStackSchemeList, postForm);

        if (response.status) {
          setSearchLoad(false);
          setDisoverList(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);

          // toast(response.message);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const showStatusAlert = (onConfirm, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to update status!",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        if (!reason) {
          Swal.showValidationMessage("Reason is required");
        } else {
          Swal.showLoading();
          try {
            await onConfirm({ ...row, reason });
            Swal.fire("Success!", "The status has been updated successfully", "success");
          } catch (error) {
            Swal.fire("Error!", "There was an error updating the status", "error");
          }
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      // preConfirm: () => {
      //     return onConfirm(row)
      //       .then(() => {
      //         return Promise.resolve();
      //       })
      //       .catch((error) => {
      //         Swal.showValidationMessage(`Request failed: ${error}`);
      //       });
      //   }
    }).then((result) => {
      if (result.isConfirmed) {
        // Success handling if needed
        // Swal.fire('Success !', 'The status has been updated successfully', 'success');
      }
    });
  };
  const handleUpdateStatusClick = (row) => {
    showStatusAlert(handleToggle1, row);
  };

  const handleToggle1 = async (rowDetails) => {
    const updatedStatus = rowDetails.isActive === 1 ? 0 : 1;

    try {
      setIsLoading(true);
      const statusUpdate = new FormData();
      statusUpdate.append("video_Id", rowDetails.video_Id);
      statusUpdate.append("title", rowDetails.title);
      statusUpdate.append("url", rowDetails.url);
      statusUpdate.append("isActive", updatedStatus);
      statusUpdate.append("position", Number(rowDetails.position));
      statusUpdate.append("description", rowDetails.description);
      statusUpdate.append("shortDescription", rowDetails.shortDescription);
      statusUpdate.append("content", JSON.stringify(rowDetails.content));
      statusUpdate.append("imageUrl", rowDetails.imageUrl);

      // statusUpdate.append("files", '')

      // const response = await postData(`${Apiservices.ProbinarUpdate}`, statusUpdate);
      const response = await postData(Apiservices.FlexibleSchemeUpdate, statusUpdate);
      if (response.status) {
        toast.dismiss();
        setIsLoading(false);
        // toast.success(response.message);
        Swal.fire("Success !", "The status has been updated successfully", "success");

        fetchData();
      } else {
        toast.success(response.message);
        setIsLoading(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
      // toast.error("Internal Server Error..Try Again Later");
      setIsLoading(false);
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState("");

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage));
    HandleClickRowperpage(newRowsPerPage);
  };
  const HandleClickRowperpage = async (newRowsPerPage) => {
    setLoading(true);
    const postForm = {
      page: 1,
      limit: Number(newRowsPerPage),
    };

    // if (dateRange[0] && dateRange[1]) {
    //   postForm.startDate = formatDate(dateRange[0]);
    //   postForm.endDate = formatDate(dateRange[1]);
    // }

    try {
      if (getDiscoverList === undefined) {
        // const response = await postData(`${Apiservices.ProbinarList}?${queryString}`);
        const response = await postData(Apiservices.FlexibleStackSchemeList, postForm);

        if (response.status) {
          setLoading(false);
          setDisoverList(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
        }
      } else {
        const response = await postData(Apiservices.FlexibleStackSchemeList, postForm);
        if (response.status) {
          setLoading(false);
          setDisoverList(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };
  // const validateImage = (file) => {
  //   // Validate file type and size here (e.g., PNG, JPG, JPEG)
  //   const validTypes = ['image/png', 'image/jpeg'];
  //   return validTypes.includes(file.type) && file.size <= 5000000; // 5MB max size
  // };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
    }
    // if (file) {
    //   if (validateImage(file)) {
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //       const img = new Image();
    //       img.src = event.target.result;
    //       img.onload = () => {
    //         const { width, height } = img;
    //         if (validateResolution(width, height)) {
    //           setImage(file);
    //           setError('');
    //         } else {
    //           setError('Image resolution must be at least 800x600 pixels.');
    //           setImage(null);
    //         }
    //       };
    //     };
    //     reader.readAsDataURL(file);
    //   } else {
    //     setError('Invalid file type or size.');
    //     setImage(null);
    //   }
    // }
  };

  // const validateResolution = (width, height) => {
  //   // Minimum resolution required: 800x600 pixels
  //   return width >= 800 && height >= 600;
  // };

  // Function to handle adding a new row
  const addStreamDetail = () => {
    formik.setFieldValue("streamDetails", [...formik.values.streamDetails, { stakeUsdtAmount: "", percentage: "" }]);
  };

  // Function to handle deleting a row
  const removeStreamDetail = (index) => {
    const updatedDetails = formik.values.streamDetails.filter((_, i) => i !== index);
    formik.setFieldValue("streamDetails", updatedDetails);
  };
  const [selectedDays, setSelectedDays] = useState("");
  const handleSelectDays = async (value) => {
    setSelectedDays(value);
    filterPaginateData({ filterType: value });
  };

  const filterPaginateData = async (params) => {
    try {
      const postForm = {
        filterType: params.filterType,
      };
      setLoading(true);
      const response = await postData(Apiservices.FlexibleStackSchemeList, postForm);
      console.log(response);

      if (response && response?.data) {
        setLoading(false);
        setTotalPagination(response.data.totalPages);
        setDisoverList(response.data.data);
        setTotalLimit(response.data.itemsPerPage);
        setCount(response.data.countFrom);
      } else {
        setLoading(false);
      }
    } catch (error) {
      // toast.error("Internal Server Error..Try Again Later");
      console.error("Error fetching data:", error);
      setLoading(false);
      setDisoverList([]);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Flexible Stake" breadcrumbItem="Flexible Stake Scheme" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4">
                    {/* <Col className="col-sm-auto">
                      <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => handleSearch()}
                            disabled={searchLoad}
                          >
                            {searchLoad ? (<Spinner className="text-light" size="sm" />) : (<i className="ri-search-line" />)}
                          </button>
                        </div>
                      </div>
                    </Col> */}
                    <Col className="col-3">
                      <select className="form-select" value={selectedDays || "Select Days"} onChange={(e) => handleSelectDays(e.target.value)}>
                        <option value="">All Days</option>
                        {filterType &&
                          filterType?.length > 0 &&
                          filterType.map((data) => (
                            <option key={data.id} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                      </select>
                    </Col>
                    {/* Add Modal Button */}
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        {adminType === 1 || adminInfo?.permissions.some((data) => data.module === "Probinar Academy" && data.actions.includes(1)) ? (
                          <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn">
                            <i className="ri-add-line align-bottom me-1"></i> Add
                          </Button>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive  mt-2">
                    <DataTable
                      columns={columns}
                      data={getDiscoverList}
                      paginationServer
                      paginationTotalRows={getDiscoverList.length}
                      progressPending={loading}
                      persistTableHead={true}
                      progressComponent={<div className="py-3">Loading...</div>}
                      // noDataComponent={formData.length===0}
                      noDataComponent={<div className="py-3">No records found</div>}
                      fixedHeader={true}
                    />
                  </div>

                  <div className="container">
                    <div className="row justify-content-end mt-4">
                      <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                        {/* <div className="d-flex align-items-center me-3">
                          <span className="me-2">Rows per page:</span>
                          <select
                            value={rowsPerPage}
                            onChange={(e) =>
                              handleRowsPerPageChange(e.target.value)
                            }
                            className="form-control-sm"
                          >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                          </select>
                        </div> */}

                        <div className="me-md-3 mb-3 mb-md-0">
                          <span>{count}</span>
                        </div>
                        <div className="pagination pagination-rounded mt-3">
                          <ReactPaginate
                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            breakLinkClassName={"break-link"}
                            disabledClassName={"disabled"}
                            initialPage={currentPage}
                            disableInitialCallback={true}
                            forcePage={currentPage}
                            renderLink={(props) => {
                              if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                return (
                                  <a
                                    {...props}
                                    onClick={() =>
                                      handlePageClick({
                                        selected: pageCount - 1,
                                      })
                                    }
                                  >
                                    {pageCount}
                                  </a>
                                );
                              }
                              return <a {...props} />;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* // ADDMODAL  */}
                  <Modal
                    className="modal-dialog modal-lg"
                    isOpen={modal_list}
                    toggle={() => {
                      tog_list();
                    }}
                    centered
                  >
                    <ModalHeader
                      className="bg-light p-3"
                      id="exampleModalLabel"
                      toggle={() => {
                        tog_list();
                      }}
                    >
                      {" "}
                      Add Scheme
                    </ModalHeader>
                    <Form
                      autoComplete="off"
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                      }}
                    >
                      <ModalBody>
                        <Row md={12}>
                          <Col md={6}>
                            <div className="mb-3">
                              <label htmlFor="durationType-field" className="form-label">
                                Duration Type
                              </label>
                              <Input
                                type="text"
                                id="durationType-field"
                                name="durationType"
                                placeholder="Enter Duration Type"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.durationType}
                                invalid={formik.touched.durationType && formik.errors.durationType}
                              />
                              {formik.touched.durationType && formik.errors.durationType && <FormFeedback>{formik.errors.durationType}</FormFeedback>}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <label for="duration">Stake Type</label>
                              <select
                                id="stakeType"
                                name="stakeType"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.stakeType || ""}
                                className={`form-control ${formik.touched.stakeType && formik.errors.stakeType ? "is-invalid" : ""}`}
                              >
                                <option value="" disabled>
                                  Select Type
                                </option>
                                <option value={0}>Normal Flexible Staking</option>
                                <option value={1}>Minimal Flexible Staking</option>
                              </select>
                              {formik.touched.stakeType && formik.errors.stakeType && <FormFeedback type="invalid">{formik.errors.stakeType}</FormFeedback>}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            {formik.values.streamDetails.map((detail, index) => (
                              <Row key={index} className="mb-3">
                                <Col md={5}>
                                  <Input
                                    type="number"
                                    name={`streamDetails[${index}].stakeUsdtAmount`}
                                    placeholder="Amount"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.streamDetails[index].stakeUsdtAmount}
                                    invalid={formik.touched.streamDetails?.[index]?.stakeUsdtAmount && formik.errors.streamDetails?.[index]?.stakeUsdtAmount}
                                  />
                                </Col>
                                <Col md={5}>
                                  <Input
                                    type="number"
                                    name={`streamDetails[${index}].percentage`}
                                    placeholder="Percentage"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.streamDetails[index].percentage}
                                    invalid={formik.touched.streamDetails?.[index]?.percentage && formik.errors.streamDetails?.[index]?.percentage}
                                  />
                                </Col>
                                <Col md={2}>
                                  {index > 0 && (
                                    <button type="button" className="btn btn-danger" onClick={() => removeStreamDetail(index)}>
                                      <i className="ri-delete-bin-line"   ></i>
                                    </button>
                                  )}
                                </Col>
                              </Row>
                            ))}
                            <button type="button" onClick={addStreamDetail} className="btn btn-success mt-3">
                              Add More
                            </button>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <button type="button" className="btn btn-light" onClick={() => tog_list()}>
                          Close
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </ModalFooter>
                    </Form>
                  </Modal>

                  {/* UpdateModal */}
                  <Modal
                    className="modal-dialog modal-lg"
                    isOpen={modal_update_list}
                    toggle={() => {
                      tog_update_list();
                    }}
                    centered
                  >
                    <ModalHeader
                      className="bg-light p-3"
                      id="exampleModalLabel"
                      toggle={() => {
                        tog_update_list();
                      }}
                    >
                      Update Scheme
                    </ModalHeader>
                    <Form
                      autoComplete="off"
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validationUpdate.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <Row>
                          <Row>
                            <Col md={6}>
                              <div className="mb-3 col-12">
                                <label className="form-label">Duration Type</label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  value={validationUpdate.values.durationType} // Assuming this holds the duration type value
                                  readOnly
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div>
                                <label for="duration">Stake Type</label>
                                <select
                                  id="stakeType"
                                  name="stakeType"
                                  onChange={validationUpdate.handleChange}
                                  onBlur={validationUpdate.handleBlur}
                                  value={validationUpdate.values.stakeType || ""}
                                  className={`form-control ${validationUpdate.touched.stakeType && validationUpdate.errors.stakeType ? "is-invalid" : ""}`}
                                >
                                  <option value="" disabled>
                                    Select Type
                                  </option>
                                  <option value={0}>Normal Flexible Staking</option>
                                  <option value={1}>Minimal Flexible Staking</option>
                                </select>
                                {validationUpdate.touched.stakeType && validationUpdate.errors.stakeType && <FormFeedback type="invalid">{validationUpdate.errors.stakeType}</FormFeedback>}
                              </div>
                            </Col>
                          </Row>

                          {validationUpdate.values.amounts.map((_, index) => (
                            <div className="mb-3 d-flex align-items-end col-6" key={index}>
                              <div className="me-2">
                                <label htmlFor={`amounts[${index}]`} className="form-label">
                                  Amount {index + 1}
                                </label>
                                <Input
                                  type="number"
                                  id={`amounts[${index}]`}
                                  className="form-control"
                                  name={`amounts[${index}]`}
                                  onChange={validationUpdate.handleChange}
                                  onBlur={validationUpdate.handleBlur}
                                  value={validationUpdate.values.amounts[index]}
                                  invalid={validationUpdate.touched.amounts?.[index] && validationUpdate.errors.amounts?.[index]}
                                />
                                <FormFeedback type="invalid">{validationUpdate.errors.amounts?.[index]}</FormFeedback>
                              </div>

                              <div>
                                <label htmlFor={`percentages[${index}]`} className="form-label">
                                  Percentage {index + 1}
                                </label>
                                <Input
                                  type="number"
                                  id={`percentages[${index}]`}
                                  className="form-control"
                                  name={`percentages[${index}]`}
                                  onChange={validationUpdate.handleChange}
                                  onBlur={validationUpdate.handleBlur}
                                  value={validationUpdate.values.percentages[index]}
                                  invalid={validationUpdate.touched.percentages?.[index] && validationUpdate.errors.percentages?.[index]}
                                />
                                <FormFeedback type="invalid">{validationUpdate.errors.percentages?.[index]}</FormFeedback>
                              </div>
                            </div>
                          ))}
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              setmodal_update_list(false);
                            }}
                          >
                            Close
                          </button>
                          <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
                            {loading ? <Loader /> : "Submit"}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  {/* View Modal    */}
                  <Modal className="modal-dialog modal-lg" size="l" isOpen={showModel} centered backdrop="static">
                    <ModalHeader
                      className="bg-light p-3"
                      id="exampleModalLabel"
                      toggle={() => {
                        setShowModel(!showModel);
                      }}
                    >
                      Flexible Scheme Details
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <div className="mb-3 col-6">
                          <label className="form-label">Duration Type</label>
                          <Input
                            type="text"
                            className="form-control"
                            value={showDetails.durationType} // Assuming this holds the duration type value
                            readOnly
                          />
                        </div>
                        <div className="mb-3 col-6">
                          <label className="form-label">Stake Type</label>
                          <Input
                            type="text"
                            className="form-control"
                            value={showDetails.stakingPlanType} // Assuming this holds the duration type value
                            readOnly
                          />
                        </div>
                        {showDetails?.streamDetails &&
                          showDetails?.streamDetails.map((data, index) => (
                            <div className="mb-3 d-flex align-items-end col-6" key={index}>
                              <div className="me-2">
                                <label htmlFor={`amounts[${index}]`} className="form-label">
                                  Amount {index + 1}
                                </label>
                                <Input
                                  type="number"
                                  id={`amounts[${index}]`}
                                  className="form-control"
                                  name={`amounts[${index}]`}
                                  // onChange={validationUpdate.handleChange}
                                  // onBlur={validationUpdate.handleBlur}
                                  value={data?.stakeUsdtAmount}
                                  readOnly
                                />
                              </div>
                              <div>
                                <label htmlFor={`percentages[${index}]`} className="form-label">
                                  Percentage {index + 1}
                                </label>
                                <Input
                                  type="number"
                                  id={`percentages[${index}]`}
                                  className="form-control"
                                  name={`percentages[${index}]`}
                                  // onChange={validationUpdate.handleChange}
                                  // onBlur={validationUpdate.handleBlur}
                                  value={data?.percentage}
                                  readOnly
                                />
                              </div>
                              
                            </div>
                          ))}
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TaskComponent;

import DataTable from 'react-data-table-component';
import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback, Spinner, CardImg, Col, CardTitle, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form } from "reactstrap";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postData, postGetData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { handleNullValue } from '../../helpers/function';
import { AdminContext } from '../../helpers/adminContext';

const CoinManagement = () => {
    const { adminInfo } = useContext(AdminContext);
    const [adminType, setAdminType] = useState('')
    const [CurrencyINR,setCurrencyINR] = useState('')
    // const Offsymbol = () => {
    //     return (
    //         <div
    //             style={{
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 alignItems: "center",
    //                 height: "100%",
    //                 fontSize: 12,
    //                 color: "#fff",
    //                 paddingRight: 2,
    //             }}
    //         >
    //             {" "}
    //             <i className="mdi mdi-signal-off" />

    //         </div>
    //     );
    // };

    // const OnSymbol = () => {
    //     return (
    //         <div
    //             style={{
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 alignItems: "center",
    //                 height: "100%",
    //                 fontSize: 12,
    //                 color: "#fff",
    //                 paddingRight: 2,
    //             }}
    //         >
    //             {" "}
    //             <i className="mdi mdi-signal" />
    //         </div>
    //     );
    // };

    const navigate = useNavigate()
    document.title = "Ultrapro | Coin Management";
    const [feeSymbol, setFeeSymbol] = useState('');
    const [feeDepositSymbol, setFeeDepositSymbol] = useState('');

    const [loading, setLoading] = useState(false)
    const [deleteLoad, setDeleteLoad] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [recordToUpdate, setRecordToUpdate] = useState({});
    const [modelCount, setmodalCount] = useState(false);
    const [model_withdraw, setmodel_withdraw] = useState(false);
    const [model_deposit, setmodel_deposit] = useState(false);

    const [tdsStatus, setTDSstatus] = useState(false);
    const [resend, setResend] = useState({
        row: {},
        userStatus: null
    });

    const [formData, setFormData] = useState([])
    const [network, setNetwork] = useState([])
    const [depositNetwork, setDepositNetwork] = useState([])

    const [totalPagination, setTotalPagination] = useState(1);
    const [totalLimit, setTotalLimit] = useState(1);
    const [count, setCount] = useState("");
    const [currentPage, setCurrentPage] = useState(0);


    // const itemsPerPage = 10; // Number of items to display per page
    // const totalItems = formData.length; // Total number of items
    // const startCount = currentPage * itemsPerPage + 1;
    // const endCount = Math.min((currentPage + 1) * itemsPerPage, totalItems);
    const itemsPerPage = totalLimit; // Number of items to display per page
    const pageCount = Math.ceil(totalPagination); // Calculate total pages
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        handlePagePost(selected);

    };


    useEffect(() => {
        coinList();
        setAdminType(adminInfo?.adminType)
    }, []);
    const coinList = async () => {
        try {
            setLoading(true);
            const response = await postData(Apiservices.USER_COIN_LIST);
            if (response.status) {
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit);
                setCount(response.data.currentCount);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) { }
    };

    const handlePagePost = async (selected) => {
        setLoading(true);
        const postForm = {
            page: selected + 1,
            //   search: inputSearch
        };
        if (selectedItem) {
            postForm.isActive = selectedItem;
          }
        try {
            const response = await postData(Apiservices.USER_COIN_LIST, postForm);

            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit);
                setCount(response.data.currentCount);
            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            coinName: '',
            symbol: '',
            currencyType: '',
            decimalpoint: '',
            walletId: '',
            depositType: '',
            withdrawType: '',
            coinType: '',
            memostatus: '',
            depositWalletId: '',

            //   image: null,
        },
        validationSchema: Yup.object({
            coinName: Yup.string().required("CoinName is required"),
            symbol: Yup.string().required("Symbol is required"),
            coinType: Yup.string().required("Coin Type is required"),
            currencyType: Yup.string().required("Crypto Type is required"),
            decimalpoint: Yup.string()
                .matches(/^(?!0$)(\d*\.?\d+)$/, "Please enter a value greater than zero")
                .required("Decimal Point is required"),
            walletId: Yup.number()
                .typeError("Withdrae Wallet Id must be a number")
                .required("Withdrae Wallet Id is required")
                .moreThan(0, "Withdrae Wallet Id must be greater than zero"),
            depositWalletId: Yup.number()
                .typeError("Deposit Wallet Id must be a number")
                .required("Deposit Wallet Id is required")
                .moreThan(0, "Deposit Wallet Id must be greater than zero"),
            depositType: Yup.string().required("Deposit Type is required"),

            withdrawType: Yup.string().required("Withdraw Type is required"),
            memostatus: Yup.string().required("Memo status is required"),

        }),
        onSubmit: (values) => {
            handlePost(values);

        },
    });



    const validationUpdate = useFormik({
        enableReinitialize: true,

        initialValues: {
            coinName: '',
            symbol: '',
            // isActive: '',
            // depositStatus: '',
            // withdrawStatus: '',
            currencyType: '',
            coinType: '',

            decimalpoint: '',
            walletId: '',
            depositType: '',
            withdrawType: '',
            depositWalletId: '',
            memostatus:''


        },
        validationSchema: Yup.object({
            coinName: Yup.string().required("Coinname is required"),
            symbol: Yup.string().required("Symbol is required"),
            // image: Yup.string().required("Image is required"),
            // isActive: Yup.string().required("Status is required"),
            // depositStatus: Yup.string().required("Deposit Status is required"),
            // withdrawStatus: Yup.string().required("WithDraw Status is required"),


            coinType: Yup.string().required("Coin Type Status is required"),

            currencyType: Yup.string().required("Currency Type Status is required"),
            decimalpoint: Yup.string()
                .matches(/^(?!0$)(\d*\.?\d+)$/, "Please enter a value greater than zero")
                .required("Decimal Point is required"),
            walletId: Yup.number()
                .typeError("Withdraw Wallet Id must be a number")
                .required("Withdraw Wallet Id is required")
                .moreThan(0, "Withdraw Wallet Id must be greater than zero"),
            depositWalletId: Yup.number()
                .typeError("Depposit Wallet Id must be a number")
                .required("Depposit Wallet Id is required")
                .moreThan(0, "Depposit Wallet Id must be greater than zero"),


            depositType: Yup.string().required("Depposit Type is required"),
            withdrawType: Yup.string().required("Withdraw Type is required"),
            memostatus: Yup.string().required("Memo Status is required"),


        }),
        onSubmit: (values) => {
            confirmUpdate(values)
        },
    });

    const validationWithDraw = useFormik({
        enableReinitialize: true,

        initialValues: {
            minWithdrawalLimit: '',
            maxWithdrawalLimit: '',
            withdrawalFeeType: '',
            withdrawalFee: '',
            dayLimit: '',
            tds: '',
            // walletId:'',
            // withdrawType:'',
            // contractaddress:''

        },
        validationSchema: Yup.object({
            minWithdrawalLimit: Yup.number()
                .typeError("Minimum  amount must be a number")
                .required("Minimum  amount is Required")
                .positive("Minimum  amount must be a positive number"), // Optional: Ensure positive value
            maxWithdrawalLimit: Yup.number()
                .typeError("Maximum  amount must be a number")
                .required("Maximum amount is required")
                .positive("Maximum amount must be a positive number") // Optional: Ensure positive value
                .when('minWithdrawalLimit', (minWithdrawalLimit, schema) =>
                    schema.moreThan(minWithdrawalLimit, 'Maximum amount must be greater than Minimum amount')
                ),
            withdrawalFee: Yup.number()
                .typeError("Fee must be a number")
                .required("Fee is required")
                .min(0, "Fee must be a positive number or zero")
                .when(['withdrawalFeeType', 'minWithdrawalLimit'], {
                    is: (withdrawalFeeType, minWithdrawalLimit) => withdrawalFeeType === "0", // Check if fee type is 'Flat'
                    then: schema => schema.lessThan(Yup.ref('minWithdrawalLimit'), 'Fee must be less than minimum amount in flat mode only'),

                }),
            withdrawalFeeType: Yup.string().required("Fee Type is required"),
            dayLimit: Yup.number()
                .typeError("Day Limit must be a number")
                .required("Day Limit is required")
                .when('maxWithdrawalLimit', (maxWithdrawalLimit, schema) =>
                    schema.min(maxWithdrawalLimit, 'Day Limit must be equal to or greater than Maximum Withdrawal Limit')
                ),
            tds: Yup.number()
                .typeError("TDS must be a number"),
            //     walletId: Yup.number()
            //     .typeError("Wallet Id must be a number")
            //     .required("Wallet Id is required")
            //     .moreThan(0, "Wallet Id must be greater than zero")
            //   , // Optional: Ensure positive value,
            // withdrawType: Yup.string().required("Withdraw Type is required"),
            // contractaddress: Yup.string().required("Contract Address is required"),



        }),
        onSubmit: (values) => {

            handleWithDrawUpdate(values)

        },
    });
    const validationDeposit = useFormik({
        enableReinitialize: true,

        initialValues: {
            minDepositLimit: '',
            maxDepositLimit: '',
            // depositFeeType: '',
            depositFee: '',
            dayLimit: '',
            tds: '',
            // walletId:'',
            // withdrawType:'',
            // contractaddress:''

        },
        validationSchema: Yup.object({
            // minDepositLimit: Yup.number()
            //     .typeError("Minimum  amount must be a number")
            //     .required("Minimum  amount is Required")
            //     .positive("Minimum  amount must be a positive number"), // Optional: Ensure positive value
            // maxDepositLimit: Yup.number()
            //     .typeError("Maximum  amount must be a number")
            //     .required("Maximum amount is required")
            //     .positive("Maximum amount must be a positive number") // Optional: Ensure positive value
            //     .when('minDepositLimit', (minDepositLimit, schema) =>
            //         schema.moreThan(minDepositLimit, 'Maximum amount must be greater than Minimum amount')
            //     ),
            // depositFee: Yup.number()
            //     .typeError("Fee must be a number")
            //     .required("Fee is required")
            //     .min(0, "Fee must be a positive number or zero"), // Optional: Ensure positive value
            // depositFeeType: Yup.string().required("Fee Type is required"),
            // dayLimit: Yup.number()
            //     .typeError("Day Limit must be a number")
            //     .required("Day Limit is required")
            //     .when('maxDepositLimit', (maxDepositLimit, schema) =>
            //         schema.min(maxDepositLimit, 'Day Limit must be equal to or greater than Maximum Withdrawal Limit')
            //     ),
            // tds: Yup.number()
            //     .typeError("TDS must be a number"),
   

               

        }),
        onSubmit: (values) => {

            handleDepositUpdate(values)

        },
    });
    

    // const handleCheckboxChange = (index) => {
    //     console.log(index,"UpdatedNetwork")
    //     const updatedNetworks = network.map((network, i) => {
    //         if (i === index) {
    //             return {
    //                 ...network,
    //                 networkType: network.networkType === 1 ? 0 : 1
    //             };
    //         }
    //         return network;
    //     });
    //     setNetwork(updatedNetworks);
    // };

    const handleCheckboxChange = (index) => {
        console.log(index,"INNNNNNNNNNNNNNNNNNNN");
        console.log("INNNNNNNNNefdfNNNNNNNNNNN");
        
        const updatedNetworks = network.map((network, i) => {
          if (i === index) {
            return {
              ...network,
              networkType: network.networkType === 1 ? 0 : 1
            };
          }
          return network;
        });
        console.log(index, "Updated Network:", updatedNetworks); // Check here for updates
        setNetwork(updatedNetworks);
      };
      
    const handleDepositCheckboxChange = (index) => {
        const updatedNetworks = depositNetwork.map((network, i) => {
            if (i === index) {
                return {
                    ...network,
                    networkType: network.networkType === 1 ? 0 : 1
                };
            }
            return network;
        });

        setDepositNetwork(updatedNetworks);
    };

    const handleFeeTypeChange = (e) => {
        const { value } = e.target;
        validationWithDraw.handleChange(e);

        if (value === "1") { // Percentage
            setFeeSymbol('%');
        } else if (value === "0") { // Flat or Fixed
            setFeeSymbol(recordToUpdate.symbol);
        }
    };

    const handleDepositFeeType = (e) => {
        const { value } = e.target;
        validationDeposit.handleChange(e);

        if (value === "1") { // Percentage
            setFeeDepositSymbol('%');
        } else if (value === "0") { // Flat or Fixed
            setFeeDepositSymbol(recordToUpdate.symbol);
        }
    };
    
    const handleWithDrawUpdate = async (data) => {

        const postForm = {
            minWithdrawalLimit: data.minWithdrawalLimit,
            maxWithdrawalLimit: data.maxWithdrawalLimit,
            withdrawalFeeType: data.withdrawalFeeType,
            withdrawalFee: data.withdrawalFee,
            dayLimit: data.dayLimit,
            withdrawalCoinId: recordToUpdate.coinId,
            currency: recordToUpdate.symbol,
            // tds: data.tds,
            networks: network.filter(net => net.networkType === 1),
            walletId: Number(data.walletId),
            withdrawType: data.withdrawType,
            // contractAddress:data.contractaddress


        }
        if (tdsStatus) {
            postForm.tds = data.tds
        }

        setLoading(true)
        console.log(postForm, "8888888888888888888888")
        try {
            const response = await postData(Apiservices.WITHDRAW_CREATE_API, postForm)
            console.log(response, "22222222222222222")
            if (response.status) {
                coinList()
                setLoading(false)
                setmodel_withdraw(false)
                toast.success(response.message)
                setmodal_list(false)



            } else {
                setLoading(false)
                toast.error(response.message)

            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later")
            setLoading(false)


            validation.resetForm();
        }

    }


    const handleDepositUpdate = async (data) => {

        const postForm = {
            minDepositLimit: data.minDepositLimit,
            maxDepositLimit: data.maxDepositLimit,
            // depositFeeType: data.depositFeeType,
            depositFee: data.depositFee,
            dayLimit: data.dayLimit,
            depositCoinId: recordToUpdate.coinId,
            currency: recordToUpdate.symbol,
            // tds: data.tds,
            networks: depositNetwork.filter(net => net.networkType === 1),
            walletId:Number(data.walletId),
            depositType:data.depositType,
            // contractAddress:data.contractaddress


        }
        if (tdsStatus) {
            postForm.tds = data.tds
        }

        setLoading(true)
            console.log(postForm,"8888888888888888888888")
        try {
            const response = await postData(Apiservices.DEPOSIT_CREATE_API, postForm)
            console.log(response,"22222222222222222")
            if (response.status) {
                coinList()
                setLoading(false)
                setmodel_deposit(false)
                toast.success(response.message)
                setmodal_list(false)
                handlePagePost(currentPage)


            } else {
                setLoading(false)
                toast.error(response.message)

            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later")
            setLoading(false)


            validation.resetForm();
        }

    }
    const [image, setImage] = useState(null);
    // const [defaultImage, setDefaultImage] = useState('path/to/default/image.jpg'); // Set your default image path
    const [error, setError] = useState('');


    const validateImage = (file) => {
        const allowedTypes = ['image/png', 'image/jpeg'];
        if (!allowedTypes.includes(file.type)) {
            setError('Only JPG and PNG formats are allowed.');
            return false;
        }
        setError('');
        return true;
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file)
    };

    const [AddLoad, setAddLoad] = useState(false)
    const handlePost = async (data) => {
        // Image validation
        if (!image) {
            toast.error("Image is required");
            setLoading(false);
            return;
        }


        const postForm = new FormData();
        postForm.append('name', data.coinName);
        postForm.append('symbol', data.symbol);
        postForm.append('coinType', data.coinType);
        postForm.append('currencyType', data.currencyType);

        // postForm.append('files', fileList[0].originFileObj);
        postForm.append('files', image);
        postForm.append('decimalPoint', data.decimalpoint);
        postForm.append('depositType', data.depositType);
        postForm.append('withdrawType', data.withdrawType);
        postForm.append('depositWalletId', data.depositWalletId);
        postForm.append('walletId', data.walletId);
        postForm.append('memoStatus', data.memostatus);

        setLoading(true)

        try {
            setAddLoad(true)
            const response = await postData(Apiservices.USER_COIN_CREATE, postForm, {

            })
            if (response.status) {
                coinList()
                setLoading(false)

                toast.success(response.message)
                setmodal_list(false)
                validation.resetForm();
                setAddLoad(false)

            } else {
                setLoading(false)
                toast.error(response.message)
                setAddLoad(false)

            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later")
            setLoading(false)
            setAddLoad(false)



            // validation.resetForm();
        }




    }
    const [modal_update_list, setmodal_update_list] = useState(false);
    function tog_update_list() {
        setImage('')
        setmodal_update_list(!modal_update_list);
    }

    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        validation.resetForm()
        setmodal_list(!modal_list);
        setImage('')
    }
    function tog_withdraw() {
        setTDSstatus(false)
        setmodel_withdraw(!model_withdraw);
        setNetwork([])
    }
    function tog_deposit() {
      
        setmodel_deposit(!model_deposit);
        setNetwork([])
    }
    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }



    const [getImage, setGetImage] = useState('')
    const [getImageName, setImageName] = useState('')
    const [getRecord,setStatusRecord] = useState('')
const [getCoinType,setCoinType] = useState('')
    const handleUpdate = (record) => {
        console.log(record, "22222222222222")
        setStatusRecord(record)
        setGetImage(record.image)
        setImageUrl(record.image)
        setImageName(record.image.substring(imageUrl.lastIndexOf('/') + 1))
        validationUpdate.setValues({
            coinName: record.name || '',
            symbol: record.symbol || '',
            image: record.image || '',
            isActive: record.isActive !== undefined ? String(record.isActive) : '',
            depositStatus: record.depositStatus !== undefined ? String(record.depositStatus) : '',
            withdrawStatus: record.withdrawStatus !== undefined ? String(record.withdrawStatus) : '',
            coinType: record.coinType !== undefined ? String(record.coinType) : '',
            depositType: record.depositType !== undefined ? String(record.depositType) : '',
            currencyType: record.currencyType !== undefined ? String(record.currencyType) : '',
            decimalpoint: record.decimalPoint === 0 ? '0' : (record.decimalPoint || ''),
            depositWalletId: record.depositWalletId,
            walletId: record.walletId,
            withdrawType: record.withdrawType !== undefined ? String(record.withdrawType) : '',
            memostatus: record.memoStatus !== undefined ? String(record.memoStatus) : '',


        });
        setRecordToUpdate(record);
        setFileList1([
            {
                uid: '-1',
                url: record.image, // Update with the fetched URL
            },
        ]);
        setmodal_update_list(true)
    }
    const [imageUrl, setImageUrl] = useState('')
    const confirmUpdate = async (values) => {

        if (!image && !getImage) {
            toast.error("Image is required");
            setLoading(false);
            return;
        }
        const postForm = new FormData();
        postForm.append('name', values.coinName);
        postForm.append('symbol', values.symbol);
        if (image) {
            postForm.append("files", image);
        } else if (getImage) {
            postForm.append("files", getImage);
        }

        // postForm.append('isActive', values.isActive);
        // postForm.append('depositStatus', values.depositStatus);
        // postForm.append('withdrawStatus', values.withdrawStatus);
        postForm.append('isActive', getRecord?.isActive);
        postForm.append('depositStatus', getRecord?.depositStatus);
        postForm.append('withdrawStatus', getRecord?.withdrawStatus);
        postForm.append('coinType', values.coinType);

        postForm.append('currencyType', values.currencyType);
        postForm.append('depositType', values.depositType);

        postForm.append('decimalPoint', values.decimalpoint);
        postForm.append('coinId', recordToUpdate.coinId);
        postForm.append('withdrawType', Number(values.withdrawType));
        postForm.append('walletId', values.walletId);
        postForm.append('depositWalletId', values.depositWalletId);
        postForm.append('memoStatus', values.memostatus);


        // const postQurey = {
        //     coinId: recordToUpdate.coinId
        // }
        setDeleteLoad(true)
        // const queryString = new URLSearchParams(postQurey).toString();
        try {
            setLoading(true)
            const response = await postData(Apiservices.USER_COIN_UPDATE, postForm)
            // const response = await postData(Apiservices.USER_COIN_UPDATE,postForm)
            if (response.status) {
                setmodal_update_list(false)
                setDeleteLoad(false)
                toast.success(response.message)
                setmodal_delete(false)
                coinList()
                setLoading(false)
                setImage(null);
                handlePagePost(currentPage)

            } else {
                toast.error(response.message)
                validation.resetForm();
                setmodal_delete(false)
                setDeleteLoad(false)
                setLoading(false)
                setImage(null);
            }

        } catch (error) {
            setDeleteLoad(false)
            toast.error("Internal Server Error..Try Again Later")
            setLoading(false)

            setmodal_delete(false)


            validation.resetForm();
        }



    }


    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = formData.filter(item => {
        const isNameMatch = item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase());
        const isSymbolMatch = item.symbol && item.symbol.toLowerCase().includes(searchTerm.toLowerCase());
        return isNameMatch || isSymbolMatch;
    });
    // const pageCount = Math.ceil(filteredData.length / itemsPerPage);
    const validCurrentPage = currentPage >= pageCount ? 0 : currentPage;

    // Slice the filtered data for pagination
    const displayedData = filteredData.slice(
        validCurrentPage * itemsPerPage,
        (validCurrentPage + 1) * itemsPerPage
    );


    const handleToggleModel = (row, action) => {
        setResend({ row: row, userStatus: action });
        setmodalCount(true);
    };

    const handleToggle = async () => {
        const permissions = [{
            module: 'Coin Management',
            actions: [2]
        }];


        const updatedRow = {
            ...resend.row,
            [resend.userStatus]: resend.row[resend.userStatus] === 1 ? 0 : 1 // Toggle the status type
        };
        const postQurey = {
            coinId: resend.row.coinId,

        }

        const queryString = new URLSearchParams(postQurey).toString();
        // const newStatus = !row.isActive;

        const postForm1 = {
            name: resend.row.name,
            symbol: resend.row.symbol,
            files: resend.row.image,
            isActive: updatedRow.isActive,
            withdrawStatus: updatedRow.withdrawStatus,
            depositStatus: updatedRow.depositStatus,
            selection: updatedRow.selection,
            coinId: resend.row.coinId,
            memoStatus:updatedRow.memoStatus
        }
        const postForm = new FormData();
        postForm.append('isActive', updatedRow.isActive);
        postForm.append('depositStatus', updatedRow.depositStatus);
        postForm.append('withdrawStatus', updatedRow.withdrawStatus);
        postForm.append('symbol', resend.row.symbol);
        postForm.append('decimalPoint', resend.row.decimalPoint);
        postForm.append('coinId', resend.row.coinId);
        postForm.append('selection', updatedRow.selection);
        postForm.append('image', resend.row.image);
        postForm.append('name', resend.row.name);
        postForm.append('currencyType', resend.row.currencyType);
        postForm.append('permissions', permissions);
        postForm.append('adminType', adminInfo?.adminType);
        postForm.append('memoStatus', updatedRow.memoStatus);

        console.log(JSON.stringify(permissions), "6666666666666666")

        setLoading(true)
        try {
            // const response = await postData(`${Apiservices.USER_COIN_UPDATE}?${queryString}`, postForm)
            const response = await postData(Apiservices.USER_COIN_UPDATE, postForm)
            if (response.status) {
                toast.dismiss()
                setLoading(false)
                toast.success(response.message)
                handlePagePost(currentPage)
                // coinList()
                setmodalCount(false);

            } else {
                toast.error(response.message)
                setLoading(false)
                setmodalCount(false);
            }

        } catch (error) {
            toast.dismiss()
            toast.error("Internal Server Error..Try Again Later")
            setLoading(false)
        }

    }
  
    const handleDepositSettings = async (data) => {
        setRecordToUpdate(data);
        setCurrencyINR(data.symbol)
        const postForm = {
            depositCoinId: data.coinId,
        };
        // setDeleteLoad(true);
        try {
            const response = await postData(Apiservices.DEPOSIT_SETTING_API, postForm);
            console.log(response,"responseresponse");
            
            if (response.status) {
                setmodel_deposit(true);
                setDeleteLoad(false);
                if (response.data.feeType === 0) {
                    setFeeDepositSymbol(data.symbol);
                } else if (response.data.feeType === 1) {
                    setFeeDepositSymbol('%');
                } else {
                    setFeeDepositSymbol('N/A');
                }
    
                if (response.data.networks) {
                    const updatedNetworks = response.data.networks.map(network => {
                        if (network.networkType === 0) {
                            const matchingNetworkType = response.data.settings?.networkTypes.find(
                                networkType => networkType.networkId === network.networkId && networkType.networkType === 1
                            );
                            if (matchingNetworkType) {
                                return { ...network, networkType: 1 };
                            }
                        }
                        return network;
                    });
                    if (response.data.currency.currencyType === 0) {
                        setDepositNetwork(updatedNetworks);
                    }
                } 
    
                setTDSstatus(response.data.currency.currencyType === 1 && response.data.currency.symbol === "INR");
          
                if (response.data.settings) {
                    validationDeposit.setValues({
                        minDepositLimit: response.data.settings.minAmount,
                        maxDepositLimit: response.data.settings.maxAmount,
                        depositFee: response.data.settings.fee,
                        dayLimit: response.data.settings.dayLimit,
                        tds: response.data.settings.tds,
                        depositFeeType: response.data.settings.feeType !== undefined ? String(response.data.settings.feeType) : '',
                        
                        // walletId:response.data.settings.walletId,
                        // withdrawType: response.data.settings.withdrawType !== undefined ? String(response.data.settings.withdrawType) : '',
                        // contractaddress: response.data.settings.contractAddress,
                    });
                }
    
                validationDeposit.setErrors({});
            } else {
                setDeleteLoad(false);
                toast.error(response.message);
            }
        } catch (error) {
            console.error(error, "ERROR");
            setDeleteLoad(false);
        }
    };

    const handleWithDraw = async (data) => {

        setCoinType(data.coinType)

        const permissions = [{
            module: 'Coin Management',
            actions: [2]
        }];
        setRecordToUpdate(data);
        const postForm = {
            withdrawalCoinId: data.coinId,
            permissions: permissions,
            adminType: adminInfo?.adminType
        };
        setDeleteLoad(true);
        try {
            const response = await postData(Apiservices.WITHDRAW_SETTING_API, postForm);
            console.log(response,'MANi');
            
            if (response.status) {
                setmodel_withdraw(true);
                setDeleteLoad(false);
                if (response.data.feeType === 0) {
                    setFeeSymbol(data.symbol);
                } else if (response.data.feeType === 1) {
                    setFeeSymbol('%');
                } else {
                    setFeeSymbol('N/A');
                }

                if (response.data.networks) {
                   
                    
                    const updatedNetworks = response.data.networks.map(network => {
                        if (network.networkType === 0) {
                            const matchingNetworkType = response.data.settings?.networkTypes.find(
                                networkType => networkType.networkId === network.networkId && networkType.networkType === 1
                            );
                            if (matchingNetworkType) {
                                return { ...network, networkType: 1 };
                            }
                        }
                        return network;
                    });
                    if (response.data.currency.currencyType === 0) {
                        console.log(updatedNetworks,"updatedNetworks12")
                        setNetwork(updatedNetworks);
                    }
                    console.log(updatedNetworks,"updatedNetworks");
                    
                }

                setTDSstatus(response.data.currency.currencyType === 1 && response.data.currency.symbol === "INR");

                if (response.data.settings) {
                    validationWithDraw.setValues({
                        minWithdrawalLimit: response.data.settings.minAmount,
                        maxWithdrawalLimit: response.data.settings.maxAmount,
                        withdrawalFee: response.data.settings.fee,
                        dayLimit: response.data.settings.dayLimit,
                        tds: response.data.settings.tds,
                        withdrawalFeeType: response.data.settings.feeType !== undefined ? String(response.data.settings.feeType) : '',
                        // walletId:response.data.settings.walletId,
                        // withdrawType: response.data.settings.withdrawType !== undefined ? String(response.data.settings.withdrawType) : '',
                        // contractaddress: response.data.settings.contractAddress,
                    });
                }

                validationWithDraw.setErrors({});
            } else {
                setDeleteLoad(false);
                toast.error(response.message);
            }
        } catch (error) {
            console.error(error, "ERROR");
            setDeleteLoad(false);
        }
    };


    const handleDelete = (value) => {
        showUserBlockAlert(confirmDelete, value)
    }

    const showUserBlockAlert = (onConfirm, row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to remove this record!`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            preConfirm: async () => {
                try {
                    await onConfirm(row);
                } catch (error) {
                    Swal.fire('Error!', 'There was an error deleting the record', 'error');
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                // Additional actions after confirmation, if needed
            }
        });
    };

    const confirmDelete = async (row) => {
        const postForm = {
            coinId: Number(row.coinId)
        }

        setDeleteLoad(true)
        try {
            const response = await postData(Apiservices.USER_COIN_DELETE, postForm)

            if (response.status === true) {
                setDeleteLoad(false)
                // toast.success(response.message)
                Swal.fire('Success!', `${response.message}`, 'success');
                coinList()

            } else {
                // toast.error(response.message)
                Swal.fire('Error!', `${response.message}`, 'error');
                setDeleteLoad(false)

            }

        } catch (error) {


            setDeleteLoad(false)
            toast.error("Internal Server Error..Try Again Later")
        }
    }


    const allowedActions = [1, 2, 3, 4];
    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true,
            minWidth: '100px',
        },
        {
            name: 'Name',
            selector: row =>
                <div className="">
                    <img style={{ width: '20px' }} src={row.image} alt="" />
                    <span className='mx-2'>{handleNullValue(row.name)}</span>
                </div>,
            sortable: true,
            minWidth: '250px',
        },
        {
            name: 'Symbol',
            selector: row => handleNullValue(row.symbol),
            sortable: true,
            minWidth: '100px',
        },
        {
            name: 'Currency Type',
            selector: row => handleNullValue(row.currencyType == 1 ? ('Fiat') : ('Cryto')),
            sortable: true,
            minWidth: '150px',
        },
        // {
        //     name: 'Decimal Point',
        //     selector: row => handleNullValue(row.decimalPoint),
        //     sortable: true,
        //     minWidth: '150px',
        // },
        ...(adminType === 1 || adminInfo?.permissions.some(data => 
            data.module === "Coin Management" && data.actions.includes(3)) ? [{


                name: 'Status',
                selector: row => (
                    <div className="form-check form-switch form-switch-md d-flex justify-content-center align-items-center">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizemd"
                            checked={row.isActive === 1}
                            onChange={() => handleToggleModel(row, 'isActive')}
                            disabled={loading}
                        />
                    </div>
                ),
                minWidth: '100px',
                sortable: true
            }] : []),
        ...(adminType === 1 || adminInfo?.permissions.some(data =>
            data.module === "Coin Management" && data.actions.includes(3)) ? [{

                name: 'Compare Exchange',
                // selector: row => row.isActive ? 'Active' : 'Inactive',
                selector: row => (
                    <div className="form-check form-switch form-switch-md d-flex justyfy-content-center align-items-center">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizemd"
                            checked={row.selection === 1}
                            onChange={() => handleToggleModel(row, 'selection')}
                            disabled={loading || row.isActive !== 1}
                        // onChange={() => handleToggle(row, 'isActive')}
                        // disabled={loading}
                        />

                    </div>
                ),

                minWidth: '170px',
                sortable: true
            }] : []),
            ...(adminType === 1 || adminInfo?.permissions.some(data =>
                data.module === "Coin Management" && data.actions.includes(3)) ? [{
    
                    name: 'Memo Status',
                    // selector: row => row.isActive ? 'Active' : 'Inactive',
                    selector: row => (
                        <div className="form-check form-switch form-switch-md d-flex justyfy-content-center align-items-center">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizemd"
                                checked={row.memoStatus === 1}
                                onChange={() => handleToggleModel(row, 'memoStatus')}
                                disabled={loading || row.isActive !== 1}
                            // onChange={() => handleToggle(row, 'isActive')}
                            // disabled={loading}
                            />
    
                        </div>
                    ),
    
                    minWidth: '150px',
                    sortable: true
                }] : []),
        ...(adminType === 1 || adminInfo?.permissions.some(data =>
            data.module === "Coin Management" && data.actions.includes(3)) ? [{

                name: 'Deposit Status',
                selector: row => (
                    <div className="d-flex justify-content-center align-items-center flex-wrap">
                        {/* Deposit Status Switch */}
                        <div className="form-check form-switch form-switch-md">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={`depositStatusSwitch-${row.id}`} // Unique ID for accessibility
                                checked={row.depositStatus === 1}
                                onChange={() => handleToggleModel(row, 'depositStatus')}
                                // disabled={loading}
                                disabled={loading || row.isActive !== 1}
                            />
                        </div>


                        <i
                            className={`fas fa-cog fs-5 ${deleteLoad && recordToUpdate === row ? 'loading-class' : ''}`}
                            onClick={() => handleDepositSettings(row)}
                            style={{ cursor: 'pointer', marginRight: '15px' }}
                        ></i>


                        {/* <label htmlFor={`toggle1-${row.id}`} className="button1" >
                        <input type="checkbox"
                         id="toggle1"
                         checked={row.networkEnableDisable === 1}
                         onChange={() => handleUpdateStatusClick(row)}
                         disabled={loading}
                        />
                        <span className="slider1">
                            <i className="mdi mdi-signal"  id="on-icon" />
                            <i className="mdi mdi-signal-off" id="off-icon" />
                        </span>
                        
                        </label> */}
                    </div>
                ),
                sortable: true,
                responsive: true,
                minWidth: '150px',



            }] : []),


        ...(adminType === 1 || adminInfo?.permissions.some(data =>
            data.module === "Coin Management" && data.actions.includes(3)) ? [{


                name: 'Withdraw Status',
                // selector: row => row.isActive ? 'Active' : 'Inactive',
                selector: row => (
                    <div className="form-check form-switch form-switch-md d-flex justyfy-content-center align-items-center">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizemd"
                            checked={row.withdrawStatus === 1}
                            onChange={(e) => handleToggleModel(row, 'withdrawStatus')}
                            // onChange={() => handleToggle(row, 'withdrawStatus')}
                            // disabled={loading}
                            disabled={loading || row.isActive !== 1}
                        />
                        <i style={{ cursor: 'pointer', marginRight: '15px' }} className={`fas fa-cog fs-5 ${deleteLoad && recordToUpdate === row ? 'fa-spin' : ''}`} onClick={() => handleWithDraw(row)}></i>
                        {/* <span className="mdi mdi-cog-outline" style={{ width: '30px', fontSize: '18px', cursor: "pointer" }} onClick={() => navigate('/networks',{state:row})}></span> */}
                        <i className="fas fa-signal" style={{ cursor: "pointer" }} onClick={() => navigate('/network', { state: row })}></i>
                        {/* {deleteLoad && recordToUpdate === row  ? (<Spinner size="sm" />) : (<span className="mdi mdi-cog-outline fs-3"  onClick={() => handleWithDraw(row)}></span>)} */}
                        {/* <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        className="me-1 mb-sm-8 mb-2 mt-2"
                        checked={row.networkEnableDisable === 1}
                        onChange={() => handleUpdateStatusClick(row)}
                        onColor="#02a499" 
                            offColor="#929292" 
                            handleDiameter={12}
                            height={20} 
                            width={36} 
                            // onChange={() => {
                            //     setswitch1(!switch1);
                            // }}
                            // checked={switch1}
                        /> */}

                    </div>
                ),


                sortable: true,
                minWidth: '150px',


            }] : []),
        {
            name: 'Withdraw Type',
            selector: row => handleNullValue(
                row.withdrawType === 0 ? 'Manual' : 
                row.withdrawType === 1 ? 'Automatic' :'-'
                // row.withdrawType === 2 ? 'Internal' : '-'
            ),
            sortable: true,
            minWidth: '150px'
        },

        // {
        //     name: 'Network Status',    //  withdraw network enable disable
        //     // selector: row => row.isActive ? 'Active' : 'Inactive',
        //     selector: row => (
        //        <>

        //             <div className="d-flex align-items-center">
        //                 {/* <div className="form-check form-switch form-switch-md position-relative ">
        //                     <input
        //                         type="checkbox"
        //                         className="form-check-input"
        //                         id={`activeStatusSwitch-${row.id}`}
        //                         checked={row.networkEnableDisable === 1}
        //                         onChange={() => handleUpdateStatusClick(row)}
        //                         disabled={loading}
        //                     />
        //                 </div> */}
        //                 <div style={{ display: "flex", alignItems: "center" }}>
        //                 <Switch
        //                 uncheckedIcon={<Offsymbol />}
        //                 checkedIcon={<OnSymbol />}
        //                 className="me-1 mb-sm-8 mb-2"
        //                 checked={row.networkEnableDisable === 1}
        //                 onChange={() => handleUpdateStatusClick(row)}
        //                 onColor="#02a499" 
        //                     offColor="#929292" 
        //                     handleDiameter={12}
        //                     height={20} 
        //                     width={36} 
        //                     // onChange={() => {
        //                     //     setswitch1(!switch1);
        //                     // }}
        //                     // checked={switch1}
        //                 />
        //                  <div style={{ marginLeft: 10, fontSize: 12 }}>
        //                  {switch1 ? "Yes" : "No"}
        //                     </div>
        //                     </div>

        //             </div>

        //             </>

        //     ),


        //     sortable: true
        // },

        ...(adminType === 1 || adminInfo?.permissions.some(data =>
            data.module === "Coin Management" && data.actions.includes(3)) ? [{


                name: 'Action',
                cell: row => (
                    <div className="d-flex gap-2">

                        {/* <button
                            onClick={() => handleDelete(row)}
                            className="btn btn-sm btn-danger remove-item-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteRecordModal"
                        >

                            Remove
                        </button> */}


                        <span className="mdi mdi-border-color fs-4 cursor-pointer" style={{ cursor: 'pointer' }} onClick={() => handleUpdate(row)}></span>

                        {(adminType === 1 || adminInfo?.permissions.some(data => 
                                            data.module === "Coin Management" && Array.isArray(data.actions) && data.actions.includes(4)
                                        )) ? (
                                            <i onClick={() => handleDelete(row)} className="mdi mdi-trash-can-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></i>
                                        ) : null}

                    </div>

                ),
                ignoreRowClick: true,
                minWidth: '100px',



                // allowOverflow: true,
                // button: true,
            }] : []),
    ];


    const [fileList, setFileList] = useState([]);
    const [fileList1, setFileList1] = useState([
        {
            uid: "-1",
            url: '',
        }
    ]);


    const [previewImage, setPreviewImage] = useState(null);
    const [modal, setModal] = useState(false);
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const onChange1 = ({ fileList: newFileList }) => {
        setFileList1(newFileList);
    };
    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        setPreviewImage(src);
        setModal(true);
    };
    const toggleModal = () => {
        setModal(!modal);
    };

    const onRemove = file => {
        setFileList1(fileList1.filter(item => item.uid !== file.uid));
    };




    const handleNetworkChange = (index, key, value) => {
        console.log(index, key, value,"2222222222222222222222222")

        const updatedNetworks = network.map((network, i) => {
            if (i === index) {
                return {
                    ...network,
                    [key]: value
                };
            }
            return network;
        });
        console.log(updatedNetworks,"updatedNetworks5356246")
        setNetwork(updatedNetworks);
    };
    
    const handleDepositNetworkChange = (index, key, value) => {
        const updatedNetworks = depositNetwork.map((network, i) => {
            if (i === index) {
                return {
                    ...network,
                    [key]: value
                };
            }
            return network;
        });
    
        setDepositNetwork(updatedNetworks);
    };
    const showStatusAlert = (onConfirm, row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Are You Sure Want to Disable or Enable All network Status!",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update it!',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
            preConfirm: () => {
                return onConfirm(row)
                    .then(() => {
                        return Promise.resolve();
                    })
                    .catch((error) => {
                        Swal.showValidationMessage(`Request failed: ${error}`);
                    });
            }
        }).then((result) => {
            if (result.isConfirmed) {
                // Success handling if needed
                // Swal.fire('Success !', 'The status has been updated successfully', 'success');
            }
        });
    };
    const handleUpdateStatusClick = (row) => {
        showStatusAlert(handleToggle1, row);
    }
    const handleToggle1 = async (rowDetails) => {
        const updatedStatus = rowDetails.networkEnableDisable === 1 ? 0 : 1;
        const postForm = {
            networkEnableDisable: updatedStatus,
            coinId: rowDetails.coinId

        }

        setLoading(true)

        try {
            const response = await postData(Apiservices.WITHDRAW_CREATE_API, postForm)
            if (response.status) {
                coinList()
                setLoading(false)
                setmodel_withdraw(false)
                toast.success(response.message)
                setmodal_list(false)



            } else {
                setLoading(false)
                toast.error(response.message)

            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later")
            setLoading(false)


            validation.resetForm();
        }
    }
    const [btnauto3, setbtnauto3] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');



    const handleClickFilter = async (item) => {
        setSelectedItem(item);

        if(item === ''){
            coinList('')
        }
        else{
        const postForm = {
            isActive: Number(item),
        };
        try {
            setLoading(true);
            const response = await postData(Apiservices.USER_COIN_LIST, postForm);
            if (response.status) {
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit);
                setCount(response.data.currentCount);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    }
    };
    const [searchValid, setSearchValid] = useState(false);
    const [inputSearch, setinputSearch] = useState('');
    const [searchLoad, setSearchLoad] = useState(false);

    const [user, setUser] = useState(Apiservices.USER_COIN_LIST);

    const handleSearchChanage = (e) => {
        const value = e.target.value
        if (value === '' && user === undefined) {
            coinList()
            setSearchValid(false)
        } else if (value === '') {

            handleFilter(user)
        }
        setinputSearch(value)

    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };
    const handleSearch = async () => {
        if (inputSearch !== '') {
            setSearchLoad(true);
            const postForm = {
                search: inputSearch,
                // page: currentPage + 1,
                // limit: itemsPerPage,
            };

            const queryString = new URLSearchParams(postForm).toString();
            try {

                // const response = await postData(`${Apiservices.USER_COIN_LIST}?${queryString}`,);
                const response = await postData(Apiservices.USER_COIN_LIST, postForm);
                if (response.status) {
                    if (response.data.length !== 0) {
                        setSearchLoad(false);
                        setFormData(response.data.data);
                        setTotalPagination(response.data.totalPages);

                    }
                    else {
                        setFormData(response.data);
                        setSearchLoad(false);
                        // setDateRange("")
                    }

                    // toast(response.message);
                } else {
                    setSearchLoad(false);
                }

            } catch (error) {

                toast.error("Internal Server Error..Try Again Later");
                setSearchLoad(false);
            }
        } else {
            setSearchValid(true)
        }
    }


    
    const handleFilter = async (apiName) => {
        setLoading(true);
        // const postForm = {
        //     ...filterParams
        // };
        try {
            const response = await postGetData(apiName);

            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);

            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };
console.log(network,'network');

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Coin Management" breadcrumbItem="Coin List" />
                    <Row>
                        <Col lg={12}>

                            <Card>


                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col sm={4} lg={3}>
                                                <div className="input-group">
                                                    <Input
                                                        // onChange={handleInputChange}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search ..."
                                                        aria-label="Recipient's username"
                                                        onChange={handleSearchChanage}
                                                        invalid={searchValid}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                    <div className="input-group-append">

                                                        <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                                                            {searchLoad ? (

                                                                <Spinner className="text-light" size="sm" />
                                                            ) : (

                                                                <i className="ri-search-line" />
                                                            )}

                                                        </button>


                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="col-sm">


                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="mx-2">
                                                        <select
                                                            className="form-select"
                                                            value={selectedItem}
                                                            onChange={(e) => handleClickFilter(e.target.value)}
                                                        >
                                                            <option value="" disabled>Select Status</option>
                                                            {/* Static options */}
                                                            <option value="">Show All</option>
                                                            <option value={1}>Active</option>
                                                            <option value={0}>Deactive</option>
                                                        </select>
                                                    </div>

                                                    {(adminType === 1 || adminInfo?.permissions.some(data =>
                                                        data.module === "Coin Management" && data.actions.includes(1))) ? (
                                                        <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                                                    ) : null}
                                                    {/* <Button color="soft-danger"
                                                       
                                                    ><i style={{ fontSize: '15px' }} className="mdi mdi-download-circle-outline"></i>Download</Button> */}

                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-scroll table-card mt-3 mb-1">
                                            <DataTable
                                                columns={columns}
                                                data={formData}
                                                paginationServer
                                                paginationTotalRows={formData.length}
                                                persistTableHead={true}
                                                progressPending={loading}
                                                progressComponent={<div className="py-3">Loading...</div>}
                                                noDataComponent={<div className="py-3">No records found</div>}
                                                // noDataComponent={<div>No records found</div>}
                                                fixedHeader={true}



                                            />




                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-6">
                                                <div className="d-flex justify-content-start mt-3">
                                                    <span>Current Count : {count}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                {loading ? (
                                                    ""
                                                ) : (
                                                    <div className="d-flex justify-content-end">
                                                        <div className="pagination pagination-rounded mb-0">
                                                            <ReactPaginate
                                                                previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                                nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageClick}
                                                                containerClassName={"pagination"}
                                                                activeClassName={"active"}
                                                                pageLinkClassName={"page-link"}
                                                                breakLinkClassName={"break-link"}
                                                                disabledClassName={"disabled"}
                                                                initialPage={currentPage}
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                                renderLink={(props) => {
                                                                    if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                                                        return (
                                                                            <a
                                                                                {...props}
                                                                                onClick={() =>
                                                                                    handlePageClick({
                                                                                        selected: pageCount - 1,
                                                                                    })
                                                                                }
                                                                            >
                                                                                {pageCount}
                                                                            </a>
                                                                        );
                                                                    }
                                                                    return <a {...props} />;
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>





                </Container>
            </div>

            {/* Update */}
            <Modal className='modal-dialog modal-lg' isOpen={modal_update_list} toggle={() => { tog_update_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}> Coin Update </ModalHeader>
                <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validationUpdate.handleSubmit();
                        return false
                    }}
                >
                    <ModalBody className='d-lg-flex justify-content-around'>

                        <div className="col-lg-5 col-sm-12">
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Coin Name</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Coin Name"
                                    name="coinName"
                                    onChange={validationUpdate.handleChange}
                                    onBlur={validationUpdate.handleBlur}
                                    value={validationUpdate.values.coinName || ""}
                                    invalid={validationUpdate.touched.coinName && validationUpdate.errors.coinName}
                                />
                                {validationUpdate.touched.coinName && validationUpdate.errors.coinName && (
                                    <FormFeedback type="invalid">{validationUpdate.errors.coinName}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Symbol</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Symbol"
                                    name="symbol"
                                    onChange={validationUpdate.handleChange}
                                    onBlur={validationUpdate.handleBlur}
                                    value={validationUpdate.values.symbol || ""}
                                    invalid={validationUpdate.touched.symbol && validationUpdate.errors.symbol}
                                />
                                {validationUpdate.touched.symbol && validationUpdate.errors.symbol && (
                                    <FormFeedback type="invalid">{validationUpdate.errors.symbol}</FormFeedback>
                                )}
                            </div>
                            {/* <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Image URL</label>
                                <Input
                                    type="file"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Image URL"
                                    name="image"
                                    ref={fileInputRef}
                                    onChange={handleFileUpdateChange}
                                    onBlur={validationUpdate.handleBlur}
                                    // value={validationUpdate.values.image || ""}
                                    invalid={validationUpdate.touched.image && validationUpdate.errors.image}
                                />
                                {validationUpdate.touched.image && validationUpdate.errors.image && (
                                    <FormFeedback type="invalid">{validationUpdate.errors.image}</FormFeedback>
                                )}
                            </div> */}
                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Coin Type</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="coinType"
                                    onChange={validationUpdate.handleChange}
                                    onBlur={validationUpdate.handleBlur}
                                    value={validationUpdate.values.coinType || ""}
                                    className={`form-control ${validationUpdate.touched.coinType && validationUpdate.errors.coinType ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select CoinType</option>
                                    <option value="0">Coin</option>
                                    <option value="1">Token</option>
                                </select>
                                {validationUpdate.touched.coinType && validationUpdate.errors.coinType && (
                                    <FormFeedback type="invalid">{validationUpdate.errors.coinType}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Currency Type</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="currencyType"
                                    onChange={validationUpdate.handleChange}
                                    onBlur={validationUpdate.handleBlur}
                                    value={validationUpdate.values.currencyType || ""}
                                    className={`form-control ${validationUpdate.touched.currencyType && validationUpdate.errors.currencyType ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select Currency Type</option>
                                    <option value="0">Crypto</option>
                                    <option value="1">Fiat</option>
                                </select>
                                {validationUpdate.touched.currencyType && validationUpdate.errors.currencyType && (
                                    <FormFeedback type="invalid">{validationUpdate.errors.currencyType}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3 mt-2">
                                <label htmlFor="customername-field" className="form-label">Liminal Deposit Wallet Id</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Liminal Wallet Id"
                                        name="depositWalletId"
                                        onChange={validationUpdate.handleChange}
                                        onBlur={validationUpdate.handleBlur}
                                        value={validationUpdate.values.depositWalletId || ""}
                                        invalid={validationUpdate.touched.depositWalletId && validationUpdate.errors.depositWalletId}
                                    />

                                    {validationUpdate.touched.depositWalletId && validationUpdate.errors.depositWalletId && (
                                        <FormFeedback type="invalid">{validationUpdate.errors.depositWalletId}</FormFeedback>
                                    )}
                                </div>
                            </div>
                            <div className="mb-3 mt-2">
                                <label htmlFor="customername-field" className="form-label">Liminal Withdraw Wallet Id</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Liminal Wallet Id"
                                        name="walletId"
                                        onChange={validationUpdate.handleChange}
                                        onBlur={validationUpdate.handleBlur}
                                        value={validationUpdate.values.walletId || ""}
                                        invalid={validationUpdate.touched.walletId && validationUpdate.errors.walletId}
                                    />

                                    {validationUpdate.touched.walletId && validationUpdate.errors.walletId && (
                                        <FormFeedback type="invalid">{validationUpdate.errors.walletId}</FormFeedback>
                                    )}
                                </div>
                            </div>

                        </div>
                        <Modal isOpen={modal} toggle={toggleModal}>
                            <ModalHeader toggle={toggleModal}>Image Preview</ModalHeader>
                            <ModalBody>{previewImage && <img src={previewImage} alt="Preview" style={{ width: "100%", height: "auto" }} />}</ModalBody>
                        </Modal>
                        <div className="col-lg-5 col-sm-12">
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Decimal Point</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Decimal Point"
                                    name="decimalpoint"
                                    onChange={validationUpdate.handleChange}
                                    onBlur={validationUpdate.handleBlur}
                                    value={validationUpdate.values.decimalpoint || ""}
                                    invalid={validationUpdate.touched.decimalpoint && validationUpdate.errors.decimalpoint}
                                />
                                {validationUpdate.touched.decimalpoint && validationUpdate.errors.decimalpoint && (
                                    <FormFeedback type="invalid">{validationUpdate.errors.decimalpoint}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Upload Image</label>
                                <div>
                                    <input
                                        type="file"
                                        className="form-control"
                                        accept=".png, .jpg, .jpeg"
                                        onChange={handleImageChange}
                                        // value={ image ? URL.createObjectURL(image) : getImage}
                                        placeholder="No file chosen"

                                    />
                                </div>
                                {/* Image Preview Card */}
                                {(getImage || image) && (
                                    <Card style={{ width: '200px' }}>
                                        <CardBody>
                                            <CardImg
                                                top
                                                src={image ? URL.createObjectURL(image) : getImage}
                                                style={{ width: '50px', objectFit: 'cover' }}

                                            />
                                        </CardBody>
                                    </Card>
                                )}

                            </div>
                            {/* <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Status</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="isActive"
                                    onChange={validationUpdate.handleChange}
                                    onBlur={validationUpdate.handleBlur}
                                    value={validationUpdate.values.isActive || ""}
                                    className={`form-control ${validationUpdate.touched.isActive && validationUpdate.errors.isActive ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select isActive</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                                {validationUpdate.touched.isActive && validationUpdate.errors.isActive && (
                                    <FormFeedback type="invalid">{validationUpdate.errors.isActive}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Deposit Status</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="depositStatus"
                                    onChange={validationUpdate.handleChange}
                                    onBlur={validationUpdate.handleBlur}
                                    value={validationUpdate.values.depositStatus || ""}
                                    className={`form-control ${validationUpdate.touched.depositStatus && validationUpdate.errors.depositStatus ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select Deposit Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                                {validationUpdate.touched.depositStatus && validationUpdate.errors.depositStatus && (
                                    <FormFeedback type="invalid">{validationUpdate.errors.depositStatus}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Withdraw Status</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="withdrawStatus"
                                    onChange={validationUpdate.handleChange}
                                    onBlur={validationUpdate.handleBlur}
                                    value={validationUpdate.values.withdrawStatus || ""}
                                    className={`form-control ${validationUpdate.touched.withdrawStatus && validationUpdate.errors.withdrawStatus ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select Withdraw Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                                {validationUpdate.touched.withdrawStatus && validationUpdate.errors.withdrawStatus && (
                                    <FormFeedback type="invalid">{validationUpdate.errors.withdrawStatus}</FormFeedback>
                                )}
                            </div> */}

<div className="mb-3">
                            <label htmlFor="status-field" className="form-label"> Deposit Type </label>
                            <select
                            id="status-field"
                            //  className="form-control"
                            name="depositType"
                            onChange={validationUpdate.handleChange}
                            onBlur={validationUpdate.handleBlur}
                            value={validationUpdate.values.depositType || ""}
                            className={`form-control ${validationUpdate.touched.depositType && validationUpdate.errors.depositType ? "is-invalid" : ""}`}
                            >
                            <option value="" disabled>
                                Choose Deposit type
                            </option>
                            <option value={0}>Manual</option>
                            <option value={1}>Automatic</option>
                            {/* <option value={2}>Internal</option> */}
                            </select>
                            {validationUpdate.touched.depositType && validationUpdate.errors.depositType && <FormFeedback type="invalid"> {validationUpdate.errors.depositType} </FormFeedback>}
                        </div>
                            <div className="mb-3">
                            <label htmlFor="status-field" className="form-label"> Withdraw Type </label>
                            <select
                            id="status-field"
                            //  className="form-control"
                            name="withdrawType"
                            onChange={validationUpdate.handleChange}
                            onBlur={validationUpdate.handleBlur}
                            value={validationUpdate.values.withdrawType || ""}
                            className={`form-control ${validationUpdate.touched.withdrawType && validationUpdate.errors.withdrawType ? "is-invalid" : ""}`}
                            >
                            <option value="" disabled>
                                Choose Withdraw type
                            </option>
                            <option value={0}>Manual</option>
                            <option value={1}>Automatic</option>
                            {/* <option value={2}>Internal</option> */}
                            </select>
                            {validationUpdate.touched.withdrawType && validationUpdate.errors.withdrawType && <FormFeedback type="invalid"> {validationUpdate.errors.withdrawType} </FormFeedback>}
                        </div>
                        <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Memo Status</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="memostatus"
                                    onChange={validationUpdate.handleChange}
                                    onBlur={validationUpdate.handleBlur}
                                    value={validationUpdate.values.memostatus || ""}
                                    className={`form-control ${validationUpdate.touched.memostatus && validationUpdate.errors.memostatus ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select Memo status</option>
                                    <option value="1">Enable</option>
                                    <option value="0">Disable</option>
                                </select>
                                {validationUpdate.touched.memostatus && validationUpdate.errors.memostatus && (
                                    <FormFeedback type="invalid">{validationUpdate.errors.memostatus}</FormFeedback>
                                )}
                            </div>

                       
                       
                       
                        </div>




                      


                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update_list(false)}>Close</button>
                            {/* <button  type="submit" className="btn btn-success" id="add-btn">Add Customer</button> */}
                            <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <Loader />

                                ) : ("Submit")}

                            </button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>


            {/* Add Modal */}
            <Modal className='modal-dialog modal-lg' isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add Coin </ModalHeader>
                <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false
                    }}
                >
                    <ModalBody className='d-lg-flex justify-content-around'>

                        <div className="col-lg-5 col-sm-12">


                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Coin Name</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Coin Name"
                                    name="coinName"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.coinName || ""}
                                    invalid={validation.touched.coinName && validation.errors.coinName}
                                />
                                {validation.touched.coinName && validation.errors.coinName && (
                                    <FormFeedback type="invalid">{validation.errors.coinName}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Symbol</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Symbol"
                                    name="symbol"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.symbol || ""}
                                    invalid={validation.touched.symbol && validation.errors.symbol}
                                />
                                {validation.touched.symbol && validation.errors.symbol && (
                                    <FormFeedback type="invalid">{validation.errors.symbol}</FormFeedback>
                                )}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Coin Type</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="coinType"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.coinType || ""}
                                    className={`form-control ${validation.touched.coinType && validation.errors.coinType ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select coinType</option>
                                    <option value="0">Coin</option>
                                    <option value="1">Token</option>
                                </select>
                                {validation.touched.coinType && validation.errors.coinType && (
                                    <FormFeedback type="invalid">{validation.errors.coinType}</FormFeedback>
                                )}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Currency Type</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="currencyType"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.currencyType || ""}
                                    className={`form-control ${validation.touched.currencyType && validation.errors.currencyType ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select currencyType</option>
                                    <option value="0">Crypto</option>
                                    <option value="1">Fiat</option>
                                </select>
                                {validation.touched.currencyType && validation.errors.currencyType && (
                                    <FormFeedback type="invalid">{validation.errors.currencyType}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Decimal Point</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Decimal Point"
                                    name="decimalpoint"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.decimalpoint || ""}
                                    invalid={validation.touched.decimalpoint && validation.errors.decimalpoint}
                                />
                                {validation.touched.decimalpoint && validation.errors.decimalpoint && (
                                    <FormFeedback type="invalid">{validation.errors.decimalpoint}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Memo Status</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="memostatus"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.memostatus || ""}
                                    className={`form-control ${validation.touched.memostatus && validation.errors.memostatus ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select Memo status</option>
                                    <option value="1">Enable</option>
                                    <option value="0">Disable</option>
                                </select>
                                {validation.touched.memostatus && validation.errors.memostatus && (
                                    <FormFeedback type="invalid">{validation.errors.memostatus}</FormFeedback>
                                )}
                            </div>
                        </div>
                        {/* <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">Image URL</label>
                            <InputGroup>
                                <Input
                                    type="file"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Image URL"
                                    name="image"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    onBlur={validation.handleBlur}
                                    // value={validation.values.image || ""}
                                    invalid={validation.touched.image && validation.errors.image}
                                />

                            </InputGroup>
                            {fileError && <FormFeedback type="invalid">{fileError}</FormFeedback>}
                            {validation.touched.image && validation.errors.image && (
                                <FormFeedback type="invalid">{validation.errors.image}</FormFeedback>
                            )}
                        </div> */}
                        <div className="col-lg-5 col-sm-12">

                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Upload Image</label>
                                {/* <ImgCrop showGrid rotationSlider aspectSlider showReset modalClassName="img-crop-modal">
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onChange}
                                    onPreview={onPreview}
                                    onRemove={onRemove}
                                      accept=".jpg,.jpeg,.png"
                                // Customize the action as needed
                                // action="/api/upload-image"
                                >
                                    {fileList.length < 1 && "+ Upload"}
                                </Upload>
                            </ImgCrop> */}
                                <div>

                                    <input type="file" className="form-control" value={getImage}
                                        accept=".png, .jpg, .jpeg" onChange={handleImageChange} />
                                </div>
                                {image ?
                                    <Card style={{ width: '200px' }}>
                                        <CardBody>
                                            <CardTitle tag="h6">Image Preview</CardTitle>
                                            <CardImg
                                                top
                                                src={image ? URL.createObjectURL(image) : ''}
                                                style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                                            />
                                        </CardBody>
                                    </Card> : null}
                            </div>
                            <div className="mb-3 mt-2">
                                <label htmlFor="customername-field" className="form-label">Liminal Deposit Wallet Id</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Liminal Wallet Id"
                                        name="depositWalletId"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.depositWalletId || ""}
                                        invalid={validation.touched.depositWalletId && validation.errors.depositWalletId}
                                    />

                                    {validation.touched.depositWalletId && validation.errors.depositWalletId && (
                                        <FormFeedback type="invalid">{validation.errors.depositWalletId}</FormFeedback>
                                    )}
                                </div>
                            </div>
                            <div className="mb-3 mt-2">
                                <label htmlFor="customername-field" className="form-label">Liminal Withdraw Wallet Id</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Liminal Wallet Id"
                                        name="walletId"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.walletId || ""}
                                        invalid={validation.touched.walletId && validation.errors.walletId}
                                    />

                                    {validation.touched.walletId && validation.errors.walletId && (
                                        <FormFeedback type="invalid">{validation.errors.walletId}</FormFeedback>
                                    )}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label"> Deposit Type </label>
                                <select
                                    id="status-field"
                                    //  className="form-control"
                                    name="depositType"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.depositType || ""}
                                    className={`form-control ${validation.touched.depositType && validation.errors.depositType ? "is-invalid" : ""}`}
                                >
                                    <option value="" disabled>
                                        Choose Deposit type
                                    </option>
                                    <option value={0}>Manual</option>
                                    <option value={1}>Automatic</option>
                                </select>
                                {validation.touched.depositType && validation.errors.depositType && <FormFeedback type="invalid"> {validation.errors.depositType} </FormFeedback>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label"> Withdraw Type </label>
                                <select
                                    id="status-field"
                                    //  className="form-control"
                                    name="withdrawType"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.withdrawType || ""}
                                    className={`form-control ${validation.touched.withdrawType && validation.errors.withdrawType ? "is-invalid" : ""}`}
                                >
                                    <option value="" disabled>
                                        Choose Withdraw type
                                    </option>
                                    <option value={0}>Manual</option>
                                    <option value={1}>Automatic</option>
                                </select>
                                {validation.touched.withdrawType && validation.errors.withdrawType && <FormFeedback type="invalid"> {validation.errors.withdrawType} </FormFeedback>}
                            </div>

                           
                            <Modal isOpen={modal} toggle={toggleModal}>
                                <ModalHeader toggle={toggleModal}>Image Preview</ModalHeader>
                                <ModalBody>{previewImage && <img src={previewImage} alt="Preview" style={{ width: "100%", height: "auto" }} />}</ModalBody>
                            </Modal>

                        </div>


                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                            {/* <button  type="submit" className="btn btn-success" id="add-btn">Add Customer</button> */}
                            <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                disabled={AddLoad}

                            >
                                {AddLoad ? (
                                    <Loader />

                                ) : ("Submit")}

                            </button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={tog_delete} className="modal zoomIn" id="deleteRecordModal" centered>
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-3 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            onClick={confirmUpdate}
                            disabled={deleteLoad}
                        >
                            {deleteLoad ? (
                                <Loader />
                            ) : ("Yes, Delete It!")}

                        </button>
                    </div>
                </ModalBody>
            </Modal>


            <Modal isOpen={modelCount} className="modal zoomIn" id="deleteRecordModal" centered>
                <div className="modal-header">
                    <h4>Coin List</h4>
                    <Button type="button" onClick={() => setmodalCount(false)} className="btn-close" aria-label="Close"> </Button>
                </div>

                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-3 mb-0">Are you Sure You want to Update The Currency Status</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodalCount(false)}>Close</button>
                        <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            disabled={loading}
                            onClick={handleToggle}
                        >
                            {loading ? (
                                <Loader />
                            ) : ("Yes, Change It!")}

                        </button>
                    </div>
                </ModalBody>

            </Modal>



            <Modal className='modal-dialog modal-lg' isOpen={model_withdraw} toggle={() => { tog_withdraw(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_withdraw(); }}> Update Withdraw Settings</ModalHeader>

                <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validationWithDraw.handleSubmit();
                        return false
                    }}
                >
                    <ModalBody className='d-lg-flex justify-content-around'>
                        <div className="col-lg-5 col-sm-12">


                            {/* <label htmlFor="customername-field" className="form-label">Currency Name:</label> <span className='mr-4'>{recordToUpdate.name}</span> */}
                            <div className="mb-3 mt-3">
                                <label htmlFor="customername-field" className="form-label">Currency Name</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Fee"
                                        name="withdrawalFee"
                                        readOnly
                                        value={recordToUpdate.name}

                                    />

                                </div>

                            </div>

                            <div className="mb-3 mt-3">
                                <label htmlFor="customername-field" className="form-label">Minimum Amount</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Minimum Amount"
                                        name="minWithdrawalLimit"
                                        onChange={validationWithDraw.handleChange}
                                        onBlur={validationWithDraw.handleBlur}
                                        value={validationWithDraw.values.minWithdrawalLimit || ""}
                                        invalid={validationWithDraw.touched.minWithdrawalLimit && validationWithDraw.errors.minWithdrawalLimit}
                                    />
                                    <div className="input-group-text" style={{ cursor: 'pointer' }}>
                                        <span>{recordToUpdate.symbol}</span>
                                    </div>
                                    {validationWithDraw.touched.minWithdrawalLimit && validationWithDraw.errors.minWithdrawalLimit && (
                                        <FormFeedback type="invalid">{validationWithDraw.errors.minWithdrawalLimit}</FormFeedback>
                                    )}
                                </div>

                                <div className="mb-3 mt-2">
                                    <label htmlFor="customername-field" className="form-label">Maximum Amount</label>
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            id="customername-field"
                                            className="form-control"
                                            placeholder="Enter Fee Limit"
                                            name="maxWithdrawalLimit"
                                            onChange={validationWithDraw.handleChange}
                                            onBlur={validationWithDraw.handleBlur}
                                            value={validationWithDraw.values.maxWithdrawalLimit || ""}
                                            invalid={validationWithDraw.touched.maxWithdrawalLimit && validationWithDraw.errors.maxWithdrawalLimit}
                                        />
                                        <div className="input-group-text" style={{ cursor: 'pointer' }}>
                                            <span>{recordToUpdate.symbol}</span>
                                        </div>
                                        {validationWithDraw.touched.maxWithdrawalLimit && validationWithDraw.errors.maxWithdrawalLimit && (
                                            <FormFeedback type="invalid">{validationWithDraw.errors.maxWithdrawalLimit}</FormFeedback>
                                        )}
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="status-field" className="form-label">Fee Type</label>
                                    <select
                                        id="status-field"
                                        //   className="form-control"
                                        name="withdrawalFeeType"
                                        onChange={handleFeeTypeChange}
                                        onBlur={validationWithDraw.handleBlur}
                                        value={validationWithDraw.values.withdrawalFeeType || ""}
                                        className={`form-control ${validationWithDraw.touched.withdrawalFeeType && validationWithDraw.errors.withdrawalFeeType ? 'is-invalid' : ''}`}
                                    >
                                        <option value="">Select Fee</option>
                                        <option value="0">Flat</option>
                                        {/* <option value="1">Fixed</option> */}
                                        <option value="1">Percentage</option>

                                    </select>
                                    {validationWithDraw.touched.withdrawalFeeType && validationWithDraw.errors.withdrawalFeeType && (
                                        <FormFeedback type="invalid">{validationWithDraw.errors.withdrawalFeeType}</FormFeedback>
                                    )}
                                </div>

                                {/* <div className="mb-3 mt-2">
                                <label htmlFor="customername-field" className="form-label">Liminal Wallet Id</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Liminal Wallet Id"
                                        name="walletId"
                                        onChange={validationWithDraw.handleChange}
                                        onBlur={validationWithDraw.handleBlur}
                                        value={validationWithDraw.values.walletId || ""}
                                        invalid={validationWithDraw.touched.walletId && validationWithDraw.errors.walletId}
                                    />

                                    {validationWithDraw.touched.walletId && validationWithDraw.errors.walletId && (
                                        <FormFeedback type="invalid">{validationWithDraw.errors.walletId}</FormFeedback>
                                    )}
                                </div>
                            </div> */}

                            </div>



                        </div>
                        <div className="col-lg-5 col-sm-12">
                            {/* <label htmlFor="customername-field" className="form-label">Currency Symbol: </label><span className='mr-4'>{recordToUpdate.symbol}</span> */}

                            <div className="mb-3 mt-3">
                                <label htmlFor="customername-field" className="form-label">Currency Symbol</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Fee"
                                        name="withdrawalFee"
                                        readOnly
                                        value={recordToUpdate.symbol}

                                    />

                                </div>

                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Withdraw 24 Hrs Limit</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Day Limit"
                                        name="dayLimit"
                                        onChange={validationWithDraw.handleChange}
                                        onBlur={validationWithDraw.handleBlur}
                                        value={validationWithDraw.values.dayLimit || ""}
                                        invalid={validationWithDraw.touched.dayLimit && validationWithDraw.errors.dayLimit}
                                    />
                                    <div className="input-group-text" style={{ cursor: 'pointer' }}>

                                        <span>{recordToUpdate.symbol}</span>
                                    </div>
                                    {validationWithDraw.touched.dayLimit && validationWithDraw.errors.dayLimit && (
                                        <FormFeedback type="invalid">{validationWithDraw.errors.dayLimit}</FormFeedback>
                                    )}
                                </div>
                            </div>
                            {tdsStatus ? (
                                <div className="mb-3">
                                    <label htmlFor="customername-field" className="form-label">TDS Percentage</label>
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            id="customername-field"
                                            className="form-control"
                                            placeholder="Enter TDS Percentage"
                                            name="tds"
                                            onChange={validationWithDraw.handleChange}
                                            onBlur={validationWithDraw.handleBlur}
                                            value={validationWithDraw.values.tds !== '' ? validationWithDraw.values.tds : ''}

                                            invalid={validationWithDraw.touched.tds && validationWithDraw.errors.tds}
                                        />
                                        <div className="input-group-text">
                                            <span>%</span>
                                        </div>
                                        {validationWithDraw.touched.tds && validationWithDraw.errors.tds && (
                                            <FormFeedback type="invalid">{validationWithDraw.errors.tds}</FormFeedback>
                                        )}
                                    </div>

                                </div>
                            ) : (
                                ""
                            )}


                            <div className="mb-3 mt-2">
                                <label htmlFor="customername-field" className="form-label">Fee Amount</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Fee Limit"
                                        name="withdrawalFee"
                                        onChange={validationWithDraw.handleChange}
                                        onBlur={validationWithDraw.handleBlur}
                                        value={validationWithDraw.values.withdrawalFee !== '' ? validationWithDraw.values.withdrawalFee : ''}
                                        invalid={validationWithDraw.touched.withdrawalFee && validationWithDraw.errors.withdrawalFee}
                                    />

                                    {validationWithDraw.touched.withdrawalFee && validationWithDraw.errors.withdrawalFee && (
                                        <FormFeedback type="invalid">{validationWithDraw.errors.withdrawalFee}</FormFeedback>
                                    )}
                                </div>
                            </div>
                            {/* <div className="mb-3">
                            <label htmlFor="status-field" className="form-label"> Withdraw Type </label>
                            <select
                            id="status-field"
                            //  className="form-control"
                            name="withdrawType"
                            onChange={validationWithDraw.handleChange}
                            onBlur={validationWithDraw.handleBlur}
                            value={validationWithDraw.values.withdrawType || ""}
                            className={`form-control ${validationWithDraw.touched.withdrawType && validationWithDraw.errors.withdrawType ? "is-invalid" : ""}`}
                            >
                            <option value="" disabled>
                                Choose Withdraw type
                            </option>
                            <option value={0}>Manual</option>
                            <option value={1}>Automatic</option>
                            </select>
                            {validationWithDraw.touched.withdrawType && validationWithDraw.errors.withdrawType && <FormFeedback type="invalid"> {validationWithDraw.errors.withdrawType} </FormFeedback>}
                        </div> */}
                            {/* <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">Contract Address</label>
                            <Input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Contract Address"
                                name="contractaddress"
                                onChange={validationWithDraw.handleChange}
                                onBlur={validationWithDraw.handleBlur}
                                value={validationWithDraw.values.contractaddress || ""}
                                // style={{ textTransform: 'uppercase' }}
                                invalid={validationWithDraw.touched.contractaddress && validationWithDraw.errors.contractaddress}
                            />
                            {validationWithDraw.touched.contractaddress && validationWithDraw.errors.contractaddress && (
                                <FormFeedback type="invalid">{validationWithDraw.errors.contractaddress}</FormFeedback>
                            )}
                        </div> */}
                        </div>


                    </ModalBody>
                    {getCoinType === 1 && (
  <>
  {network.length > 0 && (
    <div>
      <label
        htmlFor="customername-field"
        style={{ paddingLeft: "50px" }}
        className="form-label"
      >
        Withdraw Network
      </label>

      <div className="container">
        <div className="row justify-content-center">
          {network.map((data, index) => (
           
            <div key={index} className="col-10 mb-3">
              <div className="row align-items-center">
                {/* Checkbox and label column */}
                <div className="col-md-2 col-sm-12 d-flex align-items-center">
                 
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      id={`defaultCheck${index}`}
                      checked={data.networkType == 1}
                      onClick={() => handleCheckboxChange(index)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`defaultCheck${index}`}
                    >
                      {data.networkName}
                    </label>
                
                </div>
                {/* Input column */}
                <div className="col-md-8">
                  <input
                    className="form-control"
                    placeholder="Enter Contract Address"
                    value={data.tokenContractAddress || ""}
                    readOnly
                    // onChange={(e) =>
                    //   handleNetworkChange(
                    //     index,
                    //     "contractAddress",
                    //     e.target.value
                    //   )
                    // }
                    type="text"
                  />
                </div>
              </div>
            </div>
))}
        </div>
      </div>
    </div>
  )}
</>

)}


                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodel_withdraw(false)}>Close</button>
                            {/* <button  type="submit" className="btn btn-success" id="add-btn">Add Customer</button> */}
                            <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                disabled={loading}

                            >
                                {loading ? (
                                    <Loader />

                                ) : ("Submit")}

                            </button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal className='modal-dialog modal-lg' isOpen={model_deposit} toggle={() => { tog_deposit(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_deposit(); }}> Update Deposit Settings</ModalHeader>

                <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validationDeposit.handleSubmit();
                        return false
                    }}
                >
                    <ModalBody className='d-lg-flex justify-content-around'>
                        <div className="col-lg-5 col-sm-12">


                            {/* <label htmlFor="customername-field" className="form-label">Currency Name:</label> <span className='mr-4'>{recordToUpdate.name}</span> */}
                            <div className="mb-3 mt-3">
                                <label htmlFor="customername-field" className="form-label">Currency Name</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Fee"
                                        name="withdrawalFee"
                                        readOnly
                                        value={recordToUpdate.name}

                                    />

                                </div>

                            </div>
                          {CurrencyINR === 'INR' &&
                          <>
                             <div className="mb-3 mt-3">
                                <label htmlFor="customername-field" className="form-label">Minimum Amount</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Minimum Amount"
                                        name="minDepositLimit"
                                        onChange={validationDeposit.handleChange}
                                        onBlur={validationDeposit.handleBlur}
                                        value={validationDeposit.values.minDepositLimit || ""}
                                        invalid={validationDeposit.touched.minDepositLimit && validationDeposit.errors.minDepositLimit}
                                    />
                                    <div className="input-group-text" style={{ cursor: 'pointer' }}>
                                        <span>{recordToUpdate.symbol}</span>
                                    </div>
                                    {validationDeposit.touched.minDepositLimit && validationDeposit.errors.minDepositLimit && (
                                        <FormFeedback type="invalid">{validationDeposit.errors.minDepositLimit}</FormFeedback>
                                    )}
                                </div>
                                </div> 

                                <div className="mb-3 mt-2">
                                    <label htmlFor="customername-field" className="form-label">Maximum Amount</label>
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            id="customername-field"
                                            className="form-control"
                                            placeholder="Enter Fee Limit"
                                            name="maxDepositLimit"
                                            onChange={validationDeposit.handleChange}
                                            onBlur={validationDeposit.handleBlur}
                                            value={validationDeposit.values.maxDepositLimit || ""}
                                            invalid={validationDeposit.touched.maxDepositLimit && validationDeposit.errors.maxDepositLimit}
                                        />
                                        <div className="input-group-text" style={{ cursor: 'pointer' }}>
                                            <span>{recordToUpdate.symbol}</span>
                                        </div>
                                        {validationDeposit.touched.maxDepositLimit && validationDeposit.errors.maxDepositLimit && (
                                            <FormFeedback type="invalid">{validationDeposit.errors.maxDepositLimit}</FormFeedback>
                                        )}
                                    </div>
                                </div> 
                                </>
                            }
                                  
                        


                                {/* <div className="mb-3">
                                    <label htmlFor="status-field" className="form-label">Fee Type</label>
                                    <select
                                        id="status-field"
                                        //   className="form-control"
                                        name="depositFeeType"
                                        onChange={handleDepositFeeType}
                                        onBlur={validationDeposit.handleBlur}
                                        value={validationDeposit.values.depositFeeType || ""}
                                        className={`form-control ${validationDeposit.touched.depositFeeType && validationDeposit.errors.depositFeeType ? 'is-invalid' : ''}`}
                                    >
                                        <option value="">Select Fee</option>
                                        <option value="0">Flat</option>
                                        <option value="1">Fixed</option> 
                                        <option value="1">Percentage</option>

                                    </select>
                                    {validationDeposit.touched.depositFeeType && validationDeposit.errors.depositFeeType && (
                                        <FormFeedback type="invalid">{validationDeposit.errors.depositFeeType}</FormFeedback>
                                    )}
                                </div> */}

                        
                          
                          
                                

                        </div>
                        <div className="col-lg-5 col-sm-12">
                            {/* <label htmlFor="customername-field" className="form-label">Currency Symbol: </label><span className='mr-4'>{recordToUpdate.symbol}</span> */}

                            <div className="mb-3 mt-3">
                                <label htmlFor="customername-field" className="form-label">Currency Symbol</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Fee"
                                        name="withdrawalFee"
                                        readOnly
                                        value={recordToUpdate.symbol}

                                    />

                                </div>

                            </div>
                            {/* <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Deposit 24 Hrs Limit</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Day Limit"
                                        name="dayLimit"
                                        onChange={validationDeposit.handleChange}
                                        onBlur={validationDeposit.handleBlur}
                                        value={validationDeposit.values.dayLimit || ""}
                                        invalid={validationDeposit.touched.dayLimit && validationDeposit.errors.dayLimit}
                                    />
                                    <div className="input-group-text" style={{ cursor: 'pointer' }}>

                                        <span>{recordToUpdate.symbol}</span>
                                    </div>
                                    {validationDeposit.touched.dayLimit && validationDeposit.errors.dayLimit && (
                                        <FormFeedback type="invalid">{validationDeposit.errors.dayLimit}</FormFeedback>
                                    )}
                                </div>
                            </div> */}
                            {/* {tdsStatus ? (
                                <div className="mb-3">
                                    <label htmlFor="customername-field" className="form-label">TDS Percentage</label>
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            id="customername-field"
                                            className="form-control"
                                            placeholder="Enter TDS Percentage"
                                            name="tds"
                                            onChange={validationDeposit.handleChange}
                                            onBlur={validationDeposit.handleBlur}
                                            value={validationDeposit.values.tds !== '' ? validationDeposit.values.tds : ''}

                                            invalid={validationDeposit.touched.tds && validationDeposit.errors.tds}
                                        />
                                        <div className="input-group-text">
                                            <span>%</span>
                                        </div>
                                        {validationDeposit.touched.tds && validationDeposit.errors.tds && (
                                            <FormFeedback type="invalid">{validationDeposit.errors.tds}</FormFeedback>
                                        )}
                                    </div>

                                </div>
                            ) : (
                                ""
                            )} */}

                                {CurrencyINR === 'INR' &&
                            <div className="mb-3 mt-2">
                                <label htmlFor="customername-field" className="form-label">Fee Amount</label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        id="customername-field"
                                        className="form-control"
                                        placeholder="Enter Fee Limit"
                                        name="depositFee"
                                        onChange={validationDeposit.handleChange}
                                        onBlur={validationDeposit.handleBlur}
                                        value={validationDeposit.values.depositFee !== '' ? validationDeposit.values.depositFee : ''}
                                        invalid={validationDeposit.touched.depositFee && validationDeposit.errors.depositFee}
                                    />

                                    {validationDeposit.touched.depositFee && validationDeposit.errors.depositFee && (
                                        <FormFeedback type="invalid">{validationDeposit.errors.depositFee}</FormFeedback>
                                    )}
                                </div>
                            </div> }
               
                        </div>


                    </ModalBody>
                    {depositNetwork.length === 0 ? (
                        ""
                    ) : (
                        <div className="">
                            <label htmlFor="customername-field" style={{ paddingLeft: "50px" }} className="form-label" >Deposit Network</label>

                            <div className="container">
                                <div className="row justify-content-center p-2">
                                    {depositNetwork.map((data, index) => (
                                        <div key={index} className="col-12 mb-3">
                                            <div className="d-flex justify-content-around">

                                                <div className='col-md-2'>
                                                <input
                                                    className="form-check-input me-2"
                                                    type="checkbox"
                                                    id={`defaultCheck${index}`}
                                                    checked={data.networkType === 1}
                                                    onClick={() => handleDepositCheckboxChange(index)}
                                                />
                                                <label className="form-check-label me-2" htmlFor={`defaultCheck${index}`}>
                                                    {data.networkName}
                                                </label>
                                                </div>
                                                <div className='col-md-8'>

                                                <input
                                                    className="form-control"
                                                    placeholder="Enter Contract Address"
                                                    value={data.tokenContractAddress || ""}
                                                    readOnly
                                                    // onChange={(e) => handleDepositNetworkChange(index, "contractAddress", e.target.value)}
                                                    type="text"
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}


                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodel_deposit(false)}>Close</button>
                            {/* <button  type="submit" className="btn btn-success" id="add-btn">Add Customer</button> */}
                            <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                disabled={loading}

                            >
                                {loading ? (
                                    <Loader />

                                ) : ("Submit")}

                            </button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default CoinManagement;


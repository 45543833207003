import React, { useState, useEffect, useContext } from "react";
import socketIOClient from "socket.io-client";
import InboxSidebar from "./EndChatSidebar";
import "./endchat.css";
import { Card, CardBody, Col, Row, Container, CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Apiservices, configServices } from "../../ApiService/ApiServices";
import { AdminContext } from '../../helpers/adminContext'; // Adjust the path as needed
import toast from "react-hot-toast";
import { formatMonthDateTime } from '../../helpers/common';
import { Link } from "react-router-dom";
import UserProfile from '../../assets/images/user.png'
const CHAT_SOCKET_URL = Apiservices.BACKEND_CHAT_SOCKET_BACKEND_SERVER;


const EndChat = () => {
  document.title = "Ultrapro | End Chat";
  const { adminInfo } = useContext(AdminContext);
  // const [messages, setMessages] = useState([]);
  // const [images, setImages] = useState([]);
  // const [imagePreviews, setImagePreviews] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [socketConnection, setSocketConnection] = useState(null);
  const [lockChatStatus, setlockChatStatus] = useState(false);
  const [selectedChat, setSelectedChat] = useState("");  
  const [authenticated, setAuthenticated] = useState(false);
  const [chatMessage,setChatMessages] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [CurrentAdminID, setCurrentAdminID] = useState(null);

  useEffect(() => {
    if(configServices.CHAT_SOCKET_CONNECTION !== "Disable") {
      let socket;
      if (!socketConnection) {
        socket = socketIOClient(CHAT_SOCKET_URL, {
          transports: ["websocket"],
          auth: {
            senderType: 'admin',
            headers: {
              Authorization: `${adminInfo?.jwt}`,
            },
          },
        // },
      });

      socket.on("connect", () => {
        setSocketConnection(socket);
        setAuthenticated(true);
      });

      socket.on("connect_error", (err) => {
        console.log("Socket connection error:", err);
      });

      socket.on("disconnect", () => {
        setSocketConnection(null);
        setAuthenticated(false);
      });
    socket.emit('joinRoom', chatId);

      socket.on("userMessageReceived", (responceMsg) => {
        const messageContent = responceMsg?.messageContent;
        if (Array.isArray(messageContent) && messageContent.length > 0) {
            setChatMessages((prevMessages) => {
              const newMessages = messageContent.filter(
                (newMsg) => !prevMessages.some((prevMsg) => prevMsg._id === newMsg._id)
              );
              return [...prevMessages, ...newMessages];
            });
        }
      });
    }
    return () => {
      if (socketConnection) {
        socketConnection.disconnect();
      }
    };
  }
  setCurrentAdminID(adminInfo?.adminUserId)

  }, [socketConnection,adminInfo]);

  useEffect(() => {
    if(configServices.CHAT_SOCKET_CONNECTION !== "Disable") {
      if (socketConnection != null && authenticated) {
        chatmessageSocket();
      }
    }
  }, [socketConnection]);
  const chatmessageSocket = () => {
    socketConnection.emit('joinRoom', chatId);
    socketConnection.on("adminchatMessageReceiver", (responceMsg) => {
      
      const messageContent = responceMsg[0]?.messageContent;
      if (Array.isArray(messageContent) && messageContent.length > 0) {
          setChatMessages((prevMessages) => {
            const newMessages = messageContent.filter(
              (newMsg) => !prevMessages.some((prevMsg) => prevMsg._id === newMsg._id)
            );
            return [...prevMessages, ...newMessages];
          });
      }
    });
    return () => {
      socketConnection.off("adminchatMessageReceiver");
    };
  };

  const handleUserSelect = (chat) => {
    console.log(chat,"44444444444444444")
    if(configServices.CHAT_SOCKET_CONNECTION !== "Disable") {
      if(chat){
      setChatMessages(chat?.chatMsg?.messageContent);
      setSelectedChat(chat)
      setChatId(chat?.chatMsg?.chatId)
      }
    }
  };

  const sendMessage = async () => {
    if (!socketConnection) {
       console.error("Socket connection not established");
       return;
    }
    try {
      if(messageText.length>0){
        let reqData = {
          senderType: 'agent',
          jwt: adminInfo.jwt,
          messageContent: messageText,
          senderId: selectedChat.acId,
          chatId: chatId,
          messageType: 'text',
       };
       if (socketConnection != null && authenticated) socketConnection.emit('adminChatMessage', reqData);
       setMessageText("");
      }else{
        toast.error("Please Enter Message")
      }
    } catch (err) {
       console.error("Error sending message:", err);
    }
 };
  async function endChat(chatData) {
    try {
      
      socketConnection.emit("endChatMsg", { chatId: chatData.chatMsg.chatId, jwt: adminInfo.jwt});
      setSelectedChat("");
    } catch (err){
      console.error(err);
    }
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Live Chat" breadcrumbItem="End Chat" />
          <Row className="mb-4">
            <InboxSidebar
              lockChatStatus = {lockChatStatus}
              setlockChatStatus = { setlockChatStatus }
              setSelectedChat = { setSelectedChat }
              onUserSelect={handleUserSelect}
            />
            { !lockChatStatus &&
            <Col xl={8}>
              <Card className="mb-0 chat-card">
                { selectedChat ? (
                  <>
                    <CardHeader className="chat-card-header d-flex align-items-center p-3 border-bottom">
                    <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">                        
                        { selectedChat.profileImg && <img src={selectedChat.profileImg} alt="profile" className="rounded-circle me-2" width="40" height="40" />}
                        { !selectedChat.profileImg && <img src={UserProfile} alt="profile" className="rounded-circle me-2" width="40" height="40" />}
                       
                        <div>
                        <span>{selectedChat.fullName ? selectedChat.fullName : "-"}</span>
                          {selectedChat.email && <h6 className="mb-0">
                            <Link className="link-tag" to={`/allUsers/userDetails/${selectedChat?.chatMsg?.senderId}`} target="_blank">
                            <span className="text-decoration-underline">
                            {selectedChat.email ? selectedChat.email : "-"}
                            </span>
                          </Link>
                            </h6>}
                        </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          {selectedChat?.chatMsg?.endChat?.senderType === 'agent' ? (
                            <div className="text-end">
                              <h6 className="mb-1">Chat Ended By: Admin</h6>
                              <small className="d-block">{selectedChat?.chatMsg?.endChat?.senderName}</small>
                              <small className="d-block">{selectedChat?.chatMsg?.endChat?.senderEmail}</small>
                            </div>
                          ) : (
                            <div className="text-end">
                              <h6 className="mb-1">Chat Ended By: User</h6>
                              <small className="d-block">{selectedChat?.chatMsg?.endChat?.senderName}</small>
                              <small className="d-block">{selectedChat?.chatMsg?.endChat?.senderEmail}</small>
                            </div>
                          )}
                        </div>


                        {/* {chatMessage.some(message => message.senderType === "agent" && message.message) && (
                            <div className="cursor-pointer chat-cursor" onClick={() => endChat(selectedChat)}>
                              End Chat
                            </div>
                          )} */}
                      </div>
                    </CardHeader>
                 
                    <CardBody className="chat-card-body">
                    <div className="chat-box custom-scrollbar" >
                              {chatMessage.map((message, index) => (
                                <div key={index} className={message.senderType === "agent" ? "message-right mb-3" : "message-left mb-3"}>
                                
                                  <div className={message.senderType === "agent" ? "message-bubble-right position-relative" : "message-bubble-left"}>
                                    
                                    {message.senderType === "user" && message.message && (
                                      <p className="mb-0">{message.message}</p>
                                    )}
                                    {message.senderType === "agent" && message.message && (
                                      <>
                                      <p className="mb-0">{message.message}</p>
                                      </>
                                    )}
                                    <span className="time text-white">
                                      {formatMonthDateTime(new Date(message.startedAt))}
                                    </span>
                                  <small className="mb-0 usr_mail text-muted">{message.senderType === "agent" ? message.senderEmail :''}</small>

                                  </div>
                                </div>

                              ))}
                            </div>
                            {/* {selectedChat.chatMsg?.lockUser[0] !== CurrentAdminID ?
                      <div className="mb-3">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type a message"
                            value={messageText}
                            onChange={(e) => setMessageText(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") sendMessage();
                            }}
                          />
                          <button className="btn btn-info waves-effect waves-light" type="button" disabled={ messageText != "" ? false : true } onClick={sendMessage}>
                            <i className="fab fa-telegram-plane" />
                          </button>
                        </div>
                      </div> : ''} */}
                    </CardBody>
                  </>
                ) : (
                  <CardBody>
                    <h5>Please select a user to start the chat.</h5>
                  </CardBody>
                )}
              </Card>
            </Col>}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EndChat;
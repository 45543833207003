import React from 'react';
import { Container, Row, Col } from 'reactstrap';


const NotFound = () => {
  return (
    <div className="my-5 pt-5">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={8} xl={5}>
            <div className="text-center">
              <h1 className="display-2 error-text fw-bold">404</h1>
              <h4 className="text-uppercase mt-4">Page Not Found</h4>
              <p>Sorry, the page you are looking for does not exist.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;

import React, { useState, useEffect } from "react";
import { Button, Input, Card, CardBody, FormFeedback, Col, Container, FormGroup, Label, Row, Form } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import { calculateDays } from "../../helpers/function";

const StakingOffers = () => {

  document.title = "Ultrapro | Stake Offers";
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      stakename: "",
      duration: "",
      durationcount: "",
      minstakeamount: "",
      maxstakeamount: "",
      isActive: "",
      description:"",
      stakeType:"",
      content:[ 
        {
        contentLine: "",
        contentIsActive: "",
      }],
      currencyName: "",
      position:""
    },
    validationSchema: Yup.object({
      stakename: Yup.string().trim().required("Stake Name is Required"),
      duration: Yup.string().required("Duration is Required"),
      stakeType: Yup.string().required("Stake Type is Required"),
      // durationcount: Yup.string().required("Duration count is Required"),
      minstakeamount: Yup.number()
        .required("Stake Amount is Required")
        .typeError("Stake Amount must be a number"),
        //.lessThan(Yup.ref('maxstakeamount'), 'Minimum Stake Amount must be less than Maximum Stake Amount'),
      // maxstakeamount: Yup.number()
      //   .required("Maximum Stake Amount is Required")
      //   .typeError("Maximum Stake Amount must be a number")
      //   .moreThan(Yup.ref('minstakeamount'), 'Maximum Stake Amount must be greater than Minimum Stake Amount'),
      description: Yup.string().trim().required("Description is Required"),
      currencyName: Yup.string().trim().required("Currency Name is Requiresd"),
      content: Yup.array().of(
        Yup.object().shape({
          contentLine: Yup.string().trim().required("Content Line is Required"),
          contentIsActive: Yup.boolean().required("Content Status is Required"),
        })
      ).required("Content is Required"),
      isActive:Yup.string().trim().required(" Stack Status is Required"),
      position: Yup.string().trim().required("Position is Required"),

    }),
    validateOnChange: false,
    onSubmit: async (values) => {
      handleAdd(values);
    },
  });
 
  

const handleAdd = async (values) => {
    setLoading(true);
    const data = {
      stakeName: values.stakename,
      duration: values.duration,
      minstake: values.minstakeamount,
      maxstake: values.maxstakeamount,
      description: values.description,
      isActive: values.isActive,
      content: values.content,
      currencyName: values.currencyName,
      stakeType:values.stakeType,
      position:values.position
    } 
  
    try {
     
      const response = await postData(Apiservices.StakingOffersCreate, data);
  
      if (response.status) {
        toast.success(response.message);
        formik.resetForm();
        setLoading(false);
        
      } else {
        toast.error(response.message);
        formik.resetForm();
        setLoading(false)
      }
    } 
    catch (error) {
      // toast.error(error);
      setLoading(false);
      formik.resetForm();
      setLoading(false);
    }
  }

 

  const handleDurationChange = (event) => {
    const selectedDuration = event.target.value;
    const weeks = calculateDays(selectedDuration);
    formik.setFieldValue("duration", selectedDuration);
    formik.setFieldValue("durationcount", weeks);
  };
  //coinlist api call for dropdown
  const [currencyList,setCurrencyList] = useState([])
  useEffect(()=>{
    coinList()
  },[])
  const coinList = async () => {
    try {
      // setLoading(true);
      const response = await postData(Apiservices.USER_COIN_LIST);
      if (response.status) {
        setCurrencyList(response.data.data);
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    } catch (error) {}

    
  };
 // Function to add a new content row
 const addContentRow = () => {
  formik.setValues({
    ...formik.values,
    content: [...formik.values.content, { contentLine: "", contentIsActive: "" }]
  });
};

// Function to remove a content row by index
const removeContentRow = (index) => {
  const updatedContent = formik.values.content.filter((_, i) => i !== index);
  formik.setValues({ ...formik.values, content: updatedContent });
};

  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumbs title="Staking Offers" breadcrumbItem="Create Staking Offers" />
        <Row>
          <Col lg={12} className="row">
            <Form onSubmit={formik.handleSubmit}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="stakename">Stake Name</Label>
                        <Input
                          type="text"
                          name="stakename"
                          id="stakename"
                          className="form-control"
                          value={formik.values.stakename}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                          placeholder="Enter Stake Name"
                          invalid={formik.touched.stakename && !!formik.errors.stakename}
                        />
                        <FormFeedback>{formik.errors.stakename}</FormFeedback>
                      </FormGroup>
  
                      <FormGroup>
                        <Label for="minstakeamount">Pricing Stake Amount</Label>
                        <Input
                          type="text"
                          name="minstakeamount"
                          id="minstakeamount"
                          className="form-control"
                          placeholder="Enter Stake Amount"
                          value={formik.values.minstakeamount}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                          invalid={formik.touched.minstakeamount && !!formik.errors.minstakeamount}
                        />
                        <FormFeedback>{formik.errors.minstakeamount}</FormFeedback>
                      </FormGroup>
  
                      <FormGroup>
                        <Label for="position">Position</Label>
                        <Input
                          type="text"
                          name="position"
                          id="position"
                          min='1'
                          className="form-control"
                          placeholder="Enter Position"
                          value={formik.values.position}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                          invalid={formik.touched.position && !!formik.errors.position}
                        />
                        <FormFeedback>{formik.errors.position}</FormFeedback>
                      </FormGroup>
  
          
                      {/* <FormGroup>
                        <Label for="stakeBonus">Stake Bonus Percentage</Label>
                        <Input
                          type="text"
                          name="stakeBonus"
                          id="stakeBonus"
                          className="form-control"
                          placeholder="Enter Stake Bonus Percentage"
                          value={formik.values.stakeBonus}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                          invalid={formik.touched.stakeBonus && !!formik.errors.stakeBonus}
                        />
                        <FormFeedback>{formik.errors.stakeBonus}</FormFeedback>
                      </FormGroup> */}
                    </Col>
  
                    <Col md={6}>
                    <FormGroup>
                        <Label for="duration">Duration</Label>
                        <select
                          id="duration"
                          name="duration"
                          onChange={handleDurationChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.duration || ""}
                          className={`form-control ${formik.touched.duration && formik.errors.duration ? "is-invalid" : ""}`}
                        >
                          <option value="" disabled>
                            Select Duration
                          </option>
                          <option value="30">30 Days</option>
                          <option value="180">180 Days</option>
                          <option value="365">365 Days</option>
                          <option value="540">540 Days</option>
                        </select>
                        {formik.touched.duration && formik.errors.duration && <FormFeedback type="invalid">{formik.errors.duration}</FormFeedback>}
                      </FormGroup>

                      <FormGroup>
                        <Label for="duration">Stake Type</Label>
                        <select
                          id="stakeType"
                          name="stakeType"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.stakeType || ""}
                          className={`form-control ${formik.touched.stakeType && formik.errors.stakeType ? "is-invalid" : ""}`}
                        >
                          <option value="" disabled>
                            Select Type
                          </option>
                          <option value="0">Free</option>
                          <option value="1">Cost</option>
        
                        </select>
                        {formik.touched.stakeType && formik.errors.stakeType && <FormFeedback type="invalid">{formik.errors.stakeType}</FormFeedback>}
                      </FormGroup>

                        <FormGroup>
                        <label htmlFor="currencyName" className="form-label">
                          Currency Name
                        </label>
                        <select
                          id="currencyName"
                          name="currencyName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.currencyName || ""}
                          className={`form-control ${formik.touched.currencyName && formik.errors.currencyName ? "is-invalid" : ""}`}
                        >
                          <option value="" disabled>
                            Select Currency
                          </option>
                          {currencyList.map((data) => (
                            <option key={data.coinId} value={data.symbol}>
                              {data.symbol}
                            </option>
                          ))}
                        </select>
                        {formik.touched.currencyName && formik.errors.currencyName && (
                          <FormFeedback type="invalid">{formik.errors.currencyName}</FormFeedback>
                        )}
                      </FormGroup>
  
                      {/* <FormGroup>
                        <Label for="maxstakeamount">Maximum Stake Amount</Label>
                        <Input
                          type="text"
                          name="maxstakeamount"
                          id="maxstakeamount"
                          className="form-control"
                          value={formik.values.maxstakeamount}
                          onChange={formik.handleChange}
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          placeholder="Enter Maximum Stake Amount"
                          invalid={formik.touched.maxstakeamount && !!formik.errors.maxstakeamount}
                        />
                        <FormFeedback>{formik.errors.maxstakeamount}</FormFeedback>
                      </FormGroup> */}
  
                      {/* <FormGroup>
                        <Label for="referalBonus">Referral Bonus Percentage</Label>
                        <Input
                          type="text"
                          name="referalBonus"
                          id="referalBonus"
                          className="form-control"
                          placeholder="Enter Referral Bonus Percentage"
                          value={formik.values.referalBonus}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                          invalid={formik.touched.referalBonus && !!formik.errors.referalBonus}
                        />
                        <FormFeedback>{formik.errors.referalBonus}</FormFeedback>
                      </FormGroup> */}
                    </Col>
  
                    <Col lg={12}>
                    <Row>
                

                 
                </Row>

                  </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="description">Description</Label>
                        <Input
                          type="textarea"
                          row="5"
                          name="description"
                          id="description"
                          className="form-control"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          placeholder="Enter Description"
                          invalid={formik.touched.description && !!formik.errors.description}
                        />
                        <FormFeedback>{formik.errors.description}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                    <FormGroup>
                          <label htmlFor="content" className="form-label">
                            Stack Status
                          </label>
                          <select
                            id="isActive"
                            name="isActive"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.isActive || ""}
                            className={`form-control ${
                              formik.touched.isActive && formik.errors.isActive ? "is-invalid" : ""
                            }`}
                          >
                            <option value="" disabled>
                              Select Status
                            </option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                          {formik.touched.isActive && formik.errors.isActive && (
                            <FormFeedback type="invalid">
                              {formik.errors.isActive}
                            </FormFeedback>
                          )}
                        </FormGroup>
                    </Col>

                    {/* <Col sm={6} lg={6}>
                    <FormGroup>
                      <Label for="contentLine">Content Line</Label>
                      <Input
                        type="textarea"
                        name="content[0].contentLine" // Access the first element of content array
                        id="contentLine"
                        className="form-control"
                        placeholder="Enter Content Line"
                        value={formik.values.contentLine || ""} // Access the first element safely
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                        invalid={formik.touched.content?.[0]?.contentLine && !!formik.errors.content?.[0]?.contentLine}
                      />
                      <FormFeedback>{formik.errors.content?.[0]?.contentLine}</FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col sm={3} lg={3}>
                    <FormGroup>
                      <label htmlFor="isActive" className="form-label">
                        Content Status
                      </label>
                      <select
                        id="isActive"
                        name="isActive"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.isActive || ""}
                        className={`form-control ${formik.touched.isActive && formik.errors.isActive ? "is-invalid" : ""}`}
                      >
                        <option value="" disabled>
                          Select Status
                        </option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      {formik.touched.isActive && formik.errors.isActive && (
                        <FormFeedback type="invalid">{formik.errors.isActive}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm={3} lg={3} className="d-flex align-items-center">
                    <Button color="success" className="add-btn" id="create-btn">
                      <i className="ri-add-line align-bottom me-1"></i>
                      Add Content
                    </Button>
                  </Col> */}

        {formik.values.content.map((item, index) => (
        <div key={index} className="row">
          <Col sm={5} lg={5}>
            <FormGroup>
              <Label for={`content[${index}].contentLine`}>Content Line</Label>
              <Input
                type="textarea"
                name={`content[${index}].contentLine`} // Dynamically set name for each item
                id={`contentLine-${index}`}
                className="form-control"
                placeholder="Enter Content Line"
                value={formik.values.content[index].contentLine}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
                invalid={
                  formik.touched.content?.[index]?.contentLine &&
                  !!formik.errors.content?.[index]?.contentLine
                }
              />
              <FormFeedback>
                {formik.errors.content?.[index]?.contentLine}
              </FormFeedback>
            </FormGroup>
          </Col>

          <Col sm={4} lg={4}>
            <FormGroup>
              <label htmlFor={`content[${index}].contentIsActive`} className="form-label">
                Content Status
              </label>
              <select
                id={`contentIsActive-${index}`}
                name={`content[${index}].contentIsActive`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.content[index].contentIsActive || ""}
                className={`form-control ${
                  formik.touched.content?.[index]?.contentIsActive &&
                  formik.errors.content?.[index]?.contentIsActive
                    ? "is-invalid"
                    : ""
                }`}
              >
                <option value="" disabled>
                  Select Status
                </option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
              {formik.touched.content?.[index]?.contentIsActive &&
                formik.errors.content?.[index]?.contentIsActive && (
                  <FormFeedback type="invalid">
                    {formik.errors.content?.[index]?.contentIsActive}
                  </FormFeedback>
                )}
            </FormGroup>
          </Col>

          <Col sm={1} lg={1} className="d-flex align-items-center">
            {index > 0 && (
                <i className="ri-delete-bin-line"  onClick={() => removeContentRow(index)}></i>
            )}
          </Col>

          <Col sm={2} lg={2} className="d-flex align-items-center">
        <Button color="success" className="add-btn" onClick={addContentRow}>
          <i className="ri-add-line align-bottom me-1"></i> Add Content
        </Button>
      </Col>
        </div>
      ))}

   
      
                  </Row>
  
                  <div className="text-end">
                    {loading ? (
                      <Button color="primary">Loading...</Button>
                    ) : (
                      <Button color="primary" type="submit">
                        Submit
                      </Button>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
  
  );
};

export default StakingOffers;

import { useState } from 'react';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import { Modal,ModalBody,Button } from 'reactstrap';
import Loader from './Spinner';
import { Boolian } from '../ApiService/ApiServices';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';

export const handleNullValue = (value) => value ?? "-";

export const usePasswordToggle = () => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const getIcon = () => {
        return showPassword ? mdiEye : mdiEyeOff;
    };

    return {
        showPassword,
        togglePasswordVisibility,
        getIcon
    };
};
export const utcToNormalTime = (utcString) => {
  const date = new Date(utcString);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${months[date.getMonth()]}-${date.getFullYear()}`;

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  //const seconds = date.getSeconds().toString().padStart(2, "0");
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes} ${ampm}`;
  return { date: formattedDate, time: formattedTime };
};
  export const calculateDays = (duration) => {
    const [value, unit] = duration.split(" ");
    const numericValue = parseInt(value, 10);

    switch (unit) {
      case "day":
      case "days":
        return numericValue;
      case "week":
      case "weeks":
        return numericValue * 7; // 1 week = 7 days
      case "month":
      case "months":
        return numericValue * 30; // Approximation: 1 month = 30 days
      case "year":
      case "years":
        return numericValue * 365; // 1 year = 365 days
      default:
        return 0;
    }
  };


 export const AddressCell = ({ address }) => {
    //const [copied, setCopied] = useState(false);
    const truncatedAddress = address?.substring(0, 6) + '...' || 'N/A';
    const handleCopy = () => {
     // setCopied(true);
      toast.dismiss()
      toast.success('Copied to clipboard!');
  };
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Display the truncated address */}
            <span>{truncatedAddress}</span>
            
            {/* Copy icon */}
            <CopyToClipboard
                text={address}
                onCopy={handleCopy}
            >
                <button
                    style={{
                        marginLeft: '8px',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    <i className="fa fa-copy"></i> {/* FontAwesome copy icon */}
                </button>
            </CopyToClipboard>
            
            
        </div>
    );
};

export const AddressCellFull = ({ address }) => {
 // const [copied, setCopied] = useState(false);
  const truncatedAddress = address;
  const handleCopy = () => {
   // setCopied(true);
    toast.dismiss()
    toast.success('Copied to clipboard!');
};
  return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Display the truncated address */}
 
          <span>{truncatedAddress}</span>
    
          
          {/* Copy icon */}
          <CopyToClipboard
              text={address}
              onCopy={handleCopy}
          >
              <button
                  style={{
                      marginLeft: '8px',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer'
                  }}
              >
                  <i className="fa fa-copy"></i> {/* FontAwesome copy icon */}
              </button>
          </CopyToClipboard>
          
          
      </div>
  );
};
export const AddressCellFullLink = ({ address }) => {
  //const [copied, setCopied] = useState(false);
  const truncatedAddress = address?.substring(0, 30) + '...' || 'N/A';
  const handleCopy = () => {
   // setCopied(true);
    toast.dismiss()
    toast.success('Copied to clipboard!');
};
  return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Display the truncated address */}
          <a href={truncatedAddress} target='_blank' rel='noreferrer'>

          <span>{truncatedAddress}</span>
          </a>
          
          {/* Copy icon */}
          <CopyToClipboard
              text={address}
              onCopy={handleCopy}
          >
              <button
                  style={{
                      marginLeft: '8px',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer'
                  }}
              >
                  <i className="fa fa-copy"></i> {/* FontAwesome copy icon */}
              </button>
          </CopyToClipboard>
          
          
      </div>
  );
};
export const percentCalculate = (totalAmount, feeAmount) => {
  const result = (feeAmount / totalAmount) * 100;
  return result;
};



export  const CustomModal = ({ isOpen, toggle, title, body, onConfirm, loading }) => {
    return (
      <Modal isOpen={isOpen} className="modal zoomIn" centered>
        <div className="modal-header">
          <h4>{title}</h4>
          <Button type="button" onClick={toggle} className="btn-close" aria-label="Close"></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              {body}
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button type="button" className="btn w-sm btn-light" onClick={toggle}>
              Close
            </button>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="button"
              onClick={onConfirm}
              disabled={loading}
            >
              {loading ? <Loader /> : "Yes, Update It!"}
            </button>
          </div>
        </ModalBody>
      </Modal>
    );
  };


  export const getVoteStatusText = (code) => {
    switch (code) {
      case Boolian.PENDING_USER:
        return "Pending";
      case Boolian.USER_VERIFIED:
        return "Approved";
      case Boolian.USER_BLOCKED:
        return "Rejected";
      default:
        return "Unknown";
    }
  };

 export const getVoteBadgeClass = (code) => {
    switch (code) {
      case Boolian.PENDING_USER:
        return "badge-soft-warning";
      case Boolian.USER_VERIFIED:
        return "badge-soft-success";
      case Boolian.USER_BLOCKED:
        return "badge-soft-danger";
      default:
        return "";
    }
  };

  
  export const getBadgeKYCClass = (code) => {
    switch (code) {
      case Boolian.KYC_PENDING_NUMBER:
        return "badge-soft-warning";
      case Boolian.KYC_VERIFIED_NUMBER:
        return "badge-soft-success";
      case Boolian.KYC_REJECTED:
        return "badge-soft-danger";
      case Boolian.KYC_NOT_UPLOAD:
        return "badge-soft-dark";
      default:
        return "";
    }
  };

  export const getKycStatusText = (code) => {
    switch (code) {
      case Boolian.KYC_PENDING_NUMBER:
        return "Pending";
      case Boolian.KYC_VERIFIED_NUMBER:
        return "Verified";
      case Boolian.KYC_REJECTED:
        return "Rejected";
      case Boolian.KYC_NOT_UPLOAD:
        return "Not Uploaded";
      default:
        return "Unknown";
    }
  };
  export const getBadgeClass = (code) => {
    switch (code) {
      case Boolian.PENDING_USER:
        return "badge-soft-warning";
      case Boolian.USER_VERIFIED:
        return "badge-soft-success";
      case Boolian.USER_BLOCKED:
        return "badge-soft-danger";
      case Boolian.WITHDRAW_BLOCKED:
        return "badge-soft-danger";
      default:
        return "";
    }
  };

  export const getBadgeClassWith = (code) => {
    switch (code) {
      case Boolian.WITHDRAW_BLOCKED:
        return "badge-soft-danger";
      default:
        return "";
    }
  };
  export const getStatusTextWith = (code) => {
    switch (code) {
      case Boolian.WITHDRAW_BLOCKED:
        return "Blocked";
      default:
        return "";
    }
  };

  export const getStatusText = (code) => {
    switch (code) {
      case Boolian.PENDING_USER:
        return "Pending";
      case Boolian.USER_VERIFIED:
        return "Verified";
      case Boolian.USER_BLOCKED:
        return "Blocked"
      case Boolian.WITHDRAW_BLOCKED:
        return "Blocked";
      default:
        return "Unknown";
    }
  };
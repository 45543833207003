
import axios from 'axios';
import { Apiservices, TokenName,SecretKey } from './ApiServices';
import CryptoJS from 'crypto-js';
import Cookies from "js-cookie";


const axiosInstance = axios.create({
  baseURL: Apiservices.BACKEND_LOCALHOST_SERVER,
  // timeout: 90000,
});

const encryptionKey = SecretKey.SECRET_KEY;



const encryptData = (data, encryptionKey) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
  return encrypted;
};


const decryptData = (encryptedData, decryptionKey) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, decryptionKey);
  const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decrypted;
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get(TokenName.AUTH_TOKEN);
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }
    
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);


axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data && response.data.statusCode === 700) {

      if (!response.config._retry || response.config._retry == undefined) {
        response.config._retry = true;

        return axiosInstance
          .post(`/`)
          .then((refreshResponse) => {
            if (refreshResponse.data.token) {
              const token=refreshResponse.data.token;

              Cookies.set("authorization",token); 

             
              axiosInstance.defaults.headers.common[
                "Authorization"
              ] = `${refreshResponse.data.token}`;
              response.config.headers[
                "Authorization"
              ] = `${refreshResponse.data.token}`;

              return axiosInstance(response.config);
            } else {
              // console.error(
              //   "Refresh token response missing token:",
              //   refreshResponse
              // );
        
            }
          })
          .catch((refreshError) => {
            
            // console.error("Error refreshing token:", refreshError);
     
            // Cookies.remove("jwtToken");
            // window.location.href = "/auth";
            // return Promise.reject(refreshError);
          });
      } else {
        return Promise.reject(response);
      }
    }

    return response;
  },
  (error) => {
    // console.error("Error making request:", error);
    return Promise.reject(error);
  }
);

const handleError = (error) => {

  // console.error('Request failed:', error);
  throw error;
};

// POST request
export const postChatData = async (endpoint, data) => {
  try { 
    const response = await axiosInstance.post(`${Apiservices.BACKEND_LOCALHOST_SERVER + endpoint}`, data);
    if (response && response.data) {
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.status == 401) {
      // Handle other errors except 401
      await Cookies.remove(TokenName.AUTH_TOKEN);
      window.location.href = "/login";
      return null;
      
      
    }handleError(error);
  }
};

export const ChatPostGetData = async (endpoint, data = {}) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP__CHAT_API_BACKEND_SERVER + endpoint}`,data);
    if (response && response.data) {
      return response.data;
    }
    // console.log(response.data.payload,"response.data.payload");
    
    // const decryptedResponse = decryptData(response.data.payload, encryptionKey);
    // console.log(decryptedResponse,"RESPONSE")
    // return decryptedResponse;
   
  } catch (error) {
    if (error.response && error.response.status == 401) {
      // Handle other errors except 401
      await Cookies.remove(TokenName.AUTH_TOKEN);
      window.location.href = "/login";
      return null;
      
      handleError(error);
    }
    // Ensure error is propagated if needed
  }
};

// export const postData = async (endpoint,data) => {
//   try {
//     if (data instanceof FormData){


//     }
//     // Encrypt data before sending
//     const payload = encryptData(data, encryptionKey);
    
//     const response = await axiosInstance.post(`${Apiservices.BACKEND_LOCALHOST_SERVER + endpoint}`, { payload });
    
//     // Decrypt response if needed

//     const decryptedResponse = decryptData(response.data.payload, encryptionKey);
//     return decryptedResponse;
//   } catch (error) {
//     if (error.response && error.response.status == 401) {

//       await Cookies.remove(TokenName.AUTH_TOKEN);
//       window.location.href = "/login";
//       return null;
      
//       handleError(error);
//     }

//   }
// };

export const postData = async (endpoint, data) => {
  console.log(data,"DAta");
  
  try {
    let payload;
    
    if (data instanceof FormData) {
      // Extract fields from FormData for encryption
      const formDataFields = {};
      for (let [key, value] of data.entries()) {
        if (key !== 'files') { // Assuming 'files' is the field name for images
          formDataFields[key] = value;
        }
      }

      // Encrypt the fields
      const encryptedFields = encryptData(formDataFields, encryptionKey);
      
      // Create a new FormData to include encrypted data and the file(s)
      const formData = new FormData();
      formData.append('payload', encryptedFields);
      
      // Append the files back to FormData
      for (let [key, value] of data.entries()) {
        if (key === 'files') {
          formData.append(key, value);
        }
      }

      payload = formData;
    } else {
      // Encrypt data if it's not FormData
      payload = { payload: encryptData(data, encryptionKey) };
    }
    if(data){

      const response = await axiosInstance.post(`${Apiservices.BACKEND_LOCALHOST_SERVER + endpoint}`, payload);
      const decryptedResponse = decryptData(response.data.payload, encryptionKey);
    // console.log(decryptedResponse,"RESPONSE")

      return decryptedResponse;
      
    }else{
      const response = await axiosInstance.post(`${Apiservices.BACKEND_LOCALHOST_SERVER + endpoint}`);
      const decryptedResponse = decryptData(response.data.payload, encryptionKey);
      // console.log(decryptedResponse,"RESPONSE")

      return decryptedResponse;

    }
    
    // Decrypt response if needed
    // const decryptedResponse = decryptData(response.data.payload, encryptionKey);
    // return decryptedResponse;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      await Cookies.remove(TokenName.AUTH_TOKEN);
      window.location.href = "/login";
      return null;
    }
    
    handleError(error);
  }
};







export const postGetFile = async ( endpoint, data ={}, config={}) => {
  console.log(data,"DAta");
  
  try {
    let payload;
    
    if (data instanceof FormData) {
      // Extract fields from FormData for encryption
      const formDataFields = {};
      for (let [key, value] of data.entries()) {
        if (key !== 'files') { // Assuming 'files' is the field name for images
          formDataFields[key] = value;
        }
      }

      // Encrypt the fields
      const encryptedFields = encryptData(formDataFields, encryptionKey);
      
      // Create a new FormData to include encrypted data and the file(s)
      const formData = new FormData();
      formData.append('payload', encryptedFields);
      
      // Append the files back to FormData
      for (let [key, value] of data.entries()) {
        if (key === 'files') {
          formData.append(key, value);
        }
      }

      payload = formData;
    } else {
      // Encrypt data if it's not FormData
      payload = { payload: encryptData(data, encryptionKey) };
    }
    if(data){

      const response = await axiosInstance.post(`${Apiservices.BACKEND_LOCALHOST_SERVER + endpoint}`, payload,config);
     // const decryptedResponse = decryptData(response.data.payload, encryptionKey);
    // console.log(decryptedResponse,"RESPONSE")

      return response;
      
    }else{
      const response = await axiosInstance.post(`${Apiservices.BACKEND_LOCALHOST_SERVER + endpoint}`);
      const decryptedResponse = decryptData(response.data.payload, encryptionKey);
      // console.log(decryptedResponse,"RESPONSE")

      return decryptedResponse;

    }
    
    // Decrypt response if needed
    // const decryptedResponse = decryptData(response.data.payload, encryptionKey);
    // return decryptedResponse;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      await Cookies.remove(TokenName.AUTH_TOKEN);
      window.location.href = "/login";
      return null;
    }
    
    handleError(error);
  }
};






export const postGetData = async (endpoint, data) => {
  try {

    
    const response = await axiosInstance.post(`${Apiservices.BACKEND_LOCALHOST_SERVER + endpoint}`);
    // console.log(response.data.payload,"response.data.payload");
    

    const decryptedResponse = decryptData(response.data.payload, encryptionKey);
    // console.log(decryptedResponse,"RESPONSE")
    return decryptedResponse;
   
  } catch (error) {
    if (error.response && error.response.status == 401) {
      // Handle other errors except 401
      await Cookies.remove(TokenName.AUTH_TOKEN);
      window.location.href = "/login";
      return null;
      
      handleError(error);
    }
    // Ensure error is propagated if needed
  }
};
export const chatpostData = async (endpoint, data) => {
  console.log(data,"DAta");
  
  try {
    let payload;
    
    if (data instanceof FormData) {
      // Extract fields from FormData for encryption
      const formDataFields = {};
      for (let [key, value] of data.entries()) {
        if (key !== 'files') { // Assuming 'files' is the field name for images
          formDataFields[key] = value;
        }
      }

      // Encrypt the fields
      const encryptedFields = encryptData(formDataFields, encryptionKey);
      
      // Create a new FormData to include encrypted data and the file(s)
      const formData = new FormData();
      formData.append('payload', encryptedFields);
      
      // Append the files back to FormData
      for (let [key, value] of data.entries()) {
        if (key === 'files') {
          formData.append(key, value);
        }
      }

      payload = formData;
    } else {
      // Encrypt data if it's not FormData
      payload = { payload: encryptData(data, encryptionKey) };
    }
    if(data){

      const response = await axiosInstance.post(`${Apiservices.BACKEND_CHAT_API_SERVER + endpoint}`, payload);
      const decryptedResponse = decryptData(response.data.payload, encryptionKey);
    // console.log(decryptedResponse,"RESPONSE")

      return decryptedResponse;
      
    }else{
      const response = await axiosInstance.post(`${Apiservices.BACKEND_CHAT_API_SERVER + endpoint}`);
      const decryptedResponse = decryptData(response.data.payload, encryptionKey);
      // console.log(decryptedResponse,"RESPONSE")

      return decryptedResponse;

    }
    
    // Decrypt response if needed
    // const decryptedResponse = decryptData(response.data.payload, encryptionKey);
    // return decryptedResponse;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      await Cookies.remove(TokenName.AUTH_TOKEN);
      window.location.href = "/login";
      return null;
    }
    
    handleError(error);
  }
};
// export const ChatPostGetData = async (endpoint, data) => {
//   try {
//     const response = await axiosInstance.post(`${Apiservices.BACKEND_CHAT_API_SERVER + endpoint}`);
//     console.log(response.data.payload,"response.data.payload");
    
//     const decryptedResponse = decryptData(response.data.payload, encryptionKey);
//     // console.log(decryptedResponse,"RESPONSE")
//     return decryptedResponse;
   
//   } catch (error) {
//     if (error.response && error.response.status == 401) {
//       // Handle other errors except 401
//       await Cookies.remove(TokenName.AUTH_TOKEN);
//       window.location.href = "/login";
//       return null;
      
//       handleError(error);
//     }
//     // Ensure error is propagated if needed
//   }
// };

export default axiosInstance;

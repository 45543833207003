import React, { useState, useEffect } from "react";
import { Button, Input, Card, CardBody, FormFeedback, 
Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import { Editor } from '@tinymce/tinymce-react';


const CMSManagement = () => {
  
  const [loading, setLoading] = useState(false);
  const [templateList,setTemplateList] = useState([])

  const [content,setContent] = useState('')


  document.title = "Settings | Email Template Management";

  //EMAIL TEMPLATE LIST
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await postData(Apiservices.EmailTemplateList);
      if (response) {
        if(response.data.length !== 0){
          setTemplateList(response.data.data);
          setLoading(false)
        }
        else{
          setTemplateList(response);
          setLoading(false)

        }
       
      } else {
        setLoading(false)

      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)

      // Handle fetch error
    }
  };

  const columns = [
    {
      name: "S.No",
      // selector: (row, index) => currentPage * itemsPerPage + index + 1,
      // selector: (row, index) => index + 1,
      selector: (row, index) => offset + index + 1,

      sortable: true,
      maxWidth:'3%'
    },
    {
      name: "Title",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: "Email From",
      selector: (row) => row.mailFrom,
      sortable: true,
    },
    {
      name: "Status",
      // selector: row => row.isActive ? 'Active' : 'Inactive',
      selector: (row) => (
        <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            checked={row.isActive === 1}
            // onChange={() => handleToggle(row, "isActive")}

            // onChange={() => handleToggleModel(row, 'isIpActive')}
            // onChange={() =>handleToggleModel(row,Apiservices.EmailTemplateUpdate)}

            disabled={loading}
          />
        </div>
      ),

      sortable: true,
      maxWidth:'1%'

    },
    // {
    //   name: "Date",
    //   selector: (row) => utcToNormalTime(row.createdAt).date,
    //   sortable: true,
    // },
    {
      name: "Action",
      cell: (row) => (
        <div className="edit">
          <i
            style={{ cursor: "pointer" }}
            className="mdi mdi-border-color cursor-pointer"
            onClick={() => handleUpdate(row)}
          ></i>
        </div>
      ),
      ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
  ];

  //paginnation
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 10;
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * rowsPerPage;
  const currentData = templateList.slice(offset, offset + rowsPerPage);


 

  //Add Modal
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  }
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
  }
  //Add form validation
  const formik = useFormik({
    initialValues: {
      title: "",
      subject: "",
      email:"",
      isActive:"",
      templateIcon:''

    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      subject: Yup.string().required("Subject is required"),
      email: Yup.string().email("Invalid email format").required("Email is required"),
      isActive: Yup.string().required("Status is required"),

      templateIcon: Yup.string().required("Template Icon is required"),
    

    }),
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  const handleEditorChange = (content, editor) => {
    setContent(content)
  };


//Addapicall

const handleAdd = async (data) => {
    // setLoading(true);
    if(content !== ''){
      const postForm = {
        name: data.title,
        subject: data.subject,
        htmlContent:content,
        mailFrom:data.email,
        isActive:Number(data.isActive),
        templateIcon:data.templateIcon
      };
    
  
      try {
       
        // const response = await postData(Apiservices.EmailTemplateCreate, postForm);
        // if (response.status === true) {
        //   toast.success(response.message);
        //   formik.resetForm();
        //   setmodal_list(false);
        //   fetchData();
        //   setLoading(false);
        // } else {
        //   toast.error(response.message);
        //   setLoading(false);
        //   formik.resetForm();
        //   fetchData();
        // }
      } catch (error) {
        toast.error("Internal Server Error..Try Again Later");
        setLoading(false);
        formik.resetForm();
        setLoading(false);
      }

    }
    else{
      toast.error('Content is required')
    }
    
   
  };
//update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,

    initialValues: {
        title: "",
        subject: "",
        email:"",
        isActive:"",
        templateIcon:""
      },
      validationSchema: Yup.object({
        title: Yup.string().required("Title is required"),
        subject: Yup.string().required("Subject is required"),
        email: Yup.string().email("Invalid email format").required("Email is required"),
        isActive: Yup.string().required("Status is required"),
        templateIcon: Yup.string().required("Template Icon is required"),



      }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });

  const handleToggle = async () => {
    const updatedStatus = rowDetails.isActive === 1 ? 0 : 1;
    // const updatedRow = {
    //   ...row,
    //   [statusType]: row[statusType] === 1 ? 0 : 1, // Toggle the status type
      
    // };
    const postForm = {
      name: rowDetails.name || "",
      subject: rowDetails.subject || "",
      htmlContent:rowDetails.htmlContent || "",
      mailFrom:rowDetails.mailFrom || "",
      // isActive: updatedStatus.isActive !== undefined  ? String(updatedStatus.isActive) : "",
      isActive: updatedStatus,
      templateIcon:rowDetails.templateIcon

    };
    setLoading(true);
    try {
      // const response = await postData(`${Apiservices.EmailTemplateUpdate}`, postForm);

      // if (response.status) {
      //   toast.dismiss();
      //   setLoading(false);
      //   toast.success(response.message);
      //   setmodal_toggle(false);
      //   fetchData();
      // } else {
      //   toast.error(response.message);
      //   setmodal_toggle(false);
      //   setLoading(false);
      // }
    } catch (error) {
      toast.dismiss();
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      setmodal_toggle(false);
    }
  };
//updateapicall
 
  const handleUpdate = (record) => {

   
    const stripHtmlTags = (html) => {
      const div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent || div.innerText || "";
    };
  
    // Strip HTML tags from the initial content
    const cleanedContent = stripHtmlTags(record.htmlContent);
    setContent(cleanedContent)

    validationUpdate.setValues({
      
      title: record.name || "",
      subject: record.subject || "",
      // content:content || "",
      email:record.mailFrom || "",
      // isActive:record.isActive !== undefined  ? String(record.isActive)  : "",
      isActive: record.isActive !== undefined  ? String(record.isActive) : "",
      templateIcon:record.templateIcon
    });
 
    setmodal_update_list(true);
  };
  const confirmUpdate = async (values) => {
    const postForm = {
      
      name: values.title,
      subject: values.subject,
      htmlContent: content,
      mailFrom:values.email,
      isActive:values.isActive,
      templateIcon:values.templateIcon
    };
  
    try {
      setLoading(true);
      // const response = await postData(
      //   `${Apiservices.EmailTemplateUpdate}`,postForm);

      // if (response.status === true) {
      //   setmodal_update_list(false);
      //   // setDeleteLoad(false)
      //   toast.success(response.message);
      //   // setmodal_delete(false)
      //   fetchData();
      //   setLoading(false);
      // } else {
      //   toast.error(response.message);
      //   fetchData();
      //   // validation.resetForm();
      //   // setmodal_delete(false)
      //   // setDeleteLoad(false)
      //   setLoading(false);
      // }
    } catch (error) {
      // setDeleteLoad(false)
      toast.error("Internal Server Error..Try Again Later");
      // setmodal_delete(false)
      validationUpdate.resetForm();
    }
  };

 
  const [modal_toggle , setmodal_toggle] = useState(false);
    const [rowDetails, setRowDetails] = useState({
        
    });

const handleToggleModel=async(row)=>{
    setRowDetails(row);
    setmodal_toggle(true);
}

  return (
    <React.Fragment>
  <div className="page-content">
    <Container fluid={true}>
      <Breadcrumbs title="Settings" breadcrumbItem="CMS Management" />
      <Row>
        <Col lg={12} className="row">
          <Card className="col-lg-12">
            <CardBody>
              <Row className="g-4 mb-3">
                {/* <Col className="col-sm-auto">
                  <h4 className="card-title">Email Template Details</h4>
                </Col> */}
                <Col className="col-sm">
                  <div className="d-flex justify-content-sm-end">
                    <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn">
                      <i className="ri-add-line align-bottom me-1"></i> Add</Button>
                  </div>
                </Col>
              </Row>
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={currentData}
                  paginationServer
                  paginationTotalRows={currentData.length}
                  progressPending={loading}
                  progressComponent={<div>Loading...</div>}
                  persistTableHead={true}
                  noDataComponent={<div className="mt-5">No records found</div>}
                  fixedHeader={true}
                />
                {currentData.length !== 0 ? (
                  <div className="d-flex justify-content-end mt-3">
                    <div className="pagination pagination-rounded mb-0 ">
                      <ReactPaginate
                        previousLabel={<i className="mdi mdi-chevron-left"></i>}
                        nextLabel={<i className="mdi mdi-chevron-right"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        pageLinkClassName={"page-link"}
                        breakLinkClassName={"break-link"}
                        disabledClassName={"disabled"}
                        pageCount={Math.ceil(templateList.length / rowsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                ) : null}
                {/* // ADDEMAAIL  */}
                <Modal
                  className="modal-dialog modal-lg" isOpen={modal_list} toggle={() => { tog_list();}} centered >
                  <ModalHeader
                    className="bg-light p-3"
                    id="exampleModalLabel"
                    toggle={() => {tog_list();}}>{" "} Add Email Template{" "}
                  </ModalHeader>
                  <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                      return false;
                    }} >
                    <ModalBody>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Title
                        </label>

                        <Input
                          type="text"
                          id="title-field"
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter Title"
                          name="title"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.title || ""}
                          invalid={formik.touched.title && formik.errors.title}
                        />
                        {formik.touched.title && formik.errors.title && <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Subject
                        </label>

                        <Input
                          type="text"
                          id="subject-field"
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter subject"
                          name="subject"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.subject || ""}
                          invalid={formik.touched.subject && formik.errors.subject}
                        />
                        {formik.touched.subject && formik.errors.subject && <FormFeedback type="invalid">{formik.errors.subject}</FormFeedback>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Email
                        </label>

                        <Input
                          type="text"
                          id="title-field"
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter Email"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email || ""}
                          invalid={formik.touched.email && formik.errors.email}
                        />
                        {formik.touched.email && formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                        Template Icon
                        </label>

                        <Input
                          type="text"
                          id="title-field"
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter template Icon"
                          name="templateIcon"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.templateIcon || ""}
                          invalid={formik.touched.templateIcon && formik.errors.templateIcon}
                        />
                        {formik.touched.templateIcon && formik.errors.templateIcon && <FormFeedback type="invalid">{formik.errors.templateIcon}</FormFeedback>}
                      </div>
                      <div className="mb-3">
                            <label
                              htmlFor="status-field"
                              className="form-label"
                            >
                              Status
                            </label>
                            <select
                              id="status-field"
                              //  className="form-control"
                              name="isActive"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={
                                formik.values.isActive || ""
                              }
                              className={`form-control ${
                                formik.touched.isActive &&
                                formik.errors.isActive
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <option value="" disabled>
                                Select Status
                              </option>
                              <option value={1}>Active</option>
                              <option value={0}>Deactive</option>
                            </select>
                            {formik.touched.isActive &&
                              formik.errors.isActive && (
                                <FormFeedback type="invalid">
                                  {" "}
                                  {formik.errors.isActive}{" "}
                                </FormFeedback>
                              )}
                          </div>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Content
                        </label>

                      <Editor
                        apiKey="8tmldbekaot614sl1i1s5lejnuj9hkqmsncgjyxs3p4rgave" // Optional, get one from TinyMCE if you're using their cloud version
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: "link image code",
                          toolbar: "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code",
                          setup: (editor) => {
                            editor.on("change", () => {
                            });
                          },
                        }}
                        onEditorChange={handleEditorChange}
                      />
                      </div>
                  
                    </ModalBody>
                    <ModalFooter>
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => setmodal_list(false)}
                        >
                          Close
                        </button>

                        <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
                          {loading ? <Loader /> : "Submit"}
                        </button>
                      </div>
                    </ModalFooter>
                  </Form>
                </Modal>

                {/* UpdateModal */}
                <Modal className=" modal-lg" isOpen={modal_update_list} toggle={() => { tog_update_list(); }} centered>
                  <ModalHeader
                    className="bg-light p-3 "
                    id="exampleModalLabel"
                    toggle={() => { tog_update_list(); }}>
                    Update Email Template
                  </ModalHeader>
                  <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validationUpdate.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Title
                        </label>

                        <Input
                          type="text"
                          id="title-field"
                          readOnly
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter Title"
                          name="title"
                          onChange={validationUpdate.handleChange}
                          onBlur={validationUpdate.handleBlur}
                          value={validationUpdate.values.title || ""}
                          invalid={validationUpdate.touched.title && validationUpdate.errors.title}
                        />
                        {validationUpdate.touched.title && validationUpdate.errors.title && <FormFeedback type="invalid">{validationUpdate.errors.title}</FormFeedback>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Subject
                        </label>

                        <Input
                          type="text"
                          id="subject-field"
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter subject"
                          name="subject"
                          onChange={validationUpdate.handleChange}
                          onBlur={validationUpdate.handleBlur}
                          value={validationUpdate.values.subject || ""}
                          invalid={validationUpdate.touched.subject && validationUpdate.errors.subject}
                        />
                        {validationUpdate.touched.subject && validationUpdate.errors.subject && <FormFeedback type="invalid">{validationUpdate.errors.subject}</FormFeedback>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Email
                        </label>

                        <Input
                          type="text"
                          id="title-field"
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter Email"
                          name="email"
                          onChange={validationUpdate.handleChange}
                          onBlur={validationUpdate.handleBlur}
                          value={validationUpdate.values.email || ""}
                          invalid={validationUpdate.touched.email && validationUpdate.errors.email}
                        />
                        {validationUpdate.touched.email && validationUpdate.errors.email && <FormFeedback type="invalid">{validationUpdate.errors.email}</FormFeedback>}
                      </div>

                      <div className="mb-3">
                            <label
                              htmlFor="status-field"
                              className="form-label"
                            >
                              Status
                            </label>
                            <select
                              id="status-field"
                              //  className="form-control"
                              name="isActive"
                              onChange={validationUpdate.handleChange}
                              onBlur={validationUpdate.handleBlur}
                              value={
                                validationUpdate.values.isActive || ""
                              }
                              className={`form-control ${
                                validationUpdate.touched.isActive &&
                                validationUpdate.errors.isActive
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <option value="" disabled>
                                Select Status
                              </option>
                              <option value={1}>Active</option>
                              <option value={0}>Deactive</option>
                            </select>
                            {validationUpdate.touched.isActive &&
                              validationUpdate.errors.isActive && (
                                <FormFeedback type="invalid">
                                  {" "}
                                  {validationUpdate.errors.isActive}{" "}
                                </FormFeedback>
                              )}
                          </div>
                    <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                        Template Icon
                        </label>

                        <Input
                          type="text"
                          id="title-field"
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter templateIcon"
                          name="templateIcon"
                          onChange={validationUpdate.handleChange}
                          onBlur={validationUpdate.handleBlur}
                          value={validationUpdate.values.templateIcon || ""}
                          invalid={validationUpdate.touched.templateIcon && validationUpdate.errors.templateIcon}
                        />
                        {validationUpdate.touched.templateIcon && validationUpdate.errors.templateIcon && <FormFeedback type="invalid">{validationUpdate.errors.templateIcon}</FormFeedback>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Content
                        </label>
                      <Editor
                      // initialValue={content}
                      value={content}
                        apiKey="8tmldbekaot614sl1i1s5lejnuj9hkqmsncgjyxs3p4rgave" // Optional, get one from TinyMCE if you're using their cloud version
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: "link image code",
                          toolbar: "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code",
                          setup: (editor) => {
                            editor.on("change", () => {
                            });
                          },
                        }}
                        onEditorChange={handleEditorChange}
                        
                      />
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <div className="hstack gap-2 justify-content-end">
                        <button type="button" className="btn btn-light" onClick={() => setmodal_update_list(false)}>
                          Close
                        </button>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
                          {loading ? <Loader /> : "Submit"}
                        </button>
                      </div>
                    </ModalFooter>
                  </Form>
                </Modal>
                <Modal isOpen={modal_toggle} className="modal zoomIn" id="deleteRecordModal" centered>
                <div className="modal-header">
                   <h4>Email List</h4>
                    <Button type="button" onClick={() => setmodal_toggle(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-3 mb-0">Are you sure you want to update this email status, {rowDetails && rowDetails.name}?</p>
                        </div>
                    </div>

                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_toggle(false)}>Close</button>
                        <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            onClick={handleToggle}
                            disabled={loading}
                        >
                            {loading ? (
                                <Loader />
                            ) : ("Yes, Update It!")}

                        </button>
                    </div>
                </ModalBody>
            </Modal>

              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
</React.Fragment>

  );
};

export default CMSManagement;

import React, { useState, useEffect, useContext } from "react";
import { Input, Card, CardBody, Col, Container, Row, Modal, ModalBody, ModalHeader, CardImg, ModalFooter, Spinner, Form, DropdownMenu, DropdownItem, DropdownToggle, InputGroup, Dropdown } from "reactstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../ApiService/AxiosInstance";
import { Apiservices, Boolian } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useLocation } from "react-router-dom";
import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue, utcToNormalTime, getVoteStatusText, getVoteBadgeClass } from "../../helpers/function";
import Swal from "sweetalert2";
import { AdminContext } from "../../helpers/adminContext";
import { Link } from "react-router-dom";


const SocialList = () => {
  document.title = "Ultrapro | SocialMedia List";

  const location = useLocation();
  const today = new Date();
  const userApi = location.state?.user;

  const [dateRange, setDateRange] = useState([null, null]);
  const [downloadStartDate, setDownloadEndDate] = useState({});
  const [loading, setLoading] = useState(false);
  const [downLoading, setDownLoading] = useState(false);

  const [searchLoad, setSearchLoad] = useState(false);
  const [searchValid, setSearchValid] = useState(false);
  const [inputSearch, setinputSearch] = useState("");
  const [formData, setFormData] = useState([]);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [count, setCount] = useState("");
  const [btnauto3, setbtnauto3] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Over All Bonus");
  const [DateValue, setDateValue] = useState({
    startDate: "",
    endDate: ""
  })

  const [user, setUser] = useState(userApi);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);

  //Permission List
  const {adminInfo } = useContext(AdminContext);
  const[adminType,setAdminType] = useState('');

  const [fullImage, setFullImage] = useState({
    url: "",
    fileName: ""
  });
  const [modal_xlarge, setmodal_xlarge] = useState(false);

  useEffect(() => {
    if (user === undefined) {
      setUser(Apiservices.SOCIALMEDIA_LIST);
      ipList();
      setAdminType(adminInfo?.adminType);
    }
  }, [user]);

  const handleFilter = async (apiName) => {

    setLoading(true);
    try {
      const response = await postData(apiName);
      if (response.status === true) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setFormData("");
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      setFormData("");
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const ipList = async () => {
    setLoading(true);
    setCurrentPage(0);
    setDateRange([null, null]);
    setinputSearch("");
    setDateValue({
      startDate: "",
      endDate: ""
    })
    try {
      const response = await postData(Apiservices.SOCIALMEDIA_LIST);
      setSelectedItem('Over All Bonus');
      if (response.status === true) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setFormData("");
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      setFormData("");
      toast.error("Internal Server Error...");
      setLoading(false);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    console.log(selected, "selected==========", currentPage);
    setinputSearch("")
    // setDateRange([]);
    // handlePagePost(selected);
    filterPaginationList({ 
      page: selected, 
      status: selectedItem, 
      startDate: DateValue.startDate, 
      endDate: DateValue.endDate,
      });
  };

  // const handlePagePost = async (selected) => {
  //   setLoading(true);
  //   const postForm = {
  //     page: selected + 1,
  //     search: inputSearch,
  //   };

  //   if (dateRange[0] && dateRange[1]) {
  //     postForm.startDate = formatDate(dateRange[0]);
  //     postForm.endDate = formatDate(dateRange[1]);
  //   }

  //   const queryString = new URLSearchParams(postForm).toString();
  //   try {
  //     if (user === undefined) {
  //       // const response = await postData(`${Apiservices.VotingList}?${queryString}`);
  //       const response = await postData(Apiservices.SOCIALMEDIA_LIST, postForm);



  //       if (response.status === true) {
  //         setLoading(false);
  //         setFormData(response.data.data);
  //         setTotalPagination(response.data.totalPages);
  //         setTotalLimit(response.data.limit);
  //       } else {
  //         setLoading(false);
  //         setFormData("");
  //       }
  //     } else {
  //       // const response = await postData(`${user}?${queryString}`);
  //       const response = await postData(user, postForm);


  //       if (response.status === true) {
  //         setLoading(false);
  //         setFormData(response.data.data);
  //         setTotalPagination(response.data.totalPages);
  //         setTotalLimit(response.data.limit);
  //         setCount(response.data.currentCount);

  //       } else {
  //         setLoading(false);
  //         setFormData('');

  //         toast.error(response.message);
  //       }
  //     }
  //   } catch (error) {

  //     toast.error("Internal Server Error..Try Again Later");
  //     setLoading(false);
  //   }
  // };



  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);

  }
  const [showDetails, setShowDetails] = useState('')
  const [getId, setId] = useState()
  const handleUpdate = (record) => {
    setShowDetails(record)
    setId(record.votingId);
    setmodal_update_list(true);
  };

  const handleDateTimeModify = (data) => {
    if (data.createdAt) {
      const formattedDate = utcToNormalTime(data.createdAt);
      return `${handleNullValue(formattedDate.date)} ${formattedDate.time}`;
    } else {
      return "---";
    }

    // if(data.status === 0) { // pending
 
    // } else if(data.status === 1 || data.status === 2) {
    //   if (data.updatedAt) {
    //     const formattedDate = utcToNormalTime(data.updatedAt);
    //     return `${handleNullValue(formattedDate.date)} ${formattedDate.time}`;
    //   } else {
    //     return "---";
    //   }
    // }

  };
  const allowedActions = [1,2,3,4];

  const columns = [

    {
      name: "S.No",
      selector: (row, index) =>currentPage * itemsPerPage + index + 1,
      sortable: true,
      minWidth: '50px',
      // grow: 1,
    },
    // {
    //   name: "Voteid",
    //   selector: (row) => handleNullValue(row.votingId),
    //   sortable: true,
    //   minWidth: '100px',
    //   // grow: 2,
    // },
    {
      name: "Name",
      selector: (row) => handleNullValue(row?.fullName),
      sortable: true,
      minWidth: '200px',
      // grow: 2,
    },
    {
      name: "email",
      selector: (row) => <div className="d-flex align-items-center">
      <Link className="link-tag" to={`/allUsers/userDetails/${row.userId}`} target="_blank">
        <span className="mx-1 text-decoration-underline">
          {row.email || '-'}
        </span>
      </Link>
    </div>,
      sortable: true,
      minWidth: '300px',
      // grow: 2,
    },
    // {
    //   name: "Bonus",
    //   selector: (row) => handleNullValue(row?.email),
    //   sortable: true,
    //   minWidth: '300px',
    //   // grow: 2,
    // },
    // {
    //   name: "Instagram Image",
    //   selector: (row) => <img   style={{ width: "100px",   height: "50px", objectFit: "cover",}} 
    //   className="img-fluid" src={row?.files && row?.files.filter((data) => (data.fileName == "Instagram") && data.url) || '-'} />,
    //   sortable: true,
    //   minWidth: '150px',
    //   maxWidth: '200px'
    // },

    // {
    //   name: "Twitter Image",
    //   selector: (row) => <img   style={{ width: "100px",   height: "50px", objectFit: "cover",}} 
    //   className="img-fluid" src={row.votedImage || '-'} />,
    //   sortable: true,
    //   minWidth: '150px',
    //   maxWidth: '200px'
    // },

    {
      name: "Status",
      selector: (row) => (
        <div className="edit">
          <span className={`badge ${getVoteBadgeClass(row.status)} me-1`}>
            {getVoteStatusText(row.status)}
          </span>
        </div>
      ),
      sortable: true,
      minWidth: '100px',
      // grow: 2,
    },
    {
      name: "Upload From",
      selector: (row) => handleNullValue(row?.osType == 0 ? 'Web' : 'Android' ),
      sortable: true,
      minWidth: '100px',
      // grow: 2,
    },
    {
      name: "Date & Time",
      selector: (row) => handleDateTimeModify(row),
      sortable: true,
      minWidth: '200px',
      // grow: 3,
    },
    ...(adminType === 1 || adminInfo?.permissions.some(data => 
      data.module === "Overall Bonus" && allowedActions.some(action => data.actions.includes(action))) ? [
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex">
          {row.status === Boolian.PENDING_USER ? (
            (adminType === 1 || adminInfo?.permissions.some(data => 
              data.module === "Overall Bonus" && data.actions.includes(3))) ? (
            <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-border-color cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>
              ):  null 
          ) : (
            (adminType === 1 || adminInfo?.permissions.some(data => 
              data.module === "Overall Bonus" && data.actions.includes(2))) ? (
            <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-eye-outline cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>
              ):  null
          )}
        </div>
      ),
      ignoreRowClick: true,
      minWidth: '150px',
      maxWidth: '200px'
    }]:[]),

  ];

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "" && user === undefined) {
      ipList();
      setSearchValid(false);
    } else if (value === "") {
      handleFilter(user);
    }
    setSelectedItem('Over All Bonus');
    setinputSearch(value);
  };

  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
        // page: currentPage + 1,
        // limit: itemsPerPage,
      };
      filterPaginationList({ search: inputSearch, page: 0, startDate: DateValue.startDate, endDate: DateValue.endDate })
      return false
      const queryString = new URLSearchParams(postForm).toString();
      try {
        if (user === undefined) {
          // const response = await postData(`${Apiservices.VotingList}?${queryString}`);
          const response = await postData(Apiservices.SOCIALMEDIA_LIST, postForm);
          // const response = await postData(`${Apiservices.SOCIALMEDIA_LIST}?${queryString}`);
          if (response.status === true) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);
          } else {
            setFormData("");
            setSearchLoad(false);
          }
        } else {
          // const response = await postData(`${user}?${queryString}`);
          const response = await postData(user, postForm);

          if (response.status === true) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);

          } else {
            setSearchLoad(false);
            setFormData("");
          }
        }
      } catch (error) {
        setFormData("");
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };


  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const handleDateChange = ([start, end]) => {
    const formattedStart = start ? formatDate(start) : null;
    const formattedEnd = end ? formatDate(end) : null;
    setinputSearch("")
    setDateRange([start, end]);
    setDateValue({
      startDate: formattedStart,
      endDate: formattedEnd
    });
    if (!formattedStart && !formattedEnd) {
      setSelectedItem("Over All Bonus");
      handleFilter(user);
      setDownloadEndDate({});
    }

    if (formattedStart && formattedEnd) {
      setDownloadEndDate({
        startDate: formattedStart,
        endDate: formattedEnd,
      });
      filterPaginationList({ 
        page: 0,
        status: selectedItem, 
        startDate: formattedStart, 
        endDate: formattedEnd,

      });
      handleDate({ startDate: formattedStart, endDate: formattedEnd });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };


  const handleDate = async ({ startDate, endDate }) => {
    return false;
    setLoading(true);
    const postForm = {
      startDate: startDate,
      endDate: endDate,
    };
    if (inputSearch) {
      postForm.search = inputSearch
    }
    // if (selectedCountry) {
    //   postForm.country = selectedCountry
    // }

    try {
      const queryString = new URLSearchParams(postForm).toString();

      // const response = await postData(`${user}?${queryString}`);
      const response = await postData(user, postForm);

      if (response) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };
  const handleDownload = async (format) => {
    setSelectedFormat(format);
    setDownLoading(true);
    try {
      const postForm = {};

      if (downloadStartDate.startDate) {
        postForm.startDate = downloadStartDate.startDate;
      }
      if (downloadStartDate.endDate) {
        postForm.endDate = downloadStartDate.endDate;
      }
      if (inputSearch) {
        postForm.search = inputSearch;
      }
      postForm.download = true;
      postForm.format = format
      const queryString = new URLSearchParams(postForm).toString();

      const response = await axiosInstance.post(
        // `${user}?${queryString}`,
        user, postForm,
        {
          // Include any data you need to send with the POST request here
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
      if (response.data) {
        setDownLoading(false);
        const blob = response.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute('download', `${user}.xlsx`);
        link.setAttribute("download", `SOCIALMEDIA_LIST.${format}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error(response.data.message);
        setDownLoading(false);
      }
    } catch (err) {
      setDownLoading(false);
      console.error("Error downloading the file", err);
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("");
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);


  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage));
    HandleClickRowperpage(newRowsPerPage)
  }
  const HandleClickRowperpage = async (newRowsPerPage) => {
    setLoading(true);
    const postForm = {
      // page: selected + 1,
      page: Number(currentPage + 1),
      limit: Number(newRowsPerPage),
      // search: inputSearch,
    };

    if (dateRange[0] && dateRange[1]) {
      postForm.startDate = formatDate(dateRange[0]);
      postForm.endDate = formatDate(dateRange[1]);
    }

    const queryString = new URLSearchParams(postForm).toString();
    try {
      if (user === undefined) {
        // const response = await postData(`${Apiservices.VotingList}?${queryString}`);
        const response = await postData(Apiservices.SOCIALMEDIA_LIST, postForm);


        if (response.status === true) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
        } else {
          setLoading(false);
          setFormData("");
        }
      } else {
        // const response = await postData(`${user}?${queryString}`);
        const response = await postData(Apiservices.SOCIALMEDIA_LIST, postForm);


        if (response.status === true) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
          toast.error(response.message);
          setFormData("");
        }
      }
    } catch (error) {

      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      setFormData("");
    }

  };
  // const exportOptions = [
  //   { label: "PDF", value: "pdf" },
  //   { label: "CSV", value: "csv" },
  //   { label: "XLSX", value: "xlsx" },
  // ];
  // const [isLoading, setIsLoading] = useState(false)


  const handleSubmit = async (status, reason) => {
    setButtonLoading(true);
    try {
      const postForm = {
        mediaBonusId: showDetails.mediaBonusId,
        status: status,
        adminType:adminInfo?.adminType,
        permissions:adminInfo?.permissions,
        
      };
  
      if (reason) {
        postForm.reason = reason;
      }
  
      const response = await postData(Apiservices.SOCIALMEDIA_UPDATE_STATUS, postForm);
      
      if (response.status) {
        setmodal_update_list(false)
        ipList()
        setButtonLoading(false);
        toast.success(response.message);
      } else {
        setButtonLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      setButtonLoading(false);
      toast.error("Internal Server Error..Try Again Later");
    }
  };
  
  const statusAlert = (e, status) => {
    e.preventDefault();

    if(status===1){
      handleSubmit(status)

    }else{

      showAlert(handleSubmit, status); // Pass the status to showAlert
    }
  };
  
  const showAlert = (onConfirm, status) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to reject this record!`,
      inputLabel: "Enter a Reason",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off"
      },
      inputPlaceholder: "Enter Reason",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!',
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async (reason) => {
        if (!reason) {
          Swal.showValidationMessage('Reason is required');
        } else {
          Swal.showLoading();
          try {
            await onConfirm(status, reason); // Pass the status and reason to handleSubmit
          } catch (error) {
            Swal.fire('Error!', 'There was an error updating the status', 'error');
          }
        }
      },
    });
  };
  
  
  const [rejLoad, setRejLoad] = useState(false)
  const handleToggle2 = async (rowDetails, reason) => {

    const postForm = {
      // acId: rowDetails.acId,
      reason: reason,
      votingId: getId,
      status: 2
    };

    try {
      setRejLoad(true);
      const response = await postData(Apiservices.VotingUpdate, postForm);
      if (response.status) {
        toast.dismiss();
        ipList()
        setRejLoad(false);
        setmodal_update_list(false)
        Swal.fire('Success!', 'The Voting Request has been rejected successfully', 'success');
      } else {
        // toast.error(response.message);
        Swal.fire('Error!', 'There was an error rejecting this record', 'error');
        setRejLoad(false);
      }
    } catch (error) {
      toast.dismiss();
      // toast.error("Internal Server Error..Try Again Later");
      Swal.fire('Error!', 'There was an error rejecting this record', 'error');
      setRejLoad(false);
    }
  };


  const [buttonLoading, setButtonLoading] = useState(false);

  const [appLoad, setAppLoad] = useState(false)
  // const validation = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     otp: ''
  //   },
  //   validationSchema: Yup.object({
  //     otp: Yup.string()

  //       .required('OTP is required'),
  //   }),
  //   onSubmit: (values) => {
  //     otpVerify(values)
  //   },
  // });
  // const otpVerify = async (data) => {
  //   setAppLoad(true)
  //   const statusdata = {
  //     votingId: getId,
  //     status: 1,
  //     otp: data.otp,
  //   }

  //   try {
  //     const response = await postData(Apiservices.VotingUpdate, statusdata);
  //     if (response.status) {
  //       toast.success(response.message);
  //       setAppLoad(false)
  //       setShowOTPModel(false)
  //       setmodal_update_list(false)
  //       ipList()
  //       validation.resetForm()
  //     } else {
  //       toast.dismiss()
  //       toast.error(response.message);
  //       setLoading(false);
  //       // setmodal_list(false);
  //       setAppLoad(false)

  //     }
  //   } catch (error) {
  //     toast.dismiss()

  //     toast.error("Internal Server Error..Try Again Later");
  //     setLoading(false);
  //     setAppLoad(false)
  //   }
  // };


  // const [FileName, setFileName] = useState("OverAllVotes");
  const handleDropdownClick = async(item) => {
    setCurrentPage(0);
    setSelectedItem(item);
    setDateRange([null, null]);
    setinputSearch("");
    // setDateValue({
    //   startDate: "",
    //   endDate: ""
    // })
    console.log("currentPage =====", currentPage);
    
    filterPaginationList({ page: 0, status: item });
  };

  function tog_xlarge({url, fileName}) {
    setFullImage({
      url: url,
      fileName: fileName
    });
    setmodal_xlarge(!modal_xlarge);
}

const filterPaginationList = async (params) => {
  console.log("params==============", params);
  
    try {
      const postForm = {
        status: params.status,
        page: params.page + 1 ,
        search: params.search || '',
        startDate:  params.startDate || "",
        endDate: params.endDate || "",
      }
      // if(params.page) postForm.page = params.page + 1
      setLoading(true);
      if (user === undefined) {
        // const response = await postData(`${Apiservices.VotingList}?${queryString}`);
        const response = await postData(Apiservices.SOCIALMEDIA_LIST, postForm);
        // const response = await postData(`${Apiservices.SOCIALMEDIA_LIST}?${queryString}`);
        if (response.status === true) {
          setSearchLoad(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);

          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setFormData("");
          setSearchLoad(false);
        }
      } else {
        // const response = await postData(`${user}?${queryString}`);
        const response = await postData(user, postForm);

        if (response.status === true) {
          setLoading(false);
          setSearchLoad(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setCount(response.data.currentCount);
          setTotalLimit(response.data.limit);
        } else {
          setSearchLoad(false);
          setLoading(false);
          setFormData("");
        }
      }
    } catch (error) {
    console.log("filter error: ", error);
    setFormData("");
    toast.error("Internal Server Error..Try Again Later");
    setSearchLoad(false);
  }
}
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="SocialMedia Bonus" breadcrumbItem="Overall Bonus" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col sm={4} lg={3}>
                      <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          value={inputSearch}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                            {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4} lg={3}>
                      <div className="form-group mb-4">
                        <div>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select Start to End Date"
                              options={{
                                mode: "range",
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                                maxDate: today,
                              }}
                              value={dateRange}
                              onChange={handleDateChange}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Col>

                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <Dropdown className="mx-2"
                          isOpen={btnauto3}
                          toggle={() => setbtnauto3(!btnauto3)}
                          id="dropdownMenuClickableInside"
                        >
                          <DropdownToggle tag="button" className="btn btn-info">
                            {selectedItem}
                            <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start jega-ui">
                            <DropdownItem header>Select</DropdownItem>
                            <DropdownItem
                              onClick={() => ipList()}
                            >
                              Over All Bonus
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleDropdownClick("pending")}
                            >
                              Pending Bonus
                            </DropdownItem>


                            <DropdownItem
                              onClick={() =>
                                handleDropdownClick("approved")
                              }
                            >
                              Approved Bonus
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleDropdownClick("rejected")
                              }
                            >
                              Rejected Bonus
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                        {/* <Button
                          color="info"
                          className="add-btn mx-2"
                          onClick={() => handleDownload('xlsx')}
                          id="create-btn"
                          disabled={downLoading}
                        >
                          <i className="mdi mdi-download-outline"></i>

                          {downLoading ? <Loader /> : "Download"}
                        </Button> */}
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={formData}
                      paginationServer
                      paginationTotalRows={formData.length}
                      progressPending={loading}
                      persistTableHead={true}
                      progressComponent={<div className="py-3">Loading...</div>}
                      noDataComponent={<div className="py-3">No records found</div>}
                      fixedHeader={true}
                      paginationPerPage={rowsPerPage} // Add this line to control rows per page
                    />
                  </div>


                  <div className="container">
                    <div className="row justify-content-end mt-4">
                      <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                        {/* <div className="d-flex align-items-center me-3">
                          <span className="me-2">Rows per page:</span>
                          <select
                            value={rowsPerPage}
                            onChange={(e) => handleRowsPerPageChange(e.target.value)}
                            className="form-control-sm"
                          >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                          </select>
                        </div> */}
                        <div className="me-md-3 mb-3 mb-md-0">
                          <span>{count}</span>
                        </div>
                        <div className="pagination pagination-rounded mt-3">
                          <ReactPaginate
                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            breakLinkClassName={"break-link"}
                            disabledClassName={"disabled"}
                            initialPage={currentPage}
                            disableInitialCallback={true}
                            forcePage={currentPage}
                            renderLink={(props) => {
                              if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                return (
                                  <a
                                    {...props}
                                    onClick={() =>
                                      handlePageClick({
                                        selected: pageCount - 1,
                                      })
                                    }
                                  >
                                    {pageCount}
                                  </a>
                                );
                              }
                              return <a {...props} />;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* UpdateModal */}
                  <Modal className="modal-dialog modal-lg" isOpen={modal_update_list} toggle={() => { tog_update_list(); }} centered>
                    <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>
                      SocialMedia Details
                    </ModalHeader>
                    <Form
                      autoComplete="off" className="form-horizontal"
                    // onSubmit={handleSubmit}
                    >
                      <ModalBody>
                        <Row>
                          <div className="table-responsive">
                            <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                              <tbody>
                                {/* <tr>
                                  <td><b>Site Name</b></td>
                                  <td>{showDetails?.category || '-'}</td>
                                </tr> */}
                                <tr>
                                  <td><b>User Name</b></td>
                                  <td>{showDetails?.fullName || '-'}</td>
                                </tr>
                                <tr>
                                  <td><b>User Email Address</b></td>
                                  <td>{showDetails?.email || '-'}</td>
                                </tr>
                                {/* <tr>
                                    <td><b>Voting ID</b></td>
                                    <td>{showDetails?.votingId || '-'}</td>
                                </tr> */}

                                {showDetails.status !== 0 ?
                                  <>
                                    <tr>
                                      <td><b>Admin Name</b></td>
                                      <td>{showDetails?.adminName || '-'}</td>
                                    </tr>
                                    <tr>
                                      <td><b>Admin Email Address</b></td>
                                      <td>{showDetails?.adminEmail || '-'}</td>
                                    </tr>
                                  </> : ''}

                                {showDetails.status === 2 ?
                                  <tr>
                                    <td><b>Reason</b></td>
                                    <td>{showDetails?.reason || '-'}</td>
                                  </tr>

                                  : ''}

                                <tr>
                                  <td><b>Status</b></td>
                                  <td>
                                    {showDetails.status === 1
                                      ? 'Approved'
                                      : showDetails.status === 2
                                        ? 'Rejected'
                                        : 'Pending'}
                                  </td>

                                </tr>

                                {showDetails.status === 0 ?
                                  <tr>

                                    <td><b>Uploaded Date & Time</b></td>
                                    <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                                  </tr> : ''}

                                {showDetails.status === 1 ?
                                 ( 
                                  <>
                                    <tr>

                                      <td><b>Requested Date & Time</b></td>
                                      <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                                    </tr>
                                   <tr>

                                      <td><b>Approved Date & Time</b></td>
                                      <td>{utcToNormalTime(showDetails.updatedAt).date} {utcToNormalTime(showDetails.updatedAt).time || '-'}</td>
                                  </tr>
                                  </>
                                
                                ) : ''}
                                {showDetails.status === 2 ?
                                 ( 
                                  <>
                                    <tr>

                                      <td><b>Requested Date & Time</b></td>
                                      <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                                    </tr>
                                    <tr>

                                      <td><b>Rejected Date & Time</b></td>
                                      <td>{utcToNormalTime(showDetails.updatedAt).date} {utcToNormalTime(showDetails.updatedAt).time || '-'}</td>
                                      </tr>
                                  </>
                                
                                ) 
                                   : ''}

                              </tbody>
                            </table>

                            <div className="row">
                              {
                                showDetails && showDetails.files.length > 0 && showDetails.files.map(function (item, index) {

                                  let serial = parseInt(1) + index;
                                  let mod = serial % 2;

                                  return (
                                    <div className="col-md-3">
                                      <Card>
                                        <CardBody>
                                          <div className="">{item?.fileName} Image</div>
                                          <CardImg top src={item?.url || '-'} 
                                          style={{ width: '100px', height: '100px', objectFit: 'contain' }} 
                                          onClick={() => tog_xlarge({url: item?.url, fileName: item?.fileName})}
                                          />
                                        </CardBody>
                                      </Card>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </Row>

                      </ModalBody>
                      <ModalFooter>
                        {showDetails.status === 0 ? (
                          <div className="hstack gap-2 justify-content-end">
                            <button
                              onClick={(e) => statusAlert(e, 1)}
                              className="btn btn-green text-white waves-effect waves-light"
                              disabled={buttonLoading}
                            >
                              {buttonLoading ? <Loader /> : "Approve"}
                            </button>


                            <button
                              onClick={(e) => statusAlert(e, 2)}
                              className="btn btn-danger waves-effect waves-light"
                              disabled={rejLoad}
                            >
                              {rejLoad ? <Loader /> : "Reject"}
                            </button>
                          </div>
                        ) : (
                          ""
                        )}

                      </ModalFooter>
                    </Form>
                  </Modal>
                  <Modal
                size="l"
                isOpen={modal_xlarge}
                toggle={() => {
                    tog_xlarge();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                       { fullImage.fileName } Image
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_xlarge(false);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body d-flex justify-content-center">
                    <img
                        style={{ width: "450px", borderRadius: "10px" }}
                        src={fullImage.url}
                        alt=""
                    />
                </div>
            </Modal>
                  {/* <Modal className="modal-dialog modal px-4" size="l" isOpen={showOTPModel} centered backdrop="static"  >
                    <div className="modal-header">
                      <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel"
                      >
                        Approve Confirmation OTP
                      </h5>
                      <button
                        onClick={() => {
                          setShowOTPModel(false);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div>
                        <div className="text-center">
                        </div>
                        <h4 className="font-size-18 text-muted mt-2 text-center">
                          Verify Your OTP
                        </h4>

                        <div className="user-thumb text-center m-b-30">
                          <h4 className="rounded-circle avatar-lg img-thumbnail mx-auto d-flex justify-content-center align-items-center">{countdown === 60 ? ("0") : (countdown)}<span style={{ fontSize: '15px', marginTop: '7px' }}>s</span></h4>
                        </div>
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >

                          <Row>
                            <Col md={12} className="AuthStar">
                              <div className="mb-4">
                                <Label className="form-label">One Time Password<span>*</span></Label>
                                <InputGroup>
                                  <Input
                                    style={{ borderColor: '#80838B' }}
                                    name="otp"
                                    disabled={loading || countdown === 60 || countdown === 0}
                                    className="form-control"
                                    placeholder="Enter OTP"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.otp || ""}
                                    invalid={validation.touched.otp && validation.errors.otp ? true : false}
                                  />

                                  {validation.touched.otp && validation.errors.otp ? (
                                    <FormFeedback>{validation.errors.otp}</FormFeedback>
                                  ) : null}
                                </InputGroup>
                              </div>

                              <div className="d-grid mt-4">

                                <button
                                  className="btn btn-primary waves-effect waves-light"
                                  type="submit"
                                  disabled={appLoad || countdown === 60 || appLoad}
                                  onClick={validation.handleSubmit}
                                >
                                  {appLoad ? (
                                    <>
                                      <Spinner size="sm">
                                        Loading...
                                      </Spinner>
                                      <span style={{ color: 'white' }}>
                                        {' '}Loading...
                                      </span>
                                    </>
                                  ) : ("Submit")}

                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>

                    </div>
                  </Modal> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SocialList;

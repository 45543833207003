import React, { useState, useEffect, useContext } from "react";
import { Input, Card, CardBody, Col, Container, Row,Label,FormFeedback, Button, Modal, ModalBody, ModalHeader, CardImg, ModalFooter, Spinner, Form, InputGroup } from "reactstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../ApiService/AxiosInstance";
import { Apiservices, Boolian } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useLocation } from "react-router-dom";
import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AdminContext } from "../../helpers/adminContext";

const PendingVotes = () => {
  document.title = "Ultrapro | Voting List";

  const location = useLocation();
  const today = new Date();
  const userApi = location.state?.user;

  const [dateRange, setDateRange] = useState([null, null]);
  const [downloadStartDate, setDownloadEndDate] = useState({});
  const [loading, setLoading] = useState(false);
  const [downLoading, setDownLoading] = useState(false);

  const [searchLoad, setSearchLoad] = useState(false);
  const [searchValid, setSearchValid] = useState(false);
  const [inputSearch, setinputSearch] = useState("");
  const [formData, setFormData] = useState([]);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [count, setCount] = useState("");
 
  //Permission List 
  const [adminType,setAdminType] = useState('');
  const { adminInfo } = useContext(AdminContext);

  const [user, setUser] = useState(userApi);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);
  // const pageCount = 5;

  useEffect(() => {
    if (user === undefined) {
      // const postForm = {
      //  status:'Pending'
      // };
      setUser(Apiservices.PendingVotesList);

      ipList();
      setAdminType(adminInfo?.adminType);
    }
  }, [user,adminInfo?.adminType]);

  const handleFilter = async (apiName) => {

    setLoading(true);
    // const postForm = {
    //     ...filterParams
    // };
    try {
      const response = await postData(apiName);
      if (response.status === true) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setFormData("");
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      setFormData("");
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const ipList = async () => {
    setLoading(true);

    try {
      const postForm = {
        status:'Pending'
       };
       const queryString = new URLSearchParams(postForm).toString();

      const response = await postData(`${Apiservices.PendingVotesList}?${queryString}`);

      if (response.status === true) {
        setLoading(false);
        setFormData(response.data.data);
     

        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setFormData("");
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      setFormData("");
      toast.error("Internal Server Error...");
      setLoading(false);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);

  };

  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: rowsPerPage,
      search: inputSearch,
      status:"Pending"
    };

    if (dateRange[0] && dateRange[1]) {
      postForm.startDate = formatDate(dateRange[0]);
      postForm.endDate = formatDate(dateRange[1]);
    }

    try {
      if (user === undefined) {
        // const response = await postData(`${Apiservices.PendingVotesList}?${queryString}`);
        const response = await postData(Apiservices.PendingVotesList.postForm);

        if (response.status === true) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          
          
        } else {
          setLoading(false);
          setFormData("");
        }
      } else {
        // const response = await postData(`${user}?${queryString}`);
        const response = await postData(user.postForm);


        if (response.status === true) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
          
        } else {
          setLoading(false);
          setFormData("");
          toast.error(response.message);
        }
      }
    } catch (error) {
      setFormData("");
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const getStatusText = (code) => {
    switch (code) {
      case Boolian.PENDING_USER:
        return "Pending";
      case Boolian.USER_VERIFIED:
        return "Approved";
      case Boolian.USER_BLOCKED:
        return "Rejected";
      default:
        return "Unknown";
    }
  };

  const getBadgeClass = (code) => {
    switch (code) {
      case Boolian.PENDING_USER:
        return "badge-soft-warning";
      case Boolian.USER_VERIFIED:
        return "badge-soft-success";
      case Boolian.USER_BLOCKED:
        return "badge-soft-danger";
      default:
        return "";
    }
  };
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);

  }
  const [showDetails, setShowDetails] = useState('')
  //const [getStatus, setGetStatus] = useState('')
  const [getId, setId] = useState()
  const handleUpdate = (record) => {
    setShowDetails(record)
    setId(record.votingId);
    // setGetStatus(record.votingId);

    // setGetImage(record.discoverImage)
    // setSessionLink(record.discoverVideoLink)
    // validationUpdate.setValues({
    //   discoverId: getId || "",
    //   discoverTitle: record.discoverName || "",
    //   position:record.position || "",
    //   videolink:record.discoverVideoLink || "",
    //   isActive: record.discoverIsActive !== undefined ? String(record.discoverIsActive) : "",

    // });

    setmodal_update_list(true);
  };


  const allowedActions = [1,2,3,4];
  const columns = [

    {
      name: "S.No",
      selector: (row, index) => currentPage * itemsPerPage + index + 1,
      sortable: true,
      minWidth: '50px',
      // grow: 1,
    },
    // {
    //   name: "Voteid",
    //   selector: (row) => handleNullValue(row.votingId),
    //   sortable: true,
    //   minWidth: '100px',
    //   // grow: 2,
    // },
    {
      name: "email",
      selector: (row) => handleNullValue(row?.userDetails?.email),
      sortable: true,
      minWidth: '300px',
      // grow: 2,
    },
    {
      name: "Name",
      selector: (row) => handleNullValue(row?.userDetails?.fullName),
      sortable: true,
      minWidth: '200px',
      // grow: 2,
    },
    {
      name: "Site Name",
      selector: (row) => handleNullValue(row?.category),
      sortable: true,
      minWidth: '200px',
      // grow: 2,
    },
    
    {
      name: "Image",
      selector: (row) => <img  style={{ width: "100px",   height: "50px", objectFit: "cover",}} 
       className="img-fluid" src={row.votedImage || '-'}  alt={row.votedImage ? "User uploaded image" : "No image available"} />,
      sortable: true,
      minWidth: '150px',
      maxWidth: '200px'
    },

    {
      name: "Status",
      selector: (row) => (
        <div className="edit">
          {/* <span className={`badge ${getBadgeClass(row.IsActive)} me-1`}>
                        {getStatusText(row.IsActive)}
                    </span> */}
          <span className={`badge ${getBadgeClass(row.status)} me-1`}>
            {getStatusText(row.status)}
          </span>
        </div>
      ),
      sortable: true,
      minWidth: '100px',
      // grow: 2,
    },
    {
      name: "Date & Time",
      selector: (row) =>
        row.createdAt
          ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${utcToNormalTime(row.createdAt).time
          }`
          : "---",
      sortable: true,
      minWidth: '200px',
      // grow: 3,
    },
    // {
    //   name: "Updated Date & Time",
    //   selector: (row) =>
    //     row.updatedAt
    //       ? `${handleNullValue(utcToNormalTime(row.updatedAt).date)} ${
    //           utcToNormalTime(row.updatedAt).time
    //         }`
    //       : "---",
    //   sortable: true,
    //   minWidth: '200px',
    //   // grow: 3,
    // },
    ...(adminType === 1 || adminInfo?.permissions.some(data => 
      data.module === "Pending Votes" && allowedActions.some(action => data.actions.includes(action))) ? [
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex">
          {row.status === Boolian.PENDING_USER ? (
            <>
             {(adminType === 1 || adminInfo?.permissions.some(data => 
              data.module === "Pending Votes" && data.actions.includes(3)) ? (
            <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-border-color cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>
              ): null)}
            </>
          ) : (
            <>
             {(adminType === 1 || adminInfo?.permissions.some(data => 
              data.module === "Pending Votes" && data.actions.includes(2)) ? (
            <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-eye-outline cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>
              ): null )}
            </>

          )}
        </div>
      ),
      ignoreRowClick: true,
      minWidth: '150px',
      maxWidth: '200px'
    }]:[]),

  ];

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "" && user === undefined) {
      ipList();
      setSearchValid(false);
    } else if (value === "") {
      const postForm = {
        status:'Pending'
      }
      // const queryString = new URLSearchParams(postForm).toString();
      handleFilter(Apiservices.PendingVotesList,postForm);
    }
    setinputSearch(value);
  };

  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
           status:"Pending"
        // page: currentPage + 1,
        // limit: itemsPerPage,
      };

      // const queryString = new URLSearchParams(postForm).toString();
      try {
        if (user === undefined) {
          // const response = await postData(`${Apiservices.PendingVotesList}?${queryString}`);
        const response = await postData(Apiservices.PendingVotesList,postForm);

          if (response.status === true) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);
          } else {
            setFormData("");
            setSearchLoad(false);
          }
        } else {
          // const response = await postData(`${user}?${queryString}`);
          const response = await postData(user.postForm);
          if (response.status === true) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);

          } else {
            setFormData("");
            setSearchLoad(false);
          }
        }
      } catch (error) {
        setFormData("");
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };


  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // const handleDateChange = ([start, end]) => {
  //   const formattedStart = start ? formatDate(start) : null;
  //   const formattedEnd = end ? formatDate(end) : null;

  //   setDateRange([start, end]);

  //   if (!formattedStart && !formattedEnd) {
  //     handleFilter(user);
  //     setDownloadEndDate({});
  //   }

  //   if (formattedStart && formattedEnd) {
  //     setDownloadEndDate({
  //       startDate: formattedStart,
  //       endDate: formattedEnd,
  //     });
  //     handleDate({ startDate: formattedStart, endDate: formattedEnd });
  //   }
  // };
  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     handleSearch();
  //   }
  // };
  const handleDateChange = ([start, end]) => {
    const formattedStart = start ? formatDate(start) : null;
    const formattedEnd = end ? formatDate(end) : null;

    setDateRange([start, end]);

    if (!formattedStart && !formattedEnd) {
      const postForm = {
        status:'Pending'
      }
      // const queryString = new URLSearchParams(postForm).toString();
      // handleFilter(`${Apiservices.PendingVotesList}?${queryString}`);
      handleFilter(Apiservices.PendingVotesList,postForm);
      setDownloadEndDate({});
    }

    if (formattedStart && formattedEnd) {
      setDownloadEndDate({
        startDate: formattedStart,
        endDate: formattedEnd,
      });
      handleDate({ startDate: formattedStart, endDate: formattedEnd });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  // const handleDate = async ({ startDate, endDate }) => {
  //   setLoading(true);

  //   const postForm = {
  //     startDate: startDate,
  //     endDate: endDate,
  //   };
  //   try {
  //     const queryString = new URLSearchParams(postForm).toString();

  //     const response = await postData(`${user}?${queryString}`);
  //     if (response.status) {
  //       setLoading(false);
  //       setFormData(response.data.data);
  //       setTotalPagination(response.data.totalPages);
  //       setTotalLimit(response.data.limit);
  //       setCount(response.data.currentCount);
  //     } else {
  //       setLoading(false);
  //       toast.error(response.message);
  //     }
  //   } catch (error) {
  //     toast.error("Internal Server Error..Try Again Later");
  //     setLoading(false);
  //   }
  // };
  const handleDate = async ({ startDate, endDate }) => {
    setLoading(true);

    const postForm = {
      startDate: startDate,
      endDate: endDate,
      status:'Pending'
    };
    if (inputSearch) {
      postForm.search = inputSearch
    }
    // if (selectedCountry) {
    //   postForm.country = selectedCountry
    // }
   
    try {
      // const queryString = new URLSearchParams(postForm).toString();

      // const response = await postData(`${user}?${queryString}`);
      const response = await postData(user.postForm);
      
      if (response) {
        setLoading(false);
      
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };
  const handleDownload = async (format) => {
   // setSelectedFormat(format);
    setDownLoading(true);
    try {
      const postForm = {
        status:"Pending"
      };

      if (downloadStartDate.startDate) {
        postForm.startDate = downloadStartDate.startDate;
      }
      if (downloadStartDate.endDate) {
        postForm.endDate = downloadStartDate.endDate;
      }
      if (inputSearch) {
        postForm.search = inputSearch;
      }
      postForm.download = true;
      postForm.format = format
      const queryString = new URLSearchParams(postForm).toString();

      const response = await axiosInstance.post(
        `${user}?${queryString}`,
        {
          // Include any data you need to send with the POST request here
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
      if (response.data) {
        setDownLoading(false);
        const blob = response.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute('download', `${user}.xlsx`);
        link.setAttribute("download", `PendingVotesList.${format}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error(response.data.message);
        setDownLoading(false);
      }
    } catch (err) {
      setDownLoading(false);
      console.error("Error downloading the file", err);
    }
  };

  // const [dropdownOpen, setDropdownOpen] = useState(false);
  //const [selectedFormat, setSelectedFormat] = useState("");
  // const toggleDropdown = () => setDropdownOpen(!dropdownOpen);


  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage));
    HandleClickRowperpage(newRowsPerPage)
  }
  const HandleClickRowperpage = async (newRowsPerPage) => {
    setLoading(true);
    const postForm = {
      // page: selected + 1,
      page: Number(currentPage + 1),
      limit: Number(newRowsPerPage),
      status:"Pending"
      // search: inputSearch,
    };

    if (dateRange[0] && dateRange[1]) {
      postForm.startDate = formatDate(dateRange[0]);
      postForm.endDate = formatDate(dateRange[1]);
    }

    // const queryString = new URLSearchParams(postForm).toString();
    try {
      if (user === undefined) {
        // const response = await postData(`${Apiservices.PendingVotesList}?${queryString}`);
        const response = await postData(Apiservices.PendingVotesList,postForm);

        if (response.status === true) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
        } else {
          setLoading(false);
          setFormData("");
        }
      } else {
        // const response = await postData(`${user}?${queryString}`);
        const response = await postData(user.postForm);

        if (response.status === true) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setFormData("");
          setLoading(false);
          toast.error(response.message);
        }
      }
    } catch (error) {
      setFormData("");
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }

  };
  // const exportOptions = [
  //   { label: "PDF", value: "pdf" },
  //   { label: "CSV", value: "csv" },
  //   { label: "XLSX", value: "xlsx" },
  // ];
  // const [isLoading, setIsLoading] = useState(false)


  const handleSubmit = async (e, status) => {
    e.preventDefault()
    
  setButtonLoading(true)
  try {
     const postForm={
      
     }
      const response=await postData(Apiservices.VotingOtp,postForm)

      if(response.status){
          setCountdown(60)
          setShowOTPModel(true)
          startCountdown()
          // setShowModel(false)
          setButtonLoading(false)
          toast.success(response.message);
          


      }else{
          setButtonLoading(false)
          toast.error(response.message);

      }


  } catch (error) {
      setButtonLoading(false)
      toast.error("Internal Server Error..Try Again Later");
      
  }
    // try {
    //   setIsLoading(true);
    //   const statusdata = {
    //     votingId: getId,
    //     status: status
    //   }

    
    //   const response = await postData(`${Apiservices.VotingUpdate}`, statusdata);

    //   if (response.status) {
    //     toast.dismiss();
    //     setIsLoading(false);
    //     toast.success(response.message);
    //     // Swal.fire("Success !", "The status has been updated successfully", "success");
    //     ipList();
    //   } else {
    //     toast.error(response.message);
    //     setIsLoading(false);
    //   }
    // } catch (error) {
    //   toast.dismiss();
    //   toast.error(error.response?.data?.message || "Internal Server Error. Try Again Later.");
    //   setIsLoading(false);
    // }
    // setmodal_update_list(false);
  };



  const statusAlert = (e,row) => {
    e.preventDefault()
    showAlert(handleToggle2, row);
  };

  const showAlert = (onConfirm, row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to reject this record!`,
      inputLabel: "Enter a Reason",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off"
      },
      inputPlaceholder: "Enter Reason",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!',
      inputValue: row.reason,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async (reason) => {
        if (!reason) {
          Swal.showValidationMessage('Reason is required');
        } else {
          Swal.showLoading();
          try {
            await onConfirm(row, reason);

          } catch (error) {
            // Swal.fire('Error!', 'There was an error updating the status', 'error');
          }
        }
      },
    });
  };
const[rejLoad,setRejLoad] = useState(false)
  const handleToggle2 = async (rowDetails, reason) => {
 
    const postForm = {
      // acId: rowDetails.acId,
      reason: reason,
      votingId: getId,
      status: 2
    };

    try {
      setRejLoad(true);
      const response = await postData(Apiservices.VotingUpdate, postForm);
      if (response.status) {
        toast.dismiss();
        ipList()
        setRejLoad(false);
        setmodal_update_list(false)
        Swal.fire('Success!', 'The Voting Request has been rejected successfully', 'success');
      } else {
        // toast.error(response.message);
        Swal.fire('Error!', 'There was an error rejecting this record', 'error');
        setRejLoad(false);
      }
    } catch (error) {
      toast.dismiss();
      // toast.error("Internal Server Error..Try Again Later");
      Swal.fire('Error!', 'There was an error rejecting this record', 'error');
      setRejLoad(false);
    }
  };
  const [showOTPModel, setShowOTPModel] = useState(false)
  const [countdown, setCountdown] = useState(60);
  const [buttonLoading, setButtonLoading] = useState(false);
  // const [showModel, setShowModel] = useState(false)



  const startCountdown = () => {
    const intervalId = setInterval(() => {
        setCountdown(prevCountdown => {
            const newCountdown = prevCountdown - 1;
            if (newCountdown <= 0) {
                clearInterval(intervalId);
                setShowOTPModel(false);
                // validation.resetForm()
                // setIsDisabled(false)
                return 0;
            }
           
            return newCountdown;
        });
    }, 1000);
};
const [appLoad,setAppLoad] = useState(false)
const validation = useFormik({
  enableReinitialize: true,
  initialValues: {
      otp: ''
  },
  validationSchema: Yup.object({
      otp: Yup.string()

          .required('OTP is required'),
  }),
  onSubmit: (values) => {
      otpVerify(values)
  },
});
const otpVerify = async (data) => {
  setAppLoad(true)
    const statusdata = {
      votingId: getId,
      status: 1,
      otp:data.otp,
    }

  try {
    const response = await postData(Apiservices.VotingUpdate, statusdata); 
    if (response.status) {
      toast.success(response.message);
      setAppLoad(false)
      setShowOTPModel(false)
      setmodal_update_list(false)
      ipList()
      validation.resetForm()
    } else {
      toast.dismiss()
      toast.error(response.message);
      setLoading(false);
      // setmodal_list(false);
      setAppLoad(false)

    }
  } catch (error) {
    toast.dismiss()

    toast.error("Internal Server Error..Try Again Later");
    setLoading(false);
    setAppLoad(false)
 

  

  }
};




  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pending Votes" breadcrumbItem="Pending Voting List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col sm={4} lg={3}>
                      <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                            {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4} lg={3}>
                      <div className="form-group mb-4">
                        <div>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select Start to End Date"
                              options={{
                                mode: "range",
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                                maxDate: today,
                              }}
                              value={dateRange}
                              onChange={handleDateChange}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Col>

                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                         {/* <Dropdown className="mx-2"
                          isOpen={btnauto3}
                          toggle={() => setbtnauto3(!btnauto3)}
                          id="dropdownMenuClickableInside"
                        >
                          <DropdownToggle tag="button" className="btn btn-info">
                            {selectedItem}
                            <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start jega-ui">
                            <DropdownItem header>Select</DropdownItem>
                            <DropdownItem
                              onClick={() => handleDropdownClick("Over All Votes")}
                            >
                              Over All Votes
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleDropdownClick("Pending Votes")}
                            >
                              Pending Votes
                            </DropdownItem>


                            <DropdownItem
                              onClick={() =>
                                handleDropdownClick("Approved Votes")
                              }
                            >
                              Approved Votes
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleDropdownClick("Rejected Votes")
                              }
                            >
                              Rejected Votes
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown> */}
                        <Button
                          color="info"
                          className="add-btn mx-2"
                          onClick={() => handleDownload('xlsx')}
                          id="create-btn"
                          disabled={downLoading}
                        >
                          <i className="mdi mdi-download-outline"></i>

                          {downLoading ? <Loader /> : "Download"}
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={formData}
                      paginationServer
                      paginationTotalRows={formData.length}
                      progressPending={loading}
                      persistTableHead={true}
                      progressComponent={<div className="py-3">Loading...</div>}
                      noDataComponent={<div className="py-3">No records found</div>}
                      fixedHeader={true}
                      paginationPerPage={rowsPerPage} // Add this line to control rows per page
                    />
                  </div>
                  

                  <div className="container">
                    <div className="row justify-content-end mt-4">
                      <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                        <div className="d-flex align-items-center me-3">
                          <span className="me-2">Rows per page:</span>
                          <select
                            value={rowsPerPage}
                            onChange={(e) => handleRowsPerPageChange(e.target.value)}
                            className="form-control-sm"
                          >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                          </select>
                        </div>
                        <div className="me-md-3 mb-3 mb-md-0">
                          <span>{count}</span>
                        </div>
                        <div className="pagination pagination-rounded mt-3">
                          <ReactPaginate
                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            breakLinkClassName={"break-link"}
                            disabledClassName={"disabled"}
                            initialPage={currentPage}
                            disableInitialCallback={true}
                            forcePage={currentPage}
                            renderLink={(props) => {
                              if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                return (
                                  <a
                                    {...props}
                                    onClick={() =>
                                      handlePageClick({
                                        selected: pageCount - 1,
                                      })
                                    }
                                  >
                                    {pageCount}
                                  </a>
                                );
                              }
                              return <a {...props} aria-label="Link description"/>;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* UpdateModal */}
                  <Modal className="modal-dialog modal-lg" isOpen={modal_update_list} toggle={() => { tog_update_list(); }} centered>
                    <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>
                      Voting Details
                    </ModalHeader>
                    <Form
                      autoComplete="off" className="form-horizontal"
                    // onSubmit={handleSubmit}
                    >
                      <ModalBody>
                        <Row>
                          <div className="table-responsive">
                            <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                              <tbody>
                              <tr>
                                  <td><b>Site Name</b></td>
                                  <td>{showDetails?.category || '-'}</td>
                                </tr>
                                <tr>
                                  <td><b>User Name</b></td>
                                  <td>{showDetails?.userDetails?.fullName || '-'}</td>
                                </tr>
                                <tr>
                                  <td><b>User Email Address</b></td>
                                  <td>{showDetails?.userDetails?.email || '-'}</td>
                                </tr>
                                {/* <tr>
                                    <td><b>Voting ID</b></td>
                                    <td>{showDetails?.votingId || '-'}</td>
                                </tr> */}
                              
                              {showDetails.status !== 0 ?  
                               <>
                                    <tr>
                                  <td><b>Admin Name</b></td>
                                  <td>{showDetails?.adminDetails?.name || '-'}</td>
                                </tr>
                                  <tr>
                                  <td><b>Admin Email Address</b></td>
                                  <td>{showDetails?.adminDetails?.email || '-'}</td>
                                </tr>
                                </> : ''}
                                
                                {showDetails.status === 2 ?
                                <tr>
                                  <td><b>Reason</b></td>
                                  <td>{showDetails?.userDetails?.reason || '-'}</td>
                                </tr>
                                                    
                                : ''}
                                <tr>
                                  <td><b>Status</b></td>
                                  <td>
                                    {showDetails.status === 1
                                      ? 'Approved'
                                      : showDetails.status === 2
                                        ? 'Rejected'
                                        : 'Pending'}
                                  </td>

                                </tr>

                                {showDetails.status === 0 ? 
                                <tr>

                                  <td><b>Uploaded Date & Time</b></td>
                                  <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                                </tr> : ''}

                                {showDetails.status === 1 ? 
                                <tr>

                                  <td><b>Approved Date & Time</b></td>
                                  <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                                </tr> : ''}
                                {showDetails.status === 2 ? 
                                <tr>

                                <td><b>Rejected Date & Time</b></td>
                                <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                                </tr> : '' }
                                <tr>
                                  <td><b>Image</b></td>
                                  <td>
                                    <Card style={{ width: '80%' }}>
                                      <CardBody>
                                        <CardImg

                                          top
                                          src={showDetails.votedImage || '-'}
                                          style={{ width: '100%', objectFit: 'cover' }}
                                        />
                                      </CardBody>
                                    </Card>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Row>

                      </ModalBody>
                      <ModalFooter>
                        {showDetails.status === 0 ? (
                          <div className="hstack gap-2 justify-content-end">
                            {/* <button type="button" className="btn btn-light" onClick={() => {setmodal_update_list(false);}}>
        Close
      </button> */}

                            <button
                              onClick={(e) => handleSubmit(e, 1)}
                              className="btn btn-green text-white waves-effect waves-light"
                              disabled={buttonLoading}
                            >
                              {buttonLoading ? <Loader /> : "Approve"}
                            </button>

                          
                            <button
                              onClick={(e) => statusAlert(e, 2)}
                              className="btn btn-danger waves-effect waves-light"
                              disabled={rejLoad}
                            >
                              {rejLoad ? <Loader /> : "Reject"}
                            </button>
                          </div>
                        ) : (
                          ""
                        )}

                      </ModalFooter>
                    </Form>
                  </Modal>

                  <Modal className="modal-dialog modal px-4"   size="l"   isOpen={showOTPModel} centered backdrop="static"  >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                         Approve Confirmation OTP
                        </h5>
                        <button
                          onClick={() => {
                            setShowOTPModel(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                    
                                    <div>
                                        <div className="text-center">
                                           
                                        </div>
                                        <h4 className="font-size-18 text-muted mt-2 text-center">
                                            Verify Your OTP
                                        </h4>
                                      
                                        <div className="user-thumb text-center m-b-30">
                                            <h4 className="rounded-circle avatar-lg img-thumbnail mx-auto d-flex justify-content-center align-items-center">{countdown === 60 ? ("0") : (countdown)}<span style={{fontSize:'15px',marginTop:'7px'}}>s</span></h4>
                                        </div>
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                        
                                            <Row>
                                                <Col md={12} className="AuthStar">
                                                    <div className="mb-4">
                                                        <Label className="form-label">One Time Password<span>*</span></Label>
                                                        <InputGroup>
                                                            <Input
                                                                style={{ borderColor: '#80838B' }}
                                                                name="otp"
                                                                disabled={loading || countdown === 60 || countdown === 0 }
                                                                className="form-control"
                                                                placeholder="Enter OTP"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.otp || ""}
                                                                invalid={validation.touched.otp && validation.errors.otp ? true : false}
                                                            />
                                                 
                                                            {validation.touched.otp && validation.errors.otp ? (
                                                                <FormFeedback>{validation.errors.otp}</FormFeedback>
                                                            ) : null}
                                                        </InputGroup>
                                                    </div>

                                                    <div className="d-grid mt-4">
                                                  
                                                            <button
                                                                className="btn btn-primary waves-effect waves-light"
                                                                type="submit"
                                                                disabled={appLoad || countdown === 60 || appLoad}
                                                                onClick={validation.handleSubmit}
                                                            >
                                                                      {appLoad ? (
                                                            <>
                                                                <Spinner size="sm">
                                                                    Loading...
                                                                </Spinner>
                                                                <span style={{ color: 'white' }}>
                                                                    {' '}Loading...
                                                                </span>
                                                            </>


                                                        ) : ("Submit")}
                                                                
                                                            </button>

                                                       


                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                             
                      

                           
                         
                      </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PendingVotes;

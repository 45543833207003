import DataTable from "react-data-table-component";
import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form } from "reactstrap";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { utcToNormalTime } from "../../helpers/function";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import Swal from "sweetalert2";
import { AdminContext } from "../../helpers/adminContext";

const UserIPblock = () => {
  const { adminInfo } = useContext(AdminContext);
  document.title = "Ultrapro | User IP Management";
  const [loading, setLoading] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [formData, setFormData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [modal_list, setmodal_list] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchTerm, setSearchTerm] = useState("");
  const [adminType, setAdminType] = useState("");
  const allowedActions = [1, 2, 3, 4];

  function tog_list() {
    setmodal_list(!modal_list);
  }
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    setAdminType(adminInfo?.adminType);
    ipList();
  }, [adminInfo?.adminType]);
  const ipList = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.USER_IP_BLOCK_LIST);
      if (response.status) {
        setFormData(response.data || []);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const ipAddressRegex = /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/;
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      ipAddress: "",
      reason: "",
    },
    validationSchema: Yup.object({
      ipAddress: Yup.string().required("IP Address is required").matches(ipAddressRegex, "Invalid IP Address format"),
      reason: Yup.string().required("Reason is required"),
    }),
    onSubmit: (values) => {
      handlePost(values);
    },
  });

  const handlePost = async (data) => {
    setLoading(true);
    const postForm = {
      IpAddress: data.ipAddress,
      reason: data.reason,
    };
    try {
      const response = await postData(Apiservices.USER_IP_BLOCK_ADD, postForm);
      if (response.status) {
        ipList();
        setLoading(false);
        toast.success(response.message);
        setmodal_list(false);
        validation.resetForm();
      } else {
        setLoading(false);
        toast.error(response.message);
        validation.resetForm();
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      validation.resetForm();
    }
  };

  const handleDelete = (value) => {
    showUserBlockAlert(confirmDelete, value);
  };

  const showUserBlockAlert = (onConfirm, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove this record!`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          await onConfirm(row);
        } catch (error) {
          Swal.fire("Error!", "There was an error deleting the record", "error");
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Additional actions after confirmation, if needed
      }
    });
  };

  const confirmDelete = async (row) => {
    const permissions = [
      {
        module: "User IP Block",
        actions: [2],
      },
    ];
    const postForm = {
      IpAddress: row.IpAddress,
      adminType: adminInfo?.adminType,
      permissions: permissions,
    };
    setDeleteLoad(true);
    try {
      const response = await postData(Apiservices.USER_IP_BLOCK_DELETE, postForm);
      if (response.status) {
        setDeleteLoad(false);
        Swal.fire("Success!", `${response.message}`, "success");
        setmodal_delete(false);
        ipList();
      } else {
        Swal.fire("Error!", `${response.message}`, "error");
        validation.resetForm();
        setmodal_delete(false);
        setDeleteLoad(false);
      }
    } catch (error) {
      setDeleteLoad(false);
      toast.error("Internal Server Error..Try Again Later");
      setmodal_delete(false);
      validation.resetForm();
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDateChange = (selectedDates) => {
    setDateRange(selectedDates);
  };

  const filteredData = formData.filter((item) => {
    const itemDate = new Date(item.createdAt);
    const [startDate, endDate] = dateRange;
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    if (end) {
      end.setHours(23, 59, 59, 999);
    }

    const isValidItemDate = !isNaN(itemDate.getTime());
    const isDateInRange = (!start || (isValidItemDate && itemDate >= start)) && (!end || (isValidItemDate && itemDate <= end));
    const formattedDate = item.createdAt ? utcToNormalTime(item.createdAt).date : "";
    const isIPMatch = item.IpAddress && item.IpAddress.toLowerCase().includes(searchTerm.toLowerCase());
    const isReasonMatch = item.reason && item.reason.toLowerCase().includes(searchTerm.toLowerCase());
    const isAdminEmailMatch = item.blockedAdminEmail && item.blockedAdminEmail.toLowerCase().includes(searchTerm.toLowerCase());
    const isDateMatch = formattedDate && formattedDate.includes(searchTerm);
    const isIDMatch = item._id && item._id.includes(searchTerm);
    const isSearchMatch = isIPMatch || isIDMatch || isReasonMatch || isDateMatch || isAdminEmailMatch;
    return isSearchMatch && isDateInRange; // Return true if both conditions are met
  });

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const validCurrentPage = currentPage >= pageCount ? 0 : currentPage;

  // Slice the filtered data for pagination
  const displayedData = filteredData.slice(validCurrentPage * itemsPerPage, (validCurrentPage + 1) * itemsPerPage);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // handleInputChange();
    }
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => currentPage * itemsPerPage + index + 1,
      sortable: true,
      maxWidth: "2%",
    },

    {
      name: "Blocked Admin",
      selector: (row) => row.blockedAdminEmail,
      sortable: true,
      maxWidth: "50%",
    },
    {
      name: "IP Address",
      selector: (row) => row.IpAddress,
      sortable: true,
      maxWidth: "10%",
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
      sortable: true,
    },
    {
      name: "Date & Time",
      selector: (row) => (row.createdAt ? `${utcToNormalTime(row.createdAt).date} ${utcToNormalTime(row.createdAt).time}` : "-"),
      sortable: true,
    },
    ...(adminType === 1 || adminInfo?.permissions.some((data) => data.module === "All Users" && allowedActions.some((action) => data.actions.includes(action)))
      ? [
          {
            name: "Action",
            cell: (row) => (
              <div className="d-flex gap-2">
                <div className="remove">
                  {/* <button
                            onClick={() => handleDelete(row)}
                            className="btn btn-sm btn-danger remove-item-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteRecordModal"
                        >
                            Remove
                        </button> */}
                  {adminType === 1 || adminInfo?.permissions.some((data) => data.module === "User IP Block" && data.actions.includes(4)) ? (
                    <span onClick={() => handleDelete(row)} className="mdi mdi-trash-can-outline" style={{ fontSize: "15px", cursor: "pointer" }}></span>
                  ) : null}
                </div>
              </div>
            ),
            ignoreRowClick: true,
            // allowOverflow: true,
            button: true,
          },
        ]
      : []),
  ];
  const handleClose = () => {
    setmodal_list(false);
    validation.resetForm();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="User Management" breadcrumbItem="User IP Block" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-4">
                        <form className="app-search d-block">
                          <div className="position-relative">
                            <input type="text" className="form-control" placeholder="Search by Blocked Admin,IP Address or Reason" onChange={handleInputChange} onKeyPress={handleKeyPress} />
                            <span className="ri-search-line"></span>
                          </div>
                        </form>
                      </Col>
                      <Col className="col-sm-4">
                        <form className="d-block mt-3">
                          <div className="position-relative">
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select Start to End Date"
                              options={{
                                mode: "range",
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                                maxDate: new Date(),
                              }}
                              value={dateRange}
                              onChange={handleDateChange}
                            />
                          </div>
                        </form>
                      </Col>

                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          {adminType === 1 || adminInfo?.permissions.some((data) => data.module === "User IP Block" && data.actions.includes(1)) ? (
                            <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn">
                              <i className="ri-add-line align-bottom me-1"></i> Add
                            </Button>
                          ) : null}
                          {/* <Button color="info" className="add-btn mx-2" onClick={handleDownload} id="create-btn">
                          <i className="mdi mdi-download-outline"></i> Download</Button> */}
                          {/* <Button color="soft-danger"><i style={{ fontSize: '15px' }}
                           className="mdi mdi-download-circle-outline"></i>Download</Button> */}
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <DataTable
                        columns={columns}
                        data={displayedData}
                        paginationServer
                        paginationTotalRows={filteredData.length}
                        progressPending={loading}
                        progressComponent={<div className="py-3">Loading...</div>}
                        oDataComponent={<div className="py-3">No records found</div>}
                        fixedHeader
                        persistTableHead={true}
                      />
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        {displayedData.length > 0 ? (
                          <div className="pagination pagination-rounded mb-0">
                            <ReactPaginate
                              previousLabel={<i className="mdi mdi-chevron-left"></i>}
                              nextLabel={<i className="mdi mdi-chevron-right"></i>}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              breakLinkClassName={"break-link"}
                              disabledClassName={"disabled"}
                              initialPage={currentPage}
                              disableInitialCallback={true}
                              forcePage={currentPage}
                              // disableInitialCallback={true}
                              renderLink={(props) => {
                                if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                  return (
                                    <a
                                      {...props}
                                      onClick={() =>
                                        handlePageClick({
                                          selected: pageCount - 1,
                                        })
                                      }
                                    >
                                      {pageCount}
                                    </a>
                                  );
                                }
                                return <a {...props} aria-label="Link Description" />;
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Modal */}
      <Modal isOpen={modal_list} toggle={() => { tog_list();}} centered>
        <ModalHeader className="bg-light p-3" id="exampleModalLabel"
          toggle={() => {  tog_list();}} >{" "} Add IP{" "}
        </ModalHeader>
        <Form
          autoComplete="off"
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">
                IP Address
              </label>
              <Input
                type="text"
                id="customername-field"
                className="form-control"
                placeholder="Enter IP Address"
                name="ipAddress"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.ipAddress || ""}
                invalid={validation.touched.ipAddress && validation.errors.ipAddress}
              />
              {validation.touched.ipAddress && validation.errors.ipAddress && <FormFeedback type="invalid">{validation.errors.ipAddress}</FormFeedback>}
            </div>

            <div className="mb-3">
              <label htmlFor="phone-field" className="form-label">
                Reason
              </label>
              <Input
                type="text"
                id="phone-field"
                name="reason"
                className="form-control"
                placeholder="Enter Reason"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.reason || ""}
                invalid={validation.touched.reason && validation.errors.reason}
              />
              {validation.touched.reason && validation.errors.reason && <FormFeedback type="invalid">{validation.errors.reason}</FormFeedback>}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={handleClose}>
                Close
              </button>
              <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
                {loading ? <Loader /> : "Submit"}
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      {/* Remove Modal */}
      <Modal isOpen={modal_delete} className="modal zoomIn" id="deleteRecordModal" centered>
        <div className="modal-header">
          <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close">
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-3 mb-0">Are you Sure You want to Remove this Record ?</p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>
              Close
            </button>
            <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={confirmDelete} disabled={deleteLoad}>
              {deleteLoad ? <Loader /> : "Yes, Delete It!"}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UserIPblock;

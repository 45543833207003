import React, { useState, useEffect,useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback,CardImg, Col, Container, Modal, ModalBody, 
  ModalFooter, Row, ModalHeader, Form, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import Swal from "sweetalert2";
import { AdminContext } from "../../helpers/adminContext";




const DiscoverList = () => {
  const [loading, setLoading] = useState(false);
  const [getDiscoverList, setDisoverList] = useState([]);
  const [getId, setId] = useState("");
  //const token = Cookies.get('authentication');
  document.title = "Ultrapro | Discover";
  const [previewImage, setPreviewImage] = useState(null);
  const [modal, setModal] = useState(false);

  const [sessionLink, setSessionLink] = useState('');
  const [errors, setErrors] = useState('');

//Permissio List 
const { adminInfo } = useContext(AdminContext);
const [adminType,setAdminType] = useState('')

  //Add Modal
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
    formik.resetForm();
    setImage('')
  }
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
    setImage('')
  }
  const [getImage,setGetImage] = useState('')
  //classList api call
  useEffect(() => {
    fetchData();
    setAdminType(adminInfo?.adminType)
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.DiscoverList);
      if (response.status) {
        setLoading(false);
        setTotalPagination(response.data.totalPages);
        setDisoverList(response.data.data);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Handle fetch error
    }
  };
  const [showDetails, setShowDetails] = useState({})
  const [showModel, setShowModel] = useState(false)
  const [recordToDelete, setRecordToDelete] = useState({});
  const [deleteLoad,setDeleteLoad] = useState(false)

  const handleShowModel = (value) => {
    setShowDetails(value)
    setShowModel(true)
}
const handleDelete = (value) => {
  setRecordToDelete(value);
  showUserBlockAlert(confirmDelete, value)
}

const showUserBlockAlert = (onConfirm, row) => {
  Swal.fire({
      title: 'Are you sure?',
      text: `You want to remove this record!`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
          try {
              await onConfirm(row);
          } catch (error) {
              Swal.fire('Error!', 'There was an error deleting the record', 'error');
          }
      }
  }).then((result) => {
      if (result.isConfirmed) {
          // Additional actions after confirmation, if needed
      }
  });
};

const confirmDelete = async (row) => {
  const postForm = {
    discoverId : row.discoverId
  }
  
  setDeleteLoad(true)
  try {
      const response = await postData(Apiservices.DiscoverDelete,postForm )
      
      if (response.status) {
          setDeleteLoad(false)
          Swal.fire('Success!', `${response.message}`, 'success');
          fetchData()

      } else {
          Swal.fire('Error!', `${response.message}`, 'error');
          setDeleteLoad(false)

      }

  } catch (error) {
      setDeleteLoad(false)
      toast.error("Internal Server Error..Try Again Later")
  }



}
const allowedActions = [1,2,3,4];

const columns = [
  {
    name: "S.No",
    // selector: (row, index) => currentPage * itemsPerPage + index + 1,
    selector: (row) => handleNullValue(row.serialNo),

    sortable: true,
    minWidth: '80px',
    maxWidth: '100px'
  },
  {
    name: "Image",
    selector: (row) => <img style={{ width: "100px" }} alt='' src={row.discoverImage || '-'} />,
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  {
    name: "Discover Title",
    selector: (row) => handleNullValue(row.discoverName),
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  
  
  {
    name: "Position",
    selector: (row) => handleNullValue(row.position),
    // sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
 
  ...(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Discover Management" && data.actions.includes(3)) ? [{
  
    name: "Status",
    selector: (row) => (
      <div className="form-check form-switch form-switch-md mb-3 d-flex justify-content-center align-items-center">
        <input
          type="checkbox"
          className="form-check-input"
          id="customSwitchsizemd"
          checked={row.discoverIsActive === 1}
          onChange={() => handleUpdateStatusClick(row)}
          disabled={loading}
        />
      </div>
    ),
    sortable: true,
    minWidth: '100px',
    maxWidth: '100px'
  }] : []),
  {
    name: "Date & Time",
    selector: (row) => row.createdAt ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${handleNullValue(utcToNormalTime(row.createdAt).time)}` : "---",
    sortable: true,
    minWidth: '200px',
    maxWidth: '250px'
  },
  ...(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Discover Management" && allowedActions.some(action => data.actions.includes(action))) ? [

  {
    name: "Action",
    cell: (row) => (
      <div className="d-flex">
        {(adminType === 1 || adminInfo?.permissions.some(data => 
        data.module === "Discover Management" && data.actions.includes(3))) ? (
        <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-border-color cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>
        ): null }


       {(adminType === 1 || adminInfo?.permissions.some(data => 
        data.module === "Discover Management" && data.actions.includes(2))) ? (
        <i style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline mx-1"></i>
         ): null }

        {(adminType === 1 || adminInfo?.permissions.some(data => 
        data.module === "Discover Management" && data.actions.includes(4))) ? (
        <i onClick={() => handleDelete(row)} className="mdi mdi-trash-can-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></i>
         ): null }
      </div>
    ),
    ignoreRowClick: true,
    minWidth: '150px',
    maxWidth: '200px'
  }] : []),
];
  


  //paginnation
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; 
  const pageCount = Math.ceil(totalPagination);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };
  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: itemsPerPage,
    };
    try {
      const response = await postData(Apiservices.DiscoverList,postForm);
      if (response.status) {
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  //Add form validation
  const formik = useFormik({
    initialValues: {
      discoverTitle: "",
      position:'',
      videolink:'',
      isActive:""
    },
    validationSchema: Yup.object({
      discoverTitle: Yup.string().trim().required("Discover title is required"),
      position: Yup.string().trim()
      .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
      .required("Position is required"),
      videolink: Yup.string()
      .trim()
      .url("Please enter a valid URL")
      .required("Video Link is required"),
      isActive: Yup.string().trim().required("Status is required"),
    }),
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  
//Addapicall
const handleAdd = async (data) => {

  setLoading(true);
if (!image) {
  toast.error("Session image is required");
  setLoading(false);
  return;
}
  // Validate session link before submission
  const formData = new FormData();
  formData.append("discoverName", data.discoverTitle);
  formData.append("discoverVideoLink", data.videolink);
  formData.append('files', image);
  formData.append('discoverId', "");
  formData.append("discoverIsActive", data.isActive);
  formData.append("position", data.position);
  formData.append("adminType",adminInfo?.adminType);
  formData.append("permissions",adminInfo?.permission);
  try {
    const response = await postData(Apiservices.DiscoverAdd, formData);
    
    if (response.status) {
      toast.success(response.message);
      formik.resetForm();
      setmodal_list(false);
      setLoading(false);
      fetchData();
      setImage(null)
      setDisoverList('')
    setErrors('');

     
      formik.setFieldValue("sessionimage", "");
    } else {
      toast.error(response.message);
      formik.resetForm();
      setLoading(false);
      fetchData();
      setImage(null)
      setDisoverList('')
      setErrors('');



    }
  } catch (error) {
    toast.error(error.response.data.message);
    setLoading(false);
    setLoading(false);
    setImage(null)
    setDisoverList('')
    setErrors('');



  }
};

  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,
  
    initialValues: {
      discoverTitle: "",
      position:'',
      videolink:'',
      isActive:""
    },
    validationSchema: Yup.object({
      discoverTitle: Yup.string().trim().required("Discover title is required"),
      position: Yup.string().trim()
      .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
      .required("Position is required"),
      videolink: Yup.string()
      .trim()
      .url("Please enter a valid URL")
      .required("Video Link is required"),
      isActive: Yup.string().trim().required("Status is required"),
      
    }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });
  
  
  const handleUpdate = (record) => {
    setId(record.discoverId);
    setGetImage(record.discoverImage)
    setSessionLink(record.discoverVideoLink)
    validationUpdate.setValues({
      discoverId: getId || "",
      discoverTitle: record.discoverName || "",
      position:record.position || "",
      videolink:record.discoverVideoLink || "",
      isActive: record.discoverIsActive !== undefined ? String(record.discoverIsActive) : "",
      
    });
   
    setmodal_update_list(true);
  };
  
 
  const confirmUpdate = async (values) => {
    setLoading(true);
  
    if (!image && !getImage) {
      toast.error("Image is required");
      setLoading(false);
      return;
    }
   
  
    const updateformData = new FormData();
    updateformData.append("discoverName", values.discoverTitle);
    updateformData.append("discoverVideoLink", values.videolink);
    updateformData.append("discoverIsActive", Number(values.isActive));
    updateformData.append("position", Number(values.position));
    updateformData.append("discoverId", getId);

    
    // Image handling
    if (image) {
      updateformData.append("files", image);
    } else if (getImage) {
      updateformData.append("files", getImage);
    }
  
    try {
      const response = await postData(Apiservices.DiscoverUpdate, updateformData);

  
      if (response.status) {
        setmodal_update_list(false);
        toast.success(response.message);
        fetchData();
      setDisoverList('')

      } else {
        
        toast.error(response.message);
        fetchData();
      setDisoverList('')
    setErrors('');


      }
    } catch (error) {
    toast.error(error.response.message);
    validationUpdate.resetForm();
    setErrors('');

    } finally {
      setLoading(false);
    }
  };
  
  //Searchfun
  const [inputSearch, setinputSearch] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "") {
      fetchData();
      setSearchValid(false);
    } else {
      setinputSearch(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
      };

     
      try {
       
      const response = await postData(Apiservices.DiscoverList,postForm);

        if (response.status) {
          setSearchLoad(false);
          setDisoverList(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);

          // toast(response.message);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };


const showStatusAlert = (onConfirm, row) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You want to update status!",
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Update it!',
    showLoaderOnConfirm: true, 
    preConfirm: async (reason) => {
      if (!reason) {
          Swal.showValidationMessage('Reason is required');
      } else {
          Swal.showLoading();
          try {
              await onConfirm({ ...row, reason });
              Swal.fire('Success!', 'The status has been updated successfully', 'success');
          } catch (error) {
              Swal.fire('Error!', 'There was an error updating the status', 'error');
          }
      }
  },
  allowOutsideClick: () => !Swal.isLoading(), 
 
  }).then((result) => {
    if (result.isConfirmed) {
   
    }
  });
};
const handleUpdateStatusClick = (row) => {
  showStatusAlert(handleToggle1, row);
}

const handleToggle1 = async (rowDetails) => {
  const updatedStatus = rowDetails.discoverIsActive === 1 ? 0 : 1;
  try {
    const statusUpdate = new FormData();

    statusUpdate.append("discoverTitle", rowDetails.discoverName);
    statusUpdate.append("discoverVideoLink", rowDetails.discoverVideoLink);
    statusUpdate.append("discoverIsActive", updatedStatus);
    statusUpdate.append("position", Number(rowDetails.position));
    statusUpdate.append("discoverId", rowDetails.discoverId);
    statusUpdate.append("files", rowDetails.discoverImage)
    const response = await postData(Apiservices.DiscoverUpdate, statusUpdate);

    if (response.status) {
      toast.dismiss();
      toast.success(response.message);
      // Swal.fire("Success !", "The status has been updated successfully", "success");

      fetchData();
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    toast.dismiss();
    toast.error(error.response.message);
  }
};


const toggleModal = () => {
  setModal(!modal);
};

const [rowsPerPage, setRowsPerPage] = useState(10);
const [count, setCount] = useState("");

const handleRowsPerPageChange = (newRowsPerPage) => {
  setRowsPerPage(parseInt(newRowsPerPage));
  HandleClickRowperpage(newRowsPerPage)
}
const HandleClickRowperpage = async(newRowsPerPage) => {
  setLoading(true);
  const postForm = {
    page:1,
    limit:Number(newRowsPerPage) ,
  };

  // if (dateRange[0] && dateRange[1]) {
  //   postForm.startDate = formatDate(dateRange[0]);
  //   postForm.endDate = formatDate(dateRange[1]);
  // }

  try {
    if (getDiscoverList === undefined) {
      const response = await postData(Apiservices.DiscoverList,postForm);
      if (response.status) {
        setLoading(false);
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
      setLoading(false);
      }
    } else {
      const response = await postData(Apiservices.DiscoverList,postForm);
      if (response.status) {
        setLoading(false);
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    }
  } catch (error) {
   
    toast.error("Internal Server Error..Try Again Later");
    setLoading(false);
  }

};

const handleImageChange = (e) => {
  const file = e.target.files[0];
  setImage(file);
 
};


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Discover" breadcrumbItem="Discover List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4">
                    <Col className="col-sm-auto">
                    <div className="input-group">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => handleSearch()}
                            disabled={searchLoad}
                          >
                            {searchLoad ? (<Spinner className="text-light" size="sm" /> ) : (<i className="ri-search-line" /> )}
                          </button>
                        </div>
                      </div>
                   
                    </Col>
                    {/* Add Modal Button */}
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                      {(adminType === 1 || adminInfo?.permissions.some(data => 
                        data.module === "Discover Management" && data.actions.includes(1))) ? (
                        <Button
                          color="success"
                          className="add-btn"
                          onClick={() => tog_list()}
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                        </Button>
    ):null}
                      </div>
                    </Col>
                  </Row>
                 
    <div className="table-responsive  mt-2">
    <DataTable
      columns={columns}
      data={getDiscoverList}
      paginationServer
      paginationTotalRows={getDiscoverList.length}
      progressPending={loading}
      persistTableHead={true}
      progressComponent={<div className="py-3">Loading...</div>}
      noDataComponent={<div className="py-3">No records found</div>}
      fixedHeader={true}
      
    />
    </div>
    {/* {getDiscoverList.length !== 0 ? (
      <div className="d-flex justify-content-end mt-3">
        <div className="pagination pagination-rounded mb-0">
          
          <ReactPaginate
            previousLabel={<i className="mdi mdi-chevron-left"></i>}
            nextLabel={<i className="mdi mdi-chevron-right"></i>}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageLinkClassName={"page-link"}
            breakLinkClassName={"break-link"}
            disabledClassName={"disabled"}
            initialPage={currentPage}
            disableInitialCallback={true}
            forcePage={currentPage}
            // disableInitialCallback={true}
            renderLink={(props) => {
              if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                return (
                  <a {...props} onClick={() => handlePageClick({ selected: pageCount - 1 })}>
                    {pageCount}
                  </a> 
                  );
              } return <a {...props} />;
            }}/>
        </div>
      </div>
    ) : null} */}

<div className="container">
  <div className="row justify-content-end mt-4">
  <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
      <div className="d-flex align-items-center me-3">
        <span className="me-2">Rows per page:</span>
        <select
          value={rowsPerPage}
          onChange={(e) => handleRowsPerPageChange(e.target.value)}
          className="form-control-sm"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>
      <div className="me-md-3 mb-3 mb-md-0">
        <span>{count}</span>
      </div>
      <div className="pagination pagination-rounded mt-3">
        <ReactPaginate
          previousLabel={<i className="mdi mdi-chevron-left"></i>}
          nextLabel={<i className="mdi mdi-chevron-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageLinkClassName={"page-link"}
          breakLinkClassName={"break-link"}
          disabledClassName={"disabled"}
          initialPage={currentPage}
          disableInitialCallback={true}
          forcePage={currentPage}
          renderLink={(props) => {
            if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
              return (
                <a
                  {...props}
                  onClick={() =>
                    handlePageClick({
                      selected: pageCount - 1,
                    })
                  }
                >
                  {pageCount}
                </a>
              );
            }
            return <a {...props} />;
          }}
        />
      </div>
    </div>
  </div>
</div>
  

  {/* // ADDMODAL  */}
  <Modal className="modal-dialog modal-lg" isOpen={modal_list}  toggle={() => { tog_list(); }}centered>
  <ModalHeader  className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list();}}> Add Discover</ModalHeader>
  <Form autoComplete="off" className="form-horizontal" 
  onSubmit={(e) => { e.preventDefault();   formik.handleSubmit();  return false; }}>
  <ModalBody>
  <Row>
    <Col md={6}>
  

        <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
           Discover Title
        </label>

        <Input
          type="text"
          id="discoverTitle-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Discover Title"
          name="discoverTitle"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.discoverTitle || ""}
          invalid={formik.touched.discoverTitle && formik.errors.discoverTitle}
        />
        {formik.touched.discoverTitle && formik.errors.discoverTitle && <FormFeedback type="invalid">{formik.errors.discoverTitle}</FormFeedback>}
      </div>

      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Position
        </label>
        <Input
          type="number"
           min="1"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Position"
          name="position"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.position || ""}
          invalid={formik.touched.position && formik.errors.position}
        />
        {formik.touched.position && formik.errors.position && <FormFeedback type="invalid">{formik.errors.position}</FormFeedback>}
      </div> 


      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Discover Image 
        </label>
        <div> 
            <input type="file" className="form-control"
                  accept=".png, .jpg, .jpeg"
                 onChange={handleImageChange} />
                </div>
                {error && <div className="text-danger" style={{ fontSize: '12px' }}>{error}</div>}

                {image ? 
                    <Card style={{ width: '200px'}}>
            <CardBody>
              {/* <CardTitle tag="h6">Image Preview</CardTitle> */}
              <CardImg
                top
                src={image ? URL.createObjectURL(image) : ''}
                style={{ width: '100%', objectFit: 'cover' }}
              />
            </CardBody>
          </Card> : null}
      </div>


 
    
      
    </Col>
    <Col md={6}>
    

      



      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Video Link
        </label>
        <Input
          type="text"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Video Link"
          name="videolink"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.videolink || ""}
          invalid={formik.touched.videolink && formik.errors.videolink}
        />
        {formik.touched.videolink && formik.errors.videolink && <FormFeedback type="invalid">{formik.errors.videolink}</FormFeedback>}
      </div>
     

      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
          Status
        </label>
        <select
          id="status-field"
          //  className="form-control"
          name="isActive"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.isActive || ""}
          className={`form-control ${formik.touched.isActive && formik.errors.isActive ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Status
          </option>
          <option value={1}>Active</option>
          <option value={0}>Deactive</option>
        </select>
        {formik.touched.isActive && formik.errors.isActive && <FormFeedback type="invalid"> {formik.errors.isActive} </FormFeedback>}
      </div>
    </Col>
  </Row>
</ModalBody>

    <ModalFooter>
      <div className="hstack gap-2 justify-content-end">
        <button type="button" className="btn btn-light" onClick={() => {setmodal_list(false); formik.resetForm();setImage('')}}>
          Close
        </button>

        <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
          {loading ? <Loader /> : "Submit"}
        </button>
      </div>
    </ModalFooter>
  </Form>
</Modal>


     {/* UpdateModal */}
  <Modal className="modal-dialog modal-lg" isOpen={modal_update_list}  toggle={() => { tog_update_list();}} centered>
  <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>
    Update Discover
  </ModalHeader>
  <Form autoComplete="off" className="form-horizontal"
    onSubmit={(e) => {
      e.preventDefault();
      validationUpdate.handleSubmit();
      return false;
    }}
  >
    <ModalBody>
      <Row>
        <Col md={6}>
      
        <div className="mb-3">
        <label htmlFor="title-field" className="form-label">Discover Title</label>
        <Input
          type="text"
          id="discoverTitle-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Discover Title"
          name="discoverTitle"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.discoverTitle || ""}
          invalid={validationUpdate.touched.discoverTitle && validationUpdate.errors.discoverTitle}
        />
        {validationUpdate.touched.discoverTitle && validationUpdate.errors.discoverTitle && <FormFeedback type="invalid">{validationUpdate.errors.discoverTitle}</FormFeedback>}
      </div>
     
      <div className="mb-3">
      <label htmlFor="title-field" className="form-label">
         Image 
        </label>
        <div>
        <input
          type="file"
          className="form-control"
          accept=".png, .jpg, .jpeg"
          onChange={handleImageChange}
          // Set value attribute to show filename or keep it blank if no file is selected
          // value={getImage ? getImage : ''}
          placeholder="No file chosen"
        />
      </div>
      {error && <div className="text-danger" style={{ fontSize: '15px' }}>{error}</div>}

      {/* Image Preview Card */}
      {(getImage || image) && (
        <Card style={{ width: '200px' }}>
          <CardBody>
            <CardImg
              top
              src={image ? URL.createObjectURL(image) : getImage}
              style={{ width: '100%',  objectFit: 'cover' }}
            />
          </CardBody>
        </Card>
      )}
       
      </div>
    
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Image Preview</ModalHeader>
        <ModalBody>{previewImage && <img src={previewImage} alt="Preview" style={{ width: "100%", height: "auto" }} />}</ModalBody>
      </Modal>
     
        </Col>
        <Col md={6}>
      
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Position
        </label>
        <Input
          type="number"
          id="subject-field"
          className="form-control"
          autoComplete="off"
           min="1"
          placeholder="Enter Position"
          name="position"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.position || ""}
          invalid={validationUpdate.touched.position && validationUpdate.errors.position}
        />
        {validationUpdate.touched.position && validationUpdate.errors.position && <FormFeedback type="invalid">{validationUpdate.errors.position}</FormFeedback>}
      </div>
    
    
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Video Link
        </label>
        <Input
          type="text"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Video Link"
          name="videolink"
         
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.videolink || ""}
          invalid={validationUpdate.touched.videolink && validationUpdate.errors.videolink}
        />
        {validationUpdate.touched.videolink && validationUpdate.errors.videolink && <FormFeedback type="invalid">{validationUpdate.errors.videolink}</FormFeedback>}
        
      </div>
      <div className="mb-3">
        <label htmlFor="status-field" className="form-label"> Status </label>
        <select
          id="status-field"
          //  className="form-control"
          name="isActive"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.isActive || ""}
          className={`form-control ${validationUpdate.touched.isActive && validationUpdate.errors.isActive ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Status
          </option>
          <option value={1}>Active</option>
          <option value={0}>Deactive</option>
        </select>
        {validationUpdate.touched.isActive && validationUpdate.errors.isActive && <FormFeedback type="invalid"> {validationUpdate.errors.isActive} </FormFeedback>}
      </div>
        </Col>
      </Row>
  
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-light" onClick={() => {setmodal_update_list(false);setImage('')}}>
              Close
            </button>

            <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
              {loading ? <Loader /> : "Submit"}
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>



{/* View Modal    */}
<Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static" >

<div className="modal-header">
    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
       Discover Details 
    </h5>
    <button
        onClick={() => { setShowModel(false); }}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div className="modal-body">

    <div className="table-responsive">
        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td><b>Discover Title</b></td>
                    <td>{showDetails.discoverName || '-'}</td>
                </tr>
                <tr>
                    <td><b>Discover ID</b></td>
                    <td>{showDetails.discoverId || '-'}</td>
                </tr>
                <tr>
                    <td><b>Video Link</b></td>
                    <td>{showDetails.discoverVideoLink || '-'}</td>
                </tr>
                <tr>
                    <td><b>Position</b></td>
                    <td>{showDetails.position || '-'}</td>
                </tr>
            
                <tr>
                    <td><b>Status</b></td>
                    <td>{showDetails.discoverIsActive == 1 ? 'Active' : 'Deactive' || '-'}</td>
                </tr>
                
                <tr>

                    <td><b>Date & Time</b></td>
                    <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                </tr>
                <tr>
                    <td><b>Image</b></td>
                    <td>
                      <Card style={{ width: '200px'}}>
                      <CardBody>
                        <CardImg
                        top
                          src={showDetails.discoverImage || '-'}
                          style={{ width: '100%', objectFit: 'cover' }}
                        />
                      </CardBody>
                    </Card>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>

</Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DiscoverList;

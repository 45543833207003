import React, { useState, useEffect } from "react";
import {  Tooltip,Modal, } from "reactstrap";
import DataTable from 'react-data-table-component';
import {  handleNullValue, utcToNormalTime } from "../../../helpers/function";



const UserBankView = ({bankData}) => {
    document.title = "Ultrapro | User Management";
    const [loading, setLoading] = useState(false);
    const [showModel, setShowModel] = useState(false)
    const [showDetails, setShowDetails] = useState({})
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1);
    const flattenData = Array.isArray(bankData) && Array.isArray(bankData[0]) ? bankData[0] : bankData;

    const [formData, setFormData] = useState(Array.isArray(flattenData) ? flattenData : [flattenData]);

    useEffect(() => {
        const flattenData = Array.isArray(bankData) && Array.isArray(bankData[0]) ? bankData[0] : bankData;
        setFormData(Array.isArray(flattenData) ? flattenData : [flattenData]);
    }, [bankData]);
    

    const handleShowModel = (value) => {
        setShowDetails(value)
        setShowModel(true)
    }


    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
            maxWidth: '0%'
        },
        {
            name: 'Date',
            selector: row => row.createdAt
                ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${(utcToNormalTime(row.createdAt).time)}`
                : "---",
            sortable: true,
            grow: 7
        },
    
        {
            name: 'Holder Name',
            selector: row => row.accountHolderName,
            sortable: true,
            grow: 6
        },
        {
            name: 'Account No',
            selector: row => handleNullValue(row.accountNumber),
            sortable: true,
            grow: 6

        },
        {
            name: 'IFSC No',
            selector: row => handleNullValue(row.ifscCode),
            sortable: true,
            grow: 5


        },

        {
            name: 'Bank Name',
            selector: row => handleNullValue(row.bankName),
            sortable: true,
            grow: 7
        },


        {
            name: 'Action',
            cell: row => (
                <div className="edit d-flex">
                    <span id={`tooltipEmail${row.id}`} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></span>
                    <Tooltip placement="top" isOpen={tooltipOpen1} target={`tooltipEmail${row.id}`} toggle={toggleTooltip1}> View </Tooltip>


                </div>
            ),
            ignoreRowClick: true,

        },



    ];

    return (
        <React.Fragment>
            <div className="table-responsive">
                <DataTable
                    columns={columns}
                    data={formData}
                    paginationServer
                    paginationTotalRows={formData.length}
                    progressPending={loading}
                    persistTableHead={true}
                    progressComponent={<div className="py-3">Loading...</div>}
                    noDataComponent={<div className="py-3">No records found</div>}
                    fixedHeader={true}

                />
            </div>


            <Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static" >

                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
                        User Bank Account View
                    </h5>
                    <button
                        onClick={() => { setShowModel(false); }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>


                <div className="modal-body">

                    <div className="table-responsive">
                        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                            <tbody>
                               
                                <tr>
                                    <td><b>Holder Name</b></td>
                                    <td>{showDetails.accountHolderName || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Account Number</b></td>
                                    <td>{showDetails.accountNumber || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Bank Name</b></td>
                                    <td>{showDetails.bankName || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>IFSC Code</b></td>
                                    <td>{showDetails.ifscCode || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Account Type</b></td>
                                    <td>{showDetails.accountType || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Status</b></td>
                                    <td>{showDetails.status === 1 ? 'Verified' : '-'|| '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Branch Name</b></td>
                                    <td>{showDetails.branchName || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Approve By</b></td>
                                    <td>{showDetails.adminEmail}({showDetails.adminName || '-'})</td>
                                </tr>

                                <tr>

                                    <td><b>Date & Time</b></td>
                                    <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time}</td>
                                </tr>
                             
                            </tbody>
                        </table>
                    </div>

                </div>

            </Modal>

        </React.Fragment>
    );
};

export default UserBankView;

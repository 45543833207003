import React, { useState, useEffect, useContext } from "react";
import { Input, Card, CardBody, Col, Tooltip, Container, Row, Spinner, DropdownMenu, DropdownItem, DropdownToggle, InputGroup, Dropdown, Modal, } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../../ApiService/AxiosInstance";
import { Apiservices } from "../../../ApiService/ApiServices";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loader from "../../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { AddressCell, AddressCellFull, handleNullValue, percentCalculate, utcToNormalTime } from "../../../helpers/function";
import { AdminContext } from "../../../helpers/adminContext";
import { Link } from "react-router-dom";

const CryptoDepositComplete = () => {
    document.title = "Ultrapro | Deposit Management";
    const today = new Date();
    // const kyc = location.state?.kyc

    const [dateRange, setDateRange] = useState([null, null]);
    const [loading, setLoading] = useState(false);
    const [downLoading, setDownLoading] = useState(false);
    const [downloadStartDate, setDownloadEndDate] = useState({});
    const [showModel, setShowModel] = useState(false)
    const [showDetails, setShowDetails] = useState({})
    const [tooltipOpen1, setTooltipOpen1] = useState(false);

    const [searchLoad, setSearchLoad] = useState(false);
    const [btnauto3, setbtnauto3] = useState(false);
    const [searchValid, setSearchValid] = useState(false);

    const [inputSearch, setinputSearch] = useState('');
    const [formData, setFormData] = useState([]);
    const [totalPagination, setTotalPagination] = useState(1);
    const [totalLimit, setTotalLimit] = useState(1);


    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = totalLimit; // Number of items to display per page
    const pageCount = Math.ceil(totalPagination);
    const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1);

    //Permission List
    const { adminInfo } = useContext(AdminContext);
    const [adminType, setAdminType] = useState('');

    useEffect(() => {
        ipList();
        setAdminType(adminInfo?.adminType);
    }, []);







    const ipList = async () => {
        setLoading(true);

        try {
            const response = await postData(Apiservices.WITHDRAW_CRYPTO_COMPLETED_HISTORY,{});
            if (response.status) {
                setLoading(false);


                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
            } else {
                toast.error(response.message);
                setLoading(false);

            }
        } catch (error) {
            toast.error("Internal Server Error...");
            setLoading(false);


        }
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        handlePagePost(selected);
    };

    const handlePagePost = async (selected) => {
        setLoading(true);
        const postForm = {
            page: selected + 1,
        };

        if (dateRange[0] && dateRange[1]) {


            postForm.startDate = formatDate(dateRange[0]);
            postForm.endDate = formatDate(dateRange[1]);
        }
        if (inputSearch) {


            postForm.search = inputSearch;

        }

        const queryString = new URLSearchParams(postForm).toString();
        try {

            const response = await postData(`${Apiservices.WITHDRAW_CRYPTO_COMPLETED_HISTORY}?${queryString}`);

            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
            } else {
                setLoading(false);
                toast.error(response.message);
            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };




    const handleShowModel = (value) => {
        setShowDetails(value)
        setShowModel(true)
    }




    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true,
            grow: 1
        },
        {
            name: 'Date',
            selector: row => row.createdAt
                ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${(utcToNormalTime(row.createdAt).time)}`
                : "---",
            sortable: true,
            grow: 6
        },
        {
            name: 'Name',
            selector: row => handleNullValue(row.userDetails.fullName),
            sortable: true,
            grow: 5
        },

        {
            name: 'Email',
            selector: row =><div className="d-flex align-items-center">
            <Link className="link-tag" to={`/allUsers/userDetails/${row.userId}`}  target="_blank">
              <span className="mx-1 text-decoration-underline">
                {row.userDetails?.email || '-'}
              </span>
            </Link>
          </div>,
            sortable: true,
            grow: 7
        },
        {
            name: 'Currency Name',
            selector: row => handleNullValue(row.currency),
            sortable: true,

            grow: 4
        },
        {
            name: 'Amount',
            selector: row => handleNullValue(row.amount.toFixed(8)),
            sortable: true,
            grow: 3

        },
        {
            name: 'Address',
            selector: row => <AddressCell address={row.withdrawAddress} />,
            sortable: true,
            grow: 4

        },

        // {
        //     name: 'Fee',
        //     selector: row => handleNullValue(row.feeAmount.toFixed(8)),
        //     sortable: true,
        //     grow: 3


        // },
        {
            name: 'Receive',
            selector: row => handleNullValue(row.receiveAmount.toFixed(8)),
            sortable: true,
            grow: 4
        },

        {
            name: 'Transaction ID',
            selector: row => <AddressCell address={row.transactionId} />,
            sortable: true,
            grow: 4
        },

        // {
        //     name: 'TDS',
        //     selector: row => handleNullValue(row.tds.toFixed(8)),
        //     sortable: true,
        //     grow: 3
        // },
        // {
        //     name: 'TDS Percentage',
        //     selector: row => `${handleNullValue((row.tdsPercentage).toFixed(2))}%`,
        //     sortable: true,
        //     grow:2

        // },




        {
            name: 'Action',
            cell: row => (
                <div className="edit d-flex">
                     {(adminType === 1 || adminInfo.permissions.some(data => 
    data.module === "Crypto Deposit History" && data.actions.includes(2))) ? (
                    <span id={`tooltipEmail${row.id}`} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></span>
    ): null}
                    <Tooltip placement="top" isOpen={tooltipOpen1} target={`tooltipEmail${row.id}`} toggle={toggleTooltip1}> View </Tooltip>


                </div>
            ),
            ignoreRowClick: true,
            // allowOverflow: true,
            // button: true,
        },






    ];







    const handleSearchChanage = (e) => {
        const value = e.target.value
        if (value == '') {
            ipList()
            setSearchValid(false)
        }
        setinputSearch(value)

    }


    const handleSearch = async () => {
        if (inputSearch !== '') {


            setSearchLoad(true);
            const postForm = {
                search: inputSearch,
                // page: currentPage + 1,
                // limit: itemsPerPage,
            };

            const queryString = new URLSearchParams(postForm).toString();
            try {

                const response = await postData(`${Apiservices.WITHDRAW_CRYPTO_COMPLETED_HISTORY}?${queryString}`,);
                if (response.status) {
                    setSearchLoad(false);
                    setFormData(response.data.data);
                    setTotalPagination(response.data.totalPages);


                } else {
                    setSearchLoad(false);
                }


            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
                setSearchLoad(false);
            }
        } else {
            setSearchValid(true)
        }
    }

    const [resend, setResend] = useState({
        api: '',
        row: {},
        userStatus: null
    });





    const handleTogglePro = (row, api) => {
        setResend({ api: api, row: row });
        setPromodal(true);
    };





    const [modal_pro_toggle, setPromodal] = useState(false);














    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };



    const handleDateChange = ([start, end]) => {


        const formattedStart = start ? formatDate(start) : null;
        const formattedEnd = end ? formatDate(end) : null;

        setDateRange([start, end]);

        if (!formattedStart && !formattedEnd) {
            ipList();
            setDownloadEndDate({})

        }


        if (formattedStart && formattedEnd) {
            setDownloadEndDate({ startDate: formattedStart, endDate: formattedEnd })
            handleDate({ startDate: formattedStart, endDate: formattedEnd });
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    const handleDate = async ({ startDate, endDate }) => {

        setLoading(true);


        const postForm = {
            startDate: startDate,
            endDate: endDate
        }
        try {
            const queryString = new URLSearchParams(postForm).toString();

            const response = await postData(`${Apiservices.WITHDRAW_CRYPTO_COMPLETED_HISTORY}?${queryString}`);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)

            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };














    const handleDownload = async (type) => {

        setDownLoading(true)
        try {
            const postForm = {};


            if (downloadStartDate.startDate) {
                postForm.startDate = downloadStartDate.startDate;
            }
            if (downloadStartDate.endDate) {
                postForm.endDate = downloadStartDate.endDate;
            }
            if (inputSearch) {
                postForm.search = inputSearch;
            }
            postForm.download = type;
            const queryString = new URLSearchParams(postForm).toString();

            const response = await axiosInstance.post(`${Apiservices.WITHDRAW_CRYPTO_COMPLETED_HISTORY}?${queryString}`, {
                // Include any data you need to send with the POST request here
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: 'blob'
            });
            if (response.data) {
                setDownLoading(false)
                const blob = response.data;

                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                // link.setAttribute('download', `${user}.xlsx`);
                link.setAttribute('download', `CryptoCompletedUserHistory.${type}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                toast.error(response.data.message)
                setDownLoading(false)

            }
        } catch (err) {
            setDownLoading(false)
            console.error('Error downloading the file', err);
        }
    };


   








    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Crypto Deposit" breadcrumbItem="Completed History" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col sm={4} lg={3}>
                                            <div className="input-group">
                                                <Input
                                                    // onChange={handleInputChange}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name,Email,Address,ID ..."
                                                    aria-label="Recipient's username"
                                                    onChange={handleSearchChanage}
                                                    invalid={searchValid}
                                                    onKeyPress={handleKeyPress}
                                                />
                                                <div className="input-group-append">

                                                    <button className="btn btn-primary" type="submit"
                                                        onClick={() => handleSearch()} disabled={searchLoad}
                                                    >
                                                        {searchLoad ? (

                                                            <Spinner className="text-light" size="sm" />
                                                        ) : (

                                                            <i className="ri-search-line" />
                                                        )}

                                                    </button>


                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={4} lg={3}>
                                            <div className="form-group mb-4">

                                                <div>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="Select Start to End Date"
                                                            options={{
                                                                mode: 'range',
                                                                altInput: true,
                                                                altFormat: "F j, Y",
                                                                dateFormat: "Y-m-d",
                                                                maxDate: today,

                                                            }}
                                                            value={dateRange}
                                                            onChange={handleDateChange}
                                                        />
                                                    </InputGroup>
                                                </div>

                                            </div>
                                        </Col>

                                        <Col className="col-sm">

                                            <div className="d-flex justify-content-sm-end">
                                                <Dropdown
                                                    isOpen={btnauto3}
                                                    toggle={() => setbtnauto3(!btnauto3)}
                                                    id="dropdownMenuClickableInside"
                                                    disabled={downLoading}
                                                >
                                                    <DropdownToggle tag="button" className="btn btn-info">
                                                        {downLoading ? <Loader /> : "Download"}

                                                        <i className="mdi mdi-chevron-down" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start">
                                                        <DropdownItem header>Select</DropdownItem>
                                                        <DropdownItem onClick={() => handleDownload('xlsx')}>Excel</DropdownItem>
                                                        <DropdownItem onClick={() => handleDownload('csv')}>CSV</DropdownItem>
                                                        <DropdownItem onClick={() => handleDownload('pdf')}>PDF</DropdownItem>


                                                    </DropdownMenu>
                                                </Dropdown>
                                                {/* <Button color="info" className="add-btn mx-2" onClick={handleDownload} id="create-btn">
                                                    <i className="mdi mdi-download-outline"></i>
            
                                                    {downLoading ? (
                                                        <Loader />
                                                    ) : ("Download")}
                                                </Button> */}


                                            </div>
                                        </Col>
                                    </Row>


                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={formData}
                                            paginationServer
                                            paginationTotalRows={formData.length}
                                            progressPending={loading}
                                            persistTableHead={true}
                                            progressComponent={<div className="py-3">Loading...</div>}
                                            // noDataComponent={formData.length===0}
                                            noDataComponent={<div className="py-3">No records found</div>}
                                            fixedHeader={true}

                                        />
                                    </div>
                                    {loading || formData.length == 0 ? (
                                        ""

                                    ) : (

                                        <div className="d-flex justify-content-end mt-3">
                                            <div className="pagination pagination-rounded mb-0">
                                                <ReactPaginate
                                                    previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                    nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    activeClassName={"active"}
                                                    pageLinkClassName={"page-link"}
                                                    breakLinkClassName={"break-link"}
                                                    disabledClassName={"disabled"}
                                                    initialPage={currentPage}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                    // disableInitialCallback={true}
                                                    renderLink={(props) => {
                                                        if (
                                                            props.page === "..." &&
                                                            props.pageCount > props.pageRangeDisplayed
                                                        ) {
                                                            return (
                                                                <a
                                                                    {...props}
                                                                    onClick={() =>
                                                                        handlePageClick({
                                                                            selected: pageCount - 1,
                                                                        })
                                                                    }
                                                                >
                                                                    {pageCount}
                                                                </a>
                                                            );
                                                        }
                                                        return <a {...props} />;
                                                    }}
                                                />
                                            </div>


                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>










            <Modal size="l" className='modal-dialog modal-lg' isOpen={showModel} centered backdrop="static" >

                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
                        Deposit View
                    </h5>
                    <button
                        onClick={() => { setShowModel(false); }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>


                <div className="modal-body custom-modal-body">

                    <div className="table-responsive">
                        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td><b>Email</b></td>
                                    <td>{showDetails.userDetails?.email || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><b>Name</b></td>
                                    <td>{showDetails.userDetails?.fullName || 'N/A'}</td>
                                </tr>

                                <tr>

                                    <td><b>Deposit Address</b></td>
                                    <td><AddressCellFull address={showDetails.withdrawAddress} /></td>

                                </tr>
                                <tr>

                                    <td><b>Currency Name</b></td>
                                    <td>{showDetails.currency}</td>

                                </tr>
                                <tr>

                                    <td><b>Transaction ID</b></td>
                                    <td><AddressCellFull address={showDetails.transactionId} /></td>

                                </tr>
                                <tr>
                                    <td><b>Amount</b></td>
                                    <td>{showDetails.amount?.toFixed(8) || 'N/A'}</td>
                                </tr>
                                <tr>

                                    <td>  <b>
                                        Fee Amount{" "}
                                        {showDetails.feeType?.feeType === 1
                                            ? `(${percentCalculate(showDetails.amount, showDetails.feeAmount)}%)`
                                            : ""}
                                    </b></td>
                                    <td>{showDetails.feeAmount ? showDetails.feeAmount.toFixed(2) : 'N/A'}</td>

                                </tr>
                                {/* <tr>
                                    <td><b>TDS ({showDetails.tdsPercentage ? showDetails.tdsPercentage : 'N/A'}%)</b></td>
                                    <td>{showDetails.tds?.toFixed(8) || '0'}</td>
                                </tr> */}
                                {/* <tr>
                                    <td><b>TDS Percentage</b></td>
                                    <td>{showDetails.tdsPercentage?.toFixed(2) || 'N/A'}%</td>
                                </tr> */}

                                <tr>
                                    <td><b>Receive Amount</b></td>
                                    <td>{showDetails.receiveAmount?.toFixed(8) || 'N/A'}<span>({showDetails.currency})</span></td>
                                </tr>
                                <tr>
                                    <td><b>Approve By</b></td>
                                    <td>{showDetails.adminEmail}({showDetails.adminName || 'N/A'})</td>
                                </tr>

                                <tr>

                                    <td><b>Request Date & Time</b></td>
                                    <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time}</td>
                                </tr>
                                <tr>
                                    <td><b>Approve Date & Time</b></td>
                                    <td>{utcToNormalTime(showDetails.updatedAt).date} {utcToNormalTime(showDetails.updatedAt).time}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </Modal>













        </React.Fragment>
    );
};

export default CryptoDepositComplete;

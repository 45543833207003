import React, { useState, useEffect } from "react";
import {  Input, Card, CardBody,CardImg, Col, Container, Modal, Row, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import { useParams } from "react-router-dom";


const TradeNotes = () => {
  document.title = "Ultrapro | Trade Notes";
  const {streamPair} = useParams();
  const [loading, setLoading] = useState(false);
  const [getDiscoverList, setDisoverList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState("");
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit;
  const pageCount = Math.ceil(totalPagination);

  useEffect(()=>{
    fetchData()
  },[])
  const fetchData = async () => {
    try {
      const postForm={
        streamPair:streamPair
      }
      setLoading(true);
      const response = await postData(Apiservices.TRADE_NOTES,postForm);
      if (response.status) {
        setLoading(false);
        setTotalPagination(response.data.totalPages);
        setDisoverList(response.data?.data);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Handle fetch error
    }
  };
  const [showDetails, setShowDetails] = useState({})
  const [showModel, setShowModel] = useState(false)


const columns = [
  {
    name: "S.No",
    // selector: (row, index) => currentPage * itemsPerPage + index + 1,
   selector: (row, index) =>handleNullValue(row.serialNo)  ,

    sortable: true,
    minWidth: '80px',
    maxWidth: '100px'
  },
 
  {
    name: "Admin Name",
    selector: (row) => handleNullValue(row.trade?.adminName  || '-'),
    sortable: true, 
    minWidth: '150px',

  },
  
  
  {
    name: "Admin Email",
    selector: (row) => handleNullValue(row.trade?.adminEmail || '-'),
    minWidth: '300px',

  },
  {
    name: "Reason",
    selector: (row) => handleNullValue(row.trade?.reason || '-'),
    minWidth: '250px',
    
  },
  {
    name: "Status",
    selector: (row) => handleNullValue(row.trade?.status  || '-'),
    minWidth: '250px',
    
  },
  
  {
    name: "Updated Date & Time",
    selector: (row) => row.trade?.updateTime ? `${handleNullValue(utcToNormalTime(row.trade?.updateTime).date)} ${handleNullValue(utcToNormalTime(row.trade?.updateTime).time)}` : "---",
    sortable: true,
    ignoreRowClick: true,
    minWidth: '200px',
    maxWidth: '250px'
  },
  
];
  


  

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };
  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: itemsPerPage,
      streamPair:streamPair
    };
    try {
      const response = await postData(Apiservices.TRADE_NOTES,postForm);
      if (response.status) {
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };


  //Searchfun
  const [inputSearch, setinputSearch] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "") {
      fetchData();
      setSearchValid(false);
    } else {
      setinputSearch(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
        streamPair:streamPair
      };

      try {
      const response = await postData(Apiservices.TRADE_NOTES,postForm);

        if (response.status) {
          setSearchLoad(false);
          setDisoverList(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };



const handleRowsPerPageChange = (newRowsPerPage) => {
  setRowsPerPage(parseInt(newRowsPerPage));
  HandleClickRowperpage(newRowsPerPage)
}
const HandleClickRowperpage = async(newRowsPerPage) => {
  setLoading(true);
  const postForm = {
    page:1,
    limit:Number(newRowsPerPage) ,
    
  };
  try {
    if (getDiscoverList === undefined) {
      const response = await postData(Apiservices.TRADE_NOTES,postForm);

      if (response.status) {
        setLoading(false);
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } else {
      const response = await postData(Apiservices.TRADE_NOTES,postForm);
      if (response.status) {
        setLoading(false);
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    }
  } catch (error) {
   
    toast.error("Internal Server Error..Try Again Later");
    setLoading(false);
  }

};


// const [btnauto3, setbtnauto3] = useState(false);
// const [selectedItem, setSelectedItem] = useState("Select Type");
// const [FileName, setFileName] = useState("OverAllVotes");

// const handleFilterType = async (item) => {
//   setLoading(true);
//   const postForm = {
//    uploadType:item
//   };
//   try {
//     // const response = await postData(`${Apiservices.TRADE_NOTES}?${queryString}`);
//     const response = await postData(Apiservices.TRADE_NOTES,postForm);

//     if (response.status) {
//       setDisoverList(response.data.data);
//       setTotalPagination(response.data.totalPages);
//       setTotalLimit(response.data.limit);
//       setCount(response.data.currentCount);

//       setLoading(false);
//     } else {
//       setLoading(false);
//     }
//   } catch (err) {
//     setLoading(false);
//   }
// };
// const handleDropdownClick = (item) => {
//   const postForm = {
//     uploadType:item
//    };
//    const queryString = new URLSearchParams(postForm).toString()
//   setSelectedItem(item);
//   switch (item) {
//     case "image":
//       handleFilterType(item);
//       break;

//     case "video":
//       handleFilterType(item);
//       break;

  
   
//     default:
//       break;
//   }
// };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Trade Notes" breadcrumbItem="Trade Notes" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4">
                    <Col className="col-sm-auto">
                    <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => handleSearch()}
                            disabled={searchLoad}
                          >
                            {searchLoad ? (<Spinner className="text-light" size="sm" /> ) : (<i className="ri-search-line" /> )}
                          </button>
                        </div>
                      </div>
                   
                    </Col>
                
                  </Row>
                 
    <div className="table-responsive  mt-2">
    <DataTable
      columns={columns}
      data={getDiscoverList}
      paginationServer
      paginationTotalRows={getDiscoverList.length}
      progressPending={loading}
      persistTableHead={true}
      progressComponent={<div className="py-3">Loading...</div>}
      // noDataComponent={formData.length===0}
      noDataComponent={<div className="py-3">No records found</div>}
      fixedHeader={true}
      
    />
    </div>
   

<div className="container">
  <div className="row justify-content-end mt-4">
  <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
      <div className="d-flex align-items-center me-3">
        <span className="me-2">Rows per page:</span>
        <select
          value={rowsPerPage}
          onChange={(e) => handleRowsPerPageChange(e.target.value)}
          className="form-control-sm"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>
      <div className="me-md-3 mb-3 mb-md-0">
        <span>{count}</span>
      </div>
      <div className="pagination pagination-rounded mt-3">
        <ReactPaginate
          previousLabel={<i className="mdi mdi-chevron-left"></i>}
          nextLabel={<i className="mdi mdi-chevron-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageLinkClassName={"page-link"}
          breakLinkClassName={"break-link"}
          disabledClassName={"disabled"}
          initialPage={currentPage}
          disableInitialCallback={true}
          forcePage={currentPage}
          renderLink={(props) => {
            if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
              return (
                <a
                  {...props}
                  onClick={() =>
                    handlePageClick({
                      selected: pageCount - 1,
                    })
                  }
                >
                  {pageCount}
                </a>
              );
            }
            return <a {...props} aria-label="Link Description" />;
          }}
        />
      </div>
    </div>
  </div>
</div>
  

 
{/* View Modal    */}
<Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static" >

<div className="modal-header">
    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
      Crypto News Details 
    </h5>
    <button
        onClick={() => { setShowModel(false); }}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div className="modal-body">

    <div className="table-responsive">
        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td><b>Title</b></td>
                    <td>{showDetails?.newsTitle || '-'}</td>
                </tr>
                <tr>
                    <td><b>Description</b></td>
                    <td>{showDetails?.description || '-'}</td>
                </tr>
                <tr>
                    <td><b>Position</b></td>
                    <td>{showDetails?.position || '-'}</td>
                </tr>
                <tr>
                    <td><b>Status</b></td>
                    <td>{showDetails.isActive == 1 ? 'Active' : 'Deactive' || '-'}</td>
                </tr>
                <tr>
                    <td><b>Reason</b></td>
                    <td>{showDetails?.reason || '-'}</td>
                </tr>
                <tr>

                    <td><b>Created Date & Time</b></td>
                    <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                </tr>
                <tr>

              <td><b>Updated Date & Time</b></td>
              <td>{utcToNormalTime(showDetails.updatedAt).date} {utcToNormalTime(showDetails.updatedAt).time || '-'}</td>
              </tr>

                {showDetails?.uploadType === 'video' ? 
                <>
                <tr>
                    <td><b>Video Link</b></td>
                    <td>{showDetails?.videoLink || '-'}</td>
                </tr>
                <tr>
                    <td><b>Image Type</b></td>
                    <td>{showDetails?.videoType || '-'}</td>
                </tr>
                <tr>
                    <td><b>Video Thumbnail Image</b></td>
                    <td>
                      <Card style={{ width: '200px'}}>
                      <CardBody>
                        <CardImg
                        top
                          src={showDetails.videoThumbImg || '-'}
                          style={{ width: '100%', objectFit: 'cover' }}
                        />
                      </CardBody>
                    </Card>
                    </td>
                </tr>
                </> 
                :
                <tr>
                <td><b>Image</b></td>
                <td>
                  <Card style={{ width: '200px'}}>
                  <CardBody>
                    <CardImg
                    top
                      src={showDetails.newsImage || '-'}
                      style={{ width: '100%', objectFit: 'cover' }}
                    />
                  </CardBody>
                </Card>
                </td>
            </tr>
              }
               
               
            </tbody>
        </table>
    </div>

</div>

</Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TradeNotes;

import React, { useState, useEffect } from "react";
import { CardBody, Modal, } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import  { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";


const AdminNotes = () => {
    document.title = "Ultrapro | Withdraw Management";
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [showModel, setShowModel] = useState(false)
    const [showDetails, setShowDetails] = useState({})
    const [totalPagination, setTotalPagination] = useState(1);
    const [totalLimit, setTotalLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = totalLimit;
    const pageCount = Math.ceil(totalPagination);
    const [count, setCount] = useState("");
    const [getReason, setGetReason] = useState([]);
  const getUserDetails = async (currentPage,totalLimit) => {
      try {
        setLoading(true);
        const data = {
          adminUserId: id,
          page :currentPage,
          limit:totalLimit
        };
        const response = await postData(Apiservices.AdminUserById, data);
 console.log(response,"cccccccccccccccccccc")
        if (response.status) {
          setLoading(false);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
          setTotalPagination(response.data.totalPages)
          setGetReason(response.data?.data)
         
        } else {
          setLoading(false);
        }
      } catch (error) {
        toast.error("Internal Server Error...");
  
        setLoading(false);
  
      }
    };
    useEffect(()=>{
      getUserDetails();
    },[])



    const handleShowModel = (value) => {

        setShowDetails(value)
        setShowModel(true)
    }
    // const handlePageChange = (selectedPage) => {
    //   setCurrentPage(selectedPage.selected);
    // };
    const handlePageClick = ({ selected }) => {
      setCurrentPage(selected);
      handlePagePost(selected);
    };
    const handlePagePost = async (selected) => {
      setLoading(true);
      const postForm ={
        adminUserId:id,
      }
      const query = {
        page: selected + 1,
        limit: itemsPerPage,
      };
      const queryParams = new URLSearchParams(query).toString();
const response = await postData(`${Apiservices.AdminUserById}?${queryParams}`,postForm); 
      
        if (response.status) {
          setLoading(false);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
          setTotalPagination(response.data.totalPages)
          setGetReason(response.data?.data)
         
        } else {
          setLoading(false);
        }

    

    }
    const offset = currentPage * totalLimit;


 
          

  const reasonColumns = [
    {
      name: "S.No",
      selector: (row, index) => offset + index + 1,
      sortable: true,
      grow: 1,
    },
    {
      name: "Admin Email",
      selector: (row) => handleNullValue(row.adminEmail),
      sortable: true,
      grow: 3,

    },
    // {
    //     name: 'Status',
    //     selector: row => handleNullValue(row && row.status),
    //     sortable: true,
    //     minWidth:'200px'
    // },
    {
      name: "Status",
      //selector: row => row.status[0],
      selector: (row) => handleNullValue(row.status == 1 ? "Active" : "Blocked"),

      // cell: row => {
        
      //   switch (row.status[0]) {
      //     case 1:
      //       return 'Active';
      //     case 0:
      //       return 'Blocked';
      //     default:
      //       return '-';
      //   }
      // },
      sortable: true,
      grow: 3,

    },
     {  
      name: "Reason",  
    //   selector: (row) => handleNullValue(row.reason),  
    selector: (row) => handleNullValue(row.reason)?.slice(0, 20) + (row.reason?.length > 20 ? '...' : ''),
      grow: 2,

    //   sortable: true,  
    //   cell: (row) => (  
    //     <span data-tip={row.reason}>  
    //       {handleNullValue(row.reason)}  
    //     </span>  
    //   ),  


    },  
   

    {
      name: "Date & Time",
      selector: (row) =>
        row.updatedAt
          ? `${utcToNormalTime(row.updatedAt).date} ${
              utcToNormalTime(row.updatedAt).time
            }`
          : "---",
      sortable: true,
      grow: 2,

    },
    {
        name: 'Action',
        cell: row => (
            <div className="edit d-flex">
                <span id={`tooltipEmail${row.id}`} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></span>
                {/* <Tooltip placement="top" isOpen={tooltipOpen1} target={`tooltipEmail${row.id}`} toggle={toggleTooltip1}> View </Tooltip> */}


            </div>
        ),
        ignoreRowClick: true,
      grow: 1,
        

    },
    // {
    //     name: 'Updated Date',
    //     selector: row => row.createdAt
    //         ? `${utcToNormalTime(row.updatedAt).date} ${utcToNormalTime(row.updatedAt).time}`
    //         : "---",
    //     sortable: true
    // },
  ];

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setTotalLimit(parseInt());
  }
  
    return (
        <React.Fragment>
            <Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static" >

                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
                        User Notes View
                    </h5>
                    <button
                        onClick={() => { setShowModel(false); }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body">
                    <div className="table-responsive">
                        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td><b>Admin Email</b></td>
                                    <td>{showDetails.adminEmail || '-'}</td>
                                </tr>
                                <tr>
                                      <td><b>Status</b></td>
                                      <td>{showDetails.status || '-'}</td>
                                  </tr>
                                
                                <tr>
                                    <td><b>Reason</b></td>
                                    <td>{showDetails.reason || '-'}</td>
                                </tr>                          
                                <tr>
                                    <td><b>Date & Time</b></td>
                                    <td>{utcToNormalTime(showDetails.updatedAt).date} {utcToNormalTime(showDetails.updatedAt).time}</td>
                                </tr>
                             
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
            <CardBody>
                              <div className="table-responsive">
                                <DataTable
                                  columns={reasonColumns}
                                  data={getReason}
                                  paginationServer
                                  paginationTotalRows={getReason.length}
                                  progressPending={loading}
                                  persistTableHead={true}
                                  progressComponent={
                                    <div className="py-3">Loading...</div>
                                  }
                                  noDataComponent={
                                    <div className="py-3">No records found</div>
                                  }
                                  fixedHeader={true}
                                />
                                {getReason.length != 0 ? (
                                  
                                  <div className="container">
                                  <div className="row justify-content-end mt-4">
                                  <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                                      <div className="d-flex align-items-center me-3">
                                        <span className="me-2">Rows per page:</span>
                                        <select
                                          value={totalLimit}
                                          onChange={(e) => handleRowsPerPageChange(e.target.value)}
                                          className="form-control-sm"
                                        >
                                          <option value={10}>10</option>
                                          <option value={20}>20</option>
                                          <option value={30}>30</option>
                                          <option value={50}>50</option>
                                        </select>
                                      </div>
                                      <div className="me-md-3 mb-3 mb-md-0">
                                        <span>{count}</span>
                                      </div>
                                      <div className="pagination pagination-rounded mt-3">
                                        <ReactPaginate
                                          previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                          nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                          breakLabel={"..."}
                                          breakClassName={"break-me"}
                                          pageCount={pageCount}
                                          marginPagesDisplayed={1}
                                          pageRangeDisplayed={5}
                                          onPageChange={handlePageClick}
                                          containerClassName={"pagination"}
                                          activeClassName={"active"}
                                          pageLinkClassName={"page-link"}
                                          breakLinkClassName={"break-link"}
                                          disabledClassName={"disabled"}
                                          initialPage={currentPage}
                                          disableInitialCallback={true}
                                          forcePage={currentPage}
                                          renderLink={(props) => {
                                            if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                              return (
                                                <a
                                                  {...props}
                                                  onClick={() =>
                                                    handlePageClick({
                                                      selected: pageCount - 1,
                                                    })
                                                  }
                                                >
                                                  {pageCount}
                                                </a>
                                              );
                                            }
                                            return <a {...props} />;
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                ) : null}
                              </div>
                            </CardBody>
        </React.Fragment>
    );
};

export default AdminNotes;

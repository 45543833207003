
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

import { useLocation } from 'react-router-dom';
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, InputGroup, Button, Spinner } from "reactstrap";

//redux
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { createSelector } from 'reselect';
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices, TokenName } from "../../ApiService/ApiServices";
import { useNavigate } from "react-router-dom";
const Login = (props) => {
    const navigate = useNavigate()
    const location = useLocation();
    const { email } = location.state || {};
    const [loading, setLoading] = useState(false);
    const [submitLoad, setSubmitLoad] = useState(false);

    const [countdown, setCountdown] = useState(60);
    const [isDisabled, setIsDisabled] = useState(false);

    document.title = "Login | ultrapro - React Admin & Dashboard";

    const startCountdown = () => {
        setIsDisabled(true);
        const intervalId = setInterval(() => {
            setCountdown(prevCountdown => {
                const newCountdown = prevCountdown - 1;
                if (newCountdown <= 0) {
                    clearInterval(intervalId);
                    setIsDisabled(false);
                    return 0;
                }
                return newCountdown;
            });
        }, 1000);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        validation.setFieldValue(name, value.replace(/\s+/g, ''));
      };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            otp: ''
        },
        validationSchema: Yup.object({
            otp: Yup.string()

                .required('OTP is required'),
        }),
        onSubmit: (values) => {
            otpVerify(values)
        },
    });




    const sendOTP = async () => {
        setLoading(true);
        try {
            const response = await postData(Apiservices.ADMIN_OTP_SEND, { email });
            if (response.status) {
                setCountdown(60);
                startCountdown();
                setLoading(false);
                toast.dismiss()
                toast.success(response.message)
                validation.resetForm();


            } else {
                setLoading(false);
                toast.dismiss()
                toast.error(response.message)
                validation.resetForm();
        
            }
        } catch (error) {
            setLoading(false);
            toast.dismiss()
            toast.error("Internal Server Error..Try Again Later")

        }
    };

    const otpVerify = async (data) => {
        setSubmitLoad(true)
        const postForm = {
            email: email,
            otp: data.otp
        }

        try {
            const response = await postData(Apiservices.ADMIN_OTP_VERIFY, postForm);
            if (response.status) {
                const token = response.data.token
                if(response.data.token){
                    Cookies.set(TokenName.AUTH_TOKEN, token)
                    navigate('/dashboard')
                    toast.success(response.message)
                }
                setSubmitLoad(false)

            } else {
                validation.resetForm();
                toast.error(response.message)
                setSubmitLoad(false)

            }
            if (response.data.isIpActive===2){
                toast.dismiss()
                navigate('/ipBlocked')
                toast.error(response.message)
                
              }
        } catch (error) {
            setSubmitLoad(false)
            // toast.error("Internal Server Error..Try Again Later")

        }
    };

    const loginpage = createSelector(
        (state) => state.login,
        (state) => ({
            error: state.error,
        })
    );

    const { error } = useSelector(loginpage);


    useEffect(() => {
        document.body.className = "bg-pattern";
        return function cleanup() {
            document.body.className = "";
        };
    }, []);

    return (
        <React.Fragment>
            <div className="bg-overlay"></div>
            <div className="account-pages my-5 pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} xl={4}>
                            <Card>
                                <CardBody className="p-4">
                                    <div>
                                        <div className="text-center">
                                            <Link to="/">
                                                <img
                                                    src={logodark}
                                                    alt=""
                                                    height="45"
                                                    className="auth-logo logo-dark mx-auto"
                                                />
                                                <img
                                                    src={logolight}
                                                    alt=""
                                                    height="45"
                                                    className="auth-logo logo-light mx-auto"
                                                />
                                            </Link>
                                        </div>
                                        <h4 className="font-size-18 text-muted mt-2 text-center">
                                            Verify Your OTP
                                        </h4>
                                        <p className="mb-5 text-center">
                                        Ultrapro Admin Panel
                                        </p>
                                        <div className="user-thumb text-center m-b-30">
                                            <h4 className="rounded-circle avatar-lg img-thumbnail mx-auto d-flex justify-content-center align-items-center">{countdown === 60 ? ("0") : (countdown)}<span style={{fontSize:'15px',marginTop:'7px'}}>s</span></h4>
                                        </div>
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            {error ? <Alert color="danger"><div>{error}</div></Alert> : null}
                                            <Row>
                                                <Col md={12} className="AuthStar">
                                                    <div className="mb-4">
                                                        <Label className="form-label">One Time Password<span>*</span></Label>
                                                        <InputGroup>
                                                            <Input
                                                                style={{ borderColor: '#80838B' }}
                                                                name="otp"
                                                                disabled={loading || countdown === 60 || countdown === 0 }
                                                                className="form-control"
                                                                placeholder="Enter OTP"
                                                                type="text"
                                                                onChange={handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.otp || ""}
                                                                invalid={validation.touched.otp && validation.errors.otp ? true : false}
                                                            />
                                                            {countdown === 0 ? (
                                                                <Button
                                                                    onClick={sendOTP}
                                                                    className="btn btn-primary waves-effect waves-light"
                                                                    style={{ backgroundColor: '#80838B', color: 'white' }}
                                                                    disabled={isDisabled || loading}
                                                                >
                                                                     {loading ? ( 
                                                                            <Spinner size="sm" />    
                                                                    ) : ("ReSend")}
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    onClick={sendOTP}
                                                                    className="btn btn-primary waves-effect waves-light"
                                                                    style={{ backgroundColor: '#80838B', color: 'white' }}
                                                                    disabled={isDisabled || loading}
                                                                >
                                                                    {loading ? ( 
                                                                            <Spinner size="sm" />    
                                                                    ) : ("Send OTP")}
                                                                </Button>
                                                            )}
                                                            {validation.touched.otp && validation.errors.otp ? (
                                                                <FormFeedback>{validation.errors.otp}</FormFeedback>
                                                            ) : null}
                                                        </InputGroup>
                                                    </div>

                                                    <div className="d-grid mt-4">
                                                  
                                                            <button
                                                                className="btn btn-primary waves-effect waves-light"
                                                                type="submit"
                                                                disabled={loading || countdown === 60 || submitLoad}
                                                                onClick={validation.handleSubmit}
                                                            >
                                                                      {submitLoad ? (
                                                            <>
                                                                <Spinner size="sm">
                                                                    Loading...
                                                                </Spinner>
                                                                <span style={{ color: 'white' }}>
                                                                    {' '}Loading...
                                                                </span>
                                                            </>


                                                        ) : ("Submit")}
                                                                
                                                            </button>

                                                       


                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Login);

Login.propTypes = {
    history: PropTypes.object,
};


import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/logo-dark.png";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices,TokenName } from "../../ApiService/ApiServices";
import toast from "react-hot-toast";
import Loader from "../../helpers/Spinner";
import { Icon } from '@mdi/react';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const ChangePassword = () => {
  // const { showPassword, togglePasswordVisibility, getIcon } = usePasswordToggle();
  const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    
    const toggleOldPasswordVisibility = () => {
      setShowOldPassword(!showOldPassword);
    };
  
    const toggleNewPasswordVisibility = () => {
      setShowNewPassword(!showNewPassword);
    };
  
    const toggleConfirmPasswordVisibility = () => {
      setShowConfirmPassword(!showConfirmPassword);
    };
    const getIcon = (showPassword) => (showPassword ? mdiEyeOff : mdiEye);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',


        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required("Old password is required"),
            newPassword: Yup.string()
                .required("New password is required")
                .notOneOf([Yup.ref('oldPassword'), null], 'New password must be different from old password'),
            confirmPassword: Yup.string()
                .required("Confirm password is required")
                .oneOf([Yup.ref('newPassword'), null], 'Confirm password must match new password'),
        }),
        onSubmit: (values) => {

            handlePost(values)


        },
    });

    const handlePost = async (data) => {
        const postForm = {
            oldPassword:data.oldPassword,
            password: data.confirmPassword,
         
        }
        setLoading(true)

        try {
            const response = await postData(Apiservices.ADMIN_CHANGE_PASSWORD, postForm)
            if (response.status) {
              
                setLoading(false)
                toast.success(response.message)
                validation.resetForm();
                handleLogout()

            } else {
                setLoading(false)
           
                toast.error(response.message)
           
            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later")
            setLoading(false)
            validation.resetForm();
        }




    }



    const handleLogout=async()=>{

      await Cookies.remove(TokenName.AUTH_TOKEN)
      await navigate('/login')
       
     
     
   }
 

  return (
    <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb title="Ultrapro" breadcrumbItem="Change Password" />

            <Row>
              <Col lg="12">
           

                <Card>
                  <CardBody className="d-flex justify-content-center">
                    <div className="d-flex">
                      <div className="ms-3 d-flex">
                        <img
                          src={avatar}
                          alt=""
                          className=""
                          style={{width:'200px'}}
                        />
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                        
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Change Password</h4>

            <Card>
              <CardBody>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                    
                  }}
                  autoComplete="off"
                >
          <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">Old Password</label>
                            <div className="input-group">
                            <Input
                               type={showOldPassword ? "text" : "password"}
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Old Password"
                                name="oldPassword"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.oldPassword || ""}
                                invalid={validation.touched.oldPassword && validation.errors.oldPassword}
                            />
                            <div className="input-group-text" onClick={toggleOldPasswordVisibility} style={{ cursor: 'pointer' }}>
                      <Icon path={getIcon(showOldPassword)} size={1} />
                    </div>
                    {validation.touched.oldPassword && validation.errors.oldPassword && (
                                <FormFeedback type="invalid">{validation.errors.oldPassword}</FormFeedback>
                            )}
                            </div>
                           
                        </div>
                        <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">New Password</label>
                            <div className="input-group">
                            <Input
                                type={showNewPassword ? "text" : "password"}
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter New Password"
                                name="newPassword"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.newPassword || ""}
                                invalid={validation.touched.newPassword && validation.errors.newPassword}
                            />
                             <div className="input-group-text" onClick={toggleNewPasswordVisibility} style={{ cursor: 'pointer' }}>
                      <Icon path={getIcon(showNewPassword)} size={1} />
                    </div>
                    {validation.touched.newPassword && validation.errors.newPassword && (
                                <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                            )}
                            </div>
                            
                        </div>
                        <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">Confirm Password</label>
                            <div className="input-group">
                            <Input
                                 type={showConfirmPassword ? "text" : "password"}
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Confirm Password"
                                name="confirmPassword"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.confirmPassword || ""}
                                invalid={validation.touched.confirmPassword && validation.errors.confirmPassword}
                            />
                             <div className="input-group-text" onClick={toggleConfirmPasswordVisibility} style={{ cursor: 'pointer' }}>
                      <Icon path={getIcon(showConfirmPassword)} size={1} />
                    </div>
                    {validation.touched.confirmPassword && validation.errors.confirmPassword && (
                                <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                            )}
                            </div>
                           
                        </div>
                  <div className="text-center mt-4">
                    <Button type="submit" color="danger" disabled={loading}>
                      

                      {loading ? (
                                    <Loader />

                                ) : ("Update Change Password")}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </div>
    </React.Fragment>
  );
};

export default ChangePassword;


import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Navigate } from "react-router-dom";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";

const Error500 = () => {
  const [ipBlocked, setIpBlocked] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await postData(Apiservices.ADMIN_IP_BLOCK_SITE);
        
        if (response && (response.data.isIpActive === 2 || response.data.isActive === 0)) {
          
          setIpBlocked(true); // IP is blocked
        } else {
          setIpBlocked(false); // IP is not blocked
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  // Redirect to /login if IP is not blocked
  if (ipBlocked === false) {
    return <Navigate to="/login" />;
  }

  // If ipBlocked is still null (data not fetched yet), you can add a loader here if needed
  if (ipBlocked === null) {
    return <div>Loading...</div>; // Or your preferred loading state UI
  }

  return (
    <React.Fragment>
      <div className="my-5 pt-5">
        <div className="w-100">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={8} xl={5}>
                <div className="text-center">
                  <div>
                    <h1 className="display-2 error-text fw-bold">500</h1>
                  </div>
                  <div>
                    <h4 className="text-uppercase mt-4">Access Denied</h4>
                    <p>Your IP address has been blocked due to unwanted entry.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Error500;

import React, { useState, useEffect ,useContext, useCallback} from "react";
import {Col, Container, Row, Card, CardBody, Button, Nav, NavItem, NavLink,
TabContent, CardText, TabPane, CardSubtitle, Table, Modal, ModalBody} from "reactstrap";
import classnames from "classnames";
import { postData } from "../../../ApiService/AxiosInstance";
import { Apiservices, Boolian } from "../../../ApiService/ApiServices";
import toast from "react-hot-toast";
import Loader from "../../../helpers/Spinner";
import { useLocation, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import UserBankView from "./UserBankView";
import UserLoginActivities from "../UserLoginActivities";
import UserNotes from "./userNotes";
import UserRefferalCommission from "./UserReferralCommission";
import UserBalance from "./UserBalance";
import UserKYC from "./UserKYC";
import UserKYCsumsub from "./UserKYCsumsub";
import UserRefferalUser from "./UserReferralUser";
import { AdminContext } from "../../../helpers/adminContext";
import UserAddress from "./UserAddress";
import { utcToNormalTime } from "../../../helpers/function";
import OwnStakeHistory from "./OwnStakeHistory";
import OthersStakeHistory from "./OthersStakeHistory";
// import UserRefferalten from "./UserRefferalten";


const UserDetails = () => {
  const { adminInfo } = useContext(AdminContext);
  const [adminType,setAdminType] = useState('')
  const { id } = useParams();
  const location = useLocation();
  const { kycTab } = location.state || {};
  const [loading, setLoading] = useState(false);
  // const [balance, setBalance] = useState([]);
  const [fullImage, setFullImage] = useState("");
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [getReason, setGetReason] = useState([]);
  const [kycDetails, setKycDetails] = useState({});

  const [activeTab, setActiveTab] = useState("1");
  const [userDetails, setUserDetails] = useState({});
  const [getWithOtpCount,setWithOtpCount] = useState()
  const [bankDetails, setBankDetails] = useState([])
  const [getReferralList, setReferralList] = useState([]);
  const [getReferralUser, setReferralUser] = useState([]);
  const [coupenCode1,setCoupenCode] = useState('')
  const [getCode,setGetCode] = useState('')
  const [refLoad, setRefLoad] = useState(false)
  const [modelCount, setmodalCount] = useState(false);
  const [selectValue, setSelectedValue] = useState({
    userStatus: null,
    userSelect: null,
  });

  document.title = "Ultrapro | User Details";
  useEffect(() => {
    setAdminType(adminInfo?.adminType)
    if (kycTab) {
      setActiveTab("5");
    }
  }, [kycTab,adminInfo?.adminType]);

  const getUserDetails =useCallback( async () => {
    try {
      setLoading(true);
      const data = {
        acId: Number(id),
        
      };
      const response = await postData(Apiservices.GetUserDetails, data);
      if (response.status) {
        console.log(response,"222222222222222222222")
        setWithOtpCount(response.data.data.withdrawOtpCount)
        setUserDetails(response.data.data);
        setBankDetails(response.data.bankRecords)
        setLoading(false);
        setGetReason(response.data.mergedReasons);
        setReferralUser(response.data.referredUsers);
        setKycDetails(response.data.kyc)
        setCoupenCode(response.data?.data?.parentcoupons?.couponCode)
        setGetCode(response.data?.data?.childCoupons?.couponCode)
        setWithdrawStatus(response.data.data.withdrawStatus)
        setProUserStatus(response.data.data.proUsers)
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

    }
  },[id]);
  
  useEffect(()=>{
    getUserDetails();
  },[getUserDetails])


 


  const toggle = async (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
    if (tab === "2") {
      handleBalance();

    }
    if (tab === "8") {
      getUserDetails();
    }
    if (tab === "6") {
      FetchRefferalDetails();
    }
  };

  const FetchRefferalDetails = async () => {
    setRefLoad(true);
    try {
      const data = {
        acId: Number(id)
      };
      const response = await postData(Apiservices.getUserReferralList, data);
      if (response.status === true) {
        setReferralList(response.data.data);
        setRefLoad(false);
      } else {
        setRefLoad(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setRefLoad(false);

      // Handle fetch error
    }

  };



  const handleBalance = async () => {
    setLoading(true);
    const postForm = {
      acId: Number(id),
    };
    try {
      const response = await postData(Apiservices.USER_BALENCE_LIST, postForm);
      if (response && response.status) {
        setLoading(false);
        // setBalance(response.data.data);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getStatusText = (code) => {
    switch (code) {
      case Boolian.PENDING_USER:
        return "Pending";
      case Boolian.USER_VERIFIED:
        return "Verified";
      case Boolian.USER_BLOCKED:
        return "Verified";
      default:
        return "-";
    }
  };

  function tog_xlarge(image) {
    setFullImage(image);
    setmodal_xlarge(!modal_xlarge);
  }

  const handleUpdateCount = async (e) => {
    setLoading(true);
    const postForm = {
      acId: userDetails.acId,
      count: selectValue.userStatus,
    };
    try {
      const response = await postData(Apiservices.ADMIN_LOGIN_COUNT, postForm);

      if (response && response.status) {
        setLoading(false);
        setmodalCount(false);
        toast.success(response.message);
        getUserDetails();
      } else {
        setLoading(false);
        setmodalCount(false);
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);

      setLoading(false);
    }
  };





  //withdraw status
  const handleWithdrawStatusClick = (row) => {
    showWithdrawStatusAlert(handleToggle2, row);
  };

  const showWithdrawStatusAlert = (onConfirm, row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to ${withdrawStatus === 1 ? 'enable' : 'disable'} withdraw status!`,
      inputLabel: "Enter a Reason",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off"
      },
      inputPlaceholder: "Enter Reason",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!',
      inputValue: row.reason,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async (reason) => {
        if (!reason) {
          Swal.showValidationMessage('Reason is required');
        } else {
          Swal.showLoading();
          try {
            await onConfirm(row, reason);

          } catch (error) {
            // Swal.fire('Error!', 'There was an error updating the status', 'error');
          }
        }
      },
    });
  };

  const handleToggle2 = async (rowDetails, reason) => {
    const permissions = [{
      module: 'All Users', 
      actions: [2] 
    }];
    const updatedStatus = rowDetails.withdrawStatus === 0 ? 1 : 0;
    const postForm = {
      withdrawStatus: updatedStatus,
      // depositStatus: rowDetails.depositStatus,
      acId: rowDetails.acId,
      reason: reason,
      permissions:permissions,
      adminType:adminInfo?.adminType
    };

    try {
      setLoading(true);
      const response = await postData(Apiservices.USER_EDIT_LIST, postForm);
      if (response.status) {
        toast.dismiss();
        setLoading(false);
        Swal.fire('Success!', 'The Withdraw status has been updated successfully', 'success');
        getUserDetails();
      } else {
        Swal.fire('Error!',`${response.message}`, 'error');
        setLoading(false);
      }
    } catch (error) {
      toast.dismiss();
      Swal.fire('Error!',`${error.message}`, 'error');
      setLoading(false);
    }
  };

  //pro user status 
  const [withdrawStatus, setWithdrawStatus] = useState(false)
  const [prouserstatus, setProUserStatus] = useState(false)

  const handleProUserStatus = (row) => {
    showProuserAlert(handleToggle3, row);
  };

  const showProuserAlert = (onConfirm, row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to update pro user status!",
      inputLabel: "Enter a Reason",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off"
      },
      inputPlaceholder: "Enter Reason",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!',
      inputValue: row.reason,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async (reason) => {
        if (!reason) {
          Swal.showValidationMessage('Reason is required');
        } else {
          Swal.showLoading();
          try {
            await onConfirm(row, reason);
            // Swal.fire('Success!', 'The user verification status has been updated successfully', 'success');
          } catch (error) {
            // Swal.fire('Error!', 'There was an error updating the status', 'error');
          }
        }
      },
    });
  };

  const handleToggle3 = async (rowDetails, reason) => {
    const permissions = [{
      module: 'All Users', 
      actions: [2] 
    }];
    const updatedStatus = rowDetails.proUsers === 1 ? 0 : 1;
    const postForm = {
      proUsers: updatedStatus,
      acId: rowDetails.acId,
      reason: reason,
      permissions:permissions,
      adminType:adminInfo?.adminType
    };


    try {
      setLoading(true);
      const response = await postData(Apiservices.USER_EDIT_LIST, postForm);
      if (response.status) {
        toast.dismiss();
        Swal.fire('Success!', 'The pro users status has been updated successfully', 'success');
        setLoading(false);
        getUserDetails();
      } else {
        toast.error(response.message);
        setLoading(false);
        Swal.fire('Error!',`${response.message}`, 'error');
      }
    } catch (error) {
      toast.dismiss();
      // toast.error("Internal Server Error..Try Again Later");
      Swal.fire('Error!',`${error.message}`, 'error');

      setLoading(false);
    }
  };




  //user enable/disable
  const handleUserSelect = (value) => {

    setSelectedValue({ userSelect: value });
    showUserBlockAlert(handleuserBlock, value)
  }
  const showUserBlockAlert = (onConfirm, row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to ${userDetails.isActive === 2 ? 'activate' : 'deactivate'} this user!`,
      inputLabel: "Enter a Reason",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off"
      },
      inputPlaceholder: "Enter Reason",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!',
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        if (!reason) {
          Swal.showValidationMessage('Reason is required');
        } else {
          Swal.showLoading();
          try {
            await onConfirm(row, reason);
          } catch (error) {
            // Swal.fire('Error!', 'There was an error updating the status', 'error');
          }
        }
      },

      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        // Set reason here
      }
    });
  };

  const handleuserBlock = async (selectValue, reason) => {
    const permissions = [{
      module: 'All Users', 
      actions: [2] 
    }];
    const postForm = {
      acId: id,
      isActive: selectValue,
      reason: reason,
      permissions:permissions,
      adminType:adminInfo?.adminType
    };


    try {
      setLoading(true)

      const response = await postData(Apiservices.USER_BLOCK_UNBLOCK, postForm)
      if (response.status) {
        toast.dismiss()
        setLoading(false)
        Swal.fire('Success!', `The user has been ${userDetails.isActive === 2 ? ' activated' : 'deactivated'} successfully`, 'success');
        getUserDetails()

      } else {
        setLoading(false)
        Swal.fire('Error!',`${response.message}`, 'error');
      }

    } catch (error) {
      toast.dismiss()
      setLoading(false)
      Swal.fire('Error!',`${error.message}`, 'error');

    }

  }

  //password otp count

  const handlePasswordCount = (value) => {
    showAlertPasswordCount(handlePasswordCountSubmit, value);
  };
  const showAlertPasswordCount = (onConfirm, value) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to update ${userDetails.acBlock === 2 ? 'OTP Attempt' : 'Password Attempt'} Count ?`,
      icon: 'info',
      showCancelButton: true,
      inputLabel: "Enter a Reason",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off"
      },
      inputPlaceholder: "Enter Reason",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!',
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        if (!reason) {
          Swal.showValidationMessage('Reason is required');
        } else {
          Swal.showLoading();
          try {
            await onConfirm(value, reason);
          } catch (error) {
          }
        }
      },

      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  };
  const handlePasswordCountSubmit = async (value, reason) => {
    const permissions = [{
      module: 'All Users', 
      actions: [2] 
    }];
    const postForm = {
      acId: userDetails.acId,
      count: Number(value),
      acBlock: userDetails.acBlock,
      reason: reason,
      permissions:permissions,
      adminType:adminInfo?.adminType
    };

    try {
      const response = await postData(Apiservices.ADMIN_LOGIN_COUNT, postForm);

      if (response && response.status) {
        setLoading(false);
        setmodalCount(false);
        Swal.fire('Success!', `The ${userDetails.acBlock === 2 ? 'OTP Attempt' : 'Password Attempt'} count has been updated successfully`, 'success');
        getUserDetails();
      } else {
        setLoading(false);
        setmodalCount(false);
        Swal.fire('Error!',`${response.message}`, 'error');

      }
    } catch (error) {
      toast.dismiss()
      setLoading(false)
      Swal.fire('Error!',`${error.message}`, 'error');

    }

  }

  //login otp count
  const handleOtpCount = (value) => {
    showAlertOTPCount(handleOTPCountSubmit, value);
  };
  const showAlertOTPCount = (onConfirm, value) => {
    Swal.fire({
      title: 'Are you sure?',
      text: " You want to update Login OTP Attempt Count ?",
      icon: 'info',
      showCancelButton: true,
      inputLabel: "Enter a Reason",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off"
      },
      inputPlaceholder: "Enter Reason",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!',
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        if (!reason) {
          Swal.showValidationMessage('Reason is required');
        } else {
          Swal.showLoading();
          try {
            await onConfirm(value, reason);
          } catch (error) {
            
          }
        }
      },

      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
       
      }
    });
  };
  const handleOTPCountSubmit = async (value, reason) => {
    const postForm = {
      acId: userDetails.acId,
      count: value,
      wrongAttempts: 2,
      reason: reason
    };

    try {
      const response = await postData(Apiservices.ADMIN_LOGIN_COUNT, postForm);

      if (response && response.status) {
        setLoading(false);
        setmodalCount(false);
        Swal.fire('Success!', `The login otp attempt count has been updated successfully`, 'success');
        getUserDetails();
      } else {
        setLoading(false);
        setmodalCount(false);
        Swal.fire('Error!',`${response.message}`, 'error');

      }
    } catch (error) {
      toast.dismiss()
      setLoading(false)
      Swal.fire('Error!', 'There was an error updating the count', 'error');

    }

  }


  // withdraw otp count
  const handleWithdrawOtpCount = (value) => {
    showAlertWithdrawOTPCount(handleWithdrawOtpSubmit, value);
  };
  const showAlertWithdrawOTPCount = (onConfirm, value) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to unblock this withdraw ?",
      icon: 'info',
      showCancelButton: true,
      inputLabel: "Enter a Reason",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off"
      },
      inputPlaceholder: "Enter Reason",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!',
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        if (!reason) {
          Swal.showValidationMessage('Reason is required');
        } else {
          Swal.showLoading();
          try {
            await onConfirm(value, reason);
          } catch (error) {
          }
        }
      },

      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  };
  const handleWithdrawOtpSubmit = async (value, reason) => {
    const permissions = [{
      module: 'All Users', 
      actions: [2] 
    }];
    const postForm = {
      acId: userDetails.acId,
      withOtpCount: value,
      // withdrawMaxCount:Number(value),
      reason: reason,
      permissions:permissions,
      adminType:adminInfo?.adminType
    };

    try {
      const response = await postData(Apiservices.userWithdrawOtpCountUpdate, postForm);

      if (response && response.status) {
        setLoading(false);
        setmodalCount(false);
        Swal.fire('Success!', `The Withdraw OTP Attempts has been updated successfully`, 'success');
        getUserDetails();
      } else {
        setLoading(false);
        setmodalCount(false);
        Swal.fire('Error!',`${response.message}`, 'error');

      }
    } catch (error) {
      toast.dismiss()
      setLoading(false)
      Swal.fire('Error!',`${error.message}`, 'error');

    }

  }


  const getKycStatusText = (code) => {
    switch (code) {
        case Boolian.KYC_PENDING_NUMBER:
            return "Pending";
        case Boolian.KYC_VERIFIED_NUMBER:
            return "Verified";
        case Boolian.KYC_REJECTED:
            return "Rejected";
        case Boolian.KYC_NOT_UPLOAD:
            return "Not Uploaded";
        default:
            return "Unknown";
    }
};



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {!kycTab ?
                    <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "1" })}
                        onClick={() => { toggle("1"); }}>
                        <i className="dripicons-user me-1 align-middle"></i>Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "2", })}
                        onClick={() => { toggle("2"); }} >
                        <i className="mdi mdi-wallet-outline"></i> User Balance
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "3", })}
                        onClick={() => { toggle("3"); }} >
                        <i className="mdi mdi-bank-outline"></i> User Bank Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "4", })}
                        onClick={() => { toggle("4"); }} >
                        <i className="mdi mdi-update"></i> Login Activities
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "5", })}
                        onClick={() => { toggle("5"); }} >
                        <i className="mdi mdi-card-account-mail-outline"></i>{" "}KYC Details
                      </NavLink>
                    </NavItem> */}

                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "9", })}
                        onClick={() => { toggle("9"); }} >
                        <i className="mdi mdi-card-account-mail-outline"></i>{" "}KYC Details(Onfido)
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "6" })}
                        onClick={() => { toggle("6"); }} >
                        <i className="mdi mdi-account-multiple"></i> Referral Commissions
                      </NavLink>
                    </NavItem>

                      <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "7" })}
                          onClick={() => { toggle("7"); }} >
                          <i className="mdi mdi-account-multiple"></i> Referral Users
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "8", })}
                          onClick={() => { toggle("8"); }} >
                          <i className="mdi mdi-account-multiple"></i> User Notes
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "10", })}
                          onClick={() => { toggle("10"); }} >
                          <i className="mdi mdi-cash-minus"></i> User Address
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "11", })}
                          onClick={() => { toggle("11"); }} >
                          <i className="mdi  mdi-account-multiple"></i> Reached 10 Referrals
                        </NavLink>
                      </NavItem> */}
                       {/* <NavItem>
                            <NavLink style={{ cursor: "pointer" }}
                              className={classnames({ active: activeTab === "12", })}
                              onClick={() => { toggle("12"); }} >
                              <i className="mdi mdi-cash"></i>{" "}
                             Own Stake History
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink style={{ cursor: "pointer" }}
                              className={classnames({ active: activeTab === "13", })}
                              onClick={() => { toggle("13"); }} >
                              <i className="mdi mdi-cash"></i>{" "}
                              Others Stake History
                            </NavLink>
                          </NavItem> */}
                    </Nav>
                    : ''
                  }


                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1" id="home">
                      <Row>
                        <Col sm="12" className="d-lg-flex">

                          <Card className="col-lg-6">
                            <CardBody>
                              <h4 className="card-title">User Details</h4>
                              <CardSubtitle className="mb-3">
                                {/* <div className="d-flex justify-content-center">
                              <div className="ms-3">
                                <img src="{avatar}" alt="" className="avatar-md rounded-circle img-thumbnail" />
                              </div>
                              <div className="">
                                <div className="text-muted">
                                  <h5>Nantha Kumar</h5>
                                  <p className="mb-1">nandhu03tmg@gmail.com</p>
                                  <p className="mb-0">Id no: #12345</p>
                                </div>
                              </div>
                            </div> */}
                              </CardSubtitle>

                              <div className="table-responsive">
                                <Table responsive striped className="table-nowrap mb-0" >
                                  {loading ? (<div className="d-flex justify-content-center">Loading... </div>) : (

                                    <tbody>
                                      <tr>
                                        <td>Name</td>
                                        <td>{userDetails && userDetails.fullName ? userDetails.fullName : "-"}</td>
                                      </tr>
                                      <tr>
                                        <td>Profile Image</td>
                                        <td>
                                          {userDetails && userDetails.profileImg ? (<img className="rounded-circle header-profile-user" onClick={() => tog_xlarge(userDetails.profileImg)} src={userDetails.profileImg} alt="" />) : ("-")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Email Address</td>
                                        <td>
                                          {userDetails && userDetails.email ? userDetails.email : "-"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Email Verification</td>
                                        <td>
                                          {userDetails && getStatusText(userDetails.isActive)}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Country</td>
                                        <td>
                                          {userDetails && userDetails.country}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Mobile Number</td>
                                        <td>
                                          {userDetails?.mobileNo
                                            ? `${userDetails.mobileCode ? `+${userDetails.mobileCode} ` : ''}${userDetails.mobileNo}`
                                            : '-'}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>User ID</td>
                                        <td>{userDetails && userDetails.acId}</td>
                                      </tr>
                                      <tr>
                                        <td>Referral ID</td>
                                        <td>
                                          {userDetails && userDetails.referralId ? userDetails.referralId : "-"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Coupon Code</td>
                                        <td>
                                          {coupenCode1 || "-"}
                                        </td>
                                      </tr>
                                      <tr>
                                      <td>Registered Coupon Code</td>
                                      <td>
                                        {getCode || "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>MSR Code</td>
                                      <td>
                                        {userDetails?.MSRdetails?.couponCode || "-"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Stake Status</td>
                                      <td>
                                        {userDetails?.isStacked == 0 ? 'NO':'YES' || "-"}
                                      </td>
                                    </tr>
                                    
                                     
                                    </tbody>
                                  )}
                                </Table>
                              </div>
                            </CardBody>
                          </Card>
                          {loading ? ('') : (
                            <Card className="col-lg-6 mt-4">
                              <CardBody>
                                <CardSubtitle className="mb-3"></CardSubtitle>
                                <div className="table-responsive">
                                  <Table responsive striped className="table-nowrap mb-0" >

                                    <tbody>
                                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                                   data.module === "All Users" && data.actions.includes(3))) ? (
                                      <tr>
                                        <td>Withdraw Status</td>
                                        <td>
                                          <div className="form-check form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              disabled={loading}
                                              checked={withdrawStatus === 0}
                                              onClick={() => handleWithdrawStatusClick(userDetails)}
                                            />
                                          </div>
                                        </td>
                                      </tr> 
                                   ) :null}  
                                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                                   data.module === "All Users" && data.actions.includes(3))) ? (
                                      <tr>
                                        <td>Pro User Status</td>
                                        <td>
                                          <div className="form-check form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              disabled={loading}
                                              checked={prouserstatus}
                                              onClick={() => handleProUserStatus(userDetails)}

                                            />
                                          </div>
                                        </td>
                                      </tr>
                                       ) :null} 
                                      {/* status - 0 pending,status - 1 verified ,status - 2 blocked */}
                                      
                                      {userDetails.isActive !== 0 ?
                                      <>
                                        {(adminType === 1 || adminInfo?.permissions.some(data => 
                                   data.module === "All Users" && data.actions.includes(3))) ? ( 
                                        <tr>
                              
                                          <td>Active / Deactive</td>
                                          <td>
                                            <select
                                              onChange={(e) => handleUserSelect(e.target.value)}
                                              className="form-select"
                                              id="autoSizingSelect"
                                              value={userDetails.isActive}
                                            >
                                              <option defaultValue disabled>
                                                Select
                                              </option>
                                              <option value={1}>Active</option>
                                              <option value={2}>Deactive</option>

                                            </select>
                                          </td>
                                 

                                        </tr>
                                      ):null}
                                        </>
                                        : null}
                                  {(adminType === 1 || adminInfo?.permissions.some(data => 
                                   data.module === "All Users" && data.actions.includes(3))) ? ( 
                                      <tr>
                                        <td>
                                          {userDetails.acBlock === 1 ? 'Password Block' :
                                            userDetails.acBlock === 2 ? 'OTP Block' :
                                              'Not Blocked'}
                                        </td>
                                        <td>
                                          <select
                                            onChange={(e) => handlePasswordCount(e.target.value)}
                                            // onChange={handleLoginChange}
                                            className="form-select"
                                            id="autoSizingSelect"
                                            value={userDetails.loginAttempts}
                                          >
                                            <option value="" disabled>Select attempts</option>
                                            <option value={0}>0</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                          </select>
                                        </td>
                                      </tr> 
                                   ):null}




                                      {/* <tr>
                                        <td>Withdraw OTP Attempts</td>
                                        <td>
                                          <select
                                            onChange={(e) => handleWithdrawOtpCount(e.target.value)}
                                            // onChange={handleWithChange}
                                            className="form-select"
                                            id="autoSizingSelect"
                                            // value={withdrawAtmResponse.withcountAtm?.withdrawOtpCount}
                                            value={userDetails.withcountAtm?.withdrawOtpCount}
                                          >
                                            <option value="" disabled>Select attempts</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                          </select>
                                        </td>
                                      </tr> */}
                                  {
                                  (adminType === 1 || adminInfo?.permissions.some(data => 
                                   data.module === "All Users" && data.actions.includes(3))) ? ( 
                                  
                                    <tr>
                                    <td>Withdraw OTP Attempts</td>
                                    <td>
                                      <select
                                        onChange={(e) => handleWithdrawOtpCount(e.target.value)} 
                                        id="autoSizingSelect"
                                        className="form-select"
                                        value={getWithOtpCount}
                                        // value={userDetails?.withdrawMaxCount}
                                         >
                                        <option value={''}>Select attempts</option> 
                                        <option value={4}>Blocked</option> 
                                        <option value={0}>Unblocked</option> 
                                      </select>
                                    </td>
                                  </tr>
                                   ) :null}

                                      <tr>
                                      <td>Registered Date & Time</td>
                                      <td>{utcToNormalTime(userDetails?.createdAt).date} {utcToNormalTime(userDetails?.createdAt).time || '-'}</td>
                                    </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>
                          )}

                          <CardText className="mb-0"></CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    
                    <TabPane tabId="2" id="profile">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <UserBalance />
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3" id="messages">
                      <Row>
                        <Col sm="12" className="d-lg-flex">
                          <Card className="col-lg-12">
                            <CardBody>
                        
                              <UserBankView bankData={bankDetails} />

                            </CardBody>
                          </Card>

                        </Col>
                      </Row>
                    </TabPane>
                    <TabContent activeTab={activeTab}>
                    <TabPane tabId="4" id="settings">
                      <Row>
                        <Col sm="12" className="d-lg-flex">
                          <Card className="col-lg-12">
                            <CardBody>
                              <UserLoginActivities tabId={activeTab}/>
                            </CardBody>
                          </Card>

                        </Col>
                      </Row>
                    </TabPane>
                    </TabContent>
                    <TabPane tabId="5" id="settings">
                      <Row>

                        <Table responsive striped className="table-nowrap mb-0" >
                          {kycTab ? <UserKYC id={id} /> :
                            <tbody>
                              <tr>
                                <td>Name</td>
                                <td>{userDetails && userDetails.fullName ? userDetails.fullName : "-"}</td>
                              </tr>
                              <tr>
                                <td>Email</td>
                                <td>{userDetails && userDetails.email ? userDetails.email : "-"}</td>
                              </tr>
                              <tr>
                                <td>KYC Status</td>
                                <td>{kycDetails ? getKycStatusText(kycDetails.kycStatus) : "-"}</td>
                              </tr>
                              <tr>
                                <td>Country</td>
                                <td>{userDetails && userDetails.country ? userDetails.country : "-"}</td>
                              </tr>
                              {kycDetails?.indianClient ? (
                                <>
                                  {/* Indian User Details */}


                                  <tr>
                                    <td>Aadhar Status</td>
                                    <td>{getKycStatusText(kycDetails.indianClient?.aadharDetails.aadharStatus)}</td>
                                  </tr>
                                  <tr>
                                    <td>Aadhar Number</td>
                                    <td>{kycDetails.indianClient.aadharDetails.aadhaarNumber}</td>
                                  </tr>
                                  <tr>
                                    <td>Aadhar Front Image</td>
                                    <td>
                                      {kycDetails.indianClient.aadharDetails?.aadharFrontImage && (
                                        <img
                                          className="rounded-circle header-profile-user"
                                          onClick={() => tog_xlarge(kycDetails.indianClient.aadharDetails.aadharFrontImage)}
                                          src={kycDetails.indianClient.aadharDetails.aadharFrontImage}
                                          alt="Aadhar Front"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Aadhar Back Image</td>
                                    <td>
                                      {kycDetails.indianClient.aadharDetails?.aadharBackImage && (
                                        <img
                                          className="rounded-circle header-profile-user"
                                          onClick={() => tog_xlarge(kycDetails.indianClient.aadharDetails.aadharBackImage)}
                                          src={kycDetails.indianClient.aadharDetails.aadharBackImage}
                                          alt="Aadhar Front"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Pan Status</td>
                                    <td>{getKycStatusText(kycDetails.indianClient?.panDetails.panStatus)}</td>
                                  </tr>
                                  <tr>
                                    <td>Pan Number</td>
                                    <td>{kycDetails.indianClient.panDetails?.panNumber}</td>
                                  </tr>
                                  
                                  <tr>
                                    <td>Pan Card Image Front</td>
                                    <td>
                                      {kycDetails.indianClient.panDetails?.panFrontImage && (
                                        <img
                                          className="rounded-circle header-profile-user"
                                          onClick={() => tog_xlarge(kycDetails.indianClient.panDetails.panFrontImage)}
                                          src={kycDetails.indianClient.panDetails.panFrontImage}
                                          alt="Pan Card"
                                        />
                                      )}
                                    </td>

                                  </tr>
                                  <tr>
                                    <td>Pan Card Image Back</td>
                                    <td>
                                      {kycDetails.indianClient.panDetails?.panBackImage && (
                                        <img
                                          className="rounded-circle header-profile-user"
                                          onClick={() => tog_xlarge(kycDetails.indianClient.panDetails.panBackImage)}
                                          src={kycDetails.indianClient.panDetails.panBackImage}
                                          alt="Pan Card"
                                        />
                                      )}
                                    </td>

                                  </tr>
                                  <tr>
                                    <td>Profile Status</td>
                                    <td>{getKycStatusText(kycDetails.indianClient?.selfiePics.profileStatus)}</td>
                                  </tr>
                                  <tr>
                                    <td>Selfie Image</td>
                                    <td>
                                      {kycDetails.indianClient.selfiePics?.selfieImage && (
                                        <img
                                          className="rounded-circle header-profile-user"
                                          onClick={() => tog_xlarge(kycDetails.indianClient.selfiePics.selfieImage)}
                                          src={kycDetails.indianClient.selfiePics.selfieImage}
                                          alt="Selfie"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ) : kycDetails?.foreignClient ? (
                                <>
                                  {/* Foreign User Details */}
                                  <tr>
                                    <td>National ID Status</td>
                                    <td>{getKycStatusText(kycDetails.foreignClient?.nationalDetails.nationalStatus)}</td>
                                  </tr>
                                  <tr>
                                    <td>National ID Number</td>
                                    <td>{kycDetails.foreignClient.nationalDetails?.nationalIdNumber}</td>
                                  </tr>
                                  <tr>
                                    <td>National ID Card Front Image</td>
                                    <td>
                                      {kycDetails.foreignClient.nationalDetails?.nationalIdCardFrontImage && (
                                        <img
                                          className="rounded-circle header-profile-user"
                                          onClick={() => tog_xlarge(kycDetails.foreignClient.nationalDetails.nationalIdCardFrontImage)}
                                          src={kycDetails.foreignClient.nationalDetails.nationalIdCardFrontImage}
                                          alt="National ID Card Front"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>National ID Card Back Image</td>
                                    <td>
                                      {kycDetails.foreignClient.nationalDetails?.nationalIdCardBackImage && (
                                        <img
                                          className="rounded-circle header-profile-user"
                                          onClick={() => tog_xlarge(kycDetails.foreignClient.nationalDetails.nationalIdCardBackImage)}
                                          src={kycDetails.foreignClient.nationalDetails.nationalIdCardBackImage}
                                          alt="National ID Card Back"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Driving Licence Status</td>
                                    <td>{getKycStatusText(kycDetails.foreignClient?.drivingDetails.drivingStatus)}</td>
                                  </tr>
                                  <tr>
                                    <td>Driving Licence Number</td>
                                    <td>{kycDetails.foreignClient.drivingDetails?.drivingLicenseNo}</td>
                                  </tr>
                                  <tr>
                                    <td>Driving License Front Image</td>
                                    <td>
                                      {kycDetails.foreignClient.drivingDetails?.drivingLicenseFrontImage && (
                                        <img
                                          className="rounded-circle header-profile-user"
                                          onClick={() => tog_xlarge(kycDetails.foreignClient.drivingDetails.drivingLicenseFrontImage)}
                                          src={kycDetails.foreignClient.drivingDetails.drivingLicenseFrontImage}
                                          alt="Driving License Front"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Driving License Back Image</td>
                                    <td>
                                      {kycDetails.foreignClient.drivingDetails?.drivingLicenseBackImage && (
                                        <img
                                          className="rounded-circle header-profile-user"
                                          onClick={() => tog_xlarge(kycDetails.foreignClient.drivingDetails.drivingLicenseBackImage)}
                                          src={kycDetails.foreignClient.drivingDetails.drivingLicenseBackImage}
                                          alt="Driving License Back"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Profile Status</td>
                                    <td>{getKycStatusText(kycDetails.foreignClient?.selfiePics.profileStatus)}</td>
                                  </tr>
                                  <tr>
                                    <td>Selfie Image</td>
                                    <td>
                                      {kycDetails.foreignClient.selfiePics?.selfieImage && (
                                        <img
                                          className="rounded-circle header-profile-user"
                                          onClick={() => tog_xlarge(kycDetails.foreignClient.selfiePics.selfieImage)}
                                          src={kycDetails.foreignClient.selfiePics.selfieImage}
                                          alt="Selfie"
                                        />
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <tr>
                                  <td colSpan="2">No KYC Details Available</td>
                                </tr>
                              )}
                            </tbody>
                          }

                        </Table>


                      </Row>
                    </TabPane>

                    <TabPane tabId="9" id="settings">
                      <Row>
                       <UserKYCsumsub id={id}/>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6" id="settings">
                      <Row>
                        <Col sm="12" className="d-lg-flex">
                          <Card className="col-lg-12">
                            <CardBody>
                              <UserRefferalCommission getReferralList={getReferralList} refLoad={refLoad} />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="7" id="settings">
                      <Row>
                        <Col sm="12" className="d-lg-flex">
                          <Card className="col-lg-12">
                            <CardBody>
                              <UserRefferalUser getReferralList={getReferralUser} id={id} />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="8" id="settings">
                      <Row>
                        <Col sm="12" className="d-lg-flex">
                          <Card className="col-lg-12">
                            <UserNotes reasonData={getReason} />
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="10" id="settings">
                      <Row>
                        <Col sm="12" className="d-lg-flex">
                          <Card className="col-lg-12">
                            <UserAddress tabId={activeTab}/>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    {/* <TabPane tabId="11" id="settings">
                      <Row>
                        <Col sm="12" className="d-lg-flex">
                          <Card className="col-lg-12">
                            <UserRefferalten tabId={activeTab}/>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane> */}
                  </TabContent>
                  {/* <TabContent activeTab={activeTab}>
              <TabPane tabId="12">
              <OwnStakeHistory tabId={activeTab}/>
              </TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
              <TabPane tabId="13">
              <OthersStakeHistory tabId={activeTab}/>
              </TabPane>
              </TabContent> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        size="l"
        isOpen={modal_xlarge}
        toggle={() => {
          tog_xlarge();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Profile Image
          </h5>
          <button
            onClick={() => {
              setmodal_xlarge(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body d-flex justify-content-center">
          <img
            style={{ width: "450px", borderRadius: "10px" }}
            src={fullImage}
            alt=""
          />
        </div>
      </Modal>

      <Modal
        isOpen={modelCount}
        className="modal zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <h4>Login Count</h4>
          <Button
            type="button"
            onClick={() => setmodalCount(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>

        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-3 mb-0">
                Are you Sure You want to Update The User Login Count
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodalCount(false)}
            >
              Close
            </button>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="submit"
              disabled={loading}
              onClick={handleUpdateCount}
            >
              {loading ? <Loader /> : "Yes, Change It!"}
            </button>
          </div>
        </ModalBody>
      </Modal>



    </React.Fragment>
  );
};

export default UserDetails;

const LatestTransationData = [
    {
        id: "customCheck1",
        clientId: "#AP1234",
        clientName: "David Wiley",
        date: "02/01/2019",
        price: "1234",
        quantity: "1",
        color: "success",
        status: "Confirm"
    },
    {
        id: "customCheck2",
        clientId: "#AP1235",
        clientName: "Walter Jones",
        date: "04 Nov, 2019",
        price: "822",
        quantity: "2",
        color: "success",
        status: "Confirm"
    },
    {
        id: "customCheck3",
        clientId: "#AP1236",
        clientName: "Eric Ryder",
        date: "	05 Nov, 2019",
        price: "1153",
        quantity: "1",
        color: "danger",
        status: "Cancel"
    },
    {
        id: "customCheck4",
        clientId: "#AP1237",
        clientName: "Kenneth Jackson",
        date: "06 Nov, 2019",
        price: "1365",
        quantity: "1",
        color: "success",
        status: "Confirm"
    },
    {
        id: "customCheck5",
        clientId: "#AP1238",
        clientName: "Ronnie Spiller",
        date: "08 Nov, 2019",
        price: "740",
        quantity: "2",
        color: "warning",
        status: "Pending"
    },
    {
        id: "customCheck1",
        clientId: "#AP1234",
        clientName: "David Wiley",
        date: "02/01/2019",
        price: "1234",
        quantity: "1",
        color: "success",
        status: "Confirm"
    },
    {
        id: "customCheck2",
        clientId: "#AP1235",
        clientName: "Walter Jones",
        date: "04 Nov, 2019",
        price: "822",
        quantity: "2",
        color: "success",
        status: "Confirm"
    },
    {
        id: "customCheck3",
        clientId: "#AP1236",
        clientName: "Eric Ryder",
        date: "	05 Nov, 2019",
        price: "1153",
        quantity: "1",
        color: "danger",
        status: "Cancel"
    },
    {
        id: "customCheck4",
        clientId: "#AP1237",
        clientName: "Kenneth Jackson",
        date: "06 Nov, 2019",
        price: "1365",
        quantity: "1",
        color: "success",
        status: "Confirm"
    },
    {
        id: "customCheck5",
        clientId: "#AP1238",
        clientName: "Ronnie Spiller",
        date: "08 Nov, 2019",
        price: "740",
        quantity: "2",
        color: "warning",
        status: "Pending"
    },
];
export {LatestTransationData}
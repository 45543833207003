import DataTable from 'react-data-table-component';
import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, 
    Form } from "reactstrap";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { handleNullValue } from '../../helpers/function';
import Swal from 'sweetalert2';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { AdminContext } from '../../helpers/adminContext';

const AdminWhiteList = () => {
    document.title = "Ultrapro | Admin IP Management";
    const [loading, setLoading] = useState(false)
    const [deleteLoad,setDeleteLoad] = useState(false)
    const [recordToDelete, setRecordToDelete] = useState({});
    const [formData, setFormData] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; 
    const { adminInfo } = useContext(AdminContext);
    const [adminType,setAdminType] = useState('')
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    useEffect(() => {
        ipList()
        setAdminType(adminInfo?.adminType);
    }, [])
    const ipList = async () => {

        try {
            const response = await postData(Apiservices.ADMIN_IP_WHITE_LIST,{})
            if (response.status) {
                setFormData(response.data)
            } else {
            }

        } catch (error) {
        }
    }


    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            ipAddress: '',
        },
        validationSchema: Yup.object({
            ipAddress: Yup.string().required("IP Address is required")
            .matches(
                /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/,
                "Invalid IP address format"
              )
        }),
        onSubmit: (values) => {
            handlePost(values)
        },
    });

    const handlePost = async (data) => {
        setLoading(true)
        const postForm = {
            ipAddress: data.ipAddress,
        }
        try {
            const response = await postData(Apiservices.ADMIN_IP_WHITE_LIST_ADD,postForm)
            if (response.status) {
                ipList()
                setLoading(false)
                toast.success(response.message)
                setmodal_list(false)
                validation.resetForm();


            } else {
                setLoading(false)
                toast.error(response.message)
                validation.resetForm();
            }

        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
            validation.resetForm();
        }
    }
    const [modal_update_list, setmodal_update_list] = useState(false);
    function tog_update_list() {
        setmodal_update_list(!modal_update_list);
    }
    
    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }

  

    const confirmDelete = async () => {
        const postForm={
            ipAddress:recordToDelete.ipAddress
        }
        setDeleteLoad(true)
        try {
            const response = await postData(Apiservices.ADMIN_IP_WHITE_LIST_DELETE,postForm )
            if (response.status) {
                setDeleteLoad(false)
                toast.success(response.message)
                setmodal_delete(false)
                ipList()

            } else {
                toast.error(response.message)
                validation.resetForm();
                setmodal_delete(false)
                setDeleteLoad(false)

            }

        } catch (error) {
            setDeleteLoad(false)
            toast.error("Internal Server Error..Try Again Later")
            setmodal_delete(false)
            validation.resetForm();
        }
    }

    const utcToNormalTime = (utcString) => {
        const date = new Date(utcString);
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`;
        const formattedTime = `${date.getHours()
          .toString()
          .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
            .getSeconds()
            .toString()
            .padStart(2, "0")}`;
        return { date: formattedDate, time: formattedTime };
      };

    const [searchTerm, setSearchTerm] = useState("")

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const [dateRange, setDateRange] = useState([null, null]); 
    const handleDateChange = (selectedDates) => {
     setDateRange(selectedDates);
 };
 
    const filteredData = formData.filter(item => {
        const itemDate = new Date(item.createdAt); 
        const [startDate, endDate] = dateRange;
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;
        if (end) {  
            end.setHours(23, 59, 59, 999);  
        }
        const isValidItemDate = !isNaN(itemDate.getTime());
        const isDateInRange = (!start || (isValidItemDate && itemDate >= start)) && (!end || (isValidItemDate && itemDate <= end));
        const formattedDate = item.createdAt ? utcToNormalTime(item.createdAt).date : '';
        const isIPMatch = item.ipAddress && item.ipAddress.toLowerCase().includes(searchTerm.toLowerCase());
        const isReasonMatch = item.reason && item.reason.toLowerCase().includes(searchTerm.toLowerCase());
        const isAdminEmailMatch = item.addedBy && item.addedBy.toLowerCase().includes(searchTerm.toLowerCase());
        const isDateMatch = formattedDate && formattedDate.includes(searchTerm);
        const isIDMatch = item._id && item._id.includes(searchTerm);

        const isSearchMatch = isIPMatch || isIDMatch || isReasonMatch || isDateMatch || isAdminEmailMatch;
        return isSearchMatch && isDateInRange; 
    });

 
    const pageCount = Math.ceil(filteredData.length / itemsPerPage);
    const validCurrentPage = currentPage >= pageCount ? 0 : currentPage;
    const displayedData = filteredData.slice(
        validCurrentPage * itemsPerPage,
        (validCurrentPage + 1) * itemsPerPage
    );
  

    const allowedActions = [1,2,3,4];
    const columns = [
        {
            name: 'S.No',
            selector:(row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true,
            grow:1
        },
        {
            name: 'Added By',
            selector: row =>row.addedBy,
            sortable: true,
            grow:3

        },
        {
            name: 'IP Address',
            selector: row => row.ipAddress,
            sortable: true,
            grow:2

        },
        ...(adminType === 1 || adminInfo.permissions.some(data => 
            data.module === "Admin IP White List" && allowedActions.some(action => data.actions.includes(action))) ? [
        {
            name: 'Active Status',
            selector: row => (
                <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizemd"
                    checked={row.isActive}
                    onChange={() => handleUpdateStatusClick(row)}
                />
            </div>
            ),
            sortable: true,
            grow:2
        }]:[]),
        {
            name: "Date & Time",
            selector: (row) => `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${(utcToNormalTime(row.createdAt).time)}`,
            sortable: true,
            grow:2

          },
          ...(adminType === 1 || adminInfo.permissions.some(data => 
            data.module === "Admin IP White List" && allowedActions.some(action => data.actions.includes(action))) ? [
        {
            name: 'Action',
            cell: row => (
                <div className="d-flex gap-2">
                    <div className="remove" >
                          {(adminType === 1 || adminInfo.permissions.some(data => 
                        data.module === "Admin IP White List" && data.actions.includes(4))) ? (
                        <span className="mdi mdi-trash-can-outline" onClick={() => handleDeleteClick(row)} style={{fontSize:'15px',cursor:"pointer"}}></span>
                        ):null}
                        </div>
                </div>
            ),
            ignoreRowClick: true,
            grow:2
        }]:[]),
    ];    //delete api call
    const showDeleteAlert = (onConfirm,row) => {
       Swal.fire({
         title: 'Are you sure?',
         text: "You won't be able to recover this once it's deleted!",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, delete it!'
       }).then((result) => {
         if (result.isConfirmed) {
           onConfirm(row);
         }
       });
     };
    const handleDeleteClick = (row) => {
        setRecordToDelete(row)
       showDeleteAlert(handleDelete1,row);
     };
   
      const handleDelete1 = async (rowDetails) => {
        const postForm={
            ipAddress:rowDetails.ipAddress
        }
        setDeleteLoad(true)
        try {
            const response = await postData(Apiservices.ADMIN_IP_WHITE_LIST_DELETE,postForm )
            if (response.status) {
                setDeleteLoad(false)
                // toast.success(response.message)
                Swal.fire(
                    'Deleted !',
                    'The requested record has been deleted',
                    'success'
                  );
                setmodal_delete(false)
                ipList()

            } else {
                toast.error(response.message)
                validation.resetForm();
                setmodal_delete(false)
                setDeleteLoad(false)

            }

        } catch (error) {
            setDeleteLoad(false)
            toast.error("Internal Server Error..Try Again Later")

            setmodal_delete(false)
            

            validation.resetForm();
        }

     };
  //status update api call
const showStatusAlert = (onConfirm, row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to update status!",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!'
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm(row);

      }
    });
  };
  const handleUpdateStatusClick = (row) => {
    showStatusAlert(handleToggle1, row);
  };
  
  const handleToggle1 = async (rowDetails) => {
    const updatedStatus1 = rowDetails.isActive === 1 ? 0 : 1; //
    
    const postForm={
        ipAddress:rowDetails.ipAddress,
        isActive:updatedStatus1
        
    }
   
    try {
        const response = await postData(Apiservices.ADMIN_IP_WHITE_LIST_EDIT,postForm )
        if (response.status) {
        Swal.fire('Success !', 'The status has been updated successfully', 'success');
            ipList()
        } else {
            toast.error(response.message) 
        }

    } catch (error) {
       toast.dismiss()
        toast.error("Internal Server Error..Try Again Later")
       
    }
  };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Admin Management" breadcrumbItem="Admin IP White List" />
                    <Row>
                        <Col lg={12}>

                            <Card>


                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-4">

                                                <form className="app-search d-block">
                                                    <div className="position-relative">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search by Added By or Ip Address"
                                                            onChange={handleInputChange}
                                                        />
                                                        <span className="ri-search-line"></span>
                                                    </div>
                                                </form>

                                            </Col>
                                            <Col className="col-sm-4">

                                                <form className="d-block mt-3">
                                                    <div className="position-relative">
                                                    <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="Select Start to End Date"
                                                            options={{
                                                                mode: 'range',
                                                                altInput: true,
                                                                altFormat: "F j, Y",
                                                                dateFormat: "Y-m-d",
                                                                maxDate: new Date(),
                                                            }}
                                                            value={dateRange}
                                                            onChange={handleDateChange}
                                                        />
                                                    </div>
                                                </form>

                                                </Col>
                                            <Col className="col-sm">

                                                <div className="d-flex justify-content-sm-end">
                                                {(adminType === 1 || adminInfo.permissions.some(data => 
                                                    data.module === "Admin IP White List" && data.actions.includes(1))) ? (
                                                    <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                                                    ):null}
                                                    {/* <Button color="info" className="add-btn mx-2" onClick={handleDownload} id="create-btn"><i className="mdi mdi-download-outline"></i> Download</Button> */}

                                                    {/* <Button color="soft-danger"
                                                       
                                                    ><i style={{ fontSize: '15px' }} className="mdi mdi-download-circle-outline"></i>Download</Button> */}

                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                    
                                             <DataTable
                                                columns={columns}
                                                data={displayedData}
                                                paginationServer
                                                paginationTotalRows={filteredData.length}
                                                // noDataComponent={<div>No records found</div>}
                                                fixedHeader
                                                progressPending={loading}
                                                progressComponent={<div>Loading...</div>}
                                                persistTableHead={true}
                                                noDataComponent={
                                                  <div className="mt-2">No records found</div>
                                                }
                                            
                                            />

                                            
                                        
                                           
                                           
                                        
                                        </div>
                                     

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                
                                                {displayedData.length > 0 ? (
                                                 



                                                        <div className="pagination pagination-rounded mb-0">
                                                            <ReactPaginate
                                                                previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                                nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageClick}
                                                                containerClassName={"pagination"}
                                                                activeClassName={"active"}
                                                                pageLinkClassName={"page-link"}
                                                                breakLinkClassName={"break-link"}
                                                                disabledClassName={"disabled"}
                                                                initialPage={currentPage}
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                                // disableInitialCallback={true}
                                                                renderLink={(props) => {
                                                                    if (
                                                                        props.page === "..." &&
                                                                        props.pageCount > props.pageRangeDisplayed
                                                                    ) {
                                                                        return (
                                                                            <a
                                                                                {...props}
                                                                                onClick={() =>
                                                                                    handlePageClick({
                                                                                        selected: pageCount - 1,
                                                                                    })
                                                                                }
                                                                            >
                                                                                {pageCount}
                                                                            </a>
                                                                        );
                                                                    }
                                                                    return <a {...props} />;
                                                                }}
                                                            />
                                                        </div>
                                                
                                                ):("")}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>





                </Container>
            </div>

{/* Update */}
<Modal isOpen={modal_update_list} toggle={() => { tog_update_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}> Update IP </ModalHeader>
                <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false
                    }}
                >
                    <ModalBody>


                        <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">IP Address</label>
                            <Input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter IP Address"
                                name="ipAddress"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.ipAddress || ""}
                                invalid={validation.touched.ipAddress && validation.errors.ipAddress}
                            />
                            {validation.touched.ipAddress && validation.errors.ipAddress && (
                                <FormFeedback type="invalid">{validation.errors.ipAddress}</FormFeedback>
                            )}
                        </div>

               





                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                            {/* <button  type="submit" className="btn btn-success" id="add-btn">Add Customer</button> */}
                            <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <Loader/>
                                
                                ) : ("Submit")}

                            </button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>


            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add IP </ModalHeader>
                <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false
                    }}
                >
                    <ModalBody>


                        <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">IP Address</label>
                            <Input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter IP Address"
                                name="ipAddress"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.ipAddress || ""}
                                invalid={validation.touched.ipAddress && validation.errors.ipAddress}
                            />
                            {validation.touched.ipAddress && validation.errors.ipAddress && (
                                <FormFeedback type="invalid">{validation.errors.ipAddress}</FormFeedback>
                            )}
                        </div>

               





                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                            {/* <button  type="submit" className="btn btn-success" id="add-btn">Add Customer</button> */}
                            <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <Loader/>
                                
                                ) : ("Submit")}

                            </button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={tog_delete} className="modal zoomIn" id="deleteRecordModal" centered>
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-3 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                onClick={confirmDelete}
                                disabled={deleteLoad}
                            >
                                {deleteLoad ? (
                                    <Loader/>
                                ) : ("Yes, Delete It!")}

                            </button>
                    </div>
                </ModalBody>
            </Modal>


        </React.Fragment>
    );
};

export default AdminWhiteList;

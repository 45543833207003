import React, { useState, useEffect, useContext, useCallback  } from "react";
import { Button, Input, Card, CardBody, Col, ModalHeader, ModalFooter, Tooltip, FormFeedback, Form, 
Container, Row, Spinner, InputGroup, Modal, ModalBody } from "reactstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import * as Yup from "yup";
import axiosInstance, { postData } from "../../../ApiService/AxiosInstance";
import { Apiservices } from "../../../ApiService/ApiServices";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue, utcToNormalTime,getBadgeKYCClass,getKycStatusText,getStatusText,getBadgeClass} from "../../../helpers/function";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import country from "../../../helpers/CountryCodes.json";
import { AdminContext } from "../../../helpers/adminContext";


const UserList = () => {
  const { adminInfo } = useContext(AdminContext);
  document.title = "Ultrapro | User Management";
  const navigate = useNavigate();
  const location = useLocation();
  const today = new Date();
  const userApi = location.state?.user;
  const [dateRange, setDateRange] = useState([null, null]);
  const [downloadStartDate, setDownloadEndDate] = useState({});
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedKyc,setSelectedKyc] = useState('')
  const [selectedOsType, setSelectedOsType] = useState();
  const [getId, setId] = useState("");
  const [getWithdrawStatus, setWithdrawStatus] = useState("");
  const [getdepositStatus, setDepositStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [downLoading, setDownLoading] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const [searchValid, setSearchValid] = useState(false);
  const [inputSearch, setinputSearch] = useState("");
  const [formData, setFormData] = useState([]);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [count, setCount] = useState("");
  const [user, setUser] = useState(userApi);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState("All Users");
  const [selectedItemStake, setSelectedItemStake] = useState("");
  const itemsPerPage = totalLimit; 
  const pageCount = Math.ceil(totalPagination);

  const [modal_delete, setmodal_delete] = useState(false);
  const [modal_toggle, setmodal_toggle] = useState(false);
  const [modal_pro_toggle, setPromodal] = useState(false);
  const [modalwith_toggle, setwithdraw_toggle] = useState(false);

  const [modelSelect, setmodalSelect] = useState(false);
  const [modelCount, setmodalCount] = useState(false);
  const [FileName, setFileName] = useState("AllUsers");
  const [modal_update_list, setmodal_update_list] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const [tooltipOpen4, setTooltipOpen4] = useState(false);
  const [tooltipOpen5, setTooltipOpen5] = useState(false);
  const [tooltipOpen_tally, setTooltipOpen_tally] = useState(false);
  const [tooltipOpen_userOrder, setTooltipOpen_userOrder] = useState(false);
  const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1);
  const toggleTooltip2 = () => setTooltipOpen2(!tooltipOpen2);
  const toggleTooltip3 = () => setTooltipOpen3(!tooltipOpen3);
  const toggleTooltip4 = () => setTooltipOpen4(!tooltipOpen4);
  const toggleTooltip5 = () => setTooltipOpen5(!tooltipOpen5);
  const toggleTooltip_tally = () => setTooltipOpen_tally(!tooltipOpen_tally);
  const toggleTooltip_userOrder = () => setTooltipOpen_userOrder(!tooltipOpen_userOrder);
  const [adminType,setAdminType] = useState('')
  const [resend, setResend] = useState({
    api: "",
    row: {},
    userStatus: null,
  });

  useEffect(() => {
    if (user === undefined) {
      setUser(Apiservices.USER_LIST);
      ipList();
        setAdminType(adminInfo?.adminType)
    }
  }, [user,adminInfo?.adminType]);


//user list api call
  const ipList = async () => {
    setLoading(true);

    try {
      const response = await postData(Apiservices.USER_LIST);  
      console.log(response,"2222222222222222222222222")  
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {

      toast.error("Internal Server Error...");
      setLoading(false);
    }
  };
  //redirect user details page
  const handleNav = async (record) => {
    navigate(`/allUsers/userDetails/${record.acId}`);
  };
//search onchange 
  const handleSearchChange = (event) => {
    // const value = event.target.value.replace(/\s+/g, ''); // Clean up spaces
    const value = event.target.value // Clean up spaces
    setSearchTerm(value);
      if (value === '') {
      ipList()  // Load default data when search is cleared
  }
  };

  const handleFilter = useCallback(async (apiName) => {
    setLoading(true);
    const postForm = {};
    if (inputSearch) {
      postForm.search = inputSearch.trim();
    }
 
    if (selectedCountry) {
      postForm.country = selectedCountry;
    }
    if (selectedOsType) {
      postForm.osType = selectedOsType;
    }
    if (selectedKyc) {
      postForm.kycStatus = selectedKyc;
    }
    
    if (dateRange[0] && dateRange[1]) {
      postForm.startDate = formatDate(dateRange[0]);
      postForm.endDate = formatDate(dateRange[1]);
    }
    if (selectedItemStake) {
      postForm.isStacked = Number(selectedItemStake)
    }
    try {
   
        const response = await postData(apiName,postForm);
        if (response.status) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          ipList()
          setLoading(false);
          toast.error(response.message);
        }
      
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  }, [inputSearch, selectedCountry,selectedItemStake, selectedOsType, dateRange]);
  

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };


 // Function to handle pagination and search
const handlePagePost = async (selected) => {
  setLoading(true);
  const postForm = {
    page: selected + 1,
    search: searchTerm || "",  
  };

  if (selectedCountry) {
    postForm.country = selectedCountry;
  }
  if (selectedOsType) {
    postForm.osType = selectedOsType;
  }
  if (selectedKyc) {
    postForm.kycStatus = Number(selectedKyc)
  }
  if (selectedItemStake) {
    postForm.isStacked = Number(selectedItemStake)
  }
 
  if (dateRange[0] && dateRange[1]) {
    postForm.startDate = formatDate(dateRange[0]);
    postForm.endDate = formatDate(dateRange[1]);
  }
  
  try {
    const apiUrl = user === undefined ? Apiservices.USER_LIST : user;
    const response = await postData(apiUrl, postForm);
    console.log(response,"111111111111111111111111111111")
    if (response.status) {
  
      setFormData(response.data.data);
      setTotalPagination(response.data.totalPages);
      setTotalLimit(response.data.limit);
      setCount(response.data.currentCount);
    } else {
      toast.error("Search failed, please try again.");
    }
  } catch (error) {
    toast.error("Internal Server Error..Try Again Later");
  } finally {
    setLoading(false);
  }
};
 //reason form 
 const validationUpdate = useFormik({
  enableReinitialize: true,
  initialValues: {
    reason: "",
  },
  validationSchema: Yup.object({
    reason: Yup.string().required("Reason is required"),
  }),
  onSubmit: (values) => {
    handleToggle(values);
  },
});

  const handleToggle = async (data) => {
    const postForm = {
      acId: resend.row.acId,
      withdrawActive: resend.row.withdrawStatus,
      isActive: resend.isActive,
      acBlock: resend.userStatus,
      depositStatus: resend.row.depositStatus,
      reason: data.reason,
    };

    setLoading(true);
    try {
      const response = await postData(resend.api, postForm);
      if (response.status) {

        setmodalSelect(false);
        ipList();
        toast.dismiss();
        setLoading(false);
        toast.success(response.message);
      } else {

        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };


  // tally page redirect
  const handleTallyRedirect = async (userData) => {
    let userId = userData?.acId
    if(userId){
        // navigate(`/tallyDetails/${userId}`)
        window.open(`/tallyDetails/${userId}`, '_blank');
    }
  }


  // user Order List page redirect
  const handleUserOrderRedirect = async (userData) => {
    let userId = userData?.acId
    if(userId){
        window.open(`/userOrderlist/${userId}`, '_blank');
    }
  }
//users status based on condition
// isActive=0 && acBlock=0 => 0 pending
// isActive=1 && acBlock=0 => 1 verified
// isActive=2 || acBlock=1 => 2 Blocked
  const allowedActions = [1,2,3,4];
  const getStatus = (isActive, acBlock) => {
    if (isActive === 0 && acBlock === 0) {
      return "Pending";
    } else if (isActive === 1 && acBlock === 0) {
      return "Verified";
    } else if (isActive === 2 || acBlock === 1) {
      return "Blocked";
    } else {
      return "Unknown";
    }
  };
// const getBadgeClass = (status) => {
//   switch (status) {
//     case "Pending":
//       return "badge-soft-warning";  
//     case "Verified":
//       return "badge-soft-success";  
//     case "Blocked":
//       return "badge-soft-danger";   
//     default:
//       return "badge-soft-danger"; 
//   }
// };
  const columns = [
    {
      name: "S.No",
      selector: (row, index) => currentPage * itemsPerPage + index + 1,
      sortable: true,
      minWidth: '80px',
    },
    {
      name: "Name",
      selector: (row) => handleNullValue(row.fullName),
      sortable: true,
      minWidth: '200px',
    },
    {
      name: "Email",
      selector: (row) => handleNullValue(row.email),
      sortable: true,
      minWidth: '300px',
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobileNo ? `${row.mobileCode ? `+${row.mobileCode} ` : ''}${row.mobileNo}` : '-',
      sortable: true,
      minWidth: '200px',
    },
    {
      name: "Country",
      selector: (row) => handleNullValue(row.country),
      sortable: true,
      minWidth: '200px',
    },
    {
      name: "Register From",
      selector: (row) => (row.osType == 0 ? 'Web' : 'Android'),
      sortable: true,
      minWidth: '150px',
    },
    {
      name: "KYC Status",
      selector: (row) => (
        <div className="edit">
          <span className={`badge ${getBadgeKYCClass(row.kycStatus)} me-1`}>
            {getKycStatusText(row.kycStatus)}
          </span>
        </div>
      ),
      sortable: true,
      minWidth: '150px',
    },
    // isStacked -1 staked ,0 not staked
    {
      name: "Stake Status",
      selector: (row) => handleNullValue(row.isStacked == 0 ? 'NO':'YES'),
      sortable: true,
      minWidth: '150px',

    },
  
    
    {
      name: "Status",
      selector: (row) => {
        return (
          <div className="edit">
                <span className={`badge ${getBadgeClass(row.status)} me-1`}>
                  {getStatusText(row.status)}
                </span>
          </div>
        );
      },
      sortable: true,
      minWidth: '100px',
    },

   
    // {
    //   name: "Status",
    //   selector: (row) => {
    //     const status = getStatus(row.isActive, row.acBlock);  // Get the status text
    //     return (
    //       <div className="edit">
    //         <span className={`badge ${getBadgeClass(status)} me-1`}>
    //           {status}
    //         </span>
    //       </div>
    //     );
    //   },
    //   sortable: true,
    //   minWidth: '100px',
    // },
    {
      name: "Date & Time",
      selector: (row) => row.createdAt ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} 
      ${utcToNormalTime(row.createdAt).time}`: "---",
      sortable: true,
      minWidth: '200px',
    },
      ...(adminType === 1 || adminInfo?.permissions.some(data => data.module === "All Users" && allowedActions.some(action => data.actions.includes(action))) ? [
          {
        name: "Action",
        cell: (row) => (
          <div className="edit d-flex">
            {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "All Users" && data.actions.includes(3))) ? (
            <div className="form-switch form-switch-sm">
              {row.isActive === 1 ? (
                <input
                  id={`switch-${row.id}`}
                  type="checkbox"
                  className="form-check-input custom-toggle"
                  checked={row.isActive === 1}
                  style={{ cursor: "pointer", fontSize: "15px" }}
                  disabled={loading || row.isActive === 1}
                />
              ) : (
                <input
                  id={`switch-${row.id}`}
                  type="checkbox"
                  className="form-check-input custom-toggle"
                  checked={row.isActive === 1}
                  style={{ cursor: "pointer", fontSize: "15px" }}
                  onChange={() => handleUpdateStatusClick(row)}
                  disabled={loading || row.isActive === 1}
                />
              )}
              <Tooltip placement="top"  isOpen={tooltipOpen5}  target={`switch-${row.id}`} 
              toggle={toggleTooltip5} >User Verification Status
              </Tooltip>
            </div> ) : null} 
      
            {(adminType === 1 || adminInfo?.permissions.some(data =>data.module === "All Users" && data.actions.includes(5))) ? (
              <div>
                <span
                  id={`tooltipEmail${row.id}`}
                  onClick={() => handleResendEmailClick(row)}
                  className="mdi mdi-email-edit-outline"
                  style={{ fontSize: "15px", cursor: "pointer" }}
                ></span>
                <Tooltip placement="top" isOpen={tooltipOpen1} target={`tooltipEmail${row.id}`}
                  toggle={toggleTooltip1}> Resend Email </Tooltip>
              </div>
             ) : null} 
      
            {(adminType === 1 || adminInfo?.permissions.some(data => 
              data.module === "All Users" && data.actions.includes(3))) ? (
              <div>
                <span
                  id={`tooltipOtp${row.id}`}
                  onClick={() => handleResendOTPClick(row)}
                  className="mdi mdi-cellphone-key"
                  style={{ fontSize: "15px", cursor: "pointer" }}
                ></span>
                <Tooltip placement="top"  isOpen={tooltipOpen2}  target={`tooltipOtp${row.id}`} 
                toggle={toggleTooltip2}> Resend OTP </Tooltip>
              </div>
            ) : null}

          
            {(adminType === 1 || adminInfo?.permissions.some(data => 
              data.module === "All Users" && data.actions.includes(2))) ? (
              <div>
                <span
                  id={`tooltipView${row.id}`}
                  onClick={() => handleNav(row)}
                  className="mdi mdi-eye-outline"
                  style={{ fontSize: "15px", cursor: "pointer" }}
                ></span>
                <Tooltip  placement="top"  isOpen={tooltipOpen3}  target={`tooltipView${row.id}`} 
                 toggle={toggleTooltip3}> View Details</Tooltip>
              </div>
             ) : null}  

            
            {(adminType === 1 || adminInfo?.permissions.some(data => 
              data.module === "All Users" && data.actions.includes(3))) ? (
            <div>
              <span
                id={`tooltipEdit${row.id}`}
                onClick={() => handleUpdate(row)} 
                className="mdi mdi-border-color" 
                style={{ fontSize: "15px", cursor: "pointer" }}
              />
              <Tooltip placement="top"  isOpen={tooltipOpen4} 
                target={`tooltipEdit${row.id}`} toggle={toggleTooltip4} >Edit</Tooltip>
            </div>
           ) : null}

 
          {/* tally function added */}
          {(adminType === 1 || adminInfo?.permissions.some(data => 
              data.module === "All Users" && data.actions.includes(3))) ? (
          <div>
            <span id={`tooltipTally${row.id}`} onClick={()=> handleTallyRedirect(row)} className="mdi mdi-note-outline"
              style={{ fontSize: '15px', cursor: 'pointer' }}></span>
            <Tooltip placement="top" isOpen={tooltipOpen_tally} target={`tooltipTally${row.id}`} toggle={toggleTooltip_tally}>Tally
              Withdraw </Tooltip>
          </div>
          ) : null}


          {/* userOrderList function added */}
          {(adminType === 1 || adminInfo?.permissions.some(data => 
              data.module === "All Users" && data.actions.includes(3))) ? (
          <div>
            <span id={`tooltipuserOrder${row.id}`} onClick={()=> handleUserOrderRedirect(row)} className="mdi mdi-file-outline"
              style={{ fontSize: '15px', cursor: 'pointer' }}></span>
            <Tooltip placement="top" isOpen={tooltipOpen_userOrder} target={`tooltipuserOrder${row.id}`} toggle={toggleTooltip_userOrder}>userOrderList</Tooltip>
          </div>
        ) : null}
          </div>
        ),
      }]: [] ),
      
];


//os type,country,kyc,stakefilter
const handleSelect = (value, type) => {
  // Update the selected state based on the type
  if (type === 'os') {
    setSelectedOsType(value);
  } else if (type === 'country') {
    setSelectedCountry(value);
  } 
  else if (type === 'kyc') {
    setSelectedKyc(value);
  } 
  else if (type === 'stake') {
    setSelectedItemStake(value);
  } 
  else {
    ipList();
    return; // Exit early if it's for ipList, no need to call handleApiRequest
  }

  // Prepare and call the API with updated selections
  const postForm = createPostForm(
    type === 'os' ? value : selectedOsType,
    type === 'country' ? value : selectedCountry,
    type === 'kyc' ? value : selectedKyc,
    type === 'stake' ? value : selectedItemStake
  );
  handleApiRequest(postForm, user);
}

const createPostForm = (osType, country,kycStatus,isStacked) => {
  const postForm = {};
  if (downloadStartDate.startDate) postForm.startDate = downloadStartDate.startDate;
  if (downloadStartDate.endDate) postForm.endDate = downloadStartDate.endDate;
  if (inputSearch) postForm.search = inputSearch;
  if (country) postForm.country = country;
  if (osType) postForm.osType = osType;
  if (kycStatus) postForm.kycStatus = kycStatus === '' ? '' : Number(kycStatus);
  if (isStacked) postForm.isStacked = isStacked === '' ? '' : Number(isStacked);
  return postForm;
};

const handleApiRequest = async (postForm, userEndpoint) => {
  setLoading(true);
  try {
    const endpoint = userEndpoint || Apiservices.USER_LIST;
    const response = await postData(endpoint,postForm);
    console.log(response,"5555555555555555555555555555")
    if (response.status) {
      setTotalLimit(response.data.limit);
      setCount(response.data.currentCount);
      setFormData(response.data.data);
      setTotalPagination(response.data.totalPages);
    } else {
      toast.error("Data not found");
    }
  } catch (error) {
    toast.error("Internal Server Error..Try Again Later");
  } finally {
    setLoading(false);
  }
};

  const handleSearch = async () => {
    setSearchLoad(true);
    const postForm = {};
  
    if (inputSearch) {
      postForm.search = searchTerm;
    }
  
    if (selectedCountry) {
      postForm.country = selectedCountry;
    }
  
    if (selectedOsType) {
      postForm.osType = selectedOsType;
    }
  
    if (dateRange[0] && dateRange[1]) {
      postForm.startDate = formatDate(dateRange[0]);
      postForm.endDate = formatDate(dateRange[1]);
    }
    if (selectedItemStake) {
      postForm.isStacked = Number(selectedItemStake)
    }
  
    try {
      const apiUrl = user === undefined ? Apiservices.USER_LIST : user;
      const response = await postData(apiUrl, postForm);
  
      if (response.status) {
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
      } else {
        toast.error("Search failed, please try again.");
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
    } finally {
      setSearchLoad(false);
    }
  };
  
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handlePagePost(0); // Reset to page 0 and trigger search
    }
  };
  

  


  const confirmResend = async () => {
    const postForm = {
      email: resend.row.email,
      acId: resend.row.acId,
    };
    try {
      setLoading(true);
      const response = await postData(resend.api, postForm);
      if (response.status) {
        setLoading(false);
        setmodal_delete(false);
        toast.success(response.message);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleDropdownClick = (item) => {
    setDownloadEndDate({});
    setSelectedItem(item);
    setDateRange([null, null]);
    switch (item) {
      case "Today Users":
        setUser(Apiservices.TODAY_USERS);
        setFileName("TodayUsers");
        handleFilter(Apiservices.TODAY_USERS);
        break;

      case "All Users":
        setUser(Apiservices.USER_LIST);
        handleFilter(Apiservices.USER_LIST);
        setFileName("AllUsers");
        break;
      case "Active Users":
        setUser(Apiservices.USER_VERIFIED_LIST);
        handleFilter(Apiservices.USER_VERIFIED_LIST);
        setFileName("ActiveUsers");

        break;
      case "Pending Users":
        setUser(Apiservices.USER_NOT_VERIFYED_LIST);
        handleFilter(Apiservices.USER_NOT_VERIFYED_LIST);
        setFileName("PendingUsers");

        break;
      case "Blocked Users":
        setUser(Apiservices.USER_BLOCKED_LIST);
        handleFilter(Apiservices.USER_BLOCKED_LIST);
        setFileName("BlockedUsers");
        break;

      
      default:
        break;
    }
  };



  const handleDropdownClick2 = (item) => {
    console.log(item,'--')
    setDownloadEndDate({});
    setSelectedItemStake(Number(item));
    setDateRange([null, null]);
    switch (item) {
      case "":
        setUser(Apiservices.USER_LIST);
        handleFilter(Apiservices.USER_LIST);
        break;
      case 1:
        handleFilter(Apiservices.USER_LIST);
        setUser(Apiservices.USER_LIST);
        setFileName("StakeYes");
        break;
      case 0:
        handleFilter(Apiservices.USER_LIST);
        setUser(Apiservices.USER_LIST);
        setFileName("StakeNo");
        break;

      default:
        break;
    }
  };


  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDateChange = ([start, end]) => {
    const formattedStart = start ? formatDate(start) : null;
    const formattedEnd = end ? formatDate(end) : null;

    setDateRange([start, end]);

    if (!formattedStart && !formattedEnd) {
      handleFilter(user);
      setDownloadEndDate({});
    }

    if (formattedStart && formattedEnd) {
      setDownloadEndDate({
        startDate: formattedStart,
        endDate: formattedEnd,
      });
      
      handleDate({ startDate: formattedStart, endDate: formattedEnd });
    }
  };



  const handleDate = async ({ startDate, endDate }) => {
    setLoading(true);

    const postForm = {
      startDate: startDate,
      endDate: endDate,
    };
    if (inputSearch) {
      postForm.search = inputSearch
    }
    if (selectedCountry) {
      postForm.country = selectedCountry
    }
    if (selectedOsType) {
      postForm.osType = selectedOsType
    }
    if (selectedItemStake) {
      postForm.isStacked = Number(selectedItemStake)
    }
    if (selectedKyc) {
      postForm.kycStatus = Number(selectedKyc)
    }
    try {
    
      const response = await postData(user,postForm);
      if (response) {
      
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleProToggle = async () => {
    const updatedStatus = resend.row.proUsers === 1 ? 0 : 1;

    const postForm = {
      proUsers: updatedStatus,
      acId: resend.row.acId,
    };

    setLoading(true);
    try {
      const response = await postData(
        Apiservices.USER_VERIFY_PRO_TRADERS,
        postForm
      );
      if (response.status) {
        toast.dismiss();
        setLoading(false);
        setPromodal(false);
        toast.success(response.message);
        handleFilter(user);
      } else {

        toast.error(response.message);
        setLoading(false);
        setPromodal(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      setPromodal(false);
    }
  };

  const handleVerifyToggle = async () => {
    const updatedStatus = resend.row.isActive === 1 ? 0 : 1;

    const postForm = {
      userEmailVerify: updatedStatus,
      count: resend.userStatus,
      acId: resend.row.acId,
    };


    setLoading(true);
    try {
      const response = await postData(
        Apiservices.USER_VERIFY_BY_ADMIN,
        postForm
      );
      if (response.status) {
        toast.dismiss();
        setLoading(false);
        setmodal_toggle(false);
        toast.success(response.message);
        handleFilter(user);
      } else {
        toast.error(response.message);
        setLoading(false);
        setmodal_toggle(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      setmodal_toggle(false);
    }
  };

  const handleWithdrawUpdate = async () => {

    const updatedStatus = resend.row.withdrawStatus === 1 ? 0 : 1;

    const postForm = {
      withdrawStatus: updatedStatus,
      depositStatus: resend.row.depositStatus,
      acId: resend.row.acId,
    };

    setLoading(true);
    try {
      const response = await postData(Apiservices.USER_EDIT_LIST, postForm);
      if (response.status) {
        toast.dismiss();
        setLoading(false);
        setwithdraw_toggle(false);
        toast.success(response.message);
        handleFilter(user);
      } else {
        toast.error(response.message);
        setLoading(false);
        setwithdraw_toggle(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      setwithdraw_toggle(false);
    }
  };



  const handleUpdateCount = async (e) => {
    setLoading(true);
    const postForm = {
      acId: resend.row.acId,
      count: resend.userStatus,
    };
    try {
      const response = await postData(Apiservices.ADMIN_LOGIN_COUNT, postForm);

      if (response && response.status) {
        setLoading(false);
        setmodalCount(false);
        toast.success(response.message);
        handlePageClick(user);
      } else {
        setLoading(false);
        setmodalCount(false);
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleDownload = async (format) => {

    setDownLoading(true);
    try {
      const postForm = {};

      if (downloadStartDate.startDate) {
        postForm.startDate = downloadStartDate.startDate;
      }
      if (downloadStartDate.endDate) {
        postForm.endDate = downloadStartDate.endDate;
      }
      if (inputSearch) {
        postForm.search = inputSearch;
      }
      if (selectedCountry) {
        postForm.country = selectedCountry
      }
      if (selectedKyc) {
        postForm.kycStatus = selectedKyc
      }
      postForm.download = true;
      postForm.format = format
      
      const queryString = new URLSearchParams(postForm).toString();

      // const response = await axiosInstance.post(
      //   `${user}?${queryString}`,{},
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     responseType: "blob",
      //   }
      // );
      const hasFilters = Object.keys(postForm).length > 0;

      const response = await axiosInstance.post(
        Apiservices.USER_LIST,
        hasFilters ? postForm : undefined,  // Only send postForm if there are filters
        {
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        }
    );
      if (response.data) {
        setDownLoading(false);
        const blob = response.data;

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${FileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

      } else {
        toast.error(response.data.message);
        setDownLoading(false);
      }
    } catch (err) {
      setDownLoading(false);
      console.error("Error downloading the file", err);
    }
  };

  const showStatusAlertVerification = (onConfirm, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to update user verification status?",
      inputLabel: "Enter a Reason",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off",
      },
      inputPlaceholder: "Enter Reason",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        if (!reason) {
          Swal.showValidationMessage("Reason is required");
        } else {
          Swal.showLoading();
          try {
            await onConfirm(row, reason);
          } catch (error) {
          }
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        
      }
    });
  };

  //user verification toggle
  const handleUpdateStatusClick = (row) => {
    showStatusAlertVerification(handleToggle1, row);
  };

  const handleToggle1 = async (rowDetails, reason) => {
    const updatedStatus = rowDetails.isActive === 1 ? 2 : 1;
    const postForm = {
      userEmailVerify: updatedStatus,
      count: rowDetails.userStatus,
      acId: rowDetails.acId,
      emailVerificationReason: reason,
    };

    try {
      setLoading(true);

      const response = await postData(
        Apiservices.USER_VERIFY_BY_ADMIN,
        postForm
      );
      if (response.status) {
        toast.dismiss();
        setLoading(false);
        Swal.fire("Success!","The user verification status has been updated successfully","success");
        ipList();
      } else {
        setLoading(false);
        Swal.fire("Error!", `${response.message}`, "error");
      }
    } catch (error) {
      toast.dismiss();
      setLoading(false);
      Swal.fire("Error!", `${error.message}`, "error");
    }
  };

  //update Modal
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
  }

  const handleUpdate = (record) => {
    setId(record.acId);
    setDepositStatus(record.depositStatus);
    setWithdrawStatus(record.withdrawStatus);
    UserUpdate.setValues({
      username: record.fullName || "",
      useremail: record.email || "",
      usermobilenumber: record.mobileNo || "",
      country: record.country || "",
    });
    setmodal_update_list(true);
  };
  //update form validation
  const UserUpdate = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      useremail: "",
      usermobilenumber: "",
      country: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Name is required"),
      useremail: Yup.string().required("Email is required"),
      usermobilenumber: Yup.string()
        .required("Mobile Number is required")
        .matches(
          /^[0-9]+$/, // Ensures that the field contains only numbers
          "Mobile number must contain only digits"
        ),
      country: Yup.string().required("Country is required"),
    }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });

  const confirmUpdate = async (values) => {
    const permissions = [{
      module: 'All Users', 
      actions: [2] 
    }];
    
    const postForm = {
      acId: getId,
      withdrawStatus: getWithdrawStatus,
      depositStatus: getdepositStatus,
      fullName: values.username,
      email: values.useremail,
      mobileNo: values.usermobilenumber,
      country: values.country,
      permissions:permissions,
      adminType:adminInfo?.adminType

    };

    try {
      setLoading(true);
      const response = await postData(
        `${Apiservices.USER_EDIT_LIST}`,
        postForm
      );

      if (response.status === true) {
        setmodal_update_list(false);
        toast.success(response.message);
        ipList();
        setLoading(false);
      } else {
        toast.error(response.message);
        ipList();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Internal Server Error..Try Again Later");
      validationUpdate.resetForm();
    }
  };

  const showStatusAlert = (onConfirm, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Resend Email !",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Resend it!",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: () => {
        return onConfirm(row)
          .then(() => {
            return Promise.resolve();
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  };
  const handleResendEmailClick = (row) => {
    showStatusAlert(handleToggleEmail, row);
  };

  const handleToggleEmail = async (rowDetails) => {
    const postForm = {
      acId: rowDetails.acId,
      email: rowDetails.email,
    
    };

    try {
      setLoading(true);
      const response = await postData(
        `${Apiservices.USER_RESEND_MAIL}`,
        postForm
      );


      if (response.status === true) {

        toast.success(response.message);
        setLoading(false);
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Internal Server Error..Try Again Later");
      validationUpdate.resetForm();
    }
  };

  const showResendOtpAlert = (onConfirm, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Resend OTP Mail !",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Resend it!",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: () => {
        return onConfirm(row)
          .then(() => {
            return Promise.resolve();
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        
      }
    });
  };
  const handleResendOTPClick = (row) => {
    showResendOtpAlert(handleToggleOTP, row);
  };

  const handleToggleOTP = async (rowDetails) => {
    const postForm = {
      acId: rowDetails.acId,
      email: rowDetails.email,
    };

    try {
      setLoading(true);
      const response = await postData(
        `${Apiservices.USER_RESEND_OTP}`,
        postForm
      );

      if (response.status === true) {
        toast.success(response.message);
        setLoading(false);
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {

      setLoading(false);
      toast.error("Internal Server Error..Try Again Later");
      validationUpdate.resetForm();
    }
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="User Management" breadcrumbItem="All Users" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                <Row className="mb-3">
                  {/* Top Row with Search, Date Picker, Country Filter, KYC Filter */}
                  <Col sm={4} lg={3}>
                    <div className="input-group">
                      <Input
                        type="text"
                        value={searchTerm}
                        className="form-control"
                        placeholder="Search by Name, Email, Mobile Number"
                        aria-label="Search input"
                        onChange={handleSearchChange}
                        invalid={searchValid}
                        onKeyDown={handleKeyPress}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          // onClick={handleSearchBar}
                          onClick={() => handlePagePost(0)}
                          disabled={searchLoad}
                        >
                          {searchLoad ? (
                            <Spinner className="text-light" size="sm" />
                          ) : (
                            <i className="ri-search-line" />
                          )}
                        </button>
                      </div>
                    </div>
                  </Col>
                  
                  <Col sm={4} lg={3}>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="Select Start to End Date"
                        options={{
                          mode: "range",
                          altInput: true,
                          altFormat: "F j, Y",
                          dateFormat: "Y-m-d",
                          maxDate: today,
                        }}
                        value={dateRange}
                        onChange={handleDateChange}
                      />
                    </InputGroup>
                  </Col>

                  <Col sm={4} lg={3}>
                    <select
                      onChange={(e) => handleSelect(e.target.value, 'country')}
                      className="form-select"
                      value={selectedCountry || 'Select Country'}
                    >
                      <option value="">All Country</option>
                      {country.map((data) => (
                        <option key={data.name} value={data.name}>
                          {data.name}
                        </option>
                      ))}
                    </select>
                  </Col>

                  <Col sm={4} lg={3}>
                    <select
                      className="form-select"
                      value={selectedKyc}
                      onChange={(e) => handleSelect(e.target.value,'kyc')}
                    >
                      <option value="" disabled>Select KYC</option>
                      <option value="">All KYC List</option>
                      <option value={0}>Not Uploaded KYC</option>
                      <option value={3}>Verified KYC</option>
                      <option value={1}>Pending KYC</option>
                      <option value={2}>Rejected KYC</option>
                    </select>
                  </Col>
                </Row>

                <Row className="mb-3">
                  {/* Bottom Row with Stake Filter, User Filter, Download Button */}
                  <Col sm={4} lg={3}>
                    <select
                      className="form-select"
                      value={selectedItemStake}
                      // onChange={(e) => handleDropdownClick2(e.target.value)}
                      onChange={(e) => handleSelect(e.target.value,'stake')}

                    >
                      <option value="" disabled>Select Stake</option>
                      <option value="">All Stake</option>
                      <option value={1}>YES</option>
                      <option value={0}>NO</option>
                    </select>
                  </Col>

                  <Col sm={4} lg={3}>
                    <select
                      className="form-select"
                      value={selectedItem}
                      onChange={(e) => handleDropdownClick(e.target.value)}
                    >
                      <option value="" disabled>Select</option>
                      <option value="All Users">All Users</option>
                      <option value="Today Users">Today Users</option>
                      <option value="Active Users">Active Users</option>
                      <option value="Pending Users">Pending Users</option>
                      <option value="Blocked Users">Blocked Users</option>
                    </select>
                  </Col>

                  <Col sm={4} lg={3}>
                    <Button
                      color="info"
                      className="add-btn"
                      onClick={() => handleDownload('xlsx')}
                      id="create-btn"
                      disabled={downLoading}
                    >
                      <i className="mdi mdi-download-outline"></i>
                      {downLoading ? <Loader /> : "Download"}
                    </Button>
                  </Col>
                </Row>


                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={formData}
                      paginationServer
                      paginationTotalRows={formData.length}
                      progressPending={loading}
                      persistTableHead={true}
                      progressComponent={<div className="py-3">Loading...</div>}
                      noDataComponent={<div className="py-3">No records found</div>}
                      fixedHeader={true}
                    />
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-md-6">
                      <div className="d-flex justify-content-start mt-3">
                        <span>Current Count : {count}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      {loading ? (
                        ""
                      ) : (
                        <div className="d-flex justify-content-end mt-3">
                          <div className="pagination pagination-rounded mb-0">
                            <ReactPaginate
                              previousLabel={<i className="mdi mdi-chevron-left"></i>}
                              nextLabel={<i className="mdi mdi-chevron-right"></i>}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              breakLinkClassName={"break-link"}
                              disabledClassName={"disabled"}
                              initialPage={currentPage}
                              disableInitialCallback={true}
                              forcePage={currentPage}
                              // disableInitialCallback={true}
                              renderLink={(props) => {
                                if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                  return (
                                    <a {...props} onClick={() =>handlePageClick({selected: pageCount - 1})}>
                                      {pageCount}
                                    </a>
                                  );
                                }
                                return <a {...props} aria-label="Link description"/>
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal_toggle}
        className="modal zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <h4>User Verification Status</h4>
          <Button
            type="button"
            onClick={() => setmodal_toggle(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-3 mb-0">
                {" "}
                Are you sure you want to update this user status{" "}
                {resend && resend.row.fullName}?
              </p>
            </div>
          </div>

          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_toggle(false)}
            >
              Close
            </button>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="submit"
              onClick={handleVerifyToggle}
              disabled={loading}
            >
              {loading ? <Loader /> : "Yes"}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalwith_toggle}
        className="modal zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <h4>User Withdraw Status</h4>
          <Button
            type="button"
            onClick={() => setwithdraw_toggle(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-3 mb-0">
                {" "}
                Are you sure you want to update this user status{" "}
                {resend && resend.row.fullName}?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setwithdraw_toggle(false)}
            >
              Close
            </button>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="submit"
              onClick={handleWithdrawUpdate}
              disabled={loading}
            >
              {loading ? <Loader /> : "Yes"}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modal_pro_toggle}
        className="modal zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <h4>Pro User</h4>
          <Button
            type="button"
            onClick={() => setPromodal(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-3 mb-0">
                {" "}
                Are you sure you want to update this user status,{" "}
                {resend && resend.row.fullName}?
              </p>
            </div>
          </div>

          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setPromodal(false)}
            >
              Close
            </button>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="submit"
              onClick={handleProToggle}
              disabled={loading}
            >
              {loading ? <Loader /> : "Yes"}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modal_delete}
        className="modal zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <h4> Resend Mail</h4>
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
              aria-label="Link description"
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-3 mb-0">
                {" "}
                Are you sure you want to resend mail to this user{" "}
                {resend && resend.row.fullName} ?
              </p>
            </div>
          </div>

          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="submit"
              onClick={confirmResend}
              disabled={loading}
            >
              {loading ? <Loader /> : "Yes, Resend It!"}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modelCount}
        className="modal zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodalCount(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>

        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-3 mb-0">
                Are you Sure You want to Update The User Login Count
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodalCount(false)}
            >
              Close
            </button>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="submit"
              disabled={loading}
              onClick={handleUpdateCount}
            >
              {loading ? <Loader /> : "Yes, Change It!"}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modelSelect}
        className="modal zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodalSelect(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <Form
          autoComplete="off"
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validationUpdate.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <div className="mt-2 text-center">
              <lord-icon
                src="https://cdn.lordicon.com/gsqxdxog.json"
                trigger="loop"
                colors="primary:#f7b84b,secondary:#f06548"
                style={{ width: "100px", height: "100px" }}
              ></lord-icon>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you Sure ?</h4>
                <p className="text-muted mx-3 mb-0">
                  Are you Sure You want to Update The User Status{" "}
                </p>
              </div>
              <div className="mb-3">
                <Input
                  type="text"
                  id="customername-field"
                  className="form-control"
                  placeholder="Reason"
                  name="reason"
                  onChange={validationUpdate.handleChange}
                  onBlur={validationUpdate.handleBlur}
                  value={validationUpdate.values.reason || ""}
                  invalid={
                    validationUpdate.touched.reason &&
                    validationUpdate.errors.reason
                  }
                />
                {validationUpdate.touched.reason &&
                  validationUpdate.errors.reason && (
                    <FormFeedback type="invalid">
                      {validationUpdate.errors.reason}
                    </FormFeedback>
                  )}
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button
                type="button"
                className="btn w-sm btn-light"
                onClick={() => setmodalSelect(false)}
              >
                Close
              </button>
              <button
                className="btn btn-primary waves-effect waves-light"
                type="submit"
                disabled={loading}
              >
                {loading ? <Loader /> : "Yes, Change It!"}
              </button>
            </div>
          </ModalBody>
        </Form>
      </Modal>

      <Modal
        className="modal-dialog modal-lg"
        isOpen={modal_update_list}
        toggle={() => {
          tog_update_list();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            tog_update_list();
          }}
        >
          Update User Details
        </ModalHeader>
        <Form
          autoComplete="off"
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            UserUpdate.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="title-field" className="form-label">
                    Name
                  </label>
                  <Input
                    type="text"
                    id="sessionname-field"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Enter Name"
                    name="username"
                    onChange={UserUpdate.handleChange}
                    onBlur={UserUpdate.handleBlur}
                    value={UserUpdate.values.username || ""}
                    invalid={
                      UserUpdate.touched.username && UserUpdate.errors.username
                    }
                  />
                  {UserUpdate.touched.username &&
                    UserUpdate.errors.username && (
                      <FormFeedback type="invalid">
                        {UserUpdate.errors.username}
                      </FormFeedback>
                    )}
                </div>
                <div className="mb-3">
                  <label htmlFor="title-field" className="form-label">
                    Email
                  </label>
                  <Input
                    type="text"
                    id="sessionname-field"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Enter Email"
                    name="useremail"
                    onChange={UserUpdate.handleChange}
                    onBlur={UserUpdate.handleBlur}
                    value={UserUpdate.values.useremail || ""}
                    invalid={
                      UserUpdate.touched.useremail &&
                      UserUpdate.errors.useremail
                    }
                  />
                  {UserUpdate.touched.useremail &&
                    UserUpdate.errors.useremail && (
                      <FormFeedback type="invalid">
                        {UserUpdate.errors.useremail}
                      </FormFeedback>
                    )}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="title-field" className="form-label">
                    {" "}
                    Mobile Number{" "}
                  </label>
                  <Input
                    type="text"
                    id="sessiontitle-field"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Enter Mobile Number"
                    name="usermobilenumber"
                    onChange={UserUpdate.handleChange}
                    onBlur={UserUpdate.handleBlur}
                    value={UserUpdate.values.usermobilenumber || ""}
                    invalid={
                      UserUpdate.touched.usermobilenumber &&
                      UserUpdate.errors.usermobilenumber
                    }
                  />
                  {UserUpdate.touched.usermobilenumber &&
                    UserUpdate.errors.usermobilenumber && (
                      <FormFeedback type="invalid">
                        {UserUpdate.errors.usermobilenumber}
                      </FormFeedback>
                    )}
                </div>
                <div className="mb-3">
                  <label htmlFor="title-field" className="form-label">
                    Country
                  </label>
                  <Input
                    type="text"
                    id="sessionname-field"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Enter Country"
                    name="country"
                    onChange={UserUpdate.handleChange}
                    onBlur={UserUpdate.handleBlur}
                    value={UserUpdate.values.country || ""}
                    invalid={
                      UserUpdate.touched.country && UserUpdate.errors.country
                    }
                  />
                  {UserUpdate.touched.country && UserUpdate.errors.country && (
                    <FormFeedback type="invalid">
                      {UserUpdate.errors.country}
                    </FormFeedback>
                  )}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_update_list(false)}
              >
                Close
              </button>

              <button
                className="btn btn-primary waves-effect waves-light"
                type="submit"
                disabled={loading}
              >
                {loading ? <Loader /> : "Submit"}
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default UserList;

import React, { useState, useEffect } from "react";
import { Button, Input, Card, CardBody, FormFeedback, 
Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate } from "react-router-dom";


const StakeHistoryview = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [templateList,setTemplateList] = useState([])
  const [getId,setId] = useState('')
  const [content,setContent] = useState('')


  document.title = "Ultrapro | Staking Offers";

  //EMAIL TEMPLATE LIST
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await postData(Apiservices.StakingOffersList);
      if (response) {
        setTemplateList(response.data);
        setLoading(false)
      } else {
        setLoading(false)

      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)

      // Handle fetch error
    }
  };

  const columns = [
    {
      name: "S.No",
      // selector: (row, index) => currentPage * itemsPerPage + index + 1,
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.holderName,
      sortable: true,
    },
    {
      name: "Subject",
      selector: (row) => row.bankName,
      sortable: true,
    },
    {
      name: "Content",
      selector: (row) => row.accountNumber,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => utcToNormalTime(row.createdAt).date,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="edit">
          <i
            style={{ cursor: "pointer" }}
            className="mdi mdi-border-color cursor-pointer"
            onClick={() => handleUpdate(row)}
          ></i>
        </div>
      ),
      ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
  ];

  //paginnation
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 5;
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * rowsPerPage;
  const currentData = templateList.slice(offset, offset + rowsPerPage);

  const utcToNormalTime = (utcString) => {
    const date = new Date(utcString);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
    const formattedTime = `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
    return { date: formattedDate, time: formattedTime };
  };
  
 

  //Add Modal
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
  }
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
  }
  //Add form validation
  const formik = useFormik({
    initialValues: {
      title: "",
      subject: "",
      
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      subject: Yup.string().required("Subject is required"),
    }),
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  const handleEditorChange = (content, editor) => {
    setContent(content)
  };


//Addapicall

const handleAdd = async (data) => {
    // setLoading(true);
    if(content !== ''){
      const postForm = {
        title: data.title,
        subject: data.subject,
        content:content,
      };
  
      try {
       
        // const response = await postData(Apiservices.AddBankDetails, postForm);
        // if (response.status === true) {
        //   toast.success(response.message);
        //   formik.resetForm();
        //   setmodal_list(false);
        //   fetchData();
        //   setLoading(false);
        // } else {
        //   toast.error(response.message);
        //   setLoading(false);
        //   formik.resetForm();
        //   fetchData();
        // }
      } catch (error) {
        toast.error("Internal Server Error..Try Again Later");
        setLoading(false);
        formik.resetForm();
        setLoading(false);
      }

    }
    else{
      toast.error('Content is required')
    }
    
   
  };
//update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,

    initialValues: {
        title: "",
        subject: "",
        
      },
      validationSchema: Yup.object({
        title: Yup.string().required("Title is required"),
        subject: Yup.string().required("Subject is required"),
      }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });
//updateapicall
 
  const handleUpdate = (record) => {
    setId(record.bankId);
    validationUpdate.setValues({
      templateId: record.templateId || "",
      title: record.title || "",
      subject: record.subject || "",
     
    });
 
    setmodal_update_list(true);
  };
  const confirmUpdate = async (values) => {
    const postForm = {
      templateid: getId,
      title: values.title,
      subject: values.subject,
      content: content,
    };
    // const postQurey = {
    //   accountNumber: recordToUpdate.accountNumber,
    // };
    // setDeleteLoad(true)
    // const queryString = new URLSearchParams(postQurey).toString();
    try {
      setLoading(true);
      // const response = await postData(`${Apiservices.UpdateBankDetails}?${queryString}`, postForm)
      const response = await postData(
        `${Apiservices.UpdateBankDetails}`,
        postForm
      );

      if (response.status === true) {
        setmodal_update_list(false);
        // setDeleteLoad(false)
        toast.success(response.message);
        // setmodal_delete(false)
        fetchData();
        setLoading(false);
      } else {
        toast.error(response.message);
        fetchData();
        // validation.resetForm();
        // setmodal_delete(false)
        // setDeleteLoad(false)
        setLoading(false);
      }
    } catch (error) {
      // setDeleteLoad(false)
      toast.error("Internal Server Error..Try Again Later");
      // setmodal_delete(false)
      validationUpdate.resetForm();
    }
  };

 const handleAddOffer = () =>{
navigate('/stakingoffers')
 }
 
  return (
    <React.Fragment>
  <div className="page-content">
    <Container fluid={true}>
      <Breadcrumbs title="Settings" breadcrumbItem="Staking History" />
      <Row>
        <Col lg={12} className="row">
          <Card className="col-lg-12">
            <CardBody>
              <Row className="g-4 mb-3">
                <Col className="col-sm-auto">
                  <h4 className="card-title">Staking History</h4>
                </Col>
                <Col className="col-sm">
                  <div className="d-flex justify-content-sm-end">
                    <Button color="success" className="add-btn" onClick={handleAddOffer} id="create-btn">
                      <i className="ri-add-line align-bottom me-1"></i> Add Stake Offer</Button>
                  </div>
                </Col>
              </Row>
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={currentData}
                  paginationServer
                  paginationTotalRows={currentData.length}
                  progressPending={loading}
                  progressComponent={<div>Loading...</div>}
                  persistTableHead={true}
                  noDataComponent={<div className="mt-5">No records found</div>}
                  fixedHeader={true}
                />
                {currentData.length !== 0 ? (
                  <div className="d-flex justify-content-end mt-3">
                    <div className="pagination pagination-rounded mb-0 ">
                      <ReactPaginate
                        previousLabel={<i className="mdi mdi-chevron-left"></i>}
                        nextLabel={<i className="mdi mdi-chevron-right"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        pageLinkClassName={"page-link"}
                        breakLinkClassName={"break-link"}
                        disabledClassName={"disabled"}
                        pageCount={Math.ceil(templateList.length / rowsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                ) : null}
                {/* // ADDEMAAIL  */}
                <Modal
                  className="modal-dialog modal-lg" isOpen={modal_list} toggle={() => { tog_list();}} centered >
                  <ModalHeader
                    className="bg-light p-3"
                    id="exampleModalLabel"
                    toggle={() => {tog_list();}}>{" "} Add Email Template{" "}
                  </ModalHeader>
                  <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                      return false;
                    }} >
                    <ModalBody>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Title
                        </label>

                        <Input
                          type="text"
                          id="title-field"
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter Title"
                          name="title"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.title || ""}
                          invalid={formik.touched.title && formik.errors.title}
                        />
                        {formik.touched.title && formik.errors.title && <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Subject
                        </label>

                        <Input
                          type="text"
                          id="subject-field"
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter subject"
                          name="subject"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.subject || ""}
                          invalid={formik.touched.subject && formik.errors.subject}
                        />
                        {formik.touched.subject && formik.errors.subject && <FormFeedback type="invalid">{formik.errors.subject}</FormFeedback>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Content
                        </label>

                      <Editor
                        apiKey="8tmldbekaot614sl1i1s5lejnuj9hkqmsncgjyxs3p4rgave" // Optional, get one from TinyMCE if you're using their cloud version
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: "link image code",
                          toolbar: "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code",
                          setup: (editor) => {
                            editor.on("change", () => {
                            });
                          },
                        }}
                        onEditorChange={handleEditorChange}
                      />
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => setmodal_list(false)}
                        >
                          Close
                        </button>

                        <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
                          {loading ? <Loader /> : "Submit"}
                        </button>
                      </div>
                    </ModalFooter>
                  </Form>
                </Modal>

                {/* UpdateModal */}
                <Modal isOpen={modal_update_list} toggle={() => { tog_update_list(); }} centered>
                  <ModalHeader
                    className="bg-light p-3"
                    id="exampleModalLabel"
                    toggle={() => { tog_update_list(); }}>
                    Update Email Template
                  </ModalHeader>
                  <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validationUpdate.handleSubmit();
                      return false;
                    }}
                  >
                    <ModalBody>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Title
                        </label>

                        <Input
                          type="text"
                          id="title-field"
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter Title"
                          name="title"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.title || ""}
                          invalid={formik.touched.title && formik.errors.title}
                        />
                        {formik.touched.title && formik.errors.title && <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Subject
                        </label>

                        <Input
                          type="text"
                          id="subject-field"
                          className="form-control"
                          autoComplete="off"
                          placeholder="Enter subject"
                          name="subject"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.subject || ""}
                          invalid={formik.touched.subject && formik.errors.subject}
                        />
                        {formik.touched.subject && formik.errors.subject && <FormFeedback type="invalid">{formik.errors.subject}</FormFeedback>}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="title-field" className="form-label">
                          Content
                        </label>
                      <Editor
                        apiKey="8tmldbekaot614sl1i1s5lejnuj9hkqmsncgjyxs3p4rgave" // Optional, get one from TinyMCE if you're using their cloud version
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: "link image code",
                          toolbar: "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code",
                          setup: (editor) => {
                            editor.on("change", () => {
                            });
                          },
                        }}
                        onEditorChange={handleEditorChange}
                      />
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <div className="hstack gap-2 justify-content-end">
                        <button type="button" className="btn btn-light" onClick={() => setmodal_update_list(false)}>
                          Close
                        </button>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
                          {loading ? <Loader /> : "Submit"}
                        </button>
                      </div>
                    </ModalFooter>
                  </Form>
                </Modal>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
</React.Fragment>

  );
};

export default StakeHistoryview;

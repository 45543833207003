import React, { useCallback, useEffect, useState } from 'react'
import { Card, CardBody, CardSubtitle, Col, Form, FormFeedback, Input, InputGroup, Label, Modal, Row, Spinner, Table } from 'reactstrap';
import { Apiservices, Boolian } from '../../../ApiService/ApiServices';
import toast from 'react-hot-toast';
import { postData } from '../../../ApiService/AxiosInstance';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from "yup";

function UserKYC({ id }) {
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [kycDetails, setKycDetails] = useState({});
    const [showOTPModel, setShowOTPModel] = useState(false)
    const [submitLoad, setSubmitLoad] = useState(false);
    const [countdown, setCountdown] = useState(60);
    const [isDisabled, setIsDisabled] = useState(false);

    const [fullImage, setFullImage] = useState("");
    const [modal_xlarge, setmodal_xlarge] = useState(false);

    const [selectValue, setSelectedValue] = useState({

        // Indian KYC
        aadharStatus: null,
        aadharStatusReason: null,
        panStatus: null,
        panStatusReason: null,
        indianSelfieStatus: null,
        indianSelfieStatusReason: null,


        drivingLicenceStatus: null,
        drivingLicenceStatusReason: null,
        nationalIdStatus: null,
        nationalIdStatusReason: null,
        foreignSelfieStatus: null,
        foreignSelfieStatusReason: null,


    });
    //const allValuesNull = Object.values(selectValue).every(value => value === null);

    const startCountdown = () => {

        const intervalId = setInterval(() => {
            setCountdown(prevCountdown => {
                const newCountdown = prevCountdown - 1;
                if (newCountdown <= 0) {
                    clearInterval(intervalId);
                    setShowOTPModel(false);
                    setIsDisabled(false)
                    validation.resetForm()
                    return 0;
                }
                return newCountdown;
            });
        }, 1000);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            otp: ''
        },
        validationSchema: Yup.object({
            otp: Yup.string()

                .required('OTP is required'),
        }),
        onSubmit: (values) => {
            otpVerify(values)
        },
    });


    const handleKycOtpSend = async () => {
        validation.resetForm()
        setLoading(true);

        const postForm = {
            kycId: kycDetails.kycId
        };

        try {
            const response = await postData(Apiservices.KYC_OTP_SEND, postForm);
            if (response.status) {
                toast.success(response.message);
                setShowOTPModel(true);
                setCountdown(60)
                startCountdown()
                getUserDetails();
                setLoading(false);

            } else {
                toast.error(response.message);
                setLoading(false);

                getUserDetails();
            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };
    const otpVerify = async (data) => {
        setSubmitLoad(true)

        const postForm = {
            otp: validation.values.otp,
            kycId: kycDetails.kycId,

            aadharStatus: Number(selectValue.aadharStatus),
            aadharReason: selectValue.aadharStatusReason,
            panStatus: Number(selectValue.panStatus),
            panReason: selectValue.panStatusReason,
            profileStatus: Number(selectValue.indianSelfieStatus),
            selfieReason: selectValue.indianSelfieStatusReason,

            nationalStatus: Number(selectValue.nationalIdStatus),
            nationalReason: selectValue.nationalIdStatusReason,
            foreignDrivingStatus: Number(selectValue.drivingLicenceStatus),
            foreignDrivingReason: selectValue.drivingLicenceStatusReason,
            foreignProfileStatus: Number(selectValue.foreignSelfieStatus),
            foreignSelfieReason: selectValue.foreignSelfieStatusReason,
        };
        // const filteredPostForm = Object.fromEntries(
        //     Object.entries(postForm).filter(([key, value]) => value !== null)
        // );

        try {
            const response = await postData(Apiservices.KYC_OTP_VERIFY, postForm);

            if (response.status === true) {
                toast.success(response.message);
                setSubmitLoad(false)
                setShowOTPModel(false)
                getUserDetails()
                setShowOTPModel(false)
            } else {
                toast.error(response.message);
                setLoading(false);
                getUserDetails();
                setSubmitLoad(false)
                setShowOTPModel(false)

            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
            setShowOTPModel(false)
            setLoading(false);
            setSubmitLoad(false)

        }
    };

    const getUserDetails = useCallback( async () => {
        try {
            setLoading(true);
            const data = {
                acId: id,
            }; 
            const response = await postData(Apiservices.GetUserDetails, data);
            console.log(response,"RRTYRTTYR")
            if (response.status) {
                setUserDetails(response.data.data);
                setKycDetails(response.data.kyc)

                setLoading(false);

            } else {
                setLoading(false);
            }
        } catch (error) {
            toast.error("Internal Server Error...");
            setLoading(false);

        }
    },[id]);

    useEffect(() => {
        getUserDetails()
    }, [getUserDetails])
    function tog_xlarge(image) {
        setFullImage(image);
        setmodal_xlarge(!modal_xlarge);
    }

    const getKycStatusText = (code) => {
        switch (code) {
            case Boolian.KYC_PENDING_NUMBER:
                return "Pending";
            case Boolian.KYC_VERIFIED_NUMBER:
                return "Verified";
            case Boolian.KYC_REJECTED:
                return "Rejected";
            case Boolian.KYC_NOT_UPLOAD:
                return "Not Uploaded";
            default:
                return "Unknown";
        }
    };




    const showKYCAlert = (onConfirm, statusValue) => {
        const swalOptions = {
            title: 'Are you sure?',
            text: `You want to update the status of this user!`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update it!',
            allowOutsideClick: () => !Swal.isLoading(),
        };
    
        if (statusValue === "3") {  // Show reason input only if status value is 3
            swalOptions.input = "text";
            swalOptions.inputLabel = "Enter a Reason";
            swalOptions.inputAttributes = {
                autocapitalize: "off",
                autocomplete: "off"
            };
            swalOptions.inputPlaceholder = "Enter Reason";
            swalOptions.preConfirm = (reason) => {
                if (!reason) {
                    Swal.showValidationMessage('Reason is required');
                    return false; // Prevent confirmation if reason is not provided
                } else {
                    return reason; // Pass reason to the next then() block
                }
            };
        } else if (statusValue === "2") {
            swalOptions.preConfirm = () => {
                return null; // No reason, just return null
            };
        }
    
        Swal.fire(swalOptions).then((result) => {
            if (result.isConfirmed) {
                console.log(result.isConfirmed,"result.isConfirmed");
                
                onConfirm(result.value); // Call onConfirm with the reason or null
            }
        });
    };

    const handleUserSelect = (statusName, value) => {
        showKYCAlert((reason) => {
            setSelectedValue((prevState) => ({
                ...prevState,
                [statusName]: value,
                [`${statusName}Reason`]: reason || null,  // Store reason only if provided
            }));
            handleKycOtpSend(reason).then((res) => {
                console.log(res,"REEEEEEEEEE");
                if(res){

                    Swal.close();
                }
                
                // Close SweetAlert after the response is received
            });
        }, value);
    };






    return (
        <div className='d-flex justify-content-center '>
              {loading ? (<div className="d-flex justify-content-center">Loading... </div>) : (

            <>
            <Card className='col-lg-6'>

                <Table responsive striped className="table-nowrap mb-0" >
                  

                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td>{userDetails && userDetails.fullName ? userDetails.fullName : "-"}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{userDetails && userDetails.email ? userDetails.email : "-"}</td>
                            </tr>

                            {kycDetails?.indianClient ? (
                                <>

                                    {/* Indian User Details */}
                                    <tr>
                                    <td>Aadhar Number</td>
                                    <td>{kycDetails.indianClient.aadharDetails.aadhaarNumber}</td>
                                  </tr>

                                    <tr>
                                        <td>Aadhar Front Image</td>
                                        <td>
                                            {kycDetails.indianClient.aadharDetails?.aadharFrontImage && (
                                                <img
                                                    className="rounded-circle header-profile-user"
                                                    onClick={() => tog_xlarge(kycDetails.indianClient.aadharDetails.aadharFrontImage)}
                                                    src={kycDetails.indianClient.aadharDetails.aadharFrontImage}
                                                    alt="Aadhar Front"
                                                />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Aadhar Back Image</td>
                                        <td>
                                            {kycDetails.indianClient.aadharDetails?.aadharBackImage && (
                                                <img
                                                    className="rounded-circle header-profile-user"
                                                    onClick={() => tog_xlarge(kycDetails.indianClient.aadharDetails.aadharBackImage)}
                                                    src={kycDetails.indianClient.aadharDetails.aadharBackImage}
                                                    alt="Aadhar Front"
                                                />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>Pan Number</td>
                                    <td>{kycDetails.indianClient.panDetails?.panNumber}</td>
                                  </tr>
                                    <tr>
                                        <td>Pan Card Image Front</td>
                                        <td>
                                            {kycDetails.indianClient.panDetails?.panFrontImage && (
                                                <img
                                                    className="rounded-circle header-profile-user"
                                                    onClick={() => tog_xlarge(kycDetails.indianClient.panDetails.panFrontImage)}
                                                    src={kycDetails.indianClient.panDetails.panFrontImage}
                                                    alt="Pan Card"
                                                />
                                            )}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>Pan Card Image Back</td>
                                        <td>
                                            {kycDetails.indianClient.panDetails?.panBackImage && (
                                                <img
                                                    className="rounded-circle header-profile-user"
                                                    onClick={() => tog_xlarge(kycDetails.indianClient.panDetails.panBackImage)}
                                                    src={kycDetails.indianClient.panDetails.panBackImage}
                                                    alt="Pan Card"
                                                />
                                            )}
                                        </td>

                                    </tr>

                                    <tr>
                                        <td>Selfie Image</td>
                                        <td>
                                            {kycDetails.indianClient.selfiePics?.selfieImage && (
                                                <img
                                                    className="rounded-circle header-profile-user"
                                                    onClick={() => tog_xlarge(kycDetails.indianClient.selfiePics.selfieImage)}
                                                    src={kycDetails.indianClient.selfiePics.selfieImage}
                                                    alt="Selfie"
                                                />
                                            )}
                                        </td>
                                    </tr>
                                </>
                            ) : kycDetails?.foreignClient ? (
                                <>
                                    {/* Foreign User Details */}
                                    <tr>
                                    <td>National ID Number</td>
                                    <td>{kycDetails.foreignClient.nationalDetails?.nationalIdNumber}</td>
                                  </tr>
                                    <tr>
                                        <td>National ID Card Front Image</td>
                                        <td>
                                            {kycDetails.foreignClient.nationalDetails?.nationalIdCardFrontImage && (
                                                <img
                                                    className="rounded-circle header-profile-user"
                                                    onClick={() => tog_xlarge(kycDetails.foreignClient.nationalDetails.nationalIdCardFrontImage)}
                                                    src={kycDetails.foreignClient.nationalDetails.nationalIdCardFrontImage}
                                                    alt="National ID Card Front"
                                                />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>National ID Card Back Image</td>
                                        <td>
                                            {kycDetails.foreignClient.nationalDetails?.nationalIdCardBackImage && (
                                                <img
                                                    className="rounded-circle header-profile-user"
                                                    onClick={() => tog_xlarge(kycDetails.foreignClient.nationalDetails.nationalIdCardBackImage)}
                                                    src={kycDetails.foreignClient.nationalDetails.nationalIdCardBackImage}
                                                    alt="National ID Card Back"
                                                />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                    <td>Driving Licence Number</td>
                                    <td>{kycDetails.foreignClient.drivingDetails?.drivingLicenseNo}</td>
                                  </tr>
                                    <tr>
                                        <td>Driving License Front Image</td>
                                        <td>
                                            {kycDetails.foreignClient.drivingDetails?.drivingLicenseFrontImage && (
                                                <img
                                                    className="rounded-circle header-profile-user"
                                                    onClick={() => tog_xlarge(kycDetails.foreignClient.drivingDetails.drivingLicenseFrontImage)}
                                                    src={kycDetails.foreignClient.drivingDetails.drivingLicenseFrontImage}
                                                    alt="Driving License Front"
                                                />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Driving License Back Image</td>
                                        <td>
                                            {kycDetails.foreignClient.drivingDetails?.drivingLicenseBackImage && (
                                                <img
                                                    className="rounded-circle header-profile-user"
                                                    onClick={() => tog_xlarge(kycDetails.foreignClient.drivingDetails.drivingLicenseBackImage)}
                                                    src={kycDetails.foreignClient.drivingDetails.drivingLicenseBackImage}
                                                    alt="Driving License Back"
                                                />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Selfie Image</td>
                                        <td>
                                            {kycDetails.foreignClient.selfiePics?.selfieImage && (
                                                <img
                                                    className="rounded-circle header-profile-user"
                                                    onClick={() => tog_xlarge(kycDetails.foreignClient.selfiePics.selfieImage)}
                                                    src={kycDetails.foreignClient.selfiePics.selfieImage}
                                                    alt="Selfie"
                                                />
                                            )}
                                        </td>
                                    </tr>
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="2">No KYC Details Available</td>
                                </tr>
                            )}
                        </tbody>

                    
                </Table>
            </Card>
            <Card className="col-lg-6">
                <CardBody>
                    <CardSubtitle className="mb-3"></CardSubtitle>
                    <div className="table-responsive">

                        <Table responsive striped className="table-nowrap mb-0">
                            <tbody>
                                <tr>
                                    <td>KYC Status</td>
                                    <td>{kycDetails ? getKycStatusText(kycDetails.kycStatus) : "-"}</td>
                                </tr>
                                <tr>
                                    <td>Country</td>
                                    <td>{userDetails && userDetails.country ? userDetails.country : "-"}</td>
                                </tr>
                                {kycDetails?.indianClient ? (
                                    <>
                                        <tr>
                                            <td>Aadhar Status ({getKycStatusText(kycDetails.indianClient?.aadharDetails.aadharStatus)})</td>
                                            <td>
                                                <select
                                                    onChange={(e) => handleUserSelect('aadharStatus', e.target.value)}
                                                    className="form-select"
                                                    value={selectValue.aadharStatus ?? kycDetails.indianClient?.aadharDetails.aadharStatus}
                                                >
                                                    <option value='1' disabled>pending</option>
                                                    <option value="2">Approve</option>
                                                    <option value="3">Rejected</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Pan Status ({getKycStatusText(kycDetails.indianClient?.panDetails.panStatus)})</td>
                                            <td>
                                                <select
                                                    onChange={(e) => handleUserSelect('panStatus', e.target.value)}
                                                    className="form-select"
                                                    value={selectValue.panStatus ?? kycDetails.indianClient?.panDetails.panStatus}
                                                >
                                                    <option value='1' disabled>pending</option>

                                                    <option value="2">Approve</option>
                                                    <option value="3">Rejected</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Selfie Image Status ({getKycStatusText(kycDetails.indianClient?.selfiePics.profileStatus)})</td>
                                            <td>
                                                <select

                                                    value={selectValue.indianSelfieStatus ??kycDetails.indianClient?.selfiePics.profileStatus}

                                                    onChange={(e) => handleUserSelect('indianSelfieStatus', e.target.value)}
                                                    className="form-select"
                                                >
                                                    <option value='1' disabled>pending</option>

                                                    <option value="2">Approve</option>
                                                    <option value="3">Rejected</option>
                                                </select>
                                            </td>
                                        </tr>

                                        {/* <tr>
                                            <td>Submit Button</td>
                                            <td>
                                                <Button
                                                disabled={allValuesNull || loading}
                                                    color="info" 
                                                    className="add-btn mx-2"
                                                    onClick={handleKycOtpSend}
                                                    id="create-btn"

                                                >
                                                    Submit
                                                </Button>
                                            </td>
                                        </tr> */}

                                    </>
                                ) : kycDetails?.foreignClient ? (
                                    <>
                                        <tr>
                                            <td>National ID Status ({getKycStatusText(kycDetails.foreignClient?.nationalDetails.nationalStatus)})</td>
                                            <td>
                                                <select
                                                    value={selectValue.nationalIdStatus ?? kycDetails.foreignClient?.nationalDetails.nationalStatus}
                                                    onChange={(e) => handleUserSelect('nationalIdStatus', e.target.value)}
                                                    className="form-select"

                                                >
                                                    <option value='1' disabled>pending</option>
                                                    <option value="2">Approve</option>
                                                    <option value="3">Rejected</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Driving License Status ({getKycStatusText(kycDetails.foreignClient?.drivingDetails.drivingStatus)})</td>
                                            <td>
                                                <select
                                                    value={selectValue.drivingLicenceStatus ?? kycDetails.foreignClient?.drivingDetails.drivingStatus}
                                                    onChange={(e) => handleUserSelect('drivingLicenceStatus', e.target.value)}
                                                    className="form-select"
                                                >
                                                    <option value='1' disabled>pending</option>
                                                    <option value="2">Approve</option>
                                                    <option value="3">Rejected</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Selfie Image Status ({getKycStatusText(kycDetails.foreignClient?.selfiePics.profileStatus)})</td>
                                            <td>
                                                <select
                                                    onChange={(e) => handleUserSelect('foreignSelfieStatus', e.target.value)}
                                                    className="form-select"
                                                    value={selectValue.foreignSelfieStatus ?? kycDetails.foreignClient?.selfiePics.profileStatus}

                                                >
                                                    <option value='1' disabled>pending</option>
                                                    <option value="2">Approve</option>
                                                    <option value="3">Rejected</option>
                                                </select>
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td>Submit Button</td>
                                            <td>
                                                <Button
                                                    color="info"
                                                    className="add-btn mx-2"
                                                    disabled={allValuesNull || loading}
                                            
                                                      onClick={handleKycOtpSend}
                                                    id="create-btn"

                                                >
                                                    Submit
                                                </Button>
                                            </td>
                                        </tr> */}

                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan="2">No KYC Details Available</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
            <Modal
                size="l"
                isOpen={modal_xlarge}
                toggle={() => {
                    tog_xlarge();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                        Profile Image
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_xlarge(false);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body d-flex justify-content-center">
                    <img
                        style={{ width: "450px", borderRadius: "10px" }}
                        src={fullImage}
                        alt=""
                    />
                </div>
            </Modal>
            <Modal className="modal-dialog modal px-4" size="l" isOpen={showOTPModel} centered backdrop="static"  >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel"
                    >
                        Account Add OTP
                    </h5>
                    <button
                        onClick={() => {
                            setShowOTPModel(false);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <div>
                        <div className="text-center">

                        </div>
                        <h4 className="font-size-18 text-muted mt-2 text-center">
                            Verify Your OTP
                        </h4>

                        <div className="user-thumb text-center m-b-30">
                            <h4 className="rounded-circle avatar-lg img-thumbnail mx-auto d-flex justify-content-center align-items-center">{countdown === 60 ? ("0") : (countdown)}<span style={{ fontSize: '15px', marginTop: '7px' }}>s</span></h4>
                        </div>
                        <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >

                            <Row>
                                <Col md={12} className="AuthStar">
                                    <div className="mb-4">
                                        <Label className="form-label">One Time Password<span>*</span></Label>
                                        <InputGroup>
                                            <Input
                                                style={{ borderColor: '#80838B' }}
                                                name="otp"
                                                disabled={loading || countdown === 60 || countdown === 0}
                                                className="form-control"
                                                placeholder="Enter OTP"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.otp || ""}
                                                invalid={validation.touched.otp && validation.errors.otp ? true : false}
                                            />

                                            {validation.touched.otp && validation.errors.otp ? (
                                                <FormFeedback>{validation.errors.otp}</FormFeedback>
                                            ) : null}
                                        </InputGroup>
                                    </div>

                                    <div className="d-grid mt-4">

                                        <button
                                            className="btn btn-primary waves-effect waves-light"
                                            type="submit"
                                            disabled={loading || countdown === 60 || submitLoad}
                                            onClick={validation.handleSubmit}
                                        >
                                            {submitLoad ? (
                                                <>
                                                    <Spinner size="sm">
                                                        Loading...
                                                    </Spinner>
                                                    <span style={{ color: 'white' }}>
                                                        {' '}Loading...
                                                    </span>
                                                </>


                                            ) : ("Submit")}

                                        </button>




                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>





                </div>
            
            </Modal>
        </>
              )}
        </div>
    )
}

export default UserKYC
import React, { useState, useEffect } from "react";
import { Button, Input, Card, CardBody, FormFeedback, Col, Container, FormGroup, Label, Row, Form } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import { Editor } from "@tinymce/tinymce-react";


const StakingOffersUpdate = () => {
    const { id } = useParams();
    document.title = "Ultrapro | Stake Offers";
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [currencyList, setCurrencyList] = useState([]);
    const [minStakeScheme, setMinStakeScheme] = useState('');
    const navigate = useNavigate('');
  const [summary, setSummary] = useState(""); 

    useEffect(() => {
        GetSingleData();
        coinList();
        fetchData()
    }, [id]);

    const GetSingleData = async () => {
        const postForm = { flexiblePlanId: id };
        setLoading1(true);
        try {
            const response = await postData(Apiservices.FlexibleStakeGetSingle, postForm);
            console.log(response); // Log the full response

            if (response.status && response.data && response.data.flexiblePlanData) {
                const planData = response.data.flexiblePlanData; 
                setSummary(planData.summary)
                formik.setValues({
                    planName: planData.planName || "", // Default to empty if undefined
                    duration: planData.duration ? planData.duration.toString() : "", // Convert to string if needed
                    minStakeAmount: planData.minStakeAmount || "",  
                    maxStakeAmount: planData.maxStakeAmount || "",
                    description: planData.description || "",
                    lockPeriod: planData.lockPeriod || "",
                    stakeLimit: planData.stakeLimit || "",
                    isPremium: planData.isPremium !== undefined ? String(planData.isPremium) : "",
                    email: planData.email || "",
                    summary: planData.summary || "",
                    currencyName: planData.coinName || "", // Updated to reflect the correct field
                    content: planData.content.map((item) => ({
                        contentLine: item.contentLine,
                        isActive: item.isActive !== undefined ? String(item.isActive) : "",
                      })),
                    stakeType: planData.stakeType !== undefined ? String(planData.stakeType) : "",
                    initialStakePercentage: planData.initialStakePercentage,
                    referralStakePercentage:planData.referralStakePercentage,
                    dailyRewardPercentage:planData.dailyRewardPercentage
                    
                });
            } else {
                console.error("Data structure is not as expected:", response.data);
                toast.error("Failed to load plan data.");
            }
        } catch (error) {
            console.error("Error fetching single data:", error);
            toast.error("Error fetching plan details.");
        } finally {
            setLoading1(false);
        }
    };

    const fetchData = async () => { 
        try {
            const response = await postData(Apiservices.FlexiFirstUSDTAmount);
            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                const firstStakeUsdtAmount = Number(response.data[0].firstStakeUsdtAmount); // Convert to number
                setMinStakeScheme(firstStakeUsdtAmount); // Set as number
            } else {
                console.log("No valid data found in response.");
            }
        } catch (error) {
            console.error("Error fetching Min Stake Amount:", error);
        } finally {
            setLoading(false); // Ensure loading state is reset
        }
    };
    
    const formik = useFormik({
        initialValues: {
            planName: "",
            duration: "",
            minStakeAmount: "",
            maxStakeAmount: "",
            description: "",
            currencyName: "",
            lockPeriod: "",
            email:"",
            stakeLimit:"",
            summary:"",
            isPremium:"",
            content:[ 
                {
                contentLine: "",
                isActive: "",
              }],
              stakeType:'',
              initialStakePercentage:'',
              referralStakePercentage:'',
              dailyRewardPercentage:''
        },
        validationSchema: Yup.object({
            planName: Yup.string().required("Stake Name is Required"),
            duration: Yup.string().required("Duration is Required"),
            stakeLimit: Yup.string().required("Stake Limit is Required"),
            stakeType: Yup.string().required("Stake Type is Required"),
            minStakeAmount: Yup.number()
            .required("Minimum Stake Amount is Required")
            .typeError("Must be a number"),
            // .oneOf([minStakeScheme], `Min Stake must be exactly ${minStakeScheme}`)  ,      
    
          maxStakeAmount: Yup.number()
            .required("Maximum Stake Amount is Required")
            .typeError("Must be a number"),
            // .moreThan(Yup.ref("minStakeAmount"), "Max Stake must be more than Min Stake"),
            description: Yup.string().required("Description is Required"),
            currencyName: Yup.string().required("Currency Name is Required"),
            lockPeriod: Yup.string().required("Lock Period is Required"),
            isPremium: Yup.string().required("Select Premium or Not"),
            content: Yup.array().of(
                Yup.object().shape({
                  contentLine: Yup.string().trim().required("Content Line is Required"),
                  isActive: Yup.boolean().required("Content Status is Required"),
                })
              ).required("Content is Required"),
              initialStakePercentage: Yup.string()
              .nullable()
              .when('stakeType', {
                is: (value) => value == 2,
                then: (schema) => schema.required("Initial Stake Percentage  is Required"),
                otherwise: (schema) => schema.notRequired(),
              }),
              referralStakePercentage: Yup.string()
              .nullable()
              .when('stakeType', {
                is: (value) => value == 2,
                then: (schema) => schema.required("Referral Stake Percentage  is Required"),
                otherwise: (schema) => schema.notRequired(),
              }),
              dailyRewardPercentage: Yup.string()
              .nullable()
              .when('stakeType', {
                is: (value) => value == 2,
                then: (schema) => schema.required("Referral Stake Percentage  is Required"),
                otherwise: (schema) => schema.notRequired(),
              }),
        
        }),
        onSubmit: async (values) => {
            console.log(values,'values');
            handleUpdate(values);
        },
    });

    const handleUpdate = async (values) => {
      setLoading(true);
      

  
      const data = {
          flexiblePlanId: id,
          planName: values.planName,
          duration: values.duration,
          minStakeAmount: values.minStakeAmount,
          maxStakeAmount: values.maxStakeAmount,
          email: values.email,
          summary: summary ,
          description: values.description,
          lockPeriod: values.lockPeriod,
          coinName: values.currencyName,
          content: values.content,
          isPremium: values.isPremium,
          stakeLimit: values.stakeLimit,
          stakeType: values.stakeType,
          initialStakePercentage:Number(values.initialStakePercentage),
          referralStakePercentage:Number(values.referralStakePercentage),
          dailyRewardPercentage:Number(values.dailyRewardPercentage)
      };

  
      try {
          const response = await postData(Apiservices.FlexibleStakePlanUpdate, data);
          if (response.status) {
            setLoading(false)
              toast.success(response.message);
              setTimeout(() => {
                navigate('/flexiblestake-plan'); // Replace with your target route
              }, 1000);
            //   navigate('/flexiblestake-plan');
          } else {
              toast.error(response.message);
          }
      } catch (error) {
          toast.error("Error updating plan details.");
      } finally {
          setLoading(false);
      }
  };
  
    const coinList = async () => {
        try {
            const response = await postData(Apiservices.USER_COIN_LIST);
            if (response.status) {
                setCurrencyList(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const addContentRow = () => {
      formik.setValues({
          ...formik.values,
          content: [...formik.values.content, { contentLine: "", isActive: "" }]
      });
  };

  const removeContentRow = (index) => {
      const updatedContent = formik.values.content.filter((_, i) => i !== index);
      formik.setValues({ ...formik.values, content: updatedContent });
  };
  const handleEditorChange = (content, editor) => {
    setSummary(content)
    formik.setFieldValue("summary", content); // Update Formik's `summary` field
  };
  
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Staking Offers" breadcrumbItem="Update Staking Offers" />
                    <Row>
                        <Col lg={12} className="row">
                            <Form onSubmit={formik.handleSubmit}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="planName">Stake Name</Label>
                                                    <Input
                                                        type="text"
                                                        name="planName"
                                                        id="planName"
                                                        value={formik.values.planName}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        invalid={formik.touched.planName && !!formik.errors.planName}
                                                    />
                                                    <FormFeedback>{formik.errors.planName}</FormFeedback>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="minStakeAmount">Minimum Stake Amount</Label>
                                                    <Input
                                                        type="text"
                                                        name="minStakeAmount"
                                                        id="minStakeAmount"
                                                        value={formik.values.minStakeAmount}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        invalid={formik.touched.minStakeAmount && !!formik.errors.minStakeAmount}
                                                    />
                                                    <FormFeedback>{formik.errors.minStakeAmount}</FormFeedback>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="maxStakeAmount">Maximum Stake Amount</Label>
                                                    <Input
                                                        type="text"
                                                        name="maxStakeAmount"
                                                        id="maxStakeAmount"
                                                        value={formik.values.maxStakeAmount}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        invalid={formik.touched.maxStakeAmount && !!formik.errors.maxStakeAmount}
                                                    />
                                                    <FormFeedback>{formik.errors.maxStakeAmount}</FormFeedback>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="description">Description</Label>
                                                    <Input
                                                        type="textarea"
                                                        name="description"
                                                        id="description"
                                                        value={formik.values.description}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        invalid={formik.touched.description && !!formik.errors.description}
                                                    />
                                                    <FormFeedback>{formik.errors.description}</FormFeedback>
                                                </FormGroup>

                                                
                                                <FormGroup>
                        <Label for="duration">Stake Type</Label>
                        <select
                          id="stakeType"
                          name="stakeType"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.stakeType || ""}
                          className={`form-control ${formik.touched.stakeType && formik.errors.stakeType ? "is-invalid" : ""}`}
                        >
                          <option value="" disabled>
                            Select Type
                          </option>
                          <option value={0}>Free</option>
                          <option value={1}>Cost</option>
                          <option value={2}>Minimal</option>
        
                        </select>
                        {formik.touched.stakeType && formik.errors.stakeType && <FormFeedback type="invalid">{formik.errors.stakeType}</FormFeedback>}
                      </FormGroup>
                      {formik.values.stakeType == 2 && 
                      <FormGroup>
                          <Label for="initialStakePercentage">Initial Stake Percentage</Label>
                          <Input
                            type="number"
                            name="initialStakePercentage"
                            id="initialStakePercentage"
                            className="form-control"
                            value={formik.values.initialStakePercentage}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            placeholder="Enter Initial stake percentage"
                            min={0}
                            invalid={formik.touched.initialStakePercentage && !!formik.errors.initialStakePercentage}
                          />
                          <FormFeedback>{formik.errors.initialStakePercentage}</FormFeedback>
                        </FormGroup>}
                        {formik.values.stakeType == 2 && 
                        <FormGroup>
                          <Label for="dailyRewardPercentage">Daily Reward Percentage</Label>
                          <Input
                            type="number"
                            name="dailyRewardPercentage"
                            id="dailyRewardPercentage"
                            className="form-control"
                            value={formik.values.dailyRewardPercentage}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            min={0}
                            placeholder="Enter Daily reward percentage"
                            invalid={formik.touched.dailyRewardPercentage && !!formik.errors.dailyRewardPercentage}
                          />
                          <FormFeedback>{formik.errors.dailyRewardPercentage}</FormFeedback>
                        </FormGroup>}
                                            </Col>

                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="duration">Duration</Label>
                                                    <Input
                                                        type="text"
                                                        name="duration"
                                                        id="duration"
                                                        value={formik.values.duration}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        invalid={formik.touched.duration && !!formik.errors.duration}
                                                    />
                                                    <FormFeedback>{formik.errors.duration}</FormFeedback>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="currencyName">Currency Name</Label>
                                                    <Input
                                                        type="select"
                                                        name="currencyName"
                                                        id="currencyName"
                                                        value={formik.values.currencyName}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        invalid={formik.touched.currencyName && !!formik.errors.currencyName}
                                                    >
                                                        <option value="" disabled>Select Currency</option>
                                                        {currencyList.map((data) => (
                                                            <option key={data.coinId} value={data.symbol}>
                                                                {data.symbol}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                    <FormFeedback>{formik.errors.currencyName}</FormFeedback>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label for="lockPeriod">Lock Period</Label>
                                                    <Input
                                                        type="text"
                                                        name="lockPeriod"
                                                        id="lockPeriod"
                                                        value={formik.values.lockPeriod}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        invalid={formik.touched.lockPeriod && !!formik.errors.lockPeriod}
                                                    />
                                                    <FormFeedback>{formik.errors.lockPeriod}</FormFeedback>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="stakeLimit">Person Staking Limit</Label>
                                                    <Input
                                                        type="text"
                                                        name="stakeLimit"
                                                        id="stakeLimit"
                                                        value={formik.values.stakeLimit}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        invalid={formik.touched.stakeLimit && !!formik.errors.stakeLimit}
                                                    />
                                                    <FormFeedback>{formik.errors.stakeLimit}</FormFeedback>
                                                </FormGroup>
                                                <FormGroup>
                          <Label for="email">Email</Label>
                          <Input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            placeholder="Enter email"
                            invalid={formik.touched.email && !!formik.errors.email}
                          />
                          <FormFeedback>{formik.errors.email}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
  <Label for="isPremium">Is Premium</Label>
  
  <Input
    type="select"
    id="isPremium"
    name="isPremium"
    className="form-control"
    value={formik.values.isPremium}
    onChange={formik.handleChange}
    onBlur={formik.handleBlur}
    autoComplete="off"
    placeholder="Enter isPremium"
    invalid={formik.touched.isPremium && !!formik.errors.isPremium}
  >
    <option value="" disabled>Is Premium</option>
    <option value={1}>Yes</option>
    <option value={0}>No</option>
  </Input>
  <FormFeedback>{formik.errors.isPremium}</FormFeedback>

</FormGroup>
{formik.values.stakeType == 2 && 
<FormGroup>
                          <Label for="referralStakePercentage">Referral Stake Percentage</Label>
                          <Input
                            type="number"
                            name="referralStakePercentage"
                            id="referralStakePercentage"
                            className="form-control"
                            value={formik.values.referralStakePercentage}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            min={0}
                            placeholder="Enter Referral stake percentage"
                            invalid={formik.touched.referralStakePercentage && !!formik.errors.referralStakePercentage}
                          />
                          <FormFeedback>{formik.errors.referralStakePercentage}</FormFeedback>
                        </FormGroup>}
                                            </Col>
                                        </Row>
                                        <FormGroup>
  <Label for="summary">Summary</Label>
  <Editor
                              apiKey="1sc0anmjblezhxi95xon4501yg96pnp3qw0fj5osttzyz752"
                              value={summary}
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: "link image code",
                                toolbar:
                                  "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code",
                                setup: (editor) => {
                                  editor.on("change", () => {
                                    console.log("Editor content changed");
                                  });
                                },
                              }}
                              onEditorChange={handleEditorChange}
                            />
  </FormGroup>
                                        <Row>
                            {/* Other form fields */}

                            <Col md={12}>
                            
                                {formik.values.content.map((item, index) => (
                                    
                                    <Row key={index} className="align-items-center">
                                        <Col sm={5}>
                                        <FormGroup>
                              <Label for={`content[${index}].contentLine`}>Content Line</Label>
                              <Input
                                type="textarea"
                                name={`content[${index}].contentLine`} // Dynamically set name for each item
                                id={`contentLine-${index}`}
                                className="form-control"
                                placeholder="Enter Content Line"
                                value={formik.values.content[index].contentLine}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="off"
                                invalid={
                                  formik.touched.content?.[index]?.contentLine &&
                                  !!formik.errors.content?.[index]?.contentLine
                                }
                              />
                              <FormFeedback>
                                {formik.errors.content?.[index]?.contentLine}
                              </FormFeedback>
                            </FormGroup>
                                        </Col>

                                        <Col sm={4} hidden>
                                        <FormGroup>
              <label htmlFor={`content[${index}].isActive`} className="form-label">
                Content Status
              </label>
              <select
                id={`isActive-${index}`}
                name={`content[${index}].isActive`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.content[index].isActive || ""}
                className={`form-control ${
                  formik.touched.content?.[index]?.isActive &&
                  formik.errors.content?.[index]?.isActive
                    ? "is-invalid"
                    : ""
                }`}
              >
                <option value="" disabled>
                  Select Status
                </option>
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </select>
              {formik.touched.content?.[index]?.isActive &&
                formik.errors.content?.[index]?.isActive && (
                  <FormFeedback type="invalid">
                    {formik.errors.content?.[index]?.isActive}
                  </FormFeedback>
                )}
            </FormGroup>
                                        </Col>

                                        <Col sm={1} className="d-flex justify-content-center">
                                            {index > 0 && (
                                                < i className="ri-delete-bin-line"
                                                    style={{ cursor: "pointer", color: "red" }}
                                                    onClick={() => removeContentRow(index)}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                ))}
                                <Button color="success" onClick={addContentRow} className="mt-2">
                                <i className="ri-add-line align-bottom "> Add Content</i>
                                </Button>
                            </Col>
                        </Row>
                                        <Button className="mt-3" color="primary" type="submit" disabled={loading}>
                                            {loading ? "Loading..." : "Submit"}
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default StakingOffersUpdate;

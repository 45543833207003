import React, { useState,useEffect } from 'react';
import { Col } from "reactstrap";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from '../../ApiService/ApiServices';
import toast from "react-hot-toast";
import { utcToNormalTime } from '../../helpers/function';


const OverView = () => {
    const [loading,setLoading] = useState(false)
    const [formData,setFormData] = useState([])
    useEffect(() => {
      TransactionHistory();
    }, []);

    const TransactionHistory = async () => {
        setLoading(true);
        try {
            const response = await postData(Apiservices.TRANSACTION_HISTORY,{});
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
            } else {
                toast.error(response.message);
                setLoading(false);

            }
        } catch (error) {
            toast.error("Internal Server Error...");
            setLoading(false);


        }
    };
   

    return (
        <React.Fragment>
         
                <Col lg={9}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-1">Recent Transaction</h4>

                            <div className="table-responsive trans_tab">
                                <table className="table table-centered table-nowrap">

                                    <thead>
                                        <tr>
                                          
                                        
                                            <th scope="col">S.No</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Transaction ID</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Amount</th>
                                          
                                            <th scope="col">Date & Time</th>

                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        {formData && formData.length > 0 ? formData.slice(0, 5).map((item, key) => (<tr key={key}>
                                            <td>{key+1}</td>
                                            <td>{item.description ? item.description : '-'}</td>
                                            <td>{item.transId ? item.transId : '-'}</td>
                                            <td>{item.email ? item.email : '-'}</td>

                                           <td>{item.amount ? `${item.amount} USDT` : '-'}</td>
                                          
                                            <td>{utcToNormalTime(item.createdAt).date} {utcToNormalTime(item.createdAt).time}</td>
                                            
                                            {/* <td>
                                                <button type="button" className="btn btn-outline-success btn-sm me-1">Edit</button>
                                                <button type="button" className="btn btn-outline-danger btn-sm me-1">Cancel</button>
                                            </td> */}
                                        </tr>)) 
                                        :
                                        <tr>
                                            {loading ? (

                                                <td colSpan="7" className="text-center my-5">Loading</td>
                                            ):(
                                                <td colSpan="7" className="text-center my-5">No records found</td>

                                            )}

                                        </tr>  }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
          
        </React.Fragment>
    );
};

export default OverView;
import React, { useState, useEffect,useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback,CardImg, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import axiosInstance from "../../ApiService/AxiosInstance";
import { AdminContext } from "../../helpers/adminContext";



const CompareOtherExchange = () => {
  const [loading, setLoading] = useState(false);
  const [getSessionList, setSessionList] = useState([]);
  const [getId, setId] = useState("");
  const token = Cookies.get('authentication');
  document.title = "Ultrapro | Compare Other Exchanges";
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState("");
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState('');
  const [showDetails, setShowDetails] = useState({})
  const [showModel, setShowModel] = useState(false)
  const [deleteLoad,setDeleteLoad] = useState(false)
//Permission List 
const { adminInfo } = useContext(AdminContext);
const [adminType,setAdminType] = useState('')

  //Add Modal
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
    formik.resetForm();
    setImage('')
  }
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
    setImage('')
  }
  const [getImage,setGetImage] = useState('')
  //classList api call
  useEffect(() => {
    fetchData();
    setAdminType(adminInfo?.adminType)
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.CompareExchangesList);
      if (response) {
        setLoading(false);
        setTotalPagination(response.data.totalPages);
        setSessionList(response.data.data);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Handle fetch error
    }
  };
  

  const handleShowModel = (value) => {
    setShowDetails(value)
    setShowModel(true)
}
const handleDelete = (value) => {
  showUserBlockAlert(confirmDelete, value)
}

const showUserBlockAlert = (onConfirm, row) => {
  Swal.fire({
      title: 'Are you sure?',
      text: `You want to remove this record!`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
          try {
              await onConfirm(row);
          } catch (error) {
              Swal.fire('Error!', 'There was an error deleting the record', 'error');
          }
      }
  }).then((result) => {
      if (result.isConfirmed) {
          // Additional actions after confirmation, if needed
      }
  });
};

const confirmDelete = async (row) => {
  const postForm = {
    siteId : row.siteId
  }
 
  setDeleteLoad(true)
  try {
      const response = await postData(Apiservices.CompareExchangesDelete,postForm )
      if (response.status) {
          setDeleteLoad(false)
          // toast.success(response.message)
          Swal.fire('Success!', `${response.message}`, 'success');
          fetchData()

      } else {
          // toast.error(response.message)
          Swal.fire('Error!', `${response.message}`, 'error');
          setDeleteLoad(false)

      }

  } catch (error) {
      setDeleteLoad(false)
    toast.error(error.response.data.message);

    //   toast.error("Internal Server Error..Try Again Later")
  }
}
const allowedActions = [1,2,3,4];

const columns = [
  {
    name: "S.No",
    // selector: (row, index) => currentPage * itemsPerPage + index + 1,
    selector: (row) => handleNullValue(row.serialNo),

    sortable: true,
    minWidth: '80px',
    maxWidth: '100px'
  },
  {
    name: "Site Image",
    selector: (row) => <img style={{ width: "50px" }} alt='' src={row.siteImage || '-'} />,
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  {
    name: "Site Name",
    selector: (row) => handleNullValue(row.siteName),
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  
  {
    name: "Position",
    selector: (row) => handleNullValue(row.position),
    minWidth: '150px',
    maxWidth: '200px'
  },
  ...(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Compare Exchanges" && data.actions.includes(3)) ? [{
  
    name: "Vote",
    selector: (row) => (
      <div className="form-check form-switch form-switch-md mb-3 d-flex justify-content-center align-items-center">
        <input
          type="checkbox"
          className="form-check-input"
          id="customSwitchsizemd"
          checked={row.selection === 1}
          onChange={() => handleVoteClick(row)}
          disabled={loading || row.siteIsActive !== 1}
  
        />
      </div>
    ),
    sortable: true,
    minWidth: '100px',
    maxWidth: '100px'
  }] : []),
  ...(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Compare Exchanges" && data.actions.includes(3)) ? [{
  
    name: "Status",
    selector: (row) => (
      <div className="form-check form-switch form-switch-md mb-3 d-flex justify-content-center align-items-center">
        <input
          type="checkbox"
          className="form-check-input"
          id="customSwitchsizemd"
          checked={row.siteIsActive === 1}
          onChange={() => handleUpdateStatusClick(row)}
          disabled={loading}
        />
      </div>
    ),
    sortable: true,
    minWidth: '100px',
    maxWidth: '100px'
  }] : []),
  {
    name: "Date & Time",
    selector: (row) => row.createdAt ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${handleNullValue(utcToNormalTime(row.createdAt).time)}` : "---",
    sortable: true,
    minWidth: '200px',
    maxWidth: '250px'
  },
  ...(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Compare Exchanges" && allowedActions.some(action => data.actions.includes(action))) ? [

  {
    name: "Action",
    cell: (row) => (
      <div className="d-flex">
        {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Compare Exchanges" && data.actions.includes(3))) ? (
        <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-border-color cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>
    ): null}
     {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Compare Exchanges" && data.actions.includes(2))) ? (
        <i style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline mx-1"></i>
    ): null}
     {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Compare Exchanges" && data.actions.includes(4))) ? (
        <i onClick={() => handleDelete(row)} className="mdi mdi-trash-can-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></i>
    ): null }
      </div>
    ),
    ignoreRowClick: true,
    minWidth: '150px',
    maxWidth: '200px'
  }]: []),
];
  


  //paginnation
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; 
  const pageCount = Math.ceil(totalPagination);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };
  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: itemsPerPage,
    };
    try {
      const response = await postData(Apiservices.CompareExchangesList,postForm);
      if (response.status) {
        setSessionList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  

  //Add form validation
  const formik = useFormik({
    initialValues: {
      sitename: "",
      siteurl: "",
      apiurl:'',
      isActive:"",
      position:'',
    },
    validationSchema: Yup.object({
      sitename: Yup.string().required("Site Name is required"),
      siteurl: Yup.string()
      .required("Site URL is required")
      .url("Site URL must be a valid URL"),
       apiurl: Yup.string()
      .required("API URL is required")
      .url("API URL must be a valid URL"),
      isActive: Yup.string().required("Status is required"),
      position: Yup.string()
      .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
      .required("Position is required"),
      }),
        onSubmit: (values) => {
      handleAdd(values);
    },
  });


  
  
  
//Addapicall
const handleAdd = async (data) => {
 

  setLoading(true);
  const formData = new FormData();
  formData.append("siteName", data.sitename);
  formData.append("siteUrl",data.siteurl);
  formData.append("siteApiUrl", data.apiurl);
  formData.append('files', image);
  formData.append("siteIsActive", Number(data.isActive));
  formData.append("position", Number(data.position));
  formData.append("siteId", "");
  formData.append("adminType",adminInfo?.adminType);
  formData.append("permissions",adminInfo?.permission);

  
  try {
    const response = await axiosInstance.post(Apiservices.CompareExchangesAdd, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status) {
      toast.success(response.message);
      formik.resetForm();
      setmodal_list(false);
      setLoading(false);
      fetchData();
      setImage(null)
      setSessionList('')
      setErrors('');
      setError('');
    } else {
      toast.error(response.message);
      setLoading(false);
      fetchData();
      setImage(null)
      setSessionList('')
      setErrors('');
      setError('');

    }
  } catch (error) {
    toast.error(error.response.data.message);
    setLoading(false)
    setLoading(false);
    setImage(null)
    setSessionList('')
    setErrors('');
    setError('');

  }

};

  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,
  
    initialValues: {
        sitename: "",
        siteurl: "",
        apiurl:'',
        isActive:"",
        position:'',
    },
    validationSchema: Yup.object({
        sitename: Yup.string().required("Site Name is required"),
        siteurl: Yup.string()
      .required("Site URL is required")
      .url("Site URL must be a valid URL"),
       apiurl: Yup.string()
      .required("API URL is required")
      .url("API URL must be a valid URL"),
        isActive: Yup.string().required("Status is required"),
        position: Yup.string()
        .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
        .required("Position is required"),
        }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });
  
  const handleUpdate = (record) => {
    setId(record.siteId);
    setGetImage(record.siteImage)
    validationUpdate.setValues({
      siteId: getId || "",
      sitename: record.siteName || "",
      siteurl:record.siteUrl || "",
      apiurl: record.siteApiUrl || "",
      isActive: record.siteIsActive !== undefined ? String(record.siteIsActive) : "",
      position:record.position || "",
    });
   
    setmodal_update_list(true);
  };
  
 
  const confirmUpdate = async (values) => {
    setLoading(true);
  
    // Image validation
    // if (!image && !getImage) {
    //   setError("Site image is required");
    //   setLoading(false);
    //   return;
    // }
    // if(validationUpdate.values.videostatus === '1'){
    //   const error = validateSessionLink(sessionLink);
    //   if (error) {
    //     setErrors(error);
    //     setLoading(false);
    //     return;
        
    //   }
    // }
  
    const updateformData = new FormData();
    updateformData.append("siteName", values.sitename);
    updateformData.append("siteUrl",values.siteurl);
    updateformData.append("siteApiUrl", values.apiurl);
    updateformData.append("siteIsActive", Number(values.isActive));
    updateformData.append("position", Number(values.position));
    updateformData.append("siteId", getId);

    // Image handling
    if (image) {
      updateformData.append("files", image);
    } else if (getImage) {
      updateformData.append("files", getImage);
    }
  
    try {
      const response = await axiosInstance.post(Apiservices.CompareExchangesUpdate, updateformData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      });

  
      if (response.data.status === true) {
        setmodal_update_list(false);
        toast.success(response.data.message);
        fetchData();
      setSessionList('')
      setError('')

      } else {
        toast.error(response.data.message);
        fetchData();
        setSessionList('')
        setErrors('');
      setError('')

      }
    } catch (error) {
        toast.error(error.response.data.message);
      validationUpdate.resetForm();
    setErrors('');
    setError('')


    } finally {
      setLoading(false);
    }
  };
  
  //Searchfun
  const [inputSearch, setinputSearch] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "") {
      fetchData();
      setSearchValid(false);
    } else {
      setinputSearch(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
      };

      try {
      const response = await postData(Apiservices.CompareExchangesList,postForm);

        if (response.status) {
          setSearchLoad(false);
          setSessionList(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
     
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };


const showStatusAlert = (onConfirm, row) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You want to update status!",
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Update it!',
    showLoaderOnConfirm: true, 
    preConfirm: async (reason) => {
      if (!reason) {
          Swal.showValidationMessage('Reason is required');
      } else {
          Swal.showLoading();
          try {
              await onConfirm({ ...row, reason });
              Swal.fire('Success!', 'The status has been updated successfully', 'success');
          } catch (error) {
              Swal.fire('Error!', 'There was an error updating the status', 'error');
          }
      }
  },
  allowOutsideClick: () => !Swal.isLoading(), 
  }).then((result) => {
    if (result.isConfirmed) {
   
    }
  });
};
const handleUpdateStatusClick = (row) => {
  showStatusAlert(handleToggle1, row);
}
const handleVoteClick = (row) => {
  showStatusAlert(handleToggle2, row);
}

const handleToggle1 = async (rowDetails) => {
  const updatedStatus = rowDetails.siteIsActive === 1 ? 0 : 1;
 
  try {

    const statusUpdate = new FormData();
    statusUpdate.append("siteName", rowDetails.siteName);
    statusUpdate.append("siteUrl",rowDetails.siteUrl);
    statusUpdate.append("siteApiUrl", rowDetails.siteApiUrl);
    statusUpdate.append("siteIsActive",updatedStatus);
    statusUpdate.append("position", Number(rowDetails.position));
    statusUpdate.append("siteId", rowDetails.siteId);
    statusUpdate.append("files", rowDetails.siteImage)
    statusUpdate.append("selection", rowDetails.selection)

   
    const response = await axiosInstance.post(Apiservices.CompareExchangesUpdate, statusUpdate, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 200) {
      toast.dismiss();
      toast.success(response.message);
      // Swal.fire("Success !", "The status has been updated successfully", "success");

      fetchData();
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    toast.dismiss();
    toast.error("Internal Server Error..Try Again Later");
  }
};
const handleToggle2 = async (rowDetails) => {
  
  const updatedStatusvote = rowDetails.selection === 1 ? 0 : 1;
  try {

    const statusUpdate = new FormData();
    statusUpdate.append("siteName", rowDetails.siteName);
    statusUpdate.append("siteUrl",rowDetails.siteUrl);
    statusUpdate.append("siteApiUrl", rowDetails.siteApiUrl);
    statusUpdate.append("siteIsActive",rowDetails.siteIsActive);
    statusUpdate.append("position", Number(rowDetails.position));
    statusUpdate.append("siteId", rowDetails.siteId);
    statusUpdate.append("files", rowDetails.siteImage)
    statusUpdate.append("selection", updatedStatusvote)


    const response = await axiosInstance.post(Apiservices.CompareExchangesUpdate, statusUpdate, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 200) {
      toast.dismiss();
      toast.success(response.message);
      // Swal.fire("Success !", "The status has been updated successfully", "success");

      fetchData();
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    toast.dismiss();
    toast.error("Internal Server Error..Try Again Later");
  }
};



const handleRowsPerPageChange = (newRowsPerPage) => {
  setRowsPerPage(parseInt(newRowsPerPage));
  HandleClickRowperpage(newRowsPerPage)
}
const HandleClickRowperpage = async(newRowsPerPage) => {
  setLoading(true);
  const postForm = {
    page:1,
    limit:Number(newRowsPerPage) ,
  };


  try {
    if (getSessionList === undefined) {
      const response = await postData(Apiservices.CompareExchangesList,postForm);
      if (response.status) {
        setLoading(false);
        setSessionList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } else {
      const response = await postData(Apiservices.CompareExchangesList,postForm);
      if (response.status) {
        setLoading(false);
        setSessionList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    }
  } catch (error) {
   
    toast.error("Internal Server Error..Try Again Later");
    setLoading(false);
  }

};


const handleImageChange = (e) => {
  const file = e.target.files[0];
  setImage(file);
};


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs 
          title=" Compare Other Exchanges" 
          breadcrumbItem=" Compare Other Exchanges List" 
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4">
                    <Col className="col-sm-auto">
                    <div className="input-group">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => handleSearch()}
                            disabled={searchLoad}
                          >
                            {searchLoad ? (<Spinner className="text-light" size="sm" /> ) : (<i className="ri-search-line" /> )}
                          </button>
                        </div>
                      </div>
                   
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                      {(adminType === 1 || adminInfo?.permissions.some(data => 
                  data.module === "Compare Exchanges" && data.actions.includes(1))) ? (
                        <Button
                          color="success"
                          className="add-btn"
                          onClick={() => tog_list()}
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                        </Button>
                    ):null}
                      </div>
                    </Col>
                  </Row>
                 
                    <div className="table-responsive  mt-2">
                    <DataTable
                    columns={columns}
                    data={getSessionList}
                    paginationServer
                    paginationTotalRows={getSessionList.length}
                    progressPending={loading}
                    persistTableHead={true}
                    progressComponent={<div className="py-3">Loading...</div>}
                    noDataComponent={<div className="py-3">No records found</div>}
                    fixedHeader={true}
                    
                    />
                    </div>
   

        <div className="container">
        <div className="row justify-content-end mt-4">
        <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
            <div className="d-flex align-items-center me-3">
                <span className="me-2">Rows per page:</span>
                <select
                value={rowsPerPage}
                onChange={(e) => handleRowsPerPageChange(e.target.value)}
                className="form-control-sm"
                >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                </select>
            </div>
            <div className="me-md-3 mb-3 mb-md-0">
                <span>{count}</span>
            </div>
            <div className="pagination pagination-rounded mt-3">
                <ReactPaginate
                previousLabel={<i className="mdi mdi-chevron-left"></i>}
                nextLabel={<i className="mdi mdi-chevron-right"></i>}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"break-link"}
                disabledClassName={"disabled"}
                initialPage={currentPage}
                disableInitialCallback={true}
                forcePage={currentPage}
                renderLink={(props) => {
                    if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                    return (
                        <a
                        {...props}
                        onClick={() =>
                            handlePageClick({
                            selected: pageCount - 1,
                            })
                        }
                        >
                        {pageCount}
                        </a>
                    );
                    }
                    return <a {...props} />;
                }}
                />
            </div>
            </div>
        </div>
        </div>
        

  {/* // ADDMODAL  */}
  <Modal className="modal-dialog modal-lg" isOpen={modal_list}  toggle={() => { tog_list(); }}centered>
  <ModalHeader  className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list();}}>Add Compare Other Exchanges</ModalHeader>
  <Form autoComplete="off" className="form-horizontal" 
  onSubmit={(e) => { e.preventDefault();   formik.handleSubmit();  return false; }}>
  <ModalBody>
  <Row>
    <Col md={6}>
    <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Site Name
        </label>

        <Input
          type="text"
          id="sitename-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Site Name"
          name="sitename"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.sitename || ""}
          invalid={formik.touched.sitename && formik.errors.sitename}
        />
        {formik.touched.sitename && formik.errors.sitename && <FormFeedback type="invalid">{formik.errors.sitename}</FormFeedback>}
      </div>
   
      

      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Site URL
        </label>

        <Input
          type="text"
          id="siteurl-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Site URL"
          name="siteurl"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.siteurl || ""}
          invalid={formik.touched.siteurl && formik.errors.siteurl}
        />
        {formik.touched.siteurl && formik.errors.siteurl && <FormFeedback type="invalid">{formik.errors.siteurl}</FormFeedback>}
      </div>
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">Site Image</label>
        <div> 
      <input type="file" className="form-control" accept=".png, .jpg, .jpeg"
      onChange={handleImageChange} />
        </div>
      {error && <div className="text-danger" style={{ fontSize: '12px' }}>{error}</div>}
      {image ? 
            <Card style={{ width: '200px'}}>
            <CardBody>
              <CardImg top src={image ? URL.createObjectURL(image) : ''}
                style={{ width: '100%', height: '150px', objectFit: 'cover' }}
              />
            </CardBody>
          </Card> : null}
      </div>
    </Col>
    <Col md={6}>
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         API URL
        </label>

        <Input
          type="text"
          id="apiurl-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter API URL"
          name="apiurl"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.apiurl || ""}
          invalid={formik.touched.apiurl && formik.errors.apiurl}
        />
        {formik.touched.apiurl && formik.errors.apiurl && <FormFeedback type="invalid">{formik.errors.apiurl}</FormFeedback>}
      </div>
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Position
        </label>
        <Input
          type="number"
           min="1"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Position"
          name="position"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.position || ""}
          invalid={formik.touched.position && formik.errors.position}
        />
        {formik.touched.position && formik.errors.position && <FormFeedback type="invalid">{formik.errors.position}</FormFeedback>}
      </div>
      
      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
          Status
        </label>
        <select
          id="status-field"
          name="isActive"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.isActive || ""}
          className={`form-control ${formik.touched.isActive && formik.errors.isActive ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Status
          </option>
          <option value={1}>Active</option>
          <option value={0}>Deactive</option>
        </select>
        {formik.touched.isActive && formik.errors.isActive && <FormFeedback type="invalid"> {formik.errors.isActive} </FormFeedback>}
      </div>
    </Col>
  </Row>
</ModalBody>

    <ModalFooter>
      <div className="hstack gap-2 justify-content-end">
        <button type="button" className="btn btn-light" onClick={() => {setmodal_list(false); formik.resetForm();setImage('')}}>
          Close
        </button>

        <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
          {loading ? <Loader /> : "Submit"}
        </button>
      </div>
    </ModalFooter>
  </Form>
</Modal>


     {/* UpdateModal */}
  <Modal className="modal-dialog modal-lg" isOpen={modal_update_list}  toggle={() => { tog_update_list();}} centered>
  <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>
    Update Compare Other Exchanges
  </ModalHeader>
  <Form autoComplete="off" className="form-horizontal"
    onSubmit={(e) => {
      e.preventDefault();
      validationUpdate.handleSubmit();
      return false;
    }}
  >
    <ModalBody>
      <Row>
        <Col md={6}>
        <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Site Name
        </label>

        <Input
          type="text"
          id="sitename-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Site Name"
          name="sitename"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.sitename || ""}
          invalid={validationUpdate.touched.sitename && validationUpdate.errors.sitename}
        />
        {validationUpdate.touched.sitename && validationUpdate.errors.sitename && <FormFeedback type="invalid">{validationUpdate.errors.sitename}</FormFeedback>}
      </div>

      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Site URL
        </label>

        <Input
          type="text"
          id="siteurl-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter API URL"
          name="siteurl"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.siteurl || ""}
          invalid={validationUpdate.touched.siteurl && validationUpdate.errors.siteurl}
        />
        {validationUpdate.touched.siteurl && validationUpdate.errors.siteurl && <FormFeedback type="invalid">{validationUpdate.errors.siteurl}</FormFeedback>}
      </div>
      
     
      <div className="mb-3">
      <label htmlFor="title-field" className="form-label"> Site Image  </label>
        <div>
        <input
          type="file"
          className="form-control"
          accept=".png, .jpg, .jpeg"
          onChange={handleImageChange}
          // Set value attribute to show filename or keep it blank if no file is selected
          // value={getImage ? getImage : ''}
          placeholder="No file chosen"
        />
      </div>
      {error && <div className="text-danger" style={{ fontSize: '15px' }}>{error}</div>}
      {(getImage || image) && (
        <Card style={{ width: '200px' }}>
          <CardBody>
            <CardImg top src={image ? URL.createObjectURL(image) : getImage}
              style={{ width: '100%', height: '150px', objectFit: 'cover' }}/>
          </CardBody>
        </Card>)}
      </div>
    
    
        </Col>
        <Col md={6}>

        <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         API URL
        </label>

        <Input
          type="text"
          id="apiurl-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter API URL"
          name="apiurl"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.apiurl || ""}
          invalid={validationUpdate.touched.apiurl && validationUpdate.errors.apiurl}
        />
        {validationUpdate.touched.apiurl && validationUpdate.errors.apiurl && <FormFeedback type="invalid">{validationUpdate.errors.apiurl}</FormFeedback>}
      </div>
      

      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Position
        </label>
        <Input
          type="number"
          id="subject-field"
          className="form-control"
          autoComplete="off"
           min="1"
          placeholder="Enter Position"
          name="position"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.position || ""}
          invalid={validationUpdate.touched.position && validationUpdate.errors.position}
        />
        {validationUpdate.touched.position && validationUpdate.errors.position && <FormFeedback type="invalid">{validationUpdate.errors.position}</FormFeedback>}
      </div>
    
      <div className="mb-3">
        <label htmlFor="status-field" className="form-label"> Status </label>
        <select
          id="status-field"
          //  className="form-control"
          name="isActive"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.isActive || ""}
          className={`form-control ${validationUpdate.touched.isActive && validationUpdate.errors.isActive ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Status
          </option>
          <option value={1}>Active</option>
          <option value={0}>Deactive</option>
        </select>
        {validationUpdate.touched.isActive && validationUpdate.errors.isActive && <FormFeedback type="invalid"> {validationUpdate.errors.isActive} </FormFeedback>}
      </div>
        </Col>
      </Row>
  
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-light" onClick={() => {setmodal_update_list(false);setImage('')}}>
              Close
            </button>

            <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
              {loading ? <Loader /> : "Submit"}
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>



{/* View Modal    */}
<Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static" >

<div className="modal-header">
    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
       Teams Details 
    </h5>
    <button
        onClick={() => { setShowModel(false); }}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div className="modal-body">

    <div className="table-responsive">
        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td><b>Site ID</b></td>
                    <td>{showDetails.siteId || '-'}</td>
                </tr>
                <tr>
                    <td><b>Site Name</b></td>
                    <td>{showDetails.siteName || '-'}</td>
                </tr>
                <tr>
                    <td><b>Site URL</b></td>
                    <td>{showDetails.siteUrl || '-'}</td>
                </tr>
                <tr>
                    <td><b>API URL</b></td>
                    <td>{showDetails.siteApiUrl  || '-'}

                    </td>
                </tr>
                <tr>
                    <td><b>Position</b></td>
                    <td>{showDetails.position || '-'}</td>
                </tr>
      
                <tr>
                    <td><b>Status</b></td>
                    <td>{showDetails.siteIsActive == 1 ? 'Active' : 'Deactive' || '-'}</td>
                </tr>
               
                <tr>
                    <td><b>Date & Time</b></td>
                    <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                </tr>
                <tr>
                    <td><b> Site Image</b></td>
                    <td>
                      <Card style={{ width: '200px'}}>
                      <CardBody>
                        <CardImg
                          top
                          src={showDetails.siteImage || '-'}
                          style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                        />
                      </CardBody>
                    </Card>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>

</Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CompareOtherExchange;

import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Modal, Form, InputGroup, Input, FormFeedback, Spinner, Label, ModalFooter, Tooltip } from "reactstrap";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import { useParams } from "react-router-dom";
import { formatNumber } from "../../helpers/common";
import { AddressCellFull, handleNullValue, utcToNormalTime } from "../../helpers/function";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../helpers/Spinner";


const UserOrders = () => {
  const params = useParams();

  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [appLoad, setAppLoad] = useState(false);
  const [showOTPModel, setShowOTPModel] = useState(false);
  const [showTransactionOTPModel, setShowTransactionOTPModel] = useState(false);
  
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonTransactLoading, setButtonTransactLoading] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState(null); 
  const [showTransactionModel, setShowTransactionModel] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState([]);
  // const toggleTooltip2 = () => setTooltipOpen2(!tooltipOpen2);
  const [tooltipOpenArray, setTooltipOpenArray] = useState([]);
  // const [WithdrawProceedBtn, setWithdrawProceedBtn] = useState(false);

  const toggleTooltip2 = (index) => {
    const updatedTooltipState = [...tooltipOpenArray];
    updatedTooltipState[index] = !updatedTooltipState[index];
    setTooltipOpenArray(updatedTooltipState);
  };
  const [fetchData, setFetchData] = useState({
    coinDetails: [],
    walletList: [],
    coinUserBalance: [],
    tallyAmount: [],
    updatedDetails: [],
    userDetails: [],
    overallTallyStatus: "",
  })

  const [userData, setUserData] = useState({
    userId: "", 
    // currencyId: "", 
    currencyName: "", 
    oldBalance: "", 
    newBalance: "", 
  })

  useEffect(() => {
    if (params.userId) {
      fetchWalletData(params.userId);
      setUserData({ userId: params.userId });
    }
  }, [params.userId]);

  const fetchWalletData = async (userId) => {
    try {
      setLoading(true);
      setError(null);
      const res = await postData(Apiservices.TRANSACTION_TALLY_LIST, {
        userId,
      });

      const resOrder = await postData(Apiservices.GetUserTradeHistory, {
        userId,
      });

      if (res.status) {
        if (res.data && res.data.data) {
          const { userDetails } = res.data.data;
        console.log("res.data===============", resOrder.data);
        
          setFetchData((prev) => ({ ...prev, 
            coinDetails: "", 
            tallyAmount: "",
            updatedDetails: "",
            coinUserBalance: "",
            walletList: resOrder.data.data,
            userDetails: userDetails,
            overallTallyStatus: ""
          }))
          
        }
      } else {
        setError("Failed to fetch data");
      }
    } catch (error) {
      console.log("fetchWalletData list err: ", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDateTimeModify = (date) => {
    if (date) {
      const formattedDate = utcToNormalTime(date);
      return `${handleNullValue(formattedDate.date)} ${formattedDate.time}`;
    } else {
      return "---";
    }
  };


  const handleShowUserBalance = (data) => {
    if (fetchData.coinUserBalance?.length > 0) {
      const balance = fetchData.coinUserBalance.find((balance) =>
        balance?.balances?.find((user) => user.currency === data.currency)
      );

      if (balance) {
        const userBalance = balance.balances.find(
          (user) => user.currency === data.currency
        );
        // return formatNumber(userBalance?.amount, data.decimalPoint);
        return userBalance?.amount
      }
    }
    return 0;
  };

//   const startCountdown = () => {
//     const intervalId = setInterval(() => {
//         setCountdown(prevCountdown => {
//             const newCountdown = prevCountdown - 1;
//             if (newCountdown <= 0) {
//                 clearInterval(intervalId);
//                 setShowOTPModel(false);
//                 validation.resetForm()
//                 // setIsDisabled(false)
//                 return 0;
//             }

//             return newCountdown;
//         });
//     }, 1000);
// };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
      .required("OTP is required").matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
    }),
    onSubmit: (values) => {
      otpVerify(values);
    },
  });
  const otpVerify = async (data) => {
    setAppLoad(true);
   
    const statusdata = {
      userId: userData.userId, 
      status: "Verified", 
      currencyName: userData.currencyName, 
      oldBalance: userData.oldBalance, 
      newBalance: userData.newBalance,
      otp: data.otp
    };

    try {
      const response = await postData(Apiservices.TALLY_USER_BALANCE_UPDATED, statusdata);
      if (response.status) {
        toast.success(response.message);
        setAppLoad(false);
        setShowOTPModel(false);
        setButtonLoading(false);
        // setmodal_update_list(false);
        if (params.userId) {
          fetchWalletData(params.userId);
        }
        validation.resetForm();
      } else {
        toast.dismiss();
        toast.error(response.message);
        setLoading(false);
        // setmodal_list(false);
        setAppLoad(false);
      }
    } catch (error) {
      toast.dismiss();

      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
      setAppLoad(false);
    }
  };


  const handleApprove = async (details) => {
    try {
      if(details) {
          // setCountdown(60)
          setShowOTPModel(true)
          // startCountdown()
          setButtonLoading(true);
        let oldBalance = await handleShowUserBalance(details);
        setUserData((prev) => ({ ...prev, 
          currencyName: details.currency, 
          oldBalance: oldBalance, 
          newBalance: details?.balance
        }))
        setActiveCurrency(details.currency);
    }
    } catch (error) {
      console.log("handleApprove btn error: ", error);
      
        setButtonLoading(false);
        setActiveCurrency(null);
        toast.error("Internal Server Error..Try Again Later");

    }
};
const transactionValidation = useFormik({
  enableReinitialize: true,
  initialValues: {
    otp: "",
  },
  validationSchema: Yup.object({
    otp: Yup.string()
    .required("OTP is required").matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
  }),
  onSubmit: (values) => {
    otpTransactionVerify(values);
  },
});
const otpTransactionVerify = async (data) => {
  setAppLoad(true);

  const statusdata = {
    userId: userData.userId, 
    orderId: transactionDetail.orderId,
    status: "history_transaction", 
    currencyName: transactionDetail.currency, 
    // userBalance: transactionDetail.runningBalance, 
    newBalance: transactionDetail.amount, 
    otp: data.otp
  };
  try {
    const response = await postData(Apiservices.ADMIN_UPDATE_ORDER_MODIFIED, statusdata);
    if (response.status) {
      toast.success(response.message);
      setAppLoad(false);
      setShowTransactionOTPModel(!showTransactionOTPModel);
      setShowTransactionModel(!showTransactionModel);
      setButtonTransactLoading(false);
      // setmodal_update_list(false);
      if (params.userId) {
        fetchWalletData(params.userId);
      }
      transactionValidation.resetForm();
    } else {
      toast.dismiss();
      toast.error(response.message);
      setLoading(false);
      setButtonTransactLoading(false);
      // setmodal_list(false);
      setAppLoad(false);
    }
  } catch (error) {
    toast.dismiss();

    toast.error("Internal Server Error..Try Again Later");
    setLoading(false);
    setAppLoad(false);
  }
};
const handleTransactionApproved = () => {
  try {
    // setCountdown(60)
    setShowTransactionOTPModel(true)
    // startCountdown();
    setButtonTransactLoading(true);
  } catch (error) {
    console.log("handleApprove btn error: ", error);
    setButtonTransactLoading(false);
    // setActiveCurrency(null);
    toast.error("Internal Server Error..Try Again Later");
  }
};
const handleModalClick = (data) => {
  if(data) {
    setTransactionDetail(data);
    setShowTransactionModel(!showTransactionModel)
  }
}

const getBadgeClass = (code) => {
  switch (code) {
    case 'Matched':
      return "badge-soft-success";
    case 'Not-Matched':
      return "badge-soft-danger";
    default:
      return "";
  }
};

const pairLabelShow = (data) => {
  
  switch (data.transType) {
    case 'trade-buy':
      return `( ${(data.pair) ? data.pair : '-'} ${data.side ? '/ ' + data.side : ''} )`
    case 'trade-map':
      return `( ${(data.pair) ? data.pair : '-'} ${data.side ? '/ ' + data.side : ''} )`
    case 'trade-sell':
      return `( ${(data.pair) ? data.pair : '-'} ${data.side ? '/ ' + data.side : ''} )`
    case 'trade-cancel':
      return `( ${(data.pair) ? data.pair : '-'} ${data.side ? '/ ' + data.side : ''} )`
    default:
     return '';
  }
  // (data.transType =='trade-buy') && `(${data.pair ? data.pair : '-'})`
}


const nameStyle = {
  fontSize: '16px',
  color: '#666',
  marginBottom: '8px',
};

const emailStyle = {
  fontSize: '16px', 
  color: '#666',
};

if (loading) return <div>Loading...</div>;
if (error) return <div>Error: {error}</div>;


  return (
    <React.Fragment>
      <div className="page-content mb-1">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="d-flex justify-content-center align-items-center">
                <h1> User orders List </h1>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <Card
                  style={{
                    width: "800px",
                    height: "auto",
                    padding: "40px",
                    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <CardBody>
                    <div className="table-responsive">
                      <div>
                        <div style={nameStyle}>
                        Name: {fetchData.userDetails ? fetchData.userDetails[0].fullName : '-'}
                        </div>
                        <div style={emailStyle}>
                          Email: {fetchData.userDetails ? fetchData.userDetails[0].email : '-'}
                        </div>
                      </div>
                      
                      
                      
                      
                    </div>
                  </CardBody>
                </Card>
              </div>

              <Card>
                <CardBody>
                  <div className="d-flex justify-content-start align-items-start mb-4">
                    {/* <span> User Transaction History </span> */}
                    <h4> Order List </h4>

                  </div>
                  <div className="table-responsive">
                    <table
                      className="table table-bordered mb-0"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr >
                          <th style={{ backgroundColor:'black', color: 'white'  }} scope="col">SI.No</th>
                          <th style={{ backgroundColor:'black', color: 'white'  }} scope="col">Date</th>
                          <th style={{ backgroundColor:'black', color: 'white'  }} scope="col">Pair</th>
                          <th style={{ backgroundColor:'black', color: 'white'  }} scope="col">Type</th>
                          <th style={{ backgroundColor:'black', color: 'white'  }} scope="col">Amount</th>
                          <th style={{ backgroundColor:'black', color: 'white'  }} scope="col">Price</th>
                          <th style={{ backgroundColor:'black', color: 'white'  }} scope="col">Status</th>
                          <th style={{ backgroundColor:'black', color: 'white'  }} scope="col">Action</th>

                        </tr>
                      </thead>
                      <tbody>
                        {fetchData.walletList &&
                          fetchData.walletList.length > 0 &&
                          fetchData.walletList.map((data, index) => (
                            <tr>
                              <th scope="row" style={{ backgroundColor:  (data.status == 'filled') ? '#ffd580' : (data.status == 'cancelled') ? '#bf40bf' :   '#ffff00', color: 'black', fontWeight: 'bold' }}>{index + 1}</th>
                              <td style={{ backgroundColor:  (data.status == 'filled') ? '#ffd580' : (data.status == 'cancelled') ? '#bf40bf' :   '#ffff00', color: 'black', fontWeight: 'bold' }} >{handleDateTimeModify(data.createdAt)}</td>
                              <td style={{ backgroundColor:  (data.status == 'filled') ? '#ffd580' : (data.status == 'cancelled') ? '#bf40bf' :   '#ffff00', color: 'black', fontWeight: 'bold' }}>{data.pair.replace("_","-")}</td>
                              <td style={{ backgroundColor:  (data.status == 'filled') ? '#ffd580' : (data.status == 'cancelled') ? '#bf40bf' :   '#ffff00', color: 'black', fontWeight: 'bold' }}>{data.type} {pairLabelShow(data)}</td>
                              <td style={{ backgroundColor:  (data.status == 'filled') ? '#ffd580' : (data.status == 'cancelled') ? '#bf40bf' :   '#ffff00', color: 'black', fontWeight: 'bold' }}>
                                {formatNumber(data.amount, 6)}
                              </td>
                              <td style={{ backgroundColor:  (data.status == 'filled') ? '#ffd580' : (data.status == 'cancelled') ? '#bf40bf' :   '#ffff00', color: 'black', fontWeight: 'bold' }}>
                                {formatNumber(data.price, 6)}
                              </td>

                              <td style={{ backgroundColor:  (data.status == 'filled') ? '#ffd580' : (data.status == 'cancelled') ? '#bf40bf' :   '#ffff00', color: 'black', fontWeight: 'bold' }}>{data.status}</td>
                              <td style={{ backgroundColor:  (data.status == 'filled') ? '#ffd580' : (data.status == 'cancelled') ? '#bf40bf' :   '#ffff00', color: 'black', fontWeight: 'bold' }}>
                                  {data.status != 'cancelled' ?
                                  <>

                                  <span id={`tooltipClose${index}`} onClick={() => handleModalClick(data)} className="mdi mdi-close-box" style={{ fontSize: '15px', cursor: 'pointer' }}></span>

                                    <Tooltip
                                      placement="top"
                                      isOpen={tooltipOpenArray[index] || false}
                                      target={`tooltipClose${index}`}
                                      toggle={() => toggleTooltip2(index)}
                                    >
                                      Cancel Order
                                    </Tooltip>
                                  </>
                                  : "-"
                                }

                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Modal
              className="modal-dialog modal px-4"
              size="l"
              isOpen={showOTPModel}
              centered
              backdrop="static"
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                  Approve Confirmation OTP
                </h5>
                <button
                  onClick={() => {
                    setShowOTPModel(false); setButtonLoading(false);
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <div className="text-center"></div>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md={12} className="AuthStar">
                        <div className="mb-4">
                          <Label className="form-label">
                            One Time Password<span>*</span>
                          </Label>
                          <InputGroup>
                            <Input
                              style={{ borderColor: "#80838B" }}
                              name="otp"
                              // disabled={
                              //   loading || countdown === 60 || countdown === 0
                              // }
                              disabled={
                                loading 
                              }
                              className="form-control"
                              placeholder="Enter OTP"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.otp || ""}
                              invalid={
                                validation.touched.otp && validation.errors.otp
                                  ? true
                                  : false
                              }
                            />

                            {validation.touched.otp && validation.errors.otp ? (
                              <FormFeedback>
                                {validation.errors.otp}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>

                        <div className="d-grid mt-4">
                                <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            disabled={appLoad || appLoad}
                            onClick={validation.handleSubmit}
                          >
                            {appLoad ? (
                              <>
                                <Spinner size="sm">Loading...</Spinner>
                                <span style={{ color: "white" }}>
                                  {" "}
                                  Loading...
                                </span>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Modal>

            {/* confirm transaction */}
            <Modal className='modal-dialog modal-lg' size="l" isOpen={showTransactionModel} toggle={() =>  setShowTransactionModel(!showTransactionModel)} centered backdrop="static" >

                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
                      Order Details View & Approve
                    </h5>
                    <button
                        onClick={() => { setShowTransactionModel(!showTransactionModel); }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>


                <div className="modal-body custom-modal-body">

                    <div className="table-responsive">
                        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                            <tbody>
                                                              
                                <tr>
                                    <td><b>Type</b></td>
                                    <td>{transactionDetail?.type || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Pair</b></td>
                                    <td>{transactionDetail?.pair?.replace("_","-") || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Order type</b></td>
                                    <td>{transactionDetail?.orderType || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Amount</b></td>
                                    <td>{formatNumber(transactionDetail?.amount || 0, 8)} {transactionDetail?.fromCurrency} </td>
                                </tr>
                                <tr>
                                    <td><b>Price</b></td>
                                    <td>{formatNumber(transactionDetail?.price || 0, 8)} {transactionDetail?.toCurrency} </td>
                                </tr>
                                <tr>
                                  <td><b>Status</b></td>
                                  <td>{transactionDetail?.status || '-'}</td>
                                </tr>                                
                                <tr>
                                    <td><b>Request Date & Time</b></td>
                                    <td>{utcToNormalTime(transactionDetail?.createdAt).date} {utcToNormalTime(transactionDetail?.createdAt).time}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <ModalFooter>
                              <div className="hstack gap-2 justify-content-end">
                    <button onClick={()=>{setShowTransactionModel(!showTransactionModel); setButtonTransactLoading(false);}} className="btn btn-danger waves-effect waves-light" type="submit">
                          cancel
                    </button>

                        <button onClick={() => handleTransactionApproved()} className="btn btn-primary waves-effect waves-light" type="submit" disabled={buttonTransactLoading}>
                            {buttonTransactLoading ? <Loader /> : "Confirm"}
                        </button>
                    </div>
                </ModalFooter>
              </Modal>

              <Modal
              className="modal-dialog modal px-4"
              size="l"
              isOpen={showTransactionOTPModel}
              centered
              backdrop="static"
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                  Approve Confirmation OTP
                </h5>
                <button
                  onClick={() => {
                    setShowTransactionOTPModel(false); setButtonTransactLoading(false);
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <div className="text-center"></div>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      transactionValidation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md={12} className="AuthStar">
                        <div className="mb-4">
                          <Label className="form-label">
                            One Time Password<span>*</span>
                          </Label>
                          <InputGroup>
                            <Input
                              style={{ borderColor: "#80838B" }}
                              name="otp"
                              // disabled={
                              //   loading || countdown === 60 || countdown === 0
                              // }
                              disabled={loading }
                              className="form-control"
                              placeholder="Enter OTP"
                              type="text"
                              onChange={transactionValidation.handleChange}
                              onBlur={transactionValidation.handleBlur}
                              value={transactionValidation.values.otp || ""}
                              invalid={
                                transactionValidation.touched.otp && transactionValidation.errors.otp
                                  ? true
                                  : false
                              }
                            />

                            {transactionValidation.touched.otp && transactionValidation.errors.otp ? (
                              <FormFeedback>
                                {transactionValidation.errors.otp}
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>

                        <div className="d-grid mt-4">
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            // disabled={appLoad || countdown === 60 || appLoad}
                            disabled={appLoad}
                            onClick={transactionValidation.handleSubmit}
                          >
                            {appLoad ? (
                              <>
                                <Spinner size="sm">Loading...</Spinner>
                                <span style={{ color: "white" }}>
                                  {" "}
                                  Loading...
                                </span>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserOrders;

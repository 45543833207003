import React, { useState, useEffect } from "react";
import {  Input, Card, CardBody, Col, Container, InputGroup,Row, Spinner,Button} from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue ,utcToNormalTime} from "../../helpers/function";
import Loader from "../../helpers/Spinner";

const WellcomeBonus = () => {
    document.title = "Ultrapro | User Signup Bonus";


    const [loading, setLoading] = useState(false);
    const [searchLoad, setSearchLoad] = useState(false);
    const [searchValid, setSearchValid] = useState(false);
    const [count, setCount] = useState('');
    const [inputSearch, setinputSearch] = useState('');
    const [formData, setFormData] = useState([]);
    const [totalPagination, setTotalPagination] = useState(1);
    const [totalLimit, setTotalLimit] = useState(1);
    const [user, setUser] = useState(Apiservices.USER_WELCOME_BONUS);
  

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = totalLimit; // Number of items to display per page
    const pageCount = Math.ceil(totalPagination);

    useEffect(() => {
     
            ipList();
    
    }, []);






    const handleFilter = async (apiName) => {
        setLoading(true);
        // const postForm = {
        //     ...filterParams
        // };
        try {
            const response = await postData(apiName,{});
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);

            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };

 

    const ipList = async () => {
        setLoading(true);

        try {
            const response = await postData(Apiservices.USER_WELCOME_BONUS,{});
            if (response.status) {
                if(response.data.length !== 0){
                setFormData(response.data.data);
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit);
                setCount(response.data.currentCount);
               


            }
            else{
                setFormData(response.data);
                setLoading(false);

                // setDateRange("")
            }
            } else {
                toast.error(response.message);
                setLoading(false);

            }
        } catch (error) {
            toast.error("Internal Server Error...");
            setLoading(false);


        }
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        handlePagePost(selected);
    };

    const handlePagePost = async (selected) => {
        setLoading(true);
        const postForm = {
            page: selected + 1,
            // limit: itemsPerPage,
            search: inputSearch,
        };

        if (dateRange[0] && dateRange[1]) {


            postForm.startDate = formatDate(dateRange[0]);
            postForm.endDate = formatDate(dateRange[1]);
        }
        
        const queryString = new URLSearchParams(postForm).toString();
        try {
                const response = await postData(`${Apiservices.USER_WELCOME_BONUS}?${queryString}`);

                if (response.status) {
                    setLoading(false);
                    setFormData(response.data.data);
                    setTotalPagination(response.data.totalPages);
                    setTotalLimit(response.data.limit);
                    setCount(response.data.currentCount);

                } else {
                    setLoading(false);
                    toast.error(response.message);
                }

           
         
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true,
            grow:1,

        },
       
        {
            name: 'Name',
            selector: row =>handleNullValue(row.welcomeBonus && row.welcomeBonus.fullName),
            sortable: true,
            grow:2,

           
        },
        {
            name: 'Mail Id',
            selector: row =>handleNullValue(row.welcomeBonus && row.welcomeBonus.email),
            sortable: true,
            grow: 3,
        },
       
        {
            name: 'Signup Amount',
            selector: row =>  (row.amount ? `${row.amount} USDT` : '-'),
            sortable: true,
            grow:2,
        },
        {
            name: 'Transaction ID',
            selector: row => handleNullValue(row.transId),
            sortable: true,
            grow: 2,

         
        },

        {
            name: 'Registered Date & Time',
            selector: row => row.createdAt
                ? `${utcToNormalTime(row.createdAt).date} ${utcToNormalTime(row.createdAt).time}`
                : "-",
            sortable: true,
            grow: 2,

        },
      
    ];

  


    const handleSearchChanage = (e) => {
        const value = e.target.value
        if (value === '' && user === undefined) {
            ipList()
            setSearchValid(false)
        }else if(value === ''){

            handleFilter(user)
        }
        setinputSearch(value)

    }


    const handleSearch = async () => {
        if (inputSearch !== '') {
            setSearchLoad(true);
            const postForm = {
                search: inputSearch,
                // page: currentPage + 1,
                // limit: itemsPerPage,
            };

            const queryString = new URLSearchParams(postForm).toString();
            try {
               
                    const response = await postData(`${Apiservices.USER_WELCOME_BONUS}?${queryString}`,);
                    if (response.status) {
                        if(response.data.length !== 0){
                    
                            setSearchLoad(false);
                            setFormData(response.data.data);
                            setTotalPagination(response.totalPages);
                            
                            }
                            else{
                            setFormData(response.data);
                            setSearchLoad(false);
                            // setDateRange("")
                            }
                        
                        // toast(response.message);
                    } else {
                        setSearchLoad(false);
                    }
                
            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
                setSearchLoad(false);
            }
        } else {
            setSearchValid(true)
        }
    }


   
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    const [dateRange, setDateRange] = useState([null, null]);
    const today = new Date();
    const handleDateChange = ([start, end]) => {
 

        const formattedStart = start ? formatDate(start) : null;
        const formattedEnd = end ? formatDate(end) : null;

        setDateRange([start, end]);

        if (!formattedStart && !formattedEnd) {    
            handleFilter(user);
        }
     

        if (formattedStart && formattedEnd) {
            handleDate({ startDate: formattedStart, endDate: formattedEnd });
        }
       
    };
    const handleDate = async ({ startDate, endDate }) => {
    
        setLoading(true);
   

        const postForm={
            startDate:startDate,
            endDate:endDate
        }
        try {
            const queryString = new URLSearchParams(postForm).toString();
            
            const response = await postData(`${Apiservices.USER_WELCOME_BONUS}?${queryString}`);
            if (response.status) {
                if(response.data.length !== 0){
                    setLoading(false);
                    setFormData(response.data.data)
                     setTotalPagination(response.data.totalPages);
                     setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);

                    }
                    else{
                    setFormData(response.data);
                    setSearchLoad(false);
                    setLoading(false);

                    // setDateRange("")
                    }
               
                
            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            // toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const [selectedFormat, setSelectedFormat] = useState('');
    const [downLoading, setDownLoading] = useState(false);

    // const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    // const exportOptions = [
    //     { label: 'PDF', value: 'pdf' },
    //     { label: 'CSV', value: 'csv' },
    //     { label: 'XLSX', value: 'xlsx' }
    // ];
    const handleDownload = async (format) => {
        // setSelectedFormat(format.toUpperCase());
        setDownLoading(true)
        try {
            const postForm = {};

            if (dateRange[0] && dateRange[1]) {
                postForm.startDate = formatDate(dateRange[0]);
                postForm.endDate = formatDate(dateRange[1]);
            }

            if (inputSearch) {
                postForm.search = inputSearch;
            }
            // if (FileName) {
            //     postForm.filter = FileName;
            // }
            postForm.download = format;
            const queryString = new URLSearchParams(postForm).toString();

            const response = await axiosInstance.post(`${Apiservices.USER_WELCOME_BONUS}?${queryString}`, {
                // Include any data you need to send with the POST request here
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: 'blob'
            });
            if (response.data) {
                setDownLoading(false)
                const blob = response.data;

                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                // link.setAttribute('download', `${user}.xlsx`);
                link.setAttribute('download', `UserSignupBonusHistory.${format}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                toast.error(response.data.message)
                setDownLoading(false)

            }
        } catch (err) {
            setDownLoading(false)
            console.error('Error downloading the file', err);
        }
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Transaction History" breadcrumbItem="Signup Bonus History" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col sm={4} lg={3}>
                                            <div className="input-group">
                                                <Input
                                                    // onChange={handleInputChange}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search ..."
                                                    aria-label="Recipient's username"
                                                    onChange={handleSearchChanage}
                                                    invalid={searchValid}
                                                    onKeyPress={handleKeyPress}
                                                />
                                                <div className="input-group-append">

                                                    <button className="btn btn-primary" type="submit" onClick={()=>handleSearch()} disabled={searchLoad}>
                                                        {searchLoad ? (

                                                            <Spinner className="text-light" size="sm" />
                                                        ) : (

                                                            <i className="ri-search-line" />
                                                        )}

                                                    </button>


                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={4} lg={3}>
                                        <div className="form-group mb-4">
                                            
                                            <div>
                                                <InputGroup>
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        placeholder="Select Start to End Date"
                                                        options={{
                                                            mode: 'range',
                                                            altInput: true,
                                                            altFormat: "F j, Y",
                                                            dateFormat: "Y-m-d",
                                                            maxDate: today,
                                                            
                                                        }}
                                                       value={dateRange}
                                                        onChange={handleDateChange}
                                                    />
                                                </InputGroup>
                                            </div>

                                        </div>
                                        </Col>
                                        <Col className="col-sm">

                                            <div className="d-flex justify-content-sm-end">
                                              
                                                 {/* <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                            <DropdownToggle tag="button" className="btn btn-info">
                                            {downLoading ? <Loader /> : (selectedFormat || "Download")} 
                                             <i className="mdi mdi-chevron-down" />
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem header>Select Format</DropdownItem>
                                            {exportOptions.map((option, index) => (
                                                <DropdownItem 
                                                    key={index} 
                                                    onClick={() => handleDownload(option.value)}
                                                    disabled={downLoading} // Disable options while loading
                                                >
                                                    {option.label}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                        </Dropdown> */}

                                <Button
                          color="info"
                          className="add-btn mx-2"
                          onClick={() => handleDownload('xlsx')}
                          id="create-btn"
                          disabled={downLoading}
                        >
                          <i className="mdi mdi-download-outline"></i>

                          {downLoading ? <Loader /> : "Download"}
                        </Button>

                                             

                                            </div>
                                        </Col>
                                    </Row>


                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={formData}
                                            paginationServer
                                            paginationTotalRows={formData.length}
                                            progressPending={loading}
                                            persistTableHead={true}
                                            progressComponent={<div>Loading...</div>}
                                            // noDataComponent={formData.length===0}
                                            noDataComponent={<div className="py-3">No records found</div>}
                                            fixedHeader={true}
                                            
                                        />
                                    </div>

                                    <div className="row mt-3">
                                         <div className="col-12 col-md-6">
                                        <div className="d-flex justify-content-start mt-3">
                                        <span>Current Count : {count}</span>
                                    </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                    {loading ? (
                                        ""

                                    ) : (

                                        <div className="d-flex justify-content-end mt-3">
                                            <div className="pagination pagination-rounded mb-0">
                                                <ReactPaginate
                                                    previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                    nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    activeClassName={"active"}
                                                    pageLinkClassName={"page-link"}
                                                    breakLinkClassName={"break-link"}
                                                    disabledClassName={"disabled"}
                                                    initialPage={currentPage}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                    // disableInitialCallback={true}
                                                    renderLink={(props) => {
                                                        if (
                                                            props.page === "..." &&
                                                            props.pageCount > props.pageRangeDisplayed
                                                        ) {
                                                            return (
                                                                <a
                                                                    {...props}
                                                                    onClick={() =>
                                                                        handlePageClick({
                                                                            selected: pageCount - 1,
                                                                        })
                                                                    }
                                                                >
                                                                    {pageCount}
                                                                </a>
                                                            );
                                                        }
                                                        return <a {...props} />;
                                                    }}
                                                />
                                            </div>


                                        </div>
                                    )}
                                     </div>
                                     </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default WellcomeBonus;

import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, Col, Tooltip, FormFeedback, Label, Form, Container, Row, Spinner, DropdownMenu, DropdownItem, DropdownToggle, InputGroup, Dropdown, Modal, ModalFooter, } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import * as Yup from "yup";
import axiosInstance, { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { AddressCell, AddressCellFull, handleNullValue, percentCalculate, utcToNormalTime } from "../../helpers/function";
import { useFormik } from "formik";
import Swal from 'sweetalert2';
import { formatNumber } from "../../helpers/common";
import { useLocation,Link } from "react-router-dom";
import { AdminContext } from "../../helpers/adminContext";

const CryptoPendingWithdraw = () => {
    const { adminInfo } = useContext(AdminContext);
    document.title = "Ultrapro | Withdraw History";
    const today = new Date();
    const location = useLocation();
    const userApi = location.state?.user;
    const [downloadStartDate, setDownloadEndDate] = useState({});
    const [dateRange, setDateRange] = useState([null, null]);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [countdown, setCountdown] = useState(60);
    const [showOTPModel, setShowOTPModel] = useState(false)
    const [user, setUser] = useState(userApi);
    const [downLoading, setDownLoading] = useState(false);
    const [showDetails, setShowDetails] = useState({})    
    const [showModel, setShowModel] = useState(false)
    const [searchLoad, setSearchLoad] = useState(false);
    const [btnauto3, setbtnauto3] = useState(false);
    const [btnauto4, setbtnauto4] = useState(false);
    const [searchValid, setSearchValid] = useState(false);
    const [inputSearch, setinputSearch] = useState('');
    const [formData, setFormData] = useState([]);
    const [totalPagination, setTotalPagination] = useState(1);
    const [totalLimit, setTotalLimit] = useState(1);
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const [isRejected, setIsRejected] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [tooltipOpen_tally, setTooltipOpen_tally] = useState(false);
    const itemsPerPage = totalLimit; 
    const pageCount = Math.ceil(totalPagination);
    const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1);
    const toggleTooltip_tally = () => setTooltipOpen_tally(!tooltipOpen_tally);
    const [isDisabled, setIsDisabled] = useState(false);
    const [count, setCount] = useState("");
    const [load,setLoad] = useState(false)
    const [adminType,setAdminType] = useState('');
    const [adminId,setAdminId] = useState('')
    const [rowsPerPage,setRowsPerPage] = useState(10);
    const allowedActions = [1,2,3,4];
    const [walletType, setWalletType] = useState('Wallet Type');
    const [withdrawType, setWithdrawType] = useState('Withdraw Type');
    const [isLockedData,setIsLockData] = useState({})
    const [isLocked,setIsLock] = useState(false);

    useEffect(() => {
        ipList();
        setAdminType(adminInfo?.adminType)
        setAdminId(adminInfo?.adminUserId)
    }, [adminInfo?.adminType,adminInfo?.adminUserId]);

   
    const ipList = async () => {
        setLoading(true);
        const postForm ={
            status:'Pending'
        }
        try {
            const response = await postData(Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY,postForm);
            console.log(response,"44444444444444444")
            if (response.status) {
                setLoading(false);
                if(response?.data !== null) {
                    setFormData(response?.data?.data);
                    setTotalPagination(response?.data?.totalPages);
                    setTotalLimit(response?.data?.limit)
                    setCount(response?.data?.currentCount);
                } else {
                    setFormData([]);
                    setTotalPagination(0);
                    setTotalLimit(0)
                    setCount("");
                }
            } else {
                toast.error(response.message);
                setLoading(false);

            }
        } catch (error) {       
            toast.error("Internal Server Error...");
            setLoading(false);
        }
    };

    const startCountdown = () => {
        setIsDisabled(true);
        const intervalId = setInterval(() => {
            setCountdown(prevCountdown => {
                const newCountdown = prevCountdown - 1;
                if (newCountdown <= 0) {
                    clearInterval(intervalId);
                    setIsDisabled(false);
                    return 0;
                }
                return newCountdown;
            });
        }, 1000);
    };


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            otp: '',
            transactionId: '',
            withdrawType: showDetails?.withdrawType || 0
        },
        validationSchema: Yup.object({
            otp: Yup.string()
            .required('OTP is required'),
            transactionId: Yup.string()
            .when('withdrawType', (withdrawType, schema) => {
                // If withdrawType is 1, make transactionId required, otherwise don't validate it
                return withdrawType === 1 
                    ? schema.required('Transaction ID is required') // Validate
                    : schema.notRequired(); // Skip validation
            })
            // transactionId: !isRejected || showDetails.withdrawType !== 1 && Yup.string().required('Transaction Id is required'),
        }),
        onSubmit: (values) => {
            otpVerify(values)
        },
    });




// Main otpVerify function
const otpVerify = async (data) => {
    setButtonLoading(true);
    try {
        // Call wallet API first
        const walletResponse = await postData(Apiservices.withDrawWalletId, {
            walletTxid: data.transactionId,
            transactionId:data.transactionId,
            withdrawId : showDetails?._id,
            status: 'Pending',
            otp: data.otp,
            adminId:adminId
        });

        if (walletResponse.status) {
            toast.success(walletResponse.message);
            setButtonLoading(false);
            setShowOTPModel(false);
            ipList(); 
            validation.resetForm();
        } else {
            toast.dismiss();
            toast.error(walletResponse.message);
            setLoading(false);
            setShowOTPModel(true);
            setButtonLoading(false);

            
        }
    } catch (error) {
        toast.dismiss();
        toast.error(error.message);
        setLoading(false);
        setButtonLoading(false);
        setShowOTPModel(true);
    }
};

    
    
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        handlePagePost(selected);
    };

    const handlePagePost = async (selected) => {
        setLoading(true);
        const postForm = {
            page: selected + 1,
            status: 'Pending',
            withdrawType:withType,
            walletType:wallType
        };
        if (dateRange[0] && dateRange[1]) {
            postForm.startDate = formatDate(dateRange[0]);
            postForm.endDate = formatDate(dateRange[1]);
        }
        if (inputSearch) {
            postForm.search = inputSearch;
        }
        try {
            const response = await postData(Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY,postForm);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);
            } else {
                setLoading(false);
                toast.error(response.message);
            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };



    const handleShowModel = (value) => {
        setShowDetails(value)
        setShowModel(true)
        setIsLock(value)
        setIsLockData(value)
       
    }
   
    const handleRejectedModel = (row) => {      
        setShowModel(false)
        setIsRejected(true);
        setShowDetails(row)
        showRejectedModel(row);
    };
  
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(parseInt(newRowsPerPage));
      }
  
    const handleTallyRedirect = async (userData) => {        
        let userId = userData?.userId
        if(userId){
            window.open(`/tallyDetails/${userId}`, '_blank');
        }
    }
    const showRejectedModel = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to reject the withdrawal!",
            inputLabel: "Enter a Reason",
            input: "textarea",
            inputAttributes: {
                autocapitalize: "off",
                autocomplete: "off"
            },
            inputPlaceholder: "Enter Reason",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update it!',
            inputValue: '',
            preConfirm: (reason) => {
                if (!reason) {
                    Swal.showValidationMessage('Reason is required');
                    return false;
                } else {
                    setButtonLoading(true);
                    Swal.showLoading();
                        return handleReject(row,reason).then(() => {
                        Swal.close();
                    }).catch((error) => {
                        Swal.showValidationMessage('Failed to update status, try again!');
                    });
                }
            },
            allowOutsideClick: () => !Swal.isLoading() 
        });
    };
    const handleUnlockWithdraw =async (row) =>{
        const postForm = {
            withdrawId :row._id,
            adminId:adminInfo?.adminUserId
        };
        console.log(row,"444444444444")

        setLoading(true);
       
        try {
            const response = await postData(Apiservices.WITHDRAW_UNLOCK_BYSUPERADMIN_LOCKED,postForm);
            console.log(response,"44444444444444444")
            if (response.status) {
                setLoading(false);
                if(response.status) {
                    toast.success(response.message)
                    ipList()
                } else {
                    console.log('')
                }
            } else {
                toast.error(response.message);
                setLoading(false);

            }
        } catch (error) {       
            toast.error("Internal Server Error...");
            setLoading(false);
        }

    }

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true,
            minWidth:'100px'
        },
        {
            name: 'Date',
            selector: row => row.createdAt
                ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${(utcToNormalTime(row.createdAt).time)}`
                : "---",
            sortable: true,
            minWidth:'200px'

        },
        {
            name: 'Name',
            selector: row => handleNullValue(row.userDetails?.fullName),
            sortable: true,
            minWidth:'150px'
        },
        {
            name: "OS Type",
            selector: (row) => (row.userDetails?.osType == 0 ? 'Web' : 'Android' || '-'),
            sortable: true,
            minWidth: '150px',
          },
        {
            name: 'Email',
            selector: row => 
                <div className="d-flex align-items-center" >
            <Link className="link-tag" to={`/allUsers/userDetails/${row.userId}`} target='_blank'>
              <span className="mx-1 text-decoration-underline">
                {row.userDetails?.email || '-'}
              </span>
            </Link>
          </div>,
            sortable: true,
            minWidth:'250px'
        },
        
        {
            name: 'Transaction ID',
            selector: row => <AddressCell address={row.transactionId} />,
            sortable: true,
            minWidth:'150px'
        },
        {
            name: 'Currency Name',
            selector: row => handleNullValue(row.currency),
            sortable: true,
            minWidth:'150px'
        },
        {
            name: 'Address',
            selector: row => <AddressCell address={row.withdrawAddress} />,
            sortable: true,
           minWidth:'200px'
        },
        {
            name: 'Amount',
            selector: row => handleNullValue(formatNumber(row?.amount, row?.coinsList?.decimalPoint)),
            sortable: true,
            minWidth:'200px'

        },
        {
            name: 'Fee Amount',
            selector: row => handleNullValue(formatNumber(row?.feeAmount, row?.coinsList?.decimalPoint)),
            sortable: true,
            minWidth:'200px'
        },
        {
            name: 'Receive',
            selector: row => handleNullValue(formatNumber(row?.receiveAmount, row?.coinsList?.decimalPoint)),
            sortable: true,
            minWidth:'200px'
        },
        {
            name: 'Withdraw Type',
            selector: row => handleNullValue(
                row.withdrawType === 0 ? 'Manual' : 
                row.withdrawType === 1 ? 'Automatic' : 
                row.withdrawType === 2 ? 'Internal' :   
                row.withdrawType === 3 ? 'Wallet Error' : '-'
            ),
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Wallet Type',
            selector: row => handleNullValue(
                row.walletType === 0 ? 'Reward Wallet' : 
                row.walletType === 1 ? 'Spot Wallet' : 
                row.walletType === 2 ? 'Referral Wallet' : '-'
            ),
            sortable: true,
            minWidth: '150px'
        },

        ...(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Pending History" && allowedActions.some(action => data.actions.includes(action))) ? [
        {
            name: 'Action',
            cell: row => (
                <div className="edit d-flex">
                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                 data?.module === "Pending History" && data?.actions?.includes(2))) ? (
                    <>
                    <span id={`tooltipEmail${row.id}`} onClick={() => handleShowModel(row)} className="mdi mdi-border-color mx-1" style={{ fontSize: '15px', cursor: 'pointer' }}></span>
                    <Tooltip placement="top" isOpen={tooltipOpen1} target={`tooltipEmail${row.id}`} toggle={toggleTooltip1}> Edit </Tooltip>
                    </>
                    ):null}
                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                        data.module === "Pending History" && data?.actions?.includes(3))) ? (
                            <>
                                <span id={`tooltipTally${row.id}`} onClick={()=> handleTallyRedirect(row)} className="mdi mdi-note-outline"
                                style={{ fontSize: '15px', cursor: 'pointer' }}></span>
                                <Tooltip placement="top" isOpen={tooltipOpen_tally} target={`tooltipTally${row.id}`} toggle={toggleTooltip_tally}>Tally
                                Withdraw </Tooltip>
                            </>
                    


                    ):null}
                </div>
            ),
            ignoreRowClick: true,
            minWidth: '100px'
          
        }]: []),
        {
            name: 'Locked Status',
            cell: row => (
              <div className="d-flex justify-content-between align-items-center" style={{ minWidth: '120px' }}>
                <span className="mb-1">{row.lockStatus === 1 ? 'Locked' : '-'}</span>
                {adminInfo?.adminType === 1 && row.lockStatus === 1 && ( 
                  <button
                    className="btn btn-primary btn-sm waves-effect waves-light mt-1"
                    onClick={() => handleUnlockWithdraw(row)}
                  >
                    {row.lockStatus === 1 ? 'UnLock' : '-' }
                  </button>
                )}
              </div>
            ),
            sortable: true,
            minWidth: '160px'
          },
          
        {
            name: 'WithDraw Type',
            selector: row => (
                <div className="form-check form-switch form-switch-md d-flex justyfy-content-center align-items-center">
            <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                checked={row.withdrawType === 1}
                onChange={() => handleUpdateStatusClick(row)}
            />
    
        </div>
        ),
            sortable: true,
            minWidth:'150px'
        },

    ];

    const handleUpdateStatusClick = (row) => {
        showStatusAlertVerification(handleToggleModel, row);
      };
    const showStatusAlertVerification = (onConfirm, row) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to update withdraw type?",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Update it!",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !Swal.isLoading(),
          preConfirm: async () => {
            try {
              Swal.showLoading();
              await onConfirm(row); 
            } catch (error) {
              Swal.fire("Error", "Something went wrong!", "error");
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            
          }
        });
      };
      
    const handleSearchChanage = (e) => {
        const value = e.target.value
        if (value == '') {
            ipList()
            setSearchValid(false)
        }
        setinputSearch(value)
    }

    const handleSearch = async () => {
        if (inputSearch !== '') {
            setSearchLoad(true);
            const postForm = {
                search: inputSearch.trim(),
                status:'Pending'
            };
            try {
            const response = await postData(Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY,postForm);
                if (response.status) {
                    setSearchLoad(false);
                    setFormData(response.data.data);
                    setTotalPagination(response.data.totalPages);


                } else {
                    setSearchLoad(false);
                }

            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
                setSearchLoad(false);
            }
        } else {
            setSearchValid(true)
        }
    }


    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    const handleDateChange = ([start, end]) => {
        const formattedStart = start ? formatDate(start) : null;
        const formattedEnd = end ? formatDate(end) : null;
        setDateRange([start, end]);
        if (!formattedStart && !formattedEnd) {
            ipList();
            setDownloadEndDate({})
        }
        if (formattedStart && formattedEnd) {
            setDownloadEndDate({ startDate: formattedStart, endDate: formattedEnd })
            handleDate({ startDate: formattedStart, endDate: formattedEnd });
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    const handleDate = async ({ startDate, endDate }) => {
        setLoading(true);
        const postForm = {
            startDate: startDate,
            endDate: endDate,
            status:'Pending'
        }
        try {

            const response = await postData(Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY,postForm);

            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);

            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };


    const handleDownload = async (type) => {
        setDownLoading(true)
        try {
            const postForm = {
                status:'Pending'
            };
            if (downloadStartDate.startDate) {
                postForm.startDate = downloadStartDate.startDate;
            }
            if (downloadStartDate.endDate) {
                postForm.endDate = downloadStartDate.endDate;
            }
            if (inputSearch) {
                postForm.search = inputSearch;
            }
            postForm.download = type;
            const queryString = new URLSearchParams(postForm).toString();
            const response = await axiosInstance.post(`${Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY}?${queryString}`, {
                
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: 'blob'
            });
            if (response.data) {
                setDownLoading(false)
                const blob = response.data;

                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `CryptoPendingUserHistory.${type}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                toast.error(response.data.message)
                setDownLoading(false)

            }
        } catch (err) {
            setDownLoading(false)
            console.error('Error downloading the file', err);
        }
    };

    function tog_xlarge(image) {
        setShowModel(!showModel);
    }


    const handleApprove = async (details) => {
        setButtonLoading(true)
        try {
        
            const postForm = {
                transactionId: details.transactionId,
                adminId:adminId,
                withdrawId :showDetails._id,
                status:'Pending'
            }

            if (details.transactionId) {
                postForm.transactionId = details.transactionId;
                postForm.adminId=adminId;
                postForm.withdrawId = showDetails._id;
                postForm.status='Pending'


            } else {
                postForm.transactionId = showDetails.transactionId
                postForm.withdrawId = showDetails._id
                postForm.adminId=adminId
                 postForm.status='Pending'
            }
            const response = await postData(Apiservices.WITHDRAW_CRYPTO_APPROVE_OTP, postForm);
            if (response.status) {
                setCountdown(60)
                setShowOTPModel(true)
                startCountdown()
                setShowModel(false)
                setButtonLoading(false)
                toast.success(response.message);


            } else {
                setButtonLoading(false)
                toast.error(response.message);

            }
        } catch (error) {
            setButtonLoading(false)
            toast.error("Internal Server Error..Try Again Later");

        }
    }

    const handleReject = async (details,reason) => {
        setButtonLoading(true)
        try {
            const postForm = {
                transactionId: details.transactionId,
                status:"Rejected",
                reason: reason,
                withdrawId : showDetails?._id,
                adminId:adminId
            }

            if (details.transactionId) {
                postForm.transactionId = details.transactionId;
            } else {
                postForm.transactionId = showDetails.transactionId;

            }
            const response = await postData(Apiservices.withDrawWalletId, postForm);
            if (response.status) {
                ipList()
                setButtonLoading(false)
                toast.success(response.message);
            } else {
                setButtonLoading(false)
                toast.error(response.message);
            }
        } catch (error) {
            setButtonLoading(false)
            toast.error(error.message);
        }
    }

  

    const sendOTP = async () => {
        const postForm = {
            transactionId: showDetails?.transactionId,
            withdrawId : showDetails?._id
        }
        setLoading(true);
        try {
            const response = await postData(Apiservices.WITHDRAW_CRYPTO_APPROVE_OTP,postForm);
            if (response.status) {
                setCountdown(60);
                startCountdown();
                setLoading(false);
                toast.dismiss()
                toast.success(response.message)
                // validation.resetForm();


            } else {
                setLoading(false);
                toast.dismiss()
                toast.error(response.message)
                validation.resetForm();
        
            }
        } catch (error) {
            setLoading(false);
            toast.dismiss()
            toast.error("Internal Server Error..Try Again Later")

        }
    };
    useEffect(() => {
        if (!user) {
          setUser(Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY);
          ipList(); 
        }
      }, [user]);


const handleFilter = async (apiName, type) => {
    const postform = {
        status: 'Pending',
        withdrawType: type // Send 0 for Manual, 1 for Automatic, or null for All
    };
    
    setLoading(true);
    try {
        const response = await postData(apiName, postform);
        console.log(response,"4444444444444444444")
        if (response.status) {
            setFormData(response.data.data);  
            setTotalPagination(response.data.totalPages);
            setTotalLimit(response.data.limit);
            setCount(response.data.currentCount);
        } else {
            toast.error(response.message);  
        }
    } catch (error) {
        toast.error("Internal Server Error..Try Again Later");  
    } finally {
        setLoading(false);  
    }
};

const handleFilter1 = async (apiName, type) => {
    const postform = {
        status: 'Pending',
        walletType: type 
    };
    
    setLoading(true);
    try {
        const response = await postData(apiName, postform);
        if (response.status) {
            setFormData(response.data.data);  
            setTotalPagination(response.data.totalPages);
            setTotalLimit(response.data.limit);
            setCount(response.data.currentCount);
        } else {
            toast.error(response.message);  
        }
    } catch (error) {
        toast.error("Internal Server Error..Try Again Later");  
    } finally {
        setLoading(false);  
    }
};
const [withType,setWithType] = useState()
const [wallType,setWallType] = useState()
  const handleDropdownClick = (item, type) => {
    setWithdrawType(item);
    setDownloadEndDate({});
    setDateRange([null, null]);

    // Update withdrawType based on selected type
    switch (item) {
        case "Manual":
            handleFilter(Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY, 0); 
            setWithType(0)
            break;

        case "Automatic":
            handleFilter(Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY, 1);  
            setWithType(1)
            break;
        case "Internal":
            handleFilter(Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY, 2);  
            setWithType(2)
            break;
        case "Wallet Error":
            handleFilter(Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY, 3);  
            setWithType(3)
            break;
        default:
            handleFilter(Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY, null);  
            break;
    }
};

    
      const handleDropdownClick1 = (item) => {
        setWalletType(item)
        setDownloadEndDate({});
        setDateRange([null, null]);
        switch (item) {
          case "Reward Wallet":
            handleFilter1(Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY,0); 
            setWallType(0) 
            break;
    
          case "Spot Wallet":
            handleFilter1(Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY,1);  
            setWallType(1)
            break;
            case "Referral Wallet":
            handleFilter1(Apiservices.WITHDRAW_CRYPTO_PENDING_HISTORY,2);  
            setWallType(2)
            break;
        
          default:
            break;
        }
      };
     
      const handleLocked = async (data) => {
        setLoad(true);
        const postForm = {
          adminId: adminId,
          withdrawId: showDetails?._id,
          lockStatus:1
        };
        try {
          const response = await postData(Apiservices.WITHDRAW_LOCKED, postForm);
          if (response.status) {
            // setShowModel(false)
            setLoad(false);
            ipList()
            setIsLockData(response.data)
            setIsLock(response.data)
            toast.success(response.message);
          } else {
            setLoad(false);
            toast.error(response.message);
          }
        } catch (error) {
          console.error(error);
        //   toast.error(error.response.message);

          setLoad(false);
        }
      };


      const handleToggleModel=async(row)=>{
const updatedWithdrawType = row.withdrawType === 1 ? 0 : 1;

const postForm = {
    adminId: row.adminId,
    withdrawId: row._id,
    withdrawType:updatedWithdrawType
};
console.log(postForm);
        try {
          const response = await postData(Apiservices.WITHDRAW_AUTOMATIC_LOCKED, postForm);
          if (response.status) {
            // setShowModel(false)
            setLoad(false);
            ipList()
            setIsLockData(response.data)
            setIsLock(response.data)
            toast.success(response.message);
          } else {
            setLoad(false);
            toast.error(response.message);
          }
        } catch (error) {
          console.error(error);
        //   toast.error(error.response.message);

          setLoad(false);
        }

      }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Crypto Withdraw" breadcrumbItem="Pending History" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col sm={4} lg={3}>
                                            <div className="input-group">
                                                <Input
                                                    // onChange={handleInputChange}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name,Email,Address,ID ..."
                                                    aria-label="Recipient's username"
                                                    onChange={handleSearchChanage}
                                                    invalid={searchValid}
                                                    onKeyPress={handleKeyPress}
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="submit"
                                                        onClick={() => handleSearch()} disabled={searchLoad}
                                                    >
                                                        {searchLoad ? (
                                                            <Spinner className="text-light" size="sm" />
                                                        ) : (<i className="ri-search-line" />)}
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                       
                                        <Col sm={4} lg={3}>
                                            <div className="form-group mb-4">

                                                <div>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="Select Start to End Date"
                                                            options={{
                                                                mode: 'range',
                                                                altInput: true,
                                                                altFormat: "F j, Y",
                                                                dateFormat: "Y-m-d",
                                                                maxDate: today,

                                                            }}
                                                            value={dateRange}
                                                            onChange={handleDateChange}
                                                        />
                                                    </InputGroup>
                                                </div>

                                            </div>
                                        </Col>

                                        <Col className="col-sm">

                                            <div className="d-flex justify-content-sm-end">
                                                
                                                    <Dropdown className="mx-2"
                                                    isOpen={btnauto4}
                                                    toggle={() => setbtnauto4(!btnauto4)}
                                                    id="dropdownMenuClickableInside"
                                                >
                                                    <DropdownToggle tag="button" className="btn btn-info">
                                                        {withdrawType}
                                                        <i className="mdi mdi-chevron-down" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start ">
                                                        <DropdownItem header>Select</DropdownItem>
                                                        <DropdownItem onClick={() => handleDropdownClick("All", null)}>
                                                            All
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => handleDropdownClick("Manual", 0)}>
                                                            Manual
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => handleDropdownClick("Automatic", 1)}>
                                                            Automatic
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => handleDropdownClick("Internal", 2)}>
                                                            Internal
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => handleDropdownClick("Wallet Error", 3)}>
                                                            Wallet Error
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>



                                                 <Dropdown className="mx-2"
                                                    isOpen={btnauto3}
                                                    toggle={() => setbtnauto3(!btnauto3)}
                                                    id="dropdownMenuClickableInside"
                                                >
                                                    <DropdownToggle tag="button" className="btn btn-info">
                                                      
                                                       {walletType}
                                                        <i className="mdi mdi-chevron-down" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start ">
                                                        <DropdownItem header>Select</DropdownItem>
                                                        <DropdownItem
                                                            onClick={() => handleDropdownClick1("All" , null)}
                                                        >
                                                            Over All Wallet
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() => handleDropdownClick1("Reward Wallet", 0)}
                                                        >
                                                           Reward Wallet
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() =>
                                                                handleDropdownClick1("Spot Wallet",1)
                                                            }
                                                        >
                                                           Spot Wallet
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() =>
                                                                handleDropdownClick1("Referral Wallet",2)
                                                            }
                                                        >
                                                          Referral Wallet
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                                            <Button
                                                    color="info"
                                                    className="add-btn mx-2"
                                                    onClick={() => handleDownload('xlsx')}
                                                    id="create-btn"
                                                    disabled={downLoading}
                                                    >
                                                    <i className="mdi mdi-download-outline"></i>

                                                    {downLoading ? <Loader /> : "Download"}
                                                    </Button>


                                            </div>
                                        </Col>
                                    </Row>


                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={formData}
                                            paginationServer
                                            paginationTotalRows={formData?.length}
                                            progressPending={loading}
                                            persistTableHead={true}
                                            progressComponent={<div className="py-3">Loading...</div>}
                                            // noDataComponent={formData.length===0}
                                            noDataComponent={<div className="py-3">No records found</div>}
                                            fixedHeader={true}

                                        />
                                    </div>
                                   
                                    <div className="container">
                                                        <div className="row justify-content-end mt-4">
                                                        <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                                                            <div className="d-flex align-items-center me-3">
                                                              <span className="me-2">Rows per page:</span>
                                                              <select
                                                                value={rowsPerPage}
                                                                onChange={(e) => handleRowsPerPageChange(e.target.value)}
                                                                className="form-control-sm"
                                                              >
                                                                <option value={10}>10</option>
                                                                <option value={20}>20</option>
                                                                <option value={30}>30</option>
                                                                <option value={50}>50</option>
                                                              </select>
                                                            </div>
                                                            <div className="me-md-3 mb-3 mb-md-0">
                                                              <span>{count}</span>
                                                            </div>
                                                            <div className="pagination pagination-rounded mt-3">
                                                              <ReactPaginate
                                                                previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                                nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageClick}
                                                                containerClassName={"pagination"}
                                                                activeClassName={"active"}
                                                                pageLinkClassName={"page-link"}
                                                                breakLinkClassName={"break-link"}
                                                                disabledClassName={"disabled"}
                                                                initialPage={currentPage}
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                                renderLink={(props) => {
                                                                  if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                                                    return (
                                                                      <a
                                                                        {...props}
                                                                        onClick={() =>
                                                                          handlePageClick({
                                                                            selected: pageCount - 1,
                                                                          })
                                                                        }
                                                                      >
                                                                        {pageCount}
                                                                      </a>
                                                                    );
                                                                  }
                                                                  return <a {...props} aria-label="Link description" />;
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} toggle={() => { tog_xlarge(); }} centered backdrop="static" >

                <div className="modal-header">

                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
                       Pending Withdraw View & Approve
                    </h5>
                    <button
                        onClick={() => { setShowModel(false); }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>


                <div className="modal-body custom-modal-body">
                {/* This record is locked by <strong>{LockedData?.name}</strong>. */}
                    <div className="table-responsive">
                        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                                  
                            <tbody>
                            
                            <tr>
                            <td>
                                {isLocked?.lockStatus === 1 && (
                                    <>
                                    <b>Locked By Name: {isLockedData?.adminName || showDetails.adminName}</b>
                                    <br />
                                    <b>Locked By Email: {isLockedData?.adminEmail || showDetails.adminEmail}</b>
                                    </>
                                )}
                                </td>

                                    <td>
                                    {isLocked?.lockStatus !== 1 ? 
                                    <Button color="success" className="add-btn" 
                                    onClick={() => handleLocked()} id="create-btn">
                                    {load ? 'Loading...' : 'Lock'}
                                    </Button> 
                                    :
                                    <Button color="success" className="add-btn"  disabled
                                    id="create-btn"> Locked </Button>
                                    }  

                                    {/* {showDetails?.isProcess === 0  ? 
                                <div className="form-check form-switch form-switch-md d-flex justify-content-center align-items-center">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizemd"
                                    checked={showDetails.withdrawType === 1}
                                    onChange={() => handleToggleModel(showDetails.withdrawType)}
                                    disabled={loading}
                                />
                            </div>
                                    :
                                   ""
                                    }  */}



                                        </td>
                                </tr>
                             <tr>
                                    <td><b>Name</b></td>
                                    <td>{showDetails.userDetails && showDetails.userDetails.fullName ? showDetails.userDetails.fullName : '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Email</b></td>
                                    <td>{showDetails.userDetails && showDetails.userDetails.email ? showDetails.userDetails.email : '-'}</td>
                                </tr>


                                <tr>
                                    <td><b>Transaction ID</b></td>
                                    <td><AddressCellFull address={showDetails?.transactionId || '-'} /></td>
                                </tr>
                                <tr>

                                    <td><b>Currency Name</b></td>
                                    <td>{showDetails.currency || '-'}</td>

                                </tr>
                                <tr>
                                    <td><b>Withdraw Address</b></td>
                                    <td><AddressCellFull address={showDetails.withdrawAddress || '-'} /></td>
                                </tr>
                                <tr>
                                    <td><b>Withdraw Amount</b></td>
                                    <td>{showDetails.amount 
                                    // ? showDetails.amount.toFixed(8) 
                                    ? formatNumber(showDetails.amount, showDetails?.coinsList?.decimalPoint)
                                    : '0'}</td>
                                </tr>
                                <tr>
                                <td><b>Network Type</b></td>
                                <td>{showDetails?.networkName || '-'}</td>
                                </tr>
                                <tr>
                                    <td>  <b>
                                        Fee Amount{" "}
                                        {showDetails.feeType?.feeType === 1
                                            ? `(${percentCalculate(showDetails.amount, showDetails.feeAmount)}%)`
                                            // ?  formatNumber(showDetails.feeAmount, showDetails?.coinsList.decimalPoint)
                                            : ''}
                                    </b></td>
                                    <td>{showDetails.feeAmount ? formatNumber(showDetails.feeAmount, showDetails?.coinsList?.decimalPoint) : 0}</td>
                                </tr>
                                {/* <tr>

                                    <td><b>TDS ({showDetails.tdsPercentage ? showDetails.tdsPercentage : 0}%)</b></td>
                                    <td>{showDetails.tds ? showDetails.tds.toFixed(8) : '0'}</td>

                                </tr> */}
                                {/* <tr>
                                    <td><b>TDS Amount</b></td>
                                    <td>{showDetails.tds ? formatNumber(showDetails?.tds, showDetails?.decimalPoint) : 0}</td>
                                </tr> */}
                                <tr>
                                    <td><b>Receive Amount</b></td>
                                    <td>{showDetails?.receiveAmount ? formatNumber(showDetails?.receiveAmount, showDetails?.coinsList?.decimalPoint) : 0} <span>({showDetails.currency})</span></td>
                                </tr>
                                <tr>
                                    <td><b>Request Date & Time</b></td>
                                    <td>{utcToNormalTime(showDetails?.createdAt).date} {utcToNormalTime(showDetails?.createdAt).time}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                {isLocked?.lockStatus !== 0 ? (
                <ModalFooter>
                <div className="hstack gap-2 justify-content-end">
                {(isLocked?.lockStatus === 0 || 
                    (isLocked?.lockStatus === 1 && adminInfo.adminUserId === isLocked.lockId)) ? (
                    <>
                        {(adminType === 1 || adminInfo?.permissions.some(data => 
                            data.module === "Pending History" && data.actions.includes(3))) && (
                            <button 
                                onClick={() => handleRejectedModel(showDetails)} 
                                className="btn btn-danger waves-effect waves-light" 
                                type="submit"
                                disabled={showDetails.locked && adminInfo.adminUserId !== showDetails.lockId} // Corrected condition
                            >
                                Reject
                            </button>
                        )}
                        {(adminType === 1 || adminInfo?.permissions.some(data => 
                            data.module === "Pending History" && data.actions.includes(3))) && (
                            <button 
                                onClick={()=>handleApprove(showDetails)} 
                                className="btn btn-primary waves-effect waves-light" 
                                type="submit" 
                                disabled={buttonLoading || (showDetails.locked && adminInfo.adminUserId !== showDetails.lockId)} // Corrected condition
                            >
                                {buttonLoading ? <Loader /> : "Approve Withdraw"}
                            </button>
                        )}
                    </>
                ) : (
                    (showDetails?.lockStatus === 1 && adminInfo.adminUserId === showDetails.lockId) && (
                        <>
                            {(adminType === 1 || adminInfo?.permissions.some(data => 
                                data.module === "Pending History" && data.actions.includes(3))) && (
                                <button 
                                    onClick={() => handleRejectedModel(showDetails)} 
                                    className="btn btn-danger waves-effect waves-light" 
                                    type="submit"
                                    disabled={false} // Enable button since the record is locked by the current admin user
                                >
                                    Reject
                                </button>
                            )}
                            {(adminType === 1 || adminInfo?.permissions.some(data => 
                                data.module === "Pending History" && data.actions.includes(3))) && (
                                <button 
                                    onClick={handleApprove} 
                                    className="btn btn-primary waves-effect waves-light" 
                                    type="submit" 
                                    disabled={buttonLoading} // Enable button unless `buttonLoading` is true
                                >
                                    {buttonLoading ? <Loader /> : "Approve Withdraw"}
                                </button>
                            )}
                        </>
                    )
                )}


</div>
    
                </ModalFooter>
             ) :
             (
                
                       null
             )
            
            } 
            </Modal>

            <Modal className="modal-dialog modal px-4" size="l" isOpen={showOTPModel} centered backdrop="static"  >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel"
                    >
                        Withdraw Confirmation OTP
                    </h5>
                    <button
                        onClick={() => {
                            setShowOTPModel(false);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <div>
                        <div className="text-center">

                        </div>
                        <h4 className="font-size-18 text-muted mt-2 text-center">
                            Verify Your OTP
                        </h4>

                        <div className="user-thumb text-center m-b-30">
                            <h4 className="rounded-circle avatar-lg img-thumbnail mx-auto d-flex justify-content-center align-items-center">{countdown === 60 ? ("0") : (countdown)}<span style={{ fontSize: '15px', marginTop: '7px' }}>s</span></h4>
                        </div>
                        <Form
                            className="form-horizontal"
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;   
                            }}
                        >

                            <Row>
                                <Col md={12} className="AuthStar">

                                {showDetails?.status == "Pending" && !isRejected && (

                                         <div className="mb-4">
                                            {showDetails?.withdrawType == 0  || showDetails?.withdrawType == 3    ?  
                                         <>
                                          <Label className="form-label">Transaction ID<span>*</span></Label>
                                          <InputGroup>
                                              <Input
                                                  style={{ borderColor: '#80838B' }}
                                                  name="transactionId"
                                                  disabled={loading || countdown === 60 || countdown === 0}
                                                  className="form-control"
                                                  placeholder="Enter Transaction ID"
                                                  type="text"
                                                  onChange={validation.handleChange}
                                                  onBlur={validation.handleBlur}
                                                  value={validation.values.transactionId || ""}
                                                  invalid={validation.touched.transactionId && validation.errors.transactionId ? true : false}
                                              />
  
                                              {validation.touched.transactionId && validation.errors.transactionId ? (
                                                  <FormFeedback>{validation.errors.transactionId}</FormFeedback>
                                              ) : null}
                                          </InputGroup>
                                          </> : 
                                           <Label className="form-label">
                                           {showDetails.withdrawType === 1 ? 'Automatic Withdraw No need transaction Id' : 'Internal Withdraw No need transaction Id'}
                                           <span>*</span>
                                         </Label>
                                          }
                                        
                                     </div>
                                )}
                           

                                    <div className="mb-4">
                                        <Label className="form-label">One Time Password<span>*</span></Label>
                                     
                                        <InputGroup>
                                                            <Input
                                                                style={{ borderColor: '#80838B' }}
                                                                name="otp"
                                                                // maxLength={6}
                                                                disabled={loading || countdown === 60 || countdown === 0 }
                                                                className="form-control"
                                                                placeholder="Enter OTP"
                                                                type="text"
                                                                maxLength={6}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.otp || ""}
                                                                invalid={validation.touched.otp && validation.errors.otp ? true : false}
                                                            />
                                                            {countdown === 0 && 
                                                                <Button
                                                                    onClick={sendOTP}
                                                                    className="btn btn-primary waves-effect waves-light"
                                                                    style={{ backgroundColor: '#80838B', color: 'white' }}
                                                                    disabled={isDisabled || loading}
                                                                >
                                                                     {loading ? ( 
                                                                            <Spinner size="sm" />    
                                                                    ) : ("ReSend")}
                                                                </Button> }
                                                           
                                                            {validation.touched.otp && validation.errors.otp ? (
                                                                <FormFeedback>{validation.errors.otp}</FormFeedback>
                                                            ) : null}
                                                        </InputGroup>
                                    </div>

                                    <div className="d-grid mt-4">

                                        <button
                                            className="btn btn-primary waves-effect waves-light"
                                            type="submit"
                                            disabled={loading || countdown === 60 || buttonLoading}
                                            onClick={validation.handleSubmit}
                                        >
                                            {buttonLoading ? (
                                                <>
                                                    <Spinner size="sm"> Loading...
                                                    </Spinner>
                                                    <span style={{ color: 'white' }}>
                                                        {' '}Loading...
                                                    </span>
                                                </>
                                            ) : ("Submit")}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>

                </div>
            </Modal>
        </React.Fragment>
    );
};

export default CryptoPendingWithdraw;

import DataTable from "react-data-table-component";
import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, Col, Spinner, Tooltip, Container,
 Row,DropdownMenu, DropdownItem, 
  DropdownToggle, Dropdown } from "reactstrap";
import ReactPaginate from "react-paginate";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { handleNullValue } from "../../helpers/function";
import { useLocation } from "react-router-dom";
import { AdminContext } from "../../helpers/adminContext";
import { utcToNormalTime } from "../../helpers/function";

const AdminList = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const userApi = location.state?.user;
  document.title = "ULtrapro | Admin Users Management";

  const [user, setUser] = useState(userApi);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnauto3, setbtnauto3] = useState(false);
  const [userType, setuserType] = useState("Admin Role");
  const [formData, setFormData] = useState([]);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; 
  const pageCount = Math.ceil(totalPagination);
  const { adminInfo } = useContext(AdminContext);
  const [adminType, setAdminType] = useState("");
  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1);
  const toggleTooltip2 = () => setTooltipOpen2(!tooltipOpen2);
  const toggleTooltip3 = () => setTooltipOpen3(!tooltipOpen3);
  const allowedActions = [1, 2, 3, 4];
  const [Role, setRole] = useState("");
  const [searchLoad, setSearchLoad] = useState(false);
  const [searchValid, setSearchValid] = useState(false);
  const [inputSearch, setinputSearch] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [downloadStartDate, setDownloadEndDate] = useState({});

  function tog_list() {
    navigate(`/addadminuser`);
  }

  const handleNav = async (record) => {
    navigate(`/admindetails/${record.adminUserId}`);
  };
  const handleNavSub = async (record) => {
    navigate(`/updateadminuser/${record.adminUserId}`);
  };

  useEffect(() => {
    if (user === undefined) {
      setUser(Apiservices.ADMIN_LIST);
    }
  }, [user]);

  useEffect(() => {
    adminList();
    setAdminType(adminInfo?.adminType);
  }, []);

  const adminList = async () => {
    try {
      const response = await postData(Apiservices.ADMIN_LIST, {});
      if (response.status) {
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
        setFormData(response.data.data);
        handleDropdownClick("Admin Role");
      } else {
      }
    } catch (error) {}
  };

  //Add Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      adminName: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      adminName: Yup.string().required("Admin Name is required"),
      email: Yup.string().email("Invalid email format").required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      handlePost(values);
    },
  });

  const handlePost = async (data) => {
    const postForm = {
      name: data.adminName,
      email: data.email,
      password: data.password,
    };

    try {
      setLoading(true);
      const response = await postData(Apiservices.ADMIN_ADD, postForm);
      if (response.status) {
        adminList();
        setLoading(false);

        toast.success(response.message);
        validation.resetForm();
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  //Permission List
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage));
    HandleClickRowperpage(newRowsPerPage);
  };

  

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => row.serialNo,
      sortable: true,
      minWidth: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      minWidth: "300px",
    },
    {
      name: "Admin Role",
      selector: (row) => row.adminType,
      cell: (row) => {
        switch (row.adminType) {
          case 1:
            return "Super Admin";
          case 2:
            return "Sub Admin";
          default:
            return "Unknown Role";
        }
      },
      sortable: true,
      minWidth: "150px",
    },
    ...(adminType === 1 || adminInfo.permissions.some((data) => data.module === "Admin Users" && data.actions.includes(3))
      ? [
          {
            name: "Active Status",
            // selector: row => row.isActive ? 'Active' : 'Inactive',
            selector: (row) => (
              <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
                <input type="checkbox" className="form-check-input" id="customSwitchsizemd" checked={row.isActive === 1} onChange={() => handleUpdateStatusClick(row)} disabled={loading} />
              </div>
            ),
            sortable: true,
            minWidth: "150px",
          },
        ]
      : []),

    ...(adminType === 1 || adminInfo.permissions.some((data) => data.module === "Admin Users" && data.actions.includes(3))
      ? [
          {
            name: "IP Status",
            selector: (row) => (
              <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
                <input type="checkbox" className="form-check-input" id="customSwitchsizemd" checked={row.isIpActive === 1} onChange={() => handleIpStatusClick(row)} disabled={loading} />
              </div>
            ),
            sortable: true,
            minWidth: "150px",
          },
        ]
      : []),

    {
      name: "Status",
      selector: (row) => {
        // Check if the IP is blocked
        if (row.isIpActive === 2) {
          return "IP Blocked";
        }
        // Check if the account is blocked
        if (row.isActive === 0) {
          return "Blocked";
        }
        return "Active";
      },
      ignoreRowClick: true,
      minWidth: "100px",
    },

    {
      name: "Created Date & Time",
      selector: (row) => (row.createdAt ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${utcToNormalTime(row.createdAt).time}` : "---"),
      sortable: true,
      minWidth: "180px",
    },
    ...(adminType === 1 || adminInfo.permissions.some((data) => data.module === "Admin Users" && allowedActions.some((action) => data.actions.includes(action)))
      ? [
          {
            name: "Action",
            cell: (row) => (
              <div className="edit">
                {adminType === 1 || adminInfo.permissions.some((data) => data.module === "Admin Users" && data.actions.includes(3)) ? (
                  <span id={`editTooltip-${row.id}`}>
                    <i style={{ cursor: "pointer", marginLeft: "15px" }} className="mdi mdi-border-color cursor-pointer" onClick={() => handleNavSub(row)}></i>
                  </span>
                ) : null}

                {(adminType === 1 || adminInfo.permissions.some((data) => data.module === "Admin Users" && data.actions.includes(3))) && (
                  <Tooltip isOpen={tooltipOpen1} toggle={toggleTooltip1} target={`editTooltip-${row.id}`}>
                    Edit
                  </Tooltip>
                )}

                {adminType === 1 || adminInfo.permissions.some((data) => data.module === "Admin Users" && data.actions.includes(2)) ? (
                  <span id={`viewTooltip-${row.id}`}>
                    <i style={{ fontSize: "15px", cursor: "pointer", marginLeft: "15px" }} onClick={() => handleNav(row)} className="mdi mdi-eye-outline mx-1"></i>
                  </span>
                ) : null}
                {(adminType === 1 || adminInfo.permissions.some((data) => data.module === "Admin Users" && data.actions.includes(2))) && (
                  <Tooltip isOpen={tooltipOpen2} toggle={toggleTooltip2} target={`viewTooltip-${row.id}`}>
                    View
                  </Tooltip>
                )}

                {adminType === 1 || adminInfo.permissions.some((data) => data.module === "Admin Users" && data.actions.includes(4)) ? (
                  <span id={`deleteTooltip-${row.id}`}>
                    <i style={{ cursor: "pointer", marginLeft: "15px" }} className="mdi mdi-trash-can-outline" onClick={() => handleDeleteClick(row)}></i>
                  </span>
                ) : null}

                {(adminType === 1 || adminInfo.permissions.some((data) => data.module === "Admin Users" && data.actions.includes(4))) && (
                  <Tooltip isOpen={tooltipOpen3} toggle={toggleTooltip3} target={`deleteTooltip-${row.id}`}>
                    Delete
                  </Tooltip>
                )}
              </div>
            ),
            ignoreRowClick: true,
            minWidth: "150px",
          },
        ]
      : []),
  ];

  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,

    initialValues: {
      adminName: "",
      email: "",
      password: "",
      isActive: "",
    },
    validationSchema: Yup.object({
      adminName: Yup.string().required("Admin Name is required"),
      email: Yup.string().email("Invalid email format").required("Email is required"),
      // password: Yup.string()
      // .required('Password is required')
      // .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      // 'Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character'
      // ),

      isActive: Yup.string().required("Status is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      confirmUpdateAdmin(values);
    },
  });

  //updateapicall
  const confirmUpdateAdmin = async (values) => {
    const postForm = {
      email: values.email,
      newPassword: values.password,
      isActive: Number(values.isActive),
    };
    try {
      setLoading(true);
      const response = await postData(`${Apiservices.AdminUsersPasswordUpdate}`, postForm);
      if (response.status === true) {
        toast.success(response.message);
        adminList();
        setLoading(false);
      } else {
        toast.error(response.message);
        adminList();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Internal Server Error..Try Again Later");
      validationUpdate.resetForm();
    }
  };
  //status update api call
  const showStatusAlert = (onConfirm, value) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Status Change this User?",
      icon: "info",
      showCancelButton: true,
      inputLabel: "Enter a Reason",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
        autocomplete: "off",
      },
      inputPlaceholder: "Enter Reason",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        if (!reason) {
          Swal.showValidationMessage("Reason is required");
        } else {
          Swal.showLoading();
          try {
            await onConfirm(value, reason);
          } catch (error) {}
        }
      },

      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  };
  const handleUpdateStatusClick = (value) => {
    showStatusAlert(handleToggle1, value);
  };
  const handleToggle1 = async (rowDetails, reason) => {
    const updatedStatus = rowDetails.isActive === 1 ? 0 : 1;
    const postForm = {
      email: rowDetails.email,
      newPassword: rowDetails.password,
      isActive: updatedStatus,
      reason: reason,
      activeStatusHis: rowDetails.activeStatusHis,
    };

    setLoading(true);
    try {
      const response = await postData(`${Apiservices.AdminUsersPasswordUpdate}`, postForm);
      if (response.status) {
        toast.dismiss();
        setLoading(false);
        Swal.fire("Success!", "The status has been updated successfully", "success");
        adminList();
      } else {
        setLoading(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };
  const handleIpStatusClick = (value) => {
    showStatusAlert(handleToggle2, value);
  };
  const handleToggle2 = async (rowDetails, reason) => {
    const updatedStatus = rowDetails.isIpActive === 1 ? 2 : 1;

    const postForm = {
      email: rowDetails.email,
      newPassword: rowDetails.password,
      isIpActive: updatedStatus,
      isActive: rowDetails.isActive,
      reason: reason,
      activeStatusHis: rowDetails.activeStatusHis,
    };

    setLoading(true);
    try {
      const response = await postData(`${Apiservices.AdminUsersPasswordUpdate}`, postForm);
      if (response.status) {
        toast.dismiss();
        setLoading(false);
        Swal.fire("Success!", "The status has been updated successfully", "success");
        adminList();
      } else {
   
        setLoading(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };
  //delete api call
  const showDeleteAlert = (onConfirm, row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to recover this once it's deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm(row);
      }
    });
  };
  const handleDeleteClick = (row) => {
    showDeleteAlert(handleDelete1, row);
  };

  const handleDelete1 = async (rowDetails) => {
    const postForm = {
      email: rowDetails.email,
    };
    setLoading(true);
    try {
      const response = await postData(Apiservices.AdminUserDelete, postForm);
      if (response.status) {
        setLoading(false);
        toast.success(response.message);
        Swal.fire("Deleted !", "The requested record has been deleted", "success");
        adminList();
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Internal Server Error..Try Again Later");
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
    // fetchPaginationList({ search: inputSearch, page: selected, limit: rowsPerPage, startDate: DateValue.startDate, 
    //   endDate: DateValue.endDate, filterType: selectedItem, stakeType: stakeFilter,isBlock:selectedBlock });
  };

  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      adminType: Role,
      page: selected + 1,
      limit: itemsPerPage,
    };
   
    try {
      const response = await postData(Apiservices.ADMIN_LIST, postForm);

      if (response.status) {
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const HandleClickRowperpage = async (newRowsPerPage) => {
    setLoading(true);
    const postForm = {
      page: 1,
      limit: Number(newRowsPerPage),
    };

    // if (dateRange[0] && dateRange[1]) {
    //   postForm.startDate = formatDate(dateRange[0]);
    //   postForm.endDate = formatDate(dateRange[1]);
    // }

    try {
      if (formData === undefined) {
        const response = await postData(Apiservices.ADMIN_LIST, postForm);

        if (response.status) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
        }
      } else {
        // const response = await postData(`${Apiservices.MenuList}?${queryString}`);
        const response = await postData(Apiservices.ADMIN_LIST, postForm);

        if (response.status) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "" && user === undefined) {
      adminList();
      setSearchValid(false);
    } else if (value === "") {
      handleFilter(user);
    }
    setinputSearch(value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
     
      };

      
      try {
        if (user === undefined) {
          const response = await postData(Apiservices.ADMIN_LIST, postForm);
          if (response.status) {
            setSearchLoad(false);
            setFormData(response.data.data);
            handleDropdownClick("All Users");
            setTotalPagination(response.data.totalPages);
          } else {
            setSearchLoad(false);
          }
        } else {
          const response = await postData(user, postForm);
          if (response.status) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);
          } else {
            setSearchLoad(false);
          }
        }
      } catch (error) {
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };
  const handleDropdownClick = (item, type) => {
    setuserType(item);
    setDownloadEndDate({});
    setDateRange([null, null]);
    // Update withdrawType based on selected type
    switch (item) {
      case "Super Admin":
        handleFilter(Apiservices.ADMIN_LIST, 1);
        setRole(1);
        break;

      case "Sub Admin":
        handleFilter(Apiservices.ADMIN_LIST, 2);
        setRole(2);
        break;

      default:
        handleFilter(Apiservices.ADMIN_LIST, null);
        break;
    }
  };
  const handleFilter = async (apiName, type) => {
    const postform = {
      adminType: type,
    };
    setLoading(true);
    try {
      const response = await postData(apiName, postform);
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  // const formatDate = (date) => {
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const year = date.getFullYear();
  //   return `${day}-${month}-${year}`;
  // };

  // const handleDateChange = ([start, end]) => {
  //   const formattedStart = start ? formatDate(start) : null;
  //   const formattedEnd = end ? formatDate(end) : null;

  //   setDateRange([start, end]);

  //   if (!formattedStart && !formattedEnd) {
  //     handleFilter(user);
  //     setDownloadEndDate({});
  //   }

  //   if (formattedStart && formattedEnd) {
  //     setDownloadEndDate({
  //       startDate: formattedStart,
  //       endDate: formattedEnd,
  //     });
  //     handleDate({ startDate: formattedStart, endDate: formattedEnd });
  //   }
  // };

  // const handleDate = async ({ startDate, endDate }) => {
  //   setLoading(true);

  //   const postForm = {
  //     startDate: startDate,
  //     endDate: endDate,
  //   };
  //   try {
  //     const queryString = new URLSearchParams(postForm).toString();

  //     const response = await postData(`${user}?${queryString}`);
  //     if (response.status) {
  //       setLoading(false);
  //       setFormData(response.data.data);
  //       setTotalPagination(response.data.totalPages);
  //       setTotalLimit(response.data.limit);
  //       setCount(response.data.currentCount);
  //     } else {
  //       setLoading(false);
  //       toast.error(response.message);
  //     }
  //   } catch (error) {
  //     toast.error("Internal Server Error..Try Again Later");
  //     setLoading(false);
  //   }
  // };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Admin Management" breadcrumbItem="Admin Users" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-3">
                        <div className="input-group">
                          <Input
                            // onChange={handleInputChange}
                            type="text"
                            className="form-control"
                            placeholder="Search by Name,Email,Mobile Number"
                            aria-label="Recipient's username"
                            onChange={handleSearchChanage}
                            invalid={searchValid}
                            onKeyPress={handleKeyPress}
                          />
                          <div className="input-group-append">
                            <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                              {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col className="col-sm-4">
                        {/* <form className="d-block ">
                                              <div className="position-relative">
                                            
                                          <Flatpickr
                                            className="form-control d-block"
                                            placeholder="Select Start to End Date"
                                            options={{
                                              mode: "range",
                                              altInput: true,
                                              altFormat: "F j, Y",
                                              dateFormat: "Y-m-d",
                                              maxDate: today,
                                            }}
                                            value={dateRange}
                                            onChange={handleDateChange}
                                          />
                                      
                                              </div>
                                          </form> */}
                      </Col>

                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <Dropdown className="mx-2" isOpen={btnauto3} toggle={() => setbtnauto3(!btnauto3)} id="dropdownMenuClickableInside">
                            <DropdownToggle tag="button" className="btn btn-info">
                              {userType}
                              <i className="mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start ">
                              <DropdownItem header>Select</DropdownItem>
                              <DropdownItem onClick={() => handleDropdownClick("Admin Role", null)}>All</DropdownItem>
                              <DropdownItem onClick={() => handleDropdownClick("Super Admin", 1)}>Super Admin</DropdownItem>
                              <DropdownItem onClick={() => handleDropdownClick("Sub Admin", 2)}>Sub Admin</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                          {adminType === 1 || adminInfo.permissions.some((data) => data.module === "Admin Users" && data.actions.includes(1)) ? (
                            <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn">
                              <i className="ri-add-line align-bottom me-1"></i> Add
                            </Button>
                          ) : null}
                          {/* <Button color="info" className="add-btn mx-2" onClick={handleDownload} id="create-btn"><i className="mdi mdi-download-outline"></i> Download</Button> */}

                          {/* <Button color="soft-danger"
                                                       
                                                    ><i style={{ fontSize: '15px' }} className="mdi mdi-download-circle-outline"></i>Download</Button> */}
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <DataTable
                        columns={columns}
                        data={formData}
                        paginationServer
                        paginationTotalRows={formData.length}
                        fixedHeader
                        progressPending={loading}
                        progressComponent={<div>Loading...</div>}
                        persistTableHead={true}
                        noDataComponent={<div className="mt-2">No records found</div>}
                      />
                    </div>

                    <div className="container">
                      <div className="row justify-content-end mt-4">
                        <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                          <div className="d-flex align-items-center me-3">
                            <span className="me-2">Rows per page:</span>
                            <select value={rowsPerPage} onChange={(e) => handleRowsPerPageChange(e.target.value)} className="form-control-sm">
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={30}>30</option>
                              <option value={50}>50</option>
                            </select>
                          </div>
                          <div className="me-md-3 mb-3 mb-md-0">
                            <span>{count}</span>
                          </div>
                          <div className="pagination pagination-rounded mt-3">
                            <ReactPaginate
                              previousLabel={<i className="mdi mdi-chevron-left"></i>}
                              nextLabel={<i className="mdi mdi-chevron-right"></i>}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              breakLinkClassName={"break-link"}
                              disabledClassName={"disabled"}
                              initialPage={currentPage}
                              disableInitialCallback={true}
                              forcePage={currentPage}
                              renderLink={(props) => {
                                if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                  return (
                                    <a
                                      {...props}
                                      onClick={() =>
                                        handlePageClick({
                                          selected: pageCount - 1,
                                        })
                                      }
                                    >
                                      {pageCount}
                                    </a>
                                  );
                                }
                                return <a {...props} />;
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminList;

import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";





// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import Logout from "../Pages/Authentication/Logout";

import UserProfile from "../Pages/Authentication/user-profile";

// Import Authentication Inner Pages
import Login1 from "../Pages/AuthenticationPages/Login";

import LockScreen from "../Pages/AuthenticationPages/LockScreen";

import EmailOtp from "../Pages/Authentication/EmailOtp";
import FiatDepositeCrypto from "../Pages/DepositeHistory/FiatHistory";
import CryptoDepositeHistory from "../Pages/DepositeHistory/CryptoHistory";
import UserIPblock from "../Pages/UserManagement/UserIPblock";
import UserList from "../Pages/UserManagement/UserListDetails/UserList";
import UserDetails from "../Pages/UserManagement/UserListDetails/UserDetails";
import PairManagement from "../Pages/PairManage/PairManagement";
import Pair from "../Pages/Pairs/Pair";
import Swap from "../Pages/Swap/Swap";
import CoinManagement from "../Pages/CoinManage/CoinManagement";
import Error500 from "../Pages/IPblock/IPBLock";
import UserKycDetails from "../Pages/UserManagement/UserKycDetails";
import UserBankDetails from "../Pages/UserManagement/USerBankDetails";
import SiteSettings from "../Pages/Settings/SiteSettings";
import AdminWhiteList from "../Pages/AdminManagement/AdminIpWhiteList";
import SiteIpWhiteList from "../Pages/Settings/SiteIpWhiteList";
import DepositeSettings from "../Pages/DepositSettings/DepositSettings";
import TransactionHistory from "../Pages/TransactionHis/TrancationHistory";
import SwapHistory from "../Pages/SwapHis/SwapHistory";
import SiteBank from "../Pages/Settings/SiteBankDetails";
import AdminList from "../Pages/AdminManagement/AdminList";
import AdminLoginActivites from "../Pages/AdminManagement/AdminLoginActivites";
import ChangePassword from "../Pages/Authentication/ChangePassword";
import WellcomeBonus from "../Pages/BonusLIst/WellcomeBonus";
import ReferralBonus from "../Pages/BonusLIst/ReferralBonus";
import EmailTemplate from "../Pages/Settings/EmailTeamplate";
import StakingOffers from "../Pages/Stake/StakingOffersList";
import StakingOfferCreate from "../Pages/Stake/StakingOffers";
import UsersStakeHistory from "../Pages/Stake/UsersStakeHistory";
import StakeHistoryview from "../Pages/Stake/StakeHistoryview";
import AcademyList from "../Pages/Academy/AcademyList";
import TradeManagement from "../Pages/TradeManagement/TradeManage";

import SwappairManagement from "../Pages/SwappairManagement/Swappairmanag";
import HomeManagement from "../Pages/HomeManagement/HomeManagement";
import MaintenanceSettings from "../Pages/Settings/MaintenanceSettings";
import Networks from "../Pages/Networks/Networks";
import CMSManagement from "../Pages/Settings/CmsManagement";
import TradeHistory from "../Pages/TradeManagement/TradeHistory";
import TradeOpenOrders from "../Pages/TradeManagement/TradeOpenOrders";
import TradeCompletedOrders from "../Pages/TradeManagement/TradeCompletedOrders";
import TradeCancelledOrders from "../Pages/TradeManagement/TradeCancelledOrder";
import CryptoPendingWithdraw from "../Pages/WithdrawHistoryManage/CryptoWithPending";
import CryptoCompleteWithdraw from "../Pages/WithdrawHistoryManage/CryptoWithComplete";
import CryptoRejectedWithdraw from "../Pages/WithdrawHistoryManage/CryptoWithRejected";
import ActiveUsers from "../Pages/UserManagement/ActiveUsers";
import PendingUsers from "../Pages/UserManagement/PendingUsers";
import BlockedUsers from "../Pages/UserManagement/BlockedUsers";
import UserBankComplete from "../Pages/UserBankHistory/UserBankComplete";
import UserBankPending from "../Pages/UserBankHistory/UserBankPending";
import UserBankRejected from "../Pages/UserBankHistory/UserBankRejected";
import FiatCompleteWithdraw from "../Pages/FiatWithdrawHistory/FiatWithComplete";
import FiatPendingWithdraw from "../Pages/FiatWithdrawHistory/FiatWithPending";
import FiatRejectedWithdraw from "../Pages/FiatWithdrawHistory/FiatWithRejected";
import UserBankCancelled from "../Pages/UserBankHistory/UserBankCancelled";
import TodayUsersList from "../Pages/UserManagement/TodayUsersList";
import CryptoDepositComplete from "../Pages/DepositManagement/CryptoDeposit/CryptoDepositComplete";
// import CryptoDepositPending from "../Pages/DepositManagement/CryptoDeposit/CryptoDepositPending";
// import CryptoDepositRejected from "../Pages/DepositManagement/CryptoDeposit/CryptoDepositRejected";
import FiatDepositComplete from "../Pages/DepositManagement/FiatDeposit/FiatDepositComplete";
import FiatDepositPending from "../Pages/DepositManagement/FiatDeposit/FiatDepositPending";
import FiatDepositRejected from "../Pages/DepositManagement/FiatDeposit/FiatDepositRejected";
import ExpoList from "../Pages/ExpoUsers/ExpoList";
import AdminDetails from "../Pages/AdminManagement/AdminDetails";
import DiscoverList from "../Pages/DiscoverManagement/DiscoverList";
import TeamsList from "../Pages/TeamsManagement/TeamsList";
import CompareOtherExchange from "../Pages/CompareOtherExchange/CompareOtherExchange";
import ActiveUsersKyc from "../Pages/UserManagement/UserKYCDetails/ActiveUsersKyc";
import PendingUsersKyc from "../Pages/UserManagement/UserKYCDetails/PendingUserKyc";
import RejectedUsersKyc from "../Pages/UserManagement/UserKYCDetails/RejectedUsersKyc";
import AllUsersKyc from "../Pages/UserManagement/UserKYCDetails/AllUserKyc";
import NotuploadUsersKyc from "../Pages/UserManagement/UserKYCDetails/NotuploadKyc";
import ProbinarAcademy from "../Pages/ProbinarAcademy/ProbinarAcademy";
import VotingList from "../Pages/Voting/VotingList";
import HowtoClaim from "../Pages/Howtoclaim/HowtoClaim";
import VotingTransactionHistory from "../Pages/BonusLIst/VotingTransactionHistory";
import PendingVotes from "../Pages/Voting/PendingVotes";
import ApprovedVotes from "../Pages/Voting/ApprovedVotes";
import RejectedVotes from "../Pages/Voting/RejectedVotes";
import Community from "../Pages/Community/Community";
import CryptoCancelWithdraw from "../Pages/WithdrawHistoryManage/CryptoWithCancel";
import UpdateAdminUser from "../Pages/AdminManagement/UpdateAdminUser";
import AddAdminUser from "../Pages/AdminManagement/AddAdminUser";
import AddMenu from "../Pages/AdminManagement/AddMenu";
import CryptoNews from "../Pages/CryptoNews/CryptoNews";
import LiveChat from "../Pages/LiveChat/LiveChat";
import SocialList from "../Pages/SocialMedia/SocialList";
import PendingSocial from "../Pages/SocialMedia/PendingSocial";
import ApprovedSocial from "../Pages/SocialMedia/ApprovedSocial";
import RejectedSocial from "../Pages/SocialMedia/RejectedSocial";
import CryptoNewsNotes from "../Pages/CryptoNews/CryptoNewsNotes";
import MediaTransactionHistory from "../Pages/BonusLIst/MediaTransactionHistory";
import WalletType from "../Pages/AdminManagement/WalletType";
import WithdrawTallyPage from "../Pages/withdraw-tally-pages/withdrawTallyPages";
import UserOrder from "../Pages/User-Order-details/UserOrders";


import WalletCreate from "../Pages/WalletCreate/WalletList";
import NotFound from "../Pages/Page404/NotFound";
import TradeNotes from "../Pages/TradeManagement/TradeNotes";
import TaskComponent from "../Pages/Task/TaskComponent";
import StakingOffersUpdate from "../Pages/Stake/StakingOffersUpdate";
import TaskHistory from "../Pages/Task/TaskHistory";
import UsersStakeWithoutKycHistory from "../Pages/Stake/StakingWithoutKyc";
import UsersStakeWithoutWithDrawHistory from "../Pages/Stake/StakingWithoutWithdraw";
import CompletedKYCWithdrawStake from "../Pages/Stake/CompletedKYCWithdrawStake";
import FlexibleStake from "../Pages/Flexible Stacking/FlexibleStake";
import FlexibleStakeUpdate from "../Pages/Flexible Stacking/FlexibleStakeUpdate";
import FlexbleAddOffers from "../Pages/Flexible Stacking/FlexbleAddOffers";
import AdminTransaction from "../Pages/AdminManagement/AdminTransaction";
import LockedChats from "../Pages/LockedChats/LockedChats";
import AgentUsers from "../Pages/AgentUsers/AgentUsers";
import FlexibleStakePlan from "../Pages/Flexible Stacking/FlexibleStakePlan";
import EndChat from "../Pages/EndChat/EndChat";
import UserHistoryFlexibleStack from "../Pages/Flexible Stacking/UserHistoryFlexibleStack";
import FlexibleSchemeList from "../Pages/Flexible Stacking/FlexibleSchemeList";
import FlexibleStakScheme from "../Pages/Flexible Stacking/FlexibleStakScheme";
import ClaimRewardHistory from "../Pages/Flexible Stacking/ClaimRewardHistory";
import PushNotification from "../Pages/PushNotification/PushNotification";
import ChatMessageContent from "../Pages/ChatMessageContent/ChatMessageContent";
import ReferralClaimRewardHistory from "../Pages/Flexible Stacking/ReferralClaimRewardHistory";
import StakeRedeemHistory from "../Pages/Stake/StakeRedeemHistory";
import FlowInstruction from "../Pages/FlowInstruction/FlowInstruction";





const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard />},

  // Calender
 

  // Profile
  { path: "/userprofile", component: <UserProfile /> },
  { path: "/changePassword", component: <ChangePassword /> },

  { path: "/depositFiatHistory", component: <FiatDepositeCrypto /> },
  { path: "/depositCryptoHistory", component: <CryptoDepositeHistory /> },



  // KYC
  { path: "/allUsersKyc", component: < AllUsersKyc/> },
  { path: "/activeUsersKyc", component: < ActiveUsersKyc/> },
  { path: "/pendingUsersKyc", component: < PendingUsersKyc/> },
  { path: "/rejectedUsersKyc", component: < RejectedUsersKyc/> },
  { path: "/notUpladUsersKyc", component: < NotuploadUsersKyc/> },


  

  


  { path: "/userIpBlock", component: < UserIPblock/> },
  { path: "/allUsers", component: < UserList/> },
  { path: "/allUsers/userDetails/:id", component: < UserDetails/> },
  { path: "/pairManagement", component: < PairManagement/> },
  { path: "/pairs", component: < Pair/> },
  { path: "/swap", component: < Swap/> },
  { path: "/coinManagement", component: < CoinManagement/> },
  { path: "/userKycDetails", component: < UserKycDetails/> },
  { path: "/userBankDetails", component: < UserBankDetails/> },
  { path: "/siteSettings", component: < SiteSettings/> },
  { path: "/AdminWhiteList", component: < AdminWhiteList/> },
  { path: "/siteIpWhiteList", component: < SiteIpWhiteList/> },
  { path: "/depositSettings/:name", component: < DepositeSettings/> },
  { path: "/transaction-history", component: < TransactionHistory/> },
  { path: "/swap-history", component: < SwapHistory/> },
  { path: "/siteBank", component: < SiteBank/> },

  { path: "/adminUsers", component: < AdminList/> },
  { path: "/AdminLoginActivites", component: < AdminLoginActivites/> },
  { path: "/wellcomeBonus", component: < WellcomeBonus/> },
  { path: "/referralBonus", component: < ReferralBonus/> },
  { path: "/emailtemplate", component: < EmailTemplate/> },
  { path: "/stakingofferslist", component: < StakingOffers/> },
  { path: "/stakingoffers", component: < StakingOfferCreate/> },
  { path: "/usersstakehistory", component: < UsersStakeHistory/> },
  { path: "/stakehistoryview", component: < StakeHistoryview/> },
  { path: "/academylist", component: < AcademyList/> },
  { path: "/tradeManagement", component: < TradeManagement/> },
  { path: "/swappairManagement", component: < SwappairManagement/> },
  { path: "/communityManagement", component: < Community/> },

  // flexiblestacking
  { path: "/flexiblestacking", component: <FlexibleStake/> },
  { path: "/flexiblestake-update/:id", component: < FlexibleStakeUpdate/> },
  { path: "/flexiblestake-add", component: < FlexbleAddOffers/> },
  { path: "/flexiblestake-plan", component: < FlexibleStakePlan/> },
  { path: "/userhistoryFlexible", component: < UserHistoryFlexibleStack/> },
  { path: "/flexibleschemeList", component: < FlexibleSchemeList/> },
  { path: "/flexiblestackScheme", component: < FlexibleStakScheme/> },
  { path: "/flexiblestackplanlist", component: < FlexibleStakePlan/> },
  { path: "/flexibleClaimReward", component: < ClaimRewardHistory/> },
  { path: "/referralClaimRewards", component: < ReferralClaimRewardHistory/> },

  
 






  
  { path: "/homemanagement", component: < HomeManagement/> },
  { path: "/completed-crypto-withdraw-history", component: < CryptoCompleteWithdraw/> },
  { path: "/pending-crypto-withdraw-history", component: < CryptoPendingWithdraw/> },
  { path: "/rejected-crypto-withdraw-history", component: < CryptoRejectedWithdraw/> },
  { path: "/cancel-crypto-withdraw-history", component: < CryptoCancelWithdraw/> },

  { path: "/completed-fiat-withdraw-history", component: < FiatCompleteWithdraw/> },
  { path: "/pending-fiat-withdraw-history", component: < FiatPendingWithdraw/> },
  { path: "/rejected-fiat-withdraw-history", component: < FiatRejectedWithdraw/> },


  { path: "/user-bank-verifyed", component: < UserBankComplete/> },
  { path: "/user-bank-pending", component: < UserBankPending/> },
  { path: "/user-bank-rejected", component: < UserBankRejected/> },


  { path: "/maintenanceSettings", component: < MaintenanceSettings/> },
  { path: "/network", component: < Networks/> },
  { path: "/cmsmanagement", component: < CMSManagement/> },
  { path: "/tradehistory", component: < TradeHistory/> },
  { path: "/tradeactiveorders", component: < TradeOpenOrders/> },
  { path: "/tradecompletedorders", component: < TradeCompletedOrders/> },
  { path: "/tradecancelledorders", component: < TradeCancelledOrders/> },
  { path: "/todayUsers", component: < TodayUsersList/> },
  { path: "/activeUsers", component: < ActiveUsers/> },
  { path: "/pendingUsers", component: < PendingUsers/> },
  { path: "/blockedUsers", component: < BlockedUsers/> },
  { path: "/user-bank-cancelled", component: < UserBankCancelled/> },
  { path: "/usersstakeWithoutKychistory", component: < UsersStakeWithoutKycHistory/> },
  { path: "/usersstakeWithoutWithdrawhistory", component: < UsersStakeWithoutWithDrawHistory/> },
  { path: "/completedstake", component: < CompletedKYCWithdrawStake/> },
  { path: "/stakeredeemhistory", component: < StakeRedeemHistory/> },
  


  

  
//deposit history routes
  { path: "/completed-crypto-deposit-history", component: < CryptoDepositComplete/> },
  // { path: "/pending-crypto-deposit-history", component: < CryptoDepositPending/> },
  // { path: "/rejected-crypto-deposit-history", component: < CryptoDepositRejected/> },


  { path: "/completed-fiat-deposit-history", component: < FiatDepositComplete/> },
  { path: "/pending-fiat-deposit-history", component: < FiatDepositPending/> },
  { path: "/rejected-fiat-deposit-history", component: < FiatDepositRejected/> },
  { path: "/expousers", component: < ExpoList/> },
  { path: "/admindetails/:id", component: < AdminDetails/> },
  { path: "/discoverlist", component: < DiscoverList/> },
  { path: "/teamslist", component: < TeamsList/> },
  { path: "/compareotherexchanges", component: < CompareOtherExchange/> },
  { path: "/probinar_academy", component: < ProbinarAcademy/> },
  { path: "/voting", component: < VotingList/> },
  { path: "/claim_usdt", component: < HowtoClaim/> },
  { path: "/VotingTransactionHistory", component: < VotingTransactionHistory/> },
  { path: "/pendingvotes", component: < PendingVotes/> },
  { path: "/approvedvotes", component: < ApprovedVotes/> },
  { path: "/rejectedvotes", component: < RejectedVotes/> },
  { path: "/updateadminuser/:id", component: < UpdateAdminUser/> },
  { path: "/addadminuser", component: < AddAdminUser/> },
  { path: "/admintransaction", component: < AdminTransaction/> },
  { path: "/addMenu", component: < AddMenu/> },
  { path: "/walletTypes", component: < WalletType/> },
  { path: "/cryptonews", component: < CryptoNews/> },
  { path: "/livechat", component: < LiveChat/> },
  { path: "/agentlist", component: < AgentUsers/> },
  { path: "/lockedchats", component: < LockedChats/> },
  { path: "/endchat", component: < EndChat/> },
  { path: "/livechatmessagecontent", component: < ChatMessageContent/> },
  { path: "/push_notification", component: < PushNotification/> },

  { path: "/task-video", component:<TaskComponent/>},
  { path: "/taskhistory", component:<TaskHistory/>},
  { path: "/socialMediaBonus", component: <SocialList/> },
  { path: "/pendingSocialMediaBonus", component: < PendingSocial/> },
  { path: "/approvedSocialMediaBonus", component: < ApprovedSocial/> },
  { path: "/rejectedSocialMediaBonus", component: < RejectedSocial/> },
  { path: "/cryptonewsnotes", component: < CryptoNewsNotes/> },
  { path: "/walletManage", component: < WalletCreate/> },
  { path: "/cryptonewsnotes/:id", component: < CryptoNewsNotes/> },
  { path: "/media-transaction-history", component: < MediaTransactionHistory/> },
  {path: "/tallyDetails/:userId", component: <WithdrawTallyPage/>, layout: false},
  {path: "/userOrderlist/:userId", component: <UserOrder/>, layout: false},
  { path: "/flow_instruction", component:<FlowInstruction/>},
  
  //404 Found
  { path: "/pages404", component: <NotFound /> },
 
  
  { path: "/tradenotes/:streamPair", component: < TradeNotes/> },
  { path: "/stakingoffersupdate/:id", component: < StakingOffersUpdate/> },

 // Maps Pages

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },
  // Authentication Page
  { path: "/logout", component: <Logout />},
  { path: "/login", component: <Login /> },
  { path: "/email-otp", component: <EmailOtp /> },
  
  
  
  // Authentication Inner Pages
  { path: "/auth-login", component: <Login1 /> },
  
  { path: "/auth-lock-screen", component: <LockScreen /> },
  
  // Utility Pages
   //{ path: "/pages-404", component: <Error500 /> },
  // { path: "/pages-500", component: <Error500 /> },
  // { path: "/pages-maintenance", component: <Maintenance /> },
  // { path: "/pages-comingsoon", component: <ComingSoon /> },
];
const IPerror=[
  { path: "/ipBlocked", component: <Error500 /> },

]

export { authProtectedRoutes, publicRoutes,IPerror };

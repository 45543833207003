import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, Col, Tooltip, Container, Row, Spinner, InputGroup, Modal, } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { AddressCell, AddressCellFull, handleNullValue, utcToNormalTime } from "../../helpers/function";
import { AdminContext } from "../../helpers/adminContext";

import { Link } from "react-router-dom";

const CryptoCompleteWithdraw = () => {
    document.title = "Ultrapro | Withdraw Management";
    const today = new Date();
   

    const [dateRange, setDateRange] = useState([null, null]);
    const [loading, setLoading] = useState(false);
    const [downLoading, setDownLoading] = useState(false);
    const [downloadStartDate, setDownloadEndDate] = useState({});
    const [showModel, setShowModel] = useState(false)
    const [showDetails, setShowDetails] = useState({})
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const [searchLoad, setSearchLoad] = useState(false);
    const [searchValid, setSearchValid] = useState(false);
    const [inputSearch, setinputSearch] = useState('');
    const [formData, setFormData] = useState([]);
    // const [filteredData, setFilteredData] = useState([]);
    

    const [totalPagination, setTotalPagination] = useState(1);
    const [totalLimit, setTotalLimit] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = totalLimit; // Number of items to display per page
    const pageCount = Math.ceil(totalPagination);
    const [count, setCount] = useState("");
    const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1);

    //Permission List
    const { adminInfo } = useContext(AdminContext);
    const [adminType, setAdminType] = useState('');

    useEffect(() => {
        ipList();
        setAdminType(adminInfo?.adminType);
    }, []);

    const ipList = async () => {
        setLoading(true);
        try {
            const response = await postData(Apiservices.DEPOSIT_CRYPTO_COMPLETED_HISTORY,{});
            console.log(response,'DEPO');
            
            if (response.status === true) {
                setLoading(false);
                setFormData(response.data.data);
                // handleDropdownClick('All')
                // setFilteredData(response.data.data)
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);
            } else {
                toast.error(response.message);
                setLoading(false);
            }
        } catch (error) {
            // toast.error("Internal Server Error...");
            setLoading(false);
        }
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        handlePagePost(selected);
    };
    
    const handlePagePost = async (selected) => {
        setLoading(true);
        const postForm = {
            page: selected + 1,
        };
        if (dateRange[0] && dateRange[1]) {
            postForm.startDate = formatDate(dateRange[0]);
            postForm.endDate = formatDate(dateRange[1]);
        }
        if (inputSearch) {
            postForm.search = inputSearch;
        }
        try {
            const response = await postData(Apiservices.DEPOSIT_CRYPTO_COMPLETED_HISTORY,postForm);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);
            } else {
                setLoading(false);
                toast.error(response.message);
            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };
    const [tooltipOpen_tally, setTooltipOpen_tally] = useState(false);
    const toggleTooltip_tally = () => setTooltipOpen_tally(!tooltipOpen_tally);
    //redirect tally page
    const handleTallyRedirect = async (userData) => {        
        let userId = userData?.userId
        if(userId){
            window.open(`/tallyDetails/${userId}`, '_blank');
        }
    }
    const handleShowModel = (value) => {
        setShowDetails(value)
        setShowModel(true)
    }
    const allowedActions = [1, 2, 3, 4];


    const columns = [
        {
            name: 'S.No',
            // selector: (row, index) => currentPage * itemsPerPage + index + 1,
            selector: (row) => handleNullValue(row.serialNumber),
            sortable: true,
            minWidth: '100px'

        },
        {
            name: 'Date',
            selector: row => row.createdAt
                ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${(utcToNormalTime(row.createdAt).time)}`
                : "---",
            sortable: true,
            minWidth: '200px'

        },
        {
            name: 'Name',
            selector: row => handleNullValue(row?.fullName),
            sortable: true,
            minWidth: '150px'

        },

        {
            name: 'Email',
            selector: row => 
                <div className="d-flex align-items-center">
            <Link className="link-tag" to={`/allUsers/userDetails/${row.userId}`} target="_blank">
              <span className="mx-1 text-decoration-underline">
                {row.email || '-'}
              </span>
            </Link>
          </div>,
            sortable: true,
            minWidth: '250px'

        },
        {
            name: 'Currency Name',
            selector: row => handleNullValue(row.coin),
            sortable: true,

            minWidth: '150px'

        },
        {
            name: 'Address',
            selector: row => <AddressCell address={row.toAddress} />,
            sortable: true,
            minWidth: '150px'


        },
        {
            name: 'Contract Address',
            selector: row => row.tokenContractAddress 
              ? <AddressCell address={row.tokenContractAddress} /> 
              : '-',
            sortable: true,
            minWidth: '200px'
          },
    
        // {
        //     name: 'TDS',
        //     selector: row => handleNullValue(formatNumber(row?.tds, row?.coinsList.decimalPoint)),
        //     sortable: true,
        //     minWidth:'100px'

        // },
        {
            name: 'Amount',
            selector: row => handleNullValue(row.receivedAmount),
            sortable: true,
            minWidth: '150px'

        },
        // {
        //     name: 'Transaction ID',
        //     cell: row => (
              
        //         <AddressCell address={row.walletTxid} />
             
        //     ),
        //     sortable: true,
        //     minWidth: '150px',
        //   },
        
        {
            name: 'Transaction ID',
            cell: row => (
        
                <>
                  {row.explorerLink ? (
                    <div className="d-flex align-items-center">
                      <a href={`${row.explorerLink}`} target='_blank' rel="noopener noreferrer">
                        <span className="mx-1 text-decoration-underline">
                        {row.txid.substring(0, 12) || '-'}
                        </span>
                      </a>
                      <button
                        onClick={() => navigator.clipboard.writeText(row.txid)}
                        className="btn btn-link p-0 mx-2"
                        title="Copy Transaction ID"
                      >
                        <i className="fas fa-copy"></i> {/* FontAwesome icon or any other icon library */}
                      </button>
                    </div>
                  ) : (
                    <span>{row.explorerLink || '-'}</span>
                  )}
                </>
              
            ),
            sortable: true,
            minWidth: '150px',
          },
          

    
    
        // {
        //     name: 'TDS Percentage',
        //     selector: row => `${handleNullValue((row.tdsPercentage).toFixed(2))}%`,
        //     sortable: true,
        //     grow:2

        // },
        ...(adminType === 1 || adminInfo.permissions.some(data => data.module === "Completed History" && allowedActions.some(action => data.actions.includes(action))) ? [
            {
                name: 'Action',
                cell: row => (
                    <div className="edit d-flex">
                        {(adminType === 1 || adminInfo.permissions.some(data =>
                            data.module === "Completed History" && data.actions.includes(2))) ? (
                            <>
                                <span id={`tooltipEmail${row.id}`} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline mx-1" style={{ fontSize: '15px', cursor: 'pointer' }}></span>
                                <Tooltip placement="top" isOpen={tooltipOpen1} target={`tooltipEmail${row.id}`} toggle={toggleTooltip1}> View </Tooltip>
                            </>
                        ) : null}
                        {(adminType === 1 || adminInfo?.permissions.some(data => 
                        data.module === "Pending History" && data?.actions?.includes(3))) ? (
                            <>
                                <span id={`tooltipTally${row.id}`} onClick={()=> handleTallyRedirect(row)} className="mdi mdi-note-outline"
                                style={{ fontSize: '15px', cursor: 'pointer' }}></span>
                                <Tooltip placement="top" isOpen={tooltipOpen_tally} target={`tooltipTally${row.id}`} toggle={toggleTooltip_tally}>
                                    Tally Withdraw 
                                </Tooltip>
                            </>
                    ):null}
                    </div>
                ),
                ignoreRowClick: true,
                // allowOverflow: true,
                // button: true,


            }] : []),
    ]

    const handleSearchChanage = (e) => {
        const value = e.target.value
        if (value == '') {
            ipList()
            setSearchValid(false)
        }
        setinputSearch(value)
    }

    const handleSearch = async () => {
        if (inputSearch !== '') {
            setSearchLoad(true);
            const postForm = {
                search: inputSearch.trim(),
                page: 1 ,
                limit: itemsPerPage,
            };

          
            try {
                const response = await postData(Apiservices.DEPOSIT_CRYPTO_COMPLETED_HISTORY,postForm);
                console.log(response,'SEARCH');
                
                if (response.status) {
                    setSearchLoad(false);
                    setFormData(response.data.data);
                    setTotalPagination(response.data.totalPages);
                    setTotalLimit(response.data.limit)
                    setCount(response.data.currentCount);
                } else {
                    setSearchLoad(false);
                }
            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
                setSearchLoad(false);
            }
        } else {
            setSearchValid(true)
        }
    }

 

  
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleDateChange = ([start, end]) => {
        const formattedStart = start ? formatDate(start) : null;
        const formattedEnd = end ? formatDate(end) : null;
        setDateRange([start, end]);
        if (!formattedStart && !formattedEnd) {
            ipList();
            setDownloadEndDate({})
        }

        if (formattedStart && formattedEnd) {
            setDownloadEndDate({ startDate: formattedStart, endDate: formattedEnd })
            handleDate({ startDate: formattedStart, endDate: formattedEnd });
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    const handleDate = async ({ startDate, endDate }) => {
        setLoading(true);
        const postForm = {
            startDate: startDate,
            endDate: endDate,
        }

        try {
           

            const response = await postData(Apiservices.DEPOSIT_CRYPTO_COMPLETED_HISTORY,postForm);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                                setTotalPagination(response.data.totalPages);


            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };

    const handleDownload = async (type) => {

        setDownLoading(true)
        try {
            const postForm = {};
            if (downloadStartDate.startDate) {
                postForm.startDate = downloadStartDate.startDate;
            }
            if (downloadStartDate.endDate) {
                postForm.endDate = downloadStartDate.endDate;
            }
            if (inputSearch) {
                postForm.search = inputSearch;
            }
          

            const response = await axiosInstance.post(
                Apiservices.DEPOSIT_DOWNLOAD_HISTORY,
                postForm,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    responseType: 'blob' // Make sure the response is treated as a binary blob
                }
            );
          
            if (response && response.data) {
                setDownLoading(false);
                // Use the blob directly
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `CryptoCompletedUserHistory.xlsx`);
                document.body.appendChild(link);
                link.click();
            
                // Cleanup
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error("Failed to download file");
                setDownLoading(false);
            }
            
        } catch (err) {
            setDownLoading(false)
            console.error('Error downloading the file', err);
        }
    };
    

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Deposit History" breadcrumbItem="Crypto Deposit Completed History" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col sm={4} lg={3}>
                                            <div className="input-group">
                                                <Input
                                                    // onChange={handleInputChange}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name,Email,Address,ID ..."
                                                    aria-label="Recipient's username"
                                                    onChange={handleSearchChanage}
                                                    invalid={searchValid}
                                                    onKeyPress={handleKeyPress}
                                                />
                                                <div className="input-group-append">

                                                    <button className="btn btn-primary" type="submit"
                                                        onClick={() => handleSearch()} disabled={searchLoad}
                                                    >
                                                        {searchLoad ? (

                                                            <Spinner className="text-light" size="sm" />
                                                        ) : (

                                                            <i className="ri-search-line" />
                                                        )}

                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={4} lg={3}>
                                            <div className="form-group mb-4">

                                                <div>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="Select Start to End Date"
                                                            options={{
                                                                mode: 'range',
                                                                altInput: true,
                                                                altFormat: "F j, Y",
                                                                dateFormat: "Y-m-d",
                                                                maxDate: today,

                                                            }}
                                                            value={dateRange}
                                                            onChange={handleDateChange}
                                                        />
                                                    </InputGroup>
                                                </div>

                                            </div>
                                        </Col>

                                        <Col className="col-sm">

                                            <div className="d-flex justify-content-sm-end">
                                                {/* <Dropdown
                                                    isOpen={btnauto3}
                                                    toggle={() => setbtnauto3(!btnauto3)}
                                                    id="dropdownMenuClickableInside"
                                                    disabled={downLoading}
                                                >
                                                    <DropdownToggle tag="button" className="btn btn-info">
                                                        {downLoading ? <Loader /> : "Download"}

                                                        <i className="mdi mdi-chevron-down" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start">
                                                        <DropdownItem header>Select</DropdownItem>
                                                        <DropdownItem onClick={() => handleDownload('xlsx')}>Excel</DropdownItem>
                                                        <DropdownItem onClick={() => handleDownload('csv')}>CSV</DropdownItem>
                                                        <DropdownItem onClick={() => handleDownload('pdf')}>PDF</DropdownItem>


                                                    </DropdownMenu>
                                                </Dropdown> */}
                                                {/* <Button color="info" className="add-btn mx-2" onClick={handleDownload} id="create-btn">
                                                    <i className="mdi mdi-download-outline"></i>
            
                                                    {downLoading ? (
                                                        <Loader />
                                                    ) : ("Download")}
                                                </Button> */}
                                          



                                              
                                                <Button
                                                    color="info"
                                                    className="add-btn mx-2"
                                                    onClick={() => handleDownload('xlsx')}
                                                    id="create-btn"
                                                    disabled={downLoading}
                                                >
                                                    <i className="mdi mdi-download-outline"></i>

                                                    {downLoading ? <Loader /> : "Download"}
                                                </Button>


                                            </div>
                                        </Col>
                                    </Row>


                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={formData}
                                            paginationServer
                                            paginationTotalRows={formData?.length}
                                            progressPending={loading}
                                            persistTableHead={true}
                                            progressComponent={<div className="py-3">Loading...</div>}
                                            // noDataComponent={formData.length===0}
                                            noDataComponent={<div className="py-3">No records found</div>}
                                            fixedHeader={true}

                                        />
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 col-md-6">
                                            <div className="d-flex justify-content-start mt-3">
                                                <span>Current Count : {count}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {loading || formData?.length == 0 ? (
                                                ""

                                            ) : (

                                                <div className="d-flex justify-content-end mt-3">
                                                    <div className="pagination pagination-rounded mb-0">
                                                        <ReactPaginate
                                                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={pageCount}
                                                            marginPagesDisplayed={1}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={handlePageClick}
                                                            containerClassName={"pagination"}
                                                            activeClassName={"active"}
                                                            pageLinkClassName={"page-link"}
                                                            breakLinkClassName={"break-link"}
                                                            disabledClassName={"disabled"}
                                                            initialPage={currentPage}
                                                            disableInitialCallback={true}
                                                            forcePage={currentPage}
                                                            // disableInitialCallback={true}
                                                            renderLink={(props) => {
                                                                if (
                                                                    props.page === "..." &&
                                                                    props.pageCount > props.pageRangeDisplayed
                                                                ) {
                                                                    return (
                                                                        <a
                                                                            {...props}
                                                                            onClick={() =>
                                                                                handlePageClick({
                                                                                    selected: pageCount - 1,
                                                                                })
                                                                            }
                                                                        >
                                                                            {pageCount}
                                                                        </a>
                                                                    );
                                                                }
                                                                return <a {...props} />;
                                                            }}
                                                        />
                                                    </div>


                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal size="l" className='modal-dialog modal-lg' isOpen={showModel} centered backdrop="static" >

                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
                        Completed Deposit History View
                    </h5>
                    <button
                        onClick={() => { setShowModel(false); }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body custom-modal-body">
                    <div className="table-responsive">
                        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td><b>Name</b></td>
                                    <td>{showDetails?.fullName || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Email</b></td>
                                    <td>{showDetails?.email || '-'}</td>
                                </tr>
                               
                                <tr>
                                    <td><b>Transaction ID</b></td>
                                    
                                  
                                        <td>
                                        {showDetails.explorerLink ? (
                                            <a href={`${showDetails.explorerLink}`} target='_blank' rel="noopener noreferrer">
                                            <span className="mx-1 text-decoration-underline">
                                                {showDetails.txid}
                                            </span>
                                            </a>
                                        ) : (
                                            <span>-</span>
                                        )}
                                        </td>
                                    
                                    </tr>

                                    
                                <tr>
                                    <td><b>Currency Name</b></td>
                                    <td>{showDetails.coin || '-'}</td>
                                </tr>
                                    {showDetails.raw ? (
                                               <tr>
                                               <td><b>Memo Tag</b></td>
                                               <td>{showDetails.raw || '-'}</td>
                                           </tr>
                                    ):(
                                        ""
                                    )}
                                 
                                <tr>
                                    <td><b>Deposit Address</b></td>
                                    <td><AddressCellFull address={showDetails?.toAddress || "-"} /></td>
                                </tr>
                                <tr>
                                    <td><b>Deposit Amount</b></td>
                                    <td>{showDetails.receivedAmount|| 0}</td>
                                </tr>
                         
                              
                            
                              
                                <tr>
                                    <td><b>Date & Time</b></td>
                                    <td>{utcToNormalTime(showDetails?.createdAt).date} {utcToNormalTime(showDetails?.createdAt).time}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default CryptoCompleteWithdraw;

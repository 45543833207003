import React, { useState, useEffect } from "react";
import { Input, Card, CardBody, Col, Container, Row, Button, Modal, ModalBody, ModalHeader, CardImg, Spinner, Form, InputGroup } from "reactstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useLocation } from "react-router-dom";
import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue, utcToNormalTime ,getVoteBadgeClass,getVoteStatusText} from "../../helpers/function";

const ApprovedSocial = () => {
  document.title = "Ultrapro | Voting List";

  const location = useLocation();
  const today = new Date();
  const userApi = location.state?.user;

  const [dateRange, setDateRange] = useState([null, null]);
  const [downloadStartDate, setDownloadEndDate] = useState({});
  const [loading, setLoading] = useState(false);
  const [downLoading, setDownLoading] = useState(false);

  const [searchLoad, setSearchLoad] = useState(false);
  const [searchValid, setSearchValid] = useState(false);
  const [inputSearch, setinputSearch] = useState("");
  const [formData, setFormData] = useState([]);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [count, setCount] = useState("");
 

  const [user, setUser] = useState(userApi);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);
  // const pageCount = 5;

  useEffect(() => {
    if (user === undefined) {
      const postForm = {
       status:'Approved'
      };
      const queryString = new URLSearchParams(postForm).toString();
      setUser(Apiservices.ApprovedVotesList);

      // setUser(`${Apiservices.ApprovedVotesList}?${queryString}`);
      ipList();
    }
  }, [user]);

  const handleFilter = async (apiName) => {

    setLoading(true);
    // const postForm = {
    //     ...filterParams
    // };
    try {
      const response = await postData(apiName);
      if (response.status === true) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setFormData("");
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      setFormData("");
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const ipList = async () => {
    setLoading(true);

    try {
      const postForm = {
        status:'Approved'
       };
       const queryString = new URLSearchParams(postForm).toString();

      const response = await postData(`${Apiservices.ApprovedVotesList}?${queryString}`);

      if (response.status === true) {
        setLoading(false);
        setFormData(response.data.data);
  

        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setFormData("");
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      setFormData("");
      toast.error("Internal Server Error...");
      setLoading(false);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);

  };

  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: rowsPerPage,
      search: inputSearch,
        status:'Approved'
    };

    if (dateRange[0] && dateRange[1]) {
      postForm.startDate = formatDate(dateRange[0]);
      postForm.endDate = formatDate(dateRange[1]);
    }

    const queryString = new URLSearchParams(postForm).toString();
    try {
      if (user === undefined) {
        const response = await postData(`${Apiservices.ApprovedVotesList}?${queryString}`);

        if (response.status === true) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
        } else {
          setLoading(false);
          setFormData("");
        }
      } else {
        const response = await postData(`${user}?${queryString}`);

        if (response.status === true) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
          setFormData("");
          toast.error(response.message);
        }
      }
    } catch (error) {
      setFormData("");
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

 
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);

  }
  const [showDetails, setShowDetails] = useState('')
  const [getStatus, setGetStatus] = useState('')
  const [getId, setId] = useState()
  const handleUpdate = (record) => {
    setShowDetails(record)
    setId(record.votingId);
    setGetStatus(record.votingId);
    setmodal_update_list(true);
  };



  const columns = [

    {
      name: "S.No",
      selector: (row, index) => currentPage * itemsPerPage + index + 1,
      sortable: true,
      minWidth: '50px',
    },
    // {
    //   name: "Voteid",
    //   selector: (row) => handleNullValue(row.votingId),
    //   sortable: true,
    //   minWidth: '100px',
    //   // grow: 2,
    // },
    {
      name: "email",
      selector: (row) => handleNullValue(row?.userDetails?.email),
      sortable: true,
      minWidth: '300px',
     
    },
    {
      name: "Name",
      selector: (row) => handleNullValue(row?.userDetails?.fullName),
      sortable: true,
      minWidth: '200px',
    
    },
    {
      name: "Site Name",
      selector: (row) => handleNullValue(row?.category),
      sortable: true,
      minWidth: '200px',
   
    },
    
    {
      name: "Image",
      selector: (row) => <img  style={{ width: "100px",   height: "50px", objectFit: "cover",}} 
        className="img-fluid" src={row.votedImage || '-'} />,
      sortable: true,
      minWidth: '150px',
      maxWidth: '200px'
    },

    {
      name: "Status",
      selector: (row) => (
        <div className="edit">
          <span className={`badge ${getVoteBadgeClass(row.status)} me-1`}>
            {getVoteStatusText(row.status)}
          </span>
        </div>
      ),
      sortable: true,
      minWidth: '100px',
      // grow: 2,
    },
    {
      name: "Date & Time",
      selector: (row) =>
        row.createdAt
          ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${utcToNormalTime(row.createdAt).time
          }`
          : "---",
      sortable: true,
      minWidth: '200px',
      // grow: 3,
    },
    // {
    //   name: "Updated Date & Time",
    //   selector: (row) =>
    //     row.updatedAt
    //       ? `${handleNullValue(utcToNormalTime(row.updatedAt).date)} ${
    //           utcToNormalTime(row.updatedAt).time
    //         }`
    //       : "---",
    //   sortable: true,
    //   minWidth: '200px',
    //   // grow: 3,
    // },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex">
          

            <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-eye-outline cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>

        </div>
      ),
      ignoreRowClick: true,
      minWidth: '150px',
      maxWidth: '200px'
    },

  ];

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "" && user === undefined) {
      ipList();
      setSearchValid(false);
    } else if (value === "") {
      const postForm = {
        status:'Approved'
      }
      const queryString = new URLSearchParams(postForm).toString();
      handleFilter(`${Apiservices.ApprovedVotesList}?${queryString}`);
    }
    setinputSearch(value);
  };

  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
          status:'Approved'
        // page: currentPage + 1,
        // limit: itemsPerPage,
      };

      const queryString = new URLSearchParams(postForm).toString();
      try {
        if (user === undefined) {
          const response = await postData(`${Apiservices.ApprovedVotesList}?${queryString}`);
          if (response.status === true) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);
          } else {
            setSearchLoad(false);
            setFormData("");
          }
        } else {
          const response = await postData(`${user}?${queryString}`);
          if (response.status === true) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);

          } else {
            setSearchLoad(false);
            setFormData("");
          }
        }
      } catch (error) {
        setFormData("");
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };


  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  
  const handleDateChange = ([start, end]) => {
    const postForm = {
      status:'Approved'
    }
    const queryString = new URLSearchParams(postForm).toString();
    const formattedStart = start ? formatDate(start) : null;
    const formattedEnd = end ? formatDate(end) : null;

    setDateRange([start, end]);

    if (!formattedStart && !formattedEnd) {
      handleFilter(`${Apiservices.ApprovedVotesList}?${queryString}`);
      setDownloadEndDate({});
    }

    if (formattedStart && formattedEnd) {
      setDownloadEndDate({
        startDate: formattedStart,
        endDate: formattedEnd,
      });
      handleDate({ startDate: formattedStart, endDate: formattedEnd });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

 
  const handleDate = async ({ startDate, endDate }) => {
    setLoading(true);

    const postForm = {
      startDate: startDate,
      endDate: endDate,
        status:'Approved'
    };
    if (inputSearch) {
      postForm.search = inputSearch
    }
    // if (selectedCountry) {
    //   postForm.country = selectedCountry
    // }
   
    try {
      const queryString = new URLSearchParams(postForm).toString();

      const response = await postData(`${user}?${queryString}`);
      if (response) {
        setLoading(false);
     
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };
  const handleDownload = async (format) => {
    setSelectedFormat(format);
    setDownLoading(true);
    try {
      const postForm = {
          status:'Approved'
      };

      if (downloadStartDate.startDate) {
        postForm.startDate = downloadStartDate.startDate;
      }
      if (downloadStartDate.endDate) {
        postForm.endDate = downloadStartDate.endDate;
      }
      if (inputSearch) {
        postForm.search = inputSearch;
      }
      postForm.download = true;
      postForm.format = format
      const queryString = new URLSearchParams(postForm).toString();

      const response = await axiosInstance.post(
        `${user}?${queryString}`,
        {
          // Include any data you need to send with the POST request here
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
      if (response.data) {
        setDownLoading(false);
        const blob = response.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute('download', `${user}.xlsx`);
        link.setAttribute("download", `ApprovedVotesList.${format}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error(response.data.message);
        setDownLoading(false);
      }
    } catch (err) {
      setDownLoading(false);
      console.error("Error downloading the file", err);
    }
  };

;
  const [selectedFormat, setSelectedFormat] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage));
    HandleClickRowperpage(newRowsPerPage)
  }
  const HandleClickRowperpage = async (newRowsPerPage) => {
    setLoading(true);
    const postForm = {
      // page: selected + 1,
      page: Number(currentPage + 1),
      limit: Number(newRowsPerPage),
      // search: inputSearch,
    };

    if (dateRange[0] && dateRange[1]) {
      postForm.startDate = formatDate(dateRange[0]);
      postForm.endDate = formatDate(dateRange[1]);
    }

    const queryString = new URLSearchParams(postForm).toString();
    try {
      if (user === undefined) {
        const response = await postData(`${Apiservices.ApprovedVotesList}?${queryString}`);

        if (response.status === true) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
        } else {
          setLoading(false);
          setFormData("");
        }
      } else {
        const response = await postData(`${user}?${queryString}`);

        if (response.status === true) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setFormData("");
          setLoading(false);
          toast.error(response.message);
        }
      }
    } catch (error) {
      setFormData("");
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }

  };
  const exportOptions = [
    { label: "PDF", value: "pdf" },
    { label: "CSV", value: "csv" },
    { label: "XLSX", value: "xlsx" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Approved Votes" breadcrumbItem="Approved Voting List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col sm={4} lg={3}>
                      <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                            {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4} lg={3}>
                      <div className="form-group mb-4">
                        <div>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select Start to End Date"
                              options={{
                                mode: "range",
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                                maxDate: today,
                              }}
                              value={dateRange}
                              onChange={handleDateChange}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Col>

                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <Button
                          color="info"
                          className="add-btn mx-2"
                          onClick={() => handleDownload('xlsx')}
                          id="create-btn"
                          disabled={downLoading}
                        >
                          <i className="mdi mdi-download-outline"></i>

                          {downLoading ? <Loader /> : "Download"}
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={formData}
                      paginationServer
                      paginationTotalRows={formData.length}
                      progressPending={loading}
                      persistTableHead={true}
                      progressComponent={<div className="py-3">Loading...</div>}
                      noDataComponent={<div className="py-3">No records found</div>}
                      fixedHeader={true}
                      paginationPerPage={rowsPerPage} // Add this line to control rows per page
                    />
                  </div>
                  

                  <div className="container">
                    <div className="row justify-content-end mt-4">
                      <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                        <div className="d-flex align-items-center me-3">
                          <span className="me-2">Rows per page:</span>
                          <select
                            value={rowsPerPage}
                            onChange={(e) => handleRowsPerPageChange(e.target.value)}
                            className="form-control-sm"
                          >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                          </select>
                        </div>
                        <div className="me-md-3 mb-3 mb-md-0">
                          <span>{count}</span>
                        </div>
                        <div className="pagination pagination-rounded mt-3">
                          <ReactPaginate
                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            breakLinkClassName={"break-link"}
                            disabledClassName={"disabled"}
                            initialPage={currentPage}
                            disableInitialCallback={true}
                            forcePage={currentPage}
                            renderLink={(props) => {
                              if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                return (
                                  <a
                                    {...props}
                                    onClick={() =>
                                      handlePageClick({
                                        selected: pageCount - 1,
                                      })
                                    }
                                  >
                                    {pageCount}
                                  </a>
                                );
                              }
                              return <a {...props} />;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* UpdateModal */}
                  <Modal className="modal-dialog modal-lg" isOpen={modal_update_list} toggle={() => { tog_update_list(); }} centered>
                    <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>
                      Voting Details
                    </ModalHeader>
                    <Form
                      autoComplete="off" className="form-horizontal"
                    // onSubmit={handleSubmit}
                    >
                      <ModalBody>
                        <Row>
                          <div className="table-responsive">
                            <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                              <tbody>
                              <tr>
                                  <td><b>Site Name</b></td>
                                  <td>{showDetails?.category || '-'}</td>
                                </tr>
                                <tr>
                                  <td><b>User Name</b></td>
                                  <td>{showDetails?.userDetails?.fullName || '-'}</td>
                                </tr>
                                <tr>
                                  <td><b>User Email Address</b></td>
                                  <td>{showDetails?.userDetails?.email || '-'}</td>
                                </tr>
                                {/* <tr>
                                    <td><b>Voting ID</b></td>
                                    <td>{showDetails?.votingId || '-'}</td>
                                </tr> */}
                              
                                    <tr>
                                  <td><b>Admin Name</b></td>
                                  <td>{showDetails?.adminDetails?.name || '-'}</td>
                                </tr>
                                  <tr>
                                  <td><b>Admin Email Address</b></td>
                                  <td>{showDetails?.adminDetails?.email || '-'}</td>
                                </tr>
                                <tr>
                                  <td><b>Reason</b></td>
                                  <td>{showDetails?.userDetails?.email || '-'}</td>
                                </tr>
                                <tr>
                                  <td><b>Status</b></td>
                                  <td>
                                    {showDetails.status === 1
                                      ? 'Approved'
                                      : showDetails.status === 2
                                        ? 'Rejected'
                                        : 'Pending'}
                                  </td>

                                </tr>

                                <tr>

                                  <td><b>Approved Date & Time</b></td>
                                  <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                                </tr>
                                {/* <tr>

                                <td><b>Rejected Date & Time</b></td>
                                <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                                </tr> */}
                                <tr>
                                  <td><b>Image</b></td>
                                  <td>
                                    <Card style={{ width: '80%' }}>
                                      <CardBody>
                                        <CardImg

                                          top
                                          src={showDetails.votedImage || '-'}
                                          style={{ width: '100%', objectFit: 'cover' }}
                                        />
                                      </CardBody>
                                    </Card>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Row>

                      </ModalBody>
                      
                    </Form>
                  </Modal>

                
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ApprovedSocial;

import DataTable from 'react-data-table-component';
import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form, Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
// import { useTable, usePagination } from 'react-table';
import { writeFile, utils } from 'xlsx';
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { AdminContext } from '../../helpers/adminContext';

const TradeManagement = () => {
    const navigate = useNavigate()
    document.title = "Ultrapro | Swap Management";
    const [selectedCurrency, setSelectedCurrency] = useState('USDT');
    const [loading, setLoading] = useState(false)
    const [deleteLoad, setDeleteLoad] = useState(false)
    const [isEdit, setisEdit] = useState(false);
 

    const [recordToUpdate, setRecordToUpdate] = useState({});
    const [btnauto3, setbtnauto3] = useState(false);

    const [formData, setFormData] = useState([])
    const [selectedData, setSelectedData] = useState([])

    //Permission List
    const { adminInfo } = useContext(AdminContext);
    const [adminType,setAdminType] = useState('')

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // Number of items to display per page
    const pageCount = Math.ceil(formData.length / itemsPerPage);
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };



    const handleKeyDown = (event) => {
    const allowedKeys = [
      'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab', 
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 
      'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9', 
      '.'
    ];

    if (!allowedKeys.includes(event.key)) {
      event.preventDefault(); // Prevent the default behavior for disallowed keys
    }
  };
const validateValue = (value,decimal) => {
    const parts = value.split('.');
    if (parts.length > decimal) {
        value = parts[0] + '.' + parts.slice(1).join('');
    }
    if (parts.length > 1) {
        const integerPart = parts[0];
        const decimalPart = parts[1].slice(0, decimal);
        value = `${integerPart}.${decimalPart}`;
    } else {        
        value = parts[0];
    }    
    return value;
};

    useEffect(() => {
        tradePair()
        setAdminType(adminInfo?.adminType)
    }, [])
    const tradePair = async () => {

        try {
            setLoading(true)
            const response = await postData(Apiservices.USER_SWAP_PAIR_LIST)
            if (response.status) {
                const filteredData = response.data;
                setFormData(filteredData);
                setSelectedData(response.data)
                setLoading(false)


            } else {
                setLoading(false)

            }

        } catch (error) {


        }


    }



    const validationAdd = useFormik({
        enableReinitialize: true,

        initialValues: {
            pairName: '',
            fromCurrency: '',
            toCurrency: '',
            streamPair: '',
            inrPrice: '',
            isActive: '',
            priceDecimal: '',
            amountDecimal: '',
            tradeType: '',
            proUsers: '',
            swapRate: '',
            minTradeamount: '',
            maxTradeamount: '',
            limitPart: '',
            feeType: '',
            fee: '',
            increaseOrdecreasepercent: '',
            // marketPrice: '',
            plusOrminus: '',
            spendPrice: '',



        },
        validationSchema: Yup.object({
            pairName: Yup.string().required("pairName is required"),
            fromCurrency: Yup.string().required("From Currency is required"),
            toCurrency: Yup.string().required("To Currency is required"),            
            inrPrice: Yup.string(),
            isActive: Yup.string().required("Active Status is required"),
            priceDecimal: Yup.string().required("Price Decimal is required"),
            amountDecimal: Yup.string().required("Amount Decimal is required"),
            // swapRate: Yup.string().required("Swap Rate is required"),
            minTradeamount: Yup.string()
            .required("minTradeamount is required")
            .test(
            'is-less-than-or-equal-to-max',
            'minTradeamount must be less than or equal to maxTradeamount',
            function (value) {
                const { maxTradeamount } = this.parent; // Access other values in the same form
                if (!value || !maxTradeamount) return true; // Skip validation if either is not provided
                return parseFloat(value) <= parseFloat(maxTradeamount); // Custom validation logic
            }
        ),
        maxTradeamount: Yup.string()
        .required("maxTradeamount is required")
        .test(
            'is-greater-than-min',
            'maxTradeamount must be greater than or equal to minTradeamount',
            function (value) {
                const { minTradeamount } = this.parent; // Access other values in the same form
                if (!value || !minTradeamount) return true; // Skip validation if either is not provided
                return parseFloat(value) > parseFloat(minTradeamount); // Custom validation logic
            }
        ),
            limitPart: Yup.string().required("limitPart is required"),
            feeType: Yup.string().required("feeType is required"),
            fee: Yup.string().required("fee is required"),
            increaseOrdecreasepercent: Yup.string().required("Increase or decrease percent is required"),
            // marketPrice: Yup.string().required("marketPrice is required"),
            plusOrminus: Yup.string().required("plusOrminus is required"),
            spendPrice: Yup.string().required("Reserve amount is required"),



        }),
        onSubmit: (values) => {
            handleAdd(values)



        },
    });






    const handleChange = (e) => {
        const { name, value } = e.target;
        // Allow only letters and convert to uppercase
        const filteredValue = value.toUpperCase();
    
        validationAdd.setFieldValue(name, filteredValue);
    
        let { fromCurrency, toCurrency } = validationAdd.values;
    
        if (name === 'fromCurrency') {
          fromCurrency = filteredValue;
        }
        if (name === 'toCurrency') {
          toCurrency = filteredValue;
        }
    
        if (fromCurrency || toCurrency) {
          validationAdd.setFieldValue('pairName', `${fromCurrency}_${toCurrency}`);
          validationAdd.setFieldValue('streamPair', `${fromCurrency.toLowerCase()}${toCurrency.toLowerCase()}`);
        }
      };


      const handleChange1 = (e,decimal) => {
        const { name, value } = e.target;
        const filteredValue = validateValue(value,decimal);
        validationAdd.setFieldValue(name, filteredValue);
      };

    const handleAdd = async (data) => {
        const postForm = {
            pair: data.pairName,
            fromCurrency: data.fromCurrency,
            toCurrency: data.toCurrency,
            streamPair: data.streamPair,
            inrPrice: data.inrPrice,
            isActive: Number(data.isActive),
            amountDecimal: data.amountDecimal,
            priceDecimal: data.priceDecimal,
            swapRate: Number(data.swapRate),
            minTradeamount: Number(data.minTradeamount),
            maxTradeamount: Number(data.maxTradeamount),
            fee: Number(data.fee),
            feeType: data.feeType,
            limitPart: data.limitPart,
            increaseOrdecreasepercent: data.increaseOrdecreasepercent,
            marketPrice: data.marketPrice,
            plusOrminus: data.plusOrminus,
            proUsers: Number(data.proUsers),
            spendPrice: Number(data.spendPrice),
        }
        setLoading(true)

        try {
            let response;
            if (Object.keys(recordToUpdate).length === 0) {
                response = await postData(Apiservices.USER_SWAP_ADD, postForm)
            } else {
                response = await postData(Apiservices.USER_SWAP_EDIT_LIST, postForm)
            }
            if (response.status) {
                tradePair()
                setLoading(false)

                toast.success(response.message)
                setmodal_update_list(false)
                validationAdd.resetForm();


            } else {
                setLoading(false)
                toast.error(response.message)
            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later")
            setLoading(false)


            validationAdd.resetForm();
        }




    }



    const [modal_update_list, setmodal_update_list] = useState(false);
    function tog_update_list() {
        setisEdit(false);
        setmodal_update_list(!modal_update_list);
        if (modal_update_list) {
            resetFormValues();
            setRecordToUpdate({})
            clearErrors();
        }

    }
    function clearErrors() {
        Object.keys(validationAdd.errors).forEach((key) => {
            validationAdd.setFieldError(key, null);
        });
    }
    
    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }








    const utcToNormalTime = (utcString) => {
        const date = new Date(utcString);
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`;
        const formattedTime = `${date.getHours()
            .toString()
            .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
                .getSeconds()
                .toString()
                .padStart(2, "0")}`;
        return { date: formattedDate, time: formattedTime };
    };

    const [searchTerm, setSearchTerm] = useState("")

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = formData.filter(item => {
        const formattedDate = item.createdAt ? utcToNormalTime(item.createdAt).date : '';
        const isIPMatch = item.pair && item.pair.toLowerCase().includes(searchTerm.toLowerCase());
        const isReasonMatch = item.fromCurrency && item.fromCurrency.toLowerCase().includes(searchTerm.toLowerCase());
        const isToCurrecy = item.toCurrency && item.toCurrency.toLowerCase().includes(searchTerm.toLowerCase());
        const isLastPrice = item.lastPrice && item.lastPrice.toString().includes(searchTerm);



        return isIPMatch || isReasonMatch || isToCurrecy || isLastPrice;
    });

    const displayedData = filteredData.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );
    const handleDownload = async () => {
        try {
            const response = await postData(Apiservices.ADMIN_IP_BLOCK_SITE);
            if (response && response.data) {
                const ws = utils.json_to_sheet(response.data.map(item => ({
                    'ID': item._id,
                    'IP Address': item.ipAddress,
                    'Date': item.date,
                    'Price': item.price,
                    'Quantity': item.quantity,
                    'Amount': item.quantity * item.price,
                    'Status': item.status,
                })));

                // Create a new workbook and add the worksheet
                const wb = utils.book_new();
                utils.book_append_sheet(wb, ws, 'Deposite History');

                // Write the workbook to an Excel file
                writeFile(wb, 'ultrapro_user_deposit_history_data.xlsx');
            } else {
                console.error('No data received from API');
            }
        } catch (error) {
            console.error('Error downloading data:', error);
        }
    };
    const [modelCount, setmodalCount] = useState(false);

    const [resend, setResend] = useState({
        row: {},
        userStatus: null
    });

    const handleToggleModel = (row, action) => {
        
        setResend({ row: row, userStatus: action });
        setmodalCount(true);
    };


    const handleToggle = async () => {
        const updatedRow = {
            ...resend.row,
            [resend.userStatus]: resend.row[resend.userStatus] === 1 ? 0 : 1 // Toggle the status type
        };

        const postForm = {
            pair: resend.row.pair,
            isActive: updatedRow.isActive,
            // proUsers: updatedRow.proUsers,

            // liqudity: resend.row.liqudity,
            // tradeType: updatedRow.tradeType
        }
        setLoading(true)
        try {
            const response = await postData(Apiservices.USER_SWAP_EDIT_LIST, postForm)
            if (response.status) {
                toast.dismiss()
                setLoading(false)
                toast.success(response.message)
                tradePair()
                setmodalCount(false);

            } else {
                toast.error(response.message)
                setLoading(false)
                setmodalCount(false);
            }

        } catch (error) {
            toast.dismiss()
            toast.error("Internal Server Error..Try Again Later")
            setLoading(false)





        }






    }
    const handleDepositeSettings = (data) => {
        navigate(`/depositSettings/${data.name}`)
    }

    const handleUpdate = (record) => {
        setisEdit(true);
        validationAdd.setValues({
            pairName:record.pair,
            fromCurrency:record.fromCurrency,
            toCurrency:record.toCurrency,
            swapRate:record.swapRate,
            minTradeamount:record.minTradeamount,
            maxTradeamount:record.maxTradeamount,
            limitPart:record.limitPart,
            fee:record.fee,
            feeType:record.feeType,
            spendPrice:record.spendPrice,
            increaseOrdecreasepercent:record.increaseOrdecreasepercent.toString(),
            marketPrice:record.marketPrice,
            plusOrminus:record.plusOrminus,
            inrPrice:record.inrPrice,
            isActive: record.isActive !== undefined ? String(record.isActive) : '',
            priceDecimal:record.priceDecimal.toString(),
            amountDecimal:record.amountDecimal.toString(),
            tradeType: record.tradeType !== undefined ? String(record.tradeType) : '',
            proUsers: record.proUsers !== undefined ? String(record.proUsers) : ''
        });
        setRecordToUpdate(record);
        setmodal_update_list(true)
    }

    const allowedActions = [1,2,3,4];
    const columns = [
        {
            name: 'S.no',
            selector: (row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true
        },
        {
            name: 'Pair',
            selector: row => row.pair.replace(/_/g, '/'),
            sortable: true
        },
        // {
        //     name: 'From Currency',
        //     selector: row => row.fromCurrency,
        //     sortable: true
        // },
        // {
        //     name: 'To currency',
        //     selector: row => row.toCurrency,
        //     sortable: true
        // },

        {
            name: 'Old price',
            // selector: row => row.isActive ? 'Active' : 'Inactive',
            selector: row => row.lastPrice,


            sortable: true
        },

        {
            name: 'Inc or dec',
            // selector: row => row.isActive ? 'Active' : 'Inactive',
            selector: row => (row.plusOrminus == "minus") ? "-"+row.increaseOrdecreasepercent:""+row.increaseOrdecreasepercent+" %",


            sortable: true
        },

        {
            name: 'Current Price',
            // selector: row => row.isActive ? 'Active' : 'Inactive',
            selector: row => row.lastPrice,
            sortable: true
        },

         {
            name: 'Fees',
            // selector: row => row.isActive ? 'Active' : 'Inactive',
            selector: row => (row.feeType == "percent") ? row.fee+" %":row.fee +" "+row.toCurrency,


            sortable: true
        },

        
        {
            name: 'Min amount',
            // selector: row => row.isActive ? 'Active' : 'Inactive',
            selector: row => row.minTradeamount,


            sortable: true
        },
        {
            name: 'Max amount',
            // selector: row => row.isActive ? 'Active' : 'Inactive',
            selector: row => row.maxTradeamount,


            sortable: true
        },
       
    ...(adminType === 1 || adminInfo?.permissions.some(data => 
            data.module === "Swap pairs" && allowedActions.some(action => data.actions.includes(action))) ? [
        {
            name: 'Action',
            // selector: row => row.isActive ? 'Active' : 'Inactive',
            selector: row => (
                <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
                    {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Swap pairs" && data.actions.includes(3))) ? (
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizemd"
                        checked={row.isActive === 1}
                        onChange={(e) => handleToggleModel(row, 'isActive')}
                        // onChange={() => handleToggle(row, 'withdrawStatus')}
                        disabled={loading}
                    />
    ): null}

                </div>
            ),


            sortable: true
        }]:[]),
        ...(adminType === 1 || adminInfo?.permissions.some(data => 
            data.module === "Swap pairs" && allowedActions.some(action => data.actions.includes(action))) ? [
        {
            name: "Update",
            cell: (row) => (
                <div className="edit">
                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                    data.module === "Swap pairs" && data.actions.includes(3))) ? (
                    <i
                        style={{ cursor: "pointer" }}
                        className="mdi mdi-border-color cursor-pointer"
                      onClick={() => handleUpdate(row)}
                    ></i>
                ): null}
                </div>
            ),
            ignoreRowClick: true,
            // allowOverflow: true,
            // button: true,
        }]:[]),
    ];

    const handleSelect = (currency) => {
        setSelectedCurrency(currency);
        const newFilteredData = selectedData.filter(data => data.toCurrency === currency);
        setFormData(newFilteredData);
        setbtnauto3(false); // Close the dropdown after selection
    };


    const resetFormValues = () => {
        validationAdd.setValues({
            pairName: '',
            fromCurrency: '',
            toCurrency: '',
            streamPair: '',
            inrPrice: '',
            isActive: '',
            priceDecimal: '',
            amountDecimal: '',
            tradeType: '',
            proUsers: '',
        });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Swap Pair Management" breadcrumbItem="Swap Pair List" />
                    <Row>
                        <Col lg={12}>

                            <Card>


                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">

                                                <form className="app-search d-block">
                                                    <div className="position-relative">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search..."
                                                            onChange={handleInputChange}
                                                        />
                                                        <span className="ri-search-line"></span>
                                                    </div>
                                                </form>

                                            </Col>
                                            <Col className="col-sm">

                                                <div className="d-flex justify-content-sm-end">
                                                    <Dropdown
                                                        className='mx-4'
                                                        isOpen={btnauto3}
                                                        toggle={() => setbtnauto3(!btnauto3)}
                                                        id="dropdownMenuClickableInside"
                                                    >
                                                        

                                                        <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start">

                                                            {[...new Set(selectedData.map((data) => data.toCurrency))].map((currency, index) => (
                                                                <DropdownItem key={index} onClick={() => handleSelect(currency)}>{currency}</DropdownItem>
                                                            ))}




                                                        </DropdownMenu>

                                                    </Dropdown>
                                                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                                                        data.module === "Swap pairs" && data.actions.includes(1))) ? (
                                                    <Button color="success" className="add-btn" onClick={() => tog_update_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i>Add Pairs</Button>
                                                        ):null}
                                                    {/* <Button color="info" className="add-btn mx-2" onClick={handleDownload} id="create-btn"><i className="mdi mdi-download-outline"></i> Download</Button> */}

                                                    {/* <Button color="soft-danger"
                                                       
                                                    ><i style={{ fontSize: '15px' }} className="mdi mdi-download-circle-outline"></i>Download</Button> */}

                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <DataTable
                                                columns={columns}
                                                data={displayedData}
                                                paginationServer
                                                paginationTotalRows={filteredData.length}
                                                persistTableHead={true}
                                                progressPending={loading}
                                                progressComponent={<div className="py-3">Loading...</div>}
                                                // noDataComponent={<div>No records found</div>}
                                                fixedHeader

                                            />




                                        </div>
                                        {/* <AlternativePagination/> */}

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                {/* <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link> */}
                                                {displayedData.length > 0 ? (




                                                    <div className="pagination pagination-rounded mb-0">
                                                        <ReactPaginate
                                                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={pageCount}
                                                            marginPagesDisplayed={1}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={handlePageClick}
                                                            containerClassName={"pagination"}
                                                            activeClassName={"active"}
                                                            pageLinkClassName={"page-link"}
                                                            breakLinkClassName={"break-link"}
                                                            disabledClassName={"disabled"}
                                                            initialPage={currentPage}
                                                            disableInitialCallback={true}
                                                            forcePage={currentPage}
                                                            // disableInitialCallback={true}
                                                            renderLink={(props) => {
                                                                if (
                                                                    props.page === "..." &&
                                                                    props.pageCount > props.pageRangeDisplayed
                                                                ) {
                                                                    return (
                                                                        <a
                                                                            {...props}
                                                                            onClick={() =>
                                                                                handlePageClick({
                                                                                    selected: pageCount - 1,
                                                                                })
                                                                            }
                                                                        >
                                                                            {pageCount}
                                                                        </a>
                                                                    );
                                                                }
                                                                return <a {...props} />;
                                                            }}
                                                        />
                                                    </div>

                                                ) : ("")}

                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>





                </Container>
            </div>



     {/* Add Modal */}
            <Modal className='modal-dialog modal-lg' isOpen={modal_update_list} toggle={() => { tog_update_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}> Pair List </ModalHeader>
                <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validationAdd.handleSubmit();
                        return false
                    }}
                >
                    <ModalBody className='d-lg-flex justify-content-around'>

                        <div className="col-lg-5 col-sm-12">

                            <div className="mb-3">
                                <label htmlFor="fromCurrency-field" className="form-label">From Currency</label>
                                <Input
                                    type="text"
                                    id="fromCurrency-field"
                                    className="form-control"
                                    placeholder="Enter From Currency"
                                    name="fromCurrency"
                                    onChange={handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.fromCurrency || ""}
                                    invalid={validationAdd.touched.fromCurrency && validationAdd.errors.fromCurrency}
                                    readOnly={isEdit ? true : false}
                                />
                                {validationAdd.touched.fromCurrency && validationAdd.errors.fromCurrency && (
                                    <FormFeedback type="invalid">{validationAdd.errors.fromCurrency}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="toCurrency-field" className="form-label">To Currency</label>
                                <Input
                                    type="text"
                                    id="toCurrency-field"
                                    className="form-control"
                                    placeholder="Enter To Currency"
                                    name="toCurrency"
                                    onChange={handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.toCurrency || ""}
                                    invalid={validationAdd.touched.toCurrency && validationAdd.errors.toCurrency}
                                    readOnly={isEdit ? true : false}
                                />
                                {validationAdd.touched.toCurrency && validationAdd.errors.toCurrency && (
                                    <FormFeedback type="invalid">{validationAdd.errors.toCurrency}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="pairName-field" className="form-label">Pair Name</label>
                                <Input
                                    type="text"
                                    id="pairName-field"
                                    className="form-control"
                                    placeholder="Enter Pair Name"
                                    name="pairName"
                                    readOnly
                                    value={validationAdd.values.pairName || ""}
                                    invalid={validationAdd.touched.pairName && validationAdd.errors.pairName}
                                />
                                {validationAdd.touched.pairName && validationAdd.errors.pairName && (
                                    <FormFeedback type="invalid">{validationAdd.errors.pairName}</FormFeedback>
                                )}
                            </div>  


                            {validationAdd.values.marketPrice > 0 &&
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Market Price ({validationAdd.values.toCurrency})</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter marketPrice"
                                    name="marketPrice"
                                    readOnly={true}
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.marketPrice || ""}
                                    invalid={validationAdd.touched.marketPrice && validationAdd.errors.marketPrice}
                                />
                                {validationAdd.touched.marketPrice && validationAdd.errors.marketPrice && (
                                    <FormFeedback type="invalid">{validationAdd.errors.marketPrice}</FormFeedback>
                                )}
                            </div>
                            }   


                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Plus or Minus</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="plusOrminus"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.plusOrminus || ""}
                                    className={`form-control ${validationAdd.touched.plusOrminus && validationAdd.errors.plusOrminus ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select Plus or Minus</option>
                                    <option value="plus">Plus(+)</option>
                                    <option value="minus">Minus(-)</option>
                                </select>
                                {validationAdd.touched.plusOrminus && validationAdd.errors.plusOrminus && (
                                    <FormFeedback type="invalid">{validationAdd.errors.plusOrminus}</FormFeedback>
                                )}
                            </div>


                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Increase or Decrease Percent %</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Increase or Decrease Percent."
                                    name="increaseOrdecreasepercent"                                    
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.increaseOrdecreasepercent || ""}
                                    invalid={validationAdd.touched.increaseOrdecreasepercent && validationAdd.errors.increaseOrdecreasepercent}
                                />
                                {validationAdd.touched.increaseOrdecreasepercent && validationAdd.errors.increaseOrdecreasepercent && (
                                    <FormFeedback type="invalid">{validationAdd.errors.increaseOrdecreasepercent}</FormFeedback>
                                )}
                            </div>


                            {validationAdd.values.swapRate > 0 &&
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Swap Rate({validationAdd.values.toCurrency})</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter swapRate"
                                    name="swapRate"
                                    readOnly={true}
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.swapRate || ""}
                                    invalid={validationAdd.touched.swapRate && validationAdd.errors.swapRate}
                                />
                                {validationAdd.touched.swapRate && validationAdd.errors.swapRate && (
                                    <FormFeedback type="invalid">{validationAdd.errors.swapRate}</FormFeedback>
                                )}
                            </div>
                            }


                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Minimum tade amount ({validationAdd.values.fromCurrency})</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter minTradeamount"
                                    name="minTradeamount"
                                    onKeyDown={handleKeyDown}
                                    onChange={(e)=>handleChange1(e,validationAdd.values.amountDecimal)}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.minTradeamount || ""}
                                    invalid={validationAdd.touched.minTradeamount && validationAdd.errors.minTradeamount}
                                />
                                {validationAdd.touched.minTradeamount && validationAdd.errors.minTradeamount && (
                                    <FormFeedback type="invalid">{validationAdd.errors.minTradeamount}</FormFeedback>
                                )}
                            </div>


                             <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Maximum trade amount ({validationAdd.values.fromCurrency})</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter maxTradeamount"
                                    name="maxTradeamount"
                                    onKeyDown={handleKeyDown}
                                    onChange={(e)=>handleChange1(e,validationAdd.values.amountDecimal)}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.maxTradeamount || ""}
                                    invalid={validationAdd.touched.maxTradeamount && validationAdd.errors.maxTradeamount}
                                />
                                {validationAdd.touched.maxTradeamount && validationAdd.errors.maxTradeamount && (
                                    <FormFeedback type="invalid">{validationAdd.errors.maxTradeamount}</FormFeedback>
                                )}
                            </div>

                            </div>

                        <div className="col-lg-5 col-sm-12">


                        <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Fee type</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="feeType"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.feeType || ""}
                                    className={`form-control ${validationAdd.touched.feeType && validationAdd.errors.feeType ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select Fee type</option>
                                    <option value="percent">Percentage</option>
                                    <option value="flat">Flat</option>
                                </select>
                                {validationAdd.touched.feeType && validationAdd.errors.feeType && (
                                    <FormFeedback type="invalid">{validationAdd.errors.feeType}</FormFeedback>
                                )}
                            </div>


                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Fee</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Fee"
                                    name="fee"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.fee || ""}
                                    invalid={validationAdd.touched.fee && validationAdd.errors.fee}
                                />
                                {validationAdd.touched.fee && validationAdd.errors.fee && (
                                    <FormFeedback type="invalid">{validationAdd.errors.fee}</FormFeedback>
                                )}
                            </div>
                           

                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Amount Decimal({validationAdd.values.fromCurrency})</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Amount Decimal"
                                    name="amountDecimal"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.amountDecimal || ""}
                                    invalid={validationAdd.touched.amountDecimal && validationAdd.errors.amountDecimal}
                                />
                                {validationAdd.touched.amountDecimal && validationAdd.errors.amountDecimal && (
                                    <FormFeedback type="invalid">{validationAdd.errors.amountDecimal}</FormFeedback>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Price Decimal ({validationAdd.values.toCurrency})</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Price Decimal"
                                    name="priceDecimal"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.priceDecimal || ""}
                                    invalid={validationAdd.touched.priceDecimal && validationAdd.errors.priceDecimal}
                                />
                                {validationAdd.touched.priceDecimal && validationAdd.errors.priceDecimal && (
                                    <FormFeedback type="invalid">{validationAdd.errors.priceDecimal}</FormFeedback>
                                )}
                            </div>


                            <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">INR Price</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter INR Price (optional)"
                                    name="inrPrice"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.inrPrice || ""}
                                    invalid={validationAdd.touched.inrPrice && validationAdd.errors.inrPrice}
                                />
                                {validationAdd.touched.inrPrice && validationAdd.errors.inrPrice && (
                                    <FormFeedback type="invalid">{validationAdd.errors.inrPrice}</FormFeedback>
                                )}
                            </div>

                            

                            

                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Limit Status</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="limitPart"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.limitPart || ""}
                                    className={`form-control ${validationAdd.touched.limitPart && validationAdd.errors.limitPart ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select Status</option>
                                    <option value="limited">Limited</option>
                                    <option value="unlimited">Un-limited</option>
                                </select>
                                {validationAdd.touched.limitPart && validationAdd.errors.limitPart && (
                                    <FormFeedback type="invalid">{validationAdd.errors.limitPart}</FormFeedback>
                                )}
                            </div>


                             <div className="mb-3">
                                <label htmlFor="customername-field" className="form-label">Reserve Amount ({validationAdd.values.toCurrency})</label>
                                <Input
                                    type="text"
                                    id="customername-field"
                                    className="form-control"
                                    placeholder="Enter Spend Price"
                                    name="spendPrice"                                    
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.spendPrice || ""}
                                    invalid={validationAdd.touched.spendPrice && validationAdd.errors.spendPrice}
                                />
                                {validationAdd.touched.spendPrice && validationAdd.errors.spendPrice && (
                                    <FormFeedback type="invalid">{validationAdd.errors.spendPrice}</FormFeedback>
                                )}
                            </div>                       


                            <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Status</label>
                                <select
                                    id="status-field"
                                    //   className="form-control"
                                    name="isActive"
                                    onChange={validationAdd.handleChange}
                                    onBlur={validationAdd.handleBlur}
                                    value={validationAdd.values.isActive || ""}
                                    className={`form-control ${validationAdd.touched.isActive && validationAdd.errors.isActive ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="0">Deactive</option>
                                </select>
                                {validationAdd.touched.isActive && validationAdd.errors.isActive && (
                                    <FormFeedback type="invalid">{validationAdd.errors.isActive}</FormFeedback>
                                )}
                            </div>
                            
                            


                        </div>






                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update_list(false)}>Close</button>
                            {/* <button  type="submit" className="btn btn-success" id="add-btn">Add Customer</button> */}
                            <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <Loader />

                                ) : ("Submit")}

                            </button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>


       
      

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={tog_delete} className="modal zoomIn" id="deleteRecordModal" centered>
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-3 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            // onClick={confirmUpdate}
                            disabled={deleteLoad}
                        >
                            {deleteLoad ? (
                                <Loader />
                            ) : ("Yes, Delete It!")}

                        </button>
                    </div>
                </ModalBody>
            </Modal>


            <Modal isOpen={modelCount} className="modal zoomIn" id="deleteRecordModal" centered>
                <div className="modal-header">
                    <h4>Update Status</h4>
                    <Button type="button" onClick={() => setmodalCount(false)} className="btn-close" aria-label="Close"> </Button>
                </div>

                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-3 mb-0">Are you Sure You want to Update The {resend.row.pair} Status</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodalCount(false)}>Close</button>
                        <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                            disabled={loading}
                            onClick={handleToggle}
                        >
                            {loading ? (
                                <Loader />
                            ) : ("Yes, Change It!")}

                        </button>
                    </div>
                </ModalBody>

            </Modal>

        </React.Fragment>
    );
};

export default TradeManagement;

import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody,  Nav, NavItem, NavLink, TabContent,TabPane} from "reactstrap";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import AdminNotes from "./AdminNotes";


const AdminDetails = () => {
  const { id } = useParams();
  const [getReason, setGetReason] = useState([]);
  const [activeTab, setactiveTab] = useState("8");
  document.title = "Ultrapro | Admin Details";

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const data = {
        adminUserId: id,
      };
      const response = await postData(Apiservices.AdminUserById, data);
      if (response.status) {
        setGetReason(response.data)
      } else {
      }
    } catch (error) {
      toast.error("Internal Server Error...");
    }
  };


  const toggle = async (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
    
    if (tab === "8") {
      getUserDetails();
    }
   
  };

  return (
    <React.Fragment>
  
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Admin List"
            breadcrumbItem="Admin Details"
            childdren="Admin Management"
            titleRoute="/adminUsers"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab === "8", })} 
                       onClick={() => { toggle("8"); }} >
                        <i className="mdi mdi-account-multiple"></i> Admin Notes
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="8" id="settings">
                      <Row>
                        <Col sm="12" className="d-lg-flex">
                          <Card className="col-lg-12">
                            <AdminNotes reasonData={getReason} />
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminDetails;

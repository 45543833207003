import React, { useState, useEffect,useContext } from "react";
import {
  Button,
  Input,
  Card,
  CardBody,
  FormFeedback,
  FormGroup,
  Label,
  Col,
  Container,
  Modal,
  Row,
  ModalHeader,
  Form,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import {
  handleNullValue,
  utcToNormalTime,
  calculateDays,
} from "../../helpers/function";
import Swal from 'sweetalert2';
import { AdminContext } from "../../helpers/adminContext";

const StakingOffers = () => {
  document.title = "Ultrapro | Staking Offers";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [getId, setId] = useState("");
  const [content, setContent] = useState("");

  //Pagination;
  const [totalPagination, setTotalPagination] = useState(1);
 const [totalLimit, setTotalLimit] = useState(10);
 const [currentPage, setCurrentPage] = useState(0);
 const itemsPerPage = totalLimit; // Number of items to display per page
 const pageCount = Math.ceil(totalPagination);

 const [rowsPerPage, setRowsPerPage] = useState(10);
 const [count, setCount] = useState("");
 const [record,setRecord] = useState([]);
 
 
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
  }


  //frontend paginnation;
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  const offset = currentPage * rowsPerPage;
  const currentData = templateList.slice(offset, offset + rowsPerPage);

  // Permission List 
  const { adminInfo } = useContext(AdminContext);
const [adminType,setAdminType] = useState('')
  //LIST API CALL
  useEffect(() => {
    fetchData();
    setAdminType(adminInfo?.adminType)
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.StakingOffersList);
      if (response) {
        console.log(response.data.data,"RESPONSE")
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
        setRecord(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const decodeHTMLEntities = (text) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  };
  const allowedActions = [1,2,3,4];

  const columns = [
    {
        name: "S.No",
        selector: (row) => handleNullValue(row?.serialNo),
        sortable: true,
        minWidth: '100px', 
    },
    {
        name: "Stake Name",
        selector: (row) => handleNullValue(row?.stakeName),
        sortable: true,
        minWidth: '200px', 
    },
    {
        name: "Duration",
        selector: (row) => handleNullValue(row?.duration) ? `${row.duration} Days` : '-',
        sortable: true,
        minWidth: '150px', 
    },
    {
        name: "Staked Amount",
        selector: (row) => handleNullValue(row?.minstake),
        sortable: true,
        minWidth: '150px', 
    },
    {
      name: "Currency Name",
      selector: (row) => handleNullValue(row?.currencyName),
      sortable: true,
      minWidth: '150px', 
  },
  {
    name: "Position",
    selector: (row) => handleNullValue(row?.position),
    sortable: true,
    minWidth: '150px', 
  },
    {
        name: "Date",
        selector: (row) =>
            row.createdAt
                ? `${utcToNormalTime(row?.createdAt).date} ${utcToNormalTime(row?.createdAt).time}`
                : "---",
        sortable: true,
        minWidth: '200px', 
    },
    {
        name: "Status",
        selector: (row) => (
            <div className="form-check form-switch form-switch-md mb-3 d-flex justify-content-center align-items-center">
                {(adminType === 1 || adminInfo?.permissions.some(data => 
                    data.module === "Staking offers" && data.actions.includes(3))) ? (
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizemd"
                        checked={row.isActive === 1}
                        onChange={() => handleUpdateStatusClick(row)}
                        disabled={loading}
                    />
                ) : null}
            </div>
        ),
        sortable: true,
        minWidth: '150px', 
    },
    ...(adminType === 1 || adminInfo?.permissions.some(data => 
        data.module === "Staking Offers" && allowedActions.some(action => data.actions.includes(action))) ? [
        {
            name: "Action",
            cell: (row) => (
                <div className="edit">
                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                        data.module === "Staking Offers" && data.actions.includes(3))) ? (
                        <i
                            style={{ cursor: "pointer" }}
                            className="mdi mdi-border-color"
                            onClick={() => handleNavSub(row)}
                        ></i>
                    ) : null}
                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                        data.module === "Staking Offers" && data.actions.includes(4))) ? (
                        <i
                            style={{ cursor: "pointer", marginLeft: "15px" }}
                            className="mdi mdi-trash-can-outline"
                            onClick={() => handleDeleteClick(row)}
                        ></i>
                    ) : null}
                </div>
            ),
            ignoreRowClick: true,
            minWidth: '150px', 
        }
    ] : []),
];



 

  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  //Addapicall

  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,

    initialValues: {
      stakename: "",
      duration: "",
      durationcount: "",
      minstakeamount: "",
      maxstakeamount: "",
      status: "",
      description:"",
      currencyname:"",
      stakeBonus:"",
      referalBonus:""
    },
    validationSchema: Yup.object({
      stakename: Yup.string().trim().required("Stake Name is Required"),
      duration: Yup.string().required("Duration is Required"),
      // durationcount: Yup.string().required("Duration count is Required"),
      minstakeamount: Yup.number()
        .required("Minimum Stake Amount is Required")
        .typeError("Minimum Stake Amount must be a number")
        .lessThan(
          Yup.ref("maxstakeamount"),
          "Minimum Stake Amount must be less than Maximum Stake Amount"
        ),
      // maxstakeamount: Yup.number()
      //   .required("Maximum Stake Amount is Required")
      //   .typeError("Maximum Stake Amount must be a number")
      //   .moreThan(
      //     Yup.ref("minstakeamount"),
      //     "Maximum Stake Amount must be greater than Minimum Stake Amount"
      //   ),
      status: Yup.string().required("Status is Required"),
      description: Yup.string().trim().required("Description is Required"),
      currencyname: Yup.string().required("Currency Name is Required"),
      stakeBonus: Yup.number()
      .required("Stake Bonus is Required")
      .typeError("Stake Bonus must be a number")
      .min(0, "Stake Bonus must be a non-negative number"),
    referalBonus: Yup.number()
      .required("Referral Bonus is Required")
      .typeError("Referral Bonus must be a number")
      .min(0, "Referral Bonus must be a non-negative number"),
      stakeType: Yup.string().required("Stake Type is Required"),
      cancelfeesamount: Yup.number()
      .typeError("Cancel Fees Amount must be a number")
      .min(0, "Cancel Fees Amount must be a non-negative number")
    
    }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });
  //updateapicall
  const handleNavSub = async (record) => {
    navigate(`/stakingoffersupdate/${record.stakeOff_Id}`);

  };
  const handleUpdate = (record) => {
    setId(record.stakeOff_Id);
    setContent(record.content);
    validationUpdate.setValues({
      stakename: record.stakeName || "",
      duration: record.duration || "",
      minstakeamount: record.minstake || "",
      maxstakeamount: record.maxstake || "",
      description: record.description || "",
      status: record.isActive !== undefined ? String(record.isActive) : "",
    });

    setmodal_update_list(true);
  };
  const confirmUpdate = async (values) => {
    if (content !== "") {
      const postForm = {
        stakeOff_Id: getId,
        stakeName: values.stakename,
        // duration:  values.duration,
        duration: values.durationcount,
        minstake: values.minstakeamount,
        maxstake: values.maxstakeamount,
        isActive: values.status,
        // content: content,
        content:values.description,
         currencyName:values.currencyName,
        coinId:values.coinId,
        stakeBonus:values.stakeBonus / 100,
        referalBonus:values.referalBonus / 100,
        stakeType: values.stakeType,
        // cancelFeesAmount: values.cancelFeesAmount,
        // coinId:coinData
      };
      try {
        setLoading(true);
        const response = await postData(
          `${Apiservices.StakingOffersUpdate}`,
          postForm
        );

        if (response.status === true) {
          setmodal_update_list(false);
          toast.success(response.message);
          fetchData();
          setLoading(false);
          setmodal_update_list(false);
        } else {
          toast.error(response.message);
          fetchData();
          setLoading(false);
        }
      } catch (error) {
        // setDeleteLoad(false)
        toast.error("Internal Server Error..Try Again Later");
        // setmodal_delete(false)
        validationUpdate.resetForm();
      }
    } else {
      toast.error("Content is Required");
    }
  };

  //add offer navigate page
  const handleAddOffer = () => {
    navigate("/stakingoffers");
  };
  //calculation for weeks to days

  const handleDurationChange = (event) => {
    const selectedDuration = event.target.value;
    const weeks = calculateDays(selectedDuration);
    validationUpdate.setFieldValue("duration", selectedDuration);
    validationUpdate.setFieldValue("durationcount", weeks);
  };
 
 
   const showDeleteAlert = (onConfirm,row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm(row);
        // Swal.fire(
        //   'Deleted !',
        //   'The requested record has been deleted',
        //   'success'
        // );
      }
    });
  };


   //delete api call
   const handleDeleteClick = (row) => {
    showDeleteAlert(handleDelete1,row);
  };

   const handleDelete1 = async (rowDetails) => {
    const postForm = {
      stakeOff_Id: rowDetails.stakeOff_Id,
    };
    setLoading(true);
    try {
      const response = await postData(
        Apiservices.StakingOffersDelete,
        postForm
      );
      if (response.status) {
        setLoading(false);
        toast.success(response.message);
        Swal.fire(
          'Deleted !',
          'The requested record has been deleted',
          'success'
        );
        fetchData();
       
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Internal Server Error..Try Again Later");
    }
  };


//status update api call
  const showStatusAlert = (onConfirm, row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to update status!",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!'
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm(row);
        // Swal.fire('Success !', 'The status has been updated successfully', 'success');

      }
    });
  };
  const handleUpdateStatusClick = (row) => {
    showStatusAlert(handleToggle, row);
  };
  const handleToggle = async (rowDetails) => {
    const updatedStatus = rowDetails.isActive === 1 ? 0 : 1;
    const postForm = {
      stakeOff_Id: rowDetails.stakeOff_Id,
      stakeName: rowDetails.stakeName,
      // duration:  rowDetails.duration,
      duration: rowDetails.duration,
      minstake: rowDetails.minstake,
      maxstake: rowDetails.maxstake,
      isActive: updatedStatus,
      // content: content,
      content:rowDetails.content,
      currencyname:rowDetails.currencyname,
      coinId:rowDetails.coinId,
      stakeBonus:rowDetails.stakeBonus,
      referalBonus:rowDetails.referalBonus,
      stakeType: rowDetails.stakeType,
      cancelFeesAmount: rowDetails.cancelFeesAmount
      // coinId:coinData
    };
    setLoading(true);
    try {
      const response = await postData(
        `${Apiservices.StakingOffersUpdate}`,
        postForm
      );
      if (response.status) {
        toast.dismiss();
        setLoading(false);
        toast.success(response.message);
        Swal.fire('Success !', 'The status has been updated successfully', 'success');

        fetchData();
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false)
    }
  };

  const handleStakeTypeChange = (event) => {
    const selectedStakeType = event.target.value;
    
    // Set stakeType field value
    validationUpdate.setFieldValue("stakeType", selectedStakeType);
  
  
    if (selectedStakeType === "lock") {
      validationUpdate.setFieldValue("cancelfeesamount", ""); 
    }
  
    validationUpdate.setFieldTouched("cancelfeesamount", true);
  };
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected );
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage));
    HandleClickRowperpage(newRowsPerPage)
  }
  const handlePagePost = (selected)=>{
      const postForm = {

      }
  }
  const HandleClickRowperpage = async (newRowsPerPage) =>{
    setLoading(true);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Staking Offers" breadcrumbItem="Staking Offers List" />
          <Row>
            <Col lg={12} className="row">
              <Card className="col-lg-12">
                <CardBody>
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      {" "}
                      <h4 className="card-title">Staking Offers</h4>
                    </Col>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                      {(adminType === 1 || adminInfo?.permissions.some(data => 
                        data.module === "Staking Offers" && data.actions.includes(1))) ? (
                        <Button
                          color="success"
                          className="add-btn"
                          onClick={handleAddOffer}
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Stake Offer{" "}
                        </Button> ) :null}
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive overflow-hidden">
                    <DataTable
                      columns={columns}
                      data={record}
                      paginationServer
                      paginationTotalRows={currentData.length}
                      progressPending={loading}
                      progressComponent={<div>Loading...</div>}
                      persistTableHead={true}
                      noDataComponent={
                        <div className="mt-2">No records found</div>
                      }
                      fixedHeader={true}
                    />
                      <div className="container">
  <div className="row justify-content-end mt-4">
  <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">

      <div className="d-flex align-items-center me-3">
        <span className="me-2">Rows per page:</span>
        <select
          value={rowsPerPage}
          onChange={(e) => handleRowsPerPageChange(e.target.value)}
          className="form-control-sm"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>
      <div className="me-md-3 mb-3 mb-md-0">
        <span>{count}</span>
      </div>
      <div className="pagination pagination-rounded mt-3">
        <ReactPaginate
          previousLabel={<i className="mdi mdi-chevron-left"></i>}
          nextLabel={<i className="mdi mdi-chevron-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageLinkClassName={"page-link"}
          breakLinkClassName={"break-link"}
          disabledClassName={"disabled"}
          initialPage={currentPage}
          disableInitialCallback={true}
          forcePage={currentPage}
          renderLink={(props) => {
            if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
              return (
                <a
                  {...props}
                  onClick={() =>
                    handlePageClick({
                      selected: pageCount - 1,
                    })
                  }
                >
                  {pageCount}
                </a>
              );
            }
            return <a {...props} />;
          }}
        />
      </div>
    </div>
  </div>
</div>
                    {/* // ADDMODal  */}
                    {/* <Modal
                  className="modal-dialog modal-lg" isOpen={modal_list} toggle={() => { tog_list();}} centered >
                  <ModalHeader
                    className="bg-light p-3"
                    id="exampleModalLabel"
                    toggle={() => {tog_list();}}>{" "} Add Email Template{" "}
                  </ModalHeader>
                  <Form
                    autoComplete="off"
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                      return false;
                    }} >
                  </Form>
                </Modal> */}

                    {/* UpdateModal */}
                    <Modal isOpen={modal_update_list} className="modal-lg" toggle={() => {   tog_update_list(); }} centered>
                      <ModalHeader className="bg-light p-3"  id="exampleModalLabel" toggle={() => { tog_update_list();  }} >
                        Update Staking Offers
                      </ModalHeader>
                      <Form onSubmit={validationUpdate.handleSubmit}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="sitename">Stake Name</Label>
                                  <Input
                                    type="text"
                                    name="stakename"
                                    id="stakename"
                                    className="form-control"
                                    value={validationUpdate.values.stakename}
                                    onChange={validationUpdate.handleChange}
                                    onBlur={validationUpdate.handleBlur}
                                    autoComplete="off"
                                    placeholder="Enter Stake Name"
                                    invalid={ validationUpdate.touched.stakename &&!!validationUpdate.errors.stakename}
                                  />
                                  <FormFeedback> {validationUpdate.errors.stakename} </FormFeedback>
                                </FormGroup>

                                {/* <FormGroup>
                                  <Label for="durationcount">Duration (in days) </Label>
                                  <Input
                                    type="text"
                                    name="durationcount"
                                    id="durationcount"
                                    className="form-control"
                                    value={`${ validationUpdate.values.durationcount  ? `${validationUpdate.values.durationcount} days`   : ""  }`}
                                    onChange={validationUpdate.handleChange}
                                    onBlur={validationUpdate.handleBlur}
                                    autoComplete="off"
                                    placeholder="Duration in weeks"
                                    invalid={validationUpdate.touched.durationcount && !!validationUpdate.errors.durationcount}
                                  />
                                  <FormFeedback>{validationUpdate.errors.durationcount}</FormFeedback>
                                </FormGroup> */}

                                <FormGroup>
                                  <Label for="minstakeamount">Minimum Stake Amount </Label>
                                  <Input
                                    type="text"
                                    name="minstakeamount"
                                    id="minstakeamount"
                                    className="form-control"
                                    placeholder="Enter Minimum Stake Amount"
                                    value={
                                      validationUpdate.values.minstakeamount
                                    }
                                    onChange={validationUpdate.handleChange}
                                    onBlur={validationUpdate.handleBlur}
                                    autoComplete="off"
                                    invalid={
                                      validationUpdate.touched.minstakeamount &&
                                      !!validationUpdate.errors.minstakeamount
                                    }
                                  />
                                  <FormFeedback> {validationUpdate.errors.minstakeamount} </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                  <Label for="duration">Duration</Label>
                                  <select
                                    id="duration"
                                    name="duration"
                                    onChange={handleDurationChange}
                                    onBlur={validationUpdate.handleBlur}
                                    value={ validationUpdate.values.duration || "" }
                                    className={`form-control ${ validationUpdate.touched.duration &&
                                      validationUpdate.errors.duration ? "is-invalid" : "" }`}
                                  >
                                    <option value="" disabled>
                                      Select Duration
                                    </option>
                                    <option value="30 days">1 month</option>
                                    <option value="60 days">3 months</option>
                                    <option value="180 days">6 months</option>
                                    <option value="365 days">1 year</option>
                                    <option value="540 days">18 months</option>
                                  </select>
                                  {validationUpdate.touched.duration &&
                                    validationUpdate.errors.duration && (
                                      <FormFeedback type="invalid">
                                        {validationUpdate.errors.duration}
                                      </FormFeedback>
                                    )}
                                </FormGroup>
                                {/* <FormGroup>
                          <Label for="stakeType">Stake Type</Label>
                          <select
                            id="stakeType"
                            name="stakeType"
                            onChange={handleStakeTypeChange}
                            onBlur={validationUpdate.handleBlur}
                            value={validationUpdate.values.stakeType || ""}
                            className={`form-control ${validationUpdate.touched.stakeType && validationUpdate.errors.stakeType ? "is-invalid" : ""}`}
                          >
                            <option value="" disabled>
                              Select Stake Type
                            </option>
                            <option value="flexiable">Flexiable</option>
                            <option value="lock">lock</option>
                          </select>
                          {validationUpdate.touched.stakeType && validationUpdate.errors.stakeType && <FormFeedback type="invalid">{validationUpdate.errors.stakeType}</FormFeedback>}
                        </FormGroup> */}
{/* 
                                <FormGroup>
                          <Label for="minstakeamount">Stake Bonus Percentage</Label>
                          <Input
                            type="text"
                            name="stakeBonus"
                            id="stakeBonus"
                            className="form-control"
                            placeholder="Enter Stake Bonus Percentage"
                            value={validationUpdate.values.stakeBonus }
                            onChange={validationUpdate.handleChange}
                            onBlur={validationUpdate.handleBlur}
                            autoComplete="off"                    
                            invalid={validationUpdate.touched.stakeBonus && !!validationUpdate.errors.stakeBonus}
                          />
                          <FormFeedback>{validationUpdate.errors.stakeBonus}</FormFeedback>
                        </FormGroup> */}
                              </Col>
                              <Col md={6}>
                                {/* <FormGroup>
                            <label htmlFor="status-field" className="form-label">
                              Currency Name
                            </label>
                            <select
                              id="status-field"
                              name="currencyname"
                              onChange={validationUpdate.handleChange}
                              onBlur={validationUpdate.handleBlur}
                              value={validationUpdate.values.currencyname || ""}
                              className={`form-control ${validationUpdate.touched.currencyname && validationUpdate.errors.currencyname ? "is-invalid" : ""}`}
                            >
                              <option value="" selected disabled>
                                Select Currency
                              </option>
                              <option value={'INR'}>INR</option>
                              <option value={'USDT'}>USDT</option>
                            </select>
                            {validationUpdate.touched.currencyname && validationUpdate.errors.currencyname && <FormFeedback type="invalid">{validationUpdate.errors.currencyname}</FormFeedback>}
                          </FormGroup> */}

                                    {/* <FormGroup>
                                  <Label for="currencyname">Currency Name</Label>
                                  <Input
                                    type="text"
                                    name="currencyname"
                                    id="currencyname"
                                    className="form-control"
                                    value={
                                      validationUpdate.values.currencyname
                                    }
                                    onChange={validationUpdate.handleChange}
                                    autoComplete="off"
                                    onBlur={validationUpdate.handleBlur}
                                    // placeholder="Enter Maximum Stake Amount"
                                    invalid={
                                      validationUpdate.touched.currencyname &&
                                      !!validationUpdate.errors.currencyname
                                    }
                                    readOnly
                                  />
                                  <FormFeedback>
                                    {validationUpdate.errors.currencyname}
                                  </FormFeedback>
                                </FormGroup> */}
                                     <FormGroup>
                                  <label
                                    htmlFor="status-field"
                                    className="form-label"
                                  >
                                    Status
                                  </label>
                                  <select
                                    id="status-field"
                                    name="status"
                                    onChange={validationUpdate.handleChange}
                                    onBlur={validationUpdate.handleBlur}
                                    value={validationUpdate.values.status || ""}
                                    className={`form-control ${
                                      validationUpdate.touched.status &&
                                      validationUpdate.errors.status
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  >
                                    <option value="" selected disabled>
                                      Select Staus
                                    </option>
                                    <option value={1}>Active</option>
                                    <option value={0}>Deactive</option>
                                  </select>
                                  {validationUpdate.touched.status &&
                                    validationUpdate.errors.status && (
                                      <FormFeedback type="invalid">
                                        {validationUpdate.errors.status}
                                      </FormFeedback>
                                    )}
                                </FormGroup>

                          <FormGroup>
                                  <Label for="maxstakeamount"> Maximum Stake Amount </Label>
                                  <Input
                                    type="text"
                                    name="maxstakeamount"
                                    id="maxstakeamount"
                                    className="form-control"
                                    value={
                                      validationUpdate.values.maxstakeamount
                                    }
                                    onChange={validationUpdate.handleChange}
                                    autoComplete="off"
                                    onBlur={validationUpdate.handleBlur}
                                    placeholder="Enter Maximum Stake Amount"
                                    invalid={
                                      validationUpdate.touched.maxstakeamount &&
                                      !!validationUpdate.errors.maxstakeamount
                                    }
                                  />
                                  <FormFeedback>
                                    {validationUpdate.errors.maxstakeamount}
                                  </FormFeedback>
                                </FormGroup>
                               

                              
                                {/* <FormGroup>
                          <Label for="referalBonus">Referral Bonus  Percentage</Label>
                          <Input
                            type="text"
                            name="referalBonus"
                            id="referalBonus "
                            className="form-control"
                            placeholder="Enter Referral Bonus  Percentage"
                            value={validationUpdate.values.referalBonus}
                            onChange={validationUpdate.handleChange}
                            onBlur={validationUpdate.handleBlur}
                            
                            autoComplete="off"
                            invalid={validationUpdate.touched.referalBonus  && !!validationUpdate.errors.referalBonus }
                          />
                          <FormFeedback>{validationUpdate.errors.referalBonus }</FormFeedback>


                          </FormGroup> */}
                          {/* <FormGroup>
                          <Label for="maxstakeamount">Cancel Fees Amount</Label>
                          <Input
                            type="text"
                            name="cancelfeesamount"
                            id="cancelfeesamount"
                            className="form-control"
                            value={validationUpdate.values.cancelfeesamount}
                            onChange={validationUpdate.handleChange}
                            autoComplete="off"
                            onBlur={validationUpdate.handleBlur}
                            disabled={validationUpdate.values.stakeType === "lock"}
                            placeholder="Enter Cancel Fees Amount"
                            invalid={validationUpdate.touched.cancelfeesamount && !!validationUpdate.errors.cancelfeesamount}
                          />
                          <FormFeedback>{validationUpdate.errors.cancelfeesamount}</FormFeedback>
                        </FormGroup> */}
                      
                              </Col>
                              {/* <div className="mb-3">
                                <label
                                  htmlFor="title-field"
                                  className="form-label"
                                >
                                  Content
                                </label>

                                <Editor
                                  value={decodeHTMLEntities(content)}
                                  apiKey="8tmldbekaot614sl1i1s5lejnuj9hkqmsncgjyxs3p4rgave" // Optional, get one from TinyMCE if you're using their cloud version
                                  init={{
                                    height: 500,
                                    menubar: false,
                                    plugins: "link image code",
                                    toolbar:
                                      "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code",
                                    setup: (editor) => {
                                      editor.on("change", () => {
                                      });
                                    },
                                  }}
                                  onEditorChange={handleEditorChange}
                                />
                              </div> */}
                                <FormGroup>
                                  <Label for="description"> Description </Label>
                                  <Input
                                    type="textarea"
                                    row="5"
                                    name="description"
                                    id="description"
                                    className="form-control"
                                    value={validationUpdate.values.description}
                                    onChange={validationUpdate.handleChange}
                                    autoComplete="off"
                                    onBlur={validationUpdate.handleBlur}
                                    placeholder="Enter Description"
                                    invalid={
                                      validationUpdate.touched.description &&
                                      !!validationUpdate.errors.description
                                    }
                                  />
                                  <FormFeedback>
                                    {validationUpdate.errors.description}
                                  </FormFeedback>
                                </FormGroup>
                            </Row>

                            <div className="text-end">
                              {loading ? (
                                <Button color="primary">Loading...</Button>
                              ) : (
                                <Button color="primary" type="submit">
                                  Submit
                                </Button>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </Form>
                    </Modal>
                  </div>
                </CardBody>
              </Card>
            
             
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default StakingOffers;

import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback, Col, Container,  Row, 
   Form } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import toast from "react-hot-toast";
import { AdminContext } from "../../helpers/adminContext";
import { postData } from "../../ApiService/AxiosInstance";


const PushNotification = () => {
  const [loading, setLoading] = useState(false);
  document.title = "Ultrapro | Push Notification List";
   //Permission List 
   const {adminInfo} = useContext(AdminContext);
   const [adminType,setAdminType] = useState('');

useEffect(()=>{
setAdminType(adminInfo?.adminType)
},[])


  //Add form validation
  const formik = useFormik({
    initialValues: {
      title: "",
      message:'',
    },
    validationSchema: Yup.object({
      title: Yup.string().trim().required("Title is required"),
      message: Yup.string().trim().required("Message is required"),

      }),
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  
 
  const [range, setRange] = useState({ start: 1, end: 500 });
  const maxEnd = 1000000;
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [buttonClickCount, setButtonClickCount] = useState(0); // Track button clicks
  
  const handleAdd = async (data) => {
    if (isSubmitting) return;
  // const count= buttonClickCount + 1
 

    setIsSubmitting(true);
    setLoading(true);
  
    const postForm = {
      start: range.start,
      end: range.end,
    };
    const postForm1 = {
      title: data.title,
      body: data.message,
    };
  
    try {
      const queryString = new URLSearchParams(postForm).toString();
      const urlWithQuery = `${Apiservices.PUSH_NOTIFICATION_ADD}?${queryString}`;
      const response = await postData(urlWithQuery, postForm1);
  
      if (response.status) {
        console.log(`Request successful for range ${range.start}-${range.end}`, response);
        toast.success(response.message);
  
        // Update button click count
        // setButtonClickCount((prevCount) => prevCount + 1);
  
        // Update the range for the next API call
        const nextStart = range.end + 1;
        const nextEnd = Math.min(range.end + 500, maxEnd);
  
        if (nextStart <= maxEnd) {
          setRange({ start: nextStart, end: nextEnd });
          formik.resetForm();
        } else {
          formik.resetForm();
          setRange({ start: 1, end: 500 });
        }
      } 
      
      else {
        toast.error(response.message);
        if(response.message === 'There is no users in the collection.'){
          console.log(response.message,range.start,range.end,"5555555555555555555555")
          // Update the range for the next API call
          const nextStart = range.start + 500;
          const nextEnd = Math.min(range.end + 500, maxEnd);
          
          if (nextStart <= maxEnd) {
            setRange({ start: nextStart, end: nextEnd });
            formik.resetForm();
          } else {
            // Reset if maxEnd is reached
            setRange({ start: 1, end: 500 });
            formik.resetForm();
          }
        }
        else{
          toast.error(response.message);

        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      setIsSubmitting(false);
    }
  };
  







  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Push Notification" breadcrumbItem="Push Notification List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  
                  <Row className="mt-2">
                  <Form autoComplete="off" className="form-horizontal" 
                    onSubmit={(e) => { e.preventDefault();   formik.handleSubmit();  return false; }}>
                      <Col md={6}>
                      <div className="mb-3">
                          <label htmlFor="title-field" className="form-label">
                          Title
                          </label>

                          <Input
                            type="text"
                            id="name-field"
                            className="form-control"
                            autoComplete="off"
                            placeholder="Enter Title"
                            name="title"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title || ""}
                            invalid={formik.touched.title && formik.errors.title}
                          />
                          {formik.touched.title && formik.errors.title && <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>}
                        </div>
                        <div className="mb-3">
                        <label htmlFor="customername-field" className="form-label">
                        Message
                        </label>
                        <Input
                            type="textarea"
                            id="customername-field"
                            className="form-control"
                            placeholder="Enter Message"
                            name="message"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.message || ""}
                            invalid={formik.touched.message && formik.errors.message}
                          />
                          {formik.touched.message && formik.errors.message && <FormFeedback type="invalid">{formik.errors.message}</FormFeedback>}
                        </div>      

                        <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Push Notification" && data.actions.includes(1))) ? (
                        <Button
                          color="success"
                          className="add-btn"
                          id="create-btn"
                          type="text"
                          disabled={isSubmitting}
                          onClick={() => handleAdd(formik.values)}
                        >
                          Submit
                        </Button>
                      ): null }
                      </div>
                    </Col>                  
    </Col>
    </Form>
    
  </Row>
 
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PushNotification;



import React, { useState } from "react";
import { Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader } from "reactstrap";
import { Link } from 'react-router-dom';
// import { useTable, usePagination } from 'react-table';
import { writeFile, utils } from 'xlsx';
import ReactPaginate from "react-paginate";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { LatestTransationData } from "../DepositeHistory/data";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";

const Swap = () => {
    document.title = "Ultrapro | Swap Management";
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8; // Number of items to display per page
    const pageCount = Math.ceil(LatestTransationData.length / itemsPerPage);
  
    const handlePageClick = ({ selected }) => {
      setCurrentPage(selected);
    };

    // useEffect(()=>{
    //     ipList()
    // },[])
    const ipList = async () => {

        try {
            const response = await postData(Apiservices.ADMIN_IP_LIST)
            if (response.status) {


            } else {

            }

        } catch (error) {


        }





    }



    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }








    const [searchTerm, setSearchTerm] = useState("")



    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = LatestTransationData.filter(item => {
        const isNameMatch = item.clientName && item.clientName.toLowerCase().includes(searchTerm.toLowerCase());
        const isDateMatch = item.date && item.date.includes(searchTerm); // Assuming item.date is in MM/DD/YYYY format
        return isNameMatch || isDateMatch;
    });


    const displayedData = filteredData.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
      );

    const handleDownload = () => {
        // Convert the filteredData to a worksheet
        const ws = utils.json_to_sheet(LatestTransationData.map(item => ({
            'ID': item.clientId,
            'Name': item.clientName,
            'Date': item.date,
            'Price': item.price,
            'Quantity': item.quantity,
            'Amount': item.quantity * item.price,
            'Status': item.status,
        })));

        // Create a new workbook and add the worksheet
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Deposite History');

        // Write the workbook to an Excel file
        writeFile(wb, 'ultrapro_user_deposit_history_data.xlsx');
    };







    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Ultrapro" breadcrumbItem="Swap" />
                    <Row>
                        <Col lg={12}>
        
                            <Card>


                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">

                                                <form className="app-search d-block">
                                                    <div className="position-relative">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search..."
                                                            onChange={handleInputChange}
                                                        />
                                                        <span className="ri-search-line"></span>
                                                    </div>
                                                </form>

                                            </Col>
                                            <Col className="col-sm">

                                                <div className="d-flex justify-content-sm-end">

                                                    <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                                                    <Button color="soft-danger"
                                                        onClick={handleDownload}
                                                    ><i style={{ fontSize: '15px' }} className="mdi mdi-download-circle-outline"></i></Button>

                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            {displayedData.length > 0 ? (
                                                <table className="table align-middle table-nowrap" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>

                                                            <th className="sort" data-sort="date">Pair Image</th>
                                                            <th className="sort" data-sort="customer_name">Pair Name</th>
                                                            <th className="sort" data-sort="email">Pair Coin</th>
                                                            <th className="sort" data-sort="phone">Pair Coin 2</th>
                                                            <th className="sort" data-sort="action">Action</th>
                                                        </tr>
                                                    </thead>


                                                    <tbody className="list form-check-all">
                                                        {displayedData.map((data, index) => (
                                                            <tr key={data}>

                                                                <td className="ip_address" style={{ display: "none" }}><Link to="#" className="fw-medium link-primary">#VZ2101</Link></td>
                                                                <td className="customer_name">{data.clientName}</td>
                                                                <td className="email">BTC/INR</td>
                                                                <td className="phone">580-464-232</td>
                                                                <td className="date">{data.date}</td>

                                                                <td>
                                                                    <div className="d-flex gap-2">

                                                                        <div className="remove">

                                                                            <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal">Remove</button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div className="noresult">
                                                    <div className="text-center">
                                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                            colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                        </lord-icon>
                                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                                        <p className="text-muted mb-0">We've searched through {LatestTransationData.length} Fiat Crypto Deposite but couldn't find any matching your search.</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                {/* <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link> */}
                                                <Card>
                                                    <CardBody>



                                                        <div className="pagination pagination-rounded mb-0">
                                                            <ReactPaginate
                                                                previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                                nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageClick}
                                                                containerClassName={"pagination"}
                                                                activeClassName={"active"}
                                                                pageLinkClassName={"page-link"}
                                                                breakLinkClassName={"break-link"}
                                                                disabledClassName={"disabled"}
                                                                initialPage={currentPage}
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                                // disableInitialCallback={true}
                                                                renderLink={(props) => {
                                                                    if (
                                                                        props.page === "..." &&
                                                                        props.pageCount > props.pageRangeDisplayed
                                                                    ) {
                                                                        return (
                                                                            <a
                                                                                {...props}
                                                                                onClick={() =>
                                                                                    handlePageClick({
                                                                                        selected: pageCount - 1,
                                                                                    })
                                                                                }
                                                                            >
                                                                                {pageCount}
                                                                            </a>
                                                                        );
                                                                    }
                                                                    return <a {...props} />;
                                                                }}
                                                            />
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>





                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add Customer </ModalHeader>
                <form className="tablelist-form">
                    <ModalBody>
                        <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                            <label htmlFor="id-field" className="form-label">ID</label>
                            <input type="text" id="id-field" className="form-control" placeholder="ID" readOnly />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="customername-field" className="form-label">IP Address</label>
                            <input type="text" id="customername-field" className="form-control" placeholder="Enter Name" required />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="email-field" className="form-label">Browser</label>
                            <input type="email" id="email-field" className="form-control" placeholder="Enter Email" required />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="phone-field" className="form-label">Reason</label>
                            <input type="text" id="phone-field" className="form-control" placeholder="Enter Phone no." required />
                        </div>




                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Close</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Add Customer</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>


        </React.Fragment>
    );
};

export default Swap;

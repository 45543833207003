import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Input, Spinner,NavItem,Nav,NavLink,TabContent,TabPane } from "reactstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { handleNullValue } from "../../../helpers/function";
import { postData } from "../../../ApiService/AxiosInstance";
import { Apiservices } from "../../../ApiService/ApiServices";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { formatNumber } from "../../../helpers/common";
import classnames from "classnames";
import RewardAsset from "./RewardAsset";
import ReferralAsset from "./ReferralAsset";


const UserBalance = () => {
  const { id } = useParams();
  document.title = "Ultrapro | User Management";
  const [activeTab, setActiveTab] = useState("1");

  const [balance, setBalance] = useState([]);
  const [balLoad, setBalLoad] = useState(false);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [count, setCount] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchLoad, setSearchLoad] = useState(false);
  const [searchValid, setSearchValid] = useState(false);
  const [inputSearch, setinputSearch] = useState("");
  const [hideZeroBalance, setHideZeroBalance] = useState(false);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = totalLimit; 
  const pageCount = Math.ceil(totalPagination);



  const toggle = async (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };

  const handleBalance = useCallback( async () => {
    setBalLoad(true);
    const postForm = {
      acId: Number(id),
      walletId:0
    };

    try {
      const response = await postData(Apiservices.SPOT_BALANCE, postForm);
      if (response && response.status) {
        setBalLoad(false);
        setBalance(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.totalCount);
      } else {
        setBalLoad(false);
      }
    } catch (error) {
      console.error(error);
      setBalLoad(false);
    }
  },[id]);

  useEffect(() => {
    handleBalance();
  }, [handleBalance]);

  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      search: inputSearch,
      acId: Number(id),
    };
    try {
      const response = await postData(Apiservices.SPOT_BALANCE, postForm);
      if (response.status) {
        setLoading(false);
        setBalance(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.totalCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };


  const UserBalancecolumns = [
    {
      name: "S.No",
      selector: (row, index) => currentPage * itemsPerPage + index + 1,
      sortable: true,
      grow: 1,
    },
    {
      name: "Image",
      selector: (row) => (
        <div>
          <img style={{ width: "30px" }} src={row?.image ? row?.image : "-"} alt="" ></img>
        </div>
      ),
      sortable: true,
      grow: 2,
    },
    {
      name: "Name",
      // selector: (row) => handleNullValue(row.name),
      selector: (row) => handleNullValue(row?.name),
      sortable: true,
      grow: 2,
    },

    {
      name: "Symbol",
      selector: (row) => handleNullValue(row?.symbol),
      sortable: true,
      grow: 2,
    },

    {
      name: "Balance",
      selector: (row) => handleNullValue(row?.currencyType === 0 ? formatNumber(row.balance, row.decimalPoint) : formatNumber(row.balance, row.decimalPoint)),
      sortable: true,
      grow: 2,
    },
   
  ];

  const toggleHideZeroBalance = () => {
    const newValue = !hideZeroBalance; 
    setHideZeroBalance(newValue);
    handleFilter(newValue ? 1 : 0); 
  };
 

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "" && balance === undefined) {
      handleBalance();
      setSearchValid(false);
    } else if (value === "") {
      handleFilter(balance);
    }
    setinputSearch(value);
  };

  const handleFilter = async (value) => {
    setLoading(true);
    const postForm = {
      acId: Number(id),
      showHide: value,
      walletId:0
    };
    try {
      const response = await postData(Apiservices.SPOT_BALANCE, postForm);
      console.log(response);
      
      if (response && response.status) {
        setLoading(false);
        setBalance(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.totalCount);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
        acId: Number(id),
      };

      try {
        const response = await postData(Apiservices.SPOT_BALANCE, postForm);

        if (response.status) {
          setSearchLoad(false);
          setBalance(response.data.data);
          setTotalPagination(response.data.totalPages);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm="12" className="d-lg-flex">
          <Card className="col-lg-12">
            <CardBody>
            <Nav tabs className="custom-nav-tabs">
                          <NavItem>
                            <NavLink style={{ cursor: "pointer" }}
                              className={classnames({ active: activeTab === "1", })}
                              onClick={() => { toggle("1"); }} >
                              <i className="mdi mdi-cash"></i>{" "}
                              Spot Assets
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink style={{ cursor: "pointer" }}
                              className={classnames({ active: activeTab === "2", })}
                              onClick={() => { toggle("2"); }} >
                              <i className="mdi mdi-cash"></i>{" "}
                              Reward Assets
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink style={{ cursor: "pointer" }}
                              className={classnames({ active: activeTab === "3", })}
                              onClick={() => { toggle("3"); }} >
                              <i className="mdi mdi-cash"></i>{" "}
                              Referral Assets
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} className="p-3">
                        <TabPane tabId="1" id="home">
              <Row className="mb-3">
                <Col sm={4} lg={3}>
                  <div className="input-group">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Search by Name or Symbol"
                      aria-label="Recipient's username"
                      onChange={handleSearchChanage}
                      invalid={searchValid}
                      onKeyPress={handleKeyPress}
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                        {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                      </button>
                    </div>
                  </div>
                </Col>
                <Col className="col-sm">
                  <div className="d-flex justify-content-sm-end">
                    <div className="form-check form-switch form-switch-md">
                      <span>Hide Zero Balance</span>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        disabled={loading}
                        onChange={toggleHideZeroBalance}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="table-responsive">
                <DataTable
                  columns={UserBalancecolumns}
                  data={balance}
                  paginationServer
                  paginationTotalRows={balance.length}
                  progressPending={balLoad}
                  persistTableHead={true}
                  progressComponent={<div className="py-3">Loading...</div>}
                  noDataComponent={<div className="py-3">No records found</div>}
                  fixedHeader={true}
                />
              </div>
              <div className="row mt-3">
                <div className="col-12 col-md-6">
                  <div className="d-flex justify-content-start mt-3">
                    <span>Current Count : {count}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  {loading ? (
                    ""
                  ) : (
                    <div className="d-flex justify-content-end">
                      <div className="pagination pagination-rounded mb-0">
                        <ReactPaginate
                          previousLabel={<i className="mdi mdi-chevron-left"></i>}
                          nextLabel={<i className="mdi mdi-chevron-right"></i>}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          pageLinkClassName={"page-link"}
                          breakLinkClassName={"break-link"}
                          disabledClassName={"disabled"}
                          initialPage={currentPage}
                          disableInitialCallback={true}
                          forcePage={currentPage}
                          renderLink={(props) => {
                            if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                              return (
                                <a
                                  {...props}
                                  onClick={() =>
                                    handlePageClick({
                                      selected: pageCount - 1,
                                    })
                                  }
                                >
                                  {pageCount}
                                </a>
                              );
                            }
                            return <a {...props} aria-label="Link Description" />;
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              </TabPane>
              </TabContent>

              {/* TAB2 */}

              <TabContent activeTab={activeTab}>
              <TabPane tabId="2" id="home">
              <RewardAsset tabId={activeTab}/>
              </TabPane>
              </TabContent>

                {/* TAB3 */}

                <TabContent activeTab={activeTab} >
                        <TabPane tabId="3" id="home">
              <Row className="mb-3">
              <ReferralAsset tabId={activeTab} />
              </Row>
              </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserBalance;

import DataTable from "react-data-table-component";

import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Card,
  CardBody,
  FormFeedback,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
  Form,
} from "reactstrap";
// import { useTable, usePagination } from 'react-table';
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { utcToNormalTime } from "../../helpers/function";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";


const Networks = () => {
  const location = useLocation();
  const row = location.state;

  document.title = "Ultrapro | User IP Management";

  const [loading, setLoading] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);

  const [recordToDelete, setRecordToDelete] = useState({});
  const [modal_delete, setmodal_delete] = useState(false);
  const [formData, setFormData] = useState([]);
  const [coinList, setCoinList] = useState([]);
  const [tokenList, setTokenList] = useState([]);


  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Number of items to display per page
  const pageCount = Math.ceil(formData.length / itemsPerPage);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    ipList();
    coinTOkenList()
  }, []);
  const ipList = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.NETWORK_LIST);
      if (response.status) {
        // setCoinList(response.data.coinList);
        setFormData(response.data.networks);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const coinTOkenList = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.COIN_TOKEN_NETWORK_LIST);
      console.log(response,"LID}");
      
      if (response.status) {
        setCoinList(response.data?.coinsList);
        setTokenList(response.data?.tokenList);

        
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      coinName: '',
      networkName: '',
      tokenName: '',
      contractAddress:''
      // networkType: '',
      // depositWalletId: '',


    },
    validationSchema: Yup.object({
      coinName: Yup.string().required("Native Coin is required"),
      networkName: Yup.string().required("Network name is required"),
      tokenName: Yup.string().required("Token name is required"),
      contractAddress: Yup.string().required("Contact Address is required"),

      
      
      // networkType: Yup.string().required("Network type is required"),
      // depositWalletId: Yup.number()
      // .typeError("Depposit Wallet Id must be a number")
      // .required("Depposit Wallet Id is required")
      // .moreThan(0, "Depposit Wallet Id must be greater than zero"),


    }),
    onSubmit: (values) => {

      handlePost(values)


    },
  });

  // const handleNetworkNameChange = (e) => {
  //   const upperCaseValue = e.target.value.toUpperCase();
  //   validation.setFieldValue("networkName", upperCaseValue);
  // };

  const handlePost = async (data) => {
    setLoading(true);
    const postForm = {
      coinName: data.coinName,
      tokenName: data.tokenName,
      tokenContractAddress: data.contractAddress,
      shortName: data.coinName.toLowerCase(),
      networkName: data.networkName.toUpperCase(),
      networkType: 0,
    };
    console.log(postForm,"POSSSSSSSS");
    
    try {
      const response = await postData(Apiservices.NETWORK_CREATE, postForm);
      if (response.status) {
        ipList();
        setLoading(false);
        toast.success(response.message);
        setmodal_list(false);
        validation.resetForm();
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);

      validation.resetForm();
    }
  };

  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    // validation.setValues({
    //     coinName:row.name
    // })
    // validation.resetForm()
    setmodal_list(!modal_list);
  }

  const handleDelete = (record) => {
    setRecordToDelete(record);
    setmodal_delete(true);
  };

  const confirmDelete = async () => {
    const postForm = {
      networkId: recordToDelete.networkId,
    };
    const queryString = new URLSearchParams(postForm).toString();
    setDeleteLoad(true);
    try {
      const response = await postData(
        `${Apiservices.NETWORK_DELETE}?${queryString}`
      );
      if (response.status) {
        setDeleteLoad(false);
        toast.success(response.message);
        setmodal_delete(false);
        ipList();
      } else {
        toast.error(response.message);
        validation.resetForm();
        setmodal_delete(false);
        setDeleteLoad(false);
      }
    } catch (error) {
      setDeleteLoad(false);
      toast.error("Internal Server Error..Try Again Later");

      setmodal_delete(false);

      validation.resetForm();
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
  }

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
console.log(formData,"formData");

  const filteredData = formData.filter((item) => {
    const formattedDate = item.createdAt
      ? utcToNormalTime(item.createdAt).date
      : "";
    const isIPMatch =
      item.networkName &&
      item.networkName.toLowerCase().includes(searchTerm.toLowerCase());
    const isReasonMatch =
      item.coinName &&
      item.coinName.toLowerCase().includes(searchTerm.toLowerCase());
    const isDateMatch = formattedDate && formattedDate.includes(searchTerm);
    const isIDMatch = item._id && item._id.includes(searchTerm);
    return isIPMatch || isIDMatch || isReasonMatch || isDateMatch;
  });

  const displayedData = filteredData.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // handleInputChange();
    }
  };


  const [networkId, setNetworkId] = useState("");
  const handleUpdate = (record) => {
    setNetworkId(record.networkId);
    validationUpdate.setValues({
      coinName: record.coinName || "",
      tokenName: record.tokenName || "",
      contractAddress: record.tokenContractAddress || "",
      networkName: record.networkName || "",
      isActive: record.isActive !== undefined ? String(record.isActive) : "",
    });

    setmodal_update_list(true);
  };
  const handleUpdateStatusClick = (row) => {
    showStatusAlert(handleToggle1, row);
  }
  const showStatusAlert = (onConfirm, row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to update status!",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!'
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm(row);
        // Swal.fire('Success !', 'The status has been updated successfully', 'success');

      }
    });
  };
  const handleToggle1 = async (rowDetails) => {
    const updatedStatus = rowDetails.isActive === 1 ? 0 : 1;
    try {
      const postForm = {
        isActive: updatedStatus,
        coinName: rowDetails.coinName,
        networkName: rowDetails.networkName,
        networkId: networkId,
        networkType: 0
      }

      const response = await postData(Apiservices.NETWORK_UPDATE, postForm);
      if (response.status === true) {
        toast.dismiss();
        ipList();
        Swal.fire("Success !", `${response.message}`, "success");
      } else {
        Swal.fire('Error!', `${response.message}`, 'error');
      }
    } catch (error) {
      toast.dismiss();
      Swal.fire('Error!', `${error.message}`, 'error');

    }
  };
  const columns = [
    {
      name: "S.No",
      selector: (row, index) => currentPage * itemsPerPage + index + 1,
      sortable: true,
      grow:0
    },
    {
      name: "Token Name",
      selector: (row) => row.tokenName,
      sortable: true,
    },
    {
      name: "Coin Name",
      selector: (row) => row.coinName,
      sortable: true,
    },

    {
      name: "Network Name",
      selector: (row) => row.networkName,
      sortable: true,
    },
  
    
    {
      name: "Token Address",
      selector: (row) => row.tokenContractAddress,
      sortable: true,
      grow:3
    },
    
    {
      name: "Status",
      selector: (row) => (
        <div className="form-check form-switch form-switch-md mb-3 d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            checked={row.isActive === 1}
            onChange={() => handleUpdateStatusClick(row)}
            disabled={loading}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex gap-2">
          <div className="remove">
            <i
              style={{ fontSize: "15px", cursor: "pointer" }}
              className="mdi mdi-border-color cursor-pointer mx-1 mt-1"
              onClick={() => handleUpdate(row)}
            ></i>
            <span
              onClick={() => handleDelete(row)}
              className="mdi mdi-trash-can-outline"
              style={{ fontSize: "15px", cursor: "pointer" }}
            ></span>
          </div>
        </div>
      ),
      ignoreRowClick: true,
      // allowOverflow: true,
      button: true,
    },
  ];

  const validationUpdate = useFormik({
    enableReinitialize: true,

    initialValues: {
      coinName: "",
      tokenName:"",
      networkName: "",
      contractAddress:"",
      isActive: '',
      // networkType: '',
    },
    validationSchema: Yup.object({
      coinName: Yup.string().trim().required("Native Coin is required"),
      tokenName: Yup.string().trim().required("Token Name is required"),

      networkName: Yup.string().trim().required("Network name is required"),
      contractAddress: Yup.string().trim().required("Contract Address is required"),


      
      isActive: Yup.string().trim().required("Status is required"),
      // networkType: Yup.string().required("Network type is required"),
    }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });

  const confirmUpdate = async (values) => {
    // const updatedStatus = rowDetails.isActive === 1 ? 0 : 1;
    setLoading(true);

    const postForm = {
      coinName: values.coinName,
      tokenName: values.tokenName,
      networkName: values.networkName,
      isActive: values.isActive,
      tokenContractAddress:values.contractAddress,
      shortName: values.coinName.toLowerCase(),
      networkId: networkId,
    };
    console.log(values,'VALUES');
    
    try {
      const response = await postData(Apiservices.NETWORK_UPDATE, postForm);
      if (response.status === true) {
        setmodal_update_list(false);
        toast.success(response.message);
        ipList();
        // handlePagePost(currentPage)
      } else {
        toast.error(response.message);
        ipList();
      }
    } catch (error) {
      toast.error(error.message);
      validationUpdate.resetForm();
    } finally {
      setLoading(false);
    }
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Network Management"
            breadcrumbItem="Network List"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <form className="app-search d-block">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              onChange={handleInputChange}
                              onKeyPress={handleKeyPress}
                            />
                            <span className="ri-search-line"></span>
                          </div>
                        </form>
                      </Col>

                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <Button
                            color="success"
                            className="add-btn"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </Button>

                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <DataTable
                        columns={columns}
                        data={displayedData}
                        paginationServer
                        paginationTotalRows={filteredData.length}
                        progressPending={loading}
                        progressComponent={
                          <div className="py-3">Loading...</div>
                        }
                        oDataComponent={
                          <div className="py-3">No records found</div>
                        }
                        fixedHeader
                        persistTableHead={true}
                      />
                    </div>


                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">

                        {displayedData.length > 0 ? (
                          <div className="pagination pagination-rounded mb-0">
                            <ReactPaginate
                              previousLabel={
                                <i className="mdi mdi-chevron-left"></i>
                              }
                              nextLabel={
                                <i className="mdi mdi-chevron-right"></i>
                              }
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              breakLinkClassName={"break-link"}
                              disabledClassName={"disabled"}
                              initialPage={currentPage}
                              disableInitialCallback={true}
                              forcePage={currentPage}
                              // disableInitialCallback={true}
                              renderLink={(props) => {
                                if (
                                  props.page === "..." &&
                                  props.pageCount > props.pageRangeDisplayed
                                ) {
                                  return (
                                    <a
                                      {...props}
                                      onClick={() =>
                                        handlePageClick({
                                          selected: pageCount - 1,
                                        })
                                      }
                                    >
                                      {pageCount}
                                    </a>
                                  );
                                }
                                return <a {...props} />;
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Modal */}
      <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
        <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add Networks </ModalHeader>
        <Form
          autoComplete="off"
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false
          }}
        >
          <ModalBody>

            {/* <div className="mb-3">
                                <label htmlFor="status-field" className="form-label">Network Type</label>
                                <select
                                    id="status-field"
                                    
                                    name="coinName"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.coinName || ""}
                                    className={`form-control ${validation.touched.coinName && validation.errors.coinName ? 'is-invalid' : ''}`}
                                >
                                    <option value="">Select NetWork Type</option>
                                   {coinList.map((data,index)=>(
                                    <option key={index} value={data.coinId}>{data.name} ({data.symbol})</option>

                                   ))}
                                </select>
                                {validation.touched.coinName && validation.errors.coinName && (
                                    <FormFeedback type="invalid">{validation.errors.coinName}</FormFeedback>
                                )}
                            </div> */}

            <div className="mb-3">
              <label htmlFor="status-field" className="form-label">
                Native Coin{" "}
              </label>
              <select
                id="status-field"
                name="coinName"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.coinName || ""}
                className={`form-control ${validation.touched.coinName && validation.errors.coinName
                    ? "is-invalid"
                    : ""
                  }`}
              >
                <option value="">Choose Native Coin</option>
                {coinList.map((data, index) => (
                  <option key={index} value={data.symbol}>
                    {data.symbol}
                  </option>
                ))}
              </select>
              {validation.touched.coinName && validation.errors.coinName && (
                <FormFeedback type="invalid">
                  {validation.errors.coinName}
                </FormFeedback>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="status-field" className="form-label">
                Coin Token{" "}
              </label>
              <select
                id="status-field"
                name="tokenName"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.tokenName || ""}
                className={`form-control ${validation.touched.tokenName && validation.errors.tokenName
                    ? "is-invalid"
                    : ""
                  }`}
              >
                <option value="">Choose Token</option>
                {tokenList.map((data, index) => (
                  <option key={index} value={data.symbol}>
                    {data.symbol}
                  </option>
                ))}
              </select>
              {validation.touched.tokenName && validation.errors.tokenName && (
                <FormFeedback type="invalid">
                  {validation.errors.tokenName}
                </FormFeedback>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">Network Name</label>
              <Input
                type="text"
                id="customername-field"
                className="form-control"
                placeholder="Enter Network Name"
                name="networkName"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.networkName || ""}
                // style={{ textTransform: 'uppercase' }}
                invalid={validation.touched.networkName && validation.errors.networkName}
              />
              {validation.touched.networkName && validation.errors.networkName && (
                <FormFeedback type="invalid">{validation.errors.networkName}</FormFeedback>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">
                Cotract Address
              </label>
              <Input
                type="text"
                id="customername-field"
                className="form-control"
                placeholder="Enter Network Name"
                name="contractAddress"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.contractAddress || ""}
                // style={{ textTransform: 'uppercase' }}
                invalid={
                  validation.touched.contractAddress &&
                  validation.errors.contractAddress
                }
              />
              {validation.touched.contractAddress &&
                validation.errors.contractAddress && (
                  <FormFeedback type="invalid">
                    {validation.errors.contractAddress}
                  </FormFeedback>
                )}
            </div>


          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button
                className="btn btn-primary waves-effect waves-light"
                type="submit"
                disabled={loading}
              >
                {loading ? <Loader /> : "Submit"}
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>


      {/* update  modal */}
      <Modal
        className="modal-dialog"
        isOpen={modal_update_list}
        toggle={() => {
          tog_update_list();
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            tog_update_list();
          }}
        >
          Update Networks
        </ModalHeader>
        <Form
          autoComplete="off"
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();
            validationUpdate.handleSubmit();
            return false;
          }}
        >
          <ModalBody>

            <div className="mb-3">
              <label htmlFor="status-field" className="form-label">
                Native Coin{" "}
              </label>
              <select
                id="status-field"
                name="coinName"
                onChange={validationUpdate.handleChange}
                onBlur={validationUpdate.handleBlur}
                value={validationUpdate.values.coinName || ""}
                className={`form-control ${validationUpdate.touched.coinName && validationUpdate.errors.coinName
                    ? "is-invalid"
                    : ""
                  }`}
              >
                <option value="">Choose Native Coin</option>
                {coinList.map((data, index) => (
                  <option key={index} value={data.symbol}>
                    {data.symbol}
                  </option>
                ))}
              </select>
              {validationUpdate.touched.coinName && validationUpdate.errors.coinName && (
                <FormFeedback type="invalid">
                  {validationUpdate.errors.coinName}
                </FormFeedback>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="status-field" className="form-label">
                Coin Token{" "}
              </label>
              <select
                id="status-field"
                name="tokenName"
                onChange={validationUpdate.handleChange}
                onBlur={validationUpdate.handleBlur}
                value={validationUpdate.values.tokenName || ""}
                className={`form-control ${validationUpdate.touched.tokenName && validationUpdate.errors.tokenName
                    ? "is-invalid"
                    : ""
                  }`}
              >
                <option value="">Choose Token</option>
                {tokenList.map((data, index) => (
                  <option key={index} value={data.symbol}>
                    {data.symbol}
                  </option>
                ))}
              </select>
              {validationUpdate.touched.tokenName && validationUpdate.errors.tokenName && (
                <FormFeedback type="invalid">
                  {validationUpdate.errors.tokenName}
                </FormFeedback>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">
                Network Name
              </label>
              <Input
                type="text"
                id="customername-field"
                className="form-control"
                placeholder="Enter Network Name"
                name="networkName"
                onChange={validationUpdate.handleChange}
                onBlur={validationUpdate.handleBlur}
                value={validationUpdate.values.networkName || ""}
                // style={{ textTransform: 'uppercase' }}
                invalid={
                  validationUpdate.touched.networkName &&
                  validationUpdate.errors.networkName
                }
              />
              {validationUpdate.touched.networkName &&
                validationUpdate.errors.networkName && (
                  <FormFeedback type="invalid">
                    {validationUpdate.errors.networkName}
                  </FormFeedback>
                )}
            </div>
            <div className="mb-3">
              <label htmlFor="customername-field" className="form-label">
                Cotract Address
              </label>
              <Input
                type="text"
                id="customername-field"
                className="form-control"
                placeholder="Enter Network Name"
                name="contractAddress"
                onChange={validationUpdate.handleChange}
                onBlur={validationUpdate.handleBlur}
                value={validationUpdate.values.contractAddress || ""}
                // style={{ textTransform: 'uppercase' }}
                invalid={
                  validationUpdate.touched.contractAddress &&
                  validationUpdate.errors.contractAddress
                }
              />
              {validationUpdate.touched.contractAddress &&
                validationUpdate.errors.contractAddress && (
                  <FormFeedback type="invalid">
                    {validationUpdate.errors.contractAddress}
                  </FormFeedback>
                )}
            </div>
            <div className="mb-3">
              <label htmlFor="status-field" className="form-label">
                Status
              </label>
              <select
                id="status-field"
                //  className="form-control"
                name="isActive"
                onChange={validationUpdate.handleChange}
                onBlur={validationUpdate.handleBlur}
                value={validationUpdate.values.isActive || ""}
                className={`form-control ${validationUpdate.touched.isActive && validationUpdate.errors.isActive ? "is-invalid" : ""}`}
              >
                <option value="" disabled>
                  Select Status
                </option>
                <option value={1}>Active</option>
                <option value={0}>Deactive</option>
              </select>
              {validationUpdate.touched.isActive && validationUpdate.errors.isActive && <FormFeedback type="invalid"> {validationUpdate.errors.isActive} </FormFeedback>}
            </div>

          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_update_list(false);
                }}
              >
                Close
              </button>

              <button
                className="btn btn-primary waves-effect waves-light"
                type="submit"
                disabled={loading}
              >
                {loading ? <Loader /> : "Submit"}
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        className="modal zoomIn"
        id="deleteRecordModal"
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(false)}
            className="btn-close"
            aria-label="Close"
          >
            {" "}
          </Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-3 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(false)}
            >
              Close
            </button>
            <button
              className="btn btn-primary waves-effect waves-light"
              type="submit"
              onClick={confirmDelete}
              disabled={deleteLoad}
            >
              {deleteLoad ? <Loader /> : "Yes, Delete It!"}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Networks;

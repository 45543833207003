import React, { useState, useEffect } from "react";
import { Input, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Apiservices, Boolian } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import { useParams } from "react-router-dom";


const CryptoNewsNotes = () => {
  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [getDiscoverList, setDisoverList] = useState([]);
  document.title = "Ultrapro | Crypto News";
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState("");
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);


  //classList api call
  useEffect(() => {
    fetchData();
  }, []);



  const fetchData = async () => {
    try {
      const postForm={
        newsId:id
      }
      setLoading(true);
      const response = await postData(Apiservices.CryptoNewsAdminStatus,postForm);
      if (response.status) {
        setLoading(false);
        setTotalPagination(response.data.totalPages);
        setDisoverList(response.data?.data);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Handle fetch error
    }
  };






const getBadgeKYCClass = (code) => {
  console.log(code,"CODE");
  
  switch (code) {
    case Boolian.CRYPTO_ENABLE:
      return "badge-soft-success";
    case Boolian.CRYPTO_DISABLE:
      return "badge-soft-danger";
    default:
      return "";
  }
};
const getKycStatusText = (code) => {
  switch (code) {
    case Boolian.CRYPTO_ENABLE:
      return "Enable";
    case Boolian.CRYPTO_DISABLE:
      return "Disable";
    default:
      return "Unknown";
  }
};

const columns = [
  {
    name: "S.No",
   selector: (row, index) =>handleNullValue(row.serialNo)  ,
    sortable: true,
    minWidth: '80px',
    maxWidth: '100px'
  },
  
  {
    name: "Admin Name",
    selector: (row) => handleNullValue(row.crypto.adminName),
    sortable: true,
    minWidth: '150px',

  },
  
  
  {
    name: "Admin Email",
    selector: (row) => handleNullValue(row.crypto.adminEmail),
    minWidth: '300px',

  },
  {
    name: "Reason",
    selector: (row) => handleNullValue(row.crypto.reason),
    minWidth: '250px',
    
  },
 

  {
    name: "Status",
    selector: (row) => (
      <div className="d-flex justify-content-center align-items-center">
       
        <span className={`badge ${getBadgeKYCClass(row.crypto.isActive)} me-1`}>
            {getKycStatusText(row.crypto.isActive)}
          </span>
      </div>
    ),
    sortable: true,
    minWidth: '150px',

  },
 
  {
    name: "Updated Date & Time",
    selector: (row) => row.crypto.updateTime ? `${handleNullValue(utcToNormalTime(row.crypto.updateTime).date)} ${handleNullValue(utcToNormalTime(row.crypto.updateTime).time)}` : "---",
    sortable: true,
    ignoreRowClick: true,
    minWidth: '200px',
    maxWidth: '250px'
  },
 
];
  


  

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };
  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: itemsPerPage,
    };
    try {
      const response = await postData(Apiservices.CryptoNewsAdminStatus,postForm);
      if (response.status) {
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  


  //Searchfun
  const [inputSearch, setinputSearch] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "") {
      fetchData();
      setSearchValid(false);
    } else {
      setinputSearch(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
      };

      const queryString = new URLSearchParams(postForm).toString();
      try {
        const response = await postData(
          `${Apiservices.CryptoNewsAdminStatus}?${queryString}`
        );

        if (response.status) {
          setSearchLoad(false);
          setDisoverList(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);

        } else {
          setSearchLoad(false);
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };

const handleRowsPerPageChange = (newRowsPerPage) => {
  setRowsPerPage(parseInt(newRowsPerPage));
  HandleClickRowperpage(newRowsPerPage)
}
const HandleClickRowperpage = async(newRowsPerPage) => {
  setLoading(true);
  const postForm = {
    page:1,
    limit:Number(newRowsPerPage) ,
  };

 
  try {
    if (getDiscoverList === undefined) {
      const response = await postData(Apiservices.CryptoNewsAdminStatus,postForm);

      if (response.status) {
        setLoading(false);
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } else {
      const response = await postData(Apiservices.CryptoNewsAdminStatus,postForm);
      if (response.status) {
        setLoading(false);
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    }
  } catch (error) {
   
    toast.error("Internal Server Error..Try Again Later");
    setLoading(false);
  }

};


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Crypto News" breadcrumbItem="Crypto News List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4">
                    <Col className="col-sm-auto">
                    <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => handleSearch()}
                            disabled={searchLoad}
                          >
                            {searchLoad ? (<Spinner className="text-light" size="sm" /> ) : (<i className="ri-search-line" /> )}
                          </button>
                        </div>
                      </div>
                   
                    </Col>
                
                  </Row>
                 
    <div className="table-responsive  mt-2">
    <DataTable
      columns={columns}
      data={getDiscoverList}
      paginationServer
      paginationTotalRows={getDiscoverList.length}
      progressPending={loading}
      persistTableHead={true}
      progressComponent={<div className="py-3">Loading...</div>}
      noDataComponent={<div className="py-3">No records found</div>}
      fixedHeader={true}
      
    />
    </div>
   

<div className="container">
  <div className="row justify-content-end mt-4">
  <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
      <div className="d-flex align-items-center me-3">
        <span className="me-2">Rows per page:</span>
        <select
          value={rowsPerPage}
          onChange={(e) => handleRowsPerPageChange(e.target.value)}
          className="form-control-sm"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>
      <div className="me-md-3 mb-3 mb-md-0">
        <span>{count}</span>
      </div>
      <div className="pagination pagination-rounded mt-3">
        <ReactPaginate
          previousLabel={<i className="mdi mdi-chevron-left"></i>}
          nextLabel={<i className="mdi mdi-chevron-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageLinkClassName={"page-link"}
          breakLinkClassName={"break-link"}
          disabledClassName={"disabled"}
          initialPage={currentPage}
          disableInitialCallback={true}
          forcePage={currentPage}
          renderLink={(props) => {
            if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
              return (
                <a
                  {...props}
                  onClick={() =>
                    handlePageClick({
                      selected: pageCount - 1,
                    })
                  }
                >
                  {pageCount}
                </a>
              );
            }
            return <a {...props} />;
          }}
        />
      </div>
    </div>
  </div>
</div>
  


                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CryptoNewsNotes;

import React, { useState ,useEffect} from "react";
import { useParams } from "react-router-dom";
import { Button, Input, Card, CardBody, Col, Container, Row ,FormFeedback,FormGroup} from "reactstrap";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { postData} from "../../ApiService/AxiosInstance";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik, FieldArray, FormikProvider, Formik } from "formik";
import { Apiservices } from "../../ApiService/ApiServices";

const DepositSettings = () => {
 
    const { name } = useParams();
    const [loading, setLoading] = useState(false)
    const [coinDetails,setCoinDetails] = useState([])
    const [CoinStatus,setCoinStatus] = useState('')
   

    useEffect(() => {
        const fetchData = async () => {
            const data = {
                coinName:name
            }
          try {
            const response = await postData(Apiservices.GetCoinDetails,data);
            if (response) {
            setCoinStatus(response.status)
            setCoinDetails(response.data)
            formik.setValues({
                deposittype: response.data.depositType,
                isActive: response.data.isActive,
                network: response.data.netWork.map(network => ({
                    networkName: network.networkName,
                    isActive: network.isActive,
                    description: network.description
                }))
              
            });
         
        } else {
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            // Handle fetch error
          }
        };
    
        fetchData();
      }, []);


    const validationSchema = Yup.object().shape({
        // coinName: Yup.string().required("Coin Name is required"),
        deposittype: Yup.number().required("Deposit Type is required"),
        isActive: Yup.number().required("Status is required"),
        network: Yup.array().of(
            Yup.object().shape({
                networkName: Yup.string().required("Network Name is required"),
                isActive: Yup.number().required("Network Status is required"),
                description: Yup.string().required("Network Description is required"),
            })
        ),
    });

    const formik = useFormik({
        initialValues: {
            coinName: name || "",
            deposittype: "",
            isActive: "",
            network: [{
                networkName: "",
                isActive: "",
                description: "",
            }],
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
              setLoading(true);
              const data = {
                coinName: name,
                isActive: parseInt(values.isActive),
                depositType: parseInt(values.deposittype),
                network: values.network,
              };
              if (CoinStatus === false) {
                const response = await postData(Apiservices.DepositSettings_AddNetwork,data);
                if (response.status) {
                  toast.success(response.message);
                  formik.resetForm();
                  setLoading(false);
                } else {
                  toast.error(response.message);
                  setLoading(false);
                }
              } else {
                const response = await postData(Apiservices.DepositSettings_UpdateNetwork,data);
                if (response.status) {
                  toast.success(response.message);
                  formik.resetForm();
                  setLoading(false);
                } else {
                  toast.error(response.message);
                  setLoading(false);
                }
              }
            } catch (error) {
              toast.error("Failed to save deposit settings");
              console.error("Error:", error);
              setLoading(false);
            }
        },
    });
    

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Coin Management"
              breadcrumbItem={`Deposit Settings for ${name}`}
            />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <FormikProvider value={formik}>
                      <form onSubmit={formik.handleSubmit}>
                        <Row className="mb-3">
                          <Col sm={3} lg={3}>
                            <div className="mb-3">
                              <label
                                htmlFor="customername-field"
                                className="form-label"
                              >
                                Coin Name
                              </label>
                              <Input
                                type="text"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Coin Name"
                                name="coinName"
                                value={name}
                                disabled
                                 autoComplete="off"
                                // onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                // invalid={formik.touched.coinName && formik.errors.coinName}
                              />
                            </div>
                          </Col>
                          <Col sm={3} lg={3}>
                            <FormGroup>
                              <label htmlFor="deposittype-field">
                                Deposit Type
                              </label>
                              <Input
                                type="select"
                                id="deposittype-field"
                                className={`form-control ${
                                  formik.touched.deposittype &&
                                  formik.errors.deposittype
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="deposittype"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.deposittype}
                                 autoComplete="off"
                              >
                                <option disabled value="">
                                  Select Deposit Type
                                </option>
                                <option value={1}>Manual</option>
                                <option value={0}>Auto</option>
                              </Input>
                              {formik.touched.deposittype &&
                                formik.errors.deposittype && (
                                  <FormFeedback>
                                    {formik.errors.deposittype}
                                  </FormFeedback>
                                )}
                            </FormGroup>
                          </Col>
                          <Col sm={3} lg={3}>
                            <FormGroup>
                              <label htmlFor="isActive-field">Status</label>
                              <Input
                                type="select"
                                 autoComplete="off"
                                id="isActive-field"
                                className={`form-control ${
                                  formik.touched.isActive &&
                                  formik.errors.isActive
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="isActive"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.isActive}
                              >
                                <option disabled value="">
                                  Select isActive
                                </option>
                                <option value={1}>Active</option>
                                <option value={0}>Inactive</option>
                              </Input>
                              {formik.touched.isActive &&
                                formik.errors.isActive && (
                                  <FormFeedback>
                                    {formik.errors.isActive}
                                  </FormFeedback>
                                )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <FieldArray name="network">
                          {({ push, remove }) => (
                            <div>
                              {formik.values.network.map((network, index) => (
                                <Row key={index} className="mb-3">
                                  <Col sm={3} lg={3}>
                                    <div className="mb-3">
                                      <label
                                        htmlFor={`network.${index}.networkName`}
                                        className="form-label"
                                      >
                                        Network Name
                                      </label>
                                      <Input
                                       autoComplete="off"
                                        type="text"
                                        id={`network.${index}.networkName`}
                                        className="form-control"
                                        placeholder="Enter Network Name"
                                        name={`network.${index}.networkName`}
                                        value={network.networkName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                          formik.touched.network &&
                                          formik.touched.network[index] &&
                                          formik.errors.network &&
                                          formik.errors.network[index] &&
                                          formik.errors.network[index]
                                            .networkName
                                        }
                                      />
                                      {formik.touched.network &&
                                        formik.touched.network[index] &&
                                        formik.errors.network &&
                                        formik.errors.network[index] &&
                                        formik.errors.network[index]
                                          .networkName && (
                                          <FormFeedback>
                                            {
                                              formik.errors.network[index]
                                                .networkName
                                            }
                                          </FormFeedback>
                                        )}
                                    </div>
                                  </Col>
                                  <Col sm={3} lg={3}>
                                    <FormGroup>
                                      <label
                                        htmlFor={`network.${index}.isActive`}
                                      >
                                        Network Status
                                      </label>
                                      <Input
                                        type="select"
                                         autoComplete="off"
                                        id={`network.${index}.isActive`}
                                        className={`form-control ${
                                          formik.touched.network &&
                                          formik.touched.network[index] &&
                                          formik.errors.network &&
                                          formik.errors.network[index] &&
                                          formik.errors.network[index].isActive
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        name={`network.${index}.isActive`}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={network.isActive}
                                      >
                                        <option disabled value="">
                                          Select isActive
                                        </option>
                                        <option value={1}>Active</option>
                                        <option value={0}>Inactive</option>
                                      </Input>
                                      {formik.touched.network &&
                                        formik.touched.network[index] &&
                                        formik.errors.network &&
                                        formik.errors.network[index] &&
                                        formik.errors.network[index]
                                          .isActive && (
                                          <FormFeedback>
                                            {
                                              formik.errors.network[index]
                                                .isActive
                                            }
                                          </FormFeedback>
                                        )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={3} lg={3}>
                                    <FormGroup>
                                      <label
                                        htmlFor={`network.${index}.description`}
                                        className="form-label"
                                      >
                                        Network Description
                                      </label>
                                      <Input
                                        type="textarea"
                                         autoComplete="off"
                                        id={`network.${index}.description`}
                                        className="form-control"
                                        placeholder="Enter Network Description"
                                        name={`network.${index}.description`}
                                        value={network.description}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        invalid={
                                          formik.touched.network &&
                                          formik.touched.network[index] &&
                                          formik.errors.network &&
                                          formik.errors.network[index] &&
                                          !!formik.errors.network[index]
                                            .description
                                        }
                                      />
                                      {formik.touched.network &&
                                        formik.touched.network[index] &&
                                        formik.errors.network &&
                                        formik.errors.network[index] &&
                                        formik.errors.network[index]
                                          .description && (
                                          <FormFeedback>
                                            {
                                              formik.errors.network[index]
                                                .description
                                            }
                                          </FormFeedback>
                                        )}
                                    </FormGroup>
                                  </Col>

                                  <Col sm={2} lg={2}>
                                    <div className="mt-5">
                                      <i
                                        style={{ fontSize: "20px" }}
                                        onClick={() => remove(index)}
                                        className="mdi mdi-delete"
                                      ></i>
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                              <Col sm={2} lg={2}>
                                <Button
                                  color="success"
                                  className="add-btn"
                                  id="create-btn"
                                  onClick={() =>
                                    push({
                                      networkName: "",
                                      isActive: "",
                                      description: "",
                                    })
                                  }
                                >
                                  <i className="ri-add-line align-bottom me-1"></i>
                                  Add Network
                                </Button>
                              </Col>
                            </div>
                          )}
                        </FieldArray>
                        {loading ? (
                          <div className="text-center">
                            <Button color="success">Loading...</Button>
                          </div>
                        ) : (
                          <div className="text-center">
                            <Button type="submit" color="success">
                             {CoinStatus === true ? "Update" :  "Submit"}
                            </Button>
                          </div>
                        )}
                      </form>
                    </FormikProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
};

export default DepositSettings;


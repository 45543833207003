import React, { useState, useEffect,useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback,CardImg, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData, postGetData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { AdminContext } from "../../helpers/adminContext";



const TeamsList = () => {
  const [loading, setLoading] = useState(false);
  const [getSessionList, setSessionList] = useState([]);
  const [getId, setId] = useState("");
  const token = Cookies.get('authentication');
  document.title = "Ultrapro | Teams";
  const [previewImage, setPreviewImage] = useState(null);
  const [modal, setModal] = useState(false);

  const [sessionLink, setSessionLink] = useState('');
  const [errors, setErrors] = useState('');
  const [touched, setTouched] = useState(false);
//Permission List 
const { adminInfo } = useContext(AdminContext);
const [adminType,setAdminType] = useState('')

  //Add Modal
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
    formik.resetForm();
    setImage('')
  }
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
    setImage('')
  }
  const [getImage,setGetImage] = useState('')
  //classList api call
  useEffect(() => {
    fetchData();
    setAdminType(adminInfo?.adminType)
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await postGetData(Apiservices.TeamsList);
     

      if (response) {
        setLoading(false);
        setTotalPagination(response.data.totalPages);
        setSessionList(response.data.data);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
        // setCurrentPage(0); 
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Handle fetch error
    }
  };
  const [showDetails, setShowDetails] = useState({})
  const [showModel, setShowModel] = useState(false)
  const [recordToDelete, setRecordToDelete] = useState({});
  const [deleteLoad,setDeleteLoad] = useState(false)

  const handleShowModel = (value) => {
    setShowDetails(value)
    setShowModel(true)
}
const handleDelete = (value) => {
  setRecordToDelete(value);
  // setmodal_delete(true)
  showUserBlockAlert(confirmDelete, value)
}

const showUserBlockAlert = (onConfirm, row) => {
  Swal.fire({
      title: 'Are you sure?',
      text: `You want to remove this record!`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
          try {
              await onConfirm(row);
          } catch (error) {
              Swal.fire('Error!', 'There was an error deleting the record', 'error');
          }
      }
  }).then((result) => {
      if (result.isConfirmed) {
          // Additional actions after confirmation, if needed
      }
  });
};

const confirmDelete = async (row) => {
  const postForm = {
    teamsId : row.teamsId
  }
  // const formData = new FormData();
  // formData.append('sessionId', row.sessionId);
  setDeleteLoad(true)
  try {
      const response = await postData(Apiservices.TeamsDelete,postForm )
      // const response = await axiosInstance.post(Apiservices.DeleteSession, formData, {
      //   headers: {
      //     Authorization: token,
      //     "Content-Type": "multipart/form-data",
      //   },
      // });
      if (response.status) {
          setDeleteLoad(false)
          // toast.success(response.message)
          Swal.fire('Success!', `${response.message}`, 'success');
          // fetchData()
          handlePagePost(currentPage)

      } else {
          // toast.error(response.message)
          Swal.fire('Error!', `${response.message}`, 'error');
          setDeleteLoad(false)

      }

  } catch (error) {
      setDeleteLoad(false)
      toast.error("Internal Server Error..Try Again Later")
  }



}
const allowedActions = [1,2,3,4];

const columns = [
  {
    name: "S.No",
    // selector: (row, index) => currentPage * itemsPerPage + index + 1,
    selector: (row) => handleNullValue(row.serialNo),

    sortable: true,
    minWidth: '80px',
    maxWidth: '100px'
  },
  {
    name: "Profile Image",
    selector: (row) => <img style={{ width: "100px" }} src={row.teamsImage || '-'} />,
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  {
    name: "Name",
    selector: (row) => handleNullValue(row.teamsName),
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  {
    name: "Designation",
    selector: (row) => handleNullValue(row.teamsStack),
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  // {
  //   name: "LinkedIn URL",
  //   selector: (row) => handleNullValue(row.teamsLinkedinLink),
  //   sortable: true,
  //   minWidth: '150px',
  //   maxWidth: '200px'
  // },
  {
    name: "Position",
    selector: (row) => handleNullValue(row.position),
    // sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  ...(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Teams Management" && data.actions.includes(3)) ? [{
  
    name: "Status",
    selector: (row) => (
      <div className="form-check form-switch form-switch-md mb-3 d-flex justify-content-center align-items-center">
        <input
          type="checkbox"
          className="form-check-input"
          id="customSwitchsizemd"
          checked={row.teamsIsActive === 1}
          onChange={() => handleUpdateStatusClick(row)}
          disabled={loading}
        />
      </div>
    ),
    sortable: true,
    minWidth: '100px',
    maxWidth: '100px'
  }] : []),
  {
    name: "Date & Time",
    selector: (row) => row.createdAt ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${handleNullValue(utcToNormalTime(row.createdAt).time)}` : "---",
    sortable: true,
    minWidth: '200px',
    maxWidth: '250px'
  },
  ...(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Community" && allowedActions.some(action => data.actions.includes(action))) ? [

  {
    name: "Action",
    cell: (row) => (
      <div className="d-flex">
        {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Teams Management" && data.actions.includes(3))) ? (
        <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-border-color cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>
    ): null }
    {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Teams Management" && data.actions.includes(2))) ? (
        <i style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline mx-1"></i>
    ): null }
    {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Teams Management" && data.actions.includes(4))) ? (
        <i onClick={() => handleDelete(row)} className="mdi mdi-trash-can-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></i>
    ):  null}
      </div>
    ),
    ignoreRowClick: true,
    minWidth: '150px',
    maxWidth: '200px'
  }]: []),
];
  


  //paginnation
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };
  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: itemsPerPage,
      // search: inputSearch,
    };
    const queryString = new URLSearchParams(postForm).toString();
    try {
      // const response = await postData(`${Apiservices.TeamsList}?${queryString}`);
      const response = await postData(Apiservices.TeamsList,postForm);

      if (response.status) {
        setSessionList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  
  const validateSessionLink = (value) => {
    let error = '';
    if (!value) {
      error = 'Session link is required';
    }
    //  else if (!value.endsWith('.mp4')) {
    //   error = 'Session link must be an MP4 file';
    // }
    return error;
  };
  //Add form validation
  const formik = useFormik({
    initialValues: {
      teamsname: "",
      designation: "",
      // linkedin:'',
      teamsIsActive:"",
      imagelink:"",
      position:'',
    },
    validationSchema: Yup.object({
      teamsname: Yup.string().trim().required("Name is required"),
      designation: Yup.string().trim().required("Designation is required"),
      imagelink: Yup.string().trim().required("Image Link is required"),
      // linkedin: Yup.string().trim().required("LinkedIn URL is required"),
      // linkedin: Yup.string()
      // .matches(
      //   /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/,
      //   "Enter a valid LinkedIn URL"
      // )
      // .required("LinkedIn URL is required"),
      teamsIsActive: Yup.string().trim().required("Status is required"),
      position: Yup.string().trim()
      .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
      .required("Position is required"),
      }),
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  const [image, setImage] = useState(null);
  // const [defaultImage, setDefaultImage] = useState('path/to/default/image.jpg'); // Set your default image path
  const [error, setError] = useState('');
  
  
  // const validateImage = (file) => {
  //   const allowedTypes = ['image/png', 'image/jpeg'];
  //   if (!allowedTypes.includes(file.type)) {
  //       setError('Only JPG and PNG formats are allowed.');
  //       return false;
  //   }
  //   setError('');
  //   return true;
  // };
  
  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file && validateImage(file)) {
  //       setImage(file);
  //   } else {
  //       setImage(null);
  //   }
  // };
  const handleChange = (e) => {
    const { value } = e.target;
    setSessionLink(value);
    const error = validateSessionLink(value);
    setErrors(error);
  };
  const handleBlur = () => {
    setTouched(true);
  };
//Addapicall
const handleAdd = async (data) => {

  setLoading(true);
  // if(formik.values.videostatus === '1'){
  //   const error = validateSessionLink(sessionLink);
  //   if (error) {
  //     setErrors(error);
  //     setLoading(false);
  //     return;
      
  //   }
  // }

// if (!image) {
//   toast.error("Session image is required");
//   setLoading(false);
//   return;
// }
  // Validate session link before submission
 
  const formData = new FormData();
  formData.append("teamsName", data.teamsname);
  formData.append("files", data.imagelink);
  formData.append("teamsStack",data.designation);
  // formData.append("teamsLinkedinLink", data.linkedin);
  // formData.append('files', image);
  formData.append("teamsIsActive", Number(data.teamsIsActive));
  formData.append("position", Number(data.position));
  formData.append("teamsId", "");
  formData.append("adminType", adminInfo?.adminType);
  formData.append("permissions",adminInfo?.permission);


  
  try {
    // const response = await postData(Apiservices.TeamsAdd, formData);
    const response = await postData(Apiservices.TeamsAdd, formData,);

    if (response.status) {
      
      toast.success(response.data.message);
      formik.resetForm();
      setmodal_list(false);
      setLoading(false);
      fetchData();
      setImage(null)
      setSessionList('')
      setErrors('');

     
      formik.setFieldValue("sessionimage", "");
    } else {
      toast.error(response.data.message);
      formik.resetForm();
      setLoading(false);
      fetchData();
      setImage(null)
      setSessionList('')
      setErrors('');



    }
  } catch (error) {
    // toast.error(error);
    setLoading(false);
    formik.resetForm();
    setLoading(false);
    setImage(null)
    setSessionList('')
    setErrors('');



  }
};

  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,
  
    initialValues: {
      teamsname: "",
      designation: "",
      // linkedin:'',
      teamsIsActive:"",
      position:'',
      imagelink:"",
    },
    validationSchema: Yup.object({
      teamsname: Yup.string().trim().required("Name is required"),
      designation: Yup.string().trim().required("Designation is required"),
      imagelink: Yup.string().trim().required("Image Link is required"),

      // linkedin: Yup.string().trim().required("LinkedIn URL is required"),
      // linkedin: Yup.string()
      // .matches(
      //   /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/,
      //   "Enter a valid LinkedIn URL"
      // )
      // .required("LinkedIn URL is required"),
      teamsIsActive: Yup.string().trim().required("Status is required"),
      position: Yup.string().trim()
      .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
      .required("Position is required"),
      }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });
  
  const handleUpdate = (record) => {
    setId(record.teamsId);
    setGetImage(record.teamsImage)
    validationUpdate.setValues({
      teamsId: getId || "",
      teamsname: record.teamsName || "",
      designation:record.teamsStack || "",
      // linkedin: record.teamsLinkedinLink || "",
      teamsIsActive: record.teamsIsActive !== undefined ? String(record.teamsIsActive) : "",
      position:record.position || "",
      imagelink:record.teamsImage || "",
    });
   
    setmodal_update_list(true);
  };
  
 
  const confirmUpdate = async (values) => {
    setLoading(true);
  
    // Image validation
    // if (!image && !getImage) {
    //   toast.error("Session image is required");
    //   setLoading(false);
    //   return;
    // }
    // if(validationUpdate.values.videostatus === '1'){
    //   const error = validateSessionLink(sessionLink);
    //   if (error) {
    //     setErrors(error);
    //     setLoading(false);
    //     return;
        
    //   }
    // }
  
    const updateformData = new FormData();

    updateformData.append("teamsName", values.teamsname);
    updateformData.append("teamsStack",values.designation);
    // updateformData.append("teamsLinkedinLink", values.linkedin);
    updateformData.append("teamsIsActive", Number(values.teamsIsActive));
    updateformData.append("position", Number(values.position));
    updateformData.append("teamsId", getId);
    updateformData.append("files", values.imagelink);

    
    // Image handling
    // if (image) {
    //   updateformData.append("files", image);
    // } else if (getImage) {
    //   updateformData.append("files", getImage);
    // }
  
    try {
      const response = await postData(Apiservices.TeamsUpdate, updateformData);

  
      if (response.status === true) {
        setmodal_update_list(false);
        toast.success(response.message);
        // fetchData();
        handlePagePost(currentPage)
        setSessionList('')

      } else {
        
        toast.error(response.message);
        fetchData();
        setSessionList('')
      setErrors('');

      }
    } catch (error) {
     
      toast.error("Internal Server Error..Try Again Later");
      validationUpdate.resetForm();
    setErrors('');

    } finally {
      setLoading(false);
    }
  };
  
  //Searchfun
  const [inputSearch, setinputSearch] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "") {
      fetchData();
      setSearchValid(false);
    } else {
      setinputSearch(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
      };

      const queryString = new URLSearchParams(postForm).toString();
      try {
        // const response = await postData(
        //   `${Apiservices.TeamsList}?${queryString}`
        // );
        const response = await postData(Apiservices.TeamsList,postForm);
        if (response.status) {
          setSearchLoad(false);
          setSessionList(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);

          // toast(response.message);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
     
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };

const [isLoading,setIsLoading] = useState(false)

const showStatusAlert = (onConfirm, row) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You want to update status!",
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Update it!',
    showLoaderOnConfirm: true, 
    preConfirm: async (reason) => {
      if (!reason) {
          Swal.showValidationMessage('Reason is required');
      } else {
          Swal.showLoading();
          try {
              await onConfirm({ ...row, reason });
              Swal.fire('Success!', 'The status has been updated successfully', 'success');
          } catch (error) {
              Swal.fire('Error!', 'There was an error updating the status', 'error');
          }
      }
  },
  allowOutsideClick: () => !Swal.isLoading(), 
  // preConfirm: () => {
  //     return onConfirm(row) 
  //       .then(() => {
  //         return Promise.resolve();
  //       })
  //       .catch((error) => {
  //         Swal.showValidationMessage(`Request failed: ${error}`);
  //       });
  //   }
  }).then((result) => {
    if (result.isConfirmed) {
      // Success handling if needed
      // Swal.fire('Success !', 'The status has been updated successfully', 'success');
    }
  });
};
const handleUpdateStatusClick = (row) => {
  showStatusAlert(handleToggle1, row);
}

const handleToggle1 = async (rowDetails) => {
  const updatedStatus = rowDetails.teamsIsActive === 1 ? 0 : 1;
  try {
    setIsLoading(true)
    const statusUpdate = new FormData();

    statusUpdate.append("teamsName", rowDetails.teamsName);
    statusUpdate.append("teamsStack",rowDetails.teamsStack);
    // statusUpdate.append("teamsLinkedinLink", rowDetails.teamsLinkedinLink);
    statusUpdate.append("teamsIsActive", updatedStatus);
    statusUpdate.append("position", Number(rowDetails.position));
    statusUpdate.append("teamsId", rowDetails.teamsId);
    statusUpdate.append("files", rowDetails.teamsImage);

    // statusUpdate.append("files", rowDetails.teamsImage)



    // const response = await postData(`${Apiservices.TeamsUpdate}`, statusUpdate);
    const response = await postData(Apiservices.TeamsUpdate, statusUpdate, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === true) {
      toast.dismiss();
      setIsLoading(false);
      // toast.success(response.message);
      Swal.fire("Success !", "The status has been updated successfully", "success");
      // fetchData();
      handlePagePost(currentPage)

    } else {
      toast.error(response.message);
      setIsLoading(false);
    }
  } catch (error) {
    toast.dismiss();
    toast.error("Internal Server Error..Try Again Later");
    setIsLoading(false);
  }
};


const toggleModal = () => {
  setModal(!modal);
};

const [rowsPerPage, setRowsPerPage] = useState(10);
const [count, setCount] = useState("");

const handleRowsPerPageChange = (newRowsPerPage) => {
  setRowsPerPage(parseInt(newRowsPerPage));
  HandleClickRowperpage(newRowsPerPage)
}
const HandleClickRowperpage = async(newRowsPerPage) => {
  setLoading(true);
  const postForm = {
    // page: selected + 1,
    // page:Number(currentPage + 1),
    page:1,
    // limit:1 ,
    limit:Number(newRowsPerPage) ,
    // search: inputSearch,
  };

  // if (dateRange[0] && dateRange[1]) {
  //   postForm.startDate = formatDate(dateRange[0]);
  //   postForm.endDate = formatDate(dateRange[1]);
  // }

  const queryString = new URLSearchParams(postForm).toString();
  try {
    if (getSessionList === undefined) {
      // const response = await postData(`${Apiservices.TeamsList}?${queryString}`);
      const response = await postData(Apiservices.TeamsList,postForm);

      if (response.status) {
        setLoading(false);
        setSessionList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } else {
      // const response = await postData(`${Apiservices.TeamsList}?${queryString}`);
      const response = await postData(Apiservices.TeamsList,postForm);


      if (response.status) {
        setLoading(false);
        setSessionList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    }
  } catch (error) {
   
    toast.error("Internal Server Error..Try Again Later");
    setLoading(false);
  }

};
const validateImage = (file) => {
  // Validate file type and size here (e.g., PNG, JPG, JPEG)
  const validTypes = ['image/png', 'image/jpeg'];
  return validTypes.includes(file.type) && file.size <= 5000000; // 5MB max size
};

const handleImageChange = (e) => {
  const file = e.target.files[0];
  setImage(file);
  // if (file) {
  //   if (validateImage(file)) {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       const img = new Image();
  //       img.src = event.target.result;
  //       img.onload = () => {
  //         const { width, height } = img;
  //         if (validateResolution(width, height)) {
  //           setImage(file);
  //           setError('');
  //         } else {
  //           setError('Image resolution must be at least 800x600 pixels.');
  //           setImage(null);
  //         }
  //       };
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setError('Invalid file type or size.');
  //     setImage(null);
  //   }
  // }
};

const validateResolution = (width, height) => {
  // Minimum resolution required: 800x600 pixels
  return width >= 800 && height >= 600;
};
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Teams" breadcrumbItem="Teams List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4">
                    <Col className="col-sm-auto">
                    <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => handleSearch()}
                            disabled={searchLoad}
                          >
                            {searchLoad ? (<Spinner className="text-light" size="sm" /> ) : (<i className="ri-search-line" /> )}
                          </button>
                        </div>
                      </div>
                   
                    </Col>
                    {/* Add Modal Button */}
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                      {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Teams Management" && data.actions.includes(1))) ? (
                        <Button
                          color="success"
                          className="add-btn"
                          onClick={() => tog_list()}
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                        </Button>
    ):null}
                      </div>
                    </Col>
                  </Row>
                 
    <div className="table-responsive  mt-2">
    <DataTable
      columns={columns}
      data={getSessionList}
      paginationServer
      paginationTotalRows={getSessionList.length}
      progressPending={loading}
      persistTableHead={true}
      progressComponent={<div className="py-3">Loading...</div>}
      // noDataComponent={formData.length===0}
      noDataComponent={<div className="py-3">No records found</div>}
      fixedHeader={true}
      
    />
    </div>
   

<div className="container">
  <div className="row justify-content-end mt-4">
  <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
      <div className="d-flex align-items-center me-3">
        <span className="me-2">Rows per page:</span>
        <select
          value={rowsPerPage}
          onChange={(e) => handleRowsPerPageChange(e.target.value)}
          className="form-control-sm"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>
      <div className="me-md-3 mb-3 mb-md-0">
        <span>{count}</span>
      </div>
      <div className="pagination pagination-rounded mt-3">
        <ReactPaginate
          previousLabel={<i className="mdi mdi-chevron-left"></i>}
          nextLabel={<i className="mdi mdi-chevron-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageLinkClassName={"page-link"}
          breakLinkClassName={"break-link"}
          disabledClassName={"disabled"}
          initialPage={currentPage}
          disableInitialCallback={true}
          forcePage={currentPage}
          renderLink={(props) => {
            if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
              return (
                <a
                  {...props}
                  onClick={() =>
                    handlePageClick({
                      selected: pageCount - 1,
                    })
                  }
                >
                  {pageCount}
                </a>
              );
            }
            return <a {...props} />;
          }}
        />
      </div>
    </div>
  </div>
</div>
  

  {/* // ADDMODAL  */}
  <Modal className="modal-dialog modal-lg" isOpen={modal_list}  toggle={() => { tog_list(); }}centered>
  <ModalHeader  className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list();}}> Add Teams</ModalHeader>
  <Form autoComplete="off" className="form-horizontal" 
  onSubmit={(e) => { e.preventDefault();   formik.handleSubmit();  return false; }}>
  <ModalBody>
  <Row>
    <Col md={6}>
    <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Name
        </label>

        <Input
          type="text"
          id="teamsname-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Name"
          name="teamsname"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.teamsname || ""}
          invalid={formik.touched.teamsname && formik.errors.teamsname}
        />
        {formik.touched.teamsname && formik.errors.teamsname && <FormFeedback type="invalid">{formik.errors.teamsname}</FormFeedback>}
      </div>
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Designation
        </label>

        <Input
          type="text"
          id="designation-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Designation"
          name="designation"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.designation || ""}
          invalid={formik.touched.designation && formik.errors.designation}
        />
        {formik.touched.designation && formik.errors.designation && <FormFeedback type="invalid">{formik.errors.designation}</FormFeedback>}
      </div>
     
      {/* <div className="mb-3">
        <label htmlFor="title-field" className="form-label"> Profile Image </label>
        <div> 
      <input type="file" className="form-control" accept=".png, .jpg, .jpeg"
      onChange={handleImageChange} />
        </div>
      {error && <div className="text-danger" style={{ fontSize: '12px' }}>{error}</div>}
      {image ? 
            <Card style={{ width: '200px'}}>
            <CardBody>
              <CardImg top src={image ? URL.createObjectURL(image) : ''}
                style={{ width: '100%', height: '150px', objectFit: 'cover' }}
              />
            </CardBody>
          </Card> : null}
      </div> */}
  <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Image URL
        </label>

        <Input
          type="text"
          id="imagelink-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Image URL"
          name="imagelink"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.imagelink || ""}
          invalid={formik.touched.imagelink && formik.errors.imagelink}
        />
        {formik.touched.imagelink && formik.errors.imagelink && <FormFeedback type="invalid">{formik.errors.imagelink}</FormFeedback>}
      </div>
     

 
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Image Preview</ModalHeader>
        <ModalBody>{previewImage && <img src={previewImage} alt="Preview" style={{ width: "100%", height: "auto" }} />}</ModalBody>
      </Modal>
      
    </Col>
    <Col md={6}>
      {/* <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         LinkedIn URL
        </label>

        <Input
          type="text"
          id="linkedin-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter LinkedIn URL"
          name="linkedin"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.linkedin || ""}
          invalid={formik.touched.linkedin && formik.errors.linkedin}
        />
        {formik.touched.linkedin && formik.errors.linkedin && <FormFeedback type="invalid">{formik.errors.linkedin}</FormFeedback>}
      </div> */}
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Position
        </label>
        <Input
          type="number"
           min="1"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Position"
          name="position"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.position || ""}
          invalid={formik.touched.position && formik.errors.position}
        />
        {formik.touched.position && formik.errors.position && <FormFeedback type="invalid">{formik.errors.position}</FormFeedback>}
      </div>
      
      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
          Status
        </label>
        <select
          id="status-field"
          name="teamsIsActive"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.teamsIsActive || ""}
          className={`form-control ${formik.touched.teamsIsActive && formik.errors.teamsIsActive ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Status
          </option>
          <option value={1}>Active</option>
          <option value={0}>Deactive</option>
        </select>
        {formik.touched.teamsIsActive && formik.errors.teamsIsActive && <FormFeedback type="invalid"> {formik.errors.teamsIsActive} </FormFeedback>}
      </div>
    </Col>
  </Row>
</ModalBody>

    <ModalFooter>
      <div className="hstack gap-2 justify-content-end">
        <button type="button" className="btn btn-light" onClick={() => {setmodal_list(false); formik.resetForm();setImage('')}}>
          Close
        </button>

        <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
          {loading ? <Loader /> : "Submit"}
        </button>
      </div>
    </ModalFooter>
  </Form>
</Modal>


     {/* UpdateModal */}
  <Modal className="modal-dialog modal-lg" isOpen={modal_update_list}  toggle={() => { tog_update_list();}} centered>
  <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>
    Update Teams
  </ModalHeader>
  <Form autoComplete="off" className="form-horizontal"
    onSubmit={(e) => {
      e.preventDefault();
      validationUpdate.handleSubmit();
      return false;
    }}
  >
    <ModalBody>
      <Row>
        <Col md={6}>
        <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Name
        </label>

        <Input
          type="text"
          id="teamsname-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Name"
          name="teamsname"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.teamsname || ""}
          invalid={validationUpdate.touched.teamsname && validationUpdate.errors.teamsname}
        />
        {validationUpdate.touched.teamsname && validationUpdate.errors.teamsname && <FormFeedback type="invalid">{validationUpdate.errors.teamsname}</FormFeedback>}
      </div>
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Designation
        </label>

        <Input
          type="text"
          id="designation-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Designation"
          name="designation"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.designation || ""}
          invalid={validationUpdate.touched.designation && validationUpdate.errors.designation}
        />
        {validationUpdate.touched.designation && validationUpdate.errors.designation && <FormFeedback type="invalid">{validationUpdate.errors.designation}</FormFeedback>}
      </div>
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Image URL
        </label>

        <Input
          type="text"
          id="imagelink-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Image URL"
          name="imagelink"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.imagelink || ""}
          invalid={validationUpdate.touched.imagelink && validationUpdate.errors.imagelink}
        />
        {validationUpdate.touched.imagelink && validationUpdate.errors.imagelink && <FormFeedback type="invalid">{validationUpdate.errors.imagelink}</FormFeedback>}
      </div>
     
      {/* <div className="mb-3">
      <label htmlFor="title-field" className="form-label">  Image  </label>
        <div>
        <input
          type="file"
          className="form-control"
          accept=".png, .jpg, .jpeg"
          onChange={handleImageChange}
          // Set value attribute to show filename or keep it blank if no file is selected
          // value={getImage ? getImage : ''}
          placeholder="No file chosen"
        />
      </div>
      {error && <div className="text-danger" style={{ fontSize: '15px' }}>{error}</div>}
      {(getImage || image) && (
        <Card style={{ width: '200px' }}>
          <CardBody>
            <CardImg top src={image ? URL.createObjectURL(image) : getImage}
              style={{ width: '100%', height: '150px', objectFit: 'cover' }}/>
          </CardBody>
        </Card>)}
      </div> */}
    
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Image Preview</ModalHeader>
        <ModalBody>{previewImage && <img src={previewImage} alt="Preview" style={{ width: "100%", height: "auto" }} />}</ModalBody>
      </Modal>
     
        </Col>
        <Col md={6}>
{/*       
<div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         LinkedIn URL
        </label>

        <Input
          type="text"
          id="linkedin-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter LinkedIn URL"
          name="linkedin"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.linkedin || ""}
          invalid={validationUpdate.touched.linkedin && validationUpdate.errors.linkedin}
        />
        {validationUpdate.touched.linkedin && validationUpdate.errors.linkedin && <FormFeedback type="invalid">{validationUpdate.errors.linkedin}</FormFeedback>}
      </div> */}
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Position
        </label>
        <Input
          type="number"
          id="subject-field"
          className="form-control"
          autoComplete="off"
           min="1"
          placeholder="Enter Position"
          name="position"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.position || ""}
          invalid={validationUpdate.touched.position && validationUpdate.errors.position}
        />
        {validationUpdate.touched.position && validationUpdate.errors.position && <FormFeedback type="invalid">{validationUpdate.errors.position}</FormFeedback>}
      </div>
    
      <div className="mb-3">
        <label htmlFor="status-field" className="form-label"> Status </label>
        <select
          id="status-field"
          //  className="form-control"
          name="teamsIsActive"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.teamsIsActive || ""}
          className={`form-control ${validationUpdate.touched.teamsIsActive && validationUpdate.errors.teamsIsActive ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Status
          </option>
          <option value={1}>Active</option>
          <option value={0}>Deactive</option>
        </select>
        {validationUpdate.touched.teamsIsActive && validationUpdate.errors.teamsIsActive && <FormFeedback type="invalid"> {validationUpdate.errors.teamsIsActive} </FormFeedback>}
      </div>
        </Col>
      </Row>
  
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-light" onClick={() => {setmodal_update_list(false);setImage('')}}>
              Close
            </button>

            <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
              {loading ? <Loader /> : "Submit"}
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>



{/* View Modal    */}
<Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static" >

<div className="modal-header">
    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
       Teams Details 
    </h5>
    <button
        onClick={() => { setShowModel(false); }}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div className="modal-body">

    <div className="table-responsive">
        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td><b>Teams ID</b></td>
                    <td>{showDetails.teamsId || '-'}</td>
                </tr>
                <tr>
                    <td><b>Name</b></td>
                    <td>{showDetails.teamsName || '-'}</td>
                </tr>
                <tr>
                    <td><b>Designation</b></td>
                    <td>{showDetails.teamsStack || '-'}</td>
                </tr>
                {/* <tr>
                    <td><b>LinkedIn URL</b></td>
                    <td>{showDetails.teamsLinkedinLink  || '-'}

                    </td>
                </tr> */}
                <tr>
                    <td><b>Position</b></td>
                    <td>{showDetails.position || '-'}</td>
                </tr>
      
                <tr>
                    <td><b>Status</b></td>
                    <td>{showDetails.teamsIsActive == 1 ? 'Active' : 'Deactive' || '-'}</td>
                </tr>
               
                <tr>
                    <td><b>Date & Time</b></td>
                    <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                </tr>
                <tr>
                    <td><b>Profile Image</b></td>
                    <td>
                      <Card style={{ width: '200px'}}>
                      <CardBody>
                        <CardImg
                          top
                          src={showDetails.teamsImage || '-'}
                          style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                        />
                      </CardBody>
                    </Card>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>

</Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TeamsList;

import React, { useState, useEffect } from "react";
import { Input, Card, CardBody, Col, Container, Button, Row, Spinner, InputGroup } from "reactstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useLocation } from "react-router-dom";
import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import { Link } from "react-router-dom";

const BlockedUsers = () => {
  document.title = "Ultrapro | Blocked Users";
  const location = useLocation();
  const today = new Date();
  const userApi = location.state?.user;
  const [dateRange, setDateRange] = useState([null, null]);
  const [downloadStartDate, setDownloadEndDate] = useState({});
  const [loading, setLoading] = useState(false);
  const [downLoading, setDownLoading] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const [searchValid, setSearchValid] = useState(false);
  const [inputSearch, setinputSearch] = useState("");
  const [formData, setFormData] = useState([]);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [count, setCount] = useState("");
  const [user, setUser] = useState(userApi);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit;
  const pageCount = Math.ceil(totalPagination);
  const [selectedItem, setSelectedItem] = useState("Select Type");

  useEffect(() => {
    if (user === undefined) {
      setUser(Apiservices.USER_BLOCKED_LIST);
      ipList();
    }
  }, [user]);

  const handleFilter = async (apiName) => {
    setLoading(true);
    try {
      const response = await postData(apiName);
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleFilter1 = async (apiName, type) => {
    const postForm = {
      blockType: type,
    };
    setLoading(true);
    try {
      const response = await postData(apiName, postForm);
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const ipList = async () => {
    setLoading(true);
    try {
      const response = await postData(Apiservices.USER_BLOCKED_LIST);
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        handleDropdownClick("All Users");
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Internal Server Error...");
      setLoading(false);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; 
    
    return {
      formattedDate: `${day}-${month}-${year}`,
      formattedTime: `${hours}:${minutes} ${ampm}`,
    };
  };

  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      search: inputSearch,
    };

    if (dateRange[0] && dateRange[1]) {
      postForm.startDate = formatDate(dateRange[0]);
      postForm.endDate = formatDate(dateRange[1]);
    }

    try {
      if (user === undefined) {
        const response = await postData(Apiservices.USER_BLOCKED_LIST, postForm);

        if (response.status) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
        } else {
          setLoading(false);
        }
      } else {
        const response = await postData(user, postForm);

        if (response.status) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const getStatusText = (code) => {
    switch (code) {
      case "Blocked":
        return "Blocked";
      default:
        return "Unknown";
    }
  };

  const getBadgeClass = (code) => {
    switch (code) {
      case "Blocked":
        return "badge-soft-danger";
      default:
        return "";
    }
  };
  const columns = [
    {
      name: "S.No",
      selector: (row, index) => currentPage * itemsPerPage + index + 1,
      sortable: true,
      minWidth: "50px",
    },
    {
      name: "Name",
      selector: (row) => handleNullValue(row.fullName),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Email",
      selector: (row) => (
        <div className="d-flex align-items-center">
          <Link className="link-tag" to={`/allUsers/userDetails/${row.acId}`} target="_blank">
            <span className="mx-1 text-decoration-underline">{row?.email || "-"}</span>
          </Link>
        </div>
      ),
      sortable: true,
      minWidth: "300px",
    },
    {
      name: "Mobile Number",
      selector: (row) => (row.mobileNo ? `${row.mobileCode ? `+${row.mobileCode} ` : ""}${row.mobileNo}` : "-"),
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Country",
      selector: (row) => handleNullValue(row.country),
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Register From",
      selector: (row) => (row.osType == 0 ? "Web" : "Android"),
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Type",
      selector: (row) => {
        if (row.acBlock === 1) return "Password Block";
        if (row.acBlock === 2) return "OTP Block";
        if (row.isActive === 2) return "Admin Blocked";
        if (row.withdrawStatus === 1) return "Withdraw OTP Blocked";
        if (row.withdrawOtpCount === 4) return "Withdraw OTP Blocked";
        if (row.withdrawMaxCount === 3) return "Withdraw OTP Blocked";
        return "-";
      },

      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Status",
      selector: (row) => (
        <div className="edit">
          <span className={`badge ${getBadgeClass("Blocked")} me-1`}>{getStatusText("Blocked")}</span>
        </div>
      ),
      sortable: true,
      minWidth: "100px",
    },

    {
      name: "Blocked Date & Time",
      selector: (row) => (row.updatedAt ? `${handleNullValue(utcToNormalTime(row.updatedAt).date)} ${utcToNormalTime(row.updatedAt).time}` : "---"),
      sortable: true,
      minWidth: "200px",
    },
  ];

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "" && user === undefined) {
      ipList();
      setSearchValid(false);
    } else if (value === "") {
      handleFilter(user);
    }
    setinputSearch(value);
  };

  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
      };

      try {
        if (user === undefined) {
          const response = await postData(Apiservices.USER_BLOCKED_LIST, postForm);
          if (response.status) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);
          } else {
            setSearchLoad(false);
          }
        } else {
          const response = await postData(user, postForm);
          if (response.status) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);
          } else {
            setSearchLoad(false);
          }
        }
      } catch (error) {
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleDownload = async (format) => {
    // setSelectedFormat(format);
    setDownLoading(true);
    try {
      const postForm = {};

      if (downloadStartDate.startDate) {
        postForm.startDate = downloadStartDate.startDate;
      }
      if (downloadStartDate.endDate) {
        postForm.endDate = downloadStartDate.endDate;
      }
      if (inputSearch) {
        postForm.search = inputSearch;
      }
      postForm.download = true;
      postForm.format = format;
      const queryString = new URLSearchParams(postForm).toString();

      const response = await axiosInstance.post(
        `${user}?${queryString}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
      if (response.data) {
        setDownLoading(false);
        const blob = response.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `BlockedUsers.${format}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error(response.data.message);
        setDownLoading(false);
      }
    } catch (err) {
      setDownLoading(false);
      console.error("Error downloading the file", err);
    }
  };

  const handleDateChange = ([start, end]) => {
    const formattedStart = start ? formatDate(start) : null;
    const formattedEnd = end ? formatDate(end) : null;

    setDateRange([start, end]);

    if (!formattedStart && !formattedEnd) {
      handleFilter(user);
      setDownloadEndDate({});
    }

    const dateObj = {
      startDate: formattedStart?.formattedDate,
      startTime: formattedStart?.formattedTime,
    };

    if (formattedEnd) {
      dateObj.endDate = formattedEnd.formattedDate;
      dateObj.endTime = formattedEnd.formattedTime;
    }

    setDownloadEndDate(dateObj);
    if (formattedStart) {
      handleDate(dateObj);
    }
  };

  const handleDate = async (postForm) => {
    setLoading(true);

    try {
      const response = await postData(user, postForm);
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleDropdownClick = (item) => {
    setSelectedItem(item);
    if (item === "OTP Blocked") {
      handleFilter(Apiservices.USER_BLOCKED_LIST, item);
    } else if (item === "Password Blocked") {
      handleFilter1(Apiservices.USER_BLOCKED_LIST, item);
    } else if (item === "Admin Blocked") {
      handleFilter1(Apiservices.USER_BLOCKED_LIST, item);
    } else if (item === "Withdraw OTP Blocked") {
      handleFilter1(Apiservices.USER_BLOCKED_LIST, item);
    } else {
      handleFilter1(Apiservices.USER_BLOCKED_LIST);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="User Management" breadcrumbItem="Blocked Users" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col sm={4} lg={3}>
                      <div className="input-group">
                        <Input type="text" className="form-control" placeholder="Search by Name,Email,Mobile Number" aria-label="Recipient's username" onChange={handleSearchChanage} invalid={searchValid} onKeyPress={handleKeyPress} />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                            {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col sm={5} lg={5}>
                      <div className="form-group mb-4">
                        <div>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select Start to End Date and Time"
                              options={{
                                mode: "range",
                                altInput: true,
                                altFormat: "F j, Y h:i K",
                                dateFormat: "Y-m-d H:i",
                                maxDate: today,
                                enableTime: true,
                                time_24hr: false,
                              }}
                              value={dateRange}
                              onChange={handleDateChange}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Col>

                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div className="mx-2">
                          <select className="form-select" value={selectedItem} onChange={(e) => handleDropdownClick(e.target.value)}>
                            <option selected value="" disabled>
                              Select Type
                            </option>
                            <option value="All Blocked Users">All Blocked Users</option>
                            <option value="Admin Blocked">Admin Blocked</option>
                            <option value="Password Blocked">Password Blocked</option>
                            <option value="OTP Blocked">OTP Blocked</option>
                            <option value="Withdraw OTP Blocked">Withdraw OTP Blocked</option>
                          </select>
                        </div>

                        <Button color="info" className="add-btn mx-2" onClick={() => handleDownload("xlsx")} id="create-btn" disabled={downLoading}>
                          <i className="mdi mdi-download-outline"></i>

                          {downLoading ? <Loader /> : "Download"}
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={formData}
                      paginationServer
                      paginationTotalRows={formData.length}
                      progressPending={loading}
                      persistTableHead={true}
                      progressComponent={<div className="py-3">Loading...</div>}
                      noDataComponent={<div className="py-3">No records found</div>}
                      fixedHeader={true}
                    />
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-md-6">
                      <div className="d-flex justify-content-start mt-3">
                        <span>Current Count : {count}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      {loading ? (
                        ""
                      ) : (
                        <div className="d-flex justify-content-end mt-3">
                          <div className="pagination pagination-rounded mb-0">
                            <ReactPaginate
                              previousLabel={<i className="mdi mdi-chevron-left"></i>}
                              nextLabel={<i className="mdi mdi-chevron-right"></i>}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              breakLinkClassName={"break-link"}
                              disabledClassName={"disabled"}
                              initialPage={currentPage}
                              disableInitialCallback={true}
                              forcePage={currentPage}
                              renderLink={(props) => {
                                if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                  return (
                                    <a
                                      {...props}
                                      onClick={() =>
                                        handlePageClick({
                                          selected: pageCount - 1,
                                        })
                                      }
                                    >
                                      {pageCount}
                                    </a>
                                  );
                                }
                                return <a {...props} aria-label="Link Description" />;
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BlockedUsers;

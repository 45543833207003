import React, { useState, useEffect } from "react";
import { Input, Card, CardBody, Col, Container, Row, Button, Spinner, InputGroup } from "reactstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../ApiService/AxiosInstance";
import { Apiservices, Boolian } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useLocation } from "react-router-dom";
import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import country from '../../helpers/CountryCodes.json'

const ExpoList = () => {
  document.title = "Ultrapro | Expo Users";

  const location = useLocation();
  const today = new Date();
  const userApi = location.state?.user;
  const osTypeMapping = {
    '0': 'Web',
    '1': 'Android',
    '2': 'IOS'
  };
  const [dateRange, setDateRange] = useState([null, null]);
  const [downloadStartDate, setDownloadEndDate] = useState({});
  const [loading, setLoading] = useState(false);
  const [downLoading, setDownLoading] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const [searchValid, setSearchValid] = useState(false);
  const [inputSearch, setinputSearch] = useState("");
  const [formData, setFormData] = useState([]);
  const [interest, setIntrest] = useState([]);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [count, setCount] = useState("");
  const [selectedOsType, setSelectedOsType] = useState();
  const [selectedintrested, setSelectedIntrested] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [user, setUser] = useState(userApi);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);
  // const pageCount = 5;
  const createPostForm = (osType, country,intrest) => {
    const postForm = {};
    if (downloadStartDate.startDate) postForm.startDate = downloadStartDate.startDate;
    if (downloadStartDate.endDate) postForm.endDate = downloadStartDate.endDate;
    if (inputSearch) postForm.search = inputSearch;
    if (country) postForm.country = country;
    if (osType) postForm.osType = osType;
    if (intrest) postForm.interest = intrest;


    return postForm;
  };
  useEffect(() => {
    if (user === undefined) {
      setUser(Apiservices.ExpoUsersList);
      ipList();
    }
  }, [user]);

  const handleFilter = async (apiName) => {

    setLoading(true);
    // const postForm = {
    //     ...filterParams
    // };
    try {
      const response = await postData(apiName);
      if (response.status) {
        
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
        setIntrest(response.data.interest);


      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const ipList = async () => {
    setLoading(true);

    try {
      const response = await postData(Apiservices.ExpoUsersList,{});

      if (response.status) {

        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
        setIntrest(response.data.interest);

      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {

      toast.error("Internal Server Error...");
      setLoading(false);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);

  };



  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      // limit: itemsPerPage,
    };
    if (inputSearch) {
      postForm.search = inputSearch
    }

    if (selectedCountry) {
      postForm.country = selectedCountry
    }
    if (selectedOsType) {
      postForm.osType = selectedOsType
    }
    if (dateRange[0] && dateRange[1]) {
      postForm.startDate = formatDate(dateRange[0]);
      postForm.endDate = formatDate(dateRange[1]);
    }

    if (dateRange[0] && dateRange[1]) {
      postForm.startDate = formatDate(dateRange[0]);
      postForm.endDate = formatDate(dateRange[1]);
    }
    if (selectedintrested) {
      postForm.interest = selectedintrested
    }
 
    try {
      if (user === undefined) {
        const response = await postData(Apiservices.ExpoUsersList, postForm);

        if (response.status) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
        } else {
          setLoading(false);
        }
      } else {
        const response = await postData(user, postForm);

        if (response.status) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      }
    } catch (error) {

      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };


  const columns = [

    {
      name: "S.No",
      selector: (row, index) => currentPage * itemsPerPage + index + 1,
      sortable: true,
      minWidth: '50px',
    },
    {
      name: "Name",
      selector: (row) => handleNullValue(row.name),
      sortable: true,
      minWidth: '200px',
    },
    {
      name: "Email",
      selector: (row) => handleNullValue(row.email),
      sortable: true,
      minWidth: '300px',
      // grow: 5,
    },
    {
      name: "Register ID",
      selector: (row) => handleNullValue(row.regId),
      sortable: true,
      minWidth: '150px',
      // grow: 5,
    },

    {
      name: "Interest",
      selector: (row) => handleNullValue(row.interest),
      sortable: true,
      minWidth: '200px',
      // grow: 5,
    },
    {
      name: "Mobile Number",
      // selector: (row) => handleNullValue(row.mobile),
      selector: (row) => row.mobile ? `${row.mobileCode ? `+${row.mobileCode} ` : ''}${row.mobile}` : '-',
      sortable: true,
      minWidth: '150px',
      // grow: 3,
    },
    {
      name: "Register From",
      // selector: (row) => handleNullValue(row.mobileNo),
      selector: (row) => (row.osType == 0 ? 'Web' : 'Android'),
      sortable: true,
      minWidth: '150px',
      // grow: 3,
    },
    {
      name: "Country Code",
      selector: (row) => handleNullValue(row.countryCode),
      sortable: true,
      minWidth: '200px',
      // grow: 5,
    },
    {
      name: "Country",
      selector: (row) => handleNullValue(row.country),
      sortable: true,
      minWidth: '200px',
      // grow: 5,
    },
    {
      name: "Age",
      selector: (row) => handleNullValue(row.age),
      sortable: true,
      minWidth: '100px',
      // grow: 5,
    },
    //   {
    //     name: "Ip Address",
    //     selector: (row) => handleNullValue(row.ipAddress),
    //     sortable: true,
    //     minWidth: '150px',
    //     // grow: 5,
    //   },

    //  {
    //   name: "Register From",
    //   // selector: (row) => handleNullValue(row.mobileNo),
    //   selector: (row) => (row.osType == 0 ? 'Web' : 'Android'),
    //   sortable: true,
    //   minWidth: '150px',
    //   // grow: 3,
    // },
    // {
    //   name: "Status",
    //   selector: (row) => (
    //     <div className="edit">
    //       {/* <span className={`badge ${getBadgeClass(row.isActive)} me-1`}>
    //                     {getStatusText(row.isActive)}
    //                 </span> */}
    //       <span className={`badge ${getBadgeClass(row.status)} me-1`}>
    //         {getStatusText(row.status)}
    //       </span>
    //     </div>
    //   ),
    //   sortable: true,
    //   minWidth: '100px',
    //   // grow: 2,
    // },
    {
      name: "Date & Time",
      selector: (row) =>
        row.createdAt
          ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${utcToNormalTime(row.createdAt).time
          }`
          : "---",
      sortable: true,
      minWidth: '200px',
      // grow: 3,
    },

  ];

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "" && user === undefined) {
      ipList();
      setSearchValid(false);
    } else if (value === "") {
      handleFilter(user);
    }
    setinputSearch(value);
  };

  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {};
      if (inputSearch) {
        postForm.search = inputSearch
      }

      if (selectedCountry) {
        postForm.country = selectedCountry
      }
      if (selectedOsType) {
        postForm.osType = selectedOsType
      }
      if (dateRange[0] && dateRange[1]) {
        postForm.startDate = formatDate(dateRange[0]);
        postForm.endDate = formatDate(dateRange[1]);
      }
      if (selectedintrested) {
        postForm.interest = selectedintrested
      }
      try {
        if (user === undefined) {
          const response = await postData(Apiservices.ExpoUsersList, postForm);
          if (response.status) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);
          } else {
            setSearchLoad(false);
          }
        } else {
          const response = await postData(user, postForm);
          if (response.status) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);

          } else {
            setSearchLoad(false);
          }
        }
      } catch (error) {

        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDateChange = ([start, end]) => {
    const formattedStart = start ? formatDate(start) : null;
    const formattedEnd = end ? formatDate(end) : null;

    setDateRange([start, end]);

    if (!formattedStart && !formattedEnd) {
      handleFilter(user);
      setDownloadEndDate({});
    }

    if (formattedStart && formattedEnd) {
      setDownloadEndDate({
        startDate: formattedStart,
        endDate: formattedEnd,
      });
      handleDate({ startDate: formattedStart, endDate: formattedEnd });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };




  const handleSelect = (value, type) => {
    let newOsType = selectedOsType;
    let newCountry = selectedCountry;
    let newInterest = selectedintrested;
  
    if (type === 'os') {
      setSelectedOsType(value);
      newOsType = value;
    } else if (type === 'country') {
      setSelectedCountry(value);
      newCountry = value;
    } else if (type === 'interest') {
      setSelectedIntrested(value);
      newInterest = value;
    } 
    // Create post form with updated values
    const postForm = createPostForm(newOsType, newCountry, newInterest);
    if (type === 'overall') {
      ipList()
    }else{
      handleApiRequest(postForm, user);
    }
  };



  const handleApiRequest = async (postForm, userEndpoint) => {
    setLoading(true);



    try {
      const endpoint = userEndpoint || Apiservices.ExpoUsersList;
      const response = await postData(endpoint, postForm);

      if (response.status) {
    
        
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
      } else {
        toast.error("Data not found");
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
    } finally {
      setLoading(false);
    }
  };



  const handleDate = async ({ startDate, endDate }) => {
    setLoading(true);

    const postForm = {
      startDate: startDate,
      endDate: endDate,
    };
    if (inputSearch) {
      postForm.search = inputSearch
    }
    if (selectedCountry) {
      postForm.country = selectedCountry
    }
    if (selectedOsType) {
      postForm.osType = selectedOsType
    }
    try {

      const response = await postData(user, postForm);
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleDownload = async (format) => {
    // setSelectedFormat(format);
    setDownLoading(true);
    try {
      const postForm = {};

      if (downloadStartDate.startDate) {
        postForm.startDate = downloadStartDate.startDate;
      }
      if (downloadStartDate.endDate) {
        postForm.endDate = downloadStartDate.endDate;
      }
      if (inputSearch) {
        postForm.search = inputSearch;
      }
      if (selectedCountry) {
        postForm.country = selectedCountry
      }
      if (selectedOsType) {
        postForm.osType = selectedOsType
      }
      if (selectedintrested) {
        postForm.interest = selectedintrested
      }
      postForm.download = true;
      postForm.format = format
      const queryString = new URLSearchParams(postForm).toString();

      const response = await axiosInstance.post(
        `${user}?${queryString}`,
        {
          // Include any data you need to send with the POST request here
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
      if (response.data) {
        setDownLoading(false);
        const blob = response.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute('download', `${user}.xlsx`);
        link.setAttribute("download", `ExpoList.${format}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error(response.data.message);
        setDownLoading(false);
      }
    } catch (err) {
      setDownLoading(false);
      console.error("Error downloading the file", err);
    }
  };

  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [selectedFormat, setSelectedFormat] = useState("");
  // const toggleDropdown = () => setDropdownOpen(!dropdownOpen);


  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage));
    HandleClickRowperpage(newRowsPerPage)
  }
  const HandleClickRowperpage = async (newRowsPerPage) => {
    setLoading(true);
    const postForm = {
      // page: selected + 1,
      page: Number(currentPage + 1),
      limit: Number(newRowsPerPage),
      // search: inputSearch,
    };

    if (dateRange[0] && dateRange[1]) {
      postForm.startDate = formatDate(dateRange[0]);
      postForm.endDate = formatDate(dateRange[1]);
    }

    const queryString = new URLSearchParams(postForm).toString();
    try {
      if (user === undefined) {
        const response = await postData(`${Apiservices.ExpoUsersList}?${queryString}`);

        if (response.status) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
        } else {
          setLoading(false);
        }
      } else {
        const response = await postData(`${user}?${queryString}`);

        if (response.status) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      }
    } catch (error) {

      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }

  };
  const exportOptions = [
    { label: "PDF", value: "pdf" },
    { label: "CSV", value: "csv" },
    { label: "XLSX", value: "xlsx" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Expo" breadcrumbItem="Expo Users" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col sm={4} lg={3}>
                      <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                            {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4} lg={3}>
                      <div className="form-group mb-4">
                        <div>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select Start to End Date"
                              options={{
                                mode: "range",
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                                maxDate: today,
                              }}
                              value={dateRange}
                              onChange={handleDateChange}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Col>
                    <div className="col-3">
                      <select
                        onChange={(e) => handleSelect(e.target.value, 'interest')}
                        className="form-select"
                        id="autoSizingSelect"
                        value={selectedintrested || 'Select OS Type'}
                      >
                        <option value='overall'>
                          {'Select Intrest Type'}
                        </option>
                        {interest.map((data,index)=>(
                          <option value={data}>{data}</option>

                        ))}
                     
                      </select>
                    </div>

                    <div className="col-3">
                      <select
                        onChange={(e) => handleSelect(e.target.value, 'os')}
                        className="form-select"
                        id="autoSizingSelect"
                        value={selectedOsType || 'Select OS Type'}
                      >
                        <option>
                          {osTypeMapping[selectedOsType] || 'Select OS Type'}
                        </option>
                        <option value={Boolian.OSWEB}>Web</option>
                        <option value={Boolian.OSANDROID}>Android</option>
                        <option value={Boolian.OSIOS}>IOS</option>
                      </select>
                    </div>

                    <div className="col-4">
                      <select
                        onChange={(e) => handleSelect(e.target.value, 'country')}
                        className="form-select"
                        id="autoSizingSelect"
                        value={selectedCountry || 'Select Country'}
                      >
                        <option value={selectedCountry || 'Select Country'} disabled>
                          {selectedCountry || 'Select Country'}
                        </option>
                        {country.map((data) => (
                          <option key={data.name} value={data.name}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        {/* <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                          <DropdownToggle tag="button" className="btn btn-info">
                            {downLoading ? <Loader /> : selectedFormat || "Download"}
                            <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem header>Select Format</DropdownItem>
                            {exportOptions.map((option, index) => (
                              <DropdownItem
                                key={index}
                                onClick={() => handleDownload(option.value)}
                                disabled={downLoading} // Disable options while loading
                              >
                                {option.label}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown> */}
                        <Button
                          color="info"
                          className="add-btn mx-2"
                          onClick={() => handleDownload('xlsx')}
                          id="create-btn"
                          disabled={downLoading}
                        >
                          <i className="mdi mdi-download-outline"></i>

                          {downLoading ? <Loader /> : "Download"}
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={formData}
                      paginationServer
                      paginationTotalRows={formData.length}
                      progressPending={loading}
                      persistTableHead={true}
                      progressComponent={<div className="py-3">Loading...</div>}
                      noDataComponent={<div className="py-3">No records found</div>}
                      fixedHeader={true}
                      paginationPerPage={rowsPerPage} // Add this line to control rows per page
                    />
                  </div>
                  {/* <div className="row mt-3">
                <div className="col-12 col-md-6">
                  <div className="d-flex justify-content-start align-items-center mt-3">
                    <span>Current Count: {count}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  {loading ? (
                    <div className="d-flex justify-content-end mt-3">
                      <span>Loading...</span>
                    </div>
                  ) : (
                    <div className="d-flex flex-column flex-md-row justify-content-md-end align-items-center mt-3">
                    <div className="d-flex align-items-center mb-3 mb-md-0">
                      <span className="me-2">Rows per page:</span>
                      <select
                        value={rowsPerPage}
                        onChange={(e) => handleRowsPerPageChange(e.target.value)}
                        className="form-control-sm"
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                      </select>
                    </div>
                    <div className="d-flex align-items-center mb-3 mb-md-0">
                      <span>{count}</span>
                    </div>
                    <div className="pagination pagination-rounded mb-0 ms-md-3">
                      <ReactPaginate
                        previousLabel={<i className="mdi mdi-chevron-left"></i>}
                        nextLabel={<i className="mdi mdi-chevron-right"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        pageLinkClassName={"page-link"}
                        breakLinkClassName={"break-link"}
                        disabledClassName={"disabled"}
                        initialPage={currentPage}
                        disableInitialCallback={true}
                        forcePage={currentPage}
                        renderLink={(props) => {
                          if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                            return (
                              <a
                                {...props}
                                onClick={() =>
                                  handlePageClick({
                                    selected: pageCount - 1,
                                  })
                                }
                              >
                                {pageCount}
                              </a>
                            );
                          }
                          return <a {...props} />;
                        }}
                      />
                    </div>
                  </div>
                  
                  )}
                </div>
              </div> */}

                  <div className="container">
                    <div className="row justify-content-end mt-4">
                      <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                        <div className="d-flex align-items-center me-3">
                          <span className="me-2">Rows per page:</span>
                          <select
                            value={rowsPerPage}
                            onChange={(e) => handleRowsPerPageChange(e.target.value)}
                            className="form-control-sm"
                          >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                          </select>
                        </div>
                        <div className="me-md-3 mb-3 mb-md-0">
                          <span>{count}</span>
                        </div>
                        <div className="pagination pagination-rounded mt-3">
                          <ReactPaginate
                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            breakLinkClassName={"break-link"}
                            disabledClassName={"disabled"}
                            initialPage={currentPage}
                            disableInitialCallback={true}
                            forcePage={currentPage}
                            renderLink={(props) => {
                              if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                return (
                                  <a
                                    {...props}
                                    onClick={() =>
                                      handlePageClick({
                                        selected: pageCount - 1,
                                      })
                                    }
                                  >
                                    {pageCount}
                                  </a>
                                );
                              }
                              return <a {...props} />;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>



                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ExpoList;

import React, { useState, useEffect,useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback,CardImg, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import axiosInstance from "../../ApiService/AxiosInstance";
import { AdminContext } from "../../helpers/adminContext";


const AcademyList = () => {
  const [loading, setLoading] = useState(false);
  const [getSessionList, setSessionList] = useState([]);
  const [getId, setId] = useState("");
  const token = Cookies.get('authentication');
  document.title = "Ultrapro | Academy";
  
  const [sessionLink, setSessionLink] = useState('');
  const [errors, setErrors] = useState('');
  const [touched, setTouched] = useState(false);
  const [getImage,setGetImage] = useState('')
  const [showDetails, setShowDetails] = useState({})
  const [showModel, setShowModel] = useState(false)
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');

  //Permission List 
  const { adminInfo } = useContext(AdminContext);
  const [adminType,setAdminType] = useState('')

  //Add Modal
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
    formik.resetForm();
    setImage('')
  }

  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
    setImage('')
  }


  //classList api call
  useEffect(() => {
    fetchData();
    setAdminType(adminInfo?.adminType)
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.SessionList);
      if (response) {
        setLoading(false);
        setTotalPagination(response.data.totalPages);
        setSessionList(response.data.data);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
        setCurrentPage(0); 
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };


const handleShowModel = (value) => {
    setShowDetails(value)
    setShowModel(true)
}
const handleDelete = (value) => {
  showAlert(confirmDelete, value)
}

const showAlert = (onConfirm, row) => {
  Swal.fire({
      title: 'Are you sure?',
      text: `You want to remove this record!`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
          try {
              await onConfirm(row);
          } catch (error) {
              Swal.fire('Error!', 'There was an error deleting the record', 'error');
          }
      }
  }).then((result) => {
      if (result.isConfirmed) {
          // Additional actions after confirmation, if needed
      }
  });
};

const confirmDelete = async (row) => {
  const postForm = {
    sessionId : row.sessionId
  }
  try {
      const response = await postData(Apiservices.DeleteSession,postForm )
      if (response.status) {
          Swal.fire('Success!', `${response.message}`, 'success');
          fetchData()

      } else {
          Swal.fire('Error!', `${response.message}`, 'error');
      }
  } catch (error) {
      toast.error("Internal Server Error..Try Again Later")
  }
}

const allowedActions = [1, 2, 3, 4];

const columns = [
  {
    name: "S.No",
    // selector: (row, index) => currentPage * itemsPerPage + index + 1,
    selector: (row) => handleNullValue(row.serialNo),

    sortable: true,
    minWidth: '80px',
    maxWidth: '100px'
  },
  {
    name: "Session Image",
    selector: (row) => <img style={{ width: "100px" }} src={row.sessionImage || '-'} alt='' />,
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  {
    name: "Session Category",
    selector: (row) => handleNullValue(row.sessioncategory),
    minWidth: '150px',
    maxWidth: '200px'
  },
  {
    name: "Session Name",
    selector: (row) => handleNullValue(row.sessionName),
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  {
    name: "Position",
    selector: (row) => handleNullValue(row.position),
    minWidth: '150px',
    maxWidth: '200px'
  },
  {
    name: "Payment Type",
    selector: (row) => handleNullValue(`${row.sessionPaymentType === 0 ? "Free" : "Paid"}`),
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  {
    name: "Video Status",
    selector: (row) => handleNullValue(`${row.videoHandler === 0 ? "Coming Soon" : "Live"}`),
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  
  ...(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Academy" && data.actions.includes(3)) ? [{
      name: "Status",
      selector: (row) => (
        <div className="form-check form-switch form-switch-md mb-3 d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            checked={row.sessionIsActive === 1}
            onChange={() => handleUpdateStatusClick(row)}
            disabled={loading}
          />
        </div>
      ),
      sortable: true,
      minWidth: '100px',
      maxWidth: '100px',
    }] : []),
  {
    name: "Date & Time",
    selector: (row) => row.createdAt ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${handleNullValue(utcToNormalTime(row.createdAt).time)}` : "---",
    sortable: true,
    minWidth: '200px',
    maxWidth: '250px'
  },
  ...(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Academy" && allowedActions.some(action => data.actions.includes(action))) ? [
  {
    name: "Action",
    cell: (row) => (
      <div className="d-flex">
        {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Academy" && data.actions.includes(3))) ? (
        <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-border-color cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>
    ): null}
    {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Academy" && data.actions.includes(2))) ? (
        <i style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline mx-1"></i>
    ): null }
    {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Academy" && data.actions.includes(4))) ? (
        <i onClick={() => handleDelete(row)} className="mdi mdi-trash-can-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></i>
    ): null }
      </div>
    ),
    ignoreRowClick: true,
    minWidth: '150px',
    maxWidth: '200px'
  }] : []),

];
  


  //paginnation
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; 
  const pageCount = Math.ceil(totalPagination);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };
  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: itemsPerPage,
    };
    try {
      const response = await postData(Apiservices.SessionList,postForm);
      if (response.status) {
        setSessionList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  
  const validateSessionLink = (value) => {
    let error = '';
    if (!value) {
      error = 'Session link is required';
    }
    return error;
  };



  //Add form validation
  const formik = useFormik({
    initialValues: {
      sessionname: "",
      paymenttype: "",
      videostatus: "",
      sessionIsActive:"",
      sessioncategory:'',
      description:'',
      position:''
    },
    validationSchema: Yup.object({
      sessionname: Yup.string().trim().required("Session Name is required"),
      paymenttype: Yup.string().trim().required("Payment Type is required"),
      sessionIsActive: Yup.string().trim().required("Status is required"),
      videostatus: Yup.string().trim().required("Video Status is required"),
      sessioncategory: Yup.string().trim().required("Session Category is required"),
      description: Yup.string().trim().transform((value) => value.trim()).required("Description is required"),
      position: Yup.string().trim()
      .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
      .required("Position is required"),
    }),
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  const handleChange = (e) => {
    const { value } = e.target;
    setSessionLink(value);
    const error = validateSessionLink(value);
    setErrors(error);
  };
  const handleBlur = () => {
    setTouched(true);
  };
//Addapicall
const handleAdd = async (data) => {
  setLoading(true);
  if (formik.values.videostatus === "1") {
    const error = validateSessionLink(sessionLink);
    if (error) {
      setErrors(error);
      setLoading(false);
      return;
    }
  }

  if (!image) {
    toast.error("Session image is required");
    setLoading(false);
    return;
  }
  // Validate session link before submission

  const formData = new FormData();
  formData.append("sessionName", data.sessionname);
  formData.append("sessionYoutubeLink", sessionLink);
  formData.append("sessionPaymentType", Number(data.paymenttype));
  formData.append("sessionIsActive", Number(data.sessionIsActive));
  formData.append("sessionId", "");
  formData.append("sessioncategory", data.sessioncategory);
  formData.append("sessionDescription", data.description);
  formData.append("position", Number(data.position));
  formData.append("videoHandler", Number(data.videostatus));
  formData.append("files", image);
  formData.append("adminType",adminInfo?.adminType);
  formData.append("permissions",adminInfo?.permission);

  try {
    const response = await axiosInstance.post(Apiservices.AddSession, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status) {
      toast.success(response.data.message);
      formik.resetForm();
      setmodal_list(false);
      setLoading(false);
      fetchData();
      setImage(null);
      setSessionList("");
      setErrors("");

      formik.setFieldValue("sessionimage", "");
    } else {
      toast.error(response.data.message);
      formik.resetForm();
      setLoading(false);
      fetchData();
      setImage(null);
      setSessionList("");
      setErrors("");
    }
  } catch (error) {
    setLoading(false);
    formik.resetForm();
    setLoading(false);
    setImage(null);
    setSessionList("");
    setErrors("");
  }
};


  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,
  
    initialValues: {
      sessionname: "",
      paymenttype: "",
      sessionIsActive: "",
      sessioncategory:"",
      description:'',
      position:'',
      videostatus:''
    },
    validationSchema: Yup.object({
      sessionname: Yup.string().trim().required("Session Name is required"),
      sessioncategory: Yup.string().trim().required("Session Category is required"),
      paymenttype: Yup.string().trim().required("Payment Type is required"),
      sessionIsActive: Yup.string().trim().required("Status is required"),
      description: Yup.string().trim().transform((value) => value.trim()).required("Description is required"),
      videostatus: Yup.string().trim().required("Video Status is required"),
      position: Yup.string().trim()
      .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
      .required("Position is required"),
     
      
    }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });
  

  const handleUpdate = (record) => {
    setId(record.sessionId);
    setGetImage(record.sessionImage)
    setSessionLink(record.sessionYoutubeLink)
    validationUpdate.setValues({
      sessionId: getId || "",
      sessionname: record.sessionName || "",
      paymenttype: record.sessionPaymentType !== undefined ? String(record.sessionPaymentType) : "",
      sessionIsActive: record.sessionIsActive !== undefined ? String(record.sessionIsActive) : "",
      videostatus: record.videoHandler !== undefined ? String(record.videoHandler) : "",
      sessionimage: record.sessionImage || "",
      sessioncategory:record.sessioncategory || "",
      description:record.sessionDescription || "",
      position:record.position || "",
      
    });
   
    setmodal_update_list(true);
  };
  
 
  const confirmUpdate = async (values) => {
    setLoading(true);
  
    // Image validation
    if (!image && !getImage) {
      toast.error("Session image is required");
      setLoading(false);
      return;
    }
    if (validationUpdate.values.videostatus === "1") {
      const error = validateSessionLink(sessionLink);
      if (error) {
        setErrors(error);
        setLoading(false);
        return;
      }
    }
  
    const updateformData = new FormData();
    updateformData.append("sessionName", values.sessionname);
    updateformData.append("sessionYoutubeLink", sessionLink);
    updateformData.append("sessionPaymentType", Number(values.paymenttype));
    updateformData.append("sessionIsActive", Number(values.sessionIsActive));
    updateformData.append("videoHandler", Number(values.videostatus));
    updateformData.append("sessionId", getId);
    updateformData.append("sessioncategory", values.sessioncategory);
    updateformData.append("sessionDescription", values.description);
    updateformData.append("position", values.position);
  
    // Image handling
    if (image) {
      updateformData.append("files", image);
    } else if (getImage) {
      updateformData.append("files", getImage);
    }
  
    try {
      const response = await axiosInstance.post(Apiservices.UpdateSession, updateformData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response.status === 200) {
        setmodal_update_list(false);
        toast.success(response.data.message);
        fetchData();
        setSessionList("");
      } else {
        toast.error(response.data.message);
        fetchData();
        setSessionList("");
        setErrors("");
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      validationUpdate.resetForm();
      setErrors("");
    } finally {
      setLoading(false);
    }
  };
  
  
  //Searchfun
  const [inputSearch, setinputSearch] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "") {
      fetchData();
      setSearchValid(false);
    } else {
      setinputSearch(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
      };

      try {
      const response = await postData(Apiservices.SessionList,postForm);

        if (response.status) {
          setSearchLoad(false);
          setSessionList(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
     
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };

//status toggle update call
const showStatusAlert = (onConfirm, row) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You want to update status!",
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Update it!',
    showLoaderOnConfirm: true, 
    preConfirm: async (reason) => {
      if (!reason) {
          Swal.showValidationMessage('Reason is required');
      } else {
          Swal.showLoading();
          try {
              await onConfirm({ ...row, reason });
              Swal.fire('Success!', 'The status has been updated successfully', 'success');
          } catch (error) {
              Swal.fire('Error!', 'There was an error updating the status', 'error');
          }
      }
  },
  allowOutsideClick: () => !Swal.isLoading(), 
  }).then((result) => {
    if (result.isConfirmed) {
    }
  });
};
const handleUpdateStatusClick = (row) => {
  showStatusAlert(handleToggle1, row);
}

const handleToggle1 = async (rowDetails) => {
  const updatedStatus = rowDetails.sessionIsActive === 1 ? 0 : 1;
  try {
    const statusUpdate = new FormData();
    statusUpdate.append("sessionName", rowDetails.sessionName);
    statusUpdate.append("sessionYoutubeLink", rowDetails.sessionYoutubeLink);
    statusUpdate.append("sessionPaymentType", Number(rowDetails.sessionPaymentType));
    statusUpdate.append("sessionIsActive", updatedStatus);
    statusUpdate.append("videostatus", rowDetails.videoHandler);
    statusUpdate.append("position", rowDetails.position);
    statusUpdate.append("sessionId", rowDetails.sessionId);
    statusUpdate.append("files", rowDetails.sessionImage);
    statusUpdate.append("description", rowDetails.description);

    const response = await axiosInstance.post(Apiservices.UpdateSession, statusUpdate, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 200) {
      toast.dismiss();
      fetchData();
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    toast.dismiss();
    toast.error("Internal Server Error..Try Again Later");
  }
};

//rowsperpage
const [rowsPerPage, setRowsPerPage] = useState(10);
const [count, setCount] = useState("");

const handleRowsPerPageChange = (newRowsPerPage) => {
  setRowsPerPage(parseInt(newRowsPerPage));
  HandleClickRowperpage(newRowsPerPage)
}
const HandleClickRowperpage = async(newRowsPerPage) => {
  setLoading(true);
  const postForm = {
    page:1,
    limit:Number(newRowsPerPage) ,
  };


  try {
    if (getSessionList === undefined) {
      const response = await postData(Apiservices.SessionList,postForm);

      if (response.status) {
        setLoading(false);
        setSessionList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } else {
      const response = await postData(Apiservices.SessionList,postForm);


      if (response.status) {
        setLoading(false);
        setSessionList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    }
  } catch (error) {
   
    toast.error("Internal Server Error..Try Again Later");
    setLoading(false);
  }

};
// const validateImage = (file) => {
//   // Validate file type and size here (e.g., PNG, JPG, JPEG)
//   const validTypes = ['image/png', 'image/jpeg'];
//   return validTypes.includes(file.type) && file.size <= 5000000; // 5MB max size
// };

const handleImageChange = (e) => {
  const file = e.target.files[0];
  setImage(file)
  // if (file) {
  //   if (validateImage(file)) {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       const img = new Image();
  //       img.src = event.target.result;
  //       img.onload = () => {
  //         const { width, height } = img;
  //         if (validateResolution(width, height)) {
  //           setImage(file);
  //           setError('');
  //         } else {
  //           setError('Image resolution must be at least 800x600 pixels.');
  //           setImage(null);
  //         }
  //       };
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setError('Invalid file type or size.');
  //     setImage(null);
  //   }
  // }
};

// const validateResolution = (width, height) => {
//   // Minimum resolution required: 800x600 pixels
//   return width >= 800 && height >= 600;
// };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Academy" breadcrumbItem="Academy List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4">
                    <Col className="col-sm-auto">
                    <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => handleSearch()}
                            disabled={searchLoad}
                          >
                            {searchLoad ? (<Spinner className="text-light" size="sm" /> ) : (<i className="ri-search-line" /> )}
                          </button>
                        </div>
                      </div>
                   
                    </Col>
                    {/* Add Modal Button */}
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Academy" && data.actions.includes(1))) ? (
                        <Button
                          color="success"
                          className="add-btn"
                          onClick={() => tog_list()}
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                        </Button>
                      ): null }
                      </div>
                    </Col>
                  </Row>
                 
                <div className="table-responsive  mt-2">
                <DataTable
                  columns={columns}
                  data={getSessionList}
                  paginationServer
                  paginationTotalRows={getSessionList.length}
                  progressPending={loading}
                  persistTableHead={true}
                  progressComponent={<div className="py-3">Loading...</div>}
                  // noDataComponent={formData.length===0}
                  noDataComponent={<div className="py-3">No records found</div>}
                  fixedHeader={true}
                  
                />
                </div>
   
<div className="container">
  <div className="row justify-content-end mt-4">
  <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
      <div className="d-flex align-items-center me-3">
        <span className="me-2">Rows per page:</span>
        <select
          value={rowsPerPage}
          onChange={(e) => handleRowsPerPageChange(e.target.value)}
          className="form-control-sm"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>
      <div className="me-md-3 mb-3 mb-md-0">
        <span>{count}</span>
      </div>
      <div className="pagination pagination-rounded mt-3">
        <ReactPaginate
          previousLabel={<i className="mdi mdi-chevron-left"></i>}
          nextLabel={<i className="mdi mdi-chevron-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageLinkClassName={"page-link"}
          breakLinkClassName={"break-link"}
          disabledClassName={"disabled"}
          initialPage={currentPage}
          disableInitialCallback={true}
          forcePage={currentPage}
          renderLink={(props) => {
            if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
              return (
                <a
                  {...props}
                  onClick={() =>
                    handlePageClick({
                      selected: pageCount - 1,
                    })
                  }
                >
                  {pageCount}
                </a>
              );
            }
            return <a {...props} />;
          }}
        />
      </div>
    </div>
  </div>
</div>
  

  {/* // ADDMODAL  */}
  <Modal className="modal-dialog modal-lg" isOpen={modal_list}  toggle={() => { tog_list(); }}centered>
  <ModalHeader  className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list();}}> Add Academy</ModalHeader>
  <Form autoComplete="off" className="form-horizontal" 
  onSubmit={(e) => { e.preventDefault();   formik.handleSubmit();  return false; }}>
  <ModalBody>
  <Row>
    <Col md={6}>
    <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Session Category
        </label>

        <Input
          type="text"
          id="sessioncategory-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Session Category"
          name="sessioncategory"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.sessioncategory || ""}
          invalid={formik.touched.sessioncategory && formik.errors.sessioncategory}
        />
        {formik.touched.sessioncategory && formik.errors.sessioncategory && <FormFeedback type="invalid">{formik.errors.sessioncategory}</FormFeedback>}
      </div>
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Session Name
        </label>

        <Input
          type="text"
          id="sessionname-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Session Name"
          name="sessionname"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.sessionname || ""}
          invalid={formik.touched.sessionname && formik.errors.sessionname}
        />
        {formik.touched.sessionname && formik.errors.sessionname && <FormFeedback type="invalid">{formik.errors.sessionname}</FormFeedback>}
      </div>
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Description
        </label>

        <Input
          type="textarea"
          id="description-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Description"
          name="description"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description || ""}
          invalid={formik.touched.description && formik.errors.description}
        />
        {formik.touched.description && formik.errors.description && <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>}
      </div>
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Session Image 
        </label>
        <div> 
            <input type="file" className="form-control"
                  accept=".png, .jpg, .jpeg"
                 onChange={handleImageChange} />
                </div>
                {error && <div className="text-danger" style={{ fontSize: '12px' }}>{error}</div>}

                {image ? 
                    <Card style={{ width: '200px'}}>
            <CardBody>
              <CardImg
                top
                src={image ? URL.createObjectURL(image) : ''}
                style={{ width: '100%', objectFit: 'cover' }}
              />
            </CardBody>
          </Card> : null}
      </div>


 

      
    </Col>
    <Col md={6}>
    
<div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Position
        </label>
        <Input
          type="number"
           min="1"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Position"
          name="position"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.position || ""}
          invalid={formik.touched.position && formik.errors.position}
        />
        {formik.touched.position && formik.errors.position && <FormFeedback type="invalid">{formik.errors.position}</FormFeedback>}
      </div>
      
    
      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
          Payment Type{" "}
        </label>
        <select
          id="status-field"
          name="paymenttype"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.paymenttype || ""}
          className={`form-control ${formik.touched.paymenttype && formik.errors.paymenttype ? "is-invalid" : ""}`}
        >
          <option value="" selected disabled>
            Select Payment Type
          </option>
          <option value={1}>Paid</option>
          <option value={0}>Free</option>
        </select>
        {formik.touched.paymenttype && formik.errors.paymenttype && <FormFeedback type="invalid"> {formik.errors.paymenttype} </FormFeedback>}
      </div>

      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
         Video Status{" "}
        </label>
        <select
          id="status-field"
          name="videostatus"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.videostatus || ""}
          className={`form-control ${formik.touched.videostatus && formik.errors.videostatus ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Video Status
          </option>
          <option value={0}>Coming Soon</option>
          <option value={1}>Live</option>
        </select>
        {formik.touched.videostatus && formik.errors.videostatus && <FormFeedback type="invalid"> {formik.errors.videostatus} </FormFeedback>}
      </div>

      
      {formik.values.videostatus === '1' && (
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Session Link
        </label>
        <Input
          type="text"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Session Link"
          name="sessionlink"
          value={sessionLink}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={touched && !!errors}
         
        />
        {touched && errors && <FormFeedback>{errors}</FormFeedback>}
      </div>)}

      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
          Status
        </label>
        <select
          id="status-field"
          name="sessionIsActive"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.sessionIsActive || ""}
          className={`form-control ${formik.touched.sessionIsActive && formik.errors.sessionIsActive ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Status
          </option>
          <option value={1}>Active</option>
          <option value={0}>Deactive</option>
        </select>
        {formik.touched.sessionIsActive && formik.errors.sessionIsActive && <FormFeedback type="invalid"> {formik.errors.sessionIsActive} </FormFeedback>}
      </div>
    </Col>
  </Row>
</ModalBody>

    <ModalFooter>
      <div className="hstack gap-2 justify-content-end">
        <button type="button" className="btn btn-light" onClick={() => {setmodal_list(false); formik.resetForm();setImage('')}}>
          Close
        </button>

        <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
          {loading ? <Loader /> : "Submit"}
        </button>
      </div>
    </ModalFooter>
  </Form>
</Modal>


     {/* UpdateModal */}
  <Modal className="modal-dialog modal-lg" isOpen={modal_update_list}  toggle={() => { tog_update_list();}} centered>
  <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>
    Update Academy
  </ModalHeader>
  <Form autoComplete="off" className="form-horizontal"
    onSubmit={(e) => {
      e.preventDefault();
      validationUpdate.handleSubmit();
      return false;
    }}
  >
    <ModalBody>
      <Row>
        <Col md={6}>
        <div className="mb-3">
        <label htmlFor="title-field" className="form-label"> Session Category</label>
        <Input
          type="text"
          id="sessioncategory-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Session Category"
          name="sessioncategory"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.sessioncategory || ""}
          invalid={validationUpdate.touched.sessioncategory && validationUpdate.errors.sessioncategory}
        />
        {validationUpdate.touched.sessioncategory && validationUpdate.errors.sessioncategory && <FormFeedback type="invalid">{validationUpdate.errors.sessioncategory}</FormFeedback>}
      </div>
        <div className="mb-3">
        <label htmlFor="title-field" className="form-label"> Session Name</label>
        <Input
          type="text"
          id="sessionname-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Session Name"
          name="sessionname"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.sessionname || ""}
          invalid={validationUpdate.touched.sessionname && validationUpdate.errors.sessionname}
        />
        {validationUpdate.touched.sessionname && validationUpdate.errors.sessionname && <FormFeedback type="invalid">{validationUpdate.errors.sessionname}</FormFeedback>}
      </div>
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Description
        </label>

        <Input
          type="textarea"
          id="description-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Description"
          name="description"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.description || ""}
          invalid={validationUpdate.touched.description && validationUpdate.errors.description}
        />
        {validationUpdate.touched.description && validationUpdate.errors.description && <FormFeedback type="invalid">{validationUpdate.errors.description}</FormFeedback>}
      </div>
      <div className="mb-3">
      <label htmlFor="title-field" className="form-label">
          Session Image 
        </label>
        <div>
        <input
          type="file"
          className="form-control"
          accept=".png, .jpg, .jpeg"
          onChange={handleImageChange}
          placeholder="No file chosen"
        />
      </div>
      {error && <div className="text-danger" style={{ fontSize: '15px' }}>{error}</div>}

      {/* Image Preview Card */}
      {(getImage || image) && (
        <Card style={{ width: '200px' }}>
          <CardBody>
            <CardImg
              top
              src={image ? URL.createObjectURL(image) : getImage}
              style={{ width: '100%', objectFit: 'cover' }}
            />
          </CardBody>
        </Card>
      )}
       
      </div>
    
     
     
        </Col>
        <Col md={6}>
      
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Position
        </label>
        <Input
          type="number"
          id="subject-field"
          className="form-control"
          autoComplete="off"
           min="1"
          placeholder="Enter Position"
          name="position"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.position || ""}
          invalid={validationUpdate.touched.position && validationUpdate.errors.position}
        />
        {validationUpdate.touched.position && validationUpdate.errors.position && <FormFeedback type="invalid">{validationUpdate.errors.position}</FormFeedback>}
      </div>
    
      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
          Payment Type{" "}
        </label>
        <select
          id="status-field"
          name="paymenttype"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.paymenttype || ""}
          className={`form-control ${validationUpdate.touched.paymenttype && validationUpdate.errors.paymenttype ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Payment Type
          </option>
          <option value={1}>Paid</option>
          <option value={0}>Free</option>
        </select>
        {validationUpdate.touched.paymenttype && validationUpdate.errors.paymenttype && <FormFeedback type="invalid"> {validationUpdate.errors.paymenttype} </FormFeedback>}
      </div>
      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
         Video Status{" "}
        </label>
        <select
          id="status-field"
          name="videostatus"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.videostatus || ""}
          className={`form-control ${validationUpdate.touched.videostatus && validationUpdate.errors.videostatus ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Video Status
          </option>
          <option value={0}>Coming Soon</option>
          <option value={1}>Live</option>
        </select>
        {validationUpdate.touched.videostatus && validationUpdate.errors.videostatus && <FormFeedback type="invalid"> {validationUpdate.errors.videostatus} </FormFeedback>}
      </div>
      {validationUpdate.values.videostatus === '1' && (
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Session Link
        </label>
        <Input
          type="text"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Session Link"
          name="sessionlink"
          value={sessionLink}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={touched && !!errors}
         
        />

        {touched && errors && <FormFeedback>{errors}</FormFeedback>}
      </div>)}
      <div className="mb-3">
        <label htmlFor="status-field" className="form-label"> Status </label>
        <select
          id="status-field"
          name="sessionIsActive"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.sessionIsActive || ""}
          className={`form-control ${validationUpdate.touched.sessionIsActive && validationUpdate.errors.sessionIsActive ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Status
          </option>
          <option value={1}>Active</option>
          <option value={0}>Deactive</option>
        </select>
        {validationUpdate.touched.sessionIsActive && validationUpdate.errors.sessionIsActive && <FormFeedback type="invalid"> {validationUpdate.errors.sessionIsActive} </FormFeedback>}
      </div>
        </Col>
      </Row>
  
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-light" onClick={() => {setmodal_update_list(false);setImage('')}}>
              Close
            </button>

            <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
              {loading ? <Loader /> : "Submit"}
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>



{/* View Modal    */}
<Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static" >

<div className="modal-header">
    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
       Academy Details 
    </h5>
    <button
        onClick={() => { setShowModel(false); }}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div className="modal-body">

    <div className="table-responsive">
        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td><b>Session category</b></td>
                    <td>{showDetails?.sessioncategory || '-'}</td>
                </tr>
                <tr>
                    <td><b>Session ID</b></td>
                    <td>{showDetails?.sessionId || '-'}</td>
                </tr>
                <tr>
                    <td><b>Session Name</b></td>
                    <td>{showDetails?.sessionName || '-'}</td>
                </tr>
                <tr>
                    <td><b>Position</b></td>
                    <td>{showDetails?.position || '-'}</td>
                </tr>
                <tr>
                    <td><b>Video Status</b></td>
                    <td>
                  {showDetails.videoHandler === 0 ? "Coming Soon" : "Live" || '-'}

                    </td>
                </tr>
                <tr>
                    <td><b>Session Link</b></td>
                    <td>{showDetails.videoHandler === 1 ? showDetails.sessionYoutubeLink : '-'}

                    </td>
                </tr>
                <tr>
                    <td><b>Description</b></td>
                    <td>{showDetails.sessionDescription || '-'}</td>
                </tr>
                <tr>
                    <td><b>Status</b></td>
                    <td>{showDetails.sessionIsActive == 1 ? 'Active' : 'Deactive' || '-'}</td>
                </tr>
                <tr>
                    <td><b>Payment Type</b></td>
                    <td>{showDetails.sessionPaymentType == 1 ? 'Paid' : 'Free' || '-'}</td>
                </tr>
                <tr>

                    <td><b>Date & Time</b></td>
                    <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                </tr>
                <tr>
                    <td><b>Session Image</b></td>
                    <td>
                      <Card style={{ width: '200px'}}>
                      <CardBody>
                        <CardImg
                          top
                          src={showDetails.sessionImage || '-'}
                          style={{ width: '100%', objectFit: 'cover' }}
                        />
                      </CardBody>
                    </Card>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>

</Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AcademyList;

import React, { useState, useEffect, useContext } from "react";
import { Card, CardBody,  Col, Container, Row,  } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Apiservices, Boolian } from "../../ApiService/ApiServices";
import { postData, postGetData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import { handleNullValue } from "../../helpers/function";
import Swal from "sweetalert2";
import { AdminContext } from "../../helpers/adminContext";

const WalletType = () => {
  const [loading, setLoading] = useState(false);
  const [getWalletTypeList, setWalletTypeList] = useState([]);
  document.title = "Ultrapro | Wallet Types";

  const [fetchLoading,setFetchLoading] = useState(false);

  //Permission List
  const { adminInfo } = useContext(AdminContext);
  const [adminType,setAdminType] = useState('');

  //Add Modal
  // function tog_list() {
  //   setmodal_list(!modal_list);
  //   formik.resetForm();
  
  // }
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
 
  }
  //classList api call
  useEffect(() => {
    fetchData();
    setAdminType(adminInfo?.adminType);
  }, []);

  const fetchData = async () => {
    try {
      setFetchLoading(true);
      const response = await postGetData(Apiservices.WALLET_TYPE_LIST);
      
      if (response.status === true) {
        setFetchLoading(false);
        setTotalPagination(response.data.totalPages);
        setWalletTypeList(response.data.data);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
       

        setCurrentPage(0); 
      } else {
        setFetchLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setFetchLoading(false);
      // Handle fetch error
    }
  };


 
// const handleDelete = (value) => {
//   setRecordToDelete(value);
//   // setmodal_delete(true)
//   showUserBlockAlert(confirmDelete, value)
// }

// const showUserBlockAlert = (onConfirm, row) => {
//   Swal.fire({
//       title: 'Are you sure?',
//       text: `You want to remove this record!`,
//       icon: 'info',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, Delete it!',
//       showLoaderOnConfirm: true,
//       allowOutsideClick: () => !Swal.isLoading(),
//       preConfirm: async () => {
//           try {
//               await onConfirm(row);
//           } catch (error) {
//               Swal.fire('Error!', 'There was an error deleting the record', 'error');
//           }
//       }
//   }).then((result) => {
//       if (result.isConfirmed) {
//           // Additional actions after confirmation, if needed
//       }
//   });
// };

// const confirmDelete = async (row) => {
//   const postForm = {
//     menuId : row.menuId
//   }
 
//   setDeleteLoad(true)
//   try {
//       const response = await postData(Apiservices.MenuDelete, postForm )

//       if (response.status === true) {
//           setDeleteLoad(false)
//           // toast.success(response.message)
//           Swal.fire('Success!', `${response.message}`, 'success');
//           fetchData()

//       } else {
//           // toast.error(response.message)
//           Swal.fire('Error!', `${response.message}`, 'error');
//           setDeleteLoad(false)

//       }

//   } catch (error) {
  

//       setDeleteLoad(false)
//       toast.error("Internal Server Error..Try Again Later")
//   }
// }

const walletTypeVal = (walletType) => {
    switch (walletType) {
      case 0:
        return 'manual'
      case 1:
        return 'Automatic'
    
      default:
        break;
    }
}
const getBadgeKYCClass = (code) => {
  switch (code) {
      case Boolian.ENABLE:
          return "badge-soft-success";
      case Boolian.DISABLE:
          return "badge-soft-danger";
      default:
          return "";
  }
};
const handleUpdateStatusClick = (row) => {
  showStatusAlert(handleToggle1, row);
};
const columns = [
  {
    name: "S.No",
    // selector: (row, index) => currentPage * itemsPerPage + index + 1,
    selector: (row, index) => index + 1,
    grow:1,
    sortable: true,
  },
 
  {
    name: "Wallet Name",
    selector: (row) => handleNullValue(row.name),
    sortable: true,
  },
  ...(adminType === 1 || adminInfo.permissions.some(data => 
    data.module === "Wallet Types" && data.actions.includes(3)) ? [
  {
    name: 'Wallet Type',
    // selector: row => row.isActive ? 'Active' : 'Inactive',
    selector: row => (
        <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
            <input
                type="checkbox"
                className="form-check-input"
                id="customSwitchsizemd"
                checked={row.walletType === Boolian.WALLET_TYPE_MANUAL}
                // onChange={() => handleToggleModel(row, 'isIpActive')}
                // onChange={() =>handleToggleModel(row,Apiservices.ADMIN_BLOCK_UNBLOCK)}
                onChange={() => handleUpdateStatusClick(row)}
                disabled={loading}
            />

        </div>
    ),
    sortable: true,
    // minWidth: '80px',
    // maxWidth: '150px'
    // minWidth: '400px',
    // maxWidth: '450px'
}]:[]),
  {
    name: 'Wallet Type',
    selector: row => (
        <div className="edit">
            <span className={`badge ${getBadgeKYCClass(row.walletType)} me-1`}>{walletTypeVal(row.walletType)}</span>

        </div>
    ),
    ignoreRowClick: true,
    // minWidth: '80px',
    // maxWidth: '100px'   
    // minWidth: '400px',
    // maxWidth: '450px' 
},
  // {
  //   name: "Status",
  //   selector: (row) => (
  //     <div className="form-check form-switch form-switch-md mb-3 d-flex justify-content-center align-items-center">
  //       <input
  //         type="checkbox"
  //         className="form-check-input"
  //         id="customSwitchsizemd"
  //         checked={row.teamsIsActive === 1}
  //         onChange={() => handleUpdateStatusClick(row)}
  //         disabled={loading}
  //       />
  //     </div>
  //   ),
  //   sortable: true,
  
  // },
  // {
  //   name: "Created Date & Time",
  //   selector: (row) => row.createdAt ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${handleNullValue(utcToNormalTime(row.createdAt).time)}` : "---",
  //   sortable: true,
  //   minWidth: '200px',
  //   maxWidth: '250px'
  // },
  // {
  //   name: "Updated Date & Time",
  //   selector: (row) => row.updatedAt ? `${handleNullValue(utcToNormalTime(row.updatedAt).date)} ${handleNullValue(utcToNormalTime(row.updatedAt).time)}` : "---",
  //   sortable: true,
  //   minWidth: '200px',
  //   maxWidth: '250px'
  // },
  // {
  //   name: "Action",
  //   cell: (row) => (
  //     <div className="d-flex">
  //       <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-border-color cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>
  //       {/* <i onClick={() => handleDelete(row)} className="mdi mdi-trash-can-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></i> */}
  //     </div>
  //   ),
  //   ignoreRowClick: true,
  
  // },
];
  


  //paginnation
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);

  // const handlePageClick = ({ selected }) => {
  //   setCurrentPage(selected);
  //   handlePagePost(selected);
  // };
  // const handlePagePost = async (selected) => {
  //   setLoading(true);
  //   const postForm = {
  //     page: selected + 1,
  //     limit: itemsPerPage,
  //     // search: inputSearch,
  //   };
  //   const queryString = new URLSearchParams(postForm).toString();
  //   try {
  //     // const response = await postData(`${Apiservices.MenuList}?${queryString}`);
  //     const response = await postData(Apiservices.WALLET_TYPE_LIST, postForm);

  //     if (response.status) {
  //       setWalletTypeList(response.data.data);
  //       setTotalPagination(response.data.totalPages);
  //       setTotalLimit(response.data.limit);
  //       setCount(response.data.currentCount);

  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //   }
  // };
  
  // const validateSessionLink = (value) => {
  //   let error = '';
  //   if (!value) {
  //     error = 'Session link is required';
  //   }
  //   //  else if (!value.endsWith('.mp4')) {
  //   //   error = 'Session link must be an MP4 file';
  //   // }
  //   return error;
  // };
  //Add form validation
//   const formik = useFormik({
//     initialValues: {
//       walletType: "",

//     },
//     validationSchema: Yup.object({
//       walletType: Yup.string().trim().required("Wallet Type is required"),
//       }),
//     onSubmit: (values) => {
//       handleAdd(values);
//     },
//   });

//   const handleAdd = async (data) => {

//   setLoading(true);
//   const postForm = {
//     name:data.menuname
//   }
//   try {
//     const response = await postData(Apiservices.MenuAdd, postForm);
 
//     if (response.status === true) {
//       toast.success(response.message);
//       formik.resetForm();
//       setmodal_list(false);
//       setLoading(false);
//       fetchData();

//     } else {
//       toast.error(response.message);
//       setLoading(false);
//       fetchData();
//     }
//   } catch (error) {
//     toast.error(error.message);
//     setLoading(false);
//     setLoading(false);
//   }
// };


  //update form validation
  // const validationUpdate = useFormik({
  //   enableReinitialize: true,
  
  //   initialValues: {
  //     menuname: "",
  //   },
  //   validationSchema: Yup.object({
  //     menuname: Yup.string().trim().required("Name is required"),
  //     }),
  //   onSubmit: (values) => {
  //     confirmUpdate(values);
  //   },
  // });
  
  // const handleUpdate = (record) => {
  //   setId(record.menuId);
  //   setGetImage(record.teamsImage)
   
  //   validationUpdate.setValues({
  //     menuId: getId || "",
  //     menuname: record.name || "",
  //     // teamsIsActive: record.teamsIsActive !== undefined ? String(record.teamsIsActive) : "",
  //   });
   
  //   setmodal_update_list(true);
  // };
  
 
  // const confirmUpdate = async (values) => {
  //   setLoading(true);
  
  //   const postForm={
  //     name:values.menuname,
  //     menuId:getId
  //   }
  //   try {
  //     const response = await postData(Apiservices.MenuUpdate, postForm);
  //     if (response.status === true) {
      
  //       setmodal_update_list(false);
  //       toast.success(response.message);
  //       // fetchData();
  //       // handlePagePost(currentPage)

  //     } else {
     
  //       toast.error(response.message);
  //       fetchData();
        
  //     }
  //   } catch (error) {
     
  //     toast.error(error.message);
  //     validationUpdate.resetForm();

  //   } finally {
  //     setLoading(false);
  //   }
  // };
  
  //Searchfun
  // const [inputSearch, setinputSearch] = useState("");
  // const [searchValid, setSearchValid] = useState(false);
  // const [searchLoad, setSearchLoad] = useState(false);

  // const handleSearchChanage = (e) => {
  //   const value = e.target.value;
  //   if (value === "") {
  //     fetchData();
  //     setSearchValid(false);
  //   } else {
  //     setinputSearch(value);
  //   }
  // };
  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     handleSearch();
  //   }
  // };
  // const handleSearch = async () => {
  //   if (inputSearch !== "") {
  //     setSearchLoad(true);
  //     const postForm = {
  //       search: inputSearch,
  //     };

  //     const queryString = new URLSearchParams(postForm).toString();
  //     try {
  //       // const response = await postData(
  //       //   `${Apiservices.MenuList}?${queryString}`
  //       // );
  //       const response = await postData(Apiservices.MenuList,postForm);
  //       if (response.status) {
  //         setSearchLoad(false);
  //         setWalletTypeList(response.data.data);
  //         setTotalPagination(response.data.totalPages);
  //         setTotalLimit(response.data.limit);
  //         setCount(response.data.currentCount);

  //         // toast(response.message);
  //       } else {
  //         setSearchLoad(false);
  //       }
  //     } catch (error) {
     
  //       toast.error("Internal Server Error..Try Again Later");
  //       setSearchLoad(false);
  //     }
  //   } else {
  //     setSearchValid(true);
  //   }
  // };

const [isLoading,setIsLoading] = useState(false)

const showStatusAlert = (onConfirm, row) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You want to update status!",
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Update it!',
    showLoaderOnConfirm: true, 
    preConfirm: async (reason) => {
      if (!reason) {
          Swal.showValidationMessage('Reason is required');
      } else {
          Swal.showLoading();
          try {
              await onConfirm({ ...row, reason });
              Swal.fire('Success!', 'The status has been updated successfully', 'success');
          } catch (error) {
              Swal.fire('Error!', 'There was an error updating the status', 'error');
          }
      }
  },
  allowOutsideClick: () => !Swal.isLoading(), 
  // preConfirm: () => {
  //     return onConfirm(row) 
  //       .then(() => {
  //         return Promise.resolve();
  //       })
  //       .catch((error) => {
  //         Swal.showValidationMessage(`Request failed: ${error}`);
  //       });
  //   }
  }).then((result) => {
    if (result.isConfirmed) {
      // Success handling if needed
      // Swal.fire('Success !', 'The status has been updated successfully', 'success');
    }
  });
};

const handleToggle1 = async (rowDetails) => {
  try {
    setIsLoading(true)
    const postForm = {
      walletType: rowDetails?.walletType === 1 ? 0 : 1,
      walletId: rowDetails.walletId,
      // reason:reason
  };
    const response = await postData(Apiservices.WALLET_TYPE_UPDATED, postForm);
  console.log("response=============", response);
  
    if (response.status) {
      toast.dismiss();
      setIsLoading(false);
      // toast.success(response.message);
      // Swal.fire("Success !", "The status has been updated successfully", "success");

      fetchData();
      // handlePagePost(currentPage)

    } else {
      toast.error(response.message);
      setIsLoading(false);
    }
  } catch (error) {
    toast.dismiss();
    toast.error("Internal Server Error..Try Again Later");
    setIsLoading(false);
  }
};


// const toggleModal = () => {
//   setModal(!modal);
// };

const [rowsPerPage, setRowsPerPage] = useState(10);
const [count, setCount] = useState("");

// const handleRowsPerPageChange = (newRowsPerPage) => {
//   setRowsPerPage(parseInt(newRowsPerPage));
//   HandleClickRowperpage(newRowsPerPage)
// }
// const HandleClickRowperpage = async(newRowsPerPage) => {
//   setLoading(true);
//   const postForm = {
//     // page: selected + 1,
//     // page:Number(currentPage + 1),
//     page:1,
//     // limit:1 ,
//     limit:Number(newRowsPerPage) ,
//     // search: inputSearch,
//   };

//   // if (dateRange[0] && dateRange[1]) {
//   //   postForm.startDate = formatDate(dateRange[0]);
//   //   postForm.endDate = formatDate(dateRange[1]);
//   // }

//   const queryString = new URLSearchParams(postForm).toString();
//   try {
//     if (getWalletTypeList === undefined) {
//       // const response = await postData(`${Apiservices.MenuList}?${queryString}`);
//       const response = await postData(Apiservices.MenuList,postForm);

//       if (response.status) {
//         setLoading(false);
//         setWalletTypeList(response.data.data);
//         setTotalPagination(response.data.totalPages);
//         setTotalLimit(response.data.limit);
//         setCount(response.data.currentCount);
//       } else {
//         setLoading(false);
//       }
//     } else {
//       // const response = await postData(`${Apiservices.MenuList}?${queryString}`);
//       const response = await postData(Apiservices.MenuList,postForm);


//       if (response.status) {
//         setLoading(false);
//         setWalletTypeList(response.data.data);
//         setTotalPagination(response.data.totalPages);
//         setTotalLimit(response.data.limit);
//         setCount(response.data.currentCount);
//       } else {
//         setLoading(false);
//         toast.error(response.message);
//       }
//     }
//   } catch (error) {
   
//     toast.error("Internal Server Error..Try Again Later");
//     setLoading(false);
//   }

// };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Admin Management" breadcrumbItem="Wallet Types" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4">
                    <Col className="col-sm-auto">
                    {/* <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => handleSearch()}
                            disabled={searchLoad}
                          >
                            {searchLoad ? (<Spinner className="text-light" size="sm" /> ) : (<i className="ri-search-line" /> )}
                          </button>
                        </div>
                      </div>
                    */}
                    </Col>
                    {/* Add Modal Button */}
                    {/* <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <Button
                          color="success"
                          className="add-btn"
                          onClick={() => tog_list()}
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                        </Button>
                      </div>
                    </Col> */}
                  </Row>
                 
    <div className="table-responsive  mt-2">
    <DataTable
      columns={columns}
      data={getWalletTypeList}
      paginationServer
      paginationTotalRows={getWalletTypeList.length}
      progressPending={fetchLoading}
      persistTableHead={true}
      progressComponent={<div className="py-3">Loading...</div>}
      // noDataComponent={formData.length===0}
      noDataComponent={<div className="py-3">No records found</div>}
      fixedHeader={true}
      
    />
    </div>
   

{/* <div className="container">
  <div className="row justify-content-end mt-4">
  <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
      <div className="d-flex align-items-center me-3">
        <span className="me-2">Rows per page:</span>
        <select
          value={rowsPerPage}
          onChange={(e) => handleRowsPerPageChange(e.target.value)}
          className="form-control-sm"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>
      <div className="me-md-3 mb-3 mb-md-0">
        <span>{count}</span>
      </div>
      <div className="pagination pagination-rounded mt-3">
        <ReactPaginate
          previousLabel={<i className="mdi mdi-chevron-left"></i>}
          nextLabel={<i className="mdi mdi-chevron-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageLinkClassName={"page-link"}
          breakLinkClassName={"break-link"}
          disabledClassName={"disabled"}
          initialPage={currentPage}
          disableInitialCallback={true}
          forcePage={currentPage}
          renderLink={(props) => {
            if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
              return (
                <a
                  {...props}
                  onClick={() =>
                    handlePageClick({
                      selected: pageCount - 1,
                    })
                  }
                >
                  {pageCount}
                </a>
              );
            }
            return <a {...props} />;
          }}
        />
      </div>
    </div>
  </div>
</div> */}
  

  {/* // ADDMODAL  */}
  {/* <Modal className="modal-dialog" isOpen={modal_list}  toggle={() => { tog_list(); }}centered>
  <ModalHeader  className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list();}}> Add Menus</ModalHeader>
  <Form autoComplete="off" className="form-horizontal" 
  onSubmit={(e) => { e.preventDefault();   formik.handleSubmit();  return false; }}>
  <ModalBody>
  <Row>
    <Col md={12}>
    <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Name
        </label>

        <Input
          type="text"
          id="menuname-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Menu Name"
          name="menuname"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.menuname || ""}
          invalid={formik.touched.menuname && formik.errors.menuname}
        />
        {formik.touched.menuname && formik.errors.menuname && <FormFeedback type="invalid">{formik.errors.menuname}</FormFeedback>}
      </div>
  
    </Col>
    
  </Row>
</ModalBody>
    <ModalFooter>
      <div className="hstack gap-2 justify-content-end">
        <button type="button" className="btn btn-light" onClick={() => {setmodal_list(false); formik.resetForm()}}>
          Close
        </button>

        <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
          {loading ? <Loader /> : "Submit"}
        </button>
      </div>
    </ModalFooter>
  </Form>
</Modal> */}


     {/* UpdateModal */}
  {/* <Modal className="modal-dialog" isOpen={modal_update_list}  toggle={() => { tog_update_list();}} centered>
  <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>
    Update Wallet Type
  </ModalHeader>
  <Form autoComplete="off" className="form-horizontal"
    onSubmit={(e) => {
      e.preventDefault();
      validationUpdate.handleSubmit();
      return false;
    }}
  >
    <ModalBody>
      <Row>
        <Col md={12}>
        <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Wallet Type
        </label>
       <select id="status-field" name="WalletType" 
            onChange={validationUpdate.handleChange} 
            onBlur={validationUpdate.handleBlur}
            value={validationUpdate.values.WalletType || "" } 
            className={`form-control ${validationUpdate.touched.WalletType && validationUpdate.errors.WalletType ? "is-invalid" : "" }`}>
          <option value="" disabled>
            Wallet Type
          </option>
          <option value={0}>Manual</option>
          <option value={1}>Automatic</option>
      </select>
        {validationUpdate.touched.WalletType && validationUpdate.errors.WalletType && <FormFeedback type="invalid">{validationUpdate.errors.WalletType}</FormFeedback>}
      </div>
    
        </Col>
       
      </Row>
  
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-light" onClick={() => {setmodal_update_list(false)}}>
              Close
            </button>

            <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
              {loading ? <Loader /> : "Submit"}
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WalletType;

import React, { useState, useEffect,useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback,CardImg, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import Swal from "sweetalert2";
import { AdminContext } from "../../helpers/adminContext";
import country from "../../helpers/CountryCodes.json";


const FlowInstruction = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState([]);
  const [getId, setId] = useState("");
  document.title = "Ultrapro | Flow Instruction";
  const [modal, setModal] = useState(false);
  const [showDetails, setShowDetails] = useState({})
  const [showModel, setShowModel] = useState(false)
  const [errors, setErrors] = useState('');
  const [selectedCountry, setSelectedCountry] = useState();


  //Permission List
  const { adminInfo } = useContext(AdminContext);
  const [adminType,setAdminType] = useState('')

  //Add Modal
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
    formik.resetForm();
    setImage('')
  }

  const handleSelect = (value, type) => {
    if (type === 'country') {
      setSelectedCountry(value)
    }
    // fetchPaginationList({ page: 0, country: value, startDate: DateValue.startDate, endDate: DateValue.endDate, filterType: selectedItem })
  };
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
    setImage('')
  }
  const [getImage,setGetImage] = useState('')
  //classList api call
  useEffect(() => {
    fetchData();
    setAdminType(adminInfo?.adminType)
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.ProbinarList);
      if (response.status) {
        setLoading(false);
        setTotalPagination(response.data.totalPages);
        setFormData(response.data.data);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Handle fetch error
    }
  };


  const handleShowModel = (value) => {
    setShowDetails(value)
    setShowModel(true)
}
const handleDelete = (value) => {
  showUserBlockAlert(confirmDelete, value)
}

const showUserBlockAlert = (onConfirm, row) => {
  Swal.fire({
      title: 'Are you sure?',
      text: `You want to remove this record!`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
          try {
              await onConfirm(row);
          } catch (error) {
              Swal.fire('Error!', 'There was an error deleting the record', 'error');
          }
      }
  }).then((result) => {
      if (result.isConfirmed) {
          // Additional actions after confirmation, if needed
      }
  });
};

const confirmDelete = async (row) => {
  const postForm = {
    probinarId : row.probinarId
  }
 
  try {
      const response = await postData(Apiservices.ProbinarDelete,postForm )
      if (response.status) {
          Swal.fire('Success!', `${response.message}`, 'success');
          fetchData()
      } else {
          Swal.fire('Error!', `${response.message}`, 'error');
      }
  } catch (error) {
      toast.error("Internal Server Error..Try Again Later")
  }
}

const allowedActions = [1,2,3,4];
const columns = [
  {
    name: "S.No",
    // selector: (row, index) => currentPage * itemsPerPage + index + 1,
    selector: (row) => handleNullValue(row.serialNo),
    sortable: true,
    minWidth: '80px',
    maxWidth: '100px'
  },
  {
    name: "Image",
    selector: (row) => <img alt='' style={{ width: "100px" }} src={row.probinarImage || '-'} />,
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  {
    name: "Title",
    selector: (row) => handleNullValue(row.probinarName),
    sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  
  
  {
    name: "Position",
    selector: (row) => handleNullValue(row.position),
    // sortable: true,
    minWidth: '150px',
    maxWidth: '200px'
  },
  // {
  //   name: "Session Link",
  //   selector: (row) => handleNullValue(`${row.videoHandler === 1 ? row.sessionYoutubeLink : '-' }`),
  //   sortable: true,
  //   minWidth: '150px',
  //   maxWidth: '200px'
  // },
  ...(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Probinar Academy" && data.actions.includes(3)) ? [
  {
    name: "Status",
    selector: (row) => (
      <div className="form-check form-switch form-switch-md mb-3 d-flex justify-content-center align-items-center">
        <input
          type="checkbox"
          className="form-check-input"
          id="customSwitchsizemd"
          checked={row.IsActive === 1}
          onChange={() => handleUpdateStatusClick(row)}
          disabled={loading}
        />
      </div>
    ),
    sortable: true,
    minWidth: '100px',
    maxWidth: '100px'
  }]:[]),
  {
    name: "Date & Time",
    selector: (row) => row.createdAt ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${handleNullValue(utcToNormalTime(row.createdAt).time)}` : "---",
    sortable: true,
    minWidth: '200px',
    maxWidth: '250px'
  },
  ...(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Probinar Academy" && allowedActions.some(action => data.actions.includes(action))) ? [
  {
    name: "Action",
    
    cell: (row) => (
      <div className="d-flex">
        {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Probinar Academy" && data.actions.includes(3))) ? (
    
        <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-border-color cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>
  ):null}
    {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Probinar Academy" && data.actions.includes(2))) ? (
        <i style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline mx-1"></i>
    ): null}
      {(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Probinar Academy" && data.actions.includes(4))) ? (
        <i onClick={() => handleDelete(row)} className="mdi mdi-trash-can-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></i>
      ): null }
      </div>
 
    ),
    ignoreRowClick: true,
    minWidth: '150px',
    maxWidth: '200px'
  }]:[]),
];
  


  //paginnation
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };
  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: itemsPerPage,
      // search: inputSearch,
    };
    const queryString = new URLSearchParams(postForm).toString();
    try {
      // const response = await postData(`${Apiservices.ProbinarList}?${queryString}`);
      const response = await postData(Apiservices.ProbinarList,postForm);

      if (response.status) {
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  
  // const validateSessionLink = (value) => {
  //   let error = '';
  //   if (!value) {
  //     error = 'Session link is required';
  //   } else if (!value.endsWith('.mp4')) {
  //     error = 'Session link must be an MP4 file';
  //   }
  //   return error;
  // };
  //Add form validation
  const formik = useFormik({
    initialValues: {
      title: "",
      position:'',
      videolink:'',
      isActive:""
    },
    validationSchema: Yup.object({
      title: Yup.string().trim().required("Title is required"),
      position: Yup.string().trim()
      .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
      .required("Position is required"),
      videolink: Yup.string()
      .trim()
      .url("Please enter a valid URL")
      .required("Video Link is required"),
      isActive: Yup.string().trim().required("Status is required"),
    }),
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  const [image, setImage] = useState(null);
  // const [defaultImage, setDefaultImage] = useState('path/to/default/image.jpg'); // Set your default image path
  const [error, setError] = useState('');
  
  
  // const validateImage = (file) => {
  //   const allowedTypes = ['image/png', 'image/jpeg'];
  //   if (!allowedTypes.includes(file.type)) {
  //       setError('Only JPG and PNG formats are allowed.');
  //       return false;
  //   }
  //   setError('');
  //   return true;
  // };
  
  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file && validateImage(file)) {
  //       setImage(file);
  //   } else {
  //       setImage(null);
  //   }
  // };
 
//Addapicall
const handleAdd = async (data) => {

  setLoading(true);
  // if(formik.values.videostatus === '1'){
  //   const error = validateSessionLink(sessionLink);
  //   if (error) {
  //     setErrors(error);
  //     setLoading(false);
  //     return;
      
  //   }
  // }



if (!image) {
  toast.error("Image is required");
  setLoading(false);
  return;
}
  // Validate session link before submission
  const formData = new FormData();
  formData.append("probinarName", data.title);
  formData.append("probinarVideoLink", data.videolink);
  formData.append('files', image);
  formData.append('probinarId', "");
  // formData.append("IsActive", Number(data.isActive));
  formData.append("IsActive", data.isActive);
  formData.append("position", data.position);
  formData.append("adminType",adminInfo?.adminType);
  formData.append("permissions",adminInfo?.permission);
  try {
    // const response = await postData(Apiservices.ProbinarAdd, formData);
    const response = await postData(Apiservices.ProbinarAdd, formData);
    
    if (response.status) {
      console.log(response,"1111111111111111111")
      toast.success(response.message);
      formik.resetForm();
      setmodal_list(false);
      setLoading(false);
      fetchData();
      setImage(null)
      setFormData('')
    setErrors('');

     
      formik.setFieldValue("sessionimage", "");
    } else {
      toast.error(response.message);
      formik.resetForm();
      setLoading(false);
      fetchData();
      setImage(null)
      setFormData('')
      setErrors('');



    }
  } catch (error) {
    toast.error(error.response.message);
    setLoading(false);
    setLoading(false);
    setImage(null)
    setFormData('')
    setErrors('');



  }
};

  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,
  
    initialValues: {
      title: "",
      position:'',
      videolink:'',
      isActive:""
    },
    validationSchema: Yup.object({
      title: Yup.string().trim().required("Title is required"),
      position: Yup.string().trim()
      .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
      .required("Position is required"),
      videolink: Yup.string()
      .trim()
      .url("Please enter a valid URL")
      .required("Video Link is required"),
      isActive: Yup.string().trim().required("Status is required"),
      
    }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });
  
  
  const handleUpdate = (record) => {
    setId(record.probinarId);
    setGetImage(record.probinarImage)
    validationUpdate.setValues({
      discoverId: getId || "",
      title: record.probinarName || "",
      position:record.position || "",
      videolink:record.probinarVideoLink || "",
      isActive: record.IsActive !== undefined ? String(record.IsActive) : "",
      
    });
   
    setmodal_update_list(true);
  };
  
 
  const confirmUpdate = async (values) => {
    setLoading(true);
  
    // Image validation
    if (!image && !getImage) {
      toast.error("Session image is required");
      setLoading(false);
      return;
    }
    // if(validationUpdate.values.videostatus === '1'){
    //   const error = validateSessionLink(sessionLink);
    //   if (error) {
    //     setErrors(error);
    //     setLoading(false);
    //     return;
        
    //   }
    // }
  
    const updateformData = new FormData();
    updateformData.append("probinarName", values.title);
    updateformData.append("probinarVideoLink", values.videolink);
    updateformData.append("IsActive", Number(values.isActive));
    updateformData.append("position", Number(values.position));
    updateformData.append("probinarId", getId);

    
    // Image handling
    if (image) {
      updateformData.append("files", image);
    } else if (getImage) {
      updateformData.append("files", getImage);
    }
  
    try {
      const response = await postData(Apiservices.ProbinarUpdate, updateformData);

  
      if (response.status) {
        setmodal_update_list(false);
        toast.success(response.message);
        fetchData();
      setFormData('')

      } else {
        
        toast.error(response.message);
        fetchData();
      setFormData('')
    setErrors('');


      }
    } catch (error) {
    toast.error(error.response.message);
    validationUpdate.resetForm();
    setErrors('');

    } finally {
      setLoading(false);
    }
  };
  
  //Searchfun
  const [inputSearch, setinputSearch] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "") {
      fetchData();
      setSearchValid(false);
    } else {
      setinputSearch(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
      };

      const queryString = new URLSearchParams(postForm).toString();
      try {
        // const response = await postData(
        //   `${Apiservices.ProbinarList}?${queryString}`
        // );
      const response = await postData(Apiservices.ProbinarList,postForm);

        if (response.status) {
          setSearchLoad(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);

          // toast(response.message);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };

const [isLoading,setIsLoading] = useState(false)

const showStatusAlert = (onConfirm, row) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You want to update status!",
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Update it!',
    showLoaderOnConfirm: true, 
    preConfirm: async (reason) => {
      if (!reason) {
          Swal.showValidationMessage('Reason is required');
      } else {
          Swal.showLoading();
          try {
              await onConfirm({ ...row, reason });
              Swal.fire('Success!', 'The status has been updated successfully', 'success');
          } catch (error) {
              Swal.fire('Error!', 'There was an error updating the status', 'error');
          }
      }
  },
  allowOutsideClick: () => !Swal.isLoading(), 
  // preConfirm: () => {
  //     return onConfirm(row) 
  //       .then(() => {
  //         return Promise.resolve();
  //       })
  //       .catch((error) => {
  //         Swal.showValidationMessage(`Request failed: ${error}`);
  //       });
  //   }
  }).then((result) => {
    if (result.isConfirmed) {
      // Success handling if needed
      // Swal.fire('Success !', 'The status has been updated successfully', 'success');
    }
  });
};
const handleUpdateStatusClick = (row) => {
  showStatusAlert(handleToggle1, row);
}

const handleToggle1 = async (rowDetails) => {
  const updatedStatus = rowDetails.IsActive === 1 ? 0 : 1;
  try {
    setIsLoading(true)
    const statusUpdate = new FormData();

    statusUpdate.append("probinarName", rowDetails.probinarName);
    statusUpdate.append("probinarVideoLink", rowDetails.probinarVideoLink);
    statusUpdate.append("IsActive", updatedStatus);
    statusUpdate.append("position", Number(rowDetails.position));
    statusUpdate.append("probinarId", rowDetails.probinarId);
    statusUpdate.append("files", rowDetails.probinarImage)


    // const response = await postData(`${Apiservices.ProbinarUpdate}`, statusUpdate);
    const response = await postData(Apiservices.ProbinarUpdate, statusUpdate);
console.log(response);

    if (response.status) {
      toast.dismiss();
      setIsLoading(false);
      toast.success(response.message);
      // Swal.fire("Success !", "The status has been updated successfully", "success");
      fetchData();
    } 
    else {
      toast.error(response.message);
      setIsLoading(false);
    }
  } catch (error) {
    toast.dismiss();
    toast.error(error.response.data.message);
    // toast.error("Internal Server Error..Try Again Later");
    setIsLoading(false);
  }
};


const toggleModal = () => {
  setModal(!modal);
};

const [rowsPerPage, setRowsPerPage] = useState(10);
const [count, setCount] = useState("");

const handleRowsPerPageChange = (newRowsPerPage) => {
  setRowsPerPage(parseInt(newRowsPerPage));
  HandleClickRowperpage(newRowsPerPage)
}
const HandleClickRowperpage = async(newRowsPerPage) => {
  setLoading(true);
  const postForm = {
    // page: selected + 1,
    // page:Number(currentPage + 1),
    page:1,
    // limit:1 ,
    limit:Number(newRowsPerPage) ,
    // search: inputSearch,
  };

  // if (dateRange[0] && dateRange[1]) {
  //   postForm.startDate = formatDate(dateRange[0]);
  //   postForm.endDate = formatDate(dateRange[1]);
  // }

  const queryString = new URLSearchParams(postForm).toString();
  try {
    if (formData === undefined) {
      // const response = await postData(`${Apiservices.ProbinarList}?${queryString}`);
      const response = await postData(Apiservices.ProbinarList,postForm);

      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } else {
      // const response = await postData(`${Apiservices.ProbinarList}?${queryString}`);
      const response = await postData(Apiservices.ProbinarList,postForm);


      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    }
  } catch (error) {
   
    toast.error("Internal Server Error..Try Again Later");
    setLoading(false);
  }

};
const validateImage = (file) => {
  // Validate file type and size here (e.g., PNG, JPG, JPEG)
  const validTypes = ['image/png', 'image/jpeg'];
  return validTypes.includes(file.type) && file.size <= 5000000; // 5MB max size
};

const handleImageChange = (e) => {
  const file = e.target.files[0];
  setImage(file);
  // if (file) {
  //   if (validateImage(file)) {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       const img = new Image();
  //       img.src = event.target.result;
  //       img.onload = () => {
  //         const { width, height } = img;
  //         if (validateResolution(width, height)) {
  //           setImage(file);
  //           setError('');
  //         } else {
  //           setError('Image resolution must be at least 800x600 pixels.');
  //           setImage(null);
  //         }
  //       };
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setError('Invalid file type or size.');
  //     setImage(null);
  //   }
  // }
};

const validateResolution = (width, height) => {
  // Minimum resolution required: 800x600 pixels
  return width >= 800 && height >= 600;
};

const [pdfFile, setPdfFile] = useState(null);
const [error1, setError1] = useState("");

const handlePDFChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    if (file.type === "application/pdf") {
      setPdfFile(file);
      setError1(""); // Clear any previous error
    } else {
      setError("Only PDF files are allowed.");
      setPdfFile(null);
    }
  }
};
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Flow Instruction" breadcrumbItem="Flow Instruction List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4">
                    <Col className="col-sm-auto">
                    <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => handleSearch()}
                            disabled={searchLoad}
                          >
                            {searchLoad ? (<Spinner className="text-light" size="sm" /> ) : (<i className="ri-search-line" /> )}
                          </button>
                        </div>
                      </div>
                   
                    </Col>
                    {/* Add Modal Button */}
                    <Col className="col-sm">
                 
                      <div className="d-flex justify-content-sm-end">
                      {(adminType === 1 || adminInfo?.permissions.some(data => 
                      data.module === "Probinar Academy" && data.actions.includes(1))) ? (
                        <Button
                          color="success"
                          className="add-btn"
                          onClick={() => tog_list()}
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                        </Button>
                        ): null }
                      </div>
                    </Col>
                  </Row>
                 
    <div className="table-responsive  mt-2">
    <DataTable
      columns={columns}
      data={formData}
      paginationServer
      paginationTotalRows={formData.length}
      progressPending={loading}
      persistTableHead={true}
      progressComponent={<div className="py-3">Loading...</div>}
      // noDataComponent={formData.length===0}
      noDataComponent={<div className="py-3">No records found</div>}
      fixedHeader={true}
      
    />
    </div>
   

<div className="container">
  <div className="row justify-content-end mt-4">
  <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
      <div className="d-flex align-items-center me-3">
        <span className="me-2">Rows per page:</span>
        <select
          value={rowsPerPage}
          onChange={(e) => handleRowsPerPageChange(e.target.value)}
          className="form-control-sm"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>
      <div className="me-md-3 mb-3 mb-md-0">
        <span>{count}</span>
      </div>
      <div className="pagination pagination-rounded mt-3">
        <ReactPaginate
          previousLabel={<i className="mdi mdi-chevron-left"></i>}
          nextLabel={<i className="mdi mdi-chevron-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageLinkClassName={"page-link"}
          breakLinkClassName={"break-link"}
          disabledClassName={"disabled"}
          initialPage={currentPage}
          disableInitialCallback={true}
          forcePage={currentPage}
          renderLink={(props) => {
            if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
              return (
                <a
                  {...props}
                  onClick={() =>
                    handlePageClick({
                      selected: pageCount - 1,
                    })
                  }
                >
                  {pageCount}
                </a>
              );
            }
            return <a {...props} />;
          }}
        />
      </div>
    </div>
  </div>
</div>
  

  {/* // ADDMODAL  */}
  <Modal className="modal-dialog modal-lg" isOpen={modal_list}  toggle={() => { tog_list(); }}centered>
  <ModalHeader  className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list();}}> Flow Instruction</ModalHeader>
  <Form autoComplete="off" className="form-horizontal" 
  onSubmit={(e) => { e.preventDefault();   formik.handleSubmit();  return false; }}>
  <ModalBody>
  <Row>
    <Col md={6}>

       
                <div className="mb-3">
                  <label htmlFor="title-field" className="form-label">
                    Country
                  </label>
                      <select
                        onChange={(e) => handleSelect(e.target.value, 'country')}
                        className="form-select"
                        id="autoSizingSelect"
                        value={selectedCountry || 'Select Country'}
                      >
                        <option value={selectedCountry || 'Select Country'} disabled>
                          {selectedCountry || 'Select Country'}
                        </option>
                        <option value=''>All</option>
                        {country.map((data) => (
                          <option key={data.name} value={data.name}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </div>

                <div className="mb-3">
                <label htmlFor="pdf-field" className="form-label">
                  Upload PDF
                </label>
                <div>
                  <input
                    type="file"
                    className="form-control"
                    accept=".pdf"
                    onChange={handlePDFChange}
                  />
                </div>
                {error && (<div className="text-danger" style={{ fontSize: '12px' }}>{error}</div>)}

                {pdfFile && (
                  <div className="mt-3">
                    <label>Preview:</label>
                    <iframe
                      src={URL.createObjectURL(pdfFile)}
                      width="100%"
                      height="300px"
                      style={{ border: '1px solid #ccc' }}
                      title="PDF Preview"
                    />
                    <a
                      href={URL.createObjectURL(pdfFile)}
                      download={pdfFile.name}
                      className="btn btn-primary btn-sm mt-2"
                    >
                      Download PDF
                    </a>
                  </div>
                )}
              </div>


        

    <div className="mb-3">
        <label htmlFor="title-field" className="form-label">Content</label>
        <Input
          type="textarea"
          id="title-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Content"
          name="title"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.title || ""}
          invalid={validationUpdate.touched.title && validationUpdate.errors.title}
        />
        {validationUpdate.touched.title && validationUpdate.errors.title && <FormFeedback type="invalid">{validationUpdate.errors.title}</FormFeedback>}
      </div>
      
    </Col>
    <Col md={6}>
    

      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
          Video Link
        </label>
        <Input
          type="text"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Video Link"
          name="videolink"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.videolink || ""}
          invalid={formik.touched.videolink && formik.errors.videolink}
        />
        {formik.touched.videolink && formik.errors.videolink && <FormFeedback type="invalid">{formik.errors.videolink}</FormFeedback>}
      </div>
     

      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
          Status
        </label>
        <select
          id="status-field"
          //  className="form-control"
          name="isActive"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.isActive || ""}
          className={`form-control ${formik.touched.isActive && formik.errors.isActive ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Status
          </option>
          <option value={1}>Active</option>
          <option value={0}>Deactive</option>
        </select>
        {formik.touched.isActive && formik.errors.isActive && <FormFeedback type="invalid"> {formik.errors.isActive} </FormFeedback>}
      </div>
    </Col>
  </Row>
</ModalBody>

    <ModalFooter>
      <div className="hstack gap-2 justify-content-end">
        <button type="button" className="btn btn-light" onClick={() => {setmodal_list(false); formik.resetForm();setImage('')}}>
          Close
        </button>

        <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
          {loading ? <Loader /> : "Submit"}
        </button>
      </div>
    </ModalFooter>
  </Form>
</Modal>


     {/* UpdateModal */}
  <Modal className="modal-dialog modal-lg" isOpen={modal_update_list}  toggle={() => { tog_update_list();}} centered>
  <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>
    Update Probinar Academy
  </ModalHeader>
  <Form autoComplete="off" className="form-horizontal"
    onSubmit={(e) => {
      e.preventDefault();
      validationUpdate.handleSubmit();
      return false;
    }}
  >
    <ModalBody>
      <Row>
        <Col md={6}>
      
        <div className="mb-3">
        <label htmlFor="title-field" className="form-label">Title</label>
        <Input
          type="text"
          id="title-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Title"
          name="title"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.title || ""}
          invalid={validationUpdate.touched.title && validationUpdate.errors.title}
        />
        {validationUpdate.touched.title && validationUpdate.errors.title && <FormFeedback type="invalid">{validationUpdate.errors.title}</FormFeedback>}
      </div>
     
      <div className="mb-3">
      <label htmlFor="title-field" className="form-label">
         Image 
        </label>
        <div>
        <input
          type="file"
          className="form-control"
          accept=".png, .jpg, .jpeg"
          onChange={handleImageChange}
          // Set value attribute to show filename or keep it blank if no file is selected
          // value={getImage ? getImage : ''}
          placeholder="No file chosen"
        />
      </div>
      {error && <div className="text-danger" style={{ fontSize: '15px' }}>{error}</div>}

      {/* Image Preview Card */}
      {(getImage || image) && (
        <Card style={{ width: '200px' }}>
          <CardBody>
            <CardImg
              top
              src={image ? URL.createObjectURL(image) : getImage}
              style={{ width: '100%',  objectFit: 'cover' }}
            />
          </CardBody>
        </Card>
      )}
       
      </div>
    
    
     
        </Col>
        <Col md={6}>
      
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Position
        </label>
        <Input
          type="number"
          id="subject-field"
          className="form-control"
          autoComplete="off"
           min="1"
          placeholder="Enter Position"
          name="position"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.position || ""}
          invalid={validationUpdate.touched.position && validationUpdate.errors.position}
        />
        {validationUpdate.touched.position && validationUpdate.errors.position && <FormFeedback type="invalid">{validationUpdate.errors.position}</FormFeedback>}
      </div>
    
    
      <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         URL
        </label>
        <Input
          type="text"
          id="subject-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Video Link"
          name="videolink"
         
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.videolink || ""}
          invalid={validationUpdate.touched.videolink && validationUpdate.errors.videolink}
        />
        {validationUpdate.touched.videolink && validationUpdate.errors.videolink && <FormFeedback type="invalid">{validationUpdate.errors.videolink}</FormFeedback>}
        
      </div>
      <div className="mb-3">
        <label htmlFor="status-field" className="form-label"> Status </label>
        <select
          id="status-field"
          //  className="form-control"
          name="isActive"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.isActive || ""}
          className={`form-control ${validationUpdate.touched.isActive && validationUpdate.errors.isActive ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Status
          </option>
          <option value={1}>Active</option>
          <option value={0}>Deactive</option>
        </select>
        {validationUpdate.touched.isActive && validationUpdate.errors.isActive && <FormFeedback type="invalid"> {validationUpdate.errors.isActive} </FormFeedback>}
      </div>
        </Col>
      </Row>
  
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-light" onClick={() => {setmodal_update_list(false);setImage('')}}>
              Close
            </button>

            <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
              {loading ? <Loader /> : "Submit"}
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>



{/* View Modal    */}
<Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static" >

<div className="modal-header">
    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
       Probinar Academy Details 
    </h5>
    <button
        onClick={() => { setShowModel(false); }}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div className="modal-body">

    <div className="table-responsive">
        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
            <tbody>
                <tr>
                    <td><b>Title</b></td>
                    <td>{showDetails.probinarName || '-'}</td>
                </tr>
                <tr>
                    <td><b>Probinar Academy ID</b></td>
                    <td>{showDetails.probinarId || '-'}</td>
                </tr>
                <tr>
                    <td><b>Video Link</b></td>
                    <td>{showDetails.probinarVideoLink || '-'}</td>
                </tr>
                <tr>
                    <td><b>Position</b></td>
                    <td>{showDetails.position || '-'}</td>
                </tr>
            
                <tr>
                    <td><b>Status</b></td>
                    <td>{showDetails.IsActive == 1 ? 'Active' : 'Deactive' || '-'}</td>
                </tr>
                
                <tr>

                    <td><b>Date & Time</b></td>
                    <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                </tr>
                <tr>
                    <td><b>Image</b></td>
                    <td>
                      <Card style={{ width: '200px'}}>
                      <CardBody>
                        <CardImg
                        top
                          src={showDetails.probinarImage || '-'}
                          style={{ width: '100%', objectFit: 'cover' }}
                        />
                      </CardBody>
                    </Card>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>

</Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FlowInstruction;

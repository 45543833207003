import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader,Spinner,
   Form } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { ChatPostGetData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import Swal from "sweetalert2";
import { AdminContext } from "../../helpers/adminContext";


const AgentUsers = () => {
  const [loading, setLoading] = useState(false);
  const [getSessionList, setSessionList] = useState([]);
  const [getId, setId] = useState("");
  document.title = "Ultrapro | Agent List";
  const [fetchLoading,setFetchLoading] = useState(false)
   //Permission List 
   const {adminInfo} = useContext(AdminContext);
   const [adminType,setAdminType] = useState('');
  //Add Modal
  const [modal_list, setmodal_list] = useState(false);

  function tog_list() {
    setmodal_list(!modal_list);
    formik.resetForm();
  }
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
 
  }

  //classList api call
  useEffect(() => {
    fetchData();
    setAdminType(adminInfo?.adminType)
  }, []);

  const fetchData = async () => {
    try {
      setFetchLoading(true);
      const response = await ChatPostGetData(Apiservices.AGENT_LIST);
      if (response.status === true) {
        setFetchLoading(false);
        setTotalPagination(response.totalPages);
        setSessionList(response.data);
        setTotalLimit(response.limit);
        setCount(response.currentCount);
        setCurrentPage(0); 
      } else {
        setFetchLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setFetchLoading(false);
      // Handle fetch error
    }
  };
 

 
const handleDelete = (value) => {
  showUserBlockAlert(confirmDelete, value.agentId)
}


const showUserBlockAlert = (onConfirm, row) => {
  Swal.fire({
      title: 'Are you sure?',
      text: `You want to remove this record!`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
          try {
              await onConfirm(row);
          } catch (error) {
              Swal.fire('Error!', 'There was an error deleting the record', 'error');
          }
      }
  }).then((result) => {
      if (result.isConfirmed) {
          // Additional actions after confirmation, if needed
      }
  });
};

const confirmDelete = async (row) => {
  const postForm = {
    agentId : row  
  }
 
  try {
      const response = await ChatPostGetData(Apiservices.AGENT_DELETE,postForm )
      if (response.status === true) {
          Swal.fire('Success!', `${response.message}`, 'success');
          fetchData()
      } else {
          Swal.fire('Error!', `${response.message}`, 'error');
      }
  } catch (error) {
      toast.error("Internal Server Error..Try Again Later")
  }
}

const handleUpdateStatusClick = (rowDetails) => {
  showStatusAlert(handlestatusUpdate,rowDetails)
}
const handlestatusUpdate = async (rowDetails) => {
 

  const updatedStatus = rowDetails.isActive === 1 ? 0 : 1;

  setLoading(true);
  const postForm={
  name:rowDetails.name,
  email:rowDetails.email,
  isActive:updatedStatus,
  agentId:rowDetails.agentId,
  agentActive:1

  }
  try {
    const response = await ChatPostGetData(Apiservices.AGENT_UPDATE, postForm);
    if (response.status) {
    
      setmodal_update_list(false);
      toast.success(response.message);
      // fetchData();
      handlePagePost(currentPage)

    } else {
   
      toast.error(response.message);
      fetchData();
      
    }
  } catch (error) {
   
    toast.error(error.message);
    validationUpdate.resetForm();

  } finally {
    setLoading(false);
  }
};
const showStatusAlert = (onConfirm, row) => {
  Swal.fire({
      title: 'Are you sure?',
      text: `You want to update status for this record!`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
          try {
              await onConfirm(row);
          } catch (error) {
              Swal.fire('Error!', 'There was an error updating the record', 'error');
          }
      }
  }).then((result) => {
      if (result.isConfirmed) {
          // Additional actions after confirmation, if needed
      }
  });
};
const allowedActions = [1,2,3,4];
const columns = [
  {
    name: "S.No",
    // selector: (row, index) => currentPage * itemsPerPage + index + 1,
    selector: (row) => handleNullValue(row.serialNo),
    grow:1,
    sortable: true,
   
  },
 
  {
    name: "Name",
    selector: (row) => handleNullValue(row.name),
    sortable: true,
   
  },
  {
    name: "Email",
    selector: (row) => handleNullValue(row.email),
    sortable: true,
    minWidth: '300px',
   
  },
  // {
  //   name: "Active Status",
  //   selector: (row) => (
  //     <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
  //       <input type="checkbox" className="form-check-input" id="customSwitchsizemd"
  //        checked={row.isActive === 1} onChange={() => handleUpdateStatusClick(row)} disabled={loading} />
  //     </div>
  //   ),
  //   sortable: true,
  //   minWidth: "150px",
  // },
  {
    name: "Created Date & Time",
    selector: (row) => row.createdAt ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${handleNullValue(utcToNormalTime(row.createdAt).time)}` : "---",
    sortable: true,
    minWidth: '200px',
    maxWidth: '250px'
  },
  // {
  //   name: "Updated Date & Time",
  //   selector: (row) => row.updatedAt ? `${handleNullValue(utcToNormalTime(row.updatedAt).date)} ${handleNullValue(utcToNormalTime(row.updatedAt).time)}` : "---",
  //   sortable: true,
  //   minWidth: '200px',
  //   maxWidth: '250px'
  // },
  ...(adminType === 1 || adminInfo?.permissions.some(data => 
    data.module === "Agent Users" && allowedActions.some(action =>data.actions.includes(action))) ? [
  {
    name: "Action",
    cell: (row) => (
      <div className="d-flex">
        {(adminType === 1 || adminInfo?.permissions.some(data=>data.module==="Agent Users" && data.actions.includes(3))) ? (
        <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-border-color cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>
      ): null }
       {(adminType === 1 || adminInfo?.permissions.some(data=>data.module==="Agent Users" && data.actions.includes(4))) ? (
        <i onClick={() => handleDelete(row)} className="mdi mdi-trash-can-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></i>
       ): null}
      </div>
    ),
    ignoreRowClick: true,
  
  }]:[]),
];
  


  //paginnation
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };
  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: itemsPerPage,
      
    };
   
    try {
      const response = await ChatPostGetData(Apiservices.AGENT_LIST,postForm);

      if (response.status) {
        setSessionList(response.data);
        setTotalPagination(response.totalPages);
        setTotalLimit(response.limit);
        setCount(response.currentCount);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  
  // const validateSessionLink = (value) => {
  //   let error = '';
  //   if (!value) {
  //     error = 'Session link is required';
  //   }
  //   //  else if (!value.endsWith('.mp4')) {
  //   //   error = 'Session link must be an MP4 file';
  //   // }
  //   return error;
  // };
  //Add form validation
  const formik = useFormik({
    initialValues: {
      name: "",
      email:'',
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email format").required("Email is required"),
      name: Yup.string().trim().required("Name is required"),
      }),
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  
 
//Addapicall
const handleAdd = async (data) => {

  setLoading(true);
  const postForm = {
    name:data.name,
   email:data.email,
   isActive:1
  }
  try {
    const response = await ChatPostGetData(Apiservices.AGENT_ADD, postForm);
    if (response.status) {
      toast.success(response.message);
      formik.resetForm();
      setmodal_list(false);
      setLoading(false);
      fetchData();

    } else {
      toast.error(response.message);
      setLoading(false);
      fetchData();
    }
  } catch (error) {
    toast.error(error.message);
    setLoading(false);
    setLoading(false);
  }
};

  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,
  
    initialValues: {
      name: "",
      email:'',
      // isActive:''
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required("Name is required"),
      email: Yup.string().trim().required("Position is required"),
      // isActive: Yup.string().trim().required("Status is required"),
      }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });
  
  const handleUpdate = (record) => {
    setId(record.agentId);
   
    validationUpdate.setValues({
    
      name: record.name || "",
      email: record.email || "",
      isActive: record.isActive !== undefined ? String(record.isActive) : "",
    });
   
    setmodal_update_list(true);
  };
  
 
  const confirmUpdate = async (values) => {
    setLoading(true);
  
    const postForm={
    name:values.name,
   email:values.email,
  //  isActive:Number(values.isActive),
   agentId:getId
    }
    try {
      const response = await ChatPostGetData(Apiservices.AGENT_UPDATE, postForm);
      if (response.status) {
      
        setmodal_update_list(false);
        toast.success(response.message);
        // fetchData();
        handlePagePost(currentPage)

      } else {
     
        toast.error(response.message);
        fetchData();
        
      }
    } catch (error) {
     
      toast.error(error.message);
      validationUpdate.resetForm();

    } finally {
      setLoading(false);
    }
  };
  

const [rowsPerPage, setRowsPerPage] = useState(10);
const [count, setCount] = useState("");

const handleRowsPerPageChange = (newRowsPerPage) => {
  setRowsPerPage(parseInt(newRowsPerPage));
  HandleClickRowperpage(newRowsPerPage)
}
const HandleClickRowperpage = async(newRowsPerPage) => {
  setLoading(true);
  const postForm = {
    page:1,
    limit:Number(newRowsPerPage) ,
  };

  try {
    if (getSessionList === undefined) {
      const response = await ChatPostGetData(Apiservices.AGENT_LIST,postForm);

      if (response.status) {
        setLoading(false);
        setSessionList(response.data);
        setTotalPagination(response.totalPages);
        setTotalLimit(response.limit);
        setCount(response.currentCount);
      } else {
        setLoading(false);
      }
    } else {
      const response = await ChatPostGetData(Apiservices.AGENT_LIST,postForm);
      if (response.status) {
        setLoading(false);
        setSessionList(response.data);
        setTotalPagination(response.totalPages);
        setTotalLimit(response.limit);
        setCount(response.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    }
  } catch (error) {
   
    toast.error("Internal Server Error..Try Again Later");
    setLoading(false);
  }

};
  //Searchfun
  const [inputSearch, setinputSearch] = useState("");
  const [searchValid, setSearchValid] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "") {
      fetchData();
      setSearchValid(false);
    } else {
      setinputSearch(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
      };

      try {
      
        const response = await ChatPostGetData(Apiservices.AGENT_LIST,postForm);
        if (response.status) {
          setSearchLoad(false);
          setSessionList(response.data);
          setTotalPagination(response.totalPages);
          setTotalLimit(response.limit);
          setCount(response.currentCount);

          // toast(response.message);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
     
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Live Chat" breadcrumbItem="Agent Users" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4">
                    
                    <Col className="col-sm-auto">
                    <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => handleSearch()}
                            disabled={searchLoad}
                          >
                            {searchLoad ? (<Spinner className="text-light" size="sm" /> ) : (<i className="ri-search-line" /> )}
                          </button>
                        </div>
                      </div>
                   
                    </Col>
                    
                    {/* Add Modal Button */}
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                      {(adminType === 1 || adminInfo?.permissions.some(data => data.module === "Agent Users" && data.actions.includes(1))) ? (
                        <Button
                          color="success"
                          className="add-btn"
                          onClick={() => tog_list()}
                          id="create-btn"
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                        </Button>
                      ): null }
                      </div>
                    </Col>
                  </Row>
                 
              <div className="table-responsive  mt-2">
              <DataTable
                columns={columns}
                data={getSessionList}
                paginationServer
                paginationTotalRows={getSessionList?.length}
                progressPending={fetchLoading}
                persistTableHead={true}
                progressComponent={<div className="py-3">Loading...</div>}
                // noDataComponent={formData.length===0}
                noDataComponent={<div className="py-3">No records found</div>}
                fixedHeader={true}
                
              />
              </div>
   

<div className="container">
  <div className="row justify-content-end mt-4">
  <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
      <div className="d-flex align-items-center me-3">
        <span className="me-2">Rows per page:</span>
        <select
          value={rowsPerPage}
          onChange={(e) => handleRowsPerPageChange(e.target.value)}
          className="form-control-sm"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </select>
      </div>
      <div className="me-md-3 mb-3 mb-md-0">
        <span>{count}</span>
      </div>
      <div className="pagination pagination-rounded mt-3">
        <ReactPaginate
          previousLabel={<i className="mdi mdi-chevron-left"></i>}
          nextLabel={<i className="mdi mdi-chevron-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageLinkClassName={"page-link"}
          breakLinkClassName={"break-link"}
          disabledClassName={"disabled"}
          initialPage={currentPage}
          disableInitialCallback={true}
          forcePage={currentPage}
          renderLink={(props) => {
            if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
              return (
                <a
                  {...props}
                  onClick={() =>
                    handlePageClick({
                      selected: pageCount - 1,
                    })
                  }
                >
                  {pageCount}
                </a>
              );
            }
            return <a {...props} />;
          }}
        />
      </div>
    </div>
  </div>
</div>
  

  {/* // ADDMODAL  */}
  <Modal className="modal-dialog" isOpen={modal_list}  toggle={() => { tog_list(); }}centered>
  <ModalHeader  className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list();}}> Add Agent</ModalHeader>
  <Form autoComplete="off" className="form-horizontal" 
  onSubmit={(e) => { e.preventDefault();   formik.handleSubmit();  return false; }}>
  <ModalBody>
  <Row>
    <Col md={12}>
    <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Name
        </label>

        <Input
          type="text"
          id="name-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Name"
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name || ""}
          invalid={formik.touched.name && formik.errors.name}
        />
        {formik.touched.name && formik.errors.name && <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>}
      </div>
      <div className="mb-3">
      <label htmlFor="customername-field" className="form-label">
       Email
      </label>
      <Input
                            type="text"
                            id="customername-field"
                            className="form-control"
                            placeholder="Enter Email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email || ""}
                            invalid={formik.touched.email && formik.errors.email}
                          />
                          {formik.touched.email && formik.errors.email && <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>}
                        </div>                        
    </Col>
    
  </Row>
</ModalBody>
    <ModalFooter>
      <div className="hstack gap-2 justify-content-end">
        <button type="button" className="btn btn-light" onClick={() => {setmodal_list(false); formik.resetForm()}}>
          Close
        </button>

        <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
          {loading ? <Loader /> : "Submit"}
        </button>
      </div>
    </ModalFooter>
  </Form>
</Modal>


     {/* UpdateModal */}
  <Modal className="modal-dialog" isOpen={modal_update_list}  toggle={() => { tog_update_list();}} centered>
  <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>
    Update Agent
  </ModalHeader>
  <Form autoComplete="off" className="form-horizontal"
    onSubmit={(e) => {
      e.preventDefault();
      validationUpdate.handleSubmit();
      return false;
    }}
  >
    <ModalBody>
      <Row>
      <Col md={12}>
    <div className="mb-3">
        <label htmlFor="title-field" className="form-label">
         Name
        </label>

        <Input
          type="text"
          id="name-field"
          className="form-control"
          autoComplete="off"
          placeholder="Enter Name"
          name="name"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.name || ""}
          invalid={validationUpdate.touched.name && validationUpdate.errors.name}
        />
        {validationUpdate.touched.name && validationUpdate.errors.name && <FormFeedback type="invalid">{validationUpdate.errors.name}</FormFeedback>}
      </div>
      <div className="mb-3">
      <label htmlFor="customername-field" className="form-label">
       Email
      </label>
      <Input
                            type="text"
                            id="customername-field"
                            className="form-control"
                            placeholder="Enter Email"
                            name="email"
                            onChange={validationUpdate.handleChange}
                            onBlur={validationUpdate.handleBlur}
                            value={validationUpdate.values.email || ""}
                            invalid={validationUpdate.touched.email && validationUpdate.errors.email}
                          />
                          {validationUpdate.touched.email && validationUpdate.errors.email && <FormFeedback type="invalid">{validationUpdate.errors.email}</FormFeedback>}
                        </div>   

                         {/* <div className="mb-3">
        <label htmlFor="status-field" className="form-label"> Status </label>
        <select
          id="status-field"
          //  className="form-control"
          name="isActive"
          onChange={validationUpdate.handleChange}
          onBlur={validationUpdate.handleBlur}
          value={validationUpdate.values.isActive || ""}
          className={`form-control ${validationUpdate.touched.isActive && validationUpdate.errors.isActive ? "is-invalid" : ""}`}
        >
          <option value="" disabled>
            Select Status
          </option>
          <option value={1}>Active</option>
          <option value={0}>Deactive</option>
        </select>
        {validationUpdate.touched.isActive && validationUpdate.errors.isActive && <FormFeedback type="invalid"> {validationUpdate.errors.isActive} </FormFeedback>}
      </div>                      */}
    </Col>
      </Row>
  
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button type="button" className="btn btn-light" onClick={() => {setmodal_update_list(false)}}>
              Close
            </button>

            <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
              {loading ? <Loader /> : "Submit"}
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AgentUsers;

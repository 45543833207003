import React, { useState, useEffect, useContext ,useRef} from "react";
import socketIOClient from "socket.io-client";
import "./lockchat.css";
import { Card, CardBody, Col, Row, Container, CardHeader, Dropdown,DropdownItem,DropdownMenu,DropdownToggle } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Apiservices, configServices } from "../../ApiService/ApiServices";
import { AdminContext } from '../../helpers/adminContext'; // Adjust the path as needed
import toast from "react-hot-toast";
import { formatMonthDateTime } from '../../helpers/common';
import { ChatPostGetData } from "../../ApiService/AxiosInstance";
import LockedChatSideBar from "./LockedChatSidebar";
import UserProfile from '../../assets/images/user.png'
import { Link } from "react-router-dom";

const CHAT_SOCKET_URL = Apiservices.BACKEND_CHAT_SOCKET_BACKEND_SERVER;

const LockedChat = () => {
  document.title = "Ultrapro | Locked Chat";
  const { adminInfo } = useContext(AdminContext);
  // const [messages, setMessages] = useState([]);
  // const [images, setImages] = useState([]);
  // const [imagePreviews, setImagePreviews] = useState([]);
  const [btnauto3, setbtnauto3] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [socketConnection, setSocketConnection] = useState(null);
  const [lockChatStatus, setlockChatStatus] = useState(true);
  const [selectedChat, setSelectedChat] = useState("");  
  const [authenticated, setAuthenticated] = useState(false);
  const [chatMessage,setChatMessages] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [CurrentAdminID, setCurrentAdminID] = useState(null);
  const [getActiveAgents,setActiveAgents] = useState([])
  const [selectedAgent, setSelectedAgent] = useState("Transfer");
  const [showChat, setShowChat] = useState(true);
  const chatBoxRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the chat box when messages update
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatMessage]);
  useEffect(() => {
    if(configServices.CHAT_SOCKET_CONNECTION !== "Disable") {
      let socket;
      if (!socketConnection) {
        socket = socketIOClient(CHAT_SOCKET_URL, {
          transports: ["websocket"],
          auth: {
            senderType: 'admin',
            headers: {
              Authorization: `${adminInfo?.jwt}`,
            },
          },
        // },
      });

      socket.on("connect", () => {
        setSocketConnection(socket); 
        setAuthenticated(true);
      });
      socket.on("chatTransfer", (response) => {
        if(response){
          setChatMessages([])
        }
      })

      socket.on("connect_error", (err) => {
        console.log("Socket connection error:", err);
      });

      socket.on("disconnect", () => {
        setSocketConnection(null);
        setAuthenticated(false);
      });
    socket.emit('joinRoom', chatId);

      socket.on("userchatMessageReceiver", (responceMsg) => {
        const messageContent = responceMsg?.messageContent;
        if(selectedChat?.acId === responceMsg?.senderId) {
          if (Array.isArray(messageContent) && messageContent.length > 0) {
            setChatMessages((prevMessages) => {
              const newMessages = messageContent.filter(
                (newMsg) => !prevMessages.some((prevMsg) => prevMsg._id === newMsg._id)
              );
              return [...prevMessages, ...newMessages];
            });
        }
        }
       
      });
      GetActiveAgent()
    }
    return () => {
      if (socketConnection) {
        socketConnection.disconnect();
      }
    };
  }
  setCurrentAdminID(adminInfo?.adminUserId)

  }, [socketConnection,adminInfo,selectedChat]);

  useEffect(() => {
    if (configServices.CHAT_SOCKET_CONNECTION !== "Disable" && socketConnection && authenticated && chatId) {
      socketConnection.emit("joinRoom", chatId); // Join the chat room
      chatmessageSocket(); // Listen for incoming messages
    }
  }, [chatId, socketConnection, authenticated]);
  
  const chatmessageSocket = () => {
    if (!socketConnection) return;
  
    socketConnection.on("adminchatMessageReceiver", (responceMsg) => {
      const messageContent = responceMsg[0]?.messageContent;
      if (Array.isArray(messageContent) && messageContent.length > 0) {
          setChatMessages((prevMessages) => {
            const newMessages = messageContent.filter(
              (newMsg) => !prevMessages.some((prevMsg) => prevMsg._id === newMsg._id)
            );
            return [...prevMessages, ...newMessages];
          });

        }

        });

  
    // Cleanup listener when component unmounts or chatId changes
    return () => {
      socketConnection.off("adminchatMessageReceiver");
    };
  };

  const handleUserSelect = (chat) => {
    if(configServices.CHAT_SOCKET_CONNECTION !== "Disable") {
      if(chat){
        // setSelectedChat(chat)
        socketConnection.emit("adminGetOneChatMessage", { chatId: chat?.chatMsg?.chatId});
        socketConnection.on("adminInitialChat",(data)=>{
          if(data){
          
          setChatMessages(data[0]?.messageContent);
          setChatId(data[0]?.chatId)
          setSelectedChat(chat)

          }
        })
 
      // setChatMessages(chat?.chatMsg?.messageContent);
      // setSelectedChat(chat)
      // setChatId(chat?.chatMsg?.chatId)
      }
    }
  };
  const sendMessage = async () => {
    if (!socketConnection) {
       console.error("Socket connection not established");
       return;
    }
    try {
      if (messageText.trim().length > 0) {
        let reqData = {
          senderType: 'agent',
          jwt: adminInfo.jwt,
          messageContent: messageText.trim(),
          senderId: selectedChat.acId,
          chatId: chatId,
          messageType: 'text',
       };
       if (socketConnection != null && authenticated) socketConnection.emit('adminChatMessage', reqData);
       setMessageText("");
      }else{
        toast.error("Please Enter Message")
      }
    } catch (err) {
       console.error("Error sending message:", err);
    }
 };



 const endChat = async (chatData) =>{
  try {

      const postForm = {
        chatId: chatData?.chatMsg?.chatId,
        adminId:adminInfo?.adminUserId,
        senderType:'agent',
        senderName: adminInfo?.name,
        senderEmail: adminInfo?.email,
      }
      // socketConnection.emit('joinRoom', chatId);
      socketConnection.emit("endChatMsg", postForm);
      setSelectedChat("");
      socketConnection.on("chatEnded", (data)=>{
        if(data){
         console.log(data)
        }
    });
    } catch (err){
      console.error(err);
    }
  };
  const GetActiveAgent = async () =>{
    
    try {
      const response = await ChatPostGetData(Apiservices.GET_ACTIVE_AGENT_LIST, {});
      if (response.status == true) {
       setActiveAgents(response.data)
      } else {
       console.log(response.message)
      }
    } catch (error) {
      
      toast.error(error.message)
    }
  }


const [loading,setLoading] = useState(false)
  const TransferChat = async (data) =>{
    const postForm = {
      currentAdminId:adminInfo?.adminUserId,
      chatId:chatId,
      isLocked:0,
      adminId:data.adminId,
      email:data.email,
      name:data.name,
    }
   
    try {
      setLoading(true)
      socketConnection.emit("transferChat",  postForm);
      socketConnection.on("chatTransfer", (response) => {
        if (response) {
          setLoading(false)
         if (response.status == true) {
          toast.success(response.message)
          setShowChat(true);
          setSelectedAgent('Transfer')
          setSelectedChat("");
          setChatMessages([]);
          } else {
          setLoading(false)
          toast.error(response.message)
          }
        }
      });
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }
  }

  const handleTransferChange = (data) => {
    setSelectedAgent(data.name); // Set selected agent in dropdown
    TransferChat(data); // Call TransferChat function with selected agent
  };
 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Live Chat" breadcrumbItem="Locked Chat" />
          <Row className="mb-4">
            <LockedChatSideBar
              lockChatStatus = {lockChatStatus}
              setlockChatStatus = { setlockChatStatus }
              setSelectedChat = { setSelectedChat }
              onUserSelect={handleUserSelect}
              // setUserId={setUserId}
            />
              {/* { showChat &&  lockChatStatus === true ? */}
            <Col xl={8}>
              <Card className="mb-0 chat-card">
                { showChat && selectedChat && lockChatStatus === true  ? (
                  
                  <>
                    <CardHeader className="chat-card-header d-flex align-items-center p-3 border-bottom">
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div className="d-flex align-items-center">
                        
                        { selectedChat.profileImg && <img src={selectedChat.profileImg} alt="profile" className="rounded-circle me-2" width="40" height="40" />}
                        { !selectedChat.profileImg && <img src={UserProfile} alt="profile" className="rounded-circle me-2" width="40" height="40" />}
                          <div>
                        <span>{selectedChat.fullName ? selectedChat.fullName : "-"}</span>
                          {selectedChat.email && <h6 className="mb-0">
                            <Link className="link-tag" to={`/allUsers/userDetails/${selectedChat?.chatMsg?.senderId}`} target="_blank">
                            <span className="text-decoration-underline">
                            {selectedChat.email ? selectedChat.email : "-"}
                            </span>
                          </Link>
                            </h6>}
                        </div>
                        </div>
                        <div className="d-flex">
                        {(adminInfo?.adminType === 1 || adminInfo?.permissions.some(data=>data.module==="Locked Chats" && data.actions.includes(3))) ? (
                            <>
                        {chatMessage.some(message => message.senderType === "agent" && message.message) && (
                            <div className="cursor-pointer chat-cursor" onClick={() => endChat(selectedChat)}>
                              End Chat
                            </div>
                          )} </>):null}

                          {(adminInfo?.adminType === 1 || adminInfo?.permissions.some(data=>data.module==="Locked Chats" && data.actions.includes(3))) ? (
                          <Dropdown
                            className="mx-2"
                            isOpen={btnauto3}
                            toggle={() => setbtnauto3(!btnauto3)}
                            id="dropdownMenuClickableInside"
                          >
                            <DropdownToggle tag="button" className="btn btn-info">
                              {selectedAgent} <i className="mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start">
                              <DropdownItem header>Select</DropdownItem>
                              
                              {loading ? (
                                <DropdownItem disabled>Loading...</DropdownItem>
                              ) : (
                                getActiveAgents.map((option) => (
                                  <DropdownItem
                                    key={option.id}
                                    onClick={() => handleTransferChange(option)}
                                  >
                                    {option.name}
                                  </DropdownItem>
                                ))
                              )}
                              
                            </DropdownMenu>
                          </Dropdown> ) :null}
                          </div>
                      </div>
                    </CardHeader>
                 
                    <CardBody className="chat-card-body">
                    <div className="chat-box custom-scrollbar " ref={chatBoxRef}
                    >
                    
                              {chatMessage.map((message, index) => (
                                <div key={index} className={message.senderType === "agent" ? "message-right mb-3" : "message-left mb-3"}>
                                  <div className={message.senderType === "agent" ? "message-bubble-right position-relative" : "message-bubble-left"}>
                                    {message.senderType === "user" && message.message && (
                                      <p className="mb-0">{message.message}</p>
                                    )}
                                    {message.senderType === "agent" && message.message && (
                                      <p className="mb-0">{message.message}</p>
                                    )}
                                    <span className={message.senderType === "agent" ? 'time text-white' : 'time text-white text-start'}>
                                      {formatMonthDateTime(new Date(message.startedAt))}
                                    </span>
                                  <small className="mb-0 usr_mail text-muted">{message.senderType === "agent" ? message.senderEmail :''}</small>
                                    
                                  </div>

                                </div>
                              ))}
                     
                       
                      
                      

                            </div>
                            {(adminInfo?.adminType === 1 || adminInfo?.permissions.some(data=>data.module==="Locked Chats" && data.actions.includes(3))) ? (
                            <>
                            {selectedChat.chatMsg?.lockUser[0] !== CurrentAdminID ?
                      <div className="mb-3">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type a message"
                            value={messageText}
                            onChange={(e) => setMessageText(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") sendMessage();
                            }}
                          />
                          <button className="btn btn-info waves-effect waves-light" type="button" disabled={ messageText != "" ? false : true } onClick={sendMessage}>
                            <i className="fab fa-telegram-plane" />
                          </button>
                        </div>
                      </div> : '' }
                      </> ):null}
                    </CardBody>
                  </>
                ) : (
                  <CardBody>
                    <h5>Please select a user to start the chat.</h5>
                  </CardBody>
                )}
              </Card>
            </Col>
            {/* :'' } */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LockedChat;
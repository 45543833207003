import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, Col, Tooltip, Container, Row, Spinner, DropdownMenu, DropdownItem, DropdownToggle, InputGroup, Dropdown, Modal, } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { AddressCell, AddressCellFull, handleNullValue, utcToNormalTime } from "../../helpers/function";
import { formatNumber } from "../../helpers/common";
import { AdminContext } from "../../helpers/adminContext";
import { Link } from "react-router-dom";



const CryptoRejectedWithdraw = () => {
    document.title = "Ultrapro | User Management";
    const today = new Date();
    // const kyc = location.state?.kyc
    const [downloadStartDate, setDownloadEndDate] = useState({});
    const [dateRange, setDateRange] = useState([null, null]);
    const [loading, setLoading] = useState(false);
    const [downLoading, setDownLoading] = useState(false);
    const [showModel, setShowModel] = useState(false)
    const [showDetails, setShowDetails] = useState({})
    const [searchLoad, setSearchLoad] = useState(false);
    const [btnauto3, setbtnauto3] = useState(false);
    const [searchValid, setSearchValid] = useState(false);
    const [inputSearch, setinputSearch] = useState('');
    const [formData, setFormData] = useState([]);
    const [totalPagination, setTotalPagination] = useState(1);
    const [totalLimit, setTotalLimit] = useState(10);
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const [count, setCount] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = totalLimit; // Number of items to display per page
    const pageCount = Math.ceil(totalPagination);
    const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1);
    //Permission List
    const {adminInfo} = useContext(AdminContext);
    const[adminType,setAdminType] = useState('');

    useEffect(() => {
        ipList();
        setAdminType(adminInfo?.adminType);
    }, [adminInfo?.adminType]);
    const ipList = async () => {
        setLoading(true);

        try {
            const response = await postData(Apiservices.WITHDRAW_CRYPTO_REJECTED_HISTORY);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);

            } else {
                toast.error(response.message);
                setLoading(false);

            }
        } catch (error) {
            toast.error("Internal Server Error...");
            setLoading(false);


        }
    };
    const [tooltipOpen_tally, setTooltipOpen_tally] = useState(false);
    const toggleTooltip_tally = () => setTooltipOpen_tally(!tooltipOpen_tally);
    //redirect tally page
    const handleTallyRedirect = async (userData) => {        
        let userId = userData?.userId
        if(userId){
            window.open(`/tallyDetails/${userId}`, '_blank');
        }
    }
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        handlePagePost(selected);

    };

    const handlePagePost = async (selected) => {
        setLoading(true);
        const postForm = {
            page: selected + 1,
            status: 'Rejected',
            limit:totalLimit,
            withdrawType:withType,
            walletType:wallType
        };
        if (dateRange[0] && dateRange[1]) {
            postForm.startDate = formatDate(dateRange[0]);
            postForm.endDate = formatDate(dateRange[1]);
        }
        if (inputSearch) {
            postForm.search = inputSearch;
        }

        try {
            // const response = await postData(`${Apiservices.WITHDRAW_CRYPTO_COMPLETED_HISTORY}?${queryString}`);
            const response = await postData(Apiservices.WITHDRAW_CRYPTO_REJECTED_HISTORY,postForm);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);
            } else {
                setLoading(false);
                toast.error(response.message);
            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };
    const handleShowModel = (value) => {
        setShowDetails(value)
        setShowModel(true)
    }
    const allowedActions = [1,2,3,4];

    const [rowsPerPage,setRowsPerPage] = useState(10);
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(parseInt(newRowsPerPage));
      }
    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true,
             minWidth:'100px'
        },
        {
            name: 'Date',
            selector: row => row.createdAt
                ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${(utcToNormalTime(row.createdAt).time)}`
                : "---",
            sortable: true,
             minWidth:'200px'
        },
        {
            name: 'Name',
            selector: row => handleNullValue(row.userDetails?.fullName),
            sortable: true,
            minWidth: '150px'

        },
        {
            name: "OS Type",
            selector: (row) => (row.userDetails?.osType == 0 ? 'Web' : 'Android' || '-'),
            sortable: true,
            minWidth: '150px',
          },

        {
            name: 'Email',
            selector: row => 
                <div className="d-flex align-items-center">
            <Link className="link-tag" to={`/allUsers/userDetails/${row.userId}`} target='_blank'>
              <span className="mx-1 text-decoration-underline">
                {row.userDetails?.email || '-'}
              </span>
            </Link>
          </div>,
            sortable: true,
             minWidth:'250px'
        },
        {
            name: 'Transaction ID',
            selector: row => <AddressCell address={row.transactionId} />,
            sortable: true,
             minWidth:'150px'
        },
        {
            name: 'Currency Name',
            selector: row => handleNullValue(row.currency),
            sortable: true,

            minWidth:'150px'
        },
        {
            name: 'Address',
            selector: row => <AddressCell address={row.withdrawAddress} />,
            sortable: true,
            minWidth:'150px'
        },

        {
            name: 'Total',
            selector: row => handleNullValue(formatNumber(row?.amount, row?.coinsList?.decimalPoint)),
            sortable: true,
            minWidth:'200px'

        },
        {
            name: 'Fee',
            selector: row => handleNullValue(formatNumber(row?.feeAmount, row?.coinsList?.decimalPoint)),
            sortable: true,
            minWidth:'200px'
            
        },

        // {
        //     name: 'TDS',
        //     selector: row => handleNullValue(formatNumber(row?.tds, row?.coinsList.decimalPoint)),
        //     sortable: true,
        //     minWidth:'100px'

        // },
        // {
        //     name: 'TDS Percentage',
        //     selector: row => `${handleNullValue((row.tdsPercentage).toFixed(2))}%`,
        //     sortable: true,
        //     grow:2

        // },

        {
            name: 'Receive',
            selector: row => handleNullValue(formatNumber(row?.receiveAmount, row?.coinsList?.decimalPoint)),
            sortable: true,
            minWidth:'200px'

        },
        {
            name: 'Withdraw Type',
            selector: row => handleNullValue(
                row.withdrawType === 0 ? 'Manual' : 
                row.withdrawType === 1 ? 'Automatic' : 
                row.withdrawType === 2 ? 'Internal' :   
                row.withdrawType === 3 ? 'Wallet Error' : '-'
            ),
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Wallet Type',
            selector: row => handleNullValue(
                row.walletType === 0 ? 'Reward Wallet' : 
                row.walletType === 1 ? 'Spot Wallet' : 
                row.walletType === 2 ? 'Referral Wallet' : '-'
            ),
            sortable: true,
            minWidth: '150px'
        },
        ...(adminType === 1 || adminInfo.permissions.some(data => data.module === "Rejected History" && allowedActions.some(action => data.actions.includes(action))) ? [
        {
            name: 'Action',
            cell: row => (
                <div className="edit d-flex">
                    {(adminType === 1 || adminInfo.permissions.some(data => 
                data.module === "Rejected History" && data.actions.includes(2))) ? (
                    <>
                        <span id={`tooltipEmail${row.id}`} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline mx-1" style={{ fontSize: '15px', cursor: 'pointer' }}></span>
                        <Tooltip placement="top" isOpen={tooltipOpen1} target={`tooltipEmail${row.id}`} toggle={toggleTooltip1}> View </Tooltip>
                    </>
                ): null}
                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                        data.module === "Pending History" && data?.actions?.includes(3))) ? (
                            <>
                                <span id={`tooltipTally${row.id}`} onClick={()=> handleTallyRedirect(row)} className="mdi mdi-note-outline"
                                style={{ fontSize: '15px', cursor: 'pointer' }}></span>
                                <Tooltip placement="top" isOpen={tooltipOpen_tally} target={`tooltipTally${row.id}`} toggle={toggleTooltip_tally}>
                                    Tally Withdraw 
                                </Tooltip>
                            </>
                    ):null}

                </div>
            ),
            ignoreRowClick: true,
            // allowOverflow: true,
            // button: true,
        }]: []),
    ];

    const handleSearchChanage = (e) => {
        const value = e.target.value
        if (value == '') {
            ipList()
            setSearchValid(false)
        }
        setinputSearch(value)

    }


    const handleSearch = async () => {
        if (inputSearch !== '') {
            setSearchLoad(true);
            const postForm = {
                search: inputSearch.trim(),
                 status:'Rejected'
                // page: currentPage + 1,
                // limit: itemsPerPage,
            };

          
            try {

                const response = await postData(Apiservices.WITHDRAW_CRYPTO_REJECTED_HISTORY,postForm);
                if (response.status) {
                    setSearchLoad(false);
                    setFormData(response.data.data);
                    setTotalPagination(response.data.totalPages);


                } else {
                    setSearchLoad(false);
                }


            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
                setSearchLoad(false);
            }
        } else {
            setSearchValid(true)
        }
    }


    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    const handleDateChange = ([start, end]) => {
        const formattedStart = start ? formatDate(start) : null;
        const formattedEnd = end ? formatDate(end) : null;
        setDateRange([start, end]);
        if (!formattedStart && !formattedEnd) {
            ipList();
            setDownloadEndDate({})
        }

        if (formattedStart && formattedEnd) {
            setDownloadEndDate({ startDate: formattedStart, endDate: formattedEnd })
            handleDate({ startDate: formattedStart, endDate: formattedEnd });
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    const handleDate = async ({ startDate, endDate }) => {
        setLoading(true);
        const postForm = {
            startDate: startDate,
            endDate: endDate,
             status:'Rejected'
        }
        try {

            const response = await postData(Apiservices.WITHDRAW_CRYPTO_REJECTED_HISTORY,postForm);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);


            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };
 

    const handleDownload = async (type) => {
        setDownLoading(true)
        try {
            const postForm = {  status:'Rejected' };
            if (downloadStartDate.startDate) {
                postForm.startDate = downloadStartDate.startDate;
            }
            if (downloadStartDate.endDate) {
                postForm.endDate = downloadStartDate.endDate;
            }
            if (inputSearch) {
                postForm.search = inputSearch;
            }
            postForm.download = type;
            const queryString = new URLSearchParams(postForm).toString();

            const response = await axiosInstance.post(`${Apiservices.WITHDRAW_CRYPTO_REJECTED_HISTORY}?${queryString}`, {
                // Include any data you need to send with the POST request here
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: 'blob'
            });
            if (response.data) {
                setDownLoading(false)
                const blob = response.data;

                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                // link.setAttribute('download', `${user}.xlsx`);
                link.setAttribute('download', `CryptoRejectedUserHistory.${type}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                toast.error(response.data.message)
                setDownLoading(false)

            }
        } catch (err) {
            setDownLoading(false)
            console.error('Error downloading the file', err);
        }
    };
    const [btnauto4, setbtnauto4] = useState(false);
    
    
    const [FileName, setFileName] = useState("Crypto Rejected History");
    const [walletType, setWalletType] = useState('Wallet Type');
    const [withdrawType, setWithdrawType] = useState('Withdraw Type');
    const handleFilter = async (apiName, type) => {
        const postform = {
            status: 'Rejected',
            withdrawType: type // Send 0 for Manual, 1 for Automatic, or null for All
        };
        
        setLoading(true);
        try {
            const response = await postData(apiName, postform);
            if (response.status) {
                setFormData(response.data.data);  
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit);
                setCount(response.data.currentCount);
            } else {
                toast.error(response.message);  
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");  
        } finally {
            setLoading(false);  
        }
    };
    
    const handleFilter1 = async (apiName, type) => {
        const postform = {
            status: 'Rejected',
            walletType: type 
        };
        
        setLoading(true);
        try {
            const response = await postData(apiName, postform);
            if (response.status) {
                setFormData(response.data.data);  
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit);
                setCount(response.data.currentCount);
            } else {
                toast.error(response.message);  
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");  
        } finally {
            setLoading(false);  
        }
    };
    const [withType,setWithType] = useState()
    const [wallType,setWallType] = useState()
      const handleDropdownClick = (item, type) => {
        setWithdrawType(item);
        setDownloadEndDate({});
        setDateRange([null, null]);
    
        // Update withdrawType based on selected type
        switch (item) {
            case "Manual":
                setFileName("Manual");
                handleFilter(Apiservices.WITHDRAW_CRYPTO_REJECTED_HISTORY, 0); 
                setWithType(0)
                break;
    
            case "Automatic":
                setFileName("Automatic");
                handleFilter(Apiservices.WITHDRAW_CRYPTO_REJECTED_HISTORY, 1);  
                setWithType(1)
                break;
            case "Internal":
                setFileName("Internal");
                handleFilter(Apiservices.WITHDRAW_CRYPTO_COMPLETED_HISTORY, 2);  
                setWithType(2)
                break;
            case "Wallet Error":
                setFileName("Wallet Error");
                handleFilter(Apiservices.WITHDRAW_CRYPTO_COMPLETED_HISTORY, 3);  
                setWithType(3)
                break;
        
            default:
                setFileName("All");
                handleFilter(Apiservices.WITHDRAW_CRYPTO_REJECTED_HISTORY, null);  
                break;
        }
    };
    
        
          const handleDropdownClick1 = (item) => {
            setWalletType(item)
            setDownloadEndDate({});
            setDateRange([null, null]);
            switch (item) {
              case "Reward Wallet":
                setFileName("Reward Wallet");
                handleFilter1(Apiservices.WITHDRAW_CRYPTO_REJECTED_HISTORY,0);  
                setWallType(0)
                break;
        
              case "Spot Wallet":
                handleFilter1(Apiservices.WITHDRAW_CRYPTO_REJECTED_HISTORY,1);  
                setFileName("Spot Wallet");
                setWallType(1)
                break;
                case "Referral Wallet":
                handleFilter1(Apiservices.WITHDRAW_CRYPTO_REJECTED_HISTORY,2);  
                setFileName("Referral Wallet");
                setWallType(2)
                break;
            
              default:
                break;
            }
          };
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Crypto Withdraw" breadcrumbItem="Rejected History" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col sm={4} lg={3}>
                                            <div className="input-group">
                                                <Input
                                                    // onChange={handleInputChange}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name,Email,Address,ID ..."
                                                    aria-label="Recipient's username"
                                                    onChange={handleSearchChanage}
                                                    invalid={searchValid}
                                                    onKeyPress={handleKeyPress}
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="submit"
                                                        onClick={() => handleSearch()} disabled={searchLoad}
                                                    >
                                                        {searchLoad ? (
                                                            <Spinner className="text-light" size="sm" />
                                                        ) : (
                                                            <i className="ri-search-line" />
                                                        )}

                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={4} lg={3}>
                                            <div className="form-group mb-4">

                                                <div>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="Select Start to End Date"
                                                            options={{
                                                                mode: 'range',
                                                                altInput: true,
                                                                altFormat: "F j, Y",
                                                                dateFormat: "Y-m-d",
                                                                maxDate: today,

                                                            }}
                                                            value={dateRange}
                                                            onChange={handleDateChange}
                                                        />
                                                    </InputGroup>
                                                </div>

                                            </div>
                                        </Col>

                                        <Col className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                                {/* <Dropdown
                                                    isOpen={btnauto3}
                                                    toggle={() => setbtnauto3(!btnauto3)}
                                                    id="dropdownMenuClickableInside"
                                                    disabled={downLoading}
                                                >
                                                    <DropdownToggle tag="button" className="btn btn-info">
                                                        {downLoading ? <Loader /> : "Download"}

                                                        <i className="mdi mdi-chevron-down" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start">
                                                        <DropdownItem header>Select</DropdownItem>
                                                        <DropdownItem onClick={() => handleDownload('xlsx')}>Excel</DropdownItem>
                                                        <DropdownItem onClick={() => handleDownload('csv')}>CSV</DropdownItem>
                                                        <DropdownItem onClick={() => handleDownload('pdf')}>PDF</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown> */}
<Dropdown className="mx-2"
                                                    isOpen={btnauto4}
                                                    toggle={() => setbtnauto4(!btnauto4)}
                                                    id="dropdownMenuClickableInside"
                                                >
                                                    <DropdownToggle tag="button" className="btn btn-info">
                                                        {withdrawType}
                                                        <i className="mdi mdi-chevron-down" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start ">
                                                        <DropdownItem header>Select</DropdownItem>
                                                        <DropdownItem onClick={() => handleDropdownClick("All", null)}>
                                                            All
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => handleDropdownClick("Manual", 0)}>
                                                            Manual
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => handleDropdownClick("Automatic", 1)}>
                                                            Automatic
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => handleDropdownClick("Internal", 2)}>
                                                            Internal
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => handleDropdownClick("Wallet Error", 3)}>
                                                            Wallet Error
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>



                                                 <Dropdown className="mx-2"
                                                    isOpen={btnauto3}
                                                    toggle={() => setbtnauto3(!btnauto3)}
                                                    id="dropdownMenuClickableInside"
                                                >
                                                    <DropdownToggle tag="button" className="btn btn-info">
                                                      
                                                       {walletType}
                                                        <i className="mdi mdi-chevron-down" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start ">
                                                        <DropdownItem header>Select</DropdownItem>
                                                        <DropdownItem
                                                            onClick={() => handleDropdownClick1("All" , null)}
                                                        >
                                                            Over All Wallet
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() => handleDropdownClick1("Reward Wallet", 0)}
                                                        >
                                                           Reward Wallet
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() =>
                                                                handleDropdownClick1("Spot Wallet",1)
                                                            }
                                                        >
                                                           Spot Wallet
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() =>
                                                                handleDropdownClick1("Referral Wallet",2)
                                                            }
                                                        >
                                                          Referral Wallet
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                                 <Button
                                                    color="info"
                                                    className="add-btn mx-2"
                                                    onClick={() => handleDownload('xlsx')}
                                                    id="create-btn"
                                                    disabled={downLoading}
                                                    >
                                                    <i className="mdi mdi-download-outline"></i>

                                                    {downLoading ? <Loader /> : "Download"}
                                                    </Button>

                                            </div>
                                        </Col>
                                    </Row>


                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={formData}
                                            paginationServer
                                            paginationTotalRows={formData?.length}
                                            progressPending={loading}
                                            persistTableHead={true}
                                            progressComponent={<div className="py-3">Loading...</div>}
                                            // noDataComponent={formData.length===0}
                                            noDataComponent={<div className="py-3">No records found</div>}
                                            fixedHeader={true}
                                        />
                                    </div>
                                  

                                                        <div className="container">
                                                        <div className="row justify-content-end mt-4">
                                                        <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                                                            <div className="d-flex align-items-center me-3">
                                                              <span className="me-2">Rows per page:</span>
                                                              <select
                                                                value={rowsPerPage}
                                                                onChange={(e) => handleRowsPerPageChange(e.target.value)}
                                                                className="form-control-sm"
                                                              >
                                                                <option value={10}>10</option>
                                                                <option value={20}>20</option>
                                                                <option value={30}>30</option>
                                                                <option value={50}>50</option>
                                                              </select>
                                                            </div>
                                                            <div className="me-md-3 mb-3 mb-md-0">
                                                              <span>{count}</span>
                                                            </div>
                                                            <div className="pagination pagination-rounded mt-3">
                                                              <ReactPaginate
                                                                previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                                nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageClick}
                                                                containerClassName={"pagination"}
                                                                activeClassName={"active"}
                                                                pageLinkClassName={"page-link"}
                                                                breakLinkClassName={"break-link"}
                                                                disabledClassName={"disabled"}
                                                                initialPage={currentPage}
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                                renderLink={(props) => {
                                                                  if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                                                    return (
                                                                      <a
                                                                        {...props}
                                                                        onClick={() =>
                                                                          handlePageClick({
                                                                            selected: pageCount - 1,
                                                                          })
                                                                        }
                                                                      >
                                                                        {pageCount}
                                                                      </a>
                                                                    );
                                                                  }
                                                                  return <a {...props} aria-label="Link description" />;
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                   

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static">
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                    Rejected Withdraw History View
                    </h5>
                    <button
                        onClick={() => {
                            setShowModel(false);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body custom-modal-body">
                    <div className="table-responsive">
                        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td><b>Name</b></td>
                                    <td>{showDetails?.userDetails?.fullName || '-'}</td>
                                </tr>
                                 <tr>
                                    <td><b>Email</b></td>
                                    <td>{showDetails?.userDetails?.email || '-'}</td>
                                </tr>


                            
                                <tr>
                                    <td><b>Transaction ID</b></td>
                                    <td><AddressCellFull address={showDetails?.transactionId || '-'} /></td>
                                </tr>
                                <tr>
                                    <td><b>Currency Name</b></td>
                                    <td>{showDetails?.currency || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Withdraw Address</b></td>
                                    <td><AddressCellFull address={showDetails?.withdrawAddress || '-'} /></td>
                                </tr>
                                <tr>
                                    <td><b>Amount</b></td>
                                    <td>{formatNumber(showDetails?.amount, showDetails?.coinsList?.decimalPoint) || 0}</td>
                                </tr>
                                <tr>
                                <td><b>Network Type</b></td>
                                <td>{showDetails?.networkName || '-'}</td>
                                </tr>
                                <tr>

                                    <td>  <b>
                                        Fee Amount{" "}
                                        {showDetails.feeType?.feeType === 1
                                            ? `(${ (showDetails?.amount, showDetails?.feeAmount)}%)`
                                            : ""}
                                    </b></td>
                                    <td>{showDetails?.feeAmount ? formatNumber(showDetails?.feeAmount, showDetails?.coinsList?.decimalPoint) : 0}</td>

                                </tr>
                                {/* <tr>
                                    <td><b>TDS ({showDetails.tdsPercentage ? showDetails.tdsPercentage : 'N/A'}%)</b></td>
                                    <td>{showDetails.tds?.toFixed(8) || '0'}</td>
                                </tr> */}
                                {/* <tr>
                                    <td><b>TDS Percentage</b></td>
                                    <td>{showDetails.tdsPercentage?.toFixed(2) || 'N/A'}%</td>
                                </tr> */}
                                {/* <tr>
                                    <td><b>TDS Amount</b></td>
                                    <td>{showDetails.tds ? formatNumber(showDetails?.tds, showDetails?.decimalPoint) : 'N/A'}</td>
                                </tr> */}
                                <tr>
                                    <td><b>Receive Amount</b></td>
                                    <td>{ formatNumber(showDetails?.receiveAmount, showDetails?.coinsList?.decimalPoint) || 0} <span>({showDetails.currency})</span></td>
                                </tr>
                                <tr>
                                    <td><b>Rejected By Admin Email</b></td>
                                    <td>{showDetails?.adminEmail || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Rejected By Admin Name</b></td>
                                    <td> {showDetails?.adminName || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Rejected Reason</b></td>
                                    <td>{showDetails?.reason || '-'}</td>
                                </tr>
                                <tr>

                                    <td><b>Request Date</b></td>
                                    <td>{utcToNormalTime(showDetails?.createdAt).date} {utcToNormalTime(showDetails.createdAt).time}</td>
                                </tr>
                                <tr>
                                    <td><b>Rejected Date</b></td>
                                    <td>{utcToNormalTime(showDetails?.updatedAt).date} {utcToNormalTime(showDetails.updatedAt).time}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>

        </React.Fragment>
    );
};

export default CryptoRejectedWithdraw;

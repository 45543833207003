import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, Col, Tooltip, Container, Row, Spinner, InputGroup, Modal, } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../../ApiService/AxiosInstance";
import { Apiservices } from "../../../ApiService/ApiServices";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loader from "../../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { AddressCell, AddressCellFull, handleNullValue, utcToNormalTime,percentCalculate } from "../../../helpers/function";
import { formatNumber } from "../../../helpers/common";
import { AdminContext } from "../../../helpers/adminContext";
import { Link } from "react-router-dom";




const FiatDepositComplete = () => {
    document.title = "Ultrapro | Fiat Deposit Completed";
    const today = new Date();
    // const kyc = location.state?.kyc
    const [downloadStartDate, setDownloadEndDate] = useState({});
    const [dateRange, setDateRange] = useState([null, null]);
    const [loading, setLoading] = useState(false);
    const [downLoading, setDownLoading] = useState(false);
    const [showModel, setShowModel] = useState(false)
    const [showDetails, setShowDetails] = useState({})
    const [searchLoad, setSearchLoad] = useState(false);
    const [searchValid, setSearchValid] = useState(false);
    const [inputSearch, setinputSearch] = useState('');
    const [formData, setFormData] = useState([]);
    const [totalPagination, setTotalPagination] = useState(1);
    const [totalLimit, setTotalLimit] = useState(10);
    const [tooltipOpen1, setTooltipOpen1] = useState(false);
    const [count, setCount] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = totalLimit; // Number of items to display per page
    const pageCount = Math.ceil(totalPagination);
    const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1);
    //Permission List
    const {adminInfo} = useContext(AdminContext);
    const[adminType,setAdminType] = useState('');

    useEffect(() => {
        ipList();
        setAdminType(adminInfo?.adminType);
    }, [adminInfo?.adminType]);
    const ipList = async () => {
        setLoading(true);

        try {
            const response = await postData(Apiservices.FIAT_DEPOSIT_COMPLETED_HISTORY);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);

            } else {
                toast.error(response.message);
                setLoading(false);

            }
        } catch (error) {
            toast.error("Internal Server Error...");
            setLoading(false);


        }
    };
    const [tooltipOpen_tally, setTooltipOpen_tally] = useState(false);
    const toggleTooltip_tally = () => setTooltipOpen_tally(!tooltipOpen_tally);
    //redirect tally page
    const handleTallyRedirect = async (userData) => {        
        let userId = userData?.userId
        if(userId){
            window.open(`/tallyDetails/${userId}`, '_blank');
        }
    }
    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        handlePagePost(selected);

    };

    const handlePagePost = async (selected) => {
        setLoading(true);
        const postForm = {
            page: selected + 1,
            status: 2,
            limit:totalLimit,
        };
        if (dateRange[0] && dateRange[1]) {
            postForm.startDate = formatDate(dateRange[0]);
            postForm.endDate = formatDate(dateRange[1]);
        }
        if (inputSearch) {
            postForm.search = inputSearch;
        }

        try {
            const response = await postData(Apiservices.FIAT_DEPOSIT_COMPLETED_HISTORY,postForm);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);
            } else {
                setLoading(false);
                toast.error(response.message);
            }

        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };
    const handleShowModel = (value) => {
        setShowDetails(value)
        setShowModel(true)
    }
    const allowedActions = [1,2,3,4];

    const [rowsPerPage,setRowsPerPage] = useState(10);
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(parseInt(newRowsPerPage));
      }
    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true,
             minWidth:'100px'
        },
        {
            name: 'Date',
            selector: row => row.createdAt
                ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${(utcToNormalTime(row.createdAt).time)}`
                : "---",
            sortable: true,
             minWidth:'200px'
        },
        {
            name: 'Name',
            selector: row => handleNullValue(row?.fullName),
            sortable: true,
            minWidth: '150px'

        },
        {
            name: "OS Type",
            selector: (row) => (row?.osType == 0 ? 'Web' : 'Android' || '-'),
            sortable: true,
            minWidth: '150px',
          },

        {
            name: 'Email',
            selector: row => 
                <div className="d-flex align-items-center">
                    {row?.email ? 

            <Link className="link-tag" to={`/allUsers/userDetails/${row.userId}`} target='_blank'>
              <span className="mx-1 text-decoration-underline">
                {row?.email || '-'}
              </span>
            </Link> : <span>-</span>}
          </div>,
            sortable: true,
             minWidth:'250px'
        },
        {
            name: 'Transaction ID',
            selector: row => <AddressCell address={row.transactionId} />,
            sortable: true,
             minWidth:'150px'
        },
        {
            name: 'Currency Name',
            selector: row => handleNullValue(row.currency),
            sortable: true,

            minWidth:'150px'
        },
      
        {
            name: 'Total',
            selector: row => handleNullValue(formatNumber(row?.amount, row?.coinsList?.decimalPoint)),
            sortable: true,
            minWidth:'200px'

        },
        {
            name: 'Fee',
            selector: row => handleNullValue(formatNumber(row?.feeAmount, row?.coinsList?.decimalPoint)),
            sortable: true,
            minWidth:'200px'
            
        },

        // {
        //     name: 'TDS',
        //     selector: row => handleNullValue(formatNumber(row?.tds, row?.coinsList.decimalPoint)),
        //     sortable: true,
        //     minWidth:'100px'

        // },
        // {
        //     name: 'TDS Percentage',
        //     selector: row => `${handleNullValue((row.tdsPercentage).toFixed(2))}%`,
        //     sortable: true,
        //     grow:2

        // },

        {
            name: 'Receive',
            selector: row => handleNullValue(formatNumber(row?.receiveAmount, row?.coinsList?.decimalPoint)),
            sortable: true,
            minWidth:'200px'

        },
       
        ...(adminType === 1 || adminInfo.permissions.some(data => data.module === "Fiat Deposit Completed History" && allowedActions.some(action => data.actions.includes(action))) ? [
        {
            name: 'Action',
            cell: row => (
                <div className="edit d-flex">
                    {(adminType === 1 || adminInfo.permissions.some(data => 
                data.module === "Fiat Deposit Completed History" && data.actions.includes(2))) ? (
                    <>
                        <span id={`tooltipEmail${row.id}`} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline mx-1" style={{ fontSize: '15px', cursor: 'pointer' }}></span>
                        <Tooltip placement="top" isOpen={tooltipOpen1} target={`tooltipEmail${row.id}`} toggle={toggleTooltip1}> View </Tooltip>
                    </>
                ): null}
                    {(adminType === 1 || adminInfo?.permissions.some(data => 
                        data.module === "Fiat Deposit Completed History" && data?.actions?.includes(3))) ? (
                            <>
                                <span id={`tooltipTally${row.id}`} onClick={()=> handleTallyRedirect(row)} className="mdi mdi-note-outline"
                                style={{ fontSize: '15px', cursor: 'pointer' }}></span>
                                <Tooltip placement="top" isOpen={tooltipOpen_tally} target={`tooltipTally${row.id}`} toggle={toggleTooltip_tally}>
                                    Tally Deposit 
                                </Tooltip>
                            </>
                    ):null}

                </div>
            ),
            ignoreRowClick: true,
            // allowOverflow: true,
            // button: true,
        }]: []),
    ];

    const handleSearchChanage = (e) => {
        const value = e.target.value
        if (value == '') {
            ipList()
            setSearchValid(false)
        }
        setinputSearch(value)

    }


    const handleSearch = async () => {
        if (inputSearch !== '') {
            setSearchLoad(true);
            const postForm = {
                search: inputSearch.trim(),
                 status:1
                // page: currentPage + 1,
                // limit: itemsPerPage,
            };

          
            try {

                const response = await postData(Apiservices.FIAT_DEPOSIT_COMPLETED_HISTORY,postForm);
                if (response.status) {
                    setSearchLoad(false);
                    setFormData(response.data.data);
                    setTotalPagination(response.data.totalPages);
                    setTotalLimit(response.data.limit)
                    setCount(response.data.currentCount);
    

                } else {
                    setSearchLoad(false);
                }


            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
                setSearchLoad(false);
            }
        } else {
            setSearchValid(true)
        }
    }


    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    const handleDateChange = ([start, end]) => {
        const formattedStart = start ? formatDate(start) : null;
        const formattedEnd = end ? formatDate(end) : null;
        setDateRange([start, end]);
        if (!formattedStart && !formattedEnd) {
            ipList();
            setDownloadEndDate({})
        }

        if (formattedStart && formattedEnd) {
            setDownloadEndDate({ startDate: formattedStart, endDate: formattedEnd })
            handleDate({ startDate: formattedStart, endDate: formattedEnd });
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    const handleDate = async ({ startDate, endDate }) => {
        setLoading(true);
        const postForm = {
            startDate: startDate,
            endDate: endDate,
             status:1
        }
        try {

            const response = await postData(Apiservices.FIAT_DEPOSIT_COMPLETED_HISTORY,postForm);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount);


            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };
 


    const handleDownload = async (type) => {
        setDownLoading(true);
        try {
            const postForm = {};
            
            if (downloadStartDate.startDate) postForm.startDate = downloadStartDate.startDate;
            if (downloadStartDate.endDate) postForm.endDate = downloadStartDate.endDate;
            if (inputSearch) postForm.search = inputSearch;
            const hasFilters = Object.keys(postForm).length > 0;
                const response = await axiosInstance.post(
                Apiservices.FIAT_DEPOSIT_COMPLETED_HISTORY_DOWNLOAD,
                hasFilters ? postForm : undefined,  
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    responseType: 'blob'
                }
            );
    
            if (response.data) {
                setDownLoading(false);
    
                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Fiat Deposit Completed History.${type}`);
                document.body.appendChild(link);
                link.click();
    
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error(response.data.message);
                setDownLoading(false);
            }
        } catch (err) {
            setDownLoading(false);
            console.error('Error downloading the file', err);
        }
    };
    
     
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Fiat Deposit" breadcrumbItem="Fiat Deposit Completed History" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col sm={4} lg={3}>
                                            <div className="input-group">
                                                <Input
                                                    // onChange={handleInputChange}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Name,Email,Address,ID ..."
                                                    aria-label="Recipient's username"
                                                    onChange={handleSearchChanage}
                                                    invalid={searchValid}
                                                    onKeyPress={handleKeyPress}
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="submit"
                                                        onClick={() => handleSearch()} disabled={searchLoad}
                                                    >
                                                        {searchLoad ? (
                                                            <Spinner className="text-light" size="sm" />
                                                        ) : (
                                                            <i className="ri-search-line" />
                                                        )}

                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={4} lg={3}>
                                            <div className="form-group mb-4">

                                                <div>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="Select Start to End Date"
                                                            options={{
                                                                mode: 'range',
                                                                altInput: true,
                                                                altFormat: "F j, Y",
                                                                dateFormat: "Y-m-d",
                                                                maxDate: today,

                                                            }}
                                                            value={dateRange}
                                                            onChange={handleDateChange}
                                                        />
                                                    </InputGroup>
                                                </div>

                                            </div>
                                        </Col>

                                        <Col className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                               

                                                 <Button
                                                    color="info"
                                                    className="add-btn mx-2"
                                                    onClick={() => handleDownload('xlsx')}
                                                    id="create-btn"
                                                    disabled={downLoading}
                                                    >
                                                    <i className="mdi mdi-download-outline"></i>

                                                    {downLoading ? <Loader /> : "Download"}
                                                    </Button>

                                            </div>
                                        </Col>
                                    </Row>


                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={formData}
                                            paginationServer
                                            paginationTotalRows={formData?.length}
                                            progressPending={loading}
                                            persistTableHead={true}
                                            progressComponent={<div className="py-3">Loading...</div>}
                                            // noDataComponent={formData.length===0}
                                            noDataComponent={<div className="py-3">No records found</div>}
                                            fixedHeader={true}
                                        />
                                    </div>
                                  

                                                        <div className="container">
                                                        <div className="row justify-content-end mt-4">
                                                        <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                                                            <div className="d-flex align-items-center me-3">
                                                              <span className="me-2">Rows per page:</span>
                                                              <select
                                                                value={rowsPerPage}
                                                                onChange={(e) => handleRowsPerPageChange(e.target.value)}
                                                                className="form-control-sm"
                                                              >
                                                                <option value={10}>10</option>
                                                                <option value={20}>20</option>
                                                                <option value={30}>30</option>
                                                                <option value={50}>50</option>
                                                              </select>
                                                            </div>
                                                            <div className="me-md-3 mb-3 mb-md-0">
                                                              <span>{count}</span>
                                                            </div>
                                                            <div className="pagination pagination-rounded mt-3">
                                                              <ReactPaginate
                                                                previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                                nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageClick}
                                                                containerClassName={"pagination"}
                                                                activeClassName={"active"}
                                                                pageLinkClassName={"page-link"}
                                                                breakLinkClassName={"break-link"}
                                                                disabledClassName={"disabled"}
                                                                initialPage={currentPage}
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                                renderLink={(props) => {
                                                                  if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                                                    return (
                                                                      <a
                                                                        {...props}
                                                                        onClick={() =>
                                                                          handlePageClick({
                                                                            selected: pageCount - 1,
                                                                          })
                                                                        }
                                                                      >
                                                                        {pageCount}
                                                                      </a>
                                                                    );
                                                                  }
                                                                  return <a {...props} aria-label="Link description" />;
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                   

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static">
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                    Completed Withdraw History View
                    </h5>
                    <button
                        onClick={() => {
                            setShowModel(false);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body custom-modal-body">
                {/* This record is locked by <strong>{LockedData?.name}</strong>. */}
                    <div className="table-responsive">
                        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                                  
                            <tbody>
                            
                          
                             <tr>
                                    <td><b>Name</b></td>
                                    <td>{showDetails?.fullName || '-'}</td>
                                </tr>
                                <tr>
                                    <td><b>Email</b></td>
                                    <td>{showDetails?.email || '-'}</td>
                                </tr>
                                <tr>
                                <td><b>Bank Account Number</b></td>
                                <td>{showDetails?.userBank?.accountNumber || '-'}</td>
                                </tr>
                                <tr>
                                <td><b>Bank IFSC Code</b></td>
                                <td>{showDetails?.userBank?.ifscCode || '-'}</td>
                                </tr>
                                <tr>
                                <td><b>Bank Name</b></td>
                                <td>{showDetails?.userBank?.bankName || '-'}</td>
                                </tr>

                                <tr>
                                <td><b>Bank Branch Name</b></td>
                                <td>{showDetails?.userBank?.branchName || '-'}</td>
                                </tr>

                                <tr>
                                    <td><b>Transaction ID</b></td>
                                    <td><AddressCellFull address={showDetails?.transactionId || '-'} /></td>
                                </tr>
                                <tr>

                                    <td><b>Currency Name</b></td>
                                    <td>{showDetails?.currency || '-'}</td>

                                </tr>
                               
                                <tr>
                                    <td><b>Deposit Amount</b></td>
                                    <td>{showDetails?.amount 
                                    // ? showDetails.amount.toFixed(8) 
                                    ? formatNumber(showDetails?.amount, showDetails?.coinsList?.decimalPoint)
                                    : '0'}</td>
                                </tr>
                                <tr>
                                {/* <td><b>Network Type</b></td>
                                <td>{showDetails?.networkName || '-'}</td> */}
                                </tr>
                                <tr>
                                    <td>  <b>
                                        Fee Amount{" "}
                                        {showDetails.feeType?.feeType === 1
                                            ? `(${percentCalculate(showDetails?.amount, showDetails?.feeAmount)}%)`
                                            // ?  formatNumber(showDetails.feeAmount, showDetails?.coinsList.decimalPoint)
                                            : ''}
                                    </b></td>
                                    <td>{formatNumber(showDetails?.feeAmount, showDetails?.coinsList?.decimalPoint)  || '-'}</td>
                                </tr>
                                {/* <tr>

                                    <td><b>TDS ({showDetails.tdsPercentage ? showDetails.tdsPercentage : 0}%)</b></td>
                                    <td>{showDetails.tds ? showDetails.tds.toFixed(8) : '0'}</td>

                                </tr> */}
                                {/* <tr>
                                    <td><b>TDS Amount</b></td>
                                    <td>{showDetails.tds ? formatNumber(showDetails?.tds, showDetails?.decimalPoint) : 0}</td>
                                </tr> */}
                                <tr>
                                    <td><b>Receive Amount</b></td>
                                    <td>{showDetails?.receiveAmount ? formatNumber(showDetails?.receiveAmount, showDetails?.coinsList?.decimalPoint) : 0} <span>({showDetails.currency})</span></td>
                                </tr>
                                <tr>
                                    <td><b>Request Date & Time</b></td>
                                    <td>{utcToNormalTime(showDetails?.createdAt).date} {utcToNormalTime(showDetails?.createdAt).time}</td>
                                </tr>
                                <tr>

                                    <td><b>Transaction Proof</b></td>
                                    <td>
                                    <img src={showDetails?.transactionProof} alt='' style={{objectFit:'cover',width:'50%'}}/>
                                    </td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>

        </React.Fragment>
    );
};

export default FiatDepositComplete;

import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Card, CardBody, FormFeedback, CardImg, Col, Container, Modal, ModalBody, ModalFooter, Row, ModalHeader, Form, Spinner } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import Swal from "sweetalert2";
import { AdminContext } from "../../helpers/adminContext";
import { Editor } from "@tinymce/tinymce-react";


const TaskComponent = () => {
  document.title = "Ultrapro | Customer Task";
  const [loading, setLoading] = useState(false);
  const [getDiscoverList, setDisoverList] = useState([]);
  const [getId, setId] = useState("");
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  // const [sessionLink, setSessionLink] = useState('');
  // const [errors, setErrors] = useState('');
  const [showDetails, setShowDetails] = useState({})
  const [showModel, setShowModel] = useState(false)
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);
    //Searchfun
    const [inputSearch, setinputSearch] = useState("");
    const [searchValid, setSearchValid] = useState(false);
    const [searchLoad, setSearchLoad] = useState(false);

  //Permission List
  const { adminInfo } = useContext(AdminContext);
  const [adminType, setAdminType] = useState('')
  const [content, setContent] = useState("");
  //Add Modal
  const [modal_list, setmodal_list] = useState(false);
  function tog_list() {
    setmodal_list(!modal_list);
    formik.resetForm();
    setImage('')
  }
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
    setImage('')
  }
  const [getImage, setGetImage] = useState('')
  //classList api call
  useEffect(() => {
    fetchData();
    setAdminType(adminInfo?.adminType)
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.taskView);
      console.log(response);
      
      if (response) {
        setLoading(false);
        setTotalPagination(response.data.totalPages);
        setDisoverList(response.data.data);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      // Handle fetch error
    }
  };


  const handleShowModel = (value) => {
    setShowDetails(value)
    setShowModel(true)
  }
  const handleDelete = (value) => {
    showUserBlockAlert(confirmDelete, value)
  }

  const showUserBlockAlert = (onConfirm, row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to remove this record!`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        try {
          await onConfirm(row);
        } catch (error) {
          Swal.fire('Error!', 'There was an error deleting the record', 'error');
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // Additional actions after confirmation, if needed
      }
    });
  };

  const confirmDelete = async (row) => {

    const postForm = {
      video_Id: row.video_Id
    }
  
    try {
      const response = await postData(Apiservices.taskDelete, postForm)
      if (response.status) {
      
        Swal.fire('Success!', `${response.message}`, 'success');
        fetchData()

      } else {
        Swal.fire('Error!', `${response.message}`, 'error');
    
      }

    } catch (error) {
      toast.error("Internal Server Error..Try Again Later")
    }



  }

  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  const allowedActions = [1, 2, 3, 4];
  const columns = [
    {
      name: "S.No",
      // selector: (row, index) => currentPage * itemsPerPage + index + 1,
      selector: (row) => handleNullValue(row.serialNo),

      sortable: true,
      minWidth: '80px',
      maxWidth: '100px'
    },
    {
      name: "Image",
      selector: (row) => <img style={{ width: "100px" }} src={row.imageUrl || '-'}  alt=''/>,
      sortable: true,
      minWidth: '150px',
      maxWidth: '200px'
    },
    {
      name: "Title",
      selector: (row) => handleNullValue(row.title),
      sortable: true,
      minWidth: '150px',
      maxWidth: '200px'
    },
    {
      name: "Position",
      selector: (row) => handleNullValue(row.position),
      sortable: true,
      minWidth: '150px',
      maxWidth: '200px'
    },

    {
      name: "Short description",
      selector: (row) => handleNullValue(row.shortDescription),
      minWidth: '150px',
      maxWidth: '200px'
    },

    ...(adminType === 1 || adminInfo?.permissions.some(data =>
      data.module === "Probinar Academy" && data.actions.includes(3)) ? [
      {
        name: "Status",
        selector: (row) => (
          <div className="form-check form-switch form-switch-md mb-3 d-flex justify-content-center align-items-center">
            <input
              type="checkbox"
              className="form-check-input"
              id="customSwitchsizemd"
              checked={row.isActive === 1}
              onChange={() => handleUpdateStatusClick(row)}
              disabled={loading}
            />
          </div>
        ),
        sortable: true,
        minWidth: '100px',
        maxWidth: '100px'
      }] : []),
    {
      name: "Date & Time",
      selector: (row) => row.createdAt ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${handleNullValue(utcToNormalTime(row.createdAt).time)}` : "---",
      sortable: true,
      minWidth: '200px',
      maxWidth: '250px'
    },
    ...(adminType === 1 || adminInfo?.permissions.some(data =>
      data.module === "Probinar Academy" && allowedActions.some(action => data.actions.includes(action))) ? [
      {
        name: "Action",

        cell: (row) => (
          <div className="d-flex">
            {(adminType === 1 || adminInfo?.permissions.some(data =>
              data.module === "Probinar Academy" && data.actions.includes(3))) ? (

              <i style={{ fontSize: '15px', cursor: "pointer" }} className="mdi mdi-border-color cursor-pointer mx-1 mt-1" onClick={() => handleUpdate(row)}></i>
            ) : null}
            {(adminType === 1 || adminInfo?.permissions.some(data =>
              data.module === "Probinar Academy" && data.actions.includes(2))) ? (
              <i style={{ fontSize: '15px', cursor: 'pointer' }} onClick={() => handleShowModel(row)} className="mdi mdi-eye-outline mx-1"></i>
            ) : null}
            {(adminType === 1 || adminInfo?.permissions.some(data =>
              data.module === "Probinar Academy" && data.actions.includes(4))) ? (
              <i onClick={() => handleDelete(row)} className="mdi mdi-trash-can-outline" style={{ fontSize: '15px', cursor: 'pointer' }}></i>
            ) : null}
          </div>

        ),
        ignoreRowClick: true,
        minWidth: '150px',
        maxWidth: '200px'
      }] : []),
  ];



  //paginnation
 

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };
  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      limit: itemsPerPage,
    };
    try {
      const response = await postData(Apiservices.taskView, postForm);

      if (response.status) {
        setDisoverList(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  // const validateSessionLink = (value) => {
  //   let error = '';
  //   if (!value) {
  //     error = 'Session link is required';
  //   } else if (!value.endsWith('.mp4')) {
  //     error = 'Session link must be an MP4 file';
  //   }
  //   return error;
  // };
  //Add form validation
  const formik = useFormik({
    initialValues: {
      title: "",
      shortdiscription: '',
      // description: '',
      videolink: '',
      isActive: "",
      position: "",
      // content: [{ steps: "" }],
    },
    validationSchema: Yup.object({
      title: Yup.string().trim().required("Title is required"),
      shortdiscription: Yup.string().trim().required("short description is required"),
      // description: Yup.string().trim().required("description is required"),
      //   content: Yup.array()
      // .of(
      //   Yup.object().shape({
      //     steps: Yup.string().trim().required("Content Line is Required")
      //   })
      // )
      // .required("Content is Required"),
      position: Yup.string().trim()
        .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
        .required("Position is required"),
      videolink: Yup.string()
        .trim()
        .url("Please enter a valid URL")
        .required("Video Link is required"),
      isActive: Yup.string().trim().required("Status is required"),
    }),
    onSubmit: (values) => {
      handleAdd(values);
    },
  });

  //Addapicall
  const handleAdd = async (data) => {

    setLoading(true);

    if (!image) {
      toast.error("Image is required");
      setLoading(false);
      return;
    }
    if (!content) {
      toast.error("Content is required");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("url", data.videolink);
    formData.append('files', image);
    formData.append("isActive", data.isActive);
    formData.append("shortDescription", data.shortdiscription);
    formData.append("steps", '');
    formData.append("description", content);
    formData.append("position", data.position);
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value); 
    }

 
    try {
      const response = await postData(Apiservices.taskAdd, formData);


      if (response.status === true) {
        toast.success(response.message);
        formik.resetForm();
        setmodal_list(false);
        setLoading(false);
        fetchData();
        setImage(null)
        setDisoverList('')
        // setErrors('');


        formik.setFieldValue("sessionimage", "");
      } else {
        toast.error(response.message);
        formik.resetForm();
        setLoading(false);
        fetchData();
        setImage(null)
        setDisoverList('')
        // setErrors('');



      }
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
      setLoading(false);
      setImage(null)
      setDisoverList('')
      // setErrors('');



    }
  };
  // const addContentRow = () => {
  //   formik.setValues({
  //     ...formik.values,
  //     content: [...formik.values.content, { steps: "" }],
  //   });
  // };

  // // Remove Content Row Function
  // const removeContentRow = (index) => {
  //   const updatedContent = formik.values.content.filter((_, i) => i !== index);
  //   formik.setValues({ ...formik.values, content: updatedContent });
  // };


  //update form validation
  const validationUpdate = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      shortdiscription: "",
      // description: "",
      videolink: "",
      isActive: "",
      // content: [{ steps: "" }],
      position: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      shortdiscription: Yup.string().required("short description is required"),
      // description: Yup.string().required("description is required"),
      // content: Yup.array()
      // .of(
      //   Yup.object().shape({
      //     steps: Yup.string().required("Content Line is required"),
      //   })
      // )
      // .required("At least one content line is required"),
      position: Yup.string()
        .matches(/^\d*\.?\d*$/, "Only numbers are allowed")
        .required("Position is required"),
      videolink: Yup.string()

        .url("Please enter a valid URL")
        .required("Video Link is required"),
      isActive: Yup.string().required("Status is required"),
    }),
    onSubmit: (values) => {
      confirmUpdate(values);
    },
  });


  const handleUpdate = (record) => {
    setId(record.video_Id);
    setGetImage(record.imageUrl); 
    setContent(record.description)

    // Populate form fields for update
    validationUpdate.setValues({
      discoverId: getId || "", 
      title: record.title || "", 
      shortdiscription: record.shortDescription || "", 
      // description: record.description || "", // Populate the description
      videolink: record.url || "", 
      content: record?.content?.map((item) => ({
        steps: item.steps || "", 
      })) || [{ steps: "" }], 
      position: record.position || "",
      isActive: record.isActive !== undefined ? String(record.isActive) : "", 
    });

    setmodal_update_list(true); 
  };


  const confirmUpdate = async (values) => {
    setLoading(true);

    // Image validation
    if (!image && !getImage) {
      toast.error("Session image is required");
      setLoading(false);
      return;
    }
    if (!content) {
      toast.error("Content is required");
      setLoading(false);
      return;
    }
    // if(validationUpdate.values.videostatus === '1'){
    //   const error = validateSessionLink(sessionLink);
    //   if (error) {
    //     setErrors(error);
    //     setLoading(false);
    //     return;

    //   }
    // }
  


    const updateformData = new FormData();
    updateformData.append("video_Id", getId);
    updateformData.append("title", values.title);
    // updateformData.append("description", values.description);
    updateformData.append("description", content);
    updateformData.append("shortDescription", values.shortdiscription);
    // updateformData.append("content", JSON.stringify(values.content));
    updateformData.append("content", '');
    updateformData.append("url", values.videolink);
    updateformData.append("isActive", Number(values.isActive));
    updateformData.append("position", Number(values.position));




    // Image handling
    if (image) {
      updateformData.append("files", image);
    } else if (getImage) {
      updateformData.append("files", getImage);
    }
    console.log(updateformData);

    try {
      const response = await postData(Apiservices.taskUpdate, updateformData);
      if (response.status === true) {
        setmodal_update_list(false);
        toast.success(response.message);
        fetchData();
        setDisoverList('')

      } else {
        toast.error(response.data.message);
        fetchData();
        setDisoverList('')
        // setErrors('')

      }
    } catch (error) {
      toast.error(error.response.data.message);
      validationUpdate.resetForm();
      // setErrors('');

    } finally {
      setLoading(false);
    }
  };
  // // add butn for update content
  // const addupdateContentRow = () => {
  //   validationUpdate.setFieldValue("content", [
  //     ...validationUpdate.values.content,
  //     { steps: "" }
  //   ]);
  // };

  // // remove button for update content
  // const removeupdateContentRow = (index) => {
  //   const updatedContent = validationUpdate.values.content.filter((_, i) => i !== index);
  //   validationUpdate.setFieldValue("content", updatedContent);
  // };




  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "") {
      fetchData();
      setSearchValid(false);
    } else {
      setinputSearch(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
      };

      try {
      
        const response = await postData(Apiservices.taskView, postForm);

        if (response.status) {
          setSearchLoad(false);
          setDisoverList(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);

          // toast(response.message);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
        toast.error(error.response.data.message);
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };

  const [isLoading, setIsLoading] = useState(false)

  const showStatusAlert = (onConfirm, row) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to update status!",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Update it!',
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        if (!reason) {
          Swal.showValidationMessage('Reason is required');
        } else {
          Swal.showLoading();
          try {
            await onConfirm({ ...row, reason });
            Swal.fire('Success!', 'The status has been updated successfully', 'success');
          } catch (error) {
            Swal.fire('Error!', 'There was an error updating the status', 'error');
          }
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      // preConfirm: () => {
      //     return onConfirm(row) 
      //       .then(() => {
      //         return Promise.resolve();
      //       })
      //       .catch((error) => {
      //         Swal.showValidationMessage(`Request failed: ${error}`);
      //       });
      //   }
    }).then((result) => {
      if (result.isConfirmed) {
        // Success handling if needed
        // Swal.fire('Success !', 'The status has been updated successfully', 'success');
      }
    });
  };
  const handleUpdateStatusClick = (row) => {
    showStatusAlert(handleToggle1, row);
  }

  const handleToggle1 = async (rowDetails) => {

    const updatedStatus = rowDetails.isActive === 1 ? 0 : 1;

    try {
      setIsLoading(true)
      const statusUpdate = new FormData();
      statusUpdate.append("video_Id", rowDetails.video_Id);
      statusUpdate.append("title", rowDetails.title);
      statusUpdate.append("url", rowDetails.url);
      statusUpdate.append("isActive", updatedStatus);
      statusUpdate.append("position", Number(rowDetails.position));
      statusUpdate.append("description", rowDetails.description);
      statusUpdate.append("shortDescription", rowDetails.shortDescription);
      statusUpdate.append("content", JSON.stringify(rowDetails.content));
      statusUpdate.append("imageUrl", rowDetails.imageUrl);


      // statusUpdate.append("files", '')


      // const response = await postData(`${Apiservices.ProbinarUpdate}`, statusUpdate);
      const response = await postData(Apiservices.taskUpdate, statusUpdate);
      if (response.status) {
        toast.dismiss();
        setIsLoading(false);
        // toast.success(response.message);
        Swal.fire("Success !", "The status has been updated successfully", "success");

        fetchData();
      } else {
        toast.success(response.message);
        setIsLoading(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
      // toast.error("Internal Server Error..Try Again Later");
      setIsLoading(false);
    }
  };


  const toggleModal = () => {
    setModal(!modal);
  };

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState("");

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage));
    HandleClickRowperpage(newRowsPerPage)
  }
  const HandleClickRowperpage = async (newRowsPerPage) => {
    setLoading(true);
    const postForm = {
      page: 1,
      limit: Number(newRowsPerPage),
    };

    // if (dateRange[0] && dateRange[1]) {
    //   postForm.startDate = formatDate(dateRange[0]);
    //   postForm.endDate = formatDate(dateRange[1]);
    // }

    try {
      if (getDiscoverList === undefined) {
        // const response = await postData(`${Apiservices.ProbinarList}?${queryString}`);
        const response = await postData(Apiservices.taskView, postForm);

        if (response.status) {
          setLoading(false);
          setDisoverList(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
        }
      } else {
        const response = await postData(Apiservices.taskView, postForm);
        if (response.status) {
          setLoading(false);
          setDisoverList(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      }
    } catch (error) {

      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }

  };
  // const validateImage = (file) => {
  //   // Validate file type and size here (e.g., PNG, JPG, JPEG)
  //   const validTypes = ['image/png', 'image/jpeg'];
  //   return validTypes.includes(file.type) && file.size <= 5000000; // 5MB max size
  // };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file); // Update the image state
    }
    // if (file) {
    //   if (validateImage(file)) {
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //       const img = new Image();
    //       img.src = event.target.result;
    //       img.onload = () => {
    //         const { width, height } = img;
    //         if (validateResolution(width, height)) {
    //           setImage(file);
    //           setError('');
    //         } else {
    //           setError('Image resolution must be at least 800x600 pixels.');
    //           setImage(null);
    //         }
    //       };
    //     };
    //     reader.readAsDataURL(file);
    //   } else {
    //     setError('Invalid file type or size.');
    //     setImage(null);
    //   }
    // }
  };

  // const validateResolution = (width, height) => {
  //   // Minimum resolution required: 800x600 pixels
  //   return width >= 800 && height >= 600;
  // };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Task" breadcrumbItem="Task List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-4">
                    <Col className="col-sm-auto">
                      <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => handleSearch()}
                            disabled={searchLoad}
                          >
                            {searchLoad ? (<Spinner className="text-light" size="sm" />) : (<i className="ri-search-line" />)}
                          </button>
                        </div>
                      </div>

                    </Col>
                    {/* Add Modal Button */}
                    <Col className="col-sm">

                      <div className="d-flex justify-content-sm-end">
                        {(adminType === 1 || adminInfo?.permissions.some(data =>
                          data.module === "Probinar Academy" && data.actions.includes(1))) ? (
                          <Button
                            color="success"
                            className="add-btn"
                            onClick={() => tog_list()}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i> Add
                          </Button>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive  mt-2">
                    <DataTable
                      columns={columns}
                      data={getDiscoverList}
                      paginationServer
                      paginationTotalRows={getDiscoverList.length}
                      progressPending={loading}
                      persistTableHead={true}
                      progressComponent={<div className="py-3">Loading...</div>}
                      // noDataComponent={formData.length===0}
                      noDataComponent={<div className="py-3">No records found</div>}
                      fixedHeader={true}

                    />
                  </div>
                  {/* {getDiscoverList.length !== 0 ? (
      <div className="d-flex justify-content-end mt-3">
        <div className="pagination pagination-rounded mb-0">
          
          <ReactPaginate
            previousLabel={<i className="mdi mdi-chevron-left"></i>}
            nextLabel={<i className="mdi mdi-chevron-right"></i>}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageLinkClassName={"page-link"}
            breakLinkClassName={"break-link"}
            disabledClassName={"disabled"}
            initialPage={currentPage}
            disableInitialCallback={true}
            forcePage={currentPage}
            // disableInitialCallback={true}
            renderLink={(props) => {
              if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                return (
                  <a {...props} onClick={() => handlePageClick({ selected: pageCount - 1 })}>
                    {pageCount}
                  </a> 
                  );
              } return <a {...props} />;
            }}/>
        </div>
      </div>
    ) : null} */}

                  <div className="container">
                    <div className="row justify-content-end mt-4">
                      <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                        <div className="d-flex align-items-center me-3">
                          <span className="me-2">Rows per page:</span>
                          <select
                            value={rowsPerPage}
                            onChange={(e) => handleRowsPerPageChange(e.target.value)}
                            className="form-control-sm"
                          >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                          </select>
                        </div>
                        <div className="me-md-3 mb-3 mb-md-0">
                          <span>{count}</span>
                        </div>
                        <div className="pagination pagination-rounded mt-3">
                          <ReactPaginate
                            previousLabel={<i className="mdi mdi-chevron-left"></i>}
                            nextLabel={<i className="mdi mdi-chevron-right"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            breakLinkClassName={"break-link"}
                            disabledClassName={"disabled"}
                            initialPage={currentPage}
                            disableInitialCallback={true}
                            forcePage={currentPage}
                            renderLink={(props) => {
                              if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                return (
                                  <a
                                    {...props}
                                    onClick={() =>
                                      handlePageClick({
                                        selected: pageCount - 1,
                                      })
                                    }
                                  >
                                    {pageCount}
                                  </a>
                                );
                              }
                              return <a {...props} />;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* // ADDMODAL  */}
                  <Modal className="modal-dialog modal-lg" isOpen={modal_list} toggle={() => { tog_list(); }} centered>
                    <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add Task</ModalHeader>
                    <Form autoComplete="off" className="form-horizontal"
                      onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(); return false; }}>
                      <ModalBody>
                        <Row>
                          <Col md={6}>


                            <div className="mb-3">
                              <label htmlFor="title-field" className="form-label">
                                Title
                              </label>

                              <Input
                                type="text"
                                id="title-field"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter Title"
                                name="title"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.title || ""}
                                invalid={formik.touched.title && formik.errors.title}
                              />
                              {formik.touched.title && formik.errors.title && <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>}
                            </div>

                            <div className="mb-3">
                              <label htmlFor="shortdiscription-field" className="form-label">
                                Short Description
                              </label>
                              <Input
                                type="textarea"
                                id="shortdiscription-field"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter Short Description"
                                name="shortdiscription"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.shortdiscription || ""}
                                invalid={formik.touched.shortdiscription && formik.errors.shortdiscription}
                              />
                              {formik.touched.shortdiscription && formik.errors.shortdiscription && <FormFeedback type="invalid">{formik.errors.shortdiscription}</FormFeedback>}
                            </div>
                            {/* <div className="mb-3">
                              <label htmlFor="description-field" className="form-label">
                                Description
                              </label>
                              <Input
                                type="textarea"
                                id="description-field"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter Description"
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description || ""}
                                invalid={formik.touched.description && formik.errors.description}
                              />
                              {formik.touched.description && formik.errors.description && <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>}
                            </div> */}

                            <div className="mb-3">
                              <label htmlFor="title-field" className="form-label">
                                Thumbnail
                              </label>
                              <div>
                                <input type="file" className="form-control"
                                  accept=".png, .jpg, .jpeg"
                                  onChange={handleImageChange} />
                              </div>
                              {/* {error && <div className="text-danger" style={{ fontSize: '12px' }}>{error}</div>} */}

                              {image ?
                                <Card style={{ width: '200px' }}>
                                  <CardBody>
                                    {/* <CardTitle tag="h6">Image Preview</CardTitle> */}
                                    <CardImg
                                      top
                                      src={image ? URL.createObjectURL(image) : ''}
                                      style={{ width: '100%', objectFit: 'cover' }}
                                    />
                                  </CardBody>
                                </Card> : null}
                            </div>





                          </Col>
                          <Col md={6}>

                            <div className="mb-3">
                              <label htmlFor="title-field" className="form-label">
                                Video Link
                              </label>
                              <Input
                                type="text"
                                id="subject-field"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter Video Link"
                                name="videolink"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.videolink || ""}
                                invalid={formik.touched.videolink && formik.errors.videolink}
                              />
                              {formik.touched.videolink && formik.errors.videolink && <FormFeedback type="invalid">{formik.errors.videolink}</FormFeedback>}
                            </div>



                            <div className="mb-3">
                              <label htmlFor="status-field" className="form-label">
                                Status
                              </label>
                              <select
                                id="status-field"
                                //  className="form-control"
                                name="isActive"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.isActive || ""}
                                className={`form-control ${formik.touched.isActive && formik.errors.isActive ? "is-invalid" : ""}`}
                              >
                                <option value="" disabled>
                                  Select Status
                                </option>
                                <option value={1}>Active</option>
                                <option value={0}>Deactive</option>
                              </select>
                              {formik.touched.isActive && formik.errors.isActive && <FormFeedback type="invalid"> {formik.errors.isActive} </FormFeedback>}
                            </div>

                            <div className="mb-3">
                              <label htmlFor="position-field" className="form-label">
                                Position
                              </label>
                              <Input
                                type="number"
                                min="1"
                                id="position-field"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter Position"
                                name="position"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.position || ""}
                                invalid={formik.touched.position && formik.errors.position}
                              />
                              {formik.touched.position && formik.errors.position && <FormFeedback type="invalid">{formik.errors.position}</FormFeedback>}
                            </div>

                          </Col>
                          {/* <div className="mb-3">
                              {formik.values.content.map((item, index) => (
                                <div key={index} className="row">
                                  <Col>
                                    <FormGroup>
                                      <Label for={`content[${index}].steps`}>Steps</Label>
                                      <Input
                                        type="textarea"
                                        name={`content[${index}].steps`} 
                                        id={`steps-${index}`}
                                        className="form-control"
                                        placeholder="Enter Steps"
                                        value={formik.values.content[index].steps}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        autoComplete="off"
                                        invalid={
                                          formik.touched.content?.[index]?.steps &&
                                          !!formik.errors.content?.[index]?.steps
                                        }
                                      />
                                      <FormFeedback>
                                        {formik.errors.content?.[index]?.steps}
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>

                                  
                                  <Col sm={1} lg={1} className="d-flex align-items-center">
                                    {index > 0 && (
                                      <i
                                        className="ri-delete-bin-line"
                                        onClick={() => removeContentRow(index)}
                                        style={{ cursor: "pointer", color: "#ff5b5b" }}
                                      ></i>
                                    )}
                                  </Col>

                                
                                  <Col sm={2} lg={2} className="d-flex align-items-center">
                                    <Button color="success" className="add-btn" onClick={addContentRow}>
                                      <i className="ri-add-line align-bottom me-1"></i>
                                    </Button>
                                  </Col>
                                </div>
                              ))}
                            </div> */}

                          <div className="mb-3">
                            <label htmlFor="title-field" className="form-label">
                              Content
                            </label>

                            <Editor
                              apiKey="1sc0anmjblezhxi95xon4501yg96pnp3qw0fj5osttzyz752"
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: "link image code",
                                toolbar:
                                  "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code",
                                setup: (editor) => {
                                  editor.on("change", () => {
                                    console.log("Editor content changed");
                                  });
                                },
                              }}
                              onEditorChange={handleEditorChange}
                            />
                          </div>
                        </Row>
                      </ModalBody>

                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button type="button" className="btn btn-light" onClick={() => { setmodal_list(false); formik.resetForm(); setImage('') }}>
                            Close
                          </button>

                          <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
                            {loading ? <Loader /> : "Submit"}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>


                  {/* UpdateModal */}
                  <Modal className="modal-dialog modal-lg" isOpen={modal_update_list} toggle={() => { tog_update_list(); }} centered>
                    <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }}>
                      Update Task
                    </ModalHeader>
                    <Form autoComplete="off" className="form-horizontal" onSubmit={(e) => { e.preventDefault(); validationUpdate.handleSubmit(); return false; }}>
                      <ModalBody>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <label htmlFor="title-field" className="form-label">Title</label>
                              <Input
                                type="text"
                                id="title-field"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter Title"
                                name="title"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={validationUpdate.values.title || ""}
                                invalid={validationUpdate.touched.title && validationUpdate.errors.title}
                              />
                              {validationUpdate.touched.title && validationUpdate.errors.title && <FormFeedback type="invalid">{validationUpdate.errors.title}</FormFeedback>}
                            </div>

                            <div className="mb-3">
                              <label htmlFor="shortdiscription-field" className="form-label">Short Description</label>
                              <Input
                                type="textarea"
                                id="shortdiscription-field"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter Short Description"
                                name="shortdiscription"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={validationUpdate.values.shortdiscription || ""}
                                invalid={validationUpdate.touched.shortdiscription && validationUpdate.errors.shortdiscription}
                              />
                              {validationUpdate.touched.shortdiscription && validationUpdate.errors.shortdiscription && <FormFeedback type="invalid">{validationUpdate.errors.shortdiscription}</FormFeedback>}
                            </div>

                            {/* <div className="mb-3">
                              <label htmlFor="description-field" className="form-label">Description</label>
                              <Input
                                type="textarea"
                                id="description-field"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter Description"
                                name="description"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={validationUpdate.values.description || ""}
                                invalid={validationUpdate.touched.description && validationUpdate.errors.description}
                              />
                              {validationUpdate.touched.description && validationUpdate.errors.description && <FormFeedback type="invalid">{validationUpdate.errors.description}</FormFeedback>}
                            </div> */}

                            <div className="mb-3">
                              <label htmlFor="image-field" className="form-label">Thumbnail</label>
                              <input type="file" className="form-control" accept=".png, .jpg, .jpeg" onChange={handleImageChange} />
                              {error && <div className="text-danger" style={{ fontSize: '12px' }}>{error}</div>}
                              {getImage ? (
                                <Card style={{ width: '200px' }}>
                                  <CardBody>
                                    <CardImg top src={image ? URL.createObjectURL(image) : getImage}
                                      style={{ width: '100%', objectFit: 'cover' }} />
                                  </CardBody>
                                </Card>
                              ) : null}
                            </div>
                          </Col>

                          <Col md={6}>
                            <div className="mb-3">
                              <label htmlFor="videolink-field" className="form-label">Video Link</label>
                              <Input
                                type="text"
                                id="videolink-field"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter Video Link"
                                name="videolink"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={validationUpdate.values.videolink || ""}
                                invalid={validationUpdate.touched.videolink && validationUpdate.errors.videolink}
                              />
                              {validationUpdate.touched.videolink && validationUpdate.errors.videolink && <FormFeedback type="invalid">{validationUpdate.errors.videolink}</FormFeedback>}
                            </div>
                            {/* 
                            <div className="mb-3">
                              <label htmlFor="steps-field" className="form-label">Steps</label>
                              <Input
                                type="textarea"
                                id="steps-field"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter Steps"
                                name="steps"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={validationUpdate.values.steps || ""}
                                invalid={validationUpdate.touched.steps && validationUpdate.errors.steps}
                              />
                              {validationUpdate.touched.steps && validationUpdate.errors.steps && <FormFeedback type="invalid">{validationUpdate.errors.steps}</FormFeedback>}
                            </div> */}





                            <div className="mb-3">
                              <label htmlFor="status-field" className="form-label">Status</label>
                              <select
                                id="status-field"
                                name="isActive"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={validationUpdate.values.isActive || ""}
                                className={`form-control ${validationUpdate.touched.isActive && validationUpdate.errors.isActive ? "is-invalid" : ""}`}
                              >
                                <option value="" disabled>Select Status</option>
                                <option value={1}>Active</option>
                                <option value={0}>Inactive</option>
                              </select>
                              {validationUpdate.touched.isActive && validationUpdate.errors.isActive && <FormFeedback type="invalid">{validationUpdate.errors.isActive}</FormFeedback>}
                            </div>

                            <div className="mb-3">
                              <label htmlFor="position-field" className="form-label">Position</label>
                              <Input
                                type="number"
                                min="1"
                                id="position-field"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter Position"
                                name="position"
                                onChange={validationUpdate.handleChange}
                                onBlur={validationUpdate.handleBlur}
                                value={validationUpdate.values.position || ""}
                                invalid={validationUpdate.touched.position && validationUpdate.errors.position}
                              />
                              {validationUpdate.touched.position && validationUpdate.errors.position && <FormFeedback type="invalid">{validationUpdate.errors.position}</FormFeedback>}
                            </div>
                          </Col>
                          {/* <div className="mb-3">
  {validationUpdate.values.content.map((item, index) => (
    <div key={index} className="row">
      <Col>
        <FormGroup>
          <Label for={`content[${index}].steps`}>Content Line</Label>
          <Input
            type="textarea"
            name={`content[${index}].steps`}
            id={`steps-${index}`}
            className="form-control"
            placeholder="Enter Content Line"
            value={validationUpdate.values.content[index].steps || ""}
            onChange={validationUpdate.handleChange}
            onBlur={validationUpdate.handleBlur}
            invalid={
              validationUpdate.touched.content?.[index]?.steps &&
              !!validationUpdate.errors.content?.[index]?.steps
            }
          />
          {validationUpdate.touched.content?.[index]?.steps &&
            validationUpdate.errors.content?.[index]?.steps && (
              <FormFeedback>{validationUpdate.errors.content[index].steps}</FormFeedback>
          )}
        </FormGroup>
      </Col>


      <Col sm={1} lg={1} className="d-flex align-items-center">
        {index > 0 && (
          <i
            className="ri-delete-bin-line"
            onClick={() => removeupdateContentRow(index)}
            style={{ cursor: "pointer", color: "#ff5b5b" }}
          ></i>
        )}
      </Col>

  
      <Col sm={2} lg={2} className="d-flex align-items-center">
        <Button color="success" className="add-btn" onClick={addupdateContentRow}>
          <i className="ri-add-line align-bottom me-1"></i>
        </Button>
      </Col>
    </div>
  ))}
</div> */}

                          <div className="mb-3">
                            <label htmlFor="title-field" className="form-label">
                              Content
                            </label>

                            <Editor
                              apiKey="8tmldbekaot614sl1i1s5lejnuj9hkqmsncgjyxs3p4rgave" // Optional, get one from TinyMCE if you're using their cloud version
                              value={content}
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: "link image code",
                                toolbar:
                                  "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code",
                                setup: (editor) => {
                                  editor.on("change", () => {
                                    console.log("Editor content changed");
                                  });
                                },
                              }}
                              onEditorChange={handleEditorChange}
                            />
                          </div>
                        </Row>
                      </ModalBody>

                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button type="button" className="btn btn-light" onClick={() => { setmodal_update_list(false); setImage(''); }}>
                            Close
                          </button>
                          <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
                            {loading ? <Loader /> : "Submit"}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>



                  {/* View Modal    */}
                  <Modal className='modal-dialog modal-lg' size="l" isOpen={showModel} centered backdrop="static" >

                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myExtraLargeModalLabel" >
                        Task Video Details
                      </h5>
                      <button
                        onClick={() => { setShowModel(false); }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>


                    <div className="modal-body">

                      <div className="table-responsive">
                        <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                          <tbody>
                            <tr>
                              <td><b>Title</b></td>
                              <td>{showDetails.title || '-'}</td>
                            </tr>
                            {/* <tr>
                    <td><b>Task Id</b></td>
                    <td>{showDetails.taskId || '-'}</td>
                </tr> */}
                            <tr>
                              <td><b>Video Link</b></td>
                              <td>{showDetails.url || '-'}</td>
                            </tr>
                            <tr>
                              <td><b>Short Description</b></td>
                              <td>{showDetails.shortDescription || '-'}</td>
                            </tr>
                            <tr>
                              <td><b> Description</b></td>
                              <td>{showDetails.description || '-'}</td>
                            </tr>
                            <tr>
                              <td><b>Content</b></td>
                              {showDetails.content && showDetails.content.length > 0 ? (
                                showDetails.content.map((data, index) => (
                                  <li key={index}>{data.steps}</li>
                                ))
                              ) : (
                                <td>-</td>
                              )}
                            </tr>


                            <tr>
                              <td><b>Status</b></td>
                              <td>{showDetails.IsActive == 1 ? 'Active' : 'Deactive' || '-'}</td>
                            </tr>

                            <tr>

                              <td><b>Date & Time</b></td>
                              <td>{utcToNormalTime(showDetails.createdAt).date} {utcToNormalTime(showDetails.createdAt).time || '-'}</td>
                            </tr>
                            <tr>
                              <td><b>Image</b></td>
                              <td>
                                <Card style={{ width: '200px' }}>
                                  <CardBody>
                                    <CardImg
                                      top
                                      src={showDetails?.imageUrl || '-'}
                                      style={{ width: '100%', objectFit: 'cover' }}
                                    />
                                  </CardBody>
                                </Card>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>

                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TaskComponent;

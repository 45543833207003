import React, { useState, useEffect } from "react";
import { Button, Input, Card, CardBody, Col, Container, Modal, Row, ModalHeader, Form, Spinner, InputGroup } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Apiservices } from "../../ApiService/ApiServices";
import axiosInstance, { postData, postGetFile } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import { handleNullValue, utcToNormalTime } from "../../helpers/function";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import country from "../../helpers/CountryCodes.json";

const UsersStakeWithoutKycHistory = () => {
  document.title = "Ultrapro | Staking Offers";
  const location = useLocation();

  const [selectedCountry, setSelectedCountry] = useState();
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [downLoading, setDownLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [ rowsPerPage, setRowsPerPage] = useState(10);
  const [downloadStartDate, setDownloadEndDate] = useState({});
  const userApi = location.state?.user;
  const [user, setUser] = useState(userApi);
  const [selectedType, setSelectedType] = useState('')
  const [searchLoad,setSearchLoad] = useState(false)



  const today = new Date();
  //update Modal
  const [modal_update_list, setmodal_update_list] = useState(false);
  function tog_update_list() {
    setmodal_update_list(!modal_update_list);
  }


  //Pagination;
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = Math.ceil(totalPagination);
  const [count, setCount] = useState("");
  const[GetDetails,setDetails] = useState('')

  //LIST API CALL
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (user === undefined) {
      setUser(Apiservices.USER_WITHOUT_KYC);

    }
  }, [user]);


  useEffect(() => {
    if (searchTerm === '') {
      fetchData();
    }
  }, [searchTerm]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await postData(Apiservices.USER_WITHOUT_KYC);
      console.log(response,"7777777777777777777777777")
      if (response) {
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.itemsPerPage);
        setCount(response.data.currentCount);
        setRecord(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleNav = async (record) => {
    console.log(record,"888888888888")
    setmodal_update_list(true);
    setDetails(record?.stakingDetails)
  };

  const columns = [
    {
      name: "S.No",
      selector: (row) => handleNullValue(row?.serialNo),
      sortable: true,
      maxWidth: '80px',
    },
    {
      name: "Name",
      selector: (row) => handleNullValue(row?.stakingDetails?.investorFullName),
      sortable: true,
      maxWidth: '150px',
    },
    // {
    //   name: "Email",
    //   selector: (row) => handleNullValue(row.investorEmail),
    //   sortable: true,
    //   maxWidth: '250px', 
    // },
    {
      name: 'Email',
      // selector: row => handleNullValue(row.userDetails?.email),
      selector: row =>
        <div className="d-flex align-items-center">
          {row?.stakingDetails?.investorEmail ?
          <Link className="link-tag" to={`/allUsers/userDetails/${row?.userDetails?.acId}`} target='_blank'>
            <span className="mx-1 text-decoration-underline">
              {row?.stakingDetails?.investorEmail || '-'}
            </span>
          </Link> : <span>-</span>}
        </div>,
      sortable: true,
      minWidth: '300px'

    },
    // {
    //   name: "OS Type",
    //   selector: (row) => (row.userDetails?.osType == 0 ? 'Web' : 'Android' || '-'),
    //   sortable: true,
    //   minWidth: '150px',
    // },
    {
      name: "Coin",
      selector: (row) => handleNullValue(row?.stakingDetails?.coin),
      sortable: true,
      maxWidth: '100px',
    },
    {
      name: "Stake Amount",
      selector: (row) => handleNullValue(row?.stakingDetails?.stakedUPROAmount),
      sortable: true,
      minWidth: '180px',

    },

    // {
    //   name: "Staring Date",
    //   selector: (row) => handleNullValue(row.stakingStart),
    //   sortable: true,
    // },
    // {
    //   name: "Ending Date",
    //   selector: (row) => handleNullValue(row.stakingEnd),
    //   sortable: true,

    // },
    // {
    //   name: "Coupon Code",
    //   selector: (row) => handleNullValue(row.usedCouponCode),
    //   sortable: true,
    //   maxWidth: '150px',
    // },
    {
      name: "Country",
      selector: (row) => handleNullValue(row?.stakingDetails?.country),
      sortable: true,
      maxWidth: '200px',
    },
    // {
    //   name: 'Voucher Code',
    //   cell: row => 
    //     !row.stakingDetails.usedCouponCode ? (
    //       <span>Free Stake</span>
    //     ) : (
    //       <div className="d-flex align-items-center">
    //       {row?.usedCouponCode ? (
    //         <Link className="link-tag" to={`/allUsers/userDetails/${row.stakingDetails.nonpayersUserId}`} target='_blank'>
    //           <span className="mx-1 text-decoration-underline">
    //             {row.stakingDetails.usedCouponCode}
    //           </span>
    //         </Link>
    //       ) : (
    //         <span className="mx-1">-</span>
    //       )}
    //     </div>
    //     ),
    //   sortable: true,
    //   minWidth: '150px'
    // },
    {
      name: "voucher Code",
      selector: (row) =>
        
        <div>
          {row.stakingDetails?.usedCouponCode ? 
       <Link className="link-tag" to={`/allUsers/userDetails/${row.nonpayersUserId}`} target='_blank'>
          <span className="mx-1 text-decoration-underline">
              {row.stakingDetails?.usedCouponCode || '-'}
          </span>
         </Link> : <span>-</span>}
      </div>,
      sortable: true,
      minWidth: '150px',
    },
    {
      name: "Staked Type",
      selector: (row) => handleNullValue(!row.stakingDetails?.usedCouponCode ? 'Free Stake' : '-'),
      sortable: true,
      minWidth: '150px',
    },
    
    // {
    //   name: "Status",
    //   // selector: row => row.isActive ? 'Active' : 'Inactive',
    //   selector: (row) => (
    //     <div className="form-check form-switch form-switch-md mb-3 d-flex justyfy-content-center align-items-center">
    //       <input
    //         type="checkbox"
    //         className="form-check-input"
    //         id="customSwitchsizemd"
    //         checked={row.status === 1}
    //         onChange={() => handleNullValue(row.status)}
    //         disabled={loading}
    //       />
    //     </div>
    //   ),
    //   sortable: true,
    //   maxWidth: '100px',
    // },
    {
      name: "Date & Time",
      selector: (row) =>
        row?.stakingDetails?.createdAt
          ? `${utcToNormalTime(row?.stakingDetails?.createdAt).date} ${utcToNormalTime(row?.stakingDetails?.createdAt).time
          }`
          : "---",
      sortable: true,
      minWidth: '200px',
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <i
            style={{ fontSize: '15px', cursor: 'pointer', marginLeft: "15px" }}
            onClick={() => handleNav(row)}
            className="mdi mdi-eye-outline mx-1"
          ></i>
        </div>
      ),
      ignoreRowClick: true,
      maxWidth: '80px',
    }

  ];

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected, { startDate: downloadStartDate.startDate, endDate: downloadStartDate.endDate });
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(parseInt(newRowsPerPage));
    HandleClickRowperpage(newRowsPerPage)
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  const handleFilter = async (apiName) => {
    setLoading(true);
    try {
      const response = await postData(apiName);
      if (response.status) {
        setLoading(false);
        setRecord(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.itemsPerPage);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleDateChange = ([start, end]) => {
    const formattedStart = start ? formatDate(start) : null;
    const formattedEnd = end ? formatDate(end) : null;
    setDateRange([start, end]);
    if (!formattedStart && !formattedEnd) {
      handleFilter(user);
      setDownloadEndDate({});
    }

    if (formattedStart && formattedEnd) {
      setDownloadEndDate({
        startDate: formattedStart,
        endDate: formattedEnd,
      });
      handlePagePost(currentPage, { startDate: formattedStart, endDate: formattedEnd });
    }
  };


  const handleFilter1 = async (apiName, value,type) => {
    const postForm = {
      filterType: value
    }
    if (type==='country') {
      postForm.country=value
      
    }
    
    setLoading(true);
    try {
      const response = await postData(apiName, postForm);
      if (response.status) {
        setLoading(false);
        setRecord(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.itemsPerPage);
        setCount(response.data.currentCount);
        setSelectedType(value)
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleSearch = () => {
    handlePagePost(currentPage, searchTerm); // Perform the search using currentPage and searchTerm
  };
  const handleSearchChange = (event) => {
    const value = event.target.value.replace(/\s+/g, '');
    setSearchTerm(value);
  };


  // const handleMonthChange = (monthValue) => {
  //   console.log(monthValue, "Selected Month");
  //   setSelectedMonth(monthValue);
  //   handlePagePost(currentPage); // Ensure currentPage is defined in your component
  // };

  const HandleClickRowperpage = async (newRowsPerPage) => {
    setLoading(true);
    setRowsPerPage(newRowsPerPage);
    handlePagePost();
  };

  const handlePagePost = async (selected, { startDate, endDate }) => {
    const postForm = {
      page: selected + 1,
      limit: rowsPerPage,
      search: searchTerm,
      startDate: startDate,
      endDate: endDate,
      filterType: selectedType,
      country: selectedType,



    };
    try {
      setLoading(true);
      const response = await postData(Apiservices.USER_WITHOUT_KYC, postForm);
      if (response) {
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.itemsPerPage);
        setCount(response.data.currentCount);
        setRecord(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }



  const handleSelect = (value, type) => {
    if (type === 'country') {
      setSelectedCountry(value);
      handleFilter1(Apiservices.USER_WITHOUT_KYC,value,type);
    }
  };

  const handleDownload = async (format) => {
    setDownLoading(true);
    try {


      const postForm = {
        page:currentPage + 1,
        limit: rowsPerPage,
        startDate: downloadStartDate.startingDate,
        endDate: downloadStartDate.endDate,
        status: "",
        search: searchTerm || "",
        filterType: selectedType
      };
      if (downloadStartDate.startDate) {
        postForm.startDate = downloadStartDate.startDate;
      }
      if (downloadStartDate.endDate) {
        postForm.endDate = downloadStartDate.endDate;
      }
      const response = await axiosInstance.post(Apiservices.USER_WITHOUT_KYC_DOWNLOAD, postForm, {
        responseType: 'blob'
      });
      const blob = response.data;
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Without_KYC_StakeHistory.${format}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error("Error downloading the file", err);
      toast.error("Error downloading the file");
    } finally {
      setDownLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Staking History" breadcrumbItem="Users Stake Without KYC History" />
          <Row>
            <Col lg={12} className="row">
              <Card className="col-lg-12">
                <CardBody>
                  <Row className=" mb-3">
                    <Col className="col-sm-3">

                      <div className="input-group">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Search by Name, Email couponcode"
                          aria-label="Search"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                            {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                          </button>
                        </div>
                      </div>

                     
                    </Col>
                    
                    <Col sm={4} lg={3}>
                      <div className="form-group mb-4">

                        <div>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select Start to End Date"
                              options={{
                                mode: 'range',
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                                maxDate: today,

                              }}
                              value={dateRange}
                              onChange={handleDateChange}
                            />
                          </InputGroup>
                        </div>

                      </div>
                      
                    </Col>
                    

                    <div className="col-3">
                          <select
                            onChange={(e) => handleSelect(e.target.value, 'country')}
                            className="form-select"
                            id="autoSizingSelect"
                            value={selectedCountry || 'Select Country'}
                          >
                           <option value = ''>All</option>
                            <option value={selectedCountry || 'Select Country'} disabled>
                              {selectedCountry || 'Select Country'}
                            </option>
                            {country.map((data) => (
                              <option key={data.name} value={data.name}>
                                {data.name}
                              </option>
                            ))}
                          </select>
                       
                        </div>
                        <Col className="col-sm-3 ">
                          <Button
                            color="info"
                            className="add-btn"
                            onClick={() => handleDownload('xlsx')}
                            id="create-btn"
                            disabled={downLoading}
                          >
                            <i className="mdi mdi-download-outline"></i>

                            {downLoading ? <Loader /> : "Download"}


                          </Button>
                        </Col>

                    {/* <Col className="col-sm-12">
                      <div className="d-flex">
                 
                      <Col className="col-sm-3 align-items-end">
                      <select
                        className="form-select"
                        value={selectedMonth}  
                        onChange={(e) => handleMonthChange(Number(e.target.value))}  
                      >
                        <option value="" disabled>Select Month</option>
                        {Array.from({ length: 12 }, (_, i) => (
                          <option key={i + 1} value={i + 1}>
                            {new Date(0, i).toLocaleString('default', { month: 'long' })}
                          </option>
                        ))}
                      </select>
                    </Col>
                      </div>
                    </Col> */}
                  </Row>
                  <div className="table-responsive overflow-hidden">
                    <DataTable
                      columns={columns}
                      data={record || []}
                      paginationServer
                      paginationTotalRows={record ? record.length : 0}
                      progressPending={loading}
                      progressComponent={<div>Loading...</div>}
                      persistTableHead={true}
                      noDataComponent={
                        <div className="mt-2">No records found</div>
                      }
                      fixedHeader={true}
                    />
                    <div className="container">
                      <div className="row justify-content-end mt-4">
                        <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                          <div className="d-flex align-items-center me-3">
                            <span className="me-2">Rows per page:</span>
                            <select
                              value={rowsPerPage}
                              onChange={(e) => handleRowsPerPageChange(e.target.value)}
                              className="form-control-sm"
                            >
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={30}>30</option>
                              <option value={50}>50</option>
                            </select>
                          </div>
                          <div className="me-md-3 mb-3 mb-md-0">
                            <span>{count}</span>
                          </div>
                          <div className="pagination pagination-rounded mt-3">
                            <ReactPaginate
                              previousLabel={<i className="mdi mdi-chevron-left"></i>}
                              nextLabel={<i className="mdi mdi-chevron-right"></i>}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              breakLinkClassName={"break-link"}
                              disabledClassName={"disabled"}
                              initialPage={currentPage}
                              disableInitialCallback={true}
                              forcePage={currentPage}
                              renderLink={(props) => {
                                if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                  return (
                                    <a
                                      {...props}
                                      onClick={() =>
                                        handlePageClick({
                                          selected: pageCount - 1,
                                        })
                                      }
                                    >
                                      {pageCount}
                                    </a>
                                  );
                                }
                                return <a {...props} />;
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* UpdateModal */}
                    <Modal isOpen={modal_update_list} className="modal-lg" toggle={() => { tog_update_list(); }} centered>
                      <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_update_list(); }} >
                        User Staking Details
                      </ModalHeader>
                      <Form>
                        <Card>
                          <CardBody>
                          <div className="table-responsive">
                           <table className="table table-bordered mb-0" style={{ width: '100%' }}>
                                    <tbody>
                                      <tr>
                                        <td>Stake User Name</td>
                                        <td>{GetDetails?.investorFullName || "-"}</td>
                                      </tr>
                                      
                                      <tr>
                                        <td>Stake User Email</td>
                                        <td>
                                          {GetDetails?.investorEmail || "-"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Coin</td>
                                        <td>
                                        {GetDetails?.coin || "-"}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>Stake UPRO Amount</td>
                                        <td>
                                        {GetDetails?.stakedUPROAmount || "-"}

                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Stake USDT Amount</td>
                                        <td>
                                        {GetDetails?.stakedUSDTamount || "-"}

                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Voucher Code</td>
                                        <td>
                                        {GetDetails?.usedCouponCode || "-"}
                                        </td>
                                      </tr>
                                      {/* <tr>
                                        <td>Staked Type</td>
                                        <td>                                       
                                        {
                                          !GetDetails?.usedCouponCode ? 
                                            'Free Stake' :
                                            GetDetails?.stakedType === 1 ? 
                                            'Own Coupon' : 
                                            GetDetails?.stakedType === 2 ? 
                                            'Others Coupon' : 
                                            "-"
                                        }

                                        </td>
                                      </tr> */}
                                      <tr>
                                        <td>Staking StartDate</td>
                                        <td>
                                        <td>{utcToNormalTime(GetDetails?.stakingStart).date} {utcToNormalTime(GetDetails?.stakingStart).time || '-'}</td>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Staking EndDate</td>
                                        <td>
                                        <td>{utcToNormalTime(GetDetails?.stakingEnd).date} {utcToNormalTime(GetDetails?.stakingEnd).time || '-'}</td>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Transaction Id</td>
                                        <td>
                                        {GetDetails?.transId || "-"}
                                        </td>
                                      </tr>
                                  </tbody>
                                  </table>
                              </div>
                          </CardBody>
                        </Card>
                      </Form>
                    </Modal>
                  </div>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsersStakeWithoutKycHistory;

import React, { useState, useEffect } from "react";
import { Button, Input, Card, CardBody, Col, Tooltip, Container, Row, Spinner, InputGroup, Modal, } from "reactstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData,
    postGetData,
} from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import Loader from "../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import {
  AddressCell,
  AddressCellFull,
  handleNullValue,
  utcToNormalTime,
} from "../../helpers/function";
import { formatNumber } from "../../helpers/common";
import { Link } from "react-router-dom";


const CryptoCancelWithdraw = () => {
  document.title = "Ultrapro | User Management";
  const today = new Date();
  // const kyc = location.state?.kyc
  const [downloadStartDate, setDownloadEndDate] = useState({});
  const [dateRange, setDateRange] = useState([null, null]);
  const [loading, setLoading] = useState(false);
  const [downLoading, setDownLoading] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [showDetails, setShowDetails] = useState({});
  const [searchLoad, setSearchLoad] = useState(false);
  const [searchValid, setSearchValid] = useState(false);
  const [inputSearch, setinputSearch] = useState("");
  const [formData, setFormData] = useState([]);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const [count,setCount] = useState('');

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);
  const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1);

  useEffect(() => {
    ipList();
  }, []);
  const ipList = async () => {
    setLoading(true);

    try {
      const response = await postGetData(
        Apiservices.WITHDRAW_CRYPTO_CANCELED_HISTORY
      );
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Internal Server Error...");
      setLoading(false);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };

  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      search: inputSearch,
    };

    if (dateRange[0] && dateRange[1]) {
      postForm.startDate = formatDate(dateRange[0]);
      postForm.endDate = formatDate(dateRange[1]);
    }

    const queryString = new URLSearchParams(postForm).toString();
    try {
      const response = await postData(
        `${Apiservices.WITHDRAW_CRYPTO_CANCELED_HISTORY}?${queryString}`
      );

      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };
  const handleShowModel = (value) => {
    setShowDetails(value);
    setShowModel(true);
  };
  const [rowsPerPage,setRowsPerPage] = useState(10);
  const handleRowsPerPageChange = (newRowsPerPage) => {
      setRowsPerPage(parseInt(newRowsPerPage));
    }

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => currentPage * itemsPerPage + index + 1,
      sortable: true,
       minWidth:'100px'
    },
    {
      name: "Date",
      selector: (row) =>
        row.createdAt
          ? `${handleNullValue(utcToNormalTime(row?.createdAt).date)} ${
              utcToNormalTime(row?.createdAt).time
            }`
          : "---",
      sortable: true,
       minWidth:'200px'
    },
      {
      name: 'Coupon Code',
      // selector: row => handleNullValue(row.userDetails?.email),
      selector: row => 
          <div className="d-flex align-items-center">
      <Link className="link-tag" to={`/allUsers/userDetails/${row.nonpayersUserId}` } target='_blank'>
        <span className="mx-1 text-decoration-underline">
          {row?.usedCouponCode || '-'}
        </span>
      </Link>
    </div>,
      sortable: true,
      minWidth: '300px'

  },
    {
      name: "Email",
      selector: (row) =>
        <Link className="link-tag" to={`/allUsers/userDetails/${row.userId}` } target='_blank'>
        <span className="mx-1 text-decoration-underline">
          {row?.userDetails.email || '-'}
        </span>
      </Link>,
      sortable: true,
      minWidth:'250px'
    },
    {
      name: "Transaction ID",
      selector: (row) => <AddressCell address={row.transactionId} />,
      sortable: true,
     minWidth:'150px'
    },
    {
      name: "Currency Name",
      selector: (row) => handleNullValue(row.currency),
      sortable: true,

       minWidth:'150px'
    },
    {
      name: "Address",
      selector: (row) => <AddressCell address={row?.withdrawAddress} />,
      sortable: true,
       minWidth:'150px'
    },
    {
      name: "Total",
      selector: (row) =>
        handleNullValue(formatNumber(row?.amount, row?.coinsList?.decimalPoint)),
      sortable: true,
       minWidth:'100px'
    },
    {
        name: 'Fee',
        selector: row => handleNullValue(formatNumber(row?.feeAmount, row?.coinsList?.decimalPoint)),
        sortable: true,
        minWidth:'100px'

    },
    // {
    //     name: 'TDS',
    //     selector: row => handleNullValue(row.tds.toFixed(8)),
    //     sortable: true,
    //     grow: 3
    // },
    // {
    //     name: 'TDS Percentage',
    //     selector: row => `${handleNullValue((row.tdsPercentage).toFixed(2))}%`,
    //     sortable: true,
    //     grow:2

    // },

    // {
    //   name: "TDS",
    //   selector: (row) =>
    //     handleNullValue(formatNumber(row?.tds, row?.coinsList.decimalPoint)),
    //   sortable: true,
    //   minWidth:'100px'

    // },
    {
      name: "Receive",
      selector: (row) =>
        handleNullValue(formatNumber(row?.receiveAmount, row?.coinsList?.decimalPoint)),
      sortable: true,
      minWidth:'100px'

    },

    {
      name: "Action",
      cell: (row) => (
        <div className="edit d-flex">
          <span
            id={`tooltipEmail${row.id}`}
            onClick={() => handleShowModel(row)}
            className="mdi mdi-eye-outline"
            style={{ fontSize: "15px", cursor: "pointer" }}
          ></span>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen1}
            target={`tooltipEmail${row.id}`}
            toggle={toggleTooltip1}
          >
            {" "}
            View{" "}
          </Tooltip>
        </div>
      ),
      ignoreRowClick: true,
      // allowOverflow: true,
      // button: true,
    },
  ];

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value == "") {
      ipList();
      setSearchValid(false);
    }
    setinputSearch(value);
  };

  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
        // page: currentPage + 1,
        // limit: itemsPerPage,
      };

      const queryString = new URLSearchParams(postForm).toString();
      try {
        const response = await postData(
          `${Apiservices.WITHDRAW_CRYPTO_CANCELED_HISTORY}?${queryString}`
        );
        if (response.status) {
          setSearchLoad(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
        } else {
          setSearchLoad(false);
        }
      } catch (error) {
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDateChange = ([start, end]) => {
    const formattedStart = start ? formatDate(start) : null;
    const formattedEnd = end ? formatDate(end) : null;

    setDateRange([start, end]);

    if (!formattedStart && !formattedEnd) {
      ipList();
      setDownloadEndDate({});
    }
    if (formattedStart && formattedEnd) {
      setDownloadEndDate({ startDate: formattedStart, endDate: formattedEnd });
      handleDate({ startDate: formattedStart, endDate: formattedEnd });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleDate = async ({ startDate, endDate }) => {
    setLoading(true);
    const postForm = {
      startDate: startDate,
      endDate: endDate,
    };
    try {
      const queryString = new URLSearchParams(postForm).toString();
      const response = await postData(
        `${Apiservices.WITHDRAW_CRYPTO_CANCELED_HISTORY}?${queryString}`
      );
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleDownload = async (type) => {
    setDownLoading(true);
    try {
      const postForm = {};
      if (downloadStartDate.startDate) {
        postForm.startDate = downloadStartDate.startDate;
      }
      if (downloadStartDate.endDate) {
        postForm.endDate = downloadStartDate.endDate;
      }
      if (inputSearch) {
        postForm.search = inputSearch;
      }
      postForm.download = type;
      const queryString = new URLSearchParams(postForm).toString();

      const response = await axiosInstance.post(
        `${Apiservices.WITHDRAW_CRYPTO_CANCELED_HISTORY}?${queryString}`,
        {
          // Include any data you need to send with the POST request here
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
      if (response.data) {
        setDownLoading(false);
        const blob = response.data;

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute('download', `${user}.xlsx`);
        link.setAttribute("download", `CryptoRejectedUserHistory.${type}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error(response.data.message);
        setDownLoading(false);
      }
    } catch (err) {
      setDownLoading(false);
      console.error("Error downloading the file", err);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Crypto Withdraw"
            breadcrumbItem="Cancelled  History"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col sm={4} lg={3}>
                      <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Name,Email,Address,ID ..."
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            onClick={() => handleSearch()}
                            disabled={searchLoad}
                          >
                            {searchLoad ? (
                              <Spinner className="text-light" size="sm" />
                            ) : (
                              <i className="ri-search-line" />
                            )}
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4} lg={3}>
                      <div className="form-group mb-4">
                        <div>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select Start to End Date"
                              options={{
                                mode: "range",
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                                maxDate: today,
                              }}
                              value={dateRange}
                              onChange={handleDateChange}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Col>

                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        {/* <Dropdown
                          isOpen={btnauto3}
                          toggle={() => setbtnauto3(!btnauto3)}
                          id="dropdownMenuClickableInside"
                          disabled={downLoading}
                        >
                          <DropdownToggle tag="button" className="btn btn-info">
                            {downLoading ? <Loader /> : "Download"}

                            <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start">
                            <DropdownItem header>Select</DropdownItem>
                            <DropdownItem
                              onClick={() => handleDownload("xlsx")}
                            >
                              Excel
                            </DropdownItem>
                            <DropdownItem onClick={() => handleDownload("csv")}>
                              CSV
                            </DropdownItem>
                            <DropdownItem onClick={() => handleDownload("pdf")}>
                              PDF
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown> */}
                        <Button
                          color="info"
                          className="add-btn mx-2"
                          onClick={() => handleDownload("xlsx")}
                          id="create-btn"
                          disabled={downLoading}
                        >
                          <i className="mdi mdi-download-outline"></i>

                          {downLoading ? <Loader /> : "Download"}
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={formData}
                      paginationServer
                      paginationTotalRows={formData.length}
                      progressPending={loading}
                      persistTableHead={true}
                      progressComponent={<div className="py-3">Loading...</div>}
                      // noDataComponent={formData.length===0}
                      noDataComponent={
                        <div className="py-3">No records found</div>
                      }
                      fixedHeader={true}
                    />
                  </div>
                  
                  <div className="container">
                                                        <div className="row justify-content-end mt-4">
                                                        <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
                                                            <div className="d-flex align-items-center me-3">
                                                              <span className="me-2">Rows per page:</span>
                                                              <select
                                                                value={rowsPerPage}
                                                                onChange={(e) => handleRowsPerPageChange(e.target.value)}
                                                                className="form-control-sm"
                                                              >
                                                                <option value={10}>10</option>
                                                                <option value={20}>20</option>
                                                                <option value={30}>30</option>
                                                                <option value={50}>50</option>
                                                              </select>
                                                            </div>
                                                            <div className="me-md-3 mb-3 mb-md-0">
                                                              <span>{count}</span>
                                                            </div>
                                                            <div className="pagination pagination-rounded mt-3">
                                                              <ReactPaginate
                                                                previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                                nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                                breakLabel={"..."}
                                                                breakClassName={"break-me"}
                                                                pageCount={pageCount}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={5}
                                                                onPageChange={handlePageClick}
                                                                containerClassName={"pagination"}
                                                                activeClassName={"active"}
                                                                pageLinkClassName={"page-link"}
                                                                breakLinkClassName={"break-link"}
                                                                disabledClassName={"disabled"}
                                                                initialPage={currentPage}
                                                                disableInitialCallback={true}
                                                                forcePage={currentPage}
                                                                renderLink={(props) => {
                                                                  if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                                                    return (
                                                                      <a
                                                                        {...props}
                                                                        onClick={() =>
                                                                          handlePageClick({
                                                                            selected: pageCount - 1,
                                                                          })
                                                                        }
                                                                      >
                                                                        {pageCount}
                                                                      </a>
                                                                    );
                                                                  }
                                                                  return <a {...props} aria-label="Link description" />;
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        className="modal-dialog modal-lg"
        size="l"
        isOpen={showModel}
        centered
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
          Cancelled Withdraw History View
          </h5>
          <button
            onClick={() => {
              setShowModel(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body custom-modal-body">
          <div className="table-responsive">
            <table
              className="table table-bordered mb-0"
              style={{ width: "100%" }}
            >
              <tbody>
                <tr>
                  <td>
                    <b>Name</b>
                  </td>
                  <td>{showDetails.userDetails?.fullName || "-"}</td>
                </tr>
                <tr>
                  <td>
                    <b>Email</b>
                  </td>
                  <td>{showDetails.userDetails?.email || "-"}</td>
                </tr>

                <tr>
                  <td>
                    <b>Transaction ID</b>
                  </td>
                  <td>
                    <AddressCellFull address={showDetails?.transactionId || '-'} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Currency Name</b>
                  </td>
                  <td>{showDetails.currency}</td>
                </tr>
                <tr>
                  <td>
                    <b>Withdraw Address</b>
                  </td>
                  <td>
                    <AddressCellFull address={showDetails?.withdrawAddress || '-'} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Withdraw Amount</b>
                  </td>
                  <td>
                    {showDetails.amount
                      ? formatNumber(
                          showDetails?.amount,
                          showDetails?.decimalPoint
                        )
                      : 0}
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <b>
                      Fee Amount{" "}
                      {showDetails.feeType?.feeType === 1
                        ? `(${(showDetails?.amount, showDetails?.feeAmount)}%)`
                        : ""}
                    </b>
                  </td>
                  <td>
                    {showDetails.feeAmount
                      ? //   ? showDetails.feeAmount.toFixed(2)
                        formatNumber(
                          showDetails?.feeAmount,
                          showDetails?.decimalPoint
                        )
                      : 0}
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <b>TDS Amount</b>
                  </td>
                  <td>
                    {showDetails?.tds
                      ? formatNumber(
                          showDetails?.tds,
                          showDetails?.coinsList.decimalPoint
                        )
                      : "N/A"}
                  </td>
                </tr> */}
                {/* <tr>
                                    <td><b>TDS ({showDetails.tdsPercentage ? showDetails.tdsPercentage : 'N/A'}%)</b></td>
                                    <td>{showDetails.tds?.toFixed(8) || '0'}</td>
                                </tr> */}
                {/* <tr>
                                    <td><b>TDS Percentage</b></td>
                                    <td>{showDetails.tdsPercentage?.toFixed(2) || 'N/A'}%</td>
                                </tr> */}

                <tr>
                  <td>
                    <b>Receive Amount</b>
                  </td>
                  <td>
                    {formatNumber(
                      showDetails?.receiveAmount,
                      showDetails?.decimalPoint
                    ) || 0}{" "}
                    <span>({showDetails.currency})</span>
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <b>Canceled By</b>
                  </td>
                  <td>
                    {showDetails.adminEmail}({showDetails.adminName || "N/A"})
                  </td>
                </tr> */}
                {/* <tr>
                  <td>
                    <b>Canceled Reason</b>
                  </td>
                  <td>{showDetails.reason || "N/A"}</td>
                </tr> */}
                <tr>
                  <td>
                    <b>Request Date</b>
                  </td>
                  <td>
                    {utcToNormalTime(showDetails?.createdAt).date}{" "}
                    {utcToNormalTime(showDetails?.createdAt).time}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Canceled Date</b>
                  </td>
                  <td>
                    {utcToNormalTime(showDetails?.updatedAt).date}{" "}
                    {utcToNormalTime(showDetails?.updatedAt).time}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CryptoCancelWithdraw;

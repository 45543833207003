import React, { useState, useEffect ,useContext} from "react";
import { Input, Card, CardBody, Col, Container, Row,Button, Spinner, InputGroup } from "reactstrap";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../../ApiService/AxiosInstance";
import { Apiservices, Boolian } from "../../../ApiService/ApiServices";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../helpers/Spinner";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue, utcToNormalTime } from "../../../helpers/function";
import { AdminContext } from "../../../helpers/adminContext";

const PendingUsersKyc = () => {
  document.title = "Ultrapro | Active Users";
  const navigate = useNavigate();
  const { adminInfo } = useContext(AdminContext);
  const [adminType,setAdminType] = useState('')
  const location = useLocation();
  const today = new Date();
  const userApi = location.state?.user;

  const [dateRange, setDateRange] = useState([null, null]);
  const [downloadStartDate, setDownloadEndDate] = useState({});
  const [loading, setLoading] = useState(false);
  const [downLoading, setDownLoading] = useState(false);

  const [searchLoad, setSearchLoad] = useState(false);
  const [searchValid, setSearchValid] = useState(false);
  const [inputSearch, setinputSearch] = useState("");
  const [formData, setFormData] = useState([]);
  const [totalPagination, setTotalPagination] = useState(1);
  const [totalLimit, setTotalLimit] = useState(1);
  const [count, setCount] = useState("");

  const [user, setUser] = useState(userApi);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = totalLimit; // Number of items to display per page
  const pageCount = Math.ceil(totalPagination);

  useEffect(() => {
    if (user === undefined) {
      setUser(Apiservices.KYC_PENDING);
      setAdminType(adminInfo?.adminType)
      ipList();
    }
  }, [user,adminInfo?.adminType]);

  const handleFilter = async (apiName) => {
   
    setLoading(true);
    // const postForm = {
    //     ...filterParams
    // };
    try {
      const response = await postData(apiName);
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const ipList = async () => {
    setLoading(true);

    try {
      const response = await postData(Apiservices.KYC_PENDING);
     
      if (response.status) {
        console.log(response)
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
     
      toast.error("Internal Server Error...");
      setLoading(false);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    handlePagePost(selected);
  };

  const handlePagePost = async (selected) => {
    setLoading(true);
    const postForm = {
      page: selected + 1,
      // limit: itemsPerPage,
      search: inputSearch,
    };

    if (dateRange[0] && dateRange[1]) {
      postForm.startDate = formatDate(dateRange[0]);
      postForm.endDate = formatDate(dateRange[1]);
    }

    const queryString = new URLSearchParams(postForm).toString();
    try {
      if (user === undefined) {
        const response = await postData(`${Apiservices.KYC_PENDING}?${queryString}`);

        if (response.status) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
        } else {
          setLoading(false);
        }
      } else {
        const response = await postData(`${user}?${queryString}`);

        if (response.status) {
          setLoading(false);
          setFormData(response.data.data);
          setTotalPagination(response.data.totalPages);
          setTotalLimit(response.data.limit);
          setCount(response.data.currentCount);
        } else {
          setLoading(false);
          toast.error(response.message);
        }
      }
    } catch (error) {
     
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  // const getStatusText = (code) => {
  //   switch (code) {
  //     case Boolian.PENDING_USER:
  //       return "Pending";
  //     case Boolian.USER_VERIFIED:
  //       return "Verified";
  //     case Boolian.USER_BLOCKED:
  //       return "Blocked";
  //     default:
  //       return "Unknown";
  //   }
  // };

  // const getBadgeClass = (code) => {
  //   switch (code) {
  //     case Boolian.PENDING_USER:
  //       return "badge-soft-warning";
  //     case Boolian.USER_VERIFIED:
  //       return "badge-soft-success";
  //     case Boolian.USER_BLOCKED:
  //       return "badge-soft-danger";
  //     default:
  //       return "";
  //   }
  // };

  const getBadgeKYCClass = (code) => {
    switch (code) {
      case Boolian.KYC_PENDING_NUMBER:
        return "badge-soft-warning";
      case Boolian.KYC_VERIFIED_NUMBER:
        return "badge-soft-success";
      case Boolian.KYC_REJECTED:
        return "badge-soft-danger";
      case Boolian.KYC_NOT_UPLOAD:
        return "badge-soft-dark";
      default:
        return "";
    }
  };

  const getKycStatusText = (code) => {
    switch (code) {
      case Boolian.KYC_PENDING_NUMBER:
        return "Pending";
      case Boolian.KYC_VERIFIED_NUMBER:
        return "Verified";
      case Boolian.KYC_REJECTED:
        return "Rejected";
      case Boolian.KYC_NOT_UPLOAD:
        return "Not Uploaded";
      default:
        return "Unknown";
    }
  };
  const allowedActions = [1,2,3,4];
  const columns = [
   
    {
      name: "S.No",
      selector: (row, index) => currentPage * itemsPerPage + index + 1,
      sortable: true,
      minWidth: '50px',
      // grow: 1,
    },
    {
      name: "Name",
      selector: (row) => handleNullValue(row.fullName),
      sortable: true,
      minWidth: '200px',
      // grow: 2,
    },
    {
      name: "Email",
      selector: (row) => handleNullValue(row.email),
      sortable: true,
      minWidth: '300px',
      // grow: 5,
    },
    {
      name: "Mobile Number",
      // selector: (row) => handleNullValue(row.mobileNo),
      selector: (row) => row.mobileNo ? `${row.mobileCode ? `+${row.mobileCode} ` : ''}${row.mobileNo}` : '-',
      sortable: true,
      minWidth: '150px',
      // grow: 3,
    },
    {
      name: "Country",
      selector: (row) => handleNullValue(row.country),
      sortable: true,
      minWidth: '150px',
      // grow: 5,
    },
     {
      name: "Register From",
      // selector: (row) => handleNullValue(row.mobileNo),
      selector: (row) => (row.osType == 0 ? 'Web' : 'Android'),
      sortable: true,
      minWidth: '150px',
      // grow: 3,
    },
    {
      name: "KYC Status",
      selector: (row) => (
        <div className="edit">
          {/* <span className={`badge ${getBadgeClass(row.isActive)} me-1`}>
                        {getStatusText(row.isActive)}
                    </span> */}
          <span className={`badge ${getBadgeKYCClass(row.kycStatus)} me-1`}>
            {getKycStatusText(row.kycStatus)}
          </span>
        </div>
      ),
      sortable: true,
      minWidth: '100px',
      // grow: 2,
    },
    // {
    //   name: "Status",
    //   selector: (row) => (
    //     <div className="edit">
    //       {/* <span className={`badge ${getBadgeClass(row.isActive)} me-1`}>
    //                     {getStatusText(row.isActive)}
    //                 </span> */}
    //       <span className={`badge ${getBadgeClass(row.status)} me-1`}>
    //         {getStatusText(row.status)}
    //       </span>
    //     </div>
    //   ),
    //   sortable: true,
    //   minWidth: '100px',
    //   // grow: 2,
    // },
    {
      name: "Date & Time",
      selector: (row) =>
        row.createdAt
          ? `${handleNullValue(utcToNormalTime(row.createdAt).date)} ${
              utcToNormalTime(row.createdAt).time
            }`
          : "---",
      sortable: true,
      minWidth: '200px',
      // grow: 3,
    },
    ...(adminType === 1 || adminInfo?.permissions.some(data => 
      data.module === "Pending Users KYC" && allowedActions.some(action => data.actions.includes(action))) ? [
    {
        name: "Action",
        cell: (row) => (
          <div className="edit d-flex">
           
            
           {(adminType === 1 || adminInfo?.permissions.some(data => 
            data.module === "Pending Users KYC" && data.actions.includes(2))) ? (
            <div>
              <span
                id={`tooltipView${row.id}`}
                onClick={() =>   navigate(`/allUsers/userDetails/${row.acId}`, {
                  state: {kycTab:5 }
                })}
                className="mdi mdi-eye-outline"
                style={{ fontSize: "15px", cursor: "pointer" }}
              ></span>
            </div> ) :null}
          </div>
        ),
        ignoreRowClick: true,
        // minWidth: '50px',
        grow: 2,
      }]:[]),
   
  ];

  const handleSearchChanage = (e) => {
    const value = e.target.value;
    if (value === "" && user === undefined) {
      ipList();
      setSearchValid(false);
    } else if (value === "") {
      handleFilter(user);
    }
    setinputSearch(value);
  };

  const handleSearch = async () => {
    if (inputSearch !== "") {
      setSearchLoad(true);
      const postForm = {
        search: inputSearch,
        // page: currentPage + 1,
        // limit: itemsPerPage,
      };

      const queryString = new URLSearchParams(postForm).toString();
      try {
        if (user === undefined) {
          const response = await postData(`${Apiservices.KYC_PENDING}?${queryString}`);
          if (response.status) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);
          } else {
            setSearchLoad(false);
          }
        } else {
          const response = await postData(`${user}?${queryString}`);
          if (response.status) {
            setSearchLoad(false);
            setFormData(response.data.data);
            setTotalPagination(response.data.totalPages);
           
          } else {
            setSearchLoad(false);
          }
        }
      } catch (error) {
       
        toast.error("Internal Server Error..Try Again Later");
        setSearchLoad(false);
      }
    } else {
      setSearchValid(true);
    }
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDateChange = ([start, end]) => {
    const formattedStart = start ? formatDate(start) : null;
    const formattedEnd = end ? formatDate(end) : null;

    setDateRange([start, end]);

    if (!formattedStart && !formattedEnd) {
      handleFilter(user);
      setDownloadEndDate({});
    }

    if (formattedStart && formattedEnd) {
      setDownloadEndDate({
        startDate: formattedStart,
        endDate: formattedEnd,
      });
      handleDate({ startDate: formattedStart, endDate: formattedEnd });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };
 

  const handleDate = async ({ startDate, endDate }) => {
    setLoading(true);

    const postForm = {
      startDate: startDate,
      endDate: endDate,
    };
    try {
      const queryString = new URLSearchParams(postForm).toString();

      const response = await postData(`${user}?${queryString}`);
      if (response.status) {
        setLoading(false);
        setFormData(response.data.data);
        setTotalPagination(response.data.totalPages);
        setTotalLimit(response.data.limit);
        setCount(response.data.currentCount);
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Internal Server Error..Try Again Later");
      setLoading(false);
    }
  };

  const handleDownload = async (format) => {
    // setSelectedFormat(format);
    setDownLoading(true);
    try {
      const postForm = {};

      if (downloadStartDate.startDate) {
        postForm.startDate = downloadStartDate.startDate;
      }
      if (downloadStartDate.endDate) {
        postForm.endDate = downloadStartDate.endDate;
      }
      if (inputSearch) {
        postForm.search = inputSearch;
      }
      postForm.download = true;
      postForm.format = format
      const queryString = new URLSearchParams(postForm).toString();

      const response = await axiosInstance.post(
        `${user}?${queryString}`,
        {
          // Include any data you need to send with the POST request here
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
      if (response.data) {
        setDownLoading(false);
        const blob = response.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        // link.setAttribute('download', `${user}.xlsx`);
        link.setAttribute("download", `PendingUsersKyc.${format}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error(response.data.message);
        setDownLoading(false);
      }
    } catch (err) {
      setDownLoading(false);
      console.error("Error downloading the file", err);
    }
  };

  // const [dropdownOpen, setDropdownOpen] = useState(false);
  //const [selectedFormat, setSelectedFormat] = useState("");
  // const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // const exportOptions = [
  //   { label: "PDF", value: "pdf" },
  //   { label: "CSV", value: "csv" },
  //   { label: "XLSX", value: "xlsx" },
  // ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="User KYC Management" breadcrumbItem="Pending KYC Users" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col sm={4} lg={3}>
                      <div className="input-group">
                        <Input
                          // onChange={handleInputChange}
                          type="text"
                          className="form-control"
                          placeholder="Search by Name,Email,Mobile Number"
                          aria-label="Recipient's username"
                          onChange={handleSearchChanage}
                          invalid={searchValid}
                          onKeyPress={handleKeyPress}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                            {searchLoad ? <Spinner className="text-light" size="sm" /> : <i className="ri-search-line" />}
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4} lg={3}>
                      <div className="form-group mb-4">
                        <div>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select Start to End Date"
                              options={{
                                mode: "range",
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                                maxDate: today,
                              }}
                              value={dateRange}
                              onChange={handleDateChange}
                            />
                          </InputGroup>
                        </div>
                      </div>
                    </Col>

                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        {/* <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                          <DropdownToggle tag="button" className="btn btn-info">
                            {downLoading ? <Loader /> : selectedFormat || "Download"}
                            <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem header>Select Format</DropdownItem>
                            {exportOptions.map((option, index) => (
                              <DropdownItem
                                key={index}
                                onClick={() => handleDownload(option.value)}
                                disabled={downLoading} // Disable options while loading
                              >
                                {option.label}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown> */}
                          <Button
                          color="info"
                          className="add-btn mx-2"
                          onClick={() => handleDownload('xlsx')}
                          id="create-btn"
                          disabled={downLoading}
                        >
                          <i className="mdi mdi-download-outline"></i>

                          {downLoading ? <Loader /> : "Download"}
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={formData}
                      paginationServer
                      paginationTotalRows={formData.length}
                      progressPending={loading}
                      persistTableHead={true}
                      progressComponent={<div className="py-3">Loading...</div>}
                      // noDataComponent={formData.length===0}
                      noDataComponent={<div className="py-3">No records found</div>}
                      fixedHeader={true}
                    />
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-md-6">
                      <div className="d-flex justify-content-start mt-3">
                        <span>Current Count : {count}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      {loading ? (
                        ""
                      ) : (
                        <div className="d-flex justify-content-end mt-3">
                          <div className="pagination pagination-rounded mb-0">
                            <ReactPaginate
                              previousLabel={<i className="mdi mdi-chevron-left"></i>}
                              nextLabel={<i className="mdi mdi-chevron-right"></i>}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              pageLinkClassName={"page-link"}
                              breakLinkClassName={"break-link"}
                              disabledClassName={"disabled"}
                              initialPage={currentPage}
                              disableInitialCallback={true}
                              forcePage={currentPage}
                              // disableInitialCallback={true}
                              renderLink={(props) => {
                                if (props.page === "..." && props.pageCount > props.pageRangeDisplayed) {
                                  return (
                                    <a
                                      {...props}
                                      onClick={() =>
                                        handlePageClick({
                                          selected: pageCount - 1,
                                        })
                                      }
                                    >
                                      {pageCount}
                                    </a>
                                  );
                                }
                                return <a {...props} aria-label="Link Description" />;
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PendingUsersKyc;

import React, { useState, useEffect } from "react";
import { Button, Input, Card, CardBody, FormFeedback, Col, Container, FormGroup, Label, Row, Form } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Apiservices } from "../../ApiService/ApiServices";
import { postData } from "../../ApiService/AxiosInstance";
import toast from "react-hot-toast";
import { calculateDays } from "../../helpers/function";
import { useNavigate } from "react-router-dom";
import { values } from "lodash";
import { Editor } from "@tinymce/tinymce-react";


const FlexbleAddOffers = () => {
  const navigate = useNavigate('');

  document.title = "Ultrapro | Stake Offers";
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState("");

// console.log(content);

  const formik = useFormik({
    initialValues: {
      planName: "",
      duration: "",
      coinName: "",
      minStakeAmount: "",
      maxStakeAmount: "",
      description: "",
      lockPeriod:"",
      isPremium:"",
      email:"",
      summary:"",
      content:[ 
        {
        contentLine: "",
        contentIsActive: "",
      }],
      stakeType:'',
      initialStakePercentage:'',
      referralStakePercentage:'',
      dailyRewardPercentage:''
    },
    validationSchema: Yup.object({
      planName: Yup.string().required("Plan Name is Required"),
      duration: Yup.string().required("Duration in days is Required"),
      coinName: Yup.string().required("Coin Name is Required"),
      // email: Yup.string().required("Coin Name is Required"),
      isPremium: Yup.string().required("Select Premium Or Not "),
      lockPeriod: Yup.string().required("Lock Period  is Required"),
      minStakeAmount: Yup.number()
        .required("Minimum Stake Amount is Required")
        .typeError("Must be a number"),
        // .lessThan(Yup.ref("maxStakeAmount"), "Min Stake must be less than Max Stake"),
      maxStakeAmount: Yup.number()
        .required("Maximum Stake Amount is Required")
        .typeError("Must be a number"),
        // .moreThan(Yup.ref("minStakeAmount"), "Max Stake must be more than Min Stake"),
      description: Yup.string().trim().required("Description is Required"),
      stakeType: Yup.string().trim().required("Stake Type is Required"),
      content: Yup.array().of(
        Yup.object().shape({
          contentLine: Yup.string().trim().required("Content Line is Required"),
          contentIsActive: Yup.boolean().required("Content Status is Required"),
        })
      ).required("Content is Required"),

      initialStakePercentage: Yup.string()
      .nullable()
      .when('stakeType', {
        is: (value) => value == 2,
        then: (schema) => schema.required("Initial Stake Percentage  is Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      referralStakePercentage: Yup.string()
      .nullable()
      .when('stakeType', {
        is: (value) => value == 2,
        then: (schema) => schema.required("Referral Stake Percentage  is Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      dailyRewardPercentage: Yup.string()
      .nullable()
      .when('stakeType', {
        is: (value) => value == 2,
        then: (schema) => schema.required("Referral Stake Percentage  is Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    validateOnChange: false,
    onSubmit: async (values) => handleAdd(values),
  });
 
  // Submit handler
  const handleAdd = async (values) => {
    setLoading(true);
    const data = {
      planName: values.planName,
      coinName: values.coinName,
      duration: values.duration,
      minStakeAmount: values.minStakeAmount,
      maxStakeAmount: values.maxStakeAmount,
      description: values.description,
      lockPeriod : values.lockPeriod,
      content: values.content,
      email: values.email,
      summary: summary  ,
      isPremium: values.isPremium,
      stakeType:values.stakeType,
      initialStakePercentage:Number(values.initialStakePercentage),
      referralStakePercentage:Number(values.referralStakePercentage),
      dailyRewardPercentage:Number(values.dailyRewardPercentage)
    };
  console.log(data);
  
    try {
      const response = await postData(Apiservices.AddFlexiStakePlan, data);
      console.log("Backend response:", response); // Log the response for debugging
      if (response.status) {
        setLoading(false);
        toast.success(response.message);
        formik.resetForm();
        setTimeout(() => {
          navigate('/flexiblestake-plan'); // Replace with your target route
        }, 1000);
        // navigate('/flexiblestake-plan')
      } else {
        toast.error(response.message || "Submission failed");
        setLoading(false);

      }
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error("Error occurred during submission");
    } finally {
      setLoading(false);
    }
  };
  

  // Handle duration change and calculate weeks
  const handleDurationChange = (event) => {
    const selectedDuration = event.target.value;
    const weeks = calculateDays(selectedDuration);
    formik.setFieldValue("duration", selectedDuration);
    formik.setFieldValue("durationcount", weeks);
  };

  // Coin list API call for dropdown
  const [currencyList, setCurrencyList] = useState([]);
  useEffect(() => {
    coinList();
  }, []);
  
  const coinList = async () => {
    try {
      const response = await postData(Apiservices.USER_COIN_LIST);
      if (response.status) {
        setCurrencyList(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch coin list", error);
    }
  };

  // Function to add a new content row
  const addContentRow = () => {
    formik.setValues({
      ...formik.values,
      content: [...formik.values.content, { contentLine: "", contentIsActive: "" }]
    });
  };
  

  // Function to remove a content row by index
  const removeContentRow = (index) => {
    const updatedContent = formik.values.content.filter((_, i) => i !== index);
    formik.setValues({ ...formik.values, content: updatedContent });
  };
  const handleEditorChange = (content, editor) => {
    setSummary(content)
    formik.setFieldValue("summary", content); // Update Formik's `summary` field
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Flexible Stack Offers" breadcrumbItem="Create Flexible Staking Offers" />
          <Row>
            <Col lg={12} className="row">
              <Form onSubmit={formik.handleSubmit}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="planName">Plan Name</Label>
                          <Input
                            type="text"
                            name="planName"
                            id="planName"
                            className="form-control"
                            value={formik.values.planName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            placeholder="Enter Plan Name"
                            invalid={formik.touched.planName && !!formik.errors.planName}
                          />
                          <FormFeedback>{formik.errors.planName}</FormFeedback>
                        </FormGroup>
  
                        <FormGroup>
                          <Label for="coinName" className="form-label">
                            Currency Name
                          </Label>
                          <select
                            id="coinName"
                            name="coinName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.coinName || ""}
                            className={`form-control ${formik.touched.coinName && formik.errors.coinName ? "is-invalid" : ""}`}
                          >
                            <option value="" disabled>
                              Select Currency
                            </option>
                            {currencyList.map((data) => (
                              <option key={data.coinId} value={data.symbol}>
                                {data.symbol}
                              </option>
                            ))}
                          </select>
                          {formik.touched.coinName && formik.errors.coinName && (
                            <FormFeedback type="invalid">{formik.errors.coinName}</FormFeedback>
                          )}
                        </FormGroup>
  
                        <FormGroup>
                          <Label for="minStakeAmount">Minimum Stake Amount</Label>
                          <Input
                            type="number"
                            name="minStakeAmount"
                            id="minStakeAmount"
                            className="form-control"
                            placeholder="Enter Min Stake Amount"
                            value={formik.values.minStakeAmount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            invalid={formik.touched.minStakeAmount && !!formik.errors.minStakeAmount}
                          />
                          <FormFeedback>{formik.errors.minStakeAmount}</FormFeedback>
                        </FormGroup>
  
                        <FormGroup>
                          <Label for="maxStakeAmount">Maximum Stake Amount</Label>
                          <Input
                            type="number"
                            name="maxStakeAmount"
                            id="maxStakeAmount"
                            className="form-control"
                            placeholder="Enter Max Stake Amount"
                            value={formik.values.maxStakeAmount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            invalid={formik.touched.maxStakeAmount && !!formik.errors.maxStakeAmount}
                          />
                          <FormFeedback>{formik.errors.maxStakeAmount}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                          <Label for="description">Description</Label>
                          <Input
                            type="textarea"
                            rows="5"
                            name="description"
                            id="description"
                            className="form-control"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            autoComplete="off"
                            onBlur={formik.handleBlur}
                            placeholder="Enter Description"
                            invalid={formik.touched.description && !!formik.errors.description}
                          />
                          <FormFeedback>{formik.errors.description}</FormFeedback>
                        </FormGroup>
                        {formik.values.stakeType == 2 && 
                        <FormGroup>
                          <Label for="referralStakePercentage">Referral Stake Percentage</Label>
                          <Input
                            type="number"
                            min={0}
                            name="referralStakePercentage"
                            id="referralStakePercentage"
                            className="form-control"
                            value={formik.values.referralStakePercentage}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            placeholder="Enter Initial stake percentage"
                            invalid={formik.touched.referralStakePercentage && !!formik.errors.referralStakePercentage}
                          />
                          <FormFeedback>{formik.errors.referralStakePercentage}</FormFeedback>
                        </FormGroup>}
                      </Col>
  
                      <Col md={6}>
                        <FormGroup>
                          <Label for="duration">Duration</Label>
                          <Input
                            type="number"
                            name="duration"
                            id="duration"
                            className="form-control"
                            value={formik.values.duration}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            placeholder="Enter Duration"
                            invalid={formik.touched.duration && !!formik.errors.duration}
                          />
                          <FormFeedback>{formik.errors.duration}</FormFeedback>
                        </FormGroup>
  
                        <FormGroup>
                          <Label for="lockPeriod">Lock Period</Label>
                          <Input
                            type="number"
                            name="lockPeriod"
                            id="lockPeriod"
                            className="form-control"
                            value={formik.values.lockPeriod}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            placeholder="Enter Lock Period"
                            invalid={formik.touched.lockPeriod && !!formik.errors.lockPeriod}
                          />
                          <FormFeedback>{formik.errors.lockPeriod}</FormFeedback>
                        </FormGroup>
  
                        <FormGroup>
                          <Label for="email">Email</Label>
                          <Input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            placeholder="Enter Email"
                            invalid={formik.touched.email && !!formik.errors.email}
                          />
                          <FormFeedback>{formik.errors.email}</FormFeedback>
                        </FormGroup>
  
                        <FormGroup>
                          <Label for="isPremium">Is Premium</Label>
                          <Input
                            type="select"
                            id="isPremium"
                            name="isPremium"
                            className="form-control"
                            value={formik.values.isPremium}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            invalid={formik.touched.isPremium && !!formik.errors.isPremium}
                          >
                            <option value="" disabled>Is Premium</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </Input>
                          <FormFeedback>{formik.errors.isPremium}</FormFeedback>
                        </FormGroup>

                        
                      <FormGroup>
                        <Label for="duration">Stake Type</Label>
                        <select
                          id="stakeType"
                          name="stakeType"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.stakeType || ""}
                          className={`form-control ${formik.touched.stakeType && formik.errors.stakeType ? "is-invalid" : ""}`}
                        >
                          <option value="" disabled>
                            Select Type
                          </option>
                          <option value={0}>Free</option>
                          <option value={1}>Cost</option>
                          <option value={2}>Minimal</option>
        
                        </select>
                        {formik.touched.stakeType && formik.errors.stakeType && <FormFeedback type="invalid">{formik.errors.stakeType}</FormFeedback>}
                      </FormGroup>
                      {formik.values.stakeType == 2 && 
                      <FormGroup>
                          <Label for="initialStakePercentage">Initial Stake Percentage</Label>
                          <Input
                            type="number"
                            name="initialStakePercentage"
                            id="initialStakePercentage"
                            className="form-control"
                            value={formik.values.initialStakePercentage}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            min={0}
                            placeholder="Enter Initial stake percentage"
                            invalid={formik.touched.initialStakePercentage && !!formik.errors.initialStakePercentage}
                          />
                          <FormFeedback>{formik.errors.initialStakePercentage}</FormFeedback>
                        </FormGroup>}
                      {formik.values.stakeType == 2 && 
                        <FormGroup>
                          <Label for="dailyRewardPercentage">Daily Reward Percentage</Label>
                          <Input
                            type="number"
                            name="dailyRewardPercentage"
                            id="dailyRewardPercentage"
                            className="form-control"
                            value={formik.values.dailyRewardPercentage}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            placeholder="Enter Daily Reward percentage"
                            min={0}
                            invalid={formik.touched.dailyRewardPercentage && !!formik.errors.dailyRewardPercentage}
                          />
                          <FormFeedback>{formik.errors.dailyRewardPercentage}</FormFeedback>
                        </FormGroup>}
                      </Col>
  
                      
  <FormGroup>
  <Label for="summary">Summary</Label>
  <Editor
                              apiKey="1sc0anmjblezhxi95xon4501yg96pnp3qw0fj5osttzyz752"
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: "link image code",
                                toolbar:
                                  "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code",
                                setup: (editor) => {
                                  editor.on("change", () => {
                                    console.log("Editor content changed");
                                  });
                                },
                              }}
                              onEditorChange={handleEditorChange}
                            />
  </FormGroup>
                      {formik.values.content.map((item, index) => (
                        <div key={index} className="row">
                          <Col sm={5} lg={5}>
                            <FormGroup>
                              <Label for={`content[${index}].contentLine`}>Content Line</Label>
                              <Input
                                type="textarea"
                                name={`content[${index}].contentLine`} // Dynamically set name for each item
                                id={`contentLine-${index}`}
                                className="form-control"
                                placeholder="Enter Content Line"
                                value={formik.values.content[index].contentLine}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoComplete="off"
                                invalid={
                                  formik.touched.content?.[index]?.contentLine &&
                                  !!formik.errors.content?.[index]?.contentLine
                                }
                              />
                              <FormFeedback>
                                {formik.errors.content?.[index]?.contentLine}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
  
                          <Col sm={4} lg={4}>
                            <FormGroup>
                              <label htmlFor={`content[${index}].contentIsActive`} className="form-label">
                                Content Status
                              </label>
                              <select
                                id={`content-${index}`}
                                name={`content[${index}].contentIsActive`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.content[index].contentIsActive || ""}
                                className={`form-control ${
                                  formik.touched.content?.[index]?.contentIsActive &&
                                  formik.errors.content?.[index]?.contentIsActive
                                    ? "is-invalid"
                                    : ""
                                }`}
                              >
                                <option value="" disabled>
                                  Select Status
                                </option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </select>
                              {formik.touched.content?.[index]?.contentIsActive &&
                                formik.errors.content?.[index]?.contentIsActive && (
                                  <FormFeedback type="invalid">
                                    {formik.errors.content?.[index]?.contentIsActive}
                                  </FormFeedback>
                                )}
                            </FormGroup>
                          </Col>
  
                          <Col sm={1} lg={1} className="d-flex align-items-center">
                            {index > 0 && (
                              <i className="ri-delete-bin-line" onClick={() => removeContentRow(index)}></i>
                            )}
                          </Col>
  
                          <Col sm={2} lg={2} className="d-flex align-items-center">
                            <Button color="success" className="add-btn" onClick={addContentRow}>
                              <i className="ri-add-line align-bottom me-1"></i> Add Content
                            </Button>
                          </Col>
                        </div>
                      ))}
                    </Row>
  
                    {/* <Row>
                      <FormGroup>
                        <Label for="summary">Summary</Label>
                        <Input
                          type="textarea"
                          rows="5"
                          name="summary"
                          id="summary"
                          className="form-control"
                          value={formik.values.summary}
                          onChange={formik.handleChange}
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          placeholder="Enter summary"
                          invalid={formik.touched.summary && !!formik.errors.summary}
                        />
                        <FormFeedback>{formik.errors.summary}</FormFeedback>
                      </FormGroup>
                    </Row> */}
  
                    <Button color="primary" type="submit" disabled={loading}>
                      {loading ? "Loading " : "Submit"}
                    </Button>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
  
};

export default FlexbleAddOffers;

import React, { useState, useEffect } from "react";
import { Input, Card, CardBody, Col, Container, Row, Spinner, InputGroup, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";
import axiosInstance, { postData } from "../../ApiService/AxiosInstance";
import { Apiservices } from "../../ApiService/ApiServices";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { handleNullValue } from "../../helpers/function";
import Loader from "../../helpers/Spinner";


const TransactionHistory = () => {
   
    const [FileName, setFileName] = useState('')
    const [selectedItem, setSelectedItem] = useState('All History');
    document.title = "Ultrapro | Transaction History"
    const [downLoading, setDownLoading] = useState(false);
    const [downloadStartDate, setDownloadEndDate] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchLoad, setSearchLoad] = useState(false);
    const [btnauto3, setbtnauto3] = useState(false);
    const [searchValid, setSearchValid] = useState(false);
    const [inputSearch, setinputSearch] = useState('');
    const [formData, setFormData] = useState([]);
    const [dropDownData, setDropDownData] = useState([]);
    const [totalPagination, setTotalPagination] = useState(1);
    const [totalLimit, setTotalLimit] = useState(1);
    const [count, setCount] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = totalLimit; // Number of items to display per page
    const pageCount = Math.ceil(totalPagination);

    useEffect(() => {
        ipList();
    }, []);

    const ipList = async () => {
        setLoading(true);

        try {
            const response = await postData(Apiservices.TRANSACTION_HISTORY);
            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit)
                setCount(response.data.currentCount)
                setDropDownData(response.data.descriptions)

            } else {
                toast.error(response.message);
                setLoading(false);

            }
        } catch (error) {
            toast.error("Internal Server Error...");
            setLoading(false);


        }
    };

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
        handlePagePost(selected);
    };

    const handlePagePost = async (selected) => {
        setLoading(true);
        const postForm = {
            page: selected + 1,
            search: inputSearch,
        }

        if (dateRange[0] && dateRange[1]) {
            postForm.startDate = formatDate(dateRange[0]);
            postForm.endDate = formatDate(dateRange[1]);
        }
        if (FileName) {
            postForm.filter = FileName;
        }
        

        const queryString = new URLSearchParams(postForm).toString();
        try {
            const response = await postData(`${Apiservices.TRANSACTION_HISTORY}?${queryString}`);

            if (response.status) {
                setLoading(false);
                setFormData(response.data.data);
                setTotalPagination(response.data.totalPages);
                setTotalLimit(response.data.limit);
                setCount(response.data.currentCount);
            } else {
                setLoading(false);
                toast.error(response.message);
            }



        } catch (error) {
            toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => currentPage * itemsPerPage + index + 1,
            sortable: true,
            grow: 1,
        },
      
        {
            name: 'Name',
            selector: row => (row.fullName ? row.fullName : '-'),
            sortable: true,
            grow: 2,
        },
        {
            name: 'Mail Id',
            selector: row => (row.email ? row.email : '-'),
            sortable: true,
            grow: 4,
        },
        {
            name: 'Type',
            selector: row => handleNullValue(row.description),
            sortable: true,
            grow: 2,
        },
        {
            name: 'Amount',
            selector: row => (row.amount ? `${row.amount.toFixed(8)} USDT` : '-'),
            sortable: true,
            grow: 3,
        },
        {
            name: 'Transaction ID',
            selector: row => handleNullValue(row.transId),
            sortable: true,
            grow: 2,

        },

        {
            name: 'Date & Time',
            selector: row => row.createdAt
                ? `${utcToNormalTime(row.createdAt).date} ${utcToNormalTime(row.createdAt).time}`
                : "-",
            sortable: true,
            grow: 3,

        },

    ];


    const utcToNormalTime = (utcString) => {
        const date = new Date(utcString);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${months[date.getMonth()]}-${date.getFullYear()}`;

        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, "0");
        // const seconds = date.getSeconds().toString().padStart(2, "0");
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes} ${ampm}`;
        return { date: formattedDate, time: formattedTime };
    };



    const handleSearchChanage = (e) => {
        const value = e.target.value
        if (value == '') {
            ipList()
            setSearchValid(false)
        }
        setinputSearch(value)

    }


    const handleSearch = async () => {
        if (inputSearch !== '') {
            setSearchLoad(true);
            const postForm = {
                search: inputSearch,

            };
            if (FileName) {
                postForm.filter = FileName;
            }
            const queryString = new URLSearchParams(postForm).toString();
            try {

                const response = await postData(`${Apiservices.TRANSACTION_HISTORY}?${queryString}`,);
                if (response.status) {
                    setSearchLoad(false);
                    setFormData(response.data.data);
                    setTotalLimit(response.data.limit)
                    setCount(response.data.currentCount)
                    setTotalPagination(response.data.totalPages);

                    // toast(response.message);
                } else {
                    setSearchLoad(false);
                }

            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
                setSearchLoad(false);
            }
        } else {
            setSearchValid(true)
        }
    }


    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    const [dateRange, setDateRange] = useState([null, null]);
    const today = new Date();
    const handleDateChange = ([start, end]) => {


        const formattedStart = start ? formatDate(start) : null;
        const formattedEnd = end ? formatDate(end) : null;

        setDateRange([start, end]);

        if (!formattedStart && !formattedEnd) {
           ipList();
            setDownloadEndDate({})

        }


        // if (formattedStart && formattedEnd) {
        //     handleDate({ startDate: formattedStart, endDate: formattedEnd });
        // }
        if (formattedStart && formattedEnd) {
            setDownloadEndDate({ startDate: formattedStart, endDate: formattedEnd })

            handleDate({ startDate: formattedStart, endDate: formattedEnd });

        }
        else {
            ipList()
        }
    };
    const handleDate = async ({ startDate, endDate }) => {

        setLoading(true);


        const postForm = {
            startDate: startDate,
            endDate: endDate
        }
        if (FileName) {
            postForm.filter = FileName;
        }
        try {
            const queryString = new URLSearchParams(postForm).toString();

            const response = await postData(`${Apiservices.TRANSACTION_HISTORY}?${queryString}`);
            if (response.status) {
                setLoading(false);
                if (response.data.length !== 0) {
                    setTotalPagination(response.data.totalPages);
                    setTotalLimit(response.data.limit)
                    setCount(response.data.currentCount)

                    setFormData(response.data.data);
                }
                else {
                    setFormData(response.data);
                    // setDateRange("")
                }


            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            // toast.error("Internal Server Error..Try Again Later");
            setLoading(false);
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };



    const handleDropdownClick = (item) => {
        
        setDownloadEndDate({})
        setSelectedItem(item);
        setFileName(item)
        handleDropdownSelect(item)

    };



    const handleDropdownSelect = async (data) => {

           setLoading(true)
            const postForm = {
                filter:data,
                
            };
            if (dateRange[0] && dateRange[1]) {
                postForm.startDate = formatDate(dateRange[0]);
                postForm.endDate = formatDate(dateRange[1]);
            }

            const queryString = new URLSearchParams(postForm).toString();
            try {

                const response = await postData(`${Apiservices.TRANSACTION_HISTORY}?${queryString}`,);
                if (response.status) {
           setLoading(false)
                 
                    setFormData(response.data.data);
                    setTotalLimit(response.data.limit)
                    setCount(response.data.currentCount)
                    setTotalPagination(response.data.totalPages);

                    // toast(response.message);
                } else {
           setLoading(false)
                   
                }

            } catch (error) {
                toast.error("Internal Server Error..Try Again Later");
    
            }
        
    }



    const handleDownload = async (format) => {
       // setSelectedFormat(format)
        setDownLoading(true)
        try {
            const postForm = {};

            if (dateRange[0] && dateRange[1]) {
                postForm.startDate = formatDate(dateRange[0]);
                postForm.endDate = formatDate(dateRange[1]);
            }

            if (inputSearch) {
                postForm.search = inputSearch;
            }
            if (FileName) {
                postForm.filter = FileName;
            }
            postForm.download = format;
            const queryString = new URLSearchParams(postForm).toString();

            const response = await axiosInstance.post(`${Apiservices.TRANSACTION_HISTORY}?${queryString}`, {
                // Include any data you need to send with the POST request here
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                responseType: 'blob'
            });
            if (response.data) {
                setDownLoading(false)
                const blob = response.data;

                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                // link.setAttribute('download', `${user}.xlsx`);
                link.setAttribute('download', `${FileName ? FileName: "User Transaction"} History.${format}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                toast.error(response.data.message)
                setDownLoading(false)

            }
        } catch (err) {
            setDownLoading(false)
            console.error('Error downloading the file', err);
        }
    };
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    //const [selectedFormat, setSelectedFormat] = useState('');
    // const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  
    // const exportOptions = [
    //     { label: 'PDF', value: 'pdf' },
    //     { label: 'CSV', value: 'csv' },
    //     { label: 'XLSX', value: 'xlsx' }
    // ];
     return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Transaction History" breadcrumbItem=" User Transaction History" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col sm={4} lg={3}>
                                            <div className="input-group">
                                                <Input
                                                    // onChange={handleInputChange}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search ..."
                                                    aria-label="Recipient's username"
                                                    onChange={handleSearchChanage}
                                                    invalid={searchValid}
                                                    onKeyPress={handleKeyPress}
                                                />
                                                <div className="input-group-append">

                                                    <button className="btn btn-primary" type="submit" onClick={() => handleSearch()} disabled={searchLoad}>
                                                        {searchLoad ? (

                                                            <Spinner className="text-light" size="sm" />
                                                        ) : (

                                                            <i className="ri-search-line" />
                                                        )}

                                                    </button>


                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={4} lg={3}>
                                            <div className="form-group mb-4">

                                                <div>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="Select Start to End Date"
                                                            options={{
                                                                mode: 'range',
                                                                altInput: true,
                                                                altFormat: "F j, Y",
                                                                dateFormat: "Y-m-d",
                                                                maxDate: today,

                                                            }}
                                                            value={dateRange}
                                                            onChange={handleDateChange}
                                                        />
                                                    </InputGroup>
                                                </div>

                                            </div>
                                        </Col>

                                        <Col className="col-sm">

                                            <div className="d-flex justify-content-sm-end">
                                                <Dropdown className="mx-2"
                                                    isOpen={btnauto3}
                                                    toggle={() => setbtnauto3(!btnauto3)}
                                                    id="dropdownMenuClickableInside"
                                                >
                                                    <DropdownToggle tag="button" className="btn btn-info">
                                                        {selectedItem}<i className="mdi mdi-chevron-down" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end dropdown-menu-lg-start">
                                                        <DropdownItem header>Select</DropdownItem>
                                                        {/* <DropdownItem value ={'All History'}onClick={() => ipList()}>All History</DropdownItem> */}

                                                        {dropDownData.map((data, index) => (

                                                            <DropdownItem key={index} onClick={() => handleDropdownClick(data)}>{data}</DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                </Dropdown>


                                                {/* <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                            <DropdownToggle tag="button" className="btn btn-info">
                                            {downLoading ? <Loader /> : (selectedFormat || "Download")} 
                                             <i className="mdi mdi-chevron-down" />
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem header>Select Format</DropdownItem>
                                            {exportOptions.map((option, index) => (
                                                <DropdownItem 
                                                    key={index} 
                                                    onClick={() => handleDownload(option.value)}
                                                    disabled={downLoading} // Disable options while loading
                                                >
                                                    {option.label}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                        </Dropdown> */}
                                        <Button
                          color="info"
                          className="add-btn mx-2"
                          onClick={() => handleDownload('xlsx')}
                          id="create-btn"
                          disabled={downLoading}
                        >
                          <i className="mdi mdi-download-outline"></i>

                          {downLoading ? <Loader /> : "Download"}
                        </Button>

                                                {/* <Button color="soft-danger"
       
    ><i style={{ fontSize: '15px' }} className="mdi mdi-download-circle-outline"></i>Download</Button> */}
                                                {/* <Button color="info" className="add-btn mx-2" onClick={handleDownload} id="create-btn" disabled={downLoading}>
                                                    <i className="mdi mdi-download-outline"></i>

                                                    {downLoading ? (
                                                        <Loader />
                                                    ) : ("Download")}
                                                </Button> */}
                                            </div>

                                        </Col>
                                    </Row>


                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={formData}
                                            paginationServer
                                            paginationTotalRows={formData.length}
                                            progressPending={loading}
                                            progressComponent={<div>Loading...</div>}
                                            persistTableHead={true}
                                            // noDataComponent={formData.length===0}
                                            noDataComponent={<div className="py-3">No records found</div>}
                                            fixedHeader={true}

                                        />
                                    </div>

                                    <div className="d-flex justify-content-start mt-3">
                                        <span>Current Count : {count}</span>

                                    </div>

                                    {loading ? (
                                        ""

                                    ) : (

                                        <div className="d-flex justify-content-end mt-3">
                                            <div className="pagination pagination-rounded mb-0">
                                                <ReactPaginate
                                                    previousLabel={<i className="mdi mdi-chevron-left"></i>}
                                                    nextLabel={<i className="mdi mdi-chevron-right"></i>}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination"}
                                                    activeClassName={"active"}
                                                    pageLinkClassName={"page-link"}
                                                    breakLinkClassName={"break-link"}
                                                    disabledClassName={"disabled"}
                                                    initialPage={currentPage}
                                                    disableInitialCallback={true}
                                                    forcePage={currentPage}
                                                    // disableInitialCallback={true}
                                                    renderLink={(props) => {
                                                        if (
                                                            props.page === "..." &&
                                                            props.pageCount > props.pageRangeDisplayed
                                                        ) {
                                                            return (
                                                                <a
                                                                    {...props}
                                                                    onClick={() =>
                                                                        handlePageClick({
                                                                            selected: pageCount - 1,
                                                                        })
                                                                    }
                                                                >
                                                                    {pageCount}
                                                                </a>
                                                            );
                                                        }
                                                        return <a {...props} aria-label="Link Description" />;
                                                    }}
                                                />
                                            </div>


                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TransactionHistory;

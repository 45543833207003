import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import DataTable from 'react-data-table-component';
import ReactPaginate from "react-paginate";
import { handleNullValue, utcToNormalTime } from "../../../helpers/function";

const UserRefferalCommission = ({ getReferralList, refLoad }) => {

  document.title = "Ultrapro | User Management";
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 10;

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * rowsPerPage;
  const currentData = getReferralList && getReferralList.length > 0 && getReferralList[0].referredUsers 
    ? getReferralList[0].referredUsers.slice(offset, offset + rowsPerPage) 
    : [];

  const totalRows = getReferralList && getReferralList.length > 0 && getReferralList[0].referredUsers
    ? getReferralList[0].referredUsers.length
    : 0;

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => offset + index + 1,
      sortable: true,
      grow: 1,
    },
    {
      name: "Name",
      selector: (row) => handleNullValue(row.fullName),
      sortable: true,
      grow: 2,
    },
    {
      name: "Registered Mail ID",
      selector: (row) => handleNullValue(row.email),
      sortable: true,
      grow: 3,
    },
    {
      name: "Amount ",
      selector: (row) => row.referralAmount ? `${row.referralAmount} USDT` : "-",
      sortable: true,
      grow: 1,
    },
    {
      name: "Date & Time",
      selector: (row) => row.createdAt
        ? `${utcToNormalTime(row.createdAt).date} ${utcToNormalTime(row.createdAt).time}`
        : "---",
      sortable: true,
      grow: 2,
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col sm="12" className="d-lg-flex">
          <Card className="col-lg-12">
            <CardBody>
              <Row className="mb-4">
                <Col md={8}>
                  <h4 className="card-title">Referral Commissions</h4>
                </Col>
                <Col md={4}>
                  <h4 className="card-title">
                    Total Referral Commision :{" "}
                    {getReferralList[0]?.totalReferralAmount
                      ? `${getReferralList[0]?.totalReferralAmount.toFixed(8)} USDT`
                      : "0"}
                  </h4>
                </Col>
              </Row>
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={currentData}
                  paginationServer
                  paginationTotalRows={totalRows}
                  progressPending={refLoad}
                  persistTableHead={true}
                  progressComponent={
                    <div className="py-3">Loading...</div>
                  }
                  noDataComponent={
                    <div className="py-3">No records found</div>
                  }
                  fixedHeader={true}
                />
                {totalRows !== 0 ? (
                  <div className="d-flex justify-content-end mt-3">
                    <div className="pagination pagination-rounded mb-0">
                      <ReactPaginate
                        previousLabel={<i className="mdi mdi-chevron-left"></i>}
                        nextLabel={<i className="mdi mdi-chevron-right"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        pageLinkClassName={"page-link"}
                        breakLinkClassName={"break-link"}
                        disabledClassName={"disabled"}
                        pageCount={Math.ceil(totalRows / rowsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserRefferalCommission;
